import React from 'react';

import ConfigX from '../../../ConfigX'
import SearchBar from './SearchBar';
import Pagger from '../../../components/Pagger';
import SortBy from '../../../components/SortBy';
import Table from './Table';
import Det from './Det';
import Add from './Add';
import AlertMsg from '../../../components/AlertMsg';

import loading from '../../../image/loading.gif'

import { CSVLink } from "react-csv";
import CsvIcon from '../../../image/csv_icon.png';
class Main extends React.Component
 {


    //-----------filter memory-----------

    uqFilter = "2776797k645645fdf";


    setToFilter(xName, xValue)
    {
        const filterJSON = localStorage.getItem("filter-"+this.uqFilter);

        var tab = {};

        if(filterJSON != null && filterJSON != undefined)
        {
        var tab =  JSON.parse(filterJSON);

        tab[xName] = xValue;
        }

        console.log('setToFilter', tab );
        localStorage.setItem("filter-"+this.uqFilter, JSON.stringify(tab) );
    }

    getFromFilter(xName)
    {
        const filterJSON = localStorage.getItem("filter-"+this.uqFilter);

        var tab = {};

        if(filterJSON != null && filterJSON != undefined)
        {
        var tab =  JSON.parse(filterJSON);

        if(tab[xName]) return tab[xName];
        }

        return '';
    }

    goClearFilter()
    {
        const filterJSON = localStorage.getItem("filter-"+this.uqFilter);
        var tab = {};
        localStorage.setItem("filter-"+this.uqFilter, JSON.stringify(tab) );
    }


    //-----------------------------------



    constructor(props)
    {
        super(props);         

        this.state = {

            refresh: props.refresh,

            search_bar: {                                                                             
                    fraza: this.getFromFilter('fraza'), 
                    email: this.getFromFilter('email'),
                    telefon: this.getFromFilter('telefon'),                                 
                    id: this.getFromFilter('id'),                    
            },

            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },

            loading: 1,
            view: "list",
            selectedId: -1,
            rows: [],
            result: [],
            param: '',

            alertMSG: '',
            alertMSGTime: 0,                     
        }
  
    }



  
    //Filtry..
    changeFilter(filterName, filterValue)
    {                                 
       
            this.setToFilter(filterName, filterValue);

            var searchTmp = this.state.search_bar;
            searchTmp[filterName] = filterValue;                         

                    this.setState({
                        search_bar:  searchTmp,                            
                        view: "list",
                        selectedId: -1
                    });                 

            this.getData();
    }


    //Stronnicowanie...
    changePage(page)
    {
        

       //console.log("changePage: " + page );    
       this.state.pagger.page = parseInt(page);          
       this.getData(null);      
    }

    changeLimit(limit)
    {
      
       // console.log("changeLimit: " + limit );                        
        this.state.pagger.page = 1;
        this.state.pagger.limit = parseInt(limit);
        this.getData(null);
    }

    //Sortowanie..

    changeSort(column, sort)
    {
         console.log("changeSort: " + column + " | "  + sort );    

         this.state.pagger.order_col = column;
         this.state.pagger.order = sort;
         this.getData(null);
    }

    //Wyszukaj..
    goSearch(){
       
        this.getData(null);
    }
        
    //Wyczszczenie parametrów wyszukiwania
    goClear()
    {          
        this.setState(
            {
                search_bar:
                {                                                                
                    fraza: "", 
                    email: "",
                    telefon: "",                                
                    id: "",                    
                },
                view: "list",
                selectedId: -1
            }
        );
        
        setTimeout(() => {
             this.getData(null);                
        }, 100);
     
    }

    //Pobranie danych
    getData(  onFinish )
    {

        //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
        var wait = setTimeout(
             () => {
                this.setState({ loading: 1 });
             }
            ,500);

           

        var tmpNonRef = JSON.stringify( this.state );
        tmpNonRef = JSON.parse(tmpNonRef);

        var dataPost = tmpNonRef.search_bar;
        dataPost.pagger = tmpNonRef.pagger;

      
        
        //console.log("Requets " +  JSON.stringify(dataPost) );

        fetch( ConfigX.restApi + "/dash,persons" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        
             //console.log(JSON.stringify(json) );
             clearTimeout(wait);

             this.setState({
                rows: json.rows,
                result: json.result,
                loading: 0
             }); 
                         
             if(onFinish != null && onFinish != undefined ) onFinish();
           
        });
    }
        
 
    addPerson()
    {
        this.setState({view:"add"})
    }

    redirect(action, id, param)
    {
      

        if(param == "deleted") //usunięto szkolenie
        {
            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: "Osoba została usunięta!",
                alertMSGTime: dt
            });                  
        }

 
       

        if(action == "list")
        {
            this.getData(null);
        }
     
        
        this.setState(
            {
                view: action,
                selectedId: id,
                param: param
            }
           );
    }

    componentDidUpdate()
    {
        //console.log(document.location.href + " eee ");
        if(this.state.refresh != this.props.refresh)
        {   
            this.setState( {refresh: this.props.refresh, view: "list" }  );
        }
    }

    componentDidMount()
    {
        //console.log("List mount " + this.state.search_bar);    
        this.getData(null);                     
    }

    
    onDialogOK(param)
    {
        console.log("click ok dialog");
            this.setState({
                alertMSG: '',
                alertMSGTime: 0,
            });
    }


  
    onClickAddNew(event)
    {
        //this.redirect("new",-1,"");

      /*  if(this.state.new_nip.length < 4 || this.state.nn_new_nip == 'incorrect')
        {
            this.setState({
                alertMSG: 'Podaj prawidłowy nip',
                alertMSGTime: 12,
            });

            return;
        }*/

        var dataPost = {
            id: -1,       
            nazwa: "Podaj nazwę firmy",       
            nip: this.state.new_nip,       
            regon: "",       
            ulica: "Brak..",       
            nr_domu: "1",       
            nr_mieszkania: "",       
            kod_pocztowy: "00-000",       
            miasto: "...",       
            telefon: "",       
            email: "",       
            kr_nazwa: "",       
            kr_ulica: "",        
            kr_nr_domu: "", 
            kr_nr_mieszkania: "", 
            kr_kod_pocztowy:"00-000", 
            kr_miasto:"", 
            osoba_reprezentujaca: "", 
            informacje_dodatkowe: "",   
        };


        fetch( ConfigX.restApi + "/dash,add_edit_person" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {

            var msg = '';
            var param = json.param;

            if(json.status == "OK")
            {
                var id = json.id;
                
                this.setState({
                    loading: 1
                });
                this.getData( () => {

                        setTimeout(()=>{  //poczekaj az zakończy rendering..
                            this.redirect("det",id,"added");
                        } ,300);                   
                });              
            }
            
            if(json.status == "ERROR")
            {        
                if(param.nip) 
                {   var param_nip = param.nip;
                    var msg = param_nip.param;
                }
                if(param.id) 
                {   var param_id = param.id;
                    var msg = param_id.param;
                }
                if(param.ulica) 
                {   var param_ulica = param.ulica;
                    var msg = param_ulica.param;
                }
                if(param.kod_pocztowy) 
                {   var param_kod = param.kod_pocztowy;
                    var msg = param_kod.param;
                }      
                if(param.nr_domu) 
                {   var param_nr_domu = param.nr_domu;
                    var msg = param_nr_domu.param;
                } 
                if(param.miasto) 
                {   var param_miasto = param.miasto;
                    var msg = param_miasto.param;
                }     
                
                
             // console.log("EEE " , JSON.stringify(json) );                                  
               

                var dt = new Date();
                dt = dt.toISOString();
    
                this.setState({
                    alertMSG: msg,
                    alertMSGTime: dt
                 });
    
                return;
            }            

        });
    } 


    render()
    {
       
        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>           

               <SearchBar search_bar={this.state.search_bar} goSearch={this.goSearch.bind(this)} goClear={this.goClear.bind(this)}  changeFilter={this.changeFilter.bind(this)}/>                                     
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        }
         

        var headers = [
            { label: "LP", key: "lp" },
            { label: "Nazwisko", key: "nazwisko"},
            { label: "Imię", key: "imie"},
            { label: "E-mail", key: "email" },
          ];
            
          
            var i =0;

            var data = [];
            var newRow = {};

            this.state.rows.map(item => {           

                if(item.zg_handlowa == 1)
                {
                    newRow = {
                        lp: i+1,
                        nazwisko: item.nazwisko,
                        imie:  item.imie,
                        email: item.email,
                    }
                    data[i] = newRow; 
                    i++;
                }
            })

        const CSV = (
            <>
                <CSVLink data={data} headers={headers} separator={";"} filename={"Lista-email.csv"}>
    
                    <div className='d-flex ' >
                        <div className='link d-flex align-items-center'><img src={CsvIcon} alt="Lista e-mail CSV" style={{height:"32px"}}/>Lista użytkowników ze zgodą handlową</div>                    
                    </div>
    
                </CSVLink>
            </>                  
          )



        if(this.state.view == "add" )
        {
             return (
                 <div className='list'>                                                                       
                     <Add redirect={this.redirect.bind(this) }  apiKey={this.props.apiKey} param={this.state.param} /> 
                 </div>
             )
        }

        if(this.state.view == "det" && this.state.selectedId > 0)
        {
             return (
                 <div className='list'>                                                                       
                     <Det redirect={this.redirect.bind(this) } id={this.state.selectedId} apiKey={this.props.apiKey} param={this.state.param} /> 
                 </div>
             )
        }       


        if(this.state.rows.length >= 10)
        {
 
             return (                
                 <div className='list'>
                                         
                     <SearchBar search_bar={this.state.search_bar} goSearch={this.goSearch.bind(this)} goClear={this.goClear.bind(this)}  changeFilter={this.changeFilter.bind(this)}/>                                     
 
             

                     <SortBy  changeSort={this.changeSort.bind(this) }  result={this.state.result} />
                     <Pagger page={this.state.result.page} pages={this.state.result.pages} limit={this.state.result.limit} changePage={this.changePage.bind(this)} changeLimit={this.changeLimit.bind(this)} />
 
                     <div className='d-flex '>                        
                         <div className='buttonNewItem'  onClick={this.addPerson.bind(this)} >Dodaj osobę bez firmy</div>                                                   
                     </div>

                     <div className='mt-3'>{CSV}</div>

                     <Table rows={this.state.rows} apiKey={this.props.apiKey} search_bar={this.state.search_bar} redirect={this.redirect.bind(this)}/>
 
                     <SortBy changeSort={this.changeSort.bind(this) }    result={this.state.result} />
                     <Pagger page={this.state.result.page} pages={this.state.result.pages} limit={this.state.result.limit} changePage={this.changePage.bind(this)} changeLimit={this.changeLimit.bind(this)} />   
 
                     <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime }  msgParam="ok" onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>
           
                 </div>
             )
 
        }else
        {
 
             return (
                 <div className='list'>
  
 
                     <SearchBar search_bar={this.state.search_bar} goSearch={this.goSearch.bind(this)} goClear={this.goClear.bind(this)}  changeFilter={this.changeFilter.bind(this)}/>                                     

                     <SortBy  changeSort={this.changeSort.bind(this) }  result={this.state.result} />
                     <Pagger page={this.state.result.page} pages={this.state.result.pages} limit={this.state.result.limit} changePage={this.changePage.bind(this)} changeLimit={this.changeLimit.bind(this)} />
 
                     <div className='d-flex '>                        
                         <div className='buttonNewItem'  onClick={this.addPerson.bind(this)} >Dodaj osobę bez firmy</div>                                                   
                     </div>

                     <div className='mt-3'>{CSV}</div>

                     <Table rows={this.state.rows} apiKey={this.props.apiKey} search_bar={this.state.search_bar} redirect={this.redirect.bind(this)}/>  
 
                     <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } msgParam="ok"  onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>
                 </div>
             )
 
        }
 

        
    }
 }


 export default Main