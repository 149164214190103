


import React from 'react';
import { Link  } from 'react-router-dom';
import './styles.css';
import './bootstrap.css';

import intro1 from '../helpdesk/images/intro/intro1.PNG';


class Intro extends React.Component
 {



  render(){

    return(
      <section id="method" className="section border-no-sec">
          <h3>Strona główna</h3>
          <br/>
          <p>Strona główna usług BKP przedstawia listę szkoleń i listę badań .</p>
          <p>W górnej części strony znajdują się dane kontaktowe, mechanizmy pomagające w korzystaniu ze strony osobom niepełnosprawnym i linki do logowania / rejestracji.</p>
          <p>Niżej znajduje się menu skąd przechodzimy do listy szkoleń i listy badań. Klikając w BKP EDU przechodzimy do szkoleń, natomiast klikając w BKP LAB przechodzimy do listy badań.</p>
          <p>Logo po lewej stronie Bydgoski Klaster Przemysłowy pozwala nam cofnąć się do strony głównej.</p>

          <img src={intro1} alt=""/>

        </section>

    )
  }



}

export default Intro;