


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/ustawienia/preview/step1.PNG';


 


class preview extends React.Component
 {



  render(){

    return(
        <>
        
        <section id="method" className="section border-no-sec">
          <h3></h3>
          <br/>
          <p>Dla administratora systemu jest to dość ważna zakładka, ponieważ możemy tu zmienić swoje hasło systemowe, ustawić powiadomienia, które będą do niego przychodziły czy najważniejsze, dodać kolejnego administratora systemu.</p>
          <div className="imp-note">
            <p style={{color: "red"}}>Na samej górze pod nagłówkiem "Moje konto / Autoryzacja" znajdują się pola do zmiany hasła.</p>
            <p>Do pierwszego pola od lewej podajemy NOWE HASŁO później POWTARZAMY JE a na końcu wpisujemy AKTUALNE HASŁO po czym klikamy w przycisk "zapisz nowe hasło" znajdujący się obok pól.</p>
          </div>

          <div className="imp-note">
            <p style={{color: "red"}}>Kolejny blok służy do ustawienia powiadomień systemowych użytkownika.</p>
            <p>Do pola wpisujemy adres e-mail na który mają spływać wiadomości e-mail z różnych interakcji systemowych.</p>
            <p>Użytkownik sam ustala kiedy system ma mu wysyłać powiadomienie:<br/>
            - kiedy użytkownik zapisze się na szkolenie
            - kiedy firma wyśle wniosek na badanie
            - kiedy zostanie dodane nowe szkolenie do systemu
            - kiedy zostanie odane nowe badanie do systemu
            </p>
            <p>Wypełniamy pole, zaznaczamy lub odznaczamy opcje i klikamy "zapisz powiadomienia" pod polami.</p>
          </div>

          <div className="imp-note">
            <p style={{color: "red"}}>Ostatni blok pozwala na dodanie nowego administratora systemu. W nagłówku widzimy ilość w nawiasie już dodanych administratorów.</p>
            <p>W puste pola wpisujemy imię, nazwisko, adres e-mail, telefon i tu ważne klikamy przycisk "Dodaj" znajdujący się w tym samych rzędzie co pola do wpisywania.</p>
            <p>Po dodaniu użytkownik wskoczy do listy pozostałych administratorów systemu.</p>
            <p>W tym miejscu możemy także edytować dane administratorów z listy, wystarczy zmienić dane a system SAM ZAPISZE te informacje podczas wpisywania.</p>
            <p>Obok pozycji już dodanych możemy zaznaczyć czy dany administrator ma mieć aktywne konto w systemie czy nie.</p>
            <p>Przycisk "Wyślij hasło" służy do wygenerowania losowego przez system hasła i wysłaniu go pod adres e-mail który przypisany jest w tej pozycji w polu "E-mail".</p>
            <p>Przycisk "Usuń" usuwa administratora z systemu, nie można usunąć samego siebie.</p>
          </div>

          <img src={step1} alt=""/>

        </section>

        </>

    )
  }



}

export default preview;