import React from 'react';


import ConfigX from "../ConfigX";

class MailBox extends React.Component
 {

    myRefname = React.createRef();

    constructor(props)
    {
        super(props)

        var param = "del";
        var param_id = "-1";
        var btnClass = "buttonForm buttonAlert";
        var dlgClass = "dialog_alert";

        if(this.props.param) 
        {
            param = this.props.param;
        }

        if(this.props.param_id) 
        {
            param_id = this.props.param_id;
        }

        if(this.props.btnClass) 
        {
            btnClass = this.props.btnClass;
        }

        if(this.props.dlgClass) 
        {
            dlgClass = this.props.dlgClass;
        }

        this.state = {
            title: this.props.title,
            classButtonName: btnClass,
            classDialogName: dlgClass,
            query: this.props.query,
            param: param,
            param_id: param_id,
            view: "button",
            msgText: "" ,
            msgTitle: "" ,
            nn_msgText: "",
            nn_msgTitle: "",
            upfile: [],
            allP: 0,
        }

      
        
    }

    showDialog()
    {
        this.setState({
            view: "query"
        });
        
    }

    onClickYes(event)
    {

        var err = false;

        if(this.state.msgText.length < 2)
        {
            this.setState({
                nn_msgText: "incorrect"
            });
            err = true;
        }

        if(this.state.msgTitle.length < 2)
        {
            this.setState({
                nn_msgTitle: "incorrect"
            });
            err = true;
        }

        if(err)return;



        
        event.target.innerHTML = "...";

        var pl = new Array();

        this.state.upfile.forEach(item => {
        
            pl.push(
                {
                    uq: item.uq,
                    nazwa_wlasna: "",
                    typ: "",
                    created_at: "",
                    rozmiar: ""  
                }
            )
        })

        var dataPost = {
            sk_szkolenia_id: this.props.id,
            tytul: this.state.msgTitle,
            tresc: this.state.msgText,
            pliki: pl,
        };

        fetch( ConfigX.restApi + "/dash,msg_to_members" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
          
            if(json.status == "OK") //deleted on server..
            {
              //  event.target.innerHTML = "Wyślij E-mail";

                if(this.props.onSendSuccess != null)
                    this.props.onSendSuccess();

                    this.setState({
                        view: "button",
                        msgText: "" ,
                        msgTitle: "" ,
                        nn_msgText: "",
                        nn_msgTitle: "",
                        upfile: [],
                        allP: 0,
                    }); 
                
            }
            else
            {
                event.target.innerHTML = "Błąd!";
            }
          
        });


 

    
    }

    onClickNo(event)
    {
        this.setState({
            view: "button"
        });

       // this.props.onConfirm("no",this.state.param, this.state.param_id);
    }



    onChangeMsg(event)
    {
        var v = event.target.value;
        var ok = "correct";

        if(v.length < 2) { ok="incorrect"; }

        this.setState({
            msgText: v,
            nn_msgText: ok
        });
    }

    onChangeTitle(event)
    {
        var v = event.target.value;
        var ok = "correct";

        if(v.length < 2) { ok="incorrect"; }

        this.setState({
            msgTitle: v,
            nn_msgTitle: ok
        }); 
    }


    handleClick = () => {
        this.myRefname.current.click();
     }

     onChangeInput(event)
     {
         var handle = event.target;
         
         console.log("FileUploader, selected: " + handle.files.length );
 
         if( handle.files.length == 0) return false;     
         
         
         for(var i = 0; i < handle.files.length; i++)  
         {  
             var file = handle.files[i];
             var isAdded = false;
 
             this.state.upfile.forEach( exFile => {
                  if(exFile.name == file['name']) isAdded = true;
             });
 
             if(isAdded) continue;
 
                 var randName = crypto.getRandomValues( new Uint32Array(1)) + file['name'];
 
                 var item = {
                     name: file['name'],                
                     progress: 0,
                     status: 'start',
                     uq: randName,
                     size: '0',
                     time: '',
                 }
                 var list = this.state.upfile;
                 list.push(item);
                 this.setState({ upfile: list});
 
                 this.startUpload(file, list.length-1, randName );
         }        
 
     }


     odswiezProcent()
     {
        var proc = 0;


            this.state.upfile.forEach(item => { 
                    proc = proc + item["'progress"]
            })

            proc = proc / this.state.upfile.length;

            this.setState({
                allP: proc
            });
     }

     startUpload(file, tabIndex, randName)
     {        
         var refState = this.state;
         var refOd = this.odswiezProcent.bind(this);
 
 
             var xhr = new XMLHttpRequest();           
             xhr.upload.addEventListener('progress',function(ev)
             {        
                 var procent = (ev.loaded/ev.total );   
                 procent *= 100;
                 procent = Math.round(procent) ; 
                 
                 refState.upfile[tabIndex]['progress'] = procent;
                 refState.upfile[tabIndex]['status'] = "upload";
          
                 refOd();
                 
             }, false)
 
 
             xhr.addEventListener("load",  function(ev){               
             } , false);
 
             xhr.addEventListener("error", function(ev){}, false);
             xhr.addEventListener("abort", function(ev){}, false);
 
             xhr.onreadystatechange = function(ev)
             {                  
                   if (xhr.readyState == 4) 
                    {                                                                       
                       refState.upfile[tabIndex]['status'] = "finish";              
                       refState.upfile[tabIndex]['progress'] = 100;                    
                                           
                       var json = JSON.parse(this.responseText);
                       if(json.status && json.status == 'OK')
                       {
                         refState.upfile[tabIndex]['size'] = json.size;  
                         refState.upfile[tabIndex]['time'] = json.time;  
                         refState.upfile[tabIndex]['id'] = json.id;      
               
                         refOd();
                       }            
 
                       //refRefreshProgress();
                    }
             };
 
             xhr.open('POST', ConfigX.restApi + "/dash,file_upload", true);  
             xhr.setRequestHeader('X-API-KEY', this.props.apiKey); 
 
             var data = new FormData();      
             data.append('file'+tabIndex, file ); 
             data.append('typ', "none");
             data.append('typ_id', "-1");
             data.append('fileUploader','yes');
             data.append('uq', randName);
             xhr.send(data);
 
     }
     

     deleteFile(event)
     {      
        var uq = event.currentTarget.getAttribute("uq");
        console.log("FileUploader, delete: " + uq);


        var dataPost = {
            uq: uq,
            typ: "none",
            typ_id: "-1",
        };

        fetch( ConfigX.restApi + "/dash,file_delete" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
          
            if(json.status == "OK") //deleted on server..
            {
                var files = this.state.upfile.filter( item => {
                    if(item.uq == uq) return false;
                    return true;
                } );

                this.setState({
                    upfile: files
                })
            }
          
        });
     }





    render()
    {
        if(this.state.view == "button")
        {
            return (
                <div className={this.state.classButtonName} onClick={this.showDialog.bind(this) }>{this.state.title}</div>
            );
        }

        if(this.state.view == "query")
        {
            return (
                <div className={this.state.classButtonName}>    
                  {this.state.title} 
                  
                  <div className='info_shadow'>                        
                  <div className={this.state.classDialogName}>
                    <div className='dialog_title'>Wiadomość e-mail</div>
                    <div className='dialog_container'>
                        <div className='dialog_query'>
                          
                          <div className='simply_messanger'>
                           
                                    <div>
                                        <input type="text" value={this.state.msgTitle} className={this.state.nn_msgTitle}  placeholder="Temat:" onChange={this.onChangeTitle.bind(this) }  />
                                    </div>
                                    <br/>
                                    <textarea value={this.state.msgText} className={this.state.nn_msgText} placeholder={ "Napisz wiadomość " } onChange={this.onChangeMsg.bind(this)}></textarea>


                                    <div className="attach" >                                                                      
                                             <span>
                                                {
                                                    this.state.upfile.map(item=>{

                                                        return (
                                                            <span className="attach_item" >                                                                                                                                                                                                   
                                                                  {item.name} {item.progress} %
                                                                  <sup className="text_link_red"  uq={item.uq} onClick={this.deleteFile.bind(this)}>[usuń]</sup>                                                                 
                                                            </span>
                                                        )
                                                    })
                                                }
                                             </span>
                                             <br/><br/>
                                             <span className="text_link text_link_red"   onClick={this.handleClick.bind(this) }  >Dodaj załącznik</span>
                                             <input type="file" ref={this.myRefname}   multiple id="fileselect" name="fileselect[]"  accept="*/*" onChange={this.onChangeInput.bind(this)}  />  
                                        </div>

                            </div>
                                
                                    <div className={this.state.classButtonName} onClick={this.onClickYes.bind(this) } >Wyślij</div>
                                    &nbsp;&nbsp;
                                    <div className={this.state.classButtonName} onClick={this.onClickNo.bind(this) } >Anuluj</div>
                          
                        </div>

                    </div>
                  </div>
                  </div>
                </div>
            );
        }
    }
 }

 export default MailBox;