import React from 'react';

import ConfigX from '../../../ConfigX'
import ButtonConfirm from '../../../components/ButtonConfirm';
import BackPNG from './../../../image/back.png'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TipMessage from '../../../components/TipMessage';

import AlertMsg from '../../../components/AlertMsg';

import loading from '../../../image/loading.gif'

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

 
class Add extends React.Component
 {
        constructor(props)
        {
            super(props)
 
            this.state = {

                imie: "",
                nazwisko: "",
                email: "",
                telefon: "",
                zgoda: "",
                czlonek_bkp: false,

                nn_imie: "",                
                nn_nazwisko: "",
                nn_email: "",
                nn_telefon: "",            
                czlonek_bkp_ok: "",

                loading: 0,
                msg: '',

                alertMSG: '',
                alertMSGTime: 0,                

            };

   
        }


        componentDidMount()
        {             
        
        }


        componentDidUpdate()
        { 
                      
        }
 
        onChangeImie(event)
        {
            var v = event.target.value;
            var ok = "";

            if(v.length < 3) {ok = "incorrect"; } else ok = "correct";            

            this.setState({
                imie: v,
                nn_imie: ok
            });
        }

        onChangeNazwisko(event)
        {
            var v = event.target.value;
            var ok = "";
            
            if(v.length < 3) {ok = "incorrect"; } else ok = "correct";            

            this.setState({
                nazwisko: v,
                nn_nazwisko: ok
            });
        }

        onChangeEmail(event)
        {
            var v = event.target.value;
            var ok = "";
            
            var malpa = v.indexOf("@"); //sprawdzenie czy to faktycznie adres e-mail

            if(v.length < 7 || malpa == -1) {ok = "incorrect"; } else ok = "correct";            

            this.setState({
                email: v,
                nn_email: ok
            });
        }

        onChangeTelefon(event)
        {
            var v = event.target.value;

          //  v = v.replace(/[^0-9]/g, ""); //wywal litery...
            v = v.replace(" ","");

            var ok = "";
            
            if(v.length < 7) {ok = "incorrect"; } else ok = "correct";            

            this.setState({
                telefon: v,
                nn_telefon: ok
            });
        }

  
        
        onChangeCzlonekBKP(event)
        {
         
            this.setState({
                czlonek_bkp: !this.state.czlonek_bkp,
                czlonek_bkp_ok: ""
             });
        }


        onDialogOK(param)
        {
            //console.log("click ok dialog");
                this.setState({
                    alertMSG: '',
                    alertMSGTime: 0,
                });
        }

        onSubmitForm(event)
        {
           // event.preventDefault();


           var wait = setTimeout(
            () => {
               this.setState({ loading: 0 });
            }
           ,500);


            var err = false;
            var iter = 0;
            var komunikat = '';

            if(this.state.imie.length < 3 || this.state.nn_imie == "incorrect") {

                komunikat = 'Imię musi posiadać przynajmniej 3 znaki.';                        

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_imie: "incorrect"
                });                  
                return;
            }

            if(this.state.nazwisko.length < 3 || this.state.nn_nazwisko == "incorrect") {

                komunikat = 'Nazwisko musi posiadać przynajmniej 3 znaki.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_nazwisko: "incorrect"
                });                  
                return;
            }

            if(this.state.telefon.length < 1 || this.state.nn_telefon== "incorrect") {

                komunikat = 'Podaj prawidłowy numer telefonu.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_telefon: "incorrect"
                });                   
             return;
            }

            if(this.state.email.length < 7 || this.state.nn_email== "incorrect") {

                komunikat = 'Adres e-mail musi posiadać przynajmniej 7 znaków.';

                var malpa = this.state.email.indexOf("@");

                if(malpa == -1)komunikat = 'Podaj prawidłowy adres e-mail.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_email: "incorrect"
                });                  
                return;
            }


            // if(this.state.dane.termin == 'empty' || this.state.nn_termin== "incorrect") {

            //     this.setState( {nn_termin: "incorrect" } );
            //     err = true;   
            // }

            
            if(this.state.czlonek_bkp) var BKP_mem = 1; else var BKP_mem = 0;
            if(this.state.zgoda) var zgoda = 1; else var zgoda = 0;


            if(err) return;
        
            var dataPost = {

                imie: this.state.imie,
                nazwisko: this.state.nazwisko ,
                email: this.state.email,
                telefon: this.state.telefon,

                czlonek_bkp: BKP_mem,
                zgoda_handlowa: zgoda,

            };     



            // if(this.state.dane.termin)
            // {
            //     dataPost['termin'] = this.state.dane.termin;              
            // }else{
            //     dataPost['termin'] = this.state.nn_termin;
            // }
 

            fetch( ConfigX.restApi + "/dash,add_person" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
                
                clearTimeout(wait);

                var param = json.param;

                 if(json.status == "OK")
                 {
                    this.props.redirect("list",json.id);
                 }else
                 {
                    var dt = new Date();
                    dt = dt.toISOString();
                    err = true;
        
                    this.setState({
                        alertMSG: json.param,
                        alertMSGTime: dt,                
                    });                  
                    return;
                 }
                
            })
          
     
        }

        onBackClick()
        {     
          //  console.log("back: ",this.state.back);

                this.props.redirect("list", this.props.id);
       
            
        }

        onConfirm()
        {
            this.onSubmitForm();
        }


        onChangeCzlonekBKP(event)
        {
         
            this.setState({
                czlonek_bkp: !this.state.czlonek_bkp,
                czlonek_bkp_ok: ""
             });
        }
 
        onChangeZgoda(event)
        {
         
            this.setState({
                zgoda: !this.state.zgoda,
             });
        }

        render()
        {   

            if(this.state.loading == 1) 
            {

                return (


                <div className="_editor">
                    <div className='info_shadow'>
                        
                    <div className='modal'>
                        <div class='modal-dialog'>
        
                        <div class='modal-content'>
        
                            <div className='modal-body'>
                                <div className='loading'><img src={loading} /><div>Proszę czekać.</div></div>
                            </div>

                        </div>
                        </div>
                      </div>
                    </div>
                </div>
                     


               
                )
            }

            var msg = '';
       

            return (


                <>  
 
                <div className='details'>
                  
                    <div className='location' onClick={this.onBackClick.bind(this) }>
                        <img src={BackPNG} /> Powrót do listy osób
                    </div>
                 
                                    

                    <TipMessage  msg={msg} type="notice" />
                  

                    <div className='dashForm'>
                           <h3>Dodaj użytkownika</h3>

                            <br/>

                            <div className='FormField inlineField'>      
                                <input type="checkbox" name='czlonek_bkp' defaultChecked={this.state.czlonek_bkp}  onChange={this.onChangeCzlonekBKP.bind(this)} />                             
                                &nbsp; <span  className={"form-check-input " + this.state.czlonek_bkp_ok } >&nbsp;Członek BKP </span>
                            </div>


                            <div className='FormField inlineField'>      
                                <input type="checkbox" name='czlonek_bkp' defaultChecked={this.state.zgoda}  onChange={this.onChangeZgoda.bind(this)} />                             
                                &nbsp; <span  className={"form-check-input " } >&nbsp;Zgoda na informacje handlowe </span>
                            </div>



                            <div className='FormField '>
                               <label>Imie<span className='required'>*</span></label>
                               <input type="text" className={"field300 " + this.state.nn_imie } value={this.state.imie}  col="" onChange={this.onChangeImie.bind(this) } />
                            </div>


                            <div className='FormField '>
                               <label>Nazwisko<span className='required'>*</span></label>
                               <input type="text" className={"field300 " + this.state.nn_nazwisko } value={this.state.nazwisko}  col="" onChange={this.onChangeNazwisko.bind(this) } />
                            </div>

                    

                            <div className='FormField inlineField'>
                                <label>Telefon<span className='required'>*</span></label>
                                <input type="text" className={"field300 " + this.state.nn_telefon } value={this.state.telefon}  col="" onChange={this.onChangeTelefon.bind(this) } />
                            </div>


                            <div className='FormField inlineField'>
                                <label>E-mail<span className='required'>*</span></label>
                                <input type="text" className={"field400 " + this.state.nn_email } value={this.state.email}  col="" onChange={this.onChangeEmail.bind(this) } />
                            </div>
             


                            <div className='FormField FormButtons'>
                                <div className='buttonForm' onClick={this.onSubmitForm.bind(this)}>zapisz</div>  
                            </div>

                    </div>

                    <div className='location' onClick={this.onBackClick.bind(this) }>
                      <img src={BackPNG} /> Powrót do listy osób
                    </div>

                </div>

                <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime }  msgParam="ok" onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>

                </>

            )


        }
 }

 export default Add;