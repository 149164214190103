import React from 'react';

import ConfigX from '../../../ConfigX'
import Messanger from '../../../components/Messanger';
import AlertYesNo from '../../../components/AlertYesNo';
import ButtonConfirm from '../../../components/ButtonConfirm';


class DetUczestnicyRow extends React.Component
 {
        constructor(props)
        {
            super(props)

            this.state = {
                alertMSG: '',
                alertMSGTime: 0,
                alertParam1: '',
                alertParam2: '',
            }
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onClickUczestnik(id);
        }

        onChangeStatus(event)
        {      
            var dt = new Date();
            dt = dt.toISOString();

            var stTmp = event.target.value ;
            if(stTmp == 'potwierdzone') { stTmp = 'POTWIERDZONE'; }
            if(stTmp == 'odrzucone_brak_miejsc') { stTmp = 'ODRZUCONE - BRAK MIEJSC'; }
            if(stTmp == 'odrzucone_odwolanie_szkolenia') { stTmp = 'ODRZUCONE - SZKOLENIE ODWOŁANE'; }
            if(stTmp == 'wypisany') { stTmp = 'UCZESTNIK WYPISANY'; }

            this.setState({
                alertMSG: 'Zmienić status na: ' + stTmp + "? ",
                alertMSGTime: dt,
                alertParam1:event.target.value,
                alertParam2:  this.props.row.id
             }); 
            
           // this.props.onChangeStatusUczestnik(event.target.value, this.props.row.id);
        }

        onDialogYes(msgParam, param1, param2)
        {      

            this.props.onChangeStatusUczestnik(param1, param2);
        }


        delUczestnik(type, param)
        {
            if(type != "yes")  return;

            const text = param.split(" ");
            let px_group_id = text[0];
            let sk_szkolenia_id = text[1];

            this.props.delUczestnik(type, px_group_id, sk_szkolenia_id); 
        }


        onChangeStatusFrekwencja(event)
        {
            var val = event.target.value;
            var id = event.target.getAttribute("id");

            this.props.onChangeStatusFrekwencja(val, id, this.props.row.id);
        }

        render()
        {
  
            var rozliczone = "nie";

            if(this.props.row.rozliczone == 1) rozliczone = "tak";

           // let info_dodatkowe = this.props.row.informacje_dodatkowe;

            var rowStyle = "";

           
            if(this.props.row.status == "potwierdzone" && rozliczone != "tak") 
            {                
                        rowStyle = "row_green";
                        return (                 
                                    <tr className={ 'rowLink ' + rowStyle} >                                        

                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.num}</td>
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.nazwisko}   </td>                                                      
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.imie}</td>                                                      
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="">{this.props.row.email}</td>                                                      
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.row.telefon}     </td>                                                                     
                                        <td id={this.props.row.id} className="text_center">
                                            <select defaultValue={this.props.row.status}  onChange={this.onChangeStatus.bind(this) }>           
                                               <option value="wypisany">wypisany</option>                                  
                                               <option value="potwierdzone">potwierdzone</option>                                                                                                                                     
                                            </select> 

                                            <AlertYesNo msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Potwierdź" alertParam1={this.state.alertParam1} alertParam2={this.state.alertParam2} onDialogYes={this.onDialogYes.bind(this) } />                

                                        </td>                                                                        
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{rozliczone}</td>                                                                        
                                        <td id={this.props.row.id}   className="">
                                            <ul className='listInnerTab'>
                                            {
                                                this.props.row.terminy.map( (item,index) => {

                                                    var roz = " [nierozliczone ❗❗ ] ";

                                                    return (
                                                        <li key={index}>                                                            
                                                            <select defaultValue={item.frekwencja} id={item.id} onChange={this.onChangeStatusFrekwencja.bind(this) }  >
                                                                <option value="-">-</option>
                                                                <option value="obecny">obecny</option>
                                                                <option value="nieobecny" >nieobecny</option>
                                                            </select>
                                                            &nbsp;&nbsp;
                                                            {item.termin.data_od}    
                                                            {roz}                                
                                                        </li>
                                                    )
                                                } )                                    
                                            }
                                            </ul>
                                        </td> 
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">
                                        {
                                            this.props.row.terminy.map( (item,index) => {
                                                return ( 
                                                    item.informacje_dodatkowe
                                                )
                                            })
                                        }
                                        </td>  

                                        
                                                                                                          
                                    </tr>                 
                        )
           }


           if(this.props.row.status == "potwierdzone" && rozliczone == "tak") 
           {                
                       rowStyle = "row_green";
                       return (                 
                                   <tr className={ 'rowLink ' + rowStyle} >

                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.num}</td>
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.nazwisko}   </td>                                                      
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.imie}</td>                                                      
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="">{this.props.row.email}</td>                                                      
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.row.telefon}     </td>                                                                     
                                       <td id={this.props.row.id} className="text_center">
                                           Potwierdzone / Opłacone                     
                                           <AlertYesNo msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Potwierdź"  alertParam1={this.state.alertParam1} alertParam2={this.state.alertParam2} onDialogYes={this.onDialogYes.bind(this) }  />  
                                       </td>                                                                        
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{rozliczone}</td>                                                                        
                                       <td id={this.props.row.id}   className="">
                                           <ul className='listInnerTab'>
                                           {
                                               this.props.row.terminy.map( (item,index) => {

                                                var roz = " [rozliczone 💰] ";
                                        

                                                   return (
                                                       <li key={index}>                                                            
                                                           <select defaultValue={item.frekwencja} id={item.id} onChange={this.onChangeStatusFrekwencja.bind(this) }  >
                                                               <option value="-">-</option>
                                                               <option value="obecny">obecny</option>
                                                               <option value="nieobecny" >nieobecny</option>
                                                           </select>
                                                           &nbsp;&nbsp;
                                                           {item.termin.data_od}                                    
                                                           {roz}                                    
                                                       </li>
                                                   )
                                               } )                                    
                                           }
                                           </ul>
                                       </td>
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">
                                        {
                                            this.props.row.terminy.map( (item,index) => {
                                                return ( 
                                                    item.informacje_dodatkowe
                                                )
                                            })
                                        }
                                        </td>  

                                        
                                   </tr>                 
                       )
          }

           if(this.props.row.status != "potwierdzone" && this.props.row.status != "wypisany") 
           {                       
                       return (                 
                                   <tr className={ 'rowLink ' + rowStyle} >

                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.num}</td>
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.nazwisko}  </td>                                                      
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.imie}</td>                                                      
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="">{this.props.row.email}</td>                                                      
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.row.telefon}     </td>                                                                     
                                       <td id={this.props.row.id} className="text_center">
                                           <select defaultValue={this.props.row.status} onChange={this.onChangeStatus.bind(this) }>
                                               <option value="oczekuje">oczekuje</option>
                                               <option value="potwierdzone">potwierdzone</option>
                                               <option value="odrzucone_brak_miejsc">brak miejsc </option>
                                               <option value="odrzucone_odwolanie_szkolenia">odwołano szk.</option>
                                           </select>                                
                                           <AlertYesNo msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Potwierdź"  alertParam1={this.state.alertParam1} alertParam2={this.state.alertParam2} onDialogYes={this.onDialogYes.bind(this) }  />   
                                       </td>                                                                        
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">&nbsp;</td>                                                                        
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="">&nbsp;</td>
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">
                                        {
                                            this.props.row.terminy.map( (item,index) => {
                                                return ( 
                                                    item.informacje_dodatkowe
                                                )
                                            })
                                        }
                                        </td> 

                                        <td className="text_center"> <ButtonConfirm   onConfirm={this.delUczestnik.bind(this) } param={this.props.row.px_group_id +" "+ this.props.row.sk_szkolenia_id} title="usuń*" query="Na pewno chcesz usunąć tego uczestnika?"  />  </td>
                                   </tr>                 
                       )
          }


          if( this.props.row.status == "wypisany") 
          {                       
                      return (                 
                                  <tr className={ 'rowLink ' + rowStyle} >

                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.num}</td>
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.nazwisko} </td>                                                      
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.imie}</td>                                                      
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="">{this.props.row.email}</td>                                                      
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.row.telefon}     </td>                                                                     
                                      <td id={this.props.row.id} className="text_center">
                                          <select defaultValue={this.props.row.status} onChange={this.onChangeStatus.bind(this) }>
                                                <option value="wypisany">wypisany</option>                       
                                          </select>  
                                          <AlertYesNo msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Potwierdź"  alertParam1={this.state.alertParam1} alertParam2={this.state.alertParam2} onDialogYes={this.onDialogYes.bind(this) }  />                                
                                           
                                      </td>                                                                        
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{rozliczone}</td>                                                                        
                                        <td id={this.props.row.id}   className="">
                                            <ul className='listInnerTab'>
                                            {
                                                this.props.row.terminy.map( (item,index) => {
                                                    return (
                                                        <li key={index}>                                                            
                                                            <select defaultValue={item.frekwencja} id={item.id} onChange={this.onChangeStatusFrekwencja.bind(this) }  >
                                                                <option value="-">-</option>
                                                                <option value="obecny">obecny</option>
                                                                <option value="nieobecny" >nieobecny</option>
                                                            </select>
                                                            &nbsp;&nbsp;
                                                            {item.termin.data_od}                                    
                                                        </li>
                                                    )
                                                } )                                    
                                            }
                                            </ul>
                                        </td> 
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">
                                        {
                                            this.props.row.terminy.map( (item,index) => {
                                                return ( 
                                                    item.informacje_dodatkowe
                                                )
                                            })
                                        }
                                        </td> 

                                        <td className="text_center"> <ButtonConfirm   onConfirm={this.delUczestnik.bind(this) } param={this.props.row.px_group_id +" "+ this.props.row.sk_szkolenia_id} title="usuń*" query="Na pewno chcesz usunąć tego uczestnika?"  />  </td>
                                  </tr>                 
                      )
         }



        }
 }

 export default DetUczestnicyRow;