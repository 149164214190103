import React from 'react';

class ButtonConfirm extends React.Component
 {
    constructor(props)
    {
        super(props)

        var param = "del";
        var param_id = "-1";
        var btnClass = "buttonForm buttonAlert";
        var dlgClass = "dialog_alert";
        var is_query = "1";
        var query = "";

        if(this.props.param) 
        {
            param = this.props.param;
        }

        if(this.props.param_id) 
        {
            param_id = this.props.param_id;
        }

        if(this.props.btnClass) 
        {
            btnClass = this.props.btnClass;
        }

        if(this.props.dlgClass) 
        {
            dlgClass = this.props.dlgClass;
        }

        if(this.props.class) //całkiem inna klasa
        {
            btnClass = this.props.class;
        }
        if(this.props.query)
        {
            query = this.props.query;
        }
        if(this.props.is_query)
        {
            is_query = this.props.is_query;
        }

        this.state = {
            title: this.props.title,
            classButtonName: btnClass,
            classDialogName: dlgClass,
            query: query,
            param: param,
            param_id: param_id,
            view: "button",
            is_query: is_query,
        }

      
        
    }

    showDialog()
    {
        this.setState({
            view: "query"
        });
        
    }

    onClickYes()
    {
        this.setState({
            view: "button"
        }); 

        this.props.onConfirm("yes",this.state.param, this.state.param_id);
    }

    onClickNo()
    {
        this.setState({
            view: "button"
        });

        this.props.onConfirm("no",this.state.param, this.state.param_id);
    }

    render()
    {
        if(this.state.view == "button")
        {
            if(this.state.is_query == 1)
            {
                return (
                    <div className={this.state.classButtonName} onClick={this.showDialog.bind(this)} >{this.state.title}</div>
                );
            }else{
                return (
                    <div className={this.state.classButtonName} >{this.state.title}</div>
                );
            }
        }

        if(this.state.view == "query")
        {
            return (
                <div className={this.state.classButtonName}>    
                  {this.state.title}                         
                  <div className={this.state.classDialogName}>
                    <div className='dialog_title'>Powiadomienie</div>
                    <div className='dialog_container'>
                        <div className='dialog_query'>{this.state.query}</div>
                        <div className={this.state.classButtonName} onClick={this.onClickYes.bind(this) } >Tak</div>
                        &nbsp;&nbsp;
                        <div className={this.state.classButtonName} onClick={this.onClickNo.bind(this) } >Nie</div>
                    </div>
                  </div>
                </div>
            );
        }
    }
 }

 export default ButtonConfirm;