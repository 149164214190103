


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/osoby/del/step1.PNG';
import step2 from '../../helpdesk/images/osoby/del/step2.PNG';
 


class DelOsoba extends React.Component
 {



  render(){

    return(
        <>
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>Aby usunąć użytkownika przechodzimy w menu "Baza osób" i klikamy w danego użytkownika.</p>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>W widoku szczegółowych informacji musimy poszukać czerwonego przycisku "usuń z systemu".</p>
          <div className="imp-note"><p>Uwaga! Tak jak wskazuje komunikat, użytkownika nie możemy usunąć gdy: <br/><br/>
            *użytkownikiem jestem ja<br/>
            *użytkownikiem jest administrator systemu<br/>                   
          </p></div>


          <img src={step2} alt=""/>

        </section>


        </>

    )
  }



}

export default DelOsoba;