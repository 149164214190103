


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/osoby/edit/step1.PNG';
import step2 from '../../helpdesk/images/osoby/edit/step2.PNG';
import step3 from '../../helpdesk/images/osoby/edit/step3.PNG';
import step4 from '../../helpdesk/images/osoby/edit/step4.PNG';
import step5 from '../../helpdesk/images/osoby/edit/step5.PNG';
import new_account from '../../helpdesk/images/osoby/edit/new_account.PNG';

 


class EditOsoba extends React.Component
 {



  render(){

    return(
        <>
        
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>Baza osób to miejsce gdzie możemy zarządzać użytkownikami systemu. Są to i użytkownicy zarejestrowani do systemu i uczestnicy szkoleń czy badań.</p>
          <p>Przy każdym imieniu i nazwisku ikona z kopertą oznacza, że można w tym miejscu napisać wiadomość e-mail do danego użytkownika poprzez panel administracyjny.</p>
          <p>Chcąc wejść w tryb edycji użytkownika, klikamy w jego pozycję na liście.</p>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>W trybie edycji edytujemy informacje szczegółowe danego użytkownika. </p>

          <div className="imp-note"> <p style={{color: "red", fontWeight: "bold"}}>Trzeba pamiętać, że system w momencie rejestracji do systemu użytkownika tworzy i przypisuje mu także firmę z nazwą jego imienia i nazwiska (co widać na zrzucie ekranu Jan Kowalski powiązany jest Janem Kowalskim, czyli firmą o jego imieniu i nazwisku) z NIPem 0 . W momencie kiedy zaktualizuje swoje dane firmowe, czyli wypełni NIP, nazwę itd. to system utworzy mu kolejną firmę z tymi danymi jeżeli nie ma jej w systemie.</p></div>
          <img src={new_account} alt='' style={{height: "800px",width: "700px"}} />

          <p>Nad polami imienia i nazwiska znajduje się ważny mechanizm do powiązywania użytkownika z firmą / podmiotem. Użytkownik zarejestrowany do systemu, który nie wpisał swoich danych firmowych, traktowany jest jako zwykły użytkownik bez firmy, czyli przypisany jest do swojej firmy bez danych firmowych. Osoba prywatna nie powinna się przejmować tymi danymi.</p>
          <div className="imp-note"><p>Firma bez danych firmowych i z NIP 0 w trakcie rejestracji użytkownika tworzona jest tylko w razie gdy użytkownik chciałby zarejestrować się jako firma.</p></div>

          <p>Poniżej widzimy przyciski do edycji i pole do przypisywania różnych dokumentów użytkownikowi.</p>

          <img src={step2} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 3</h3>
          <br/>
          <p>Chcąc przypisać użytkownika do firmy klikamy w nazwę podmiotu po czym pojawia nam się lista firm do wyboru.</p>
          <p>Na liście widać pozycje z nazwą firmy, adresem i pozycje z imieniem i nazwiskiem bez adresu co sugeruje, że te firmy nie posiadają NIPu, czyli są to firmy zwykłych użytkowników.</p>
          <p>Z tego miejsca możemy także dodać nową firmę klikając w przycisk "Nowy podmiot" o czym w kolejnym kroku.</p>

          <img src={step3} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 4</h3>
          <br/>
          <p>Dodając nową firmę w celu przypisania jej do użytkownika wypełniamy wszystkie pola po czym klikamy zapisz.</p>          

          <img src={step4} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 5</h3>
          <br/>
          <p>Jak widać po dodaniu nowej firmy i zapisaniu jej od razu przypisała się do tego użytkownika. Pamiętajmy także, że należy wypełniać wszystkie pola z czerwoną gwiazdką.</p>

          <img src={step5} alt=""/>

        </section>

        </>

    )
  }



}

export default EditOsoba;