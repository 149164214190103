


import React from 'react';
import { Link  } from 'react-router-dom';


import step1  from '../../helpdesk/images/trainings/addtraining/step1.PNG';
import step2  from '../../helpdesk/images/trainings/addtraining/step2.PNG';
import step2a from '../../helpdesk/images/trainings/addtraining/step2a.PNG';
import step3  from '../../helpdesk/images/trainings/addtraining/step3.PNG';
import step4  from '../../helpdesk/images/trainings/addtraining/step4.PNG';
import step5  from '../../helpdesk/images/trainings/addtraining/step5.PNG';
 


class AddTraining extends React.Component
 {



  render(){

    return(
        <>
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <div className="imp-note"><p>Dodać szkolenie może każdy administrator systemu.</p></div>
          <p>Aby dodać nowe szkolenie przechodzimy w menu "Szkolenia" i szukamy w prawym górym rogu zielonego przycisku "Nowe szkolenie".</p>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>Na samej górze widzimy pasek postępu z liczbą zajętych miejsc. Dalej widoczne są pola z czerwoną gwiazdką, które nalezy wypełnić żeby dodać szkolenie.</p>
          <div className="imp-note"><p>Uwaga! Należy pamiętać, że aby szkolenie pojawiło się na liście szkoleń trzeba ustawić status "Widoczne".</p></div>

          <img src={step2} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 2a</h3>
          <br/>

          <div className="imp-note"><p>Uwaga! Wprowadzono aktualizację systemową i pod wyborem podmiotu prowadzącego dodano pole wyboru z opcją czy to szkolenie ma być bezterminowe, czyli możliwość wystawienia szkolenia nie znając jeszcze terminu .</p></div>
          <p>Po wyborze opcji "TAK" system pozwala na wpisanie własnej treści o terminie szkolenia poprzez pojawienie się pola tekstowego i wprowadzenie informacji. </p>                    

          <img src={step2a} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 3</h3>
          <br/>
          <p>Idąc niżej widzimy główne przyciski do zapisu i usuwania tego szkolenia. Dalej dodajemy termin szkolenia, jest to ważna opcja, która musi zostać wypełniona, później dodajemy program szkolenia i pliki z których będą mogli korzystać uczestnicy zapisujący się na szkolenie np. (regulaminy, zdjęcia, dokumenty)</p>
          <p>Patrząc niżej widzimy postanowienia, czyli zgody, które zaznacza uczestnik zapisując się na szkolenie. wpisujemy treść postanowienia i zaznaczamy czy ma to być zgoda wymagana do zaznaczenia przez uczestnika czy nie.</p>

          <img src={step3} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 4</h3>
          <br/>
          <p>Niżej również ważne miejsce, znajduje się tu lista uczestników, zapisanych na to szkolenie. Ikony ponad tabelą pozwalają na generowanie dokumentów, które będą tyczyły się tylko tych uczestników.</p>

          <img src={step4} alt=""/>

        </section>



        <section id="method" className="section border-no-sec">

          <h3>Krok 5</h3>
          <br/>
          <p>Po zapisie szkolenia widzimy, że kolejna pozycja pojawia się na liście szkoleń.</p>

          <img src={step5} alt=""/>

        </section>

        </>

    )
  }



}

export default AddTraining;