import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfigX from '../../../ConfigX';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';


registerLocale('pl', pl)


class SearchBar extends React.Component
 {
        constructor(props)
        {
            super(props)  

            this.state = {          
                szkolenia: [],                     
             }     
                                  
        }
        componentDidMount()
        {
            var dataPost = {};
        
            fetch( ConfigX.restApi + "/dash,getSzkoleniaCombo" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
       
                 this.setState({
                    szkolenia: json,
                    loading: 0
                 }); 
                                                
            });  
        
        }

        onChangeNazwaFirmy(event)
        {                          
            this.props.changeFilter('nazwa_firmy', event.target.value);
        }

        onChangeNIP(event)
        {                          
            this.props.changeFilter('nip', event.target.value);
        }

      
        onChangeSzkolenie(event)
        {           
            this.props.changeFilter('id_szkolenia',  event.target.value );
        }

        onChangeDateFrom(event)
        {
            this.props.changeFilter('od', event);
        } 

        onChangeDateTo(event)
        {
            this.props.changeFilter('do', event);
        }

        onChangeTyp(event)
        {
            this.props.changeFilter('typ', event.target.value);
        }

        onChangeCzlonekBKP(event)
        {
            this.props.changeFilter('czlonek_bkp', event.target.value);
        }

        goSearch()
        {
            this.props.goSearch();
        }

        goClear()
        {             
            this.props.goClear();
        }


        render()
        { 

            return (
                <div className='search_bar'>

                    <div className='filter'>
                          <div className='filter_label'>Nazwa firmy</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.nazwa_firmy} onChange={this.onChangeNazwaFirmy.bind(this)}    />
                          </div>                                 
                    </div>

                    <div className='filter'>
                          <div className='filter_label'>NIP</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.nip} onChange={this.onChangeNIP.bind(this)}    />
                          </div>                                 
                    </div>

                    <div className='filter'>
                          <div className='filter_label'>Szkolenie</div>
                          <div className='filter_content'>
                              <select value={this.props.search_bar.id_szkolenia}  onChange={this.onChangeSzkolenie.bind(this) }  >
                                { 
                                  this.state.szkolenia.map((row,index)=>{
                                    return(
                                        <option key={index} value={row.id}>{row.tytul}</option>
                                    )
                                  })                                
                                }                                       
                               </select>
                          </div>                                 
                    </div>                   
             
             
                    <div className='filter'>
                          <div className='filter_label'>Data szkolenia</div>
                          <div className='filter_content'>
                                <DatePicker
                                selected={this.props.search_bar.od}
                                onChange={this.onChangeDateFrom.bind(this)}
                                showTimeSelect
                                dateFormat="yyyy-MM-dd HH:ii"
                                locale="pl"
                                />                                                                                         
                          </div>
                          <div className='filter_label'> - </div>
                          <div className='filter_content'>
                                <DatePicker
                                selected={this.props.search_bar.do}
                                onChange={this.onChangeDateTo.bind(this)}
                                showTimeSelect
                                dateFormat="yyyy-MM-dd HH:ii"
                                locale="pl"
                                />                                                                                         
                          </div>
                    </div>
 
                    <div className='filter'>
                          <div className='filter_label'>Typ</div>
                          <div className='filter_content'>
                              <select value={this.props.search_bar.typ} onChange={this.onChangeTyp.bind(this) }  >                               
                                  <option value="">Wybierz</option>                                                                     
                                  <option value="firma">Firma</option>                                                                     
                                  <option value="os_pryw">Osoba prywatna</option>                                                                     
                               </select>
                          </div>                                 
                    </div> 

                    <div className='filter'>
                          <div className='filter_label'>Członek BKP</div>
                          <div className='filter_content'>
                              <select value={this.props.search_bar.czlonek_bkp} onChange={this.onChangeCzlonekBKP.bind(this) }  >                               
                                  <option value="">Wybierz</option>                                                                     
                                  <option value="1">TAK</option>                                                                     
                                  <option value="0">NIE</option>                                                                     
                               </select>
                          </div>                                 
                    </div> 

                    <div className='filter filterButton'>

                         {/*  <div className='buttonSearch' onClick={this.goSearch.bind(this)}>Szukaj</div>   */}                            
                           <div className='buttonSearch' onClick={this.goClear.bind(this) }>Wyczyść</div>                               
                    </div>
           

                </div>
            )
        }
 }

 export default SearchBar;