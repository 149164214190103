import React from "react";
import ConfigX from '../ConfigX'

import downloadPNG from '../image/download.png'

class FileLister extends React.Component
{
    url_upload   = "";
    url_download = "";  
    url_get = "";  
    apiKey       = "";

    typ          = "news";
    typ_id       = 99999; 

    constructor(props)
    {
        super(props)
      
        this.state = {                     
           nazwa: this.props.fileName,                                             
           uq: this.props.uq,
           statusAr: []
        }

        this.url_upload   = ConfigX.restApi + "/dash,file_upload";
        this.url_download = ConfigX.restApi + "/dash,file_list";  
        this.url_get = ConfigX.restApi + "/dash,file_download";  
        this.url_delete   = ConfigX.restApi + "/dash,file_delete";  
        this.apiKey       = this.props.apiKey;
        this.typ          = this.props.typ;
        this.typ_id       = this.props.typ_id;    
    }


    onDownload(event)
    {      
        console.log("FileUploader, download: " + this.state.uq);

      

        var ff = this.state.statusAr;

        if(ff['down_start']) return;

        ff['down_start'] = true;
        this.setState( { statusAr: ff } );

        var dataPost = {
            uq: this.state.uq,
            typ: this.typ,
            typ_id: this.typ_id,
        };

        fetch( this.url_get , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
          
            if(json.status == "OK") 
            {                            
                var ff = this.state.statusAr;
                ff['down_end'] = new Array();
                ff['down_end']['b64'] =  json.b64 ;
                ff['down_end']['mime'] = json.mime;
                this.setState( { statusAr: ff } );
            }else
            {  
                console.log("błąd pobrania");
                var ff = this.state.statusAr;
                ff['error'] = new Array() ;
                this.setState( { statusAr: ff } );
            }
          
        });

    }


    render()
    {

        
        var downloading = <img src={downloadPNG} /> 

        if(this.state.statusAr["down_start"])   var downloading = "czekaj...";
        if(this.state.statusAr["down_end"])  
        {
             var downloading = "pobierz";
             downloading = <a target="_blank" download={this.state.uq} href={"data:"+ this.state.statusAr["down_end"]["mime"] + ";base64, " + encodeURI(this.state.statusAr["down_end"]["b64"]) }>pobierz</a>
        }

        if(this.state.statusAr["error"])   var downloading = "Błąd";

        return (
            <tr>                              
                <td> <span  uq={this.state.uq}   onClick={this.onDownload.bind(this) }  > {downloading} </span>  <span  uq={this.state.uq}   onClick={this.onDownload.bind(this) }  > {this.state.nazwa}</span> </td>
            </tr>
        )
    }
}

export default FileLister