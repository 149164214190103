


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/trainings/members/step1.PNG';
import step2 from '../../helpdesk/images/trainings/members/step2.PNG';
import step3 from '../../helpdesk/images/trainings/members/step3.PNG';
import step4 from '../../helpdesk/images/trainings/members/step4.PNG';
 


class Members extends React.Component
 {



  render(){

    return(
        <>
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <div className="imp-note"><p>Lista uczestników znajduje się po wejściu w szczegóły szkolenia na samym dole strony. Osoba która zapisała się na to właśnie szkolenie znajdzie się na liście ze statusem "oczekuje". Co ważne tylko w tym momencie uczestnik ten może zostać usunięty przez administratora z listy uczestników.</p></div>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>Uczestnikowi nowo zapisanemu możemy zmienić status. Jak widać na zrzucie są cztery statusy do wyboru: oczekuje, potwierdzone, brak miejsc, odwołano szkolenie.</p>
          <p>
            - "oczekuje" to status przypisany dla uczestnika nowego, co do którego administrator nie wykonał żadnych czynności<br/>
            - "potwierdzone", czyli administrator potwierdza przybycie na szkolenie tego uczestnika<br/>
            - "brak miejsc", czyli status w którym kiedy administratorzy potwierdzą już wystarczającą ilość miejsc według zgłoszeń<br/>
            - "odwołano szk.", ustawiamy ten status kiedy najzwyczajniej odwoła się szkolenie<br/>
          </p>

          <img src={step2} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 3</h3>
          <br/>
          <p>Po potwierdzeniu uczestnictwa widzimy, że przy pozycji pojawiły się nowe opcje. W kolumnie "rozliczone", czyli czy uczestnik rozliczył się za szkolenie widnieje "nie". W kolumnie "frekwencja" ustawiamy obecność uczestnika i tu pojawiła się opcja z wyborem obecny / nieobecny.</p>

          <img src={step3} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 4</h3>
          <br/>
          <p>Przy zmianie statusu, uczestnik otrzymuje wiadomość e-mail z informacją o zmianie.             
          </p>
          <p>Ostatnia pozycja wskazuje uczestnika już rozliczonego ze statusem Potwierdzony / Opłacony.</p>
          <p>Aby rozliczyć uczestnika należy przejśc do sekcji Rozliczeń i tam poczukać odpowiednich informacji.</p>

          <img src={step4} alt=""/>

        </section>



        </>

    )
  }



}

export default Members;