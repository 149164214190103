import React from "react";


class TipMessage extends React.Component
{

    constructor(props)
    {
        super(props)

        this.state = {
            show: 1
        }
    }

    render()
    {
        if(this.state.show != 1 || this.props.msg.length < 1)
        {
            return (
                <span></span>
            )
        }
        

                if(this.props.type == "notice")
                {
                    return (
                        <div className="tipMessage">
                            {this.props.msg}
                        </div>
                    )
                }else
                {
                    return (
                        <div className="tipAlert">
                        {this.props.msg}
                        </div>
                    )
                }

     
    }

}

export default TipMessage