import React   from 'react';
import ConfigX from '../../../ConfigX';

import TipMessage    from '../../../components/TipMessage';
import loading       from '../../../image/loading.gif';
import ButtonConfirm from '../../../components/ButtonConfirm';
import AlertMsg      from '../../../components/AlertMsg';
import BackPNG from './../../../image/back.png'
import FileUploader from '../../../components/FileUploader';
import GusNip from '../../../components/GUS';

class Det extends React.Component
{
    constructor(props) 
    {
        super(props)

        this.state = {
            loading: 1,

            idGus: null,

            rows: [],
            firma: [],
            alertMSG: '',
            alertMSGTime: 0,
            error: '', //error z logowania

            dane_firma: 0,
            gus_ok: 0,

            haslo: "",
            haslo2: "",

            err_nip: "",

            nn_login: "",
            nn_haslo: "",
            nn_haslo2: "",

            nn_nazwa: "",
            nn_ulica: "",
            nn_nr_domu: "",
            nn_nr_mieszkania: "",
            nn_kod_pocztowy: "",
            nn_miasto: "",
            nn_nip: "",

            nn_imie: "",
            nn_nazwisko: "",
            nn_ulica_user: "",
            nn_nr_domu_user: "",
            nn_nr_mieszkania_user: "",
            nn_kod_pocztowy_user: "",
            nn_miasto_user: "",
            nn_email: "",
            nn_telefon: "",
          
            editor_action: "none", //nie wyświetlaj edytora terminów
            editor_row: { }, //pusta baza dla editora..               
            editor_selected: 0,
            refresh: 1,
        };
    }

    componentDidMount() 
    {
        console.log("Det did mount");
        this.getData();                       
    }

     isLocalhost(){
 
        const currentHost = `${window.location.protocol}//${window.location.hostname}`;
       
        if(currentHost == 'http://localhost' || currentHost == 'http://127.0.0.1')
        {
           return true;
        }

        return false;
    }

      //Pobranie danych
    getData()
    {
  
          //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
          var wait = setTimeout(
               () => {
                  this.setState({ loading: 1 });
               }
              ,500);

         // var tmpNonRef = JSON.stringify( this.state );  
        //  tmpNonRef = JSON.parse(tmpNonRef);

          var dataPost = {
             
          }

      //    var dataPost = tmpNonRef.search_bar;
      //    dataPost.pagger = tmpNonRef.pagger;

          //console.log("Requets " +  JSON.stringify(dataPost) );
  
          fetch( ConfigX.restApi + "/user,get_data_user" , {
              method: 'POST',  
              body: JSON.stringify(dataPost), 
              headers: {
                  'Content-Type': 'text/html',
                  'X-API-KEY': this.props.apiKey
              }
          })
          .then( res => res.json() )
          .then(json => {
          
               clearTimeout(wait);

               this.setState({
                  rows: json.rows[0],                 
                  loading: 0
               });      
          
              // this.getDataFirm(); 

          });
    }

    getDataFirm(event)
    {

        var dataPost = {
            id: this.state.rows.sk_firmy_id     
         };    
 
//console.log(dataPost);
         fetch( ConfigX.restApi + "/user,get_data_firm" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() ) 
         .then(json => {
        
//console.log("res: ",json);  
              this.setState({
                 firma: json.rows[0],                               
              });      
             
         });                   


 
    }  

    onChangeLogin(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["login"] = v; 

            this.setState( {
                rows: rows,
                nn_login: ok
            } );

    }  
       
    onChangeHaslo(event)
    {
            var v   = event.target.value;
            var ok = "";
            var refresh = this.state.refresh + 1;

            if(v.length < 6)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["haslo"] = v; 
        
            this.setState( {
                rows: rows,
                nn_haslo: ok,
                refresh: refresh
            } );

    }  

    onChangeHaslo2(event)
    {
            var v   = event.target.value;
            var ok = "";
            var refresh = this.state.refresh + 1;

            var rows = this.state.rows;
            rows["haslo2"] = v; 

            this.setState( {
                rows: rows,
                nn_haslo2: ok,
                refresh: refresh
            } );
    }  

    updateDane(event)
    {     

        var err = false;
         
        if(this.state.rows.login == undefined)
        {
           var rows = this.state.rows;
           rows['login'] = this.state.rows.username;

           this.setState({
                rows: rows,
            }); 
        }

        if(this.state.rows.login.length < 2 || this.state.nn_login == 'incorrect') 
        {         
            err = true;
            this.setState({
                nn_login: "incorrect"
            });
        }

        if(this.state.rows.haslo)
        {
     
            if(this.state.rows.haslo.length < 8 || this.state.nn_haslo == 'incorrect') 
            {
                err = true;
                this.setState({
                    nn_haslo: "incorrect",
                    refresh: this.state.refresh
                });
            }
        }

        if(this.state.rows.haslo2)
        {
   
            if(this.state.rows.haslo2.length < 8 || this.state.nn_haslo2 == 'incorrect') 
            {
                err = true;
                this.setState({
                    nn_haslo2: "incorrect",
                    refresh: this.state.refresh
                });
            }
        }

        if(this.state.rows.haslo2 && this.state.rows.haslo)
        {
            if(this.state.rows.haslo2 != this.state.rows.haslo)
            {
                err = true;
                this.setState({
                    alertMSG: "Hasła różnią się",
                    alertMSGTime: 200,
                    nn_haslo2: "incorrect",
                    nn_haslo: "incorrect",
                    refresh: this.state.refresh
                });
            }
        }


        if(err) return;

        if(this.state.rows.nip == undefined)
        {
            var dt = new Date();
            dt = dt.toISOString();

             this.setState({
                 alertMSG: "Podaj prawidłowy NIP",
                 alertMSGTime: dt
             })
             return;
        } 

        if((this.state.rows.haslo != this.state.rows.haslo2))
        {
            var dt = new Date();
            dt = dt.toISOString();

             this.setState({
                 alertMSG: "Hasła muszą być takie same.",
                 alertMSGTime: dt
             })
             return;
        } 

        if(this.state.rows.nip != 0)
        {
            if(!this.isValidNip(this.state.rows.nip))
            {
                this.setState({
                    nn_nip: "incorrect",
                    err_nip: "Podaj prawidłowy NIP"
                })
               // return;
            }else{
                this.setState({
                    nn_nip: "correct",
                    err_nip: "",
                })
            }
        }
     //   var bnText = event.target.innerHTML;
     //   event.target.innerHTML = "...";

           var dataPost = {

               id:     this.state.rows.id, //px_user_id
               login:  this.state.rows.login,
               haslo:  this.state.rows.haslo,
               haslo2: this.state.rows.haslo2,
               sk_firmy_id: this.state.rows.sk_firmy_id,
               px_group_id:  this.state.rows.px_group_id,

           //    nazwa_f : this.state.firma.nazwa,
           //    nip: this.state.firma.nip,
           //    ulica_f : this.state.firma.ulica,
           //    nr_domu_f : this.state.firma.nr_domu,
          //     nr_mieszkania_f : this.state.firma.nr_mieszkania,
          //     kod_pocztowy_f : this.state.firma.kod_pocztowy,
          //     miasto_f : this.state.firma.miasto,

                nazwa_f : this.state.rows.nazwa_firmy,
                nip: this.state.rows.nip,
                ulica_f : this.state.rows.ulica_f, 
                nr_domu_f : this.state.rows.nr_domu_f,
                nr_mieszkania_f : this.state.rows.nr_mieszkania_f,
                kod_pocztowy_f : this.state.rows.kod_pocztowy_f,
                miasto_f : this.state.rows.miasto_f,

               imie: this.state.rows.imie,
               nazwisko: this.state.rows.nazwisko,
               ulica: this.state.rows.ulica,
               nr_domu: this.state.rows.nr_domu,
               nr_mieszkania: this.state.rows.nr_mieszkania,
               kod_pocztowy: this.state.rows.kod_pocztowy, 
               miasto : this.state.rows.miasto,
               email: this.state.rows.email,
               telefon: this.state.rows.telefon

           };

           fetch( ConfigX.restApi + "/user,updateDataUser" , {
               method: 'POST', 
               body: JSON.stringify(dataPost), 
               headers: { 
                   'Content-Type': 'text/html',
                   'X-API-KEY': this.props.apiKey
               }
           })
           .then( res => res.json() )
           .then(json => {

                 var dt = new Date();
                 dt = dt.toISOString();

                if(json.status == "OK")
                {
                   // event.target.innerHTML = bnText;
                                     
                    this.setState({
                       alertMSG: 'Informacje zostały zapisane.',
                       alertMSGTime: dt
                    });
                }else
                {                    
                    this.setState({
                        alertMSG: json.param,
                        alertMSGTime: dt,                    
                     });                       
                }
           });
           
    }



    onChangeNazwa(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var firma = this.state.firma;
            firma["nazwa"] = v; 

            this.setState( {
                firma: firma,
                nn_nazwa: ok
            } );
    }  

    onChangeUlica(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

           // var firma = this.state.firma;
           // firma["ulica"] = v; 

           var rows = this.state.rows;
           rows["ulica_f"] = v; 

            this.setState( {
                rows: rows,
                nn_ulica: ok
            } );
    }  
     
    onChangeNrDomu(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

          //  var firma = this.state.firma;
          //  firma["nr_domu"] = v; 

            var rows = this.state.rows;
            rows["nr_domu_f"] = v;

            this.setState( {
                rows: rows,
                nn_nr_domu: ok
            } );
    } 

    onChangeNrMieszkania(event)
    {
            var v   = event.target.value;
            var ok = "";

            //if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

         //   var firma = this.state.firma;
         //   firma["nr_mieszkania"] = v; 

            var rows = this.state.rows;
            rows["nr_mieszkania_f"] = v;

            this.setState( {
                rows: rows,
                nn_nr_mieszkania: ok
            } );
    }  

    onChangeKod(event)
    {
        var ok = "";
    
    
        var v = event.target.value;        

       // v = v.replace(/[^\d.-]/g, '');

       // const arr = v.split("-");
      //  if(arr.length == 2 && arr[0].length == 2 && arr[1].length == 3 && /^\d+$/.test(arr[0])  && /^\d+$/.test(arr[1])   )
     //   {
            ok ="correct";
      //  }else ok ="incorrect";

    
        //var firma = this.state.firma;
        //firma["kod_pocztowy"] = v; 

        var rows = this.state.rows;
        rows["kod_pocztowy_f"] = v;

        this.setState( {
            rows: rows,
            nn_kod_pocztowy: ok
        } );

    }

    onChangeMiasto(event)
    {
            var v   = event.target.value;
            var ok = "";

            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

          //  var firma = this.state.firma;
          //  firma["miasto"] = v; 

            var rows = this.state.rows;
            rows["miasto_f"] = v;

            this.setState( {
                rows: rows,
                nn_miasto: ok
            } );
    } 


    onChangeImie(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["imie"] = v; 

            this.setState( {
                rows: rows,
                nn_imie: ok
            } );
    }  

    onChangeNazwisko(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["nazwisko"] = v; 

            this.setState( {
                rows: rows,
                nn_nazwisko: ok
            } );
    }  

    onChangeUlicaUser(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["ulica"] = v; 

            this.setState( {
                rows: rows,
                nn_ulica_user: ok
            } );
    }  

    onChangeEmail(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["email"] = v; 

            this.setState( {
                rows: rows,
                nn_email: ok
            } );
    } 

    onChangeTelefon(event)
    { 
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["telefon_kontaktowy"] = v; 

            this.setState( {
                rows: rows,
                nn_telefon: ok
            } );
    } 

    onChangeNrDomuUser(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["nr_domu"] = v; 

            this.setState( {
                rows: rows,
                nn_nr_domu_user: ok
            } );
    } 

    onChangeNrMieszkaniaUser(event)
    {
            var v   = event.target.value;
            var ok = "";

            //if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["nr_mieszkania"] = v; 

            this.setState( {
                rows: rows,
                nn_nr_mieszkania_user: ok
            } );
    }  

    onChangeKodUser(event)
    {
        var ok = "";
    
    
        var v = event.target.value;        

     //   v = v.replace(/[^\d.-]/g, '');

    //    const arr = v.split("-");
    //    if(arr.length == 2 && arr[0].length == 2 && arr[1].length == 3 && /^\d+$/.test(arr[0])  && /^\d+$/.test(arr[1])   )
   //     {
            ok ="correct";
   //     }else ok ="incorrect";

    
        var rows = this.state.rows;
        rows["kod_pocztowy"] = v; 

        this.setState( {
            rows: rows,
            nn_kod_pocztowy_user: ok
        } );

    }

    onChangeMiastoUser(event)
    {
            var v   = event.target.value;
            var ok = "";

            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["miasto"] = v; 

            this.setState( {
                rows: rows,
                nn_miasto_user: ok
            } );
    } 


    isValidNip(nip) 
    {
        if(typeof nip !== 'string')
            return false;
    
        nip = nip.replace(/[\ \-]/gi, '');
    
        let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(nip.substring(9, 10));
        let weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
            sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        } 
        
        return sum % 11 === controlNumber;
    }

    onChangeNIP(event)
    {
            var v   = event.target.value;
   
            var ok = "";

         //   if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

        /*    if(!this.isValidNip(v))
            { 
                ok = "incorrect"; 

                this.setState( {                                
                    nn_nip: ok
                } );
            }else { 
                ok="correct"; 

                //var firma = this.state.firma;
                //firma["nip"] = v; 

                this.setState( {
                    //firma: firma,                  
                    nn_nip: ok
                } );
                */
                var rows = this.state.rows;
                rows["nip"] = v; 

                this.setState({
                    rows: rows
                })
                
                //this.checkNIP(v); 

                // this.getDataGUS(this.state.nip);                  
                

       //     }         
    }

    onDialogOK(param)
    {      
            this.setState({
                alertMSG: '',
                alertMSGTime: 0,
            });
    }

    checkFirma()
    {

       var nip = this.state.nip;

       if(nip == undefined)
       {
            this.setState({
                alertMSG: "Podaj prawidłowy NIP",
                alertMSGTime: 200
            })
            return;
       }

       var dataPost = {
         "nip": nip
       }

       fetch( ConfigX.restApi + "/user,checkFirma" , {
           method: 'POST',  
           body: JSON.stringify(dataPost), 
           headers: { 
               'Content-Type': 'text/html',
               'X-API-KEY': this.props.apiKey
           }
       })
       .then( res => res.json() )
       .then(json => {
       
            //clearTimeout(wait);


            this.setState({
                 firma: json.rows[0],                 
                 loading: 0
            });                  
       
                            
       })
       .catch((error)=> {
            console.log("error: ",error);
       });        
    }


    render()
    {      

        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>                                               
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        } 

 
        const pageStyle = `
        @page {
          size: 210mm 297mm;
        }
      
        @media all {
          .pagebreak {
            display: none;
          }
        }
      
        @media print {
          .pagebreak {
            page-break-before: always;
          }
        }
      `;
      
  //  let daneFirmowe = '';


    /* if( this.state.firma.nip != 0 || this.state.gus_ok == 1) 
     {
         daneFirmowe = ( 
            <> 
            
            <h5>Dane firmowe</h5>

                <div className='FormField inlineField'>
                    <label className="">Nazwa firmy</label>
                    <input type="text" className="form-control mb-3 field900" defaultValue={this.state.firma.nazwa}  col="Nazwa" onChange={this.onChangeNazwa.bind(this) } />                           
                </div>  
                <br/>                                     
                <div className="FormField inlineField">
                    <label className="">Ulica</label>
                    <input type="text" className="form-control mb-3 field300" defaultValue={this.state.firma.ulica}  col="ulica" onChange={this.onChangeUlica.bind(this) } />
                </div>
                <div className="FormField inlineField">
                    <label className="">Nr domu</label>
                    <input type="text" className="form-control mb-3 field50" defaultValue={this.state.firma.nr_domu}  col="" onChange={this.onChangeNrDomu.bind(this) } />
                </div>
                <div className="FormField inlineField">
                    <label className="">Nr mieszkania</label>
                <input type="text" className="form-control mb-3 field50" defaultValue={this.state.firma.nr_mieszkania}  col="" onChange={this.onChangeNrMieszkania.bind(this) } />
                </div>
                <br/>
                <div className="FormField inlineField">
                <label className="">Kod pocztowy</label>
                <input type="text" className="form-control mb-3 field100" defaultValue={this.state.firma.kod_pocztowy}  col="" onChange={this.onChangeKod.bind(this) } />
                </div>
                <div className="FormField inlineField">
                    <label className="">Miasto</label>
                    <input type="text" className="form-control mb-3 field300" defaultValue={this.state.firma.miasto}  col="" onChange={this.onChangeMiasto.bind(this) } />
                </div>            
            
            </>
        )
     }*/


        return (

            <> 


            <div className='dashForm'>

                
                    <h5>Dane firmowe</h5>

                    <div className='FormField inlineField'>
                        <label className="">Nazwa firmy</label>
                        <input type="text" className="form-control mb-3 field900" defaultValue={this.state.rows.nazwa_firmy}  col="Nazwa" onChange={this.onChangeNazwa.bind(this) } />                           
                    </div>  
                    <br/>   
                    <div className='FormField inlineField'>
                        <label>NIP</label>
                        <input type="text" className={"form-control mb-3 field200 " + this.state.nn_nip} defaultValue={ this.state.rows.nip }  col="nip" onChange={this.onChangeNIP.bind(this) } onPaste={this.onChangeNIP.bind(this)} />
                        <span className={'errorForm'}>{this.state.err_nip}</span>
                        {/*<div className="buttonForm" type="button" onClick={this.checkFirma.bind(this)}>pobierz dane</div>*/}
                    </div>
                    <br/>                                                      
                    <div className="FormField inlineField">
                        <label className="">Ulica</label>
                        <input type="text" className={"form-control mb-3 field300 "+ this.state.nn_ulica} defaultValue={this.state.rows.ulica_f}  col="ulica" onChange={this.onChangeUlica.bind(this) } />
                    </div>
                    <div className="FormField inlineField">
                        <label className="">Nr domu</label>
                        <input type="text" className={"form-control mb-3 field50 "+ this.state.nn_nr_domu} defaultValue={this.state.rows.nr_domu_f}  col="" onChange={this.onChangeNrDomu.bind(this) } />
                    </div>
                    <div className="FormField inlineField">
                        <label className="">Nr mieszkania</label>
                    <input type="text" className={"form-control mb-3 field50 "+ this.state.nn_nr_mieszkania} defaultValue={this.state.rows.nr_mieszkania_f}  col="" onChange={this.onChangeNrMieszkania.bind(this) } />
                    </div>
                    <br/>
                    <div className="FormField inlineField">
                    <label className="">Kod pocztowy</label>
                    <input type="text" className={"form-control mb-3 field100 "+ this.state.nn_kod_pocztowy} defaultValue={this.state.rows.kod_pocztowy_f}  col="" onChange={this.onChangeKod.bind(this) } />
                    </div>
                    <div className="FormField inlineField">
                        <label className="">Miasto</label>
                        <input type="text" className={"form-control mb-3 field300 "+ this.state.nn_miasto} defaultValue={this.state.rows.miasto_f}  col="" onChange={this.onChangeMiasto.bind(this) } />
                    </div>            

                    <h5>Dane osobowe</h5>

                    <div className="FormField inlineField">
                        <label className="">Imię</label>
                        <input type="text" className={"form-control mb-3 field300 "+ this.state.nn_imie} defaultValue={this.state.rows.imie} onChange={this.onChangeImie.bind(this) } />
                    </div>
                    <div className="FormField inlineField">
                        <label className="">Nazwisko</label>
                        <input type="text" className={"form-control mb-3 field300 "+ this.state.nn_nazwisko} defaultValue={this.state.rows.nazwisko}  onChange={this.onChangeNazwisko.bind(this) }/>
                    </div> 
                    <br/>
                    <div className="FormField inlineField">
                        <label className="">Ulica</label>
                        <input type="text" className={"form-control mb-3 field300 "+ this.state.nn_ulica_user} defaultValue={this.state.rows.ulica}  col="ulica" onChange={this.onChangeUlicaUser.bind(this) } />
                    </div>
                    <div className="FormField inlineField"> 
                        <label className="">Nr domu</label>
                         <input type="text" className={"form-control mb-3 field50 "+ this.state.nn_nr_domu_user} defaultValue={this.state.rows.nr_domu}  col="" onChange={this.onChangeNrDomuUser.bind(this) } />
                    </div>
                    <div className="FormField inlineField">
                        <label className="">Nr mieszkania</label>
                       <input type="text" className={"form-control mb-3 field50 "+ this.state.nn_nr_mieszkania_user} defaultValue={this.state.rows.nr_mieszkania}  col="" onChange={this.onChangeNrMieszkaniaUser.bind(this) } />
                   </div>
                    <br/>
                   <div className="FormField inlineField">
                    <label className="">Kod pocztowy</label>
                       <input type="text" className={"form-control mb-3 field100 "+ this.state.nn_kod_pocztowy_user} defaultValue={this.state.rows.kod_pocztowy}  col="" onChange={this.onChangeKodUser.bind(this) } />
                    </div>
                    <div className="FormField inlineField">
                        <label className="">Miasto</label>
                        <input type="text" className={"form-control mb-3 field300 "+ this.state.nn_miasto_user} defaultValue={this.state.rows.miasto}  col="" onChange={this.onChangeMiastoUser.bind(this) } />
                    </div>

                    <br/>
                    <div className="FormField inlineField">
                        <label className="">Email</label>
                        <input type="text" className={"form-control mb-3 field300 "+ this.state.nn_email} defaultValue={this.state.rows.email}  col="ulica" onChange={this.onChangeEmail.bind(this) } />
                    </div>
                    <div className="FormField inlineField">
                        <label className="">Telefon</label>
                        <input type="text" className={"form-control mb-3 field300 "+ this.state.nn_telefon} defaultValue={this.state.rows.telefon}  col="ulica" onChange={this.onChangeTelefon.bind(this) } />
                    </div>




                    <h5>Dane do logowania</h5>

                        <div className="FormField inlineField">
                            <label className="">Login (e-mail)<span className='required'>*</span></label>
                            <input type="text" className={"form-control mb-3 field400 " + this.state.nn_login } defaultValue={this.state.rows.username}  col="tytul" onChange={this.onChangeLogin.bind(this) } />
                        </div>
                        <br/>
                        <div className="FormField inlineField">
                            <label className="">Hasło (min. 8 znaków, min. 1 cyfra, min. 1 litera)<span className='required'>*</span></label>
                            <input type="password" className={"form-control mb-3 field400 " + this.state.nn_haslo} defaultValue={this.state.haslo}  col="haslo" onChange={this.onChangeHaslo.bind(this) }    />
                        </div>
                        <div className="FormField inlineField">
                            <label className="">Powtórz hasło <span className='required'>*</span></label>
                            <input type="password" className={"form-control mb-3 field400 " + this.state.nn_haslo2} defaultValue={this.state.haslo2}  col="haslo2" onChange={this.onChangeHaslo2.bind(this) }    />
                        </div>

 
                        <div className="mt-5 text-center">
                        <div className="buttonForm" type="button" onClick={this.updateDane.bind(this)}>Zapisz dane</div>
                        </div>
                       

            </div>

                <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Powiadomienie" onDialogOK={this.onDialogOK.bind(this) }  />
            </>
        
        )
    }
}

export default Det;