import React from 'react';

import checkPNG from '../../../image/check.png'
import nonePNG from '../../../image/none.png'
import infoPNG from '../../../image/info.png'
import { Link } from 'react-router-dom';
import ConfigX from '../../../ConfigX';
 
class TableRow extends React.Component
 {
        constructor(props)
        {
            super(props)
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onRowClick(id);
        }

        render()
        {
            let nip = '';
            var letter = '';
            if( this.props.row.nazwa.length > '0' )
            {
                letter = this.props.row.nazwa.charAt(0);
            }
            if(this.props.row.nip == 0)
            {
                nip = "-";
            }else{
                nip = this.props.row.nip;
            }

            return (
                 
                        <tr className='rowLink' >
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center"> {this.props.num} </td> 
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > <div className="letter" id={this.props.row.id}  onClick={this.onRowClick.bind(this)} > {letter} </div>   </td>   
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)}>  {this.props.row.nazwa}    </td>   
                             <td id={this.props.row.id}  className="text_center">  {nip}    </td>                                                    
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)}>  {this.props.row.ulica}    </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center">  {this.props.row.nr_domu}    </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" >  {this.props.row.nr_mieszkania}    </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center">  {this.props.row.kod_pocztowy}    </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)}>  {this.props.row.miasto}    </td>               
                        </tr>                 
            )
        }
 }

 export default TableRow;