import React from "react";

import ConfigX from '../ConfigX'
import ButtonConfirm from './ButtonConfirm';


class Regulation extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            regulation: [],

            new: {
                wymagane: 0,
                tytul: '',
            }
        }
    }


    getData()
    {
        var dataPost = {
            typ: this.props.typ,            
            typ_id: this.props.typ_id,            
          }
 
         fetch( ConfigX.restApi + "/dash,get_regulation" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() )
         .then(json => {

            this.setState({
                regulation: json
            });

            this.props.onChange(json);

         });
             
    }


    componentDidMount()
    {
            this.getData();
    }



    onConfirmDel(type, param, param_id)
    {
       console.log(type + param + param_id);

       if(type != "yes")  return;

         var dataPost = {
            id: param_id,       
        };

   
        fetch( ConfigX.restApi + "/dash,remove_regulation" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             if(json.status == "OK")
             {
                this.getData();
             }                 
        });

        
        
    }


    handleChangeNew(event) 
    {
        var n_name =  event.target.name;
        var n_val  =  event.target.value; 
 
        if(n_name == "wymagane"){
            var cc   =  event.target.checked;
            if(cc) n_val = "1";
            if(!cc) n_val = "0";
        }

        var newx = this.state.new;        
        newx[n_name] = n_val;       
        this.setState(
            {
                new: newx,                
            }
        );
    };  


    saveNew()
    {

        if(this.state.new.tytul.length < 3)
        {
            return;
        }

        var dataPost = {
            typ: this.props.typ,            
            typ_id: this.props.typ_id,            
            wymagane: this.state.new.wymagane, 
            tytul: this.state.new.tytul, 
          }

         fetch( ConfigX.restApi + "/dash,add_regulation" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() )
         .then(json => {

            if(json.status == "OK")
            {
               this.setState({
                new: {
                    wymagane: 0,
                    tytul: '',
                }
               });
               this.getData();
            }  

         });
             
    }





    render()
    {  

        var cc1 = this.state.new.wymagane==="1" ? true : false


        return (
            <div>
                 <h3>📓 Postanowienia do akceptacji podczas zapisu ({this.state.regulation.length})</h3>

                 <div className='table_content'>                 
                        <table className='table table100'>
                        <thead>
                            <tr>
                                <td>LP</td>
                                <td>Wymagana akceptacja</td>
                                <td>Szczegóły (opis tekstowy)</td>                                                                                   
                                <td> </td>                                                                                   
                            </tr>
                        </thead>
                        <tbody> 

                            <tr>
                                  <td> </td>
                                  <td className="text_center">   <input type="checkbox" name="wymagane" value="1"  checked={cc1} onChange={this.handleChangeNew.bind(this) } />  </td>
                                  <td> <input type="text" className='field100p' name="tytul" placeholder="Np. Akceptuje postanowienia regulaminu znajdującedo się w reg.doc"  value={this.state.new.tytul} onChange={this.handleChangeNew.bind(this) } /> </td>                                                                                     
                                  <td className="text_center"> <span className="buttonForm" onClick={this.saveNew.bind(this)}>dodaj</span> </td>                                                                                   
                            </tr>

                            {
                                this.state.regulation.map( (item, ix) => {

                                        var wym = 'Nie';
                                        if(item.wymagane == '1')  wym = 'Tak';

                                        return (
                                             <tr key={ix}>
                                                <td className="text_center">{ix +  1}</td>
                                                <td className="text_center">{wym}</td>
                                                <td>{item.tytul}</td>
                                                <td className="text_center"> <ButtonConfirm   onConfirm={this.onConfirmDel.bind(this) } param="sk_postanowienia" param_id={item.id}  title="usuń*" query="Na pewno chcesz usunąć to postanowienie?"  />  </td>
                                             </tr>
                                        ) 
                                } )
                            }
                        </tbody>    
                        </table>

                   </div>


            </div>
        )
    }

}

export default Regulation;