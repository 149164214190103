
import React from "react";
import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom";


import ConfigX from '../../ConfigX';
 
class ItemT extends React.Component
{
 
 

  onSelect()
  {
    this.props.onClickItem( this.props.id ); 
  }
 

  render(){

    var cel = this.props.row.cel_szkolenia;
    //if(cel.length > 150)
   // {
   //   cel = cel.substring(0,150) + "...";
   // }



    //let ilosc_miejsc_dostepnych = 0;
    let zapisanych = this.props.row.zapisanych;
    let ilosc_miejsc = this.props.row.ilosc_miejsc;
    let status_brak_miejsc = this.props.row.status_brak_miejsc; //ustawiony status uczestnika na brak miejsc 

    let ilosc_miejsc_dostepnych = parseInt(ilosc_miejsc) - parseInt(zapisanych);
   
    if(ilosc_miejsc_dostepnych < 0) ilosc_miejsc_dostepnych = 0;

    var koszty = "Koszt: "; //z opcją modyfikacji netto/brutto
    let koszty_bkp = " ";

    if(this.props.row.koszt_szkolenia_bkp != '0.00')
    {
        koszty_bkp = (
          <>
            Koszt dla członka BKP:  <NumberFormat  value={this.props.row.koszt_szkolenia_bkp} displayType={'text'} decimalScale={0} /> zł / {this.props.row.podstawa_kwoty}
          </>
       );
    }
    // if(this.props.row.koszt_jm == 'UL')
    // {
    //   var koszty = "Koszt za lekcję: ";
    // }
    // if(this.props.row.koszt_jm == 'UW')
    // {
    //   var koszty = "Koszt za szkolenie: ";
    // }

    var terminBuff = '';

    if(this.props.row.bezterminowe == 'tak')
    {
        terminBuff = (<div className="f-18">{this.props.row.tresc_terminu}</div>);
    }else{
        terminBuff = this.props.row.terminy.map( (row,index) => {

        // var thedate = new Date(row.data_od);   
        
        // var rok_od     = thedate.getYear(); 
        // var miesiac_od = month[thedate.getMonth()];
        // var dzien_od   = thedate.getDay(); 
        
        // const formatted = `${dzien_od} ${miesiac_od} ${rok_od}`; 
        // var dataFull = formatted.toString();
        
          return (                                     
            <div key={index} className="f-18" title={row.temat + ", miejsce: " + row.ulica + " " + row.nr_domu + " " + row.nr_mieszkania + " " + row.miasto } >&nbsp; {row.data_od} &nbsp;&nbsp; {row.data_do} &nbsp;</div>
          )
        
            
        } ) 
    }

    return(
   
        <div className="item">


             <div className="p1_3">
                 <div className="info">
                    <div className="title padding-2"><Link to={ConfigX.pathDef + "szczegoly?id=" + this.props.row.id }  >{this.props.row.tytul}</Link></div>

                    <div className="dates  padding-2"><span className="f-15">Termin: </span>&nbsp;  
                        {terminBuff}
                    </div>

                    <div className="price">
                      {koszty} <NumberFormat  value={this.props.row.koszt_szkolenia} displayType={'text'} decimalScale={0} /> zł / {this.props.row.podstawa_kwoty} <br/>
                      {koszty_bkp} 
                    </div>                  
                   

                 </div>
             </div>

             <div className="p1_4 border-left-right">
                 <div className="info"  >
                  <p>
                      {cel}
                  </p>
                  <p>
                      {this.props.row.wymagane_od_uczestnika}
                  </p>
                </div>
              </div>

             <div className="p1_3">
              <div className="title padding-bottom-2 info_center"><span style={{fontWeight: "bold",fontSize: "16px"}}>Ilość dostępnych miejsc: {ilosc_miejsc_dostepnych}</span></div>
                <div className="info info_center" >
                      <Link to={ConfigX.pathDef + "szczegoly?id=" + this.props.row.id } className="buttonStandard" >Więcej informacji</Link>                      
                </div>
             </div>

        </div>
    )
  }
}


export default ItemT