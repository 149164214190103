import React from 'react';
import { Link } from 'react-router-dom';
import {Navigate} from 'react-router-dom';
import ConfigX from '../../ConfigX';

import DetailsPrint from './DetailsPrint';
import ReactToPrint from 'react-to-print';
import TipMessage from '../../components/TipMessage';
import Linkify from 'react-linkify';

import TerminPNG from '../../image/termin.png'
import BackPNG from '../../image/back.png'
import MapPNG from '../../image/google_map.png'
import PrintPNG from '../../image/print.png'

import FileUploader from '../../components/FileUploaderUser'; 
import SignUPT from '../page/SignUpT';
import AlertMsg from '../../components/AlertMsg';
import NumberFormat from 'react-number-format';

class Details extends React.Component
{
  constructor(props)
  { 
    super(props)

    this.state = {
        sk_szkolenia_id: this.props.row.id,
        page: this.props.page,
        editor_action: "none", //nie wyświetlaj edytora terminów
        editor_row: { }, //pusta baza dla editora..               
        editor_selected: 0,
        response: 0,
        user: [],
        login: 0,
        oczekuje: 0,
        potwierdzone : 0,
        brak_miejsc: 0,
        odwolane: 0,
        id_uczestnika: 0,
        alertMSG: '',
        alertMSGTime: 0,    
        row: this.props.row      
    };



  }

  checkLogout()
  {

    if(this.state.page == 'logout')
    { 
            var localUsr = window.localStorage.getItem('user');

          //  console.log("||| " + localUsr);

            if(localUsr != null && localUsr != "undefinded" && localUsr.length > 3)
            {
                      var jsonUsr = JSON.parse(localUsr);
            
                      fetch( ConfigX.restApi + '/rest,go_logout', {
                        method: 'POST', 
                        body: JSON.stringify(this.state), 
                        headers: {
                            'Content-Type': 'text/html',
                            'X-API-KEY': jsonUsr.apiKey   
                        }
                    })
                    .then(res => res.json() )
                    .then(res => {

                      window.localStorage.removeItem("user");  

                      this.setState({
                        page: "logout_success",                        
                      });
                      
                    })
                    .catch((error)=> console.log(error))

            }


    }

  }
  componentDidMount()
  {
     // this.checkLogout();  

     if(this.props.apiKey != "")
     { 
        this.checkSignUp();
        this.getDataUser();        
      }
      
  }

  componentDidUpdate()
  {
    this.checkLogout(); 

   /* if(this.state.page != this.props.page)
    {
        this.setState({
            page: this.props.page,         
        });
    }    */
  }

  getDataUser()
  {

                    fetch( ConfigX.restApi + '/user,get_data_user', {    
                        method: 'POST', 
                        body: JSON.stringify(this.state), 
                        headers: {
                            'Content-Type': 'text/html',
                            'X-API-KEY': this.props.apiKey
                        }
                    })
                    .then(res => res.json() )
                    .then(res => {
          
                            this.setState({
                                user: res.rows[0],                       
                            }); 
                   
                    })
                    
  } 



  onClickNewSign()
  {

      this.setState({
          sk_szkolenia_id: this.state.sk_szkolenia_id,             
          page: "sign",
          editor_row:   {},
          editor_action: "new",    
          editor_selected: -1,      
      });

  }

  checkSignUp()
  {
      var dataPost = {
          sk_szkolenia_id: this.state.sk_szkolenia_id
       };    


       fetch( ConfigX.restApi + "/user,checkSignUp" , { 
           method: 'POST', 
           body: JSON.stringify(dataPost), 
           headers: {
               'Content-Type': 'text/html',
               'X-API-KEY': this.props.apiKey
           }
       })
       .then( res => res.json() )
       .then(json => {

          if(json.status == "oczekuje")
          {            
              this.setState({
                oczekuje: 1,
                id_uczestnika: json.id_uczestnik
              })
          }
          if(json.status == "potwierdzone")
          {            
              this.setState({
                potwierdzone: 1,
                id_uczestnika: json.id_uczestnik
              })
          }                
          if(json.status == "odrzucone_brak_miejsc")
          {            
              this.setState({
                brak_miejsc: 1,
                id_uczestnika: json.id_uczestnik
              })
          }   
          if(json.status == "odrzucone_odwolanie_szkolenia")
          {            
              this.setState({
                odwolane: 1,
                id_uczestnika: json.id_uczestnik
              })
          }           
          
       })
                          
  }

  wypiszSie()
  {
      var dataPost = {
          id_uczestnika: this.state.id_uczestnika
       };    


       fetch( ConfigX.restApi + "/user,wypiszSie" , {
           method: 'POST', 
           body: JSON.stringify(dataPost), 
           headers: {
               'Content-Type': 'text/html',
               'X-API-KEY': this.props.apiKey
           }
       })
       .then( res => res.json() )
       .then(json => {
      
          if(json.status == "OK")
          {            
              this.setState({
                alertMSG: "zostałeś pomyślnie wypisany ze szkolenia",
                alertMSGTime: 12
              })
              this.getDataUser();
          }
            
           
       });                      
  }

  opuscKolejke()
  {

      var dataPost = {
          id_uczestnika: this.state.id_uczestnika
       };    


       fetch( ConfigX.restApi + "/user,opuscKolejke" , {
           method: 'POST', 
           body: JSON.stringify(dataPost), 
           headers: {
               'Content-Type': 'text/html',
               'X-API-KEY': this.props.apiKey
           }
       })
       .then( res => res.json() )
       .then(json => {
      
        if(json.status == "OK")
        {            
            this.setState({
              alertMSG: "pomyślnie opuściłeś kolejkę",
              alertMSGTime: 12
            })
            this.getDataUser();
        }             
           
       });                      
  }

  onDeleteTime(id)
  {
          this.setState({
              rows: this.state.rows
          });
  }
 
  onCloseEditor(modifyRow,res_type)
  {

    if(modifyRow != null)
    {
        if(res_type === 'ok')
        {
            this.setState({response:1}) 
            this.getDataUser();
        }
        this.setState({            
            sk_szkolenia_id: modifyRow,          
        })
    }

      this.setState({          
          editor_row: {},
          editor_action: "none",     
          editor_selected: -2              
      });
  }

  onDialogOK(param)
  {
   
          this.setState({
              alertMSG: '',
              alertMSGTime: 0,
              oczekuje: 0,
              potwierdzone : 0,
              brak_miejsc: 0,
              odwolane: 0,
              id_uczestnika: 0,
              alertMSG: '',
              alertMSGTime: 0,   
          });


  }


  goLogin(event)
  {
    this.setState({page:"sign"})
  }

  render()
  {

    var cel        = this.props.row.cel_szkolenia;

    var dla_kogo   = this.props.row.dla_kogo;
    

    if(dla_kogo.length > 0)
    {
        dla_kogo = (<p>{this.props.row.dla_kogo}</p>);
    }

   // var wymagane  = this.props.row.wymagane_od_uczestnika;

    var koszt = "";//Koszt szkolenia z opcją modyfikacji netto/brutto
    let kosztBKP = "";
    var platnosc = ""; 
 
    if(this.state.page == "sign" && this.props.apiKey == "")  
    {
           
        return (
              <div>
                {<Navigate to={ConfigX.pathDef   + "login"  } />}
              </div>
          );
    }


    // if(this.props.row.koszt_jm == "UL")
    // {
    //     koszt = this.props.row.koszt_szkolenia + " zł / jedne zajęcia";
    // }
    // else
    // if(this.props.row.koszt_jm == "UW")
    // {
    //     koszt = this.props.row.koszt_szkolenia + " zł / wszystkie zajęcia w wymienionych terminach";
    // }
    // else
    // {
    // if(this.props.row.koszt_jm == "UL")
    // {
    //     koszt = this.props.row.koszt_szkolenia + " zł / jedne zajęcia";
    // }
    // else
    // if(this.props.row.koszt_jm == "UW")
    // {
    //     koszt = this.props.row.koszt_szkolenia + " zł / wszystkie zajęcia w wymienionych terminach";
    // }
    // else
    // {
        
        koszt = this.props.row.koszt_szkolenia;
        kosztBKP = "";

        if(this.props.row.koszt_szkolenia_bkp != '0.00' && this.props.row.koszt_szkolenia_bkp != 0 && this.props.row.koszt_szkolenia_bkp != '0' )
        {
             kosztBKP = (
                <div className="price2">Koszt dla członka BKP: <NumberFormat  value={this.props.row.koszt_szkolenia_bkp} displayType={'text'} decimalScale={0} /> zł / {this.props.row.podstawa_kwoty}</div>             
            );
        }


 //   }
 //   }

    // if(this.props.row.platnosci == "LP")
    // {
    //     platnosc = "Płatność po każdych zajęciach";
    // }
    // else
    // if(this.props.row.platnosci == "PL")
    // {
    //     platnosc = "Płatność przed każdymi zajęciami";
    // }
    // else    
    // if(this.props.row.platnosci == "WP")
    // {
    //     platnosc = "Płatność po zakończeniu wszystkich lekcji";
    // }else
    // if(this.props.row.platnosci == "PW")
    // {
    //     platnosc = "Płatność z góry za wszystkie zajęcia";
    // }else
    // {
    //     platnosc = this.props.row.platnosci;
    // }
    // if(this.props.row.platnosci == "LP")
    // {
    //     platnosc = "Płatność po każdych zajęciach";
    // }
    // else
    // if(this.props.row.platnosci == "PL")
    // {
    //     platnosc = "Płatność przed każdymi zajęciami";
    // }
    // else    
    // if(this.props.row.platnosci == "WP")
    // {
    //     platnosc = "Płatność po zakończeniu wszystkich lekcji";
    // }else
    // if(this.props.row.platnosci == "PW")
    // {
    //     platnosc = "Płatność z góry za wszystkie zajęcia";
    // }else
    // {
    //     platnosc = this.props.row.platnosci;
    // }

    var msg = "";



    var content = "";

    if(this.state.page == "sign" && this.props.apiKey != "") 
    {
     
        document.title = ConfigX.title  + " / Szkolenia"; 

        content = (
            <section className="container">             
                 <SignUPT apiKey={this.props.apiKey} action={this.state.editor_action} onDeleteTime={this.onDeleteTime.bind(this)} row={this.state.editor_row} terminy={this.props.row.terminy} sk_szkolenia_id={this.state.sk_szkolenia_id} nowSelected={this.state.editor_selected} onCloseEditor={this.onCloseEditor.bind(this)}/>                 
            </section>
          );
    }


    var msg_type = "";//notice
    var zapiszButton = "";
    var msg = '';
    var wypiszSie ='';
    var opusc = '';
    var v = false;

   // if(wymagane.length < 1) wymagane = 'Brak informacji od organizatora szkolenia.'


    if(this.props.apiKey == '')//niezalogowany
    {

        if(this.state.brak_miejsc == 1)
        {

            zapiszButton = ''; 
        
            msg = (
                <span>Nie można zapisać się na szkolenie, ponieważ wszystkie miejsca są już zajęte. </span>
            )
            msg_type = "error";  

        }else{

            
            zapiszButton = ( 
                <div className='detailsButton'>
                    <div className='buttonStandard' onClick={this.goLogin.bind(this)}>Zapisz się na szkolenie</div>                                            
                </div>            
            )  
        
            if(this.state.row.bezterminowe == 'tak')
            {
                zapiszButton = "";
            }
            msg = (
                <span>Aby zapisać się na szkolenie musisz najpierw zarejestrować się w systemie.</span>
            )
            msg_type = "error";             
        }     
    }


    if(this.props.apiKey != '')//zalogowany
    {

        if(this.state.user.typ == 'ADMIN' ) 
        {
             zapiszButton = '';
             msg = '';
        }


        if(this.state.user.typ == 'UCZESTNIK')
        {
            if(this.state.response == '1') //odpowiedz po zapisie
            {
                msg = "Pomyślnie zapisano się na szkolenie ";
                msg_type = 'notice';
                v = true;
            }

            if(this.state.oczekuje == 1) //status oczekuje
            {
                msg = "Aktualnie jesteś zapisany i oczekujesz w kolejce chętnych ";
                msg_type = 'error';
                v =true;

                opusc = (
                    <div className='detailsButton'>
                        <div className='buttonStandard' onClick={this.opuscKolejke.bind(this)}>Opuść kolejkę</div>                                           
                    </div>                                 
                )       
             //   zapiszButton = '';       
            }



            if(this.state.potwierdzone == 1)
            {
                msg = "Twoje uczestnictwo w tym szkoleniu zostało potwierdzone. ";
                msg_type = 'notice';
                v = true;

                wypiszSie = "";

                // wypiszSie = (
                //     <div className='detailsButton'>
                //         <div className='buttonStandard' onClick={this.wypiszSie.bind(this)}>Wypisz się</div>                                           
                //     </div>                                 
                // )  
              //  zapiszButton = '';
            }

            if(this.state.brak_miejsc == 1)
            {
                msg = "Nie można zapisać się na szkolenie, ponieważ wszystkie miejsca są już zajęte. ";
                msg_type = 'error';
                v= true;
            }

            if(this.state.odwolane == 1)
            {
                msg = "Szkolenie zostało odwołane. ";
                msg_type = 'error';
                v = true;
            }
        
            if(this.state.row.bezterminowe == 'tak')
            {
                v = true;
            }

            if(v == false)
            {
                zapiszButton = (
                    <div className='detailsButton'>
                        <div className='buttonStandard' onClick={this.onClickNewSign.bind(this)}>Zapisz się na szkolenie</div>                                            
                    </div>            
                )                  
            }

        }

        
  
             
    }

    /*var returnButton = (
        <div className='location'><Link to={ConfigX.pathDef}><img src={BackPNG}/> Szkolenia</Link> | {this.props.row.tytul} </div>
    )*/
    var returnButton = '';
    var nrMieszkaniaPodmiot = '';

    if(this.props.row.podmiot_prowadzacy.nr_mieszkania.length > 0)
    {
        var nrMieszkaniaPodmiot = this.props.row.podmiot_prowadzacy.nr_domu+"/"+ this.props.row.podmiot_prowadzacy.nr_mieszkania+",";
    }else{
        var nrMieszkaniaPodmiot = this.props.row.podmiot_prowadzacy.nr_domu+",";                                        
    }
  
    let zapisanych   = this.props.row.zapisanych;
    let ilosc_miejsc = this.props.row.ilosc_miejsc;
    let ilosc = parseInt(ilosc_miejsc) - parseInt(zapisanych);
    
    if( ilosc == 0 || ilosc < 0)
    {
        this.state.brak_miejsc = 1;
    }

    var terminBuff = '';

    if(this.props.row.bezterminowe == 'tak')
    {
        terminBuff = (<div className="">{this.props.row.tresc_terminu}</div>);
    }else{
        terminBuff = this.props.row.terminy.map( (row,index) => {
                               
            if(row.nr_mieszkania.length > 0)
            {
                var nrMieszkania = row.nr_domu+"/"+ row.nr_mieszkania+",";
            }else{
                var nrMieszkania = row.nr_domu+",";                                        
            }
                                        
            return (
                <div key={index}> 
                 
                  <div className='p-1 mb-1'> <span style={{fontWeight:"bold"}}>Data: </span> {row.data_od}</div> 
                  <div className='p-1'> <span style={{fontWeight:"bold"}}>Godzina: </span> {row.data_do} </div>
                  <div className='p-1'> <span style={{fontWeight:"bold"}}>Miejsce: </span> {row.nr_nazwa_pomieszczenia}, ul. {row.ulica}  {nrMieszkania} {row.miasto} <a href={  "https://www.google.com/maps/place/" + row.miasto + "," + row.ulica }  className='link' target="_blank"> <img src={MapPNG} alt='pokaż na mapie' title='pokaż na mapie' className='downloadIco' style={{"verticalAlign":"middle"}}/></a> </div>
                  <div className='p-1'> <span style={{fontWeight:"bold"}}>Prowadzący: </span> {row.osoba_prowadzaca_inne}   </div>
                  <div>&nbsp;</div>

                </div>
            )
        } )
    }




    return ( 
 
        
        
        <div className='details table-content-boot'>
                {content}

                <TipMessage  msg={msg} type={msg_type} />

                {returnButton}

                
                {zapiszButton}{wypiszSie}{opusc}

                <h1 style={{fontFamily: "inherit"}}>&nbsp;{this.props.row.tytul}</h1>

                <div className='slitInfo'>
                    <div className='subTitle'>Cel szkolenia</div> 
                    <p style={{whiteSpace: "pre-wrap"}}>
                        <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}
                        >{cel}
                        </Linkify> 
                    </p>            
                   
                </div>

                <br/>

                <div className='slitInfo'>
                    <div className='subTitle'>Dla kogo?</div> 
                    <div style={{whiteSpace: "pre-wrap"}}>
                    <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}
                        >{dla_kogo}
                    </Linkify>                         
                    </div> 
                </div>

                <br/>

                <div className='slitInfo'> 
                    <div className='subTitle'>Program</div>                     
                    <FileUploader typ="sk_szkolenia_program" typ_id={this.props.row.id} apiKey={this.props.apiKey}  onlyDisplay="1" onlyMyFiles='no' deleteIcon='off' titlex=' ' showDate='off' showSize='off' />  
                </div>

                <br/>

                <div className='slitInfo'>
                    <div className='subTitle'>Termin</div> 
                        <br/>
                        {terminBuff}
                        <br/>
                        
                </div>
                               

                <div className='slitInfo'>
                    <div className='subTitle'>Organizator</div> 
                    <br/>
                    <div className='p-1'>{this.props.row.podmiot_prowadzacy.nazwa}</div> 
                    <div className='p-1'>  
                        ul. {this.props.row.podmiot_prowadzacy.ulica} {nrMieszkaniaPodmiot} {this.props.row.podmiot_prowadzacy.kod_pocztowy} {this.props.row.podmiot_prowadzacy.miasto}                         
                    </div> 
                    <div className='p-1'><span>NIP:</span> {this.props.row.podmiot_prowadzacy.nip}  </div>                                       
                    <div className='p-1'>{this.props.row.podmiot_prowadzacy.email}</div>
                    <div className='p-1'>{this.props.row.podmiot_prowadzacy.telefon}  </div>          
                    

                </div>
                
                <br/>
              
                <div className='slitInfo'>
                    <div className='subTitle'>Pliki do pobrania</div>                     
                    <FileUploader typ="sk_szkolenia" typ_id={this.props.row.id} apiKey={this.props.apiKey} onlyDisplay="1" onlyMyFiles='no' deleteIcon='off' titlex=' ' showDate='off' showSize='off' /> 
                </div>

                <br/><br/>
                
                <div className='slitInfo'>                     
                    <div className="price2">Koszt: <NumberFormat  value={koszt} displayType={'text'} decimalScale={0} /> zł / {this.props.row.podstawa_kwoty}</div>                                                     
                    {kosztBKP}                                                    
                </div>



                <br/>

                <div className='location'>
                    <Link to={ConfigX.pathDef}>
                          <img src={BackPNG}/> Pokaż inne 
                    </Link> 
                    
                    &nbsp;|&nbsp;
                

                    <ReactToPrint
                    trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return  <span className='link'><img src={PrintPNG}/> Drukuj </span>
                    }}
                    content={() => this.componentRef}
                    />
                    <DetailsPrint ref={el => (this.componentRef = el)} row={this.props.row} />                

                    <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime }  msgParam="ok" onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>
                </div>

         </div>
    )
  }

}

export default Details