import React from 'react';

class Main extends React.Component
 {
    constructor(props)
    {
        super(props)
    }

    render()
    {
        return (
            <div> Panel main</div>
        )
    }
 }


 export default Main