
import React from 'react';
import ConfigX from '../ConfigX';
import {Navigate} from 'react-router-dom';

import MenuUser  from '../views/user/MenuUser';
import PanelMain from '../views/user/panel/Main'
import SzkoleniaMain from '../views/user/szkolenia/Main'
import BadaniaMain from '../views/user/badania/Main'
import WiadomosciMain from '../views/user/wiadomosci/Main'
import MojeKontoMain from '../views/user/konto/Main'
import SignOther from '../views/user/sign_other/Main'



class DashboardContainerUser extends React.Component
 {

  constructor(props)
  {
    super(props)
    
    this.state = {
        option: this.props.option,
        refresh: 0,
    }

  }


  componentDidMount()
  {
   // console.log("DashboardContainer mount ");          
  }

  componentDidUpdate()
  {
   // console.log("DashboardConatainer update " + this.props.option);   
    
    if(this.props.option != this.state.option)
    {
      this.setState({
          option: this.props.option
      });
    }
  }

  selectMenu(option)
  {
      //console.log("Select Menu " + option);

      this.setState({
        option: option,
        refresh: this.state.refresh + 1,
      }); 

  }

  render()
  {

    document.title = ConfigX.title  + " / Dashboard";
    //console.log("DashboardContainer: " + this.state.option + " | " + this.props.option);

    if(this.state.option == 1)
    { 
        return (
            <div className='dashboard'>
                <div className='d_menu'>
                     <MenuUser option={this.state.option} selectMenu={this.selectMenu.bind(this)}/>
                </div>           
                <div className='d_menu_tab'>
                    <PanelMain apiKey={this.props.apiKey}  refresh={this.state.refresh}/>
                </div>  
            </div>
        )
    }

    if(this.state.option == 2)
    {
        return (
            <div className='dashboard'>
                <div className='d_menu'>
                     <MenuUser option={this.state.option} selectMenu={this.selectMenu.bind(this)}/>
                </div>           
                <div className='d_menu_tab'>
                    <SzkoleniaMain apiKey={this.props.apiKey}  refresh={this.state.refresh}/>
                </div>  
            </div>
        )
    }

    if(this.state.option == 3)
    {
        return (
            <div className='dashboard'>
                <div className='d_menu'>
                     <MenuUser option={this.state.option} selectMenu={this.selectMenu.bind(this)}/>
                </div>           
                <div className='d_menu_tab'>
                    <BadaniaMain apiKey={this.props.apiKey} refresh={this.state.refresh}/>
                </div>  
            </div>
        )
    }

    if(this.state.option == 4)
    {
        return (
            <div className='dashboard'>
                <div className='d_menu'>
                     <MenuUser option={this.state.option} selectMenu={this.selectMenu.bind(this)}/>
                </div>           
                <div className='d_menu_tab'>
                    <WiadomosciMain apiKey={this.props.apiKey}  refresh={this.state.refresh}/>
                </div>  
            </div>
        )
    }

    if(this.state.option == 5)
    {
        return (
            <div className='dashboard'>
                <div className='d_menu'>
                     <MenuUser option={this.state.option} selectMenu={this.selectMenu.bind(this)}/>
                </div>           
                <div className='d_menu_tab'>
                    <MojeKontoMain apiKey={this.props.apiKey} refresh={this.state.refresh} />
                </div>  
            </div>
        )
    }      

    if(this.state.option == 6)
    {
        return (
            <div className='dashboard'>
                <div className='d_menu'>
                     <MenuUser option={this.state.option} selectMenu={this.selectMenu.bind(this)}/>
                </div>           
                <div className='d_menu_tab'>
                    <SignOther apiKey={this.props.apiKey} refresh={this.state.refresh} />
                </div>  
            </div>
        )
    } 

    return (
        <div className='dashboard'>
            <div className='d_menu'>
                <MenuUser option={this.state.option} selectMenu={this.selectMenu.bind(this)}/>
            </div>           
            <div className='d_menu_tab'>
                <SzkoleniaMain apiKey={this.props.apiKey}  refresh={this.state.refresh}/>
            </div>  
        </div>
    )


  }


}

export default DashboardContainerUser