import React from 'react';


class SortBy extends React.Component
 {
    selected = "";

    constructor(props)
    {
        super(props)
    }

   
    changeSort(event)
    {
        var v = event.target.value;
        //console.log(v);        
        const arr = v.split("@");
        if(arr.length == 2)
        {
            this.selected = arr;
            this.props.changeSort(arr[0],arr[1]);
        }
    }

    render()
    {
        if(this.props.result.order_avilable.length < 1)
        {
            return (
                <span></span>
            )
        }
    
        

        this.props.result.order_avilable.forEach(item => {                      

            if(this.props.result.order.length > 0 && this.props.result.order_col.length > 0)
            {
                const tmp = this.props.result.order_col + "@" + this.props.result.order;
                this.selected = tmp;
            }
        });
      
       

        return(
            <div className='sortby'>
                Sortuj wg.
                <select onChange={this.changeSort.bind(this)}  value={this.selected}  >
                    <option key={0}> -- </option>                   
                    {
                        this.props.result.order_avilable.map(item => {
                            
                            return (
                                <option key={item.column  +"@" + item.by }     value={item.column  +"@" + item.by}>{item.name }</option>
                            )
                        })
                    }

                </select>
            </div>
        );
    }
 }

 export default SortBy;