import React from 'react';

import checkPNG from '../../../image/check.png'
import nonePNG from '../../../image/none.png'
import infoPNG from '../../../image/info.png'
import { Link } from 'react-router-dom';
import ConfigX from '../../../ConfigX';

import Messanger from '../../../components/Messanger';
 
class TableRow extends React.Component
 {
        constructor(props)
        {
            super(props)
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onRowClick(id);
        }

        render()
        {            
            var pozostalo = parseFloat(this.props.row.do_zaplaty) - parseFloat( this.props.row.kwota_rozliczenia );
            var clsRow = '';
            var clsRow2 = '';
           
            var termin = this.props.row.sugerowany_termin_platnosci;
            if(termin == '0000-00-00') { termin = ''; }
            
            var dataWystawienia = this.props.row.data_wystawienia;
            if(dataWystawienia == '0000-00-00') { dataWystawienia = ''; }

            if(pozostalo <= 0)
            {
                clsRow = 'row_green';
                clsRow2 = 'text_green'; 
            }else
            {
                clsRow2 = 'text_red';
            }

            var formatDoZaplaty        = parseFloat(this.props.row.do_zaplaty).toFixed(2); 
            var formatKwotaRozliczenia = parseFloat(this.props.row.kwota_rozliczenia).toFixed(2); 
            var formatPozostalo        = parseFloat(pozostalo).toFixed(2); 

            return (                 
                        <tr className={" rowLink " + clsRow} >
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} >  {this.props.num} </td>                                                   
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} >  <Messanger apiKey={this.props.apiKey}    user_id={this.props.row.odbiorca.id} /></td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} >  {this.props.row.info} </td>                                                                                                                                   
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} >  {this.props.row.odbiorca.nazwisko}  {this.props.row.odbiorca.imie} / {this.props.row.firma.nazwa}     </td>                                                   
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" >  {this.props.row.numer_dokumentu} </td>                                                   
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" >  {dataWystawienia} </td>                                                   
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" >  {termin} </td>                                                   
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="number" >  {formatDoZaplaty} </td>                                                   
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="number" >  {formatKwotaRozliczenia} </td>                                                   
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className={"number " + clsRow2} >  {formatPozostalo} </td>                                                   
                        </tr>                 
            )
        }
 }

 export default TableRow;