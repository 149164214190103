import React from "react";

import CertyfikatBG from '../../../image/certyfikat_bg2.png';
import ConfigX from "../../../ConfigX";

class Certificate extends React.Component
{


  diff_hours(dt2, dt1) 
  {
 
   var diff =(dt1.getTime() - dt2.getTime()) / 1000;
   var hours = Math.floor(diff / 45); 
    
    var minutes = Math.floor(hours / 60); 
    var seconds = hours % 60; 

   return minutes +', '+seconds+'h ';
   
  }

 render()
 {

    

    const mystyle = {
        color: "black", 
        width: "100%",
        height: "100%",
        fontFamily: "Cambria",
        fontSize: "10px",
        fontWeight:"normal",
      //  overflow: "scroll",
      }

      const styleTab = {
        width: "100%",
        fontSize: "12px",
      }


      const styleTr = {
        backgroundColor: "#ddffdd"
      }

      const imgStyle = { 
        position: 'absolute', 
        left: '0', 
        top: '0', 
        zIndex: -1, 
    }


      const textStyle = { 
        zIndex: 5,        
        textShadow: '2px 2px 4px white', 
        position: "relative",
        top: '280px',
        bottom: '0px',
        marginRight: '10px',
        marginLeft: '10px',
        letterSpacing: "1px"
      
    }

    const row1 = {fontSize: "22px", textAlign: 'center' }      
    const row2 = {fontWeight: "bold", fontSize: "35px", textAlign: 'center', }
    const row3 = {fontSize: "22px", textAlign: "center",}
    const row4 = {color: "red", fontWeight: "bold", fontSize: "35px", textAlign: "center",}
    const row5 = {fontSize: "22px", textAlign: 'center',}
    const row6 = {textAlign: "center", fontSize: "12px", float: "left"}
    const row7 = {textAlign: "center", fontSize: "12px", float: "left"} 
    const row8 = {textAlign: "center", fontSize: "10px", float: "right", marginRight: "80px"}
    const row9 = {textAlign: "center", fontSize: "14px", float: "right", marginRight: "80px"}
 
    const div_img = {
      zIndex: -1,
      backgroundImage: `url(${CertyfikatBG})`, 
      width:"100%",
      height: "1010px",
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      color: "black", 
      fontFamily: "Cambria",
      fontSize: "10px",
      fontWeight:"normal",
      //overflow: "scroll",
      //marginBottom: "100px"
      margin: "10px"
    }


    var rows = this.props.rows; 
  
    const print = [];

    if(rows.length > 0)
    {
        var data_szkolenia = this.props.data;

        rows.map((item,index)=>{
       
          if(item.status != 'oczekuje')
          {
            if(item.terminy.length > 0)
            {
              var dd_od  = new Date(item.terminy[0].termin.data_od); 
              var dd_do  = new Date(item.terminy[0].termin.data_do); 
  
              var y_od = dd_od.getFullYear(); 
              var m_od = dd_od.getMonth()+1;
              var d_od = dd_od.getDate();
              var t_od = dd_od.getDay();
  
              if(d_od < 10) d_od = "0"+d_od;
              if(m_od < 10) m_od = "0"+m_od; 
  
              var data_od  =  d_od+'-'+m_od+'-'+y_od;
  
              var hours = this.diff_hours(dd_od, dd_do);   
              var osoba_prowadzaca = item.terminy[0].termin.osoba_prowadzaca;
            }else{
              var hours = 0;
              var osoba_prowadzaca = '';
            }
      
          //  var hours = Math.floor(data_do - data_od) / 36e5;

            print.push( 
  
              <div key={index} className="printView" style={ div_img } >       
                                            
                  <div style={textStyle}>
      
                      <div style={row1}>Zaświadcza się, że Pan/Pani:</div><br/><br/><br/>
                      <div style={row2}>{item.imie} {item.nazwisko}</div><br/><br/><br/>
                      <div style={row3}>ukończył szkolenie pn.:</div><br/><br/><br/>
                      <div style={row4}>{this.props.title}</div><br/><br/><br/><br/>
                      <div style={row5}>które odbylo się w dniu {data_szkolenia} r.</div><r/><br/><br/>
      
                      <br/><br/><br/>
      
                    <div style={row6}> 
                      <div >Szkolenie prowadził: {osoba_prowadzaca}</div><br/>
                      <div >Liczba godzin dydaktycznych szkolenia: {hours}</div><br/>
                    </div>
      
                      <br/><br/><br/><br/><br/><br/>
                    
                  
      
                    <div style={row8} >
                      <div >.......................................</div><br/>
                      <div >prowadzący szkolenie</div>
                    </div>
      
                  </div>
                  
                  <div className="page-break"></div>
              </div>              
            
          )

          }

        })
    }



      return(
             <div>{print}</div>
      )

      
    }


 }



export default Certificate

/*
  <img key={index} src={CertyfikatBG} alt={index} style={imgStyle} /> 
  print = ( 

                <div key={index} className="printView" style={ mystyle } >
                    
                    <img src={CertyfikatBG} alt='' style={imgStyle} />
        
                    <div style={textStyle}>
        
                        <div style={row1}>Zaświadcza się, że Pan:</div><br/><br/><br/><br/><br/>
                        <div style={row2}>{item.imie} {item.nazwisko}</div><br/><br/><br/><br/><br/>
                        <div style={row3}>ukończył szkolenie pn.:</div><br/><br/><br/><br/>
                        <div style={row4}>{this.props.title}</div><br/><br/><br/><br/>
                        <div style={row5}>które odbylo się w dniu {item.data} r.</div><r/><br/><br/><br/>
        
                        <br/><br/><br/><br/><br/><br/><br/><br/>
        
                      <div style={row6}> 
                        <div >Szkolenie prowadził: {item.prowadzacy}</div><br/>
                        <div >Liczba godzin dydaktycznych szkolenia: 8</div><br/>
                      </div>
        
                        <br/><br/><br/><br/><br/><br/><br/><br/>
                        <br/><br/><br/><br/><br/><br/><br/><br/>
                        <br/><br/><br/><br/><br/><br/><br/><br/>
        
                      <div style={row8} >
                        <div >.......................................</div><br/>
                        <div >prowadzący szkolenie</div>
                      </div>
         
                    </div>
                    <div className="pagebreak"></div>
                </div>              
              
            )

*/