import React from 'react';

class AlertYesNo extends React.Component
 {
    constructor(props)
    {
        super(props)

      

        this.state = {
            title: this.props.title,            
            msg: this.props.msg, 
            msgTime: this.props.msgTime,
            view: "dialog",
            param: this.props.msgParam,
            param1: this.props.alertParam1,
            param2: this.props.alertParam2,
        } 
        
       
    }
 
    showDialog()
    {
        this.setState({
            view: "dialog"
        });
        
    }

    onDialogYes()
    {
        this.setState({
            view: "close",
            msg: ''
        }); 

        if(this.props.onDialogYes)
        {
          this.props.onDialogYes(this.state.msgParam, this.state.param1, this.state.param2);
        }
    }

    onDialogNo()
    {
        this.setState({
            view: "close",
            msg: ''
        }); 

        if(this.props.onDialogNo)
        {
          this.props.onDialogNo(this.state.msgParam, this.state.param1, this.state.param2);
        }
    }

    componentDidUpdate()
    {       

        if( this.props.msgTime &&  this.props.msgTime != this.state.msgTime)
        {
            this.setState({
                msg: this.props.msg,
                msgTime: this.props.msgTime,
                view: "dialog",
                msgParam: this.props.msgParam,
                param: this.props.msgParam,
                param1: this.props.alertParam1,
                param2: this.props.alertParam2,
            });
        }
    }

 

    render()
    {        
      //  console.log("AlertMSG " + this.state.view + " | " + this.state.msg);

        if(this.state.view == "dialog" && this.state.msg.length > 2)
        {
            return (
                <div className="dialog_alert">                           
                  <div className="DialogContent">
                    <div className='dialog_title'>{this.state.title}</div>
                    <div className='dialog_container'>
                        <div className='dialog_query'>{this.state.msg}</div>
                        <div className="buttonForm buttonAlert" onClick={this.onDialogYes.bind(this) } >TAK</div>    
                        &nbsp; &nbsp;                 
                        <div className="buttonForm buttonAlert" onClick={this.onDialogNo.bind(this) } >NIE</div>    
                    </div>
                  </div>
                </div>
            );
        }
    }
 }

 export default AlertYesNo;