import React from "react";


import ConfigX from '../../../ConfigX'
import TipMessage from '../../../components/TipMessage';

import FirmSelector from '../../../components/FirmSelector';

import BackPNG from './../../../image/back.png'
import PrintPNG from './../../../image/print.png'

import loading from '../../../image/loading.gif'
import ButtonConfirm from '../../../components/ButtonConfirm';
import AlertMsg from '../../../components/AlertMsg';

import FileUploader from '../../../components/FileUploader';

import Messanger from '../../../components/Messanger';

import MainFirm from '../baza_firm/Main';
import DetFirm from '../baza_firm/Det';

class AddPerson extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            loading: 1,
            search_bar: {                                                         
                fraza: "",        
                id: this.props.id,                    
            },
            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },
            rows: [],
            result: [],
 

            alertMSG: '',
            alertMSGTime: 0,

            nn_nazwisko: "",      
            nn_imie: "",      
            nn_ulica: "",      
            nn_nr_domu: "",      
            nn_nr_mieszkania: "",      
            nn_kod_pocztowy: "",      
            nn_miasto: "",      
            nn_telefon: "",      
            nn_email: "", 
            back: this.props.back, //parametr mówiący gdzie ma wrócić przekierowanie
            view: "",

            sk_firmy_id: -1,
            redirect_param: "",
        }

        if(this.props.id) // -1 oznacza, że jest to nowy pracownik
        {
            this.state.search_bar.id = this.props.id;
        }

        if(this.props.sk_firmy_id)
        {
            this.state.sk_firmy_id = this.props.sk_firmy_id;
        }
        
        if(this.props.param)
        {
            this.state.redirect_param = this.props.param;
        }

    }



    componentDidMount()
    {
        console.log("Det did mount");

            this.getData();          
    } 


      //Pobranie danych
    getData()
      {
  
          //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
          var wait = setTimeout(
               () => {
                  this.setState({ loading: 1 });
               }
              ,500);
  
          var tmpNonRef = JSON.stringify( this.state );
          tmpNonRef = JSON.parse(tmpNonRef);
  
          var dataPost = tmpNonRef.search_bar;
          dataPost.pagger = tmpNonRef.pagger;
  
      
          
          //console.log("Requets " +  JSON.stringify(dataPost) );
  
          fetch( ConfigX.restApi + "/dash,persons" , {
              method: 'POST', 
              body: JSON.stringify(dataPost), 
              headers: {
                  'Content-Type': 'text/html',
                  'X-API-KEY': this.props.apiKey
              }
          })
          .then( res => res.json() )
          .then(json => {
          
               //console.log(JSON.stringify(json) );
               clearTimeout(wait);
  
               this.setState({
                  rows: json.rows[0],
                  result: json.result, //zawsze pierwszy indeks
                  loading: 0
               });      
             
          });
    }


    onBackClick()
    {     
      //  console.log("back: ",this.state.back);
        if(this.state.back == 'baza_firm')
        {
            this.props.redirect("/baza_firm/Det", this.props.id);

            //this.setState({
            //    view: "baza_firm"
            //})
        }else{
            this.props.redirect("list", this.props.id);
        }
        
    }

  

    onChangeNazwisko(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 2)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["nazwisko"] = v; 

            this.setState( {
                rows: rows,
                nn_nazwisko: ok
            } );
    }  

    onChangeImie(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 2)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["imie"] = v; 

            this.setState( {
                rows: rows,
                nn_imie: ok
            } );
    }      
 
 
    onChangeUlica(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["ulica"] = v; 

            this.setState( {
                rows: rows,
                nn_ulica: ok
            } );
    } 


    onChangeNrDomu(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["nr_domu"] = v; 

            this.setState( {
                rows: rows,
                nn_nr_domu: ok
            } );
    } 
 
    onChangeNrMieszkania(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            //if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["nr_mieszkania"] = v; 

            this.setState( {
                rows: rows,
                nn_nr_domu: ok
            } );
    }     
  

    onChangeKod(event)
    {
        var ok = "";
     
    
        var v = event.target.value;        

       // v = v.replace(/[^\d.-]/g, '');

      //  const arr = v.split("-");
      //  if(arr.length == 2 && arr[0].length == 2 && arr[1].length == 3 && /^\d+$/.test(arr[0])  && /^\d+$/.test(arr[1])   )
     //   {
            ok ="correct";
     //   }else ok ="incorrect";

       
        var rows = this.state.rows;
        rows["kod_pocztowy"] = v; 

        this.setState( {
            rows: rows,
            nn_kod_pocztowy: ok
        } );

    }
 
    onChangeMiasto(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["miasto"] = v; 

            this.setState( {
                rows: rows,
                nn_miasto: ok
            } );
    } 

 
    onChangeTelefon(event)
    {
              var v = event.target.value;

               // v = v.replace("+48","");

              //  v = v.replace(/[^0-9]/g, ""); //wywal litery...
                v = v.replace(" ","");

               // if(v.substr(0,3) != '+48')
              //  {
              //      v = "+48" + v;
              //  }
            
                
                var ok = "";
                if(v.length >8  ) 
                {
                    ok = "correct";
                }

                var rows = this.state.rows;
                rows["telefon"] = v; 
    
                this.setState( {
                    rows: rows,
                    nn_telefon: ok
                } );
    }

    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

    onChangeEmail(event)
    {
          var v = event.target.value;
        v = v.replace(" ","");

        var ok = "";
        if(v.length > 0 && this.isValidEmail(v) ) 
        {
           ok = "correct";
        }else
        if(v.length > 0)
        {
            ok ="incorrect";
        }

        var rows = this.state.rows;
        rows["email"] = v; 

        this.setState( {
            rows: rows,
            nn_email: ok
        } );
    }

 
  

    onConfirmDel(type, param, param_id)
    {
        console.log(type + param + param_id);

       if(type != "yes")  return;


       var dt = new Date();
       dt = dt.toISOString();

      
         var dataPost = {
            id: param_id,       
        };

   
        fetch( ConfigX.restApi + "/dash,del_person" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             if(json.status == "OK")
             {
                this.zamknij();
             }                 

             if(json.status == "ERROR")
             {                                             
                  this.setState({
                      alertMSG:  json.param,
                      alertMSGTime: dt
                   });
             }    

        });

        
        
        
    }

    onChangeStatus(event)
    {
        var v   = event.target.value;      

        var rows = this.state.rows;
        rows["czlonek_bkp"] = v; 

        this.setState( {
            rows: rows,     
        } );

        //this.updateSzkolenie(null);
    }

    onSelectedFirm(id, nazwa)
    {
        var tmp = this.state.rows; 
        tmp.sk_firmy_id = id;
        tmp.nazwa_firmy = nazwa;

        this.setState({
            rows: tmp                        
        });

    }
    zamknij(event)
    {
        this.props.onClose();
    }

    updateUczestnik(event)
    {

        var err = false;

        if(this.state.rows.imie.length < 2 || this.state.nn_imie == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_imie: "incorrect"
            });
        }

        
        if(this.state.rows.nazwisko.length < 2 || this.state.nn_nazwisko == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_nazwisko: "incorrect"
            });
        }

        if(this.state.rows.ulica.length < 2 || this.state.nn_ulica == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_ulica: "incorrect"
            });
        }        
    
        if(this.state.rows.nr_domu.length < 1 || this.state.nn_nr_domu == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_nr_domu: "incorrect"
            });
        }        
            

        if(this.state.rows.kod_pocztowy.length < 2 || this.state.nn_kod_pocztowy == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_kod_pocztowy: "incorrect"
            });
        }         


        if(this.state.rows.miasto.length < 2 || this.state.nn_miasto == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_miasto: "incorrect"
            });
        }         

        
     
        if(err) return;

        var bnText = event.target.innerHTML;
        event.target.innerHTML = "...";

        if(this.props.id > 0) //edit
        {
            var typ = "edit";
        }else{
            var typ = "new";
        }
        
        var dataPost = {
            id: this.state.rows.id,       
            imie: this.state.rows.imie,                      
            nazwisko: this.state.rows.nazwisko,        
            ulica: this.state.rows.ulica,        
            nr_domu: this.state.rows.nr_domu,        
            nr_mieszkania: this.state.rows.nr_mieszkania,        
            kod_pocztowy: this.state.rows.kod_pocztowy,        
            miasto: this.state.rows.miasto,        
            email: this.state.rows.email,        
            telefon: this.state.rows.telefon,        
            sk_firmy_id: this.state.rows.sk_firmy_id,  

            typ: typ // rozróżnienie czy nowy rekord czy nie

           // czlonek_bkp: this.state.rows.czlonek_bkp,      
        }

     //   console.log(JSON.stringify(this.state.rows)  );
     //   console.log(JSON.stringify(dataPost) );


        fetch( ConfigX.restApi + "/dash,add_edit_uczestnik" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {

              var dt = new Date();
              dt = dt.toISOString();

             if(json.status == "OK")
             {
                 event.target.innerHTML = bnText;
                                  
                 this.setState({
                    alertMSG: 'Informacje zostały zapisane.',
                    alertMSGTime: dt,                   
                 });

                 if(json.typ == 'new')
                 {
                    this.zamknij();

                  //  this.setState({
                  //      view: "det_firm" 
                  //  })
                 }
         
             }else
             {
                 this.setState({
                     alertMSG: json.param,
                     alertMSGTime: dt
                  });  
             }
      
           
        });

    }


  /*
                             <div className='FormField '>
                               <label>Członek BKP</label>
                               <select defaultValue={this.state.rows.status} onChange={this.onChangeStatus.bind(this) } col="czlonek_bkp" >
                                  <option value="0">NIE</option>              
                                  <option value="1">TAK</option>                          
                               </select>
                            </div> 
  */
 
    
 
    render()
    {

        var msg = "";

        if(this.state.sk_firmy_id > 0) //jeżeli jest podane id_firmy np. z widoku baza firm dodajemy pracownika do firmy
        {
            this.state.rows.sk_firmy_id = this.props.sk_firmy_id;
            this.state.rows.nazwa_firmy = this.props.title;
        }
 
        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>                                               
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        } 

        if(this.state.view == "baza_firm" )
        {
             return (
                 <div className='list'>                                                                       
                     <MainFirm apiKey={this.props.apiKey} /> 
                 </div>
             )
        }   

        if(this.state.view == "det_firm" )
        {
             return (
                 <div className='list'>                                                                       
                     <DetFirm apiKey={this.props.apiKey} id={this.state.sk_firmy_id} redirect={this.props.redirect(this)}  refresh={Math.random()*10}/> 
                 </div>
             )
        }  

        if(this.state.rows == null || this.state.rows == 'undefinded') return null;

        var podmiot =   <div className='FormField'>
        <label>Nazwa firmy</label>
        <div className="text_bold">{this.state.rows.nazwa_firmy}</div>
   </div>

        if(this.state.rows.nazwa_firmy == "---") podmiot = null 
        
        var dodaj_pliki = '';
        var usun_btn = '';

        if(this.props.id > 0)
        {
            dodaj_pliki = (
                <div>
                    <br/>
                    <FileUploader typ="px_group_adm" typ_id={this.state.rows.id} apiKey={this.props.apiKey}  titlex="Powiązane dokumenty" />
                    <br/>
                </div>
            )

            usun_btn = (

                <>
                    <ButtonConfirm   onConfirm={this.onConfirmDel.bind(this) } param="px_group_id" param_id={this.state.rows.id}  title="usuń z systemu*" query="Uwaga, usuwasz użytkownika z systemu, czy chcesz to zrobić?"  />                                                                           

                    <div className='simplyTipAlert'>
                        Nie można usunąć użytkownika gdy: <br/><br/>
                        *użytkownikiem jestem ja<br/>
                        *użytkownikiem jest administrator systemu<br/>                                              
                    </div>
                </>
            )
        }

        return (
            <div className="_editor">   
                      
            <div className="info_shadow">                                                     
            <span className='window window_form'>
                <div className='container container_form'>   
                 
                                    
                    <TipMessage  msg={msg} type="notice" />
                  

                    <div className='dashForm'>
                           
                   
                            {podmiot}
                         
 

                            <div className='FormField inlineField'>
                               <label>Imie<span className='required'>*</span></label>
                               <input type="text" className={"field200 " + this.state.nn_imie } value={this.state.rows.imie}  col="" onChange={this.onChangeImie.bind(this) } />
                            </div>


                            <div className='FormField inlineField'>
                               <label>Nazwisko<span className='required'>*</span></label>
                               <input type="text" className={"field200 " + this.state.nn_nazwisko } value={this.state.rows.nazwisko}  col="" onChange={this.onChangeNazwisko.bind(this) } />
                            </div>

                            <br/>

                            <div className='FormField inlineField'>
                               <label>Ulica<span className='required'>*</span></label>
                               <input type="text" className={"field300 " + this.state.nn_ulica } value={this.state.rows.ulica}  col="ulica" onChange={this.onChangeUlica.bind(this) } />
                            </div>

                            <div className='FormField inlineField'>
                               <label>Nr domu<span className='required'>*</span></label>
                               <input type="text" className={"field50 " + this.state.nn_nr_domu } value={this.state.rows.nr_domu}  col="" onChange={this.onChangeNrDomu.bind(this) } />
                            </div>

                            <div className='FormField inlineField'>
                               <label>Nr mieszkania</label>
                               <input type="text" className={"field50 " + this.state.nn_nr_mieszkania } value={this.state.rows.nr_mieszkania}  col="" onChange={this.onChangeNrMieszkania.bind(this) } />
                            </div>
                        
                            <br/>

                            <div className='FormField inlineField'>
                               <label>Kod pocztowy<span className='required'>*</span></label>
                               <input type="text" className={"field100 " + this.state.nn_kod_pocztowy } value={this.state.rows.kod_pocztowy}  col="" onChange={this.onChangeKod.bind(this) } />
                            </div>

                            <div className='FormField inlineField'>
                               <label>Miasto<span className='required'>*</span></label>
                               <input type="text" className={"field200 " + this.state.nn_miasto } value={this.state.rows.miasto}  col="" onChange={this.onChangeMiasto.bind(this) } />
                            </div>


                            <br/>

                            <div className='FormField inlineField'>
                            <label>Telefon<span className='required'>*</span></label>
                            <input type="text" className={"field100 " + this.state.nn_telefon } value={this.state.rows.telefon}  col="" onChange={this.onChangeTelefon.bind(this) } />
                            </div>


                            <div className='FormField inlineField'>
                            <label>E-mail<span className='required'>*</span></label>
                            <input type="text" className={"field300 " + this.state.nn_email } value={this.state.rows.email}  col="" onChange={this.onChangeEmail.bind(this) } />
                            </div>

                   

 

                          <div className='FormField FormButtons'>
                            <div className='buttonNewItem' onClick={this.updateUczestnik.bind(this)}>zapisz</div>  
                            &nbsp; &nbsp;   

                            <div className='buttonForm' onClick={this.zamknij.bind(this)}>Anuluj</div>  
                            &nbsp; &nbsp;   

                            {usun_btn}

                            

                          </div>

                    </div>


                    {dodaj_pliki}
 
             
                    
                    
                    <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Powiadomienie"/>
                   
                    </div>
                  </span>
                </div>
            </div>
        )
    }
}

export default AddPerson;