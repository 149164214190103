import React from 'react';
import TableRow from './TableRow';
import EmptyTab from '../../../components/EmptyTab';

 

class Table extends React.Component
 {
        constructor(props)
        {
            super(props)
        }


        onRowClick(id)
        {
            this.props.redirect("det", id);
        }

        render()
        {
            var i = 0;

            if(this.props.rows.length == 0)
            {
                return (
                        <EmptyTab msg="Ta tabelka jest pusta lub brak wyników wyszukiwania"/>
                )
            }

            return (
                <div className='table_content'>
                 

                    <table className='table'>
                      <thead>
                        <tr>
                             <td>LP</td>
                             <td>Status</td>
                             <td>Podmiot prowadzący</td>
                             <td>Osoba kontaktowa</td>                   
                             <td>Tytuł</td>                   
                             <td>Informacje ogólne</td>                                                                  
                             <td>Liczba wniosków</td>                                                                  
                             <td>Badań w trakcie</td>                                                                  
                          
                        </tr>
                      </thead>
                      <tbody> 
                        {
                           
                            this.props.rows.map( item   => {
                                i++;
                                return (
                                    <TableRow key={i} num={i} row={item} onRowClick={this.onRowClick.bind(this)} />
                                )
                            } )
                        } 
                    </tbody>    


                    </table>

                </div>
            )
        }
 }

 export default Table;