import React from 'react';
import { withRouter } from 'react-router-dom';

import ConfigX from '../../../ConfigX'

import Det from './Det';
import AlertMsg from '../../../components/AlertMsg';
import DetTerminyRow from './DetTerminyRow';
import loading from '../../../image/loading.gif'
import FileUploader from '../../../components/FileUploaderUser';
import Linkify from 'react-linkify';

import EmptyTab from '../../../components/EmptyTab';

class Main extends React.Component
 {
    constructor(props)
    {        

        super(props);         

        this.state = {

            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },
            loading: 1,
            view: "list",
            selectedId: -1,
            rows: [],
            result: [],
            oczekuje: 0,
            potwierdzone : 0,
            id_uczestnika: 0,

            newButton : "",
            alertMSG: '',
            alertMSGTime: 0
           
        }

        
        
    }



    //Stronnicowanie...
    changePage(page)
    {
        

       //console.log("changePage: " + page );    
       //this.state.pagger.page = parseInt(page);          
       this.getData(null);      
    }

    changeLimit(limit)
    {
      
       // console.log("changeLimit: " + limit );                        
        //this.state.pagger.page = 1;
        //this.state.pagger.limit = parseInt(limit);
        this.getData(null);
    }

    //Sortowanie..

    changeSort(column, sort)
    {
      //  console.log("changeSort: " + column + " | "  + sort );    

         //this.state.pagger.order_col = column;
        // this.state.pagger.order = sort;
         this.getData(null);
    }



    //Pobranie danych
    getData(  onFinish )
    {
 
        //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
        var wait = setTimeout(
             () => {
                this.setState({ loading: 1 });
             }
            ,500);

           

        var tmpNonRef = JSON.stringify( this.state );
        tmpNonRef = JSON.parse(tmpNonRef);
      
      
        var dataPost = {};
        //console.log("Requets " +  JSON.stringify(dataPost) );


        fetch( ConfigX.restApi + "/user,get_training" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        
             //console.log(JSON.stringify(json) );
             clearTimeout(wait);

             this.setState({
                rows: json.rows,
                result: json.result,
                loading: 0
             }); 
                         
             if(onFinish != null && onFinish != undefined ) onFinish();
           
        });
    }
        
   
    redirect(action, id, param)
    {
      

        if(param == "deleted") //usunięto szkolenie
        {
            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: "Szkolenie zostało usunięte!",
                alertMSGTime: dt
            });                  
        }

 
       

        if(action == "list")
        {
            this.getData(null);
        }
     
        
        this.setState(
            {
                view: action,
                selectedId: id,
            }
           );
    }

    componentDidUpdate()
    {
       // console.log(document.location.href + " eee ");
    }

    componentDidMount()
    {
        //console.log("List mount " + this.state.search_bar);    
        this.getData(null);                    
    }



    onDialogOK(param)
    {
      //  console.log("click ok dialog");
            this.setState({
                alertMSG: '',
                alertMSGTime: 0,
                id_uczestnika: 0,                
            });
            this.getData(null);
    }


    wypiszSie(event)
    {
      

        var dataPost = {
            id_uczestnika: event.target.id
         };    
  
  
         fetch( ConfigX.restApi + "/user,wypiszSie" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() )
         .then(json => {
        
            if(json.status == "OK")
            {            
                this.setState({
                  alertMSG: "zostałeś pomyślnie wypisany ze szkolenia",
                  alertMSGTime: 12
                })
            }
              
             
         });                      
    }
  
    opuscKolejke(event)
    {

        var dataPost = {
            id_uczestnika: event.target.id
         };    
  
  
         fetch( ConfigX.restApi + "/user,opuscKolejke" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() )
         .then(json => {
        
          if(json.status == "OK")
          {            
              this.setState({
                alertMSG: "pomyślnie opuściłeś kolejkę",
                alertMSGTime: 12
              })
          }             
             
         });                      
    }

    render()
    {         

       if(this.state.loading == 1)  
       {
           return (
            <div className='list'>
              
               <div className='table_content'>
                 <div className='loading'><img src={loading} /></div>
               </div>
             </div>
           )
       }
             
       
       if(this.state.view == "det" && this.state.selectedId > 0)
       {
            return (
                <div className='list'>
                    
                    <Det redirect={this.redirect.bind(this) } id={this.state.selectedId} apiKey={this.props.apiKey} /> 
                </div>
            )
       }

       var i = 0;
  
       //------------------------------------------
       //------------------------------------------
       //------------------------------------------

       var wypiszSie = '';
       var opusc = '';
       var status_szkolenia = '';
       var komunikat = '';
       var extra_section = '';



       if(this.state.rows.length == 0)
       {
            return (
                <div className='rowTrain'>
                  <section className="container">
                       <h1>Twoje szkolenia</h1>
                       <div>Nie jesteś zapisany na żadne szkolenie.</div>
                  </section>
                </div>
            )
       }


            return (

                <div className='rowTrain'>
                  <section className="container"> 
                       <h2>Twoje szkolenia</h2>
                      
                 
                    {
                
                        this.state.rows.map( item   => {
                                    i++;

//console.log("item: ",item);
                                    if(item.status2 == 'potwierdzone')
                                    {
                                        // wypiszSie = (
                                        //     <div className='detailsButton'>
                                        //         <a id={item.id_uczestnika} href='#' onClick={this.wypiszSie.bind(this)}>Wypisz się</a>                                           
                                        //     </div>                 
                                            
                                        // )
                                        
                                        wypiszSie = '';

                                        status_szkolenia = (
                                            <span style={{"color":"green"}}>Status {item.status2}</span>                                         
                                        )   

                                        komunikat = (
                                            <div className='info_succ'>Szkolenie zostało potwierdzone</div>
                                        ) 
                                    }
                                    if(item.status2 == 'oczekuje')
                                    {
                                         opusc = (
                                            <div className='detailsButton'>
                                                <a id={item.id_uczestnika} href='#' onClick={this.opuscKolejke.bind(this)}>Opuść kolejkę</a>                                           
                                            </div>                 
                                            
                                        ) 
                                        
                                        status_szkolenia = (
                                            <span style={{"color":"#e6e600"}}>Status {item.status2}</span>                                         
                                        )                                        
                                        
                                    }  

                                    if(item.status2 == 'odrzucone brak miejsc' || item.status2 == 'odrzucone odwolanie szkolenia' )
                                    {
                                        status_szkolenia = (
                                            <span style={{"color":"red"}}>Status {item.status2}</span>                                                
                                        )
                                    }

                                    if(item.status2 == 'wypisany')
                                    {
                                        status_szkolenia = (
                                            <span style={{"color":"#e6e600"}}>Status {item.status2}</span>                                                
                                        ) 
                                        komunikat = (
                                            <div className='info_war'>Zostałeś wypisany ze szkolenia.</div>
                                        )                                                                                
                                    }

                                    if(item.faktura)
                                    {
                                       extra_section = (
                                            <section className='card-cont date2'>
                                                <h3>Wystawiono fakturę</h3>
                                                <br/><br/><br/><br/>
                                                <div className="even-date">
                                                
                                                <span style={{"color":"#000"}}>Numer dokumentu </span> <br/>{item.faktura.numer_dokumentu}<br/>
                                                <span style={{"color":"#000"}}>Kwota do zapłaty</span> <br/>{item.faktura.do_zaplaty} <br/>

                                                </div>

                                                <div className="even-info">
                                        
                                                    <p>                                
                                                        <span style={{"color":"#000"}}>Pobierz fakturę </span>  <br/>                                                                  
                                                    </p>
                                                   
                                                    <FileUploader typ="fak_faktury" typ_id={item.faktura.id} apiKey={this.props.apiKey} onlyDisplay="1"/>                                          
                                                                                                                                                
                                                </div>
                                              
                                               

                                            </section>                                        
                                       )
                                       
                                    }


                                    return (
                                        
                                        <div className="row_szkolenia" key={i}>
                                            {komunikat}
                                            <article className="card fl-left">
                                            <section className="date">
                                                <time dateTime="23th feb">
                                                <span>{item.tytul}</span>   
                                                {status_szkolenia}                                       
                                                </time>
                                            </section>
                                            <section className="card-cont">
                                                
                                                <h3>{item.podmiot_prowadzacy.nazwa}</h3>
                                               
                                                <div className="even-date">
                                                
                                                {
                                                    item.terminy.map( (item,index) => {
                                                
                                                        return(                                                           
                                                            <DetTerminyRow num={index+1} key={index} row={item} />
                                                        )
                                                    } )
                                                }
                                         
                                              
                                                </div>
                                                <div className="even-info">
                                          
                                                <p>
                                                    <span style={{"color":"#000"}}>Opis</span><br/>
                                                    <Linkify
                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                            <a target="blank" href={decoratedHref} key={key}>
                                                                {decoratedText}
                                                            </a>
                                                        )}
                                                    >{item.cel_szkolenia}
                                                    </Linkify>                                                     
                                                    <br/><br/>
                                                    <span style={{"color":"#000"}}>Wymagane od uczestnika </span><br/>
                                                    <Linkify
                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                            <a target="blank" href={decoratedHref} key={key}>
                                                                {decoratedText}
                                                            </a>
                                                        )}
                                                    >{item.wymagane_od_uzytkownika}
                                                    </Linkify>                                                     
                                                    <br/><br/>
                                                    <span style={{"color":"#000"}}>Cena </span><br/>{item.koszt_szkolenia} zł
                                                    <br/><br/>
                                                    <span style={{"color":"#000"}}>Cena (dla członków BKP) </span><br/>{item.koszt_szkolenia_bkp} zł
                                               
                                              
                                                    <br/><br/>
                                             
                                                 </p>
                                                    {/*
                                                    <div><FileUploader typ="sk_szkolenia_program" titlex="Program" typ_id={item.id} apiKey={this.props.apiKey} onlyDisplay="1" deleteIcon="off" showSize="off" showDate="off"/></div>
                                                    <div><FileUploader typ="sk_szkolenia" typ_id={item.id} apiKey={this.props.apiKey} onlyDisplay="1" deleteIcon="off" showSize="off" showDate="off"/></div>
                                                    */}
                                                </div>
                                                <br/><br/>
                                                {wypiszSie}{opusc}
                                            </section>

                                                {extra_section}

                                            </article>

                                        </div>
                                    )
                                } )

                    }
                        </section>

                        <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } msgParam="ok"  onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/> 

                    {/* 
                    <SortBy  changeSort={this.changeSort.bind(this) }  result={this.state.result} />
                    <Pagger page={this.state.result.page} pages={this.state.result.pages} limit={this.state.result.limit} changePage={this.changePage.bind(this)} changeLimit={this.changeLimit.bind(this)} /> */}

                    {/*<Table rows={this.state.rows} search_bar={this.state.search_bar} redirect={this.redirect.bind(this)}/>  */}

                    {/*<AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } msgParam="ok"  onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>  */}
                </div>
            )

       }

         
    }
 


 export default Main