import React from 'react';

import checkPNG from '../../../image/check.png'
import nonePNG from '../../../image/none.png'
import infoPNG from '../../../image/info.png'
import { Link } from 'react-router-dom';
import ConfigX from '../../../ConfigX';
 
class TableRow extends React.Component
 {
        constructor(props)
        {
            super(props)
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onRowClick(id);
        }

        render()
        {
         /*   let nip = '';
            var letter = '';
            if( this.props.row.nazwa.length > '0' )
            {
                letter = this.props.row.nazwa.charAt(0);
            }
            if(this.props.row.nip == 0)
            {
                nip = "-";
            }else{
                nip = this.props.row.nip;
            }*/

            var szkolenia = this.props.row.szkolenia;

            return (
                 
                        <tr className='' >
                            <td>{this.props.num}</td> 
                            <td>
                                <h3>{this.props.row.nazwa} </h3> 

                                <div className='table-content-boot-rap'>
                                    <table className='table-boot-rap'>
                                        <thead>
                                            <th>LP</th>
                                            <th>Pracownik</th>
                                            <th>Szkolenie</th>
                                            <th>Data szkolenia</th>
                                            <th>Status</th>
                                        </thead>
                                            
                                                {
                                                    szkolenia.map((item,index)=>{  
                                                        return(                                       
                                                            <tr className='' key={index}>
                                                                <td>{index+1}</td>
                                                                <td>{item.imie} {item.nazwisko}</td>
                                                                <td>{item.tytul}</td>
                                                                <td>{item.data_od} - {item.data_do}</td>
                                                                <td>{item.status}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                           
                                       
                                    </table>       
                                </div>             
                            </td>                           
                        </tr>                 
            )
        }
 }

 export default TableRow;