import React,{useRef} from 'react';
import ConfigX from '../../../ConfigX'

import FirmSelector from '../../../components/FirmSelector';
import DetTerminyRow from './DetTerminyRow';
import DetUczestnicyRow from './DetUczestnicyRow';
import EditTermin from './EditTermin';
 
import TipMessage from '../../../components/TipMessage';

import BackPNG from './../../../image/back.png'
import CSVPNG from './../../../image/csv_icon.png'
//import PrintPNG from './../../../image/print.png'
import PDFPNG from './../../../image/PDF_icon.png'
import PrintPNG from './../../../image/print_icon.png'
import PrintPNGBlue from './../../../image/print_icon_blue.png'

import loading from '../../../image/loading.gif'
import ButtonConfirm from '../../../components/ButtonConfirm';
import MailBox from '../../../components/MailBox';
import AlertMsg from '../../../components/AlertMsg';

import MembersPrint  from './MembersPrint';
import InvoiceData   from './InvoiceData';
import PresentList   from './PresentList';
import Certificate   from './Certificate'; 
import Questionnaire from './Questionnaire'; 

import ReactToPrint from 'react-to-print';

import PDFFile from './PDFFile'; 
import generatePDF,{ Resolution, Margin, Options, usePDF } from "react-to-pdf";

import FileUploader from '../../../components/FileUploader';
import Regulation from '../../../components/Regulation';

import { CSVLink } from "react-csv";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import MembersPDF from './MembersPDF_copy';





class Det extends React.Component
{    
     
     myRef  = React.createRef();
     myRef2 = React.createRef();
     myRef3 = React.createRef();
     myRef4 = React.createRef();

    constructor(props)
    {
        super(props)
        

        this.state = {
            loading: 1,
            search_bar: {                                           
                od: "",
                do: "",
                nazwa: "",
                nazwa_firmy: "",
                id: this.props.id,                    
            },
            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },
            rows: [],
            result: [],
            dane_firmy: [],

            postanowienia: [],

            alertMSG: '',
            alertMSGTime: 0,
            alertParam: 'ok', // error inne

            uczestnicy_fraza: '',      
            uczestnicy_status: '',      
            uczestnicy_rows: [],      

            dane: false,   
            lista_obecnosci: false,
            zaswiadczenie: false,
            ankieta: false,
            bezterminowe: 'nie',
            refresh: 1,

            nn_tytul: "",
            nn_cel_szkolenia: "",
            nn_dla_kogo: "",
            nn_wymagane_od_uczestnika: "",
            nn_ilosc_miejsc: "",
            nn_koszt_szkolenia: "",
            nn_koszt_szkoleniaBKP: "",
            nn_koszt_jm: "",
            nn_platnosci: "",
            nn_stawka: "",
            nn_stawka: "",
            nn_tresc_terminu: "",


            editor_action: "none", //nie wyświetlaj edytora terminów
            editor_row: { }, //pusta baza dla editora..               
            editor_selected: 0
        };
    }

    onBackClick()
    {
        this.props.redirect("list", this.props.id);
    }

    componentDidMount()
    {
        console.log("Det did mount");
        this.getData();
        this.getDataUczestnicy();          
    }

      //Pobranie danych
    getData()
      {
  
          //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
          var wait = setTimeout(
               () => {
                  this.setState({ loading: 1 });
               }
              ,500);
  
          var tmpNonRef = JSON.stringify( this.state );
          tmpNonRef = JSON.parse(tmpNonRef);
  
          var dataPost = tmpNonRef.search_bar;
          dataPost.pagger = tmpNonRef.pagger;
  
        
          
          //console.log("Requets " +  JSON.stringify(dataPost) );
  
          fetch( ConfigX.restApi + "/dash,get_training" , {
              method: 'POST', 
              body: JSON.stringify(dataPost), 
              headers: {
                  'Content-Type': 'text/html',
                  'X-API-KEY': this.props.apiKey
              }
          })
          .then( res => res.json() )
          .then(json => {
          
               //console.log(JSON.stringify(json) );
               clearTimeout(wait);
  
               this.setState({
                  rows: json.rows[0],
                  result: json.result, //zawsze pierwszy indeks
                  loading: 0
               });      
             
               this.getDataFirmaUczestnicy();
          });
    }

     //Pobranie danych
    

    getDataUczestnicy()
    {

        var dataPost = {
           pagger: {
             page: "1",
             limit: "1000",
             order_col: "",
             order: ""
           },
           id: this.props.id,
           imie_nazwisko: ""
         }

        fetch( ConfigX.restApi + "/dash,get_members" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        
           

             this.setState({
                uczestnicy_rows: json,                                       
             });      
           
        });
   }

   getDataFirmaUczestnicy()
   {

       var dataPost = {
          pagger: {
            page: "1",
            limit: "1000",
            order_col: "",
            order: ""
          },
          id: this.props.id,
          imie_nazwisko: ""
        }

       fetch( ConfigX.restApi + "/dash,getDataFirmUczestnik" , {
           method: 'POST', 
           body: JSON.stringify(dataPost), 
           headers: {
               'Content-Type': 'text/html',
               'X-API-KEY': this.props.apiKey
           }
       })
       .then( res => res.json() )
       .then(json => {
      

            this.setState({
                dane_firmy: json,                                      
             });      
           
  

           
       });
  }

    updateSzkolenie(event)
    {            
 
        
        if(event != null)
        {

                    var err = false;

                    if(this.state.rows.tytul.length < 2 || this.state.nn_tytul == 'incorrect') 
                    {
                        var dt = new Date();
                        dt = dt.toISOString();
                        err = true;

                        this.setState({
                            alertMSG: 'Podaj tytuł',
                            alertMSGTime: dt,
                            alertParam: 'error',
                            nn_tytul: "incorrect"
                        });
                    }


                    if(this.state.rows.ilosc_miejsc.length < 1 || this.state.nn_ilosc_miejsc == 'incorrect' || this.state.rows.ilosc_miejsc < 1) 
                    {
                        var dt = new Date();
                        dt = dt.toISOString();
                        err = true;

                        this.setState({
                            alertMSG: 'Podaj ilość miejsc',
                            alertMSGTime: dt,
                            alertParam: 'error',
                            nn_ilosc_miejsc: "incorrect"
                        });
                    }


                    if(this.state.rows.koszt_szkolenia.length < 1 || this.state.nn_koszt_szkolenia == 'incorrect') 
                    {
                        var dt = new Date();
                        dt = dt.toISOString();
                        err = true;

                        this.setState({
                            alertMSG: 'Podaj koszt szkolenia',
                            alertMSGTime: dt,
                            alertParam: 'error',
                            nn_koszt_szkolenia: "incorrect"
                        });
                    }

                    if(this.state.rows.koszt_szkolenia_bkp.length < 1 || this.state.nn_koszt_szkoleniaBKP == 'incorrect') 
                    {
                        var dt = new Date();
                        dt = dt.toISOString();
                        err = true;

                        this.setState({
                            alertMSG: 'Koszt szkolenia BKP ',
                            alertMSGTime: dt, 
                            alertParam: 'error',               
                            nn_koszt_szkoleniaBKP: "incorrect"
                        });
                    }        
                    
                    if(this.state.rows.stawka_vat.length < 1 || this.state.nn_stawka == 'incorrect') 
                    {
                        var dt = new Date();
                        dt = dt.toISOString();
                        err = true;

                        this.setState({
                            alertMSG: 'Podaj stawkę VAT ',
                            alertMSGTime: dt,
                            alertParam: 'error',
                            nn_stawka: "incorrect"
                        });
                    }
                    

                    if(this.state.rows.bezterminowe == 'nie' && this.state.bezterminowe == 'nie')
                    {
                        if(this.state.rows.terminy.length < 1 )
                        {
                            var dt = new Date();
                            dt = dt.toISOString();
                            err = true;

                            this.setState({
                                alertMSG: 'Nie podano terminu szkolenia. ',
                                alertMSGTime: dt,
                                alertParam: 'error',
                            });
                        }
                    }
                
                    if(this.state.rows.cel_szkolenia.length < 100 || this.state.nn_cel_szkolenia == 'incorrect') 
                    {
                        var dt = new Date();
                        dt = dt.toISOString();
                        err = true;

                        this.setState({
                            alertMSG: 'Cel szkolenia musi mieć przynajmniej 100 znaków.. ',
                            alertMSGTime: dt,       
                            alertParam: 'error',         
                            nn_cel_szkolenia: "incorrect"
                        });
                    }

                    if((this.state.rows.tresc_terminu < 10 || this.state.nn_tresc_terminu == 'incorrect') && this.state.rows.bezterminowe == 'tak')
                    {
                        var dt = new Date();
                        dt = dt.toISOString();
                        err = true;

                        this.setState({
                            alertMSG: 'Treść o terminie powinna mieć przynajmniej 10 znaków',
                            alertMSGTime: dt,    
                            alertParam: 'error',            
                            nn_tresc_terminu: "incorrect"
                        });            
                    }


                    if(this.state.postanowienia.length < 1 )
                    {
                        err = true;

                        var dt = new Date();
                        dt = dt.toISOString();

                        this.setState({
                            alertMSG: 'Dodaj min. jedno postanowienie. ',
                            alertMSGTime: dt,
                            alertParam: 'error',
                        });
                    }


                    
                    if(err) return;
      }

 


        if(event != null)
        {
            var bnText = event.target.innerHTML;
            event.target.innerHTML = "...";
        }

     
        //if(this.state.bezterminowe == true)var bezterminowe = 'tak'; else var bezterminowe = 'nie';

           var dataPost = {
               id: this.state.rows.id,
               tytul: this.state.rows.tytul,
               cel_szkolenia: this.state.rows.cel_szkolenia,
               dla_kogo: this.state.rows.dla_kogo,
               status: this.state.rows.status,
               wymagane_od_uczestnika: this.state.rows.wymagane_od_uczestnika,
               sk_firmy_id: this.state.rows.podmiot_prowadzacy.id,
               koszt_szkolenia: this.state.rows.koszt_szkolenia,
               koszt_szkolenia_bkp: this.state.rows.koszt_szkolenia_bkp,
               podstawa_kwoty: this.state.rows.podstawa_kwoty,
               stawka_vat: this.state.rows.stawka_vat,
               ilosc_miejsc: this.state.rows.ilosc_miejsc,
               koszt_jm: this.state.rows.koszt_jm,
               platnosci: this.state.rows.platnosci,
               podstawa_kwoty: this.state.rows.podstawa_kwoty,
               stawka_vat: this.state.rows.stawka_vat,
               podstawa_kwoty: this.state.rows.podstawa_kwoty,
               stawka_vat: this.state.rows.stawka_vat,
               termin_odwolania: '2099-01-01 00:00:00',
               bezterminowe: this.state.rows.bezterminowe,
               tresc_terminu: this.state.rows.tresc_terminu,

               email_tytul: this.state.rows.email_tytul,
               email_tresc: this.state.rows.email_tresc,
           };

           //console.log(JSON.stringify(dataPost) );
          
   
           fetch( ConfigX.restApi + "/dash,edit_training" , {
               method: 'POST', 
               body: JSON.stringify(dataPost), 
               headers: {
                   'Content-Type': 'text/html',
                   'X-API-KEY': this.props.apiKey
               }
           })
           .then( res => res.json() )
           .then(json => {

                 var dt = new Date();
                 dt = dt.toISOString();

                if(json.status == "OK")
                {
                   if(event != null)
                   {
                        event.target.innerHTML = bnText;

                        this.setState({
                            alertMSG: 'Informacje zostały zapisane.',
                            alertMSGTime: dt,
                            alertParam: 'ok',
                         });
                         console.log("result, dash,edit_training ");
                         this.getData();
                   }

                }else
                {
                    this.setState({
                        alertMSG: 'Wystąpił problem z zapisem danych.',
                        alertMSGTime: dt,
                        alertParam: 'error',
                     });  
                }
         
              
           });
    }

    onChangeTytul(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["tytul"] = v; 

            this.setState( {
                rows: rows,
                nn_tytul: ok
            } );

         //  if(ok == "correct" ) this.updateSzkolenie(null);

        

    }  
    
    onChangeCelSzkolenia(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 100)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["cel_szkolenia"] = v; 

            this.setState( {
                rows: rows,
                nn_cel_szkolenia: ok
            } );

          //  if(ok == "correct" )  this.updateSzkolenie(null);

    }  

    onChangeTrescTerminu(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 10)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["tresc_terminu"] = v; 

            this.setState( {
                rows: rows,
                nn_tresc_terminu: ok
            } );

          //  if(ok == "correct" )  this.updateSzkolenie(null);

    }  

    onChangeDlaKogo(event)
    {
            var v   = event.target.value;
            var ok = "correct";
  
            //if(v.length < 100)  { ok = "incorrect"; } else { ok="correct" }        

            var rows = this.state.rows;
            rows["dla_kogo"] = v; 

            this.setState( {
                rows: rows,
                nn_dla_kogo: ok
            } );

           // if(ok == "correct" )  this.updateSzkolenie(null);

    }  





    onChangeEmailTemat(event)
    {
            var v   = event.target.value;
            var ok = "";
              
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["email_tytul"] = v; 

            this.setState( {
                rows: rows,               
            } );

        //    this.updateSzkolenie(null);
    } 

    onChangeEmailTresc(event)
    {
            var v   = event.target.value;
            var ok = "";
              

            var rows = this.state.rows;
            rows["email_tresc"] = v; 

            this.setState( {
                rows: rows,               
            } );

          //  this.updateSzkolenie(null);
    } 

    onChangeIloscMiejsce(event)
    {
            var v   = event.target.value;
            var ok = "";
              
            v = v.replace(/[^0-9]/g, ""); //wywal litery...
            v = v.replace(",","");
            v = v.replace(".","");
            v = v.replace(" ","");

            var num = parseInt(v);
            if(num < 1 || v.length<1){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["ilosc_miejsc"] = v; 

            this.setState( {
                rows: rows,
                nn_ilosc_miejsc: ok
            } );

         //   if(ok == "correct" )   this.updateSzkolenie(null);
    } 

    onChangeKoszt(event)
    {
            var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["koszt_szkolenia"] = v; 

            this.setState( {
                rows: rows,
                nn_koszt_szkolenia: ok
            } );

          //  if(ok == "correct" )     this.updateSzkolenie(null);

    } 

    onChangeKosztBKP(event)
    {
            var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["koszt_szkolenia_bkp"] = v; 

            this.setState( {
                rows: rows,
                nn_koszt_szkoleniaBKP: ok
            } );

         //   if(ok == "correct" )     this.updateSzkolenie(null);

    } 

    onDialogOK(param)
    {
        console.log("dialogOK " + param);
        if(param == 'ok')
        {
          this.getData();        
        }
    }

    onChangeStawka(event)
    {
            var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["stawka_vat"] = v; 

            this.setState( {
                rows: rows,
                nn_stawka: ok
            } );

    } 

    onChangeJM(event)
    {
        var v   = event.target.value;
        var ok = "";     

        var rows = this.state.rows;
        rows["koszt_jm"] = v; 

        this.setState( {
            rows: rows,
            nn_koszt_jm: ok
        } );

      //  this.updateSzkolenie(null);
    }


    onChangePodstawa(event)
    {
        var v   = event.target.value;
        var ok = "";     

        var rows = this.state.rows;
        rows["podstawa_kwoty"] = v; 

        this.setState( {
            rows: rows,
            nn_koszt_jm: ok
        } );
    }

    onChangeStatus(event)
    {
        var v   = event.target.value;      

        var rows = this.state.rows;
        rows["status"] = v; 

        this.setState( {
            rows: rows,     
        } );

        //this.updateSzkolenie(null);
    }

    onChangePlatnosci(event)
    {
        var v   = event.target.value;
        var ok = "";     

        var rows = this.state.rows;
        rows["platnosci"] = v; 

        this.setState( {
            rows: rows,
            nn_platnosci: ok
        } );

      //  this.updateSzkolenie(null);
    }

    onChangeTyp(event)
    {
        var v   = event.target.value;
        var ok = "";     

     //   var rows = this.state.rows;
      //  rows["bezterminowe"] = v; 


      var ilosc_potwierdzonych = 0; //jeżeli występują potwierdzeni użytkownicy to nie można ustawić bezterminowe na TAK i do tego musi być dodany termin

      for(var k=0; k<this.state.uczestnicy_rows.length; k++)
      {
         if(this.state.uczestnicy_rows[k].status == 'potwierdzone') {ilosc_potwierdzonych++; }
      }      
    
      if(this.state.rows.terminy.length > 0 && ilosc_potwierdzonych > 0 && v == 'tak')
      {

          var dt = new Date();
          dt = dt.toISOString();
      
        var rows = this.state.rows;
        rows["bezterminowe"] = "nie"; 

              this.setState({
                  alertMSG: 'Nie można zmienić na szkolenie bez terminu ponieważ są już potwierdzeni uczestnicy tego szkolenia.',
                  alertMSGTime: dt,
                  alertParam: 'error',
                  rows: rows,
                  bezterminowe: "nie"
              });
                 
      }else{
        
       
            var rows = this.state.rows;
            rows["bezterminowe"] = v;

            this.setState({
                rows: rows
            });

      }

 
  

    }

    onSelectedFirm(id, nazwa)
    {
        var tmp = this.state.rows;
        tmp.podmiot_prowadzacy = {};
        tmp.podmiot_prowadzacy.id = id;
        tmp.podmiot_prowadzacy.nazwa = nazwa;

        this.setState({
            rows: tmp                        
        });

        this.updateSzkolenie(null);
    }

    onClickTermin(id)
    {
        const data = this.state.rows.terminy.filter( item => {  if(item.id == id ) return true; return false; } );

        var newRefRow = JSON.parse( JSON.stringify(data[0]) );

        this.setState({
            editor_row: newRefRow,
            editor_action: "edit",     
            editor_selected: newRefRow.id              
        });

        this.updateSzkolenie(null);
    }


    onCloseEditor(modifyRow)
    {
        if(modifyRow != null)
        {
           
                //zwróc wszystko oprócz wybranego rekordu.
                var selectedInx = -1;

                this.state.rows.terminy.forEach( (item, index) => {
                    if(item.id == modifyRow.id)
                    {
                        selectedInx = index;                        
                    }
                });

            if(selectedInx >= 0)
            {
                var tmp =  this.state.rows;
                tmp.terminy[selectedInx] = modifyRow;

                this.setState({
                    rows: tmp
                });

              //  console.log("Edytowany " + modifyRow.id);
            }else
            {
              //  console.log("Nowy " + modifyRow.id);
                
                var tmp =  this.state.rows;
                tmp.terminy.push(modifyRow);

                this.setState({
                    rows: tmp
                });
            }
           
        }


        this.setState({
            editor_row: {},
            editor_action: "none",     
            editor_selected: -2              
        });
    }

    onDeleteTime(id)
    {
            var withOut = this.state.rows.terminy.filter( map => {
                if(map.id == id ) return false;
                return true;
            } )

            var tmp = this.state.rows;
            tmp.terminy = withOut;

            this.setState({
                rows: tmp
            });

    }

    onClickNewTime()
    {
        this.setState({
            editor_row:   {},
            editor_action: "new",    
            editor_selected: -1      
        });
    }

    onConfirmDel(type, param, param_id)
    {
      // console.log(type + param + param_id);

       if(type != "yes")  return;

       
       var row = this.state.rows[param_id];
       
       
       this.setState({
                loading: 1
            });


        fetch( ConfigX.restApi+ "/dash,remove_tm" , {
            method: 'POST', 
            body: JSON.stringify(row), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {                   
            
              if(json.status == 'OK')
              {      
                 console.log("ok");
              }
        });


    }
    
    onClickUczestnik(id)
    {
      //  console.log("Wybrano rezerwacje " + id);
    }

    redirect2(action, id, param)
    {
      

        if(param == "deleted_member") //usunięto szkolenie
        {
            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: "Uczestnik został usunięty!",
                alertMSGTime: dt,
                alertParam: 'ok',
            });                  
        }

       

        if(action == "det")
        {
            this.getDataUczestnicy();
        }
     
        
        this.setState(
            {
                view: action,
                selectedId: id,
            }
           );
    }

    onChangeStatusUczestnik(status,id)
    {
      //  console.log(id + status);


        var dataPost = {
            id: id,
            status: status
          }
 
         fetch( ConfigX.restApi + "/dash,confirm_mem" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() )
         .then(json => {
                    

             if(json.status == "OK" )
             {
                var idCh = -1;
                for(var k=0; k<this.state.uczestnicy_rows.length; k++)
                {
                   if(this.state.uczestnicy_rows[k].id == id) {idCh = k; break; }
                }

           
                if(idCh >= 0)
                {
                    var tmpU = this.state.uczestnicy_rows;
                    tmpU[idCh].status = status;

                    this.setState({
                        uczestnicy_rows: tmpU                             
                     });                    
                }
              
             }                  
            
         });

    }

    onChangeUczestnikFraza(event)
    {

        this.setState({
            uczestnicy_fraza: event.target.value
        });
    }

    onChangeUczestnikStatus(event)
    {
        //console.log(event.target.value);
        this.setState({
            uczestnicy_status: event.target.value
        });
    }

    onChangeStatusFrekwencja(val,sk_szkolenia_terminy_id, sk_szkolenia_uczestnicy_id)
    {

        var dataPost = {
            id: sk_szkolenia_terminy_id,
            status: val
          }
 
         fetch( ConfigX.restApi + "/dash,confirm_absent" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() )
         .then(json => {
                    

             if(json.status == "OK" )
             {
                var idCh = -1;
                for(var k=0; k<this.state.uczestnicy_rows.length; k++)
                {
                   if(this.state.uczestnicy_rows[k].id == sk_szkolenia_uczestnicy_id) {idCh = k; break; }
                }

               // console.log("DEEE " + idCh);
                if(idCh >= 0)
                {
                    var tmpU = this.state.uczestnicy_rows;

                    var idTerm = -1;

                    for(var kk=0; k<tmpU[idCh].terminy.length; kk++)
                    {
                        if(tmpU[idCh].terminy[kk].id == sk_szkolenia_terminy_id) {  idTerm=kk; break; }
                    }

                    if(idTerm > 0)
                    {
                        tmpU[idCh].terminy[idTerm].frekwencja = val; 

                        this.setState({
                            uczestnicy_rows: tmpU                             
                         }); 
                    }
                    
                }
              
             }                  
            
         });
    }

    onConfirmDel(type, param, param_id)
    {
      // console.log(type + param + param_id);

       if(type != "yes")  return;


       //sprawdz czy sa potwierdzone uczestnictwa..
    
       var potwierdzony = false;
      
       this.state.uczestnicy_rows.forEach(item => {
           if( item.status == 'potwierdzone')
           {
         //    console.log("Nie mozna usunąc z powodu:  " + item.id + "  " + item.status);
             potwierdzony = true;
           }
        })

       if(potwierdzony)
       {
           var dt = new Date();
           dt = dt.toISOString();
         
           this.setState({
              alertMSG: 'Nie możesz usunać tego zgłoszenia. Wypisz wszystkich potwierdzonych członków.',
              alertMSGTime: dt,
              alertParam: 'error',
           });
           return;
       }


         //
         var dataPost = {
            id: param_id,       
        };

   
        fetch( ConfigX.restApi + "/dash,del_training" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             if(json.status == "OK")
             {
                this.props.redirect("list",-1,"deleted");
             }                 
        });

        
        
    }



    downloadPDF = (event) => {

        const options = {
            method: "save",
            filename: "Dane do faktur uczestników szkolenia.pdf",       
            method: "save",
            // default is Resolution.MEDIUM = 3, which should be enough, higher values
            // increases the image quality but also the size of the PDF, so be careful
            // using values higher than 10 when having multiple pages generated, it
            // might cause the page to crash or hang.
            resolution: Resolution.EXTREME,
            page: {
              // margin is in MM, default is Margin.NONE = 0
              margin: Margin.SMALL,
              // default is 'A4'
              format: "letter",
              // default is 'portrait'
              orientation: "landscape"
            },
            canvas: {
              // default is 'image/jpeg' for better size performance
              mimeType: "image/jpeg",
              qualityRatio: 1
            },
            // Customize any value passed to the jsPDF instance and html2canvas
            // function. You probably will not need this and things can break,
            // so use with caution.
            overrides: {
              // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
              pdf: {
                compress: true
              },
              // see https://html2canvas.hertzen.com/configuration for more options
              canvas: {
                useCORS: true
              }
            }          
        };

        const typ = event.target.alt;

        if(typ == 'Dane do faktur')
        {
            //this.setState({ dane: true });         
            generatePDF(() => document.getElementById("dane_faktura"), options);
        }
        if(typ == 'Lista obecności')
        {
            //this.setState({ lista_obecnosci: true });        
            generatePDF(() => document.getElementById("lista_obecnosci"), options);
        }
        if(typ == 'Zaświadczenie')
        {
            //this.setState({ zaswiadczenie: true });
            generatePDF(() => document.getElementById("zaswiadczenie"), options);
        }
        if(typ == 'Ankieta')
        {
            //this.setState({ ankieta: true });
            generatePDF(() => document.getElementById("ankieta"), options);
        }                        
      };


    setPostanowienia(rows)
    {
        this.setState({
            postanowienia: rows
        });
    }

    onSendSuccess()
    {
        var dt = new Date();
        dt = dt.toISOString();

        this.setState({
            alertMSG: 'Wiadomość została wysłana',
            alertMSGTime: dt,
            alertParam: 'ok',
         });
    }


    delUczestnik(type, px_group_id, sk_szkolenia_id)
    {
      // console.log(type +" "+ param +" "+ param_id);

         var dataPost = {
            sk_szkolenia_id: sk_szkolenia_id,
            id_mem: px_group_id       
        };


        fetch( ConfigX.restApi + "/dash,del_member" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
   
             if(json.status == "OK")
             {     
                var dt = new Date();
                dt = dt.toISOString();

                this.setState({
                    alertMSG: 'Uczestnik został usunięty.',
                    alertMSGTime: dt,
                    alertParam: 'ok',
                 });

                 this.getDataUczestnicy();

             }else{
                var dt = new Date();
                dt = dt.toISOString();

                this.setState({
                    alertMSG: 'Wystąpił błąd podczas usuwania.',
                    alertMSGTime: dt,
                    alertParam: 'error',
                 });
             }                 
        });

        
        
    }

    render()
    {      

        console.log("Redner ",  this.state.rows );


        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>                                               
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        } 
        



       
        var iloscTerminow = 0; 
        var iloscUczestnikow = 0; 
        var iloscUczestnikowOczekuje = 0; 
        var iloscUczestnikowPotwierdzone = 0; 
        var iloscUczestnikowOdrzucone = 0; 

        this.state.uczestnicy_rows.map( (item,index) => {
              if(item.status == "oczekuje")      iloscUczestnikowOczekuje++; 
              if(item.status == "potwierdzone")  iloscUczestnikowPotwierdzone++; 
              if(item.status == "odrzucone_brak_miejsc" || item.status == "odrzucone_odwolanie_szkolenia" )     iloscUczestnikowOdrzucone++; 
        })


        var msg = ""
        if(iloscUczestnikowOczekuje == 1) msg = "Brawo! Masz  chętną osobą na to szkolenie. Przejdź do poniższej listy uczestników i potwierdź zgłoszenie. ";
        if(iloscUczestnikowOczekuje >= 2) msg = "Pojawiły się osoby chętne na to szkolenie. Przejdź do poniższej listy uczestników i potwierdź zgłoszenie. ";


        if( this.state.rows.hasOwnProperty("terminy")  )     iloscTerminow = this.state.rows.terminy.length;
        if(this.state.uczestnicy_rows != undefined) iloscUczestnikow = this.state.uczestnicy_rows.length;
          


        if(this.state.rows.terminy.length < 1 && this.state.rows.bezterminowe == 'nie') 
        {
            msg = "Nie podano jeszcze terminu szkolenia. Każde dodane szkolenie musi posiać min. jeden termin.  ";
        }

       /*------DRUKARKA - Drukowanie ---------
        <ReactToPrint
        trigger={() => {                            
            return  <span className='link'><img src={PrintPNG}/> </span>
        }}
        content={() => this.componentRef}
        pageStyle={pageStyle} 
        />
                                 
        <MembersPrint ref={el => (this.componentRef = el)} rows={this.state.uczestnicy_rows}  title={this.state.rows.tytul} />      
        ------------------------*/

        const pageStyle = `

        @page {
          size: 210mm 297mm,                   
        }

        @media print {
            table {
                width: 100%;
                border: solid #000 !important;
                border-width: 1px 0 0 1px !important;
            }
            th{                
                font-weight: 400;
                color: #000;
                padding: 7px;
                text-align: left;   
                border: solid #000 !important;
                border-width: 0 1px 1px 0 !important;             
            }
            td {
                padding: 7px;
                border: solid #000 !important;
                border-width: 0 1px 1px 0 !important;
            }
        }
        
      `;

      
     /* @media all {
        .page-break {
          display: none;
        }
      }
    
      @media print {
          html, body {
              height: 100vh;
              overflow: initial !important;
              -webkit-print-color-adjust: exact;
            }          
        .page-break {
          page-break-before: always;
          page-break-inside: avoid;
        }
      }*/

       var proc = 100 * parseInt( iloscUczestnikowPotwierdzone );
       proc = parseInt( proc / this.state.rows.ilosc_miejsc);
       if(proc == NaN || proc == undefined || proc == null || isNaN(proc) ) proc = 0;
      
        if(proc > 100) proc = 100;

       var styleProgress = {
          width: proc+"%"
       }
       if(proc ==0 ) styleProgress = {
         width: 2
     }

     let class_pdf_dane = 'd-none';
     let class_pdf_obecnosc = 'd-none';
     let class_pdf_zaswiadczenie = 'd-none';
     let class_pdf_ankieta = 'd-none';
     
     if(this.state.dane){ class_pdf_dane = ''; }
     if(this.state.lista_obecnosci){ class_pdf_obecnosc = ''; }
     if(this.state.zaswiadczenie){ class_pdf_zaswiadczenie = ''; }
     if(this.state.ankieta){ class_pdf_ankieta = ''; }

   
     var headers = [
        { label: "LP", key: "lp" },
        { label: "Imię", key: "imie" },
        { label: "Nazwisko", key: "nazwisko" },
        { label: "Nazwa firmy", key: "nazwa_firmy" },
        { label: "NIP", key: "nip" },
        { label: "Ulica", key: "ulica" },
        { label: "Nr domu", key: "nr_domu" },
        { label: "Nr mieszkania", key: "nr_mieszkania" },
        { label: "Kod pocztowy", key: "kod_pocztowy" },
        { label: "Miasto", key: "miasto" },
        { label: "Telefon", key: "telefon" },
        { label: "Email", key: "email" },
      ];
   
        var i =0;
        var z = 0;
        var data = [];
        var newRow = {};

    // console.log(this.state.rows);
        if(this.state.dane_firmy.length > 0)
        {

        this.state.dane_firmy.map(item => {           

            newRow = {
                lp: i+1,
                imie: item.imie,
                nazwisko: item.nazwisko,
                nazwa_firmy: item.nazwa,
                nip: item.nip,
                ulica: item.ulica,
                nr_domu: item.nr_domu,
                nr_mieszkania: item.nr_mieszkania, 
                kod_pocztowy: item.kod_pocztowy,
                miasto: item.miasto,
                telefon: item.telefon,
                email: item.email,
            }
            data[i] = newRow; 
            i++;
        
        })
    }


      const CSV = (
        <>
            <CSVLink data={data} headers={headers} separator={";"} filename={"Dane-do_faktur.csv"}>

                <div className='d-flex ' >
                    <div className='link d-flex align-items-center mr-3'><img src={CSVPNG} alt="Dane do faktur CSV" />Dane do faktur</div>                    
                </div>

            </CSVLink>
        </>                  
      )

      var data_szkolenia = '';

      if(this.state.rows.terminy.length > 0)
      {
        var dd = new Date(this.state.rows.terminy[0].data_od);

        var y = dd.getFullYear(); 
        var m = dd.getMonth()+1;
        var d = dd.getDate();
        
        if(d < 10) d = "0"+d;
        if(m < 10) m = "0"+m;

        data_szkolenia  =   d+"-"+m+"-"+y;            
      }

      var zapiszBtn = '';
      var status = this.state.rows.status;

      if(status != 'archiwalne')
      {
         zapiszBtn = (
            <>
                <div className='buttonForm' onClick={this.updateSzkolenie.bind(this)}>zapisz</div>  
                &nbsp; &nbsp;
            </>            
         )
      }


      var terminyBuff = '';
      var terminText = '';

      if(this.state.rows.bezterminowe == 'nie')
      {
        terminyBuff = (
           <>
           
           <h3>🕛 Terminy zajęć (lekcji) w ramach szkolenia ({iloscTerminow})</h3>                     
                <div className="terminy">
                            <div>
                                <div className='buttonForm' onClick={this.onClickNewTime.bind(this)}>(+) dodaj termin</div>  
                            </div>
                            <div className='table_content'>                            
                                <table className='table table100'>
                                <thead>
                                    <tr>
                                        <td>LP</td>
                                        <td>Rozpoczęcie</td>
                                        <td>Zakończenie</td>
                                        <td>Temat</td>
                                        <td>Adres</td> 
                                        <td>Osoba prowadząca</td>                                                              
                                        <td>Informacje dodatkowe</td>                                                                                                                                                                                                                                           
                                    </tr>
                                </thead>
                                <tbody> 
                                     {
                                        this.state.rows.terminy.map( (item,index) => {
                                      
                                            return(
                                                <DetTerminyRow num={index+1} key={index} row={item} onClickTermin={this.onClickTermin.bind(this) } />
                                            )
                                        } )
                                     }
                                </tbody>    
                                </table> 
                            </div>
                </div>           

           </> 
        )
     }

    if(this.state.rows.bezterminowe == 'tak' )
    {
        terminText = (
            <>
            
            <div className='FormField'>
                <label>Treść o terminie<span className='required'>*</span> (min. 10 znaków)</label>                  
                <textarea  className={"field100p field100h " + this.state.nn_tresc_terminu} value={this.state.rows.tresc_terminu} col="tresc_terminu" onChange={this.onChangeTrescTerminu.bind(this) }   />
            </div>            
            
            </>
        )
    }



        var szkolenie_opt = (
            <>
            
            <div className='FormField '>
                               <label>Szkolenie bezterminowe</label>
                               <select defaultValue={this.state.rows.bezterminowe} onChange={this.onChangeTyp.bind(this) } col="typ" >                                   
                                  <option value="tak">TAK</option>
                                  <option value="nie">NIE</option>              
                               </select>
             </div>            
            
            </>
        )
    


        return (
            <div className='details'>
                 <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy szkoleń
                 </div>
                 
                                    
                    <h3>
                    ✏ {this.state.rows.tytul}                                                      
                    </h3>
                     
                    zajętych miejsc: {proc}% 
                    <div className='progressBarX'>
                        <div className='progressLineX' style={styleProgress}></div>
                    </div>

                    <TipMessage  msg={msg} type="notice" />

                    <div className='dashForm'>

                          <div className='FormField'> 
                               <label>Podmiot prowadzący<span className='required'>*</span></label>
                               <FirmSelector onSelectedFirm={this.onSelectedFirm.bind(this)} apiKey={this.props.apiKey}  id={this.state.rows.podmiot_prowadzacy.id} title={this.state.rows.podmiot_prowadzacy.nazwa} onCloseEditor={this.onCloseEditor.bind(this)}/>
                          </div>

                          <br/>

                          {szkolenie_opt}

                          {terminText}

                          <div className='FormField inlineField'>
                               <label>Status<span className='required'>*</span></label>
                               <select defaultValue={this.state.rows.status} onChange={this.onChangeStatus.bind(this) } col="status" >
                                  <option value="widoczne">Widoczne</option>              
                                  <option value="robocze">Robocze</option>              
                                  <option value="niewidoczne">Niewidoczne</option>
                                  <option value="archiwalne">Archiwalne</option>              
                               </select>
                          </div>

                          <div className='FormField '>
                               <label>Tytuł<span className='required'>*</span></label>
                               <input type="text" className={"field800 " + this.state.nn_tytul } value={this.state.rows.tytul}  col="tytul" onChange={this.onChangeTytul.bind(this) } />
                          </div>

                    
                          <div className='FormField'>
                               <label>Cel szkolenia<span className='required'>*</span> (min. 100 znaków)</label>                  
                               <textarea  className={"field100p field100h " + this.state.nn_cel_szkolenia} value={this.state.rows.cel_szkolenia} col="cel_szkolenia" onChange={this.onChangeCelSzkolenia.bind(this) }   />
                          </div>

                          <div className='FormField'>
                               <label>Dla kogo?<span className='required'></span> </label>                  
                               <textarea  className={"field100p field100h " + this.state.nn_dla_kogo} value={this.state.rows.dla_kogo} col="dla_kogo" onChange={this.onChangeDlaKogo.bind(this) }   />
                          </div>                

                          <div className='FormField inlineField'>
                               <label>Ilość miejsc<span className='required'>*</span> (maks. liczba uczestników)</label>
                               <input type="text" className={"field200 " + this.state.nn_ilosc_miejsc} value={this.state.rows.ilosc_miejsc}  col="ilosc_miejsc" onChange={this.onChangeIloscMiejsce.bind(this) }    />
                          </div>
                   
                   <br/>
                          <div className='FormField inlineField'>
                               <label>Koszt szkolenia<span className='required'>*</span> (PLN)</label>
                               <input type="text" className={"field100 " + this.state.nn_koszt_szkolenia} value={this.state.rows.koszt_szkolenia}  col="koszt_szkolenia" onChange={this.onChangeKoszt.bind(this) }    />
                          </div>
            
                          <div className='FormField inlineField'>
                               <label>Koszt dla członków BKP ('0' - zero, jeśli ukryć)<span className='required'>*</span> (PLN)</label>
                               <input type="text" className={"field100 " + this.state.nn_koszt_szkoleniaBKP} value={this.state.rows.koszt_szkolenia_bkp}  col="koszt_szkolenia_bkp" onChange={this.onChangeKosztBKP.bind(this) }    />
                          </div>

                          <div className='FormField inlineField'>                         
                               <select defaultValue={this.state.rows.podstawa_kwoty}  col="podstawa_kwoty" onChange={this.onChangePodstawa.bind(this)} >
                                  <option value="netto">Netto</option>
                                  <option value="brutto">Brutto</option>
                               </select>
                          </div>

                          <div className='FormField inlineField'>
                               <label>(%) VAT<span className='required'>*</span> </label>
                               <input type="text" className={"field50 " + this.state.nn_stawka} value={this.state.rows.stawka_vat}  col="stawka_vat" onChange={this.onChangeStawka.bind(this) }    />
                          </div>

                          <div className='FormField inlineField'>
                               <label>Podany koszt obejmuje<span className='required'>*</span></label>
                               <select defaultValue={this.state.rows.koszt_jm}  col="koszt_jm" onChange={this.onChangeJM.bind(this)} >
                                  <option value="UL">Uczestnik / Za jedną lekcje</option>
                                  <option value="UW">Uczestnik / Wszystkie lekcje</option>
                               </select>
                          </div>

                          <div className='FormField inlineField'>
                               <label>Płatność<span className='required'>*</span></label>
                               <select defaultValue={this.state.rows.platnosci}  col="platnosci" onChange={this.onChangePlatnosci.bind(this) } >
                                  <option value="LP">Po każdej lekcji</option>                               
                                  <option value="WP">Po wszystkich lekcjach</option>
                                  <option value="PL">Z góry przed każdą lekcją</option>
                                  <option value="PW">Z góry za wszystkie lekcje</option>
                               </select>
                          </div>                                               

                          <br/>

                          <br/>


                          <div className='FormField inlineField'>
                               <label>Tytuł e-mail <span className='required'>*</span></label>
                               <input type="text" className={"field300 " } value={this.state.rows.email_tytul}  col="tytul" onChange={this.onChangeEmailTemat.bind(this) } />
                          </div>
                          <div className='FormField'>
                               <label>Treść e-mail (wysyłana podczas zapisu na szkolenie)</label>                  
                               <textarea  className={"field100p field100h " } value={this.state.rows.email_tresc} col="email_tresc" onChange={this.onChangeEmailTresc.bind(this) }    />
                          </div>

                          <br/>
                          <br/>


                        <div className='FormField FormButtons'>
                            <div className='buttonForm' onClick={this.updateSzkolenie.bind(this)}>zapisz</div>  
                            &nbsp; &nbsp;
                            <ButtonConfirm   onConfirm={this.onConfirmDel.bind(this) } param="sk_szkolenia" param_id={this.state.rows.id}  title="usuń szkolenie*" query="Na pewno chcesz usunąć to szkolenie?"  />                                                           
                         </div>

                         <div className='simplyTipAlert'>
                            *Przeniesienie szkolenia do archiwum jest możliwe wyłącznie, gdy nie ma żadnego potwierdzonego uczestnictwa na liście uczestników.  Jeśli chcesz usunąć szkolenie wypisz ze szkolenia wszystkich uczestników.                             
                         </div>

                    </div>  

                    <div className='location' onClick={this.onBackClick.bind(this) }>
                     <br/>
                     <img src={BackPNG} /> Powrót do listy szkoleń
                     <br/>
                    </div>                
                                    

                    <EditTermin apiKey={this.props.apiKey} action={this.state.editor_action} onDeleteTime={this.onDeleteTime.bind(this)} row={this.state.editor_row}  allt={this.state.rows.terminy} sk_szkolenia_id={this.state.rows.id} nowSelected={this.state.editor_selected} onCloseEditor={this.onCloseEditor.bind(this)}/> 
                   
                    {terminyBuff}

                    <div>
                        <br/>
                          <FileUploader typ="sk_szkolenia_program" typ_id={this.state.rows.id} apiKey={this.props.apiKey} titlex='Program'/>
                          <br/>
                    </div>

                      
                    <div>
                        <br/>
                          <FileUploader typ="sk_szkolenia" typ_id={this.state.rows.id} apiKey={this.props.apiKey} />
                          <br/>
                    </div>


                    <div>
                        <br/>
                          <Regulation typ="sk_szkolenia" onChange={this.setPostanowienia.bind(this)} typ_id={this.state.rows.id} apiKey={this.props.apiKey} />
                          <br/>
                    </div>


                    <h3>Uczestnicy <sup style={{color: "red"}}>potwierdzonych {iloscUczestnikowPotwierdzone}, oczekujących: {iloscUczestnikowOczekuje}</sup> </h3> 

                    <div>                                     
                       <MailBox title="Wyślij e-mail do potwierdzonych uczestników" apiKey={this.props.apiKey} id={this.state.rows.id}  onSendSuccess={this.onSendSuccess.bind(this)} />                       
                      <br/>&nbsp;                      
                    </div>  
                   

 
                    <div className='search_bar'>
                        <div className='filter'>
                            <div className='filter_label'>Nazwisko / Imię / Tel.</div>
                            <div className='filter_content'>
                                <input type="text" value={this.state.uczestnicy_fraza} onChange={this.onChangeUczestnikFraza.bind(this)}    />
                            </div>                                 
                        </div>

                        <div className='filter'>
                            <div className='filter_label'>Status</div>
                            <div className='filter_content'>
                                <select defaultValue={this.state.uczestnicy_status} onChange={this.onChangeUczestnikStatus.bind(this)}>
                                     <option value="">Wszystkie</option>
                                     <option value="potwierdzone">Potwierdzone</option>
                                     <option value="oczekuje">Oczekujące</option>
                                     <option value="odrzucone_brak_miejsc">Odrzucone - brak miejsc</option>
                                     <option value="odrzucone_odwolanie_szkolenia">Odrzucone - odwołano szkolenie</option>
                                     <option value="wypisany">Wypisany</option>
                                </select>
                            </div>                                 
                        </div>
                    </div>

                    <br/>
                     
                    

             {   
             /*
                <div className='d-flex ' >
                    <div className='link d-flex align-items-center' onClick={() => generatePDF(this.myRef, options)}><img src={PDFPNG} alt="Dane do faktur"/>Dane do faktur</div>                    
                    <div className='link d-flex align-items-center' onClick={() => generatePDF(this.myRef, options)}><img src={PDFPNG} alt="Lista obecności"/>Lista obecności</div>
                    <div className='link d-flex align-items-center' onClick={() => generatePDF(this.myRef, options)}><img src={PDFPNG} alt="Zaświadczenie"/>Zaświadczenie</div>
                    <div className='link d-flex align-items-center' onClick={() => generatePDF(this.myRef, options)}><img src={PDFPNG} alt="Ankieta"/>Ankieta</div>
                </div>
                */
            }
            <div className='d-flex ' >

              {/*  <ReactToPrint
                    trigger={() => {                            
                        return(
                            <div className='link d-flex align-items-center mr-3'><img src={CSVPNG} alt="Dane do faktur PDF" />&nbsp;Dane do faktur </div>
                        )  
                    }}
                    content={() => this.componentRef}
                    pageStyle={pageStyle} 
                />*/}

                {CSV}


                <ReactToPrint
                    trigger={() => {                            
                        return(
                            <div className='link d-flex align-items-center mr-3'><img src={PrintPNGBlue} alt="Lista obecności PDF" />&nbsp;Lista obecności </div>
                        )  
                    }}
                    content={() => this.componentRef2}
                    pageStyle={pageStyle} 
                />
                <ReactToPrint
                    trigger={() => {                            
                        return(
                            <div className='link d-flex align-items-center mr-3'><img src={PrintPNGBlue} alt="Zaświadczenie PDF"/>&nbsp;Zaświadczenie </div>
                        )  
                    }}
                    content={() => this.componentRef3}
                    pageStyle={pageStyle} 
                />
                <ReactToPrint
                    trigger={() => {                            
                        return(
                            <div className='link d-flex align-items-center mr-3'><img src={PrintPNGBlue} alt="Ankieta PDF"/>&nbsp;Ankieta </div>
                        )  
                    }}
                    content={() => this.componentRef4}
                    pageStyle={pageStyle} 
                />                  

              {/*  <InvoiceData   ref={el => (this.componentRef = el)}  rows={this.state.dane_firmy}       title={this.state.rows.tytul} />   */}
                <PresentList   ref={el => (this.componentRef2 = el)} rows={this.state.uczestnicy_rows}  title={this.state.rows.tytul} data={data_szkolenia}  />   
                <Certificate   ref={el => (this.componentRef3 = el)} rows={this.state.uczestnicy_rows}  title={this.state.rows.tytul} data={data_szkolenia } />   
                <Questionnaire ref={el => (this.componentRef4 = el)} rows={this.state.uczestnicy_rows}  title={this.state.rows.tytul} data={data_szkolenia } />   

            </div>
 

                   <div className='table_content'>                 
                        <table className='table table100'>
                        <thead>
                            <tr>
                                <td>LP</td>
                                <td>Nazwisko</td>
                                <td>Imię</td>
                                <td>E-mail</td>
                                <td>Telefon</td>
                                <td>Status</td>                           
                                <td>Rozliczone</td>                                                        
                                <td>Frekwencja</td> 
                                <td>Info dodatkowe</td>      
                                <td></td>                                                 
                            </tr>
                        </thead>
                        <tbody> 
                            {                                                        
                                this.state.uczestnicy_rows.map( (item,index) => 
                                {                                                                                   
                                        var render = true;
                              

                                        if(this.state.uczestnicy_fraza.length>0 )
                                        {                                         
                                                render = false;
                                                if(item.nazwisko.toLowerCase().includes(this.state.uczestnicy_fraza.toLowerCase()) ) render =  true;
                                                if(item.imie.toLowerCase().includes(this.state.uczestnicy_fraza.toLowerCase()) ) render =  true;
                                                if(item.telefon.toLowerCase().includes(this.state.uczestnicy_fraza.toLowerCase()) ) render = true;                                                                                     
                                        }

                                        if(this.state.uczestnicy_status.length > 1)
                                        {
                                            render = false;
                                            if(item.status == this.state.uczestnicy_status) render = true;
                                        }
                         
                                        if(!render) return (null);                                    

                                    return(
                                     <DetUczestnicyRow redirect2={this.redirect2.bind(this)} row={item} key={index}  apiKey={this.props.apiKey}  num={index+1} onConfirmDel={this.onConfirmDel.bind(this)} onClickUczestnik={this.onClickUczestnik.bind(this)} onChangeStatusUczestnik={this.onChangeStatusUczestnik.bind(this) } onChangeStatusFrekwencja={this.onChangeStatusFrekwencja.bind(this)} delUczestnik={this.delUczestnik.bind(this)}/>
                                    )
                                    
                                } )
                            } 
                        </tbody>    


                        </table>

                   </div>

                 <br/><br/>
                 <br/><br/>
                 <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy szkoleń
                 </div>


                 <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } msgParam={this.state.alertParam} title="Powiadomienie" onDialogOK={this.onDialogOK.bind(this)}/>
           
            </div>
        )
    }
}

export default Det;