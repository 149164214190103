
import React from 'react';
import {  NavLink, Link  } from 'react-router-dom';
import logo from '../image/klaster_logo_new.png'
import bkp_edu from '../image/bkp_edu.png';
import bkp_lab from '../image/bkp_lab.png';
//import themeIcon from '../image/theme.png';
//import contrastIcon from '../image/contrast_icon_w.png';


import {
  DropdownMenu,
  DropdownItem,
  DropdownHeader,
  DropdownDivider,
  MenuMenu,
  Dropdown,
  Icon,
  Menu,
  Segment,
  MenuItem

} from 'semantic-ui-react'

import 'semantic-ui-css/semantic.min.css';


import Config from '../ConfigX'; 

class BarTop extends React.Component
 {

  constructor(props)  
  {
    super(props)    

    this.state = {
        valueJ: 100,
        valueK: 100,
        minJ: 0, 
        maxJ: 200 ,
        minK: 0, 
        maxK: 200 ,      
    }
        
  }

  componentDidMount()
  {
   // console.log("Mount BarTop" + this.props.page);     
  }

  componentDidUpdate()
  {
   // console.log("Update BarTop " + this.props); 
  }


 /* wagButtons(event)
  {
     var type = event.target.id;
     const divs    = document.querySelectorAll("div");
     const tables  = document.querySelectorAll("td");
     const content = document.querySelectorAll("table,a,span,label,h1,h2,h3,h4,h5,p");

     if(type === 'plus')
     {
        
        divs.forEach((element) => { 
          const fonsi = window
            .getComputedStyle(element, null)
            .getPropertyValue("font-size");
          const currentSize = parseFloat(fonsi);
          element.style.fontSize = currentSize + 1 + "px";
          element.style.cssText += "!important";
        }); 

        tables.forEach((element) => { 
          const fonsi = window
            .getComputedStyle(element, null)
            .getPropertyValue("font-size");
          const currentSize = parseFloat(fonsi);
          element.style.fontSize = currentSize + 1 + "px";
          element.style.cssText += "!important";
        });  
 
     }
     if(type === 'minus')
     {

        divs.forEach((element) => { 
          const fonsi = window
            .getComputedStyle(element, null)
            .getPropertyValue("font-size");
          const currentSize = parseFloat(fonsi);
          element.style.fontSize = currentSize - 1 + "px";
          element.style.cssText += "!important";
        });  

        tables.forEach((element) => { 
          const fonsi = window
            .getComputedStyle(element, null)
            .getPropertyValue("font-size");
          const currentSize = parseFloat(fonsi);
          element.style.fontSize = currentSize - 1 + "px";
          element.style.cssText += "!important";
        });
     } 
     if(type === 'theme')
     {
        let el = document.getElementById(type).getAttribute("aria-expanded");

        if(el == "false") //pokaz menu
        {
          document.getElementById(type).setAttribute("aria-expanded","true");

          var ul=document.createElement('ul');
          ul.id = "submenu_wag2";
          ul.className = "submenu themes-menu inline-submenu-lg ";
 
          var dark=document.createElement('li');
          dark.className = "submenu-item";
          dark.onclick = function(){
            document.querySelectorAll("body").forEach((element) => {                     
                if(!element.classList.contains('themeDark'))
                {
                  element.classList.add('themeDark'); 
                }                                
              });  
              document.querySelectorAll("table,a,span,label,h1,h2,h3,h4,h5,p").forEach((element) => { 
                if(!element.classList.contains('themeDark'))
                {
                   element.classList.add('themeDark');                     
                }                                               
              });                                      
            
          }
          dark.innerHTML="<div style='color:#000;margin-left:3px;display:flex;'><svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'><path d='M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z'/></svg>Ciemny</div>";
          ul.appendChild(dark);          

          var light=document.createElement('li');
          light.className = "submenu-item";
          light.onclick = function(){
            document.querySelectorAll("body").forEach((element) => { 
                if(element.classList.contains('themeDark'))
                {
                  element.classList.remove('themeDark');  
                }                                              
              });  
              document.querySelectorAll("table,a,span,label,h1,h2,h3,h4,h5,p").forEach((element) => { 
                if(element.classList.contains('themeDark'))
                {
                   element.classList.remove('themeDark');     
                }                                               
              }); 
            }          
          light.innerHTML="<div style='color:#000;margin-left:3px;display:flex;'><svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'><path d='M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z'/></svg>Jasny</div>";
          ul.appendChild(light);

          document.getElementById(type).appendChild(ul);   

        }else{

          document.getElementById(type).setAttribute("aria-expanded","false"); 
          document.getElementById("submenu_wag2").remove(); 
        }

     }
     if(type === 'brightness')
     {
        let el = document.getElementById(type).getAttribute("aria-expanded");

        if(el == "false") //pokaz menu
        {
          document.getElementById(type).setAttribute("aria-expanded","true");

          var ul=document.createElement('ul');
          ul.id = "submenu_wag";
          ul.className = "submenu themes-menu inline-submenu-lg ";
       
          var brightlabel = document.createElement('p');
          brightlabel.style.padding = '0px';
          brightlabel.style.margin = '0px';
          brightlabel.textContent = 'Jasność';

          var dark=document.createElement('li');
          dark.className = "submenu-item";   

          var percentBright = document.createElement('span');
          percentBright.id = 'percentbright';
          percentBright.className = 'value';
          percentBright.textContent = ' (100) ';
         // percentBright.innerHTML += ' <sup><a href="#" style="color:blue">wyczyść</a></sup>';  
          brightlabel.appendChild(percentBright);
    //------------------------------------------------------------ 
            const bright = document.createElement('input');
            bright.type  = 'range';
            bright.id    = 'bright';
            bright.min   = '0';
            bright.max   = '200'; 
            bright.value = '100';
            bright.style.width = '100%';

            bright.onchange = function(){
              const rangeInput = document.querySelectorAll("input");
              const value = document.querySelectorAll('.value');
              const App   = document.querySelector('.App');

              const divs    = document.querySelectorAll("div");
              const tables  = document.querySelectorAll("td");
              const content = document.querySelectorAll("table,a,span,label,h1,h2,h3,h4,h5,p");

              for(let i=0; i<rangeInput.length;i++)
              {
                value[i].textContent = " ("+ rangeInput[i].value +") ";

                rangeInput[i].addEventListener('input',()=>{
                  value[i].textContent = " ("+ rangeInput[i].value +") ";           

                    App.style.filter     = `brightness(${rangeInput[i].value}%)`;
                    divs.style.filter    = `brightness(${rangeInput[i].value}%)`;
                    tables.style.filter  = `brightness(${rangeInput[i].value}%)`;
                    content.style.filter = `brightness(${rangeInput[i].value}%)`;

                })
              }              
            }

          dark.appendChild(brightlabel);
          dark.appendChild(bright);
   //---------------------------------------------------------    
          
          //dark.innerHTML="<div style='color:#000;margin-left:3px;display:flex;'><svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'><path d='M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z'/></svg>Ciemny</div>";
          ul.appendChild(dark);          

          var light=document.createElement('li');
          light.className = "submenu-item";

          var percentContrast = document.createElement('span');
          percentContrast.id = 'percentContrast';
          percentContrast.className = 'value';
          percentContrast.textContent = ' (100)';  
        //  percentContrast.innerHTML += ' <sup><a href="#" style="color:blue">wyczyść</a></sup>';                     

          var contrastlabel = document.createElement('p');
          contrastlabel.style.padding = '0px';
          contrastlabel.style.margin = '0px';
          contrastlabel.textContent = 'Kontrast';          
          contrastlabel.appendChild(percentContrast);

          const contrast = document.createElement('input');
          contrast.type  = 'range';
          contrast.id    = 'contrast';
          contrast.min   = '0';
          contrast.max   = '200'; 
          contrast.value = '100';
          contrast.style.width = '100%';
 
          contrast.onchange = function(){
     
            const rangeInput = document.querySelectorAll("input");
            const value = document.querySelectorAll('.value');
            const App = document.querySelector('.App');

            const divs    = document.querySelectorAll("div");
            const tables  = document.querySelectorAll("td");
            const content = document.querySelectorAll("table,a,span,label,h1,h2,h3,h4,h5,p");

            for(let i=0; i<rangeInput.length;i++)
            {
              value[i].textContent = " ("+ rangeInput[i].value +")";
              rangeInput[i].addEventListener('input',()=>{
                value[i].textContent = " ("+ rangeInput[i].value +")";      
                          
                  App.style.filter     = `contrast(${rangeInput[i].value}%)`;
                  divs.style.filter    = `contrast(${rangeInput[i].value}%)`;
                  tables.style.filter  = `contrast(${rangeInput[i].value}%)`;
                  content.style.filter = `contrast(${rangeInput[i].value}%)`;
           
              })
            }              
          }

          light.appendChild(contrastlabel);
          light.appendChild(contrast);          
//--------------------------------------------------------- 

          //light.innerHTML="<div style='color:#000;margin-left:3px;display:flex;'><svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'><path d='M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z'/></svg>Jasny</div>";
          ul.appendChild(light);

          document.getElementById(type).appendChild(ul);   

        }else{          
          document.getElementById(type).setAttribute("aria-expanded","false");
          document.getElementById("submenu_wag").remove();            
        }

     }


  }*/

  changeBrightness(event)
  {
  
      const value = event.target.value; //document.querySelectorAll('.value');
      let id = document.getElementById(event.target.id);

      const App     = document.querySelector('.App');
      const divs    = document.querySelectorAll("div");
      const tables  = document.querySelectorAll("td");
      const content = document.querySelectorAll("table,a,span,label,h1,h2,h3,h4,h5,p");

      id.addEventListener('input', () => {

        if(App != 'undefined') App.style.filter     = `brightness(${value}%)`;
        if(divs.length > 0)    divs.style.filter    = `brightness(${value}%)`;
        if(tables.length > 0)  tables.style.filter  = `brightness(${value}%)`;
        if(content.length > 0) content.style.filter = `brightness(${value}%)`;
     
      });
 
      this.setState({valueJ: value}) 
  
  }

  changeContrast(event)
  {

    const value = event.target.value; //document.querySelectorAll('.value');
    let id = document.getElementById(event.target.id);

    const App     = document.querySelector('.App');
    const divs    = document.querySelectorAll("div");
    const tables  = document.querySelectorAll("td");
    const content = document.querySelectorAll("table,a,span,label,h1,h2,h3,h4,h5,p");

    id.addEventListener('input', () => {

      if(App != 'undefined') App.style.filter     = `contrast(${value}%)`;
      if(divs.length > 0)    divs.style.filter    = `contrast(${value}%)`;
      if(tables.length > 0)  tables.style.filter  = `contrast(${value}%)`;
      if(content.length > 0) content.style.filter = `contrast(${value}%)`;
   
    });

    this.setState({valueK: value}) 

  }

  changeTheme(event)
  {
    const id = event.target.id;

    if(id == 'light')
    {
     // id.addEventListener('onclick', () => {

        document.querySelectorAll("body").forEach((element) => { 
            if(element.classList.contains('themeDark'))
            {
              element.classList.remove('themeDark');  
            }                                              
          });  
          document.querySelectorAll("table,a,span,label,h1,h2,h3,h4,h5,p,div").forEach((element) => { 
            if(element.classList.contains('themeDark'))
            {
               element.classList.remove('themeDark');     
            }                                               
          }); 

          document.querySelectorAll(".main-content").forEach((element) => { 
            if(element.classList.contains('themeDark'))
            {
               element.classList.remove('themeDark');     
            }                                               
          });           


     //   });   
    }

    if(id == 'dark')
    {

    //  id.addEventListener('onclick', () => {

        document.querySelectorAll("body").forEach((element) => {                     
            if(!element.classList.contains('themeDark'))
            {
              element.classList.add('themeDark'); 
            }                                
          });  
          document.querySelectorAll("table,a,span,label,h1,h2,h3,h4,h5,p,div").forEach((element) => { 
            if(!element.classList.contains('themeDark'))
            {
               element.classList.add('themeDark');                     
            }                                               
          });                                      
          document.querySelectorAll(".main-content").forEach((element) => { 
            if(!element.classList.contains('themeDark'))
            {
               element.classList.add('themeDark');                     
            }                                               
          });          
      //});
    }

  }

  increaseFont(event)
  {
    const divs    = document.querySelectorAll("div");
    const tables  = document.querySelectorAll("td");
    const content = document.querySelectorAll("table,a,span,label,h1,h2,h3,h4,h5,p");

       divs.forEach((element) => { 
         const fonsi = window
           .getComputedStyle(element, null)
           .getPropertyValue("font-size");
         const currentSize = parseFloat(fonsi);
         element.style.fontSize = currentSize + 1 + "px";
         element.style.cssText += "!important";
       }); 

       tables.forEach((element) => { 
         const fonsi = window
           .getComputedStyle(element, null)
           .getPropertyValue("font-size");
         const currentSize = parseFloat(fonsi);
         element.style.fontSize = currentSize + 1 + "px";
         element.style.cssText += "!important";
       }); 
       
       content.forEach((element) => { 
        const fonsi = window
          .getComputedStyle(element, null)
          .getPropertyValue("font-size");
        const currentSize = parseFloat(fonsi);
        element.style.fontSize = currentSize + 1 + "px";
        element.style.cssText += "!important";
      });       
  }

  reduceFont(event)
  {

    const divs    = document.querySelectorAll("div");
    const tables  = document.querySelectorAll("td");
    const content = document.querySelectorAll("table,a,span,label,h1,h2,h3,h4,h5,p");

    divs.forEach((element) => { 
      const fonsi = window
        .getComputedStyle(element, null)
        .getPropertyValue("font-size");
      const currentSize = parseFloat(fonsi);
      element.style.fontSize = currentSize - 1 + "px";
      element.style.cssText += "!important";
    });  

    tables.forEach((element) => { 
      const fonsi = window
        .getComputedStyle(element, null)
        .getPropertyValue("font-size");
      const currentSize = parseFloat(fonsi);
      element.style.fontSize = currentSize - 1 + "px";
      element.style.cssText += "!important";
    });

    content.forEach((element) => { 
      const fonsi = window
        .getComputedStyle(element, null)
        .getPropertyValue("font-size");
      const currentSize = parseFloat(fonsi);
      element.style.fontSize = currentSize - 1 + "px";
      element.style.cssText += "!important";
    });    
  }


  selectLogin(event){

    var option =  "choose";
    this.props.selectLogin(option);
}

  render(){
    
      let panel = "";


 

      var homePage = (   
        
            <MenuItem><span onClick={this.selectLogin.bind(this)}><NavLink to={Config.pathDef   + "login" } className="c-w">Zaloguj się</NavLink></span></MenuItem>

          /*<NavLink to={Config.pathDef   + "login" }  >Zaloguj się | Rejestracja</NavLink> */
      );

    /*  var fontButtonPlus = (
        <div id="plus" className='font-size-plus' title='zwiększ czcionkę' onClick={this.wagButtons.bind(this)}><Icon name="plus"/>&nbsp;&nbsp;Zwiększ czcionkę</div>     
      );
      
      var fontButtonMinus = (
        <div id="minus" className='font-size-minus' title='zmniejsz czcionkę' onClick={this.wagButtons.bind(this)}><Icon name="minus"/>&nbsp;&nbsp;Zmniejsz czcionkę</div>   
      );

      var themeButton = (
        <div id="theme" title='zmień motyw' className="theme-switcher-menu" onClick={this.wagButtons.bind(this)} aria-expanded="false">                                         
            <Icon name="theme"/>Motyw 
        </div>    
      );*/

      var brightnessButton = (                                  
           <input id="bright" type="range" min={this.state.minJ} max={this.state.maxJ} defaultValue={this.state.valueJ} onChange={this.changeBrightness.bind(this)}></input>               
      );

      var contrastButton = (                                  
           <input id="contrast" type="range" min={this.state.minK} max={this.state.maxK} defaultValue={this.state.valueK} onChange={this.changeContrast.bind(this)}></input>               
      );

      var lightButton = (
        <div onClick={this.changeTheme.bind(this)} id="light"><Icon name="sun"/>Jasny</div>
      );

      var darkButton = (
        <div onClick={this.changeTheme.bind(this)} id="dark"><Icon name="moon"/>Ciemny</div>
      );

      var reduceFont = (
        <div onClick={this.reduceFont.bind(this)} ><Icon name="minus" />Zmniejsz czcionkę</div>
      );

      var increaseFont = (
        <div onClick={this.increaseFont.bind(this)} ><Icon name="plus" />Zwiększ czcionkę</div>
      );

      var logout = (
        <></>
      );



      var localUsr = window.localStorage.getItem('user');
     
      var active = '';
      if(this.props.page == 'szkolenia' || this.props.page == 'badania')
      {
         active = ' active';
      }
      //console.log(localUsr);
 
      if(localUsr != null && localUsr != "undefinded" && localUsr.length > 3) //jest zalogowany
      {
          var jsonUsr = JSON.parse(localUsr);

          if(jsonUsr.usr == "ADMIN") 
          {
            homePage = ( <MenuItem><NavLink to={Config.pathDef   }  className={"c-w" + active}>Strona WWW</NavLink></MenuItem> );
            panel = ( <MenuItem><NavLink to={Config.pathDef   + "dashboard" } className="c-w" >Panel administratora  </NavLink></MenuItem> );
          }
          else
          if(jsonUsr.usr == "UCZESTNIK") 
          {
            homePage = ( <MenuItem><span><NavLink to={Config.pathDef   }  className={"c-w" + active}>Strona WWW</NavLink>   </span></MenuItem> );
            panel = ( <MenuItem><span><NavLink to={Config.pathDef   + "dashboardUser" }  className="c-w"> Panel użytkownika</NavLink></span></MenuItem> );

          }else
          {
            homePage = (<></>);
            panel    = (<></>); 
          }
 
          logout = ( <MenuItem><span><NavLink to={Config.pathDef   + "logout" } className="c-w">Wyloguj</NavLink></span></MenuItem> );

      }
             
      var capitalized = '';
      var page = this.props.page;  

      if(page != undefined)
      {
        var capitalized =
        page.charAt(0).toUpperCase()
        + page.slice(1);   

      }
  
      if(page === 'szczegoly' || page === 'szczegolyB')  var capitalized = "Szczegóły";
    


      return (

      <>

        <div className="container back-header b-red">

 
        <Menu className='back-header' size='small' stackable >
              
                  <MenuItem className="c-w">tel: +48 52 515 30 73</MenuItem>
                  <MenuItem className="c-w">e-mail: uslugi@klaster.bydgoszcz.pl</MenuItem>
    
                  <Dropdown text='' icon="setting"  pointing='top right' className='link item right' > 
                    <DropdownMenu>

                      <DropdownItem >
                        <Dropdown text='Jasność/Kontrast' >
                          <DropdownMenu >  
                            <DropdownHeader>Jasność ({this.state.valueJ}%)</DropdownHeader> 
                            <DropdownItem>{brightnessButton}</DropdownItem>                            
                            <DropdownHeader>Kontrast ({this.state.valueK}%)</DropdownHeader>
                            <DropdownItem>{contrastButton}</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </DropdownItem>

                      <DropdownItem>
                        <Dropdown text='Motyw' >
                          <DropdownMenu >
                            <DropdownItem>{lightButton}</DropdownItem>
                            <DropdownItem>{darkButton}</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </DropdownItem>

                      <DropdownItem>
                        <Dropdown text='Czcionka' >               
                          <DropdownMenu >
                            <DropdownItem>{increaseFont}</DropdownItem>
                            <DropdownItem>{reduceFont}</DropdownItem>                        
                          </DropdownMenu>
                        </Dropdown>
                      </DropdownItem>

                    </DropdownMenu>
                  </Dropdown>

                  {homePage}
                  {panel}
                  {logout}
            
        </Menu>
   
    </div>



          <header id="header" className="header"> 


              {/* <div className="main-hexagon">
                  <div className='item'> 
                      <img src={logo} alt='logo' style={{height:"70px"}}/> 
                  </div>                 

                <div className="container-hexagon"> 
                
                        <div><div className='div2'><NavLink to={Config.pathDef  }  >SZKOLENIA</NavLink></div></div>
                        <div><NavLink to={Config.pathDef + "badania"  } >BADANIA</NavLink></div>
                        <div><NavLink to={Config.pathDef + "o_nas" }  >O NAS</NavLink></div>
                        <div><NavLink to={Config.pathDef + "partnerzy" }  >PARTNERZY</NavLink></div>
                        <div><NavLink to={Config.pathDef + "kontakt" }  >KONTAKT</NavLink></div>

                </div>
              </div> */}

                  {/*
                  
                  <div class="hexagon">
                    <NavLink to={Config.pathDef  }  >SZKOLENIA</NavLink>
                  </div> 
                  <div class="hexagon">
                    <NavLink to={Config.pathDef + "badania"  } >BADANIA</NavLink>
                  </div> 
                  <div class="hexagon">
                    <NavLink to={Config.pathDef + "o_nas" }  >O NAS</NavLink>
                  </div> 
                  <div class="hexagon">
                    <NavLink to={Config.pathDef + "partnerzy" }  >PARTNERZY</NavLink>
                  </div>     
                  <div class="hexagon">
                    <NavLink to={Config.pathDef + "kontakt" }  >KONTAKT</NavLink>
                  </div>                  
            */}
                <div className='menu'>
                  <div className='item img'> 
                  <NavLink to={Config.pathDef  }  ><img src={logo} alt='logo' style={{height:"70px"}}/></NavLink>
                  </div>               
                  <div className='item item_padding'><NavLink to={Config.pathDef + "szkolenia"}  ><img src={bkp_edu} alt='logo' style={{height:"70px"}}/></NavLink></div>
                  <div className='item item_padding'><NavLink to={Config.pathDef + "badania"} ><img src={bkp_lab} alt='logo' style={{height:"70px"}}/></NavLink></div>

                  {/* <div className='item item_padding'><NavLink to={Config.pathDef + "o_nas" }  >O NAS</NavLink></div>
                  <div className='item item_padding'><NavLink to={Config.pathDef + "partnerzy" }  >PARTNERZY</NavLink></div>
                  <div className='item item_padding'><NavLink to={Config.pathDef + "kontakt" }  >KONTAKT</NavLink></div>  */}
                </div>

              <div className='imageSplit'>
                  <div className='pageTitle'>{capitalized}<div className='small mt-5'> znajdź to czego szukasz!</div> </div>
              </div>  

          </header>
          
          
          
          </>
        );

  }
 
}

export default BarTop;
