import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

 

 
class SearchBar extends React.Component
 {
        constructor(props)
        {
            super(props)            
        }


        onChangeImie(event)
        {                          
              this.props.changeFilter('imie', event.target.value);
        }

        onChangeNazwisko(event)
        {                          
              this.props.changeFilter('nazwisko', event.target.value);
        }

        onChangeDateFrom(event)
        {
            this.props.changeFilter('od', event);
        }

        // onChangeDateTo(event)
        // {
        //     this.props.changeFilter('do', event);
        // }

        onChangeTytul(event)
        {
            this.props.changeFilter('tytul',  event.target.value );
        }


        goSearch()
        {
            this.props.goSearch();
        }

        goClear()
        {
            this.props.goClear();
        }

      

        render()
        {
       // console.log(this.props.search_bar);
            return (
                <div className='search_bar'>

                    <div className='filter'>
                          <div className='filter_label'>Imię nadawcy</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.imie} onChange={this.onChangeImie.bind(this)}    />
                          </div>                                 
                    </div>

                    <div className='filter'>
                          <div className='filter_label'>Nazwisko nadawcy</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.nazwisko} onChange={this.onChangeNazwisko.bind(this)}    />
                          </div>                                 
                    </div>
                    
                    <div className='filter'>
                          <div className='filter_label'>Data i czas wysłania</div>
                          <div className='filter_content'>
                                <DatePicker
                                selected={this.props.search_bar.od}
                                onChange={this.onChangeDateFrom.bind(this)}
                                showTimeSelect
                                dateFormat="yyyy-MM-dd HH:ii:ss"
                                locale="pl"
                                />                                                                                         
                          </div>
                    </div>


                    <div className='filter'>
                          <div className='filter_label'>Tytuł</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.tytul} onChange={this.onChangeTytul.bind(this)}    />
                          </div>                                   
                    </div>

                    <div className='filter filterButton'>
                           <div className='buttonSearch' onClick={this.goSearch.bind(this)}>Szukaj</div>                               
                           <div className='buttonSearch' onClick={this.goClear.bind(this) }>Wyczyść</div>                               
                    </div>
           

                </div>
            )
        }
 }

 export default SearchBar;