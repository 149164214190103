


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/baza/add/step1.PNG';
import step2 from '../../helpdesk/images/baza/add/step2.PNG';
import step3 from '../../helpdesk/images/baza/add/step3.PNG';
import step4 from '../../helpdesk/images/baza/add/step4.PNG';
import step5 from '../../helpdesk/images/baza/add/step5.png';

 


class AddBaza extends React.Component
 {



  render(){

    var param = this.props.param;

    return(
        <>
        
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>Przechodzimy do menu "Baza firm". Znajduję się tu lista firm zarejestrowanych do systemu. Ikona nad listą powoduje wygenerowanie do pliku CSV firm z danymi do faktury.</p>
          <p>Chcąc dodać nową firmę, wpisujemy prawidłowy i istniejący NIP w pole z zielonym przyciskiem "Dodaj podmiot" w prawym górnym rogu zaznaczonym na zrzucie ekranu.</p>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>Po wpisaniu prawidłowego i istniejącego NIPu klikamy na zielony przycisk z boku "Dodaj podmiot".</p>

          <img src={step2} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 3</h3>
          <br/>
          <p>Jeżeli NIP jest prawidłowy, dane zostają pobrane z GUS i wstawione do pól. Oczywiście trzeba wypełnić wszystkie pola z czerwoną gwiazdką.</p>

          <img src={step3} alt=""/>

        </section>

        <section className="section border-no-sec">

          <h3>Krok 4</h3>
          <br/>
          <p>Schodząc niżej strony widoczne są pola z adresem korespondencyjnym i przyciski zapisu czy przenoszenia do archiwum.</p>
          <p>Niżej możemy załączyć dokumenty, pliki, zdjęcia, które powiązane będą z tą firmą a na samym dole widać listę pracowników czy osób w systemie, którzy zostali powiązani z tą firmą.</p>
          <p>Dodać użytkowników możemy klikając w przycisk "Dodaj osobę".</p> 
          <p>Uzytkownik dodany w ten sposób nie ma dostępu do systemu, tzn. nie ma możliwości do logowania. Ważnym jest, że podczas dodawania jesli nie znamy adresu e-mail użytkownika, możemy wymyślić dowolny adres, który nie występuje w systemie.</p>
          <p>Poniżej tej listy znajduje się lista wniosków zlecona przez tę daną firmę i dodatkowo możliwość zlecenia badania przez tę firmę.</p>


          <img src={step4} alt=""/>

        </section>

        <section id="zlec" className="section border-no-sec">

          <h3>Krok 5</h3>
          <br/>
          <p>Aby zlecić badanie klikamy w przycisk "Zleć badanie", po czym wyskoczy nam okno gdzie wypełniamy niezbędne informacje.</p>
          <p>Wybieramy badanie, osobę, która będzie przypisana czy odpowiedzialna za ten wniosek, kwotę netto za badanie, kwotę VAT i przewidywaną datę zakończenia usługi.</p>
          <p>Są to niezbędne informacje, które potrzebuje system aby firma mogła zlecić badanie.
             Po tej operacji wniosek przejdzie do etapu końcowego i nie będzie trzeba go wyceniać a użytkownik nie będzie musiał akceptować wyceny jak wygląda to ze strony firmy chcącej zlecić badanie.</p>

          <img src={step5} alt=""/>

        </section>
        </>

    )
  }



}

export default AddBaza;