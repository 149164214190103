import React from 'react';

import ConfigX from '../../../ConfigX'
import ButtonConfirm from '../../../components/ButtonConfirm';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

 
class EditTermin extends React.Component
 {
        constructor(props)
        {
            super(props)

 

            this.state = {
                action:  this.props.action,  //new edit none 
                row: this.props.row,
                allt: this.props.allt,
                nowSelected: this.props.nowSelected,                         
               // pracownicy: [], //osoby prowadzące szkolenie...

                nn_temat: "",
                nn_osoba_prowadzaca: "",
                nn_czas_rozpoczecia: "",
                nn_czas_zakonczenia: "",
                nn_ulica: "",
                nn_nr_domu: "",
                nn_kod_pocztowy: "",
                nn_miasto: "",
            };
        }

      
        getEmptyRow()
        {
            var nowT = this.convertDate( new Date() ); 

            var data =  {
                id: "-1",
                data_od: nowT,
                data_do:  nowT,
                //prowadzacy: {id: -1},
                osoba_prowadzaca_inne: "",
                temat: "Szkolenie",
                informacje_dodatkowe: "",
                ulica: "",
                nr_domu: "",
                nr_mieszkania: "",
                kod_pocztowy: "",
                miasto: "",
                nr_nazwa_pomieszczenia: ""
              }

              if(this.state.allt.length > 0)
              {
                data['ulica'] = this.state.allt[0]['ulica'];
                data['nr_domu'] = this.state.allt[0]['nr_domu'];
                data['nr_mieszkania'] = this.state.allt[0]['nr_mieszkania'];
                data['kod_pocztowy'] = this.state.allt[0]['kod_pocztowy'];
                data['miasto'] = this.state.allt[0]['miasto'];
              }

              return JSON.stringify(data);
        }

        componentDidMount()
        {             
          /*  var dataPost = {};     

            fetch( ConfigX.restApi + "/dash,get_employees" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
            
                    //console.log(JSON.stringify(json) );                  
                    this.setState({
                            pracownicy: json
                    });      
                
            });*/
        }
 
        componentDidUpdate()
        {             
            if(this.state.nowSelected != this.props.nowSelected)
            {
                var row = this.props.row;
                if(this.props.action == "new") row =  JSON.parse(this.getEmptyRow()); //tworz nową instancje pustych danych..

                this.setState( {
                    action: this.props.action,  //new edit none 
                    row: row,
                    nowSelected: this.props.nowSelected     ,
                    nn_temat: "",
                    nn_osoba_prowadzaca: "",
                    nn_czas_rozpoczecia: "",
                    nn_czas_zakonczenia: "",
                    nn_ulica: "",
                    nn_nr_domu: "",
                    nn_kod_pocztowy: "",
                    nn_miasto: "",           
                });
            }
        }

        onChangeTemat(event)
        {
            var v = event.target.value;
            var ok = "";
            

            if(v.length < 2) {ok = "incorrect"; } else ok = "correct";            

            var tmp = this.state.row;
            tmp.temat = v;

            this.setState({
                row: tmp,
                nn_temat: ok
            });
        }


        convertDate(dateIn)
        {

            console.log("Check " + dateIn + "| " + (typeof dateIn) );


                if ( typeof dateIn === 'object' ) {
                    var v = dateIn;
                    var yyyy = v.getFullYear();
                    var mm = (v.getMonth()+1) ;  if(mm < 10) { mm = "0" + mm; }
                    var dd = (v.getDate()) ;  if(dd < 10) { dd = "0" + dd; }
                    var hh = (v.getHours()) ;  if(hh < 10) { hh = "0" + hh; }
                    var ii = (v.getMinutes()) ;  if(ii < 10) { ii = "0" + ii; }

                    v  =   yyyy+ "-" + mm + "-" + dd + " " + hh + ":" + ii;
                    return v;
                }

                return dateIn;
        }


        onChangeDateFrom(from)
        {

            //https://reactdatepicker.com/#example-min-date
           // var v = from;
           // var yyyy = v.getFullYear();
           // var mm = (v.getMonth()+1) ;  if(mm < 10) { mm = "0" + mm; }
           // var dd = (v.getDate()) ;  if(dd < 10) { dd = "0" + dd; }
           // var hh = (v.getHours()) ;  if(hh < 10) { hh = "0" + hh; }
           // var ii = (v.getMinutes()) ;  if(ii < 10) { ii = "0" + ii; }

            //v  =   yyyy+ "-" + mm + "-" + dd + " " + hh + ":" + ii;
            var v = this.convertDate(from);

         //   console.log(v);

            var tmp = this.state.row;
            tmp.data_od = v;

            this.setState({
                row: tmp
            });
        }






        onChangeDateTo(to)
        {

            //https://reactdatepicker.com/#example-min-date
             // var v = to;
           // var yyyy = v.getFullYear();
           // var mm = (v.getMonth()+1) ;  if(mm < 10) { mm = "0" + mm; }
           // var dd = (v.getDate()) ;  if(dd < 10) { dd = "0" + dd; }
           // var hh = (v.getHours()) ;  if(hh < 10) { hh = "0" + hh; }
           // var ii = (v.getMinutes()) ;  if(ii < 10) { ii = "0" + ii; }

            //v  =   yyyy+ "-" + mm + "-" + dd + " " + hh + ":" + ii;
            var v = this.convertDate(to);

           // console.log(v);

            var tmp = this.state.row;
            tmp.data_do = v;

            this.setState({
                row: tmp
            });
        }


        onChangeUlica(event)
        {
            var v = event.target.value;

            var ok = "";
            if(v.length < 2) {ok = "incorrect"; } else ok = "correct";

            var tmp = this.state.row;
            tmp.ulica = v;

            this.setState({
                row: tmp,
                nn_ulica: ok
            });
        }

        onChangeOsoba(event)
        {
            var v = event.target.value;

            var ok = "";
            if(v.length < 2) {ok = "incorrect"; } else ok = "correct";

            var tmp = this.state.row;
            tmp.osoba_prowadzaca_inne = v;

            this.setState({
                row: tmp,
                nn_osoba_prowadzaca: ok
            });
        }

        onChangeNrDomu(event)
        {
            var v = event.target.value;

            var ok = "";
            if(v.length < 1) {ok = "incorrect"; } else ok = "correct";

            var tmp = this.state.row;
            tmp.nr_domu = v;

            this.setState({
                row: tmp,
                nn_nr_domu: ok
            });
        }

        onChangeNrM(event)
        {
            var v = event.target.value;

            var tmp = this.state.row;
            tmp.nr_mieszkania = v;

            this.setState({
                row: tmp
            });
        }

        onChangePomieszenie(event)
        {
            var v = event.target.value;

            var tmp = this.state.row;
            tmp.nr_nazwa_pomieszczenia = v;

            this.setState({
                row: tmp
            });
        }


        onChangeKodPocztowy(event)
        {
            var v = event.target.value;
            var ok= "";

            v = v.replace(/[^\d.-]/g, '');
         //   console.log(v);

        //    const arr = v.split("-");
        //    if(arr.length == 2 && arr[0].length == 2 && arr[1].length == 3 && /^\d+$/.test(arr[0])  && /^\d+$/.test(arr[1])   )
       //     {
                ok ="correct";
        //    }else ok ="incorrect";

            var tmp = this.state.row;
            tmp.kod_pocztowy = v;

            this.setState({
                row: tmp,
                nn_kod_pocztowy: ok,
            });
        }

        onChangeMiasto(event)
        {
            var v = event.target.value;

            v = v.replace(/[0-9]/g, ""); //wywal cyfrwy...
    
            var ok = "";
            if(v.length > 2)
            {
                ok = "correct";
            }else ok ="incorrect";

            var tmp = this.state.row;
            tmp.miasto = v;

            this.setState({
                row: tmp,
                nn_miasto: ok
            });
        }


        onChangeInfo(event)
        {
            var v = event.target.value;

            var tmp = this.state.row;
            tmp.informacje_dodatkowe = v;

            this.setState({
                row: tmp
            });
        }

    /*    changeSort(event)
        {
            var ok = "";
            var v = event.target.value;

            if(v <= 0 ) { ok = "incorrect" } else ok = "correct";

            var tmp = this.state.row;
            tmp.prowadzacy.id = v;
            tmp.prowadzacy.imie = "?";
            tmp.prowadzacy.nazwisko = "-";

            this.setState({
                row: tmp,
                nn_osoba_prowadzaca: ok
            });
        }*/

        onSubmitForm(event)
        {
           

            var err = false;

            if(this.state.row.temat.length < 1 || this.state.nn_temat == "incorrect") {

                this.setState( {nn_temat: "incorrect" } );
                err = true;   
            }

            if(this.state.row.data_od.length < 5 || this.state.nn_czas_rozpoczecia == "incorrect") {

                this.setState( {nn_czas_rozpoczecia: "incorrect" } );
                err = true;   
            }

            if(this.state.row.data_do.length < 5 || this.state.nn_czas_zakonczenia == "incorrect") {

                this.setState( {nn_czas_zakonczenia: "incorrect" } );
                err = true;   
            }

            if(this.state.row.ulica.length < 1 || this.state.nn_ulica == "incorrect") {

                this.setState( {nn_ulica: "incorrect" } );
                err = true;   
            }


            if(this.state.row.nr_domu.length < 1 || this.state.nn_nr_domu == "incorrect") {

                this.setState( {nn_nr_domu: "incorrect" } );
                err = true;    
            }

            if(this.state.row.kod_pocztowy.length < 6 || this.state.nn_kod_pocztowy == "incorrect") {

                this.setState( {nn_kod_pocztowy: "incorrect" } );
                err = true;    
            }

            if(this.state.row.miasto.length < 2 || this.state.nn_miasto == "incorrect") {

                this.setState( {nn_miasto: "incorrect" } );
                err = true;   
            }

            if(this.state.row.osoba_prowadzaca_inne.length < 2 || this.state.nn_osoba_prowadzaca == "incorrect") {

                this.setState( {nn_osoba_prowadzaca: "incorrect" } );
                err = true;   
            }

          //  this.state.row.data_od  = this.convertDate(this.state.row.data_od);
           // this.state.row.data_do  = this.convertDate(this.state.row.data_do);

           // console.log("TS OD " + this.state.row.data_od );
          //  console.log("TS DO " + this.state.row.data_do );     

            const dataNow = (new Date()).getTime();
            const dataOd = (new Date(this.state.row.data_od)).getTime();
            const dataDo = (new Date(this.state.row.data_do)).getTime();
  


            if(  dataOd >= dataDo )
            {
                this.setState( {nn_czas_rozpoczecia: "incorrect", nn_czas_zakonczenia: "incorrect" } );
                err = true; 
               // console.log("E Daty" );
            }else   this.setState( {nn_czas_rozpoczecia: "", nn_czas_zakonczenia: "" } );


         /*   if(this.state.row.prowadzacy.id < 0)
            {
                err = true;
                this.setState( {nn_osoba_prowadzaca: "incorrect" } );
            }*/
           
            if(err) return;


       //   console.log("PPP " + JSON.stringify(this.state.row) );

            //zapisz na serwerze...

            var dataPost = {
                sk_szkolenia_id: this.props.sk_szkolenia_id,
                id: this.state.row.id,
                data_od: this.state.row.data_od,
                data_do: this.state.row.data_do ,
                osoba_prowadzaca_inne: this.state.row.osoba_prowadzaca_inne, 
                //prowadzacy_px_group_id: this.state.row.prowadzacy.id,
                temat: this.state.row.temat,
                informacje_dodatkowe: this.state.row.informacje_dodatkowe,
                ulica: this.state.row.ulica,
                nr_domu: this.state.row.nr_domu,
                nr_mieszkania: this.state.row.nr_mieszkania,
                kod_pocztowy: this.state.row.kod_pocztowy,
                miasto: this.state.row.miasto,
                nr_nazwa_pomieszczenia: this.state.row.nr_nazwa_pomieszczenia
            };     

            fetch( ConfigX.restApi + "/dash,add_edit_training_time" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
             
                 if(json.status == "OK")
                 {
                    this.state.row.id = json.id;
                    this.props.onCloseEditor(this.state.row);
                 }else
                 {

                 }
                
            });

     
        }

        onConfirmDel(type, param, id)
        {
           console.log(type + param + id);

           if(type == "yes") this.onDelete(null);
        }

        onDelete(event)
        {           

            var dataPost = {
                id: this.state.row.id
            };

            fetch( ConfigX.restApi + "/dash,del_training_time" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
             
                 if(json.status == "OK")
                 {                  
                     this.props.onDeleteTime(json.id);
                     this.props.onCloseEditor(null);
                 }else
                 {

                 }                 
            });


         
        }

        onCloseForm(event)
        {
           this.props.onCloseEditor(null);
        }


        render()
        {
            if(this.state.action == "none" )
            {
                return ( <span></span> )
            }

       /* kod który był poniżej jest pozostawiony w razie czego------- <div className='FormField'>
            <label>Osoba prowadząca<span className='required'>*</span></label>
            <select onChange={this.changeSort.bind(this)}   className={ this.state.nn_osoba_prowadzaca} value={this.state.row.prowadzacy.id}  >                                                     
            <option>--</option>
                {
                    this.state.pracownicy.map(item => {
                        
                        return (
                            <option key={item.id}     value={item.id}>{item.imie + " " + item.nazwisko + ", " + item.firma.nazwa }</option>
                        )
                    })
                }
            </select>

            </div>*/
 

       
            if( this.state.row.data_od  == null  || this.state.row.data_od == undefined || this.state.row.data_od.length < 2)
            {
                console.log("BD : " + JSON.stringify(this.state.row) );
                return ( <span>Bad time...</span> )   
            }

            return (
                <div className="_editor">  
                <div className='info_shadow'>                                                          
                <span className='window window_form'>
                     <div className='container container_form'>

                        <div className='dashForm'>                                    
                                    <div className='FormField'>
                                       <label>Temat zajęć<span className='required'>*</span></label>
                                       <input type="text" value={ this.state.row.temat } className={"field300 " + this.state.nn_temat} onChange={this.onChangeTemat.bind(this)} />
                                    </div>



                                    <div className='FormField'>
                                       <label>Osoba prowadząca<span className='required'>*</span></label>
                                       <input type="text" value={ this.state.row.osoba_prowadzaca_inne } className={"field300 " + this.state.nn_osoba_prowadzaca} onChange={this.onChangeOsoba.bind(this)} />
                                    </div>

                                    <br/>

                                            
                                    <div className='FormField inlineField'>
                                       <label>Czas rozpoczęcia<span className='required'>*</span></label>
                                       <DatePicker
                                        selected={  new Date(this.state.row.data_od) }
                                        onChange={this.onChangeDateFrom.bind(this)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={5}
                                        timeCaption="time"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        locale="pl"
                                        className={this.state.nn_czas_rozpoczecia}
                                        />
                                    </div>

                                    -

                                    <div className='FormField inlineField'>
                                       <label>Czas zakończenia<span className='required'>*</span></label>
                                       <DatePicker
                                        selected={  new Date(this.state.row.data_do) }
                                        onChange={this.onChangeDateTo.bind(this)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={5}
                                        timeCaption="time"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        locale="pl"
                                        className={this.state.nn_czas_zakonczenia}
                                        />
                                    </div>

                                    <div className='FormField'>
                                       <label>Ulica<span className='required'>*</span></label>
                                       <input type="text" value={ this.state.row.ulica } className={"field300 " + this.state.nn_ulica} onChange={this.onChangeUlica.bind(this)} />
                                    </div>
 

                                    <div className='FormField inlineField'>
                                       <label>Nr domu<span className='required'>*</span></label>
                                       <input type="text" value={ this.state.row.nr_domu } className={"field100 " + this.state.nn_nr_domu} onChange={this.onChangeNrDomu.bind(this)} />
                                    </div>

                                       
                                    <div className='FormField inlineField'>
                                       <label>Nr mieszkania</label>
                                       <input type="text" value={ this.state.row.nr_mieszkania } className={"field100 "} onChange={this.onChangeNrM.bind(this)} />
                                    </div>

                                    <div className='FormField inlineField'>
                                       <label>Pomieszczenie (sala)</label>
                                       <input type="text" value={ this.state.row.nr_nazwa_pomieszczenia } className={"field200 "} onChange={this.onChangePomieszenie.bind(this)} />
                                    </div>

                                    <br/>

                                    <div className='FormField inlineField'>
                                       <label>Kod pocztowy<span className='required'>*</span></label>
                                       <input type="text" value={ this.state.row.kod_pocztowy } className={"field100 " + this.state.nn_kod_pocztowy} onChange={this.onChangeKodPocztowy.bind(this)} />
                                    </div>

                                    <div className='FormField inlineField'>
                                       <label>Miasto<span className='required'>*</span></label>
                                       <input type="text" value={ this.state.row.miasto } className={"field300 " + this.state.nn_miasto} onChange={this.onChangeMiasto.bind(this)} />
                                    </div>

                                    <div className='FormField'>
                                       <label>Informacje dodatkowe</label>
                                       <input type="text" value={ this.state.row.informacje_dodatkowe } className={"field300 "} onChange={this.onChangeInfo.bind(this)} />
                                    </div>

                                    <br/>
                                    <div className='FormField'>
                                        {
                                            this.state.action == "edit" ? <span><ButtonConfirm  onConfirm={this.onConfirmDel.bind(this) }  title="Usuń" query="Na pewno chcesz usunąć ten termin?"  />&nbsp;</span>: ''
                                        }                                       

                                        <div className='buttonForm' onClick={this.onSubmitForm.bind(this) }>Zapisz zmiany</div> 
                                        &nbsp;
                                        <div className='buttonForm' onClick={this.onCloseForm.bind(this)}>Anuluj</div> 
                                     </div>
                        </div>                           
                    </div>
                </span>
                </div>
            </div>
            )
        }
 }

 export default EditTermin;