import React,{useRef} from 'react';
import ConfigX from '../../../ConfigX'

import FirmSelector from '../../../components/FirmSelector';
 
import TipMessage from '../../../components/TipMessage';

import BackPNG from './../../../image/back.png'

import loading from '../../../image/loading.gif'
import ButtonConfirm from '../../../components/ButtonConfirm';
import MailBox from '../../../components/MailBox';
import AlertMsg from '../../../components/AlertMsg';







class Det extends React.Component
{    
     
     myRef  = React.createRef();
     myRef2 = React.createRef();
     myRef3 = React.createRef();
     myRef4 = React.createRef();

    constructor(props)
    {
        super(props)
        

        this.state = {
            loading: 1,
            search_bar: {                                           
                od: "",
                do: "",
                nazwa: "",
                nazwa_firmy: "",
                id: this.props.id,                    
            },
            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },
            rows: [],
            result: [],
            dane_firmy: [],

            postanowienia: [],

            alertMSG: '',
            alertMSGTime: 0,

            uczestnicy_fraza: '',      
            uczestnicy_status: '',      
            uczestnicy_rows: [],      

            dane: false,   
            lista_obecnosci: false,
            zaswiadczenie: false,
            ankieta: false,

            nn_tytul: "",
            nn_cel_szkolenia: "",
            nn_dla_kogo: "",
            nn_wymagane_od_uczestnika: "",
            nn_ilosc_miejsc: "",
            nn_koszt_szkolenia: "",
            nn_koszt_szkoleniaBKP: "",
            nn_koszt_jm: "",
            nn_platnosci: "",
            nn_stawka: "",
            nn_stawka: "",


            editor_action: "none", //nie wyświetlaj edytora terminów
            editor_row: { }, //pusta baza dla editora..               
            editor_selected: 0
        };
    }

    onBackClick()
    {
        this.props.redirect("list", this.props.id);
    }

    componentDidMount()
    {
       // console.log("Det did mount");
        this.getData();
        this.getDataUczestnicy();          
    }

      //Pobranie danych
    getData()
      {
  
          //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
          var wait = setTimeout(
               () => {
                  this.setState({ loading: 1 });
               }
              ,500);
  
          var tmpNonRef = JSON.stringify( this.state );
          tmpNonRef = JSON.parse(tmpNonRef);
  
          var dataPost = tmpNonRef.search_bar;
          dataPost.pagger = tmpNonRef.pagger;
  
        
          
          //console.log("Requets " +  JSON.stringify(dataPost) );
  
          fetch( ConfigX.restApi + "/dash,get_training" , {
              method: 'POST', 
              body: JSON.stringify(dataPost), 
              headers: {
                  'Content-Type': 'text/html',
                  'X-API-KEY': this.props.apiKey
              }
          })
          .then( res => res.json() )
          .then(json => {
          
               //console.log(JSON.stringify(json) );
               clearTimeout(wait);
  
               this.setState({
                  rows: json.rows[0],
                  result: json.result, //zawsze pierwszy indeks
                  loading: 0
               });      
             
               this.getDataFirmaUczestnicy();
          });
    }

     //Pobranie danych
    

    getDataUczestnicy()
    {

        var dataPost = {
           pagger: {
             page: "1",
             limit: "1000",
             order_col: "",
             order: ""
           },
           id: this.props.id,
           imie_nazwisko: ""
         }

        fetch( ConfigX.restApi + "/dash,get_members" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        

             this.setState({
                uczestnicy_rows: json,                                       
             });      
           
        });
   }

   getDataFirmaUczestnicy()
   {

       var dataPost = {
          pagger: {
            page: "1",
            limit: "1000",
            order_col: "",
            order: ""
          },
          id: this.props.id,
          imie_nazwisko: ""
        }

       fetch( ConfigX.restApi + "/dash,getDataFirmUczestnik" , {
           method: 'POST', 
           body: JSON.stringify(dataPost), 
           headers: {
               'Content-Type': 'text/html',
               'X-API-KEY': this.props.apiKey
           }
       })
       .then( res => res.json() )
       .then(json => {
      

            this.setState({
                dane_firmy: json,                                      
             });      
           
  

           
       });
  }

    updateSzkolenie(event)
    {            

        
        if(event != null)
        {
        var err = false;

        if(this.state.rows.tytul.length < 2 || this.state.nn_tytul == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_tytul: "incorrect"
            });
        }

        if(this.state.rows.cel_szkolenia.length < 100 || this.state.nn_cel_szkolenia == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_cel_szkolenia: "incorrect"
            });
        }




        if(this.state.rows.ilosc_miejsc.length < 1 || this.state.nn_ilosc_miejsc == 'incorrect' || this.state.rows.ilosc_miejsc < 1) 
        {
            err = true;
            this.setState({
                nn_ilosc_miejsc: "incorrect"
            });
        }


        if(this.state.rows.koszt_szkolenia.length < 1 || this.state.nn_koszt_szkolenia == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_koszt_szkolenia: "incorrect"
            });
        }

        if(this.state.rows.koszt_szkolenia_bkp.length < 1 || this.state.nn_koszt_szkoleniaBKP == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_koszt_szkoleniaBKP: "incorrect"
            });
        }        
        
        if(this.state.rows.stawka_vat.length < 1 || this.state.nn_stawka == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_stawka: "incorrect"
            });
        }


        if(this.state.rows.terminy.length < 1)
        {
            err = true;

            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: 'Nie podanu terminu szkolenia. ',
                alertMSGTime: dt
             });
        }
    
        if(this.state.rows.cel_szkolenia.length < 100 || this.state.nn_cel_szkolenia == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_cel_szkolenia: "incorrect"
            });
        }



        if(this.state.postanowienia.length < 1)
        {
            err = true;

            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: 'Dodaj min. jedno postanowienie. ',
                alertMSGTime: dt
             });
        }


        if(err) return;
      }

        if(event != null)
        {
            var bnText = event.target.innerHTML;
            event.target.innerHTML = "...";
        }

           var dataPost = {
               id: this.state.rows.id,
               tytul: this.state.rows.tytul,
               cel_szkolenia: this.state.rows.cel_szkolenia,
               dla_kogo: this.state.rows.dla_kogo,
               status: this.state.rows.status,
               wymagane_od_uczestnika: this.state.rows.wymagane_od_uczestnika,
               sk_firmy_id: this.state.rows.podmiot_prowadzacy.id,
               koszt_szkolenia: this.state.rows.koszt_szkolenia,
               koszt_szkolenia_bkp: this.state.rows.koszt_szkolenia_bkp,
               podstawa_kwoty: this.state.rows.podstawa_kwoty,
               stawka_vat: this.state.rows.stawka_vat,
               ilosc_miejsc: this.state.rows.ilosc_miejsc,
               koszt_jm: this.state.rows.koszt_jm,
               platnosci: this.state.rows.platnosci,
               podstawa_kwoty: this.state.rows.podstawa_kwoty,
               stawka_vat: this.state.rows.stawka_vat,
               podstawa_kwoty: this.state.rows.podstawa_kwoty,
               stawka_vat: this.state.rows.stawka_vat,
               termin_odwolania: '2099-01-01 00:00:00',
            

               email_tytul: this.state.rows.email_tytul,
               email_tresc: this.state.rows.email_tresc,
           };

         //  console.log(JSON.stringify(dataPost) );
          
   
           fetch( ConfigX.restApi + "/dash,edit_training" , {
               method: 'POST', 
               body: JSON.stringify(dataPost), 
               headers: {
                   'Content-Type': 'text/html',
                   'X-API-KEY': this.props.apiKey
               }
           })
           .then( res => res.json() )
           .then(json => {

                 var dt = new Date();
                 dt = dt.toISOString();

                if(json.status == "OK")
                {
                   if(event != null)
                   {
                        event.target.innerHTML = bnText;

                        this.setState({
                            alertMSG: 'Informacje zostały zapisane.',
                            alertMSGTime: dt
                         });
                   }
                    
                   
                  

                }else
                {
                    this.setState({
                        alertMSG: 'Wystąpił problem z zapisem danych.',
                        alertMSGTime: dt
                     });  
                }
         
              
           });
    }

    onChangeTytul(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["tytul"] = v; 

            this.setState( {
                rows: rows,
                nn_tytul: ok
            } );

         //  if(ok == "correct" ) this.updateSzkolenie(null);

    }  
    
    onChangeCelSzkolenia(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 100)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["cel_szkolenia"] = v; 

            this.setState( {
                rows: rows,
                nn_cel_szkolenia: ok
            } );

          //  if(ok == "correct" )  this.updateSzkolenie(null);

    }  

    onChangeDlaKogo(event)
    {
            var v   = event.target.value;
            var ok = "correct";
  
            //if(v.length < 100)  { ok = "incorrect"; } else { ok="correct" }        

            var rows = this.state.rows;
            rows["dla_kogo"] = v; 

            this.setState( {
                rows: rows,
                nn_dla_kogo: ok
            } );

           // if(ok == "correct" )  this.updateSzkolenie(null);

    }  


   /* onChangeWymagane(event)
    {
            var v   = event.target.value;
            var ok = "";
              

            var rows = this.state.rows;
            rows["wymagane_od_uczestnika"] = v; 

            this.setState( {
                rows: rows,
                nn_wymagane_od_uczestnika: ok
            } );

        this.updateSzkolenie(null);
    }  */


    onChangeEmailTemat(event)
    {
            var v   = event.target.value;
            var ok = "";
              
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["email_tytul"] = v; 

            this.setState( {
                rows: rows,               
            } );

        //    this.updateSzkolenie(null);
    } 

    onChangeEmailTresc(event)
    {
            var v   = event.target.value;
            var ok = "";
              

            var rows = this.state.rows;
            rows["email_tresc"] = v; 

            this.setState( {
                rows: rows,               
            } );

          //  this.updateSzkolenie(null);
    } 

    onChangeIloscMiejsce(event)
    {
            var v   = event.target.value;
            var ok = "";
              
            v = v.replace(/[^0-9]/g, ""); //wywal litery...
            v = v.replace(",","");
            v = v.replace(".","");
            v = v.replace(" ","");

            var num = parseInt(v);
            if(num < 1 || v.length<1){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["ilosc_miejsc"] = v; 

            this.setState( {
                rows: rows,
                nn_ilosc_miejsc: ok
            } );

         //   if(ok == "correct" )   this.updateSzkolenie(null);
    } 

    onChangeKoszt(event)
    {
            var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["koszt_szkolenia"] = v; 

            this.setState( {
                rows: rows,
                nn_koszt_szkolenia: ok
            } );

          //  if(ok == "correct" )     this.updateSzkolenie(null);

    } 

    onChangeKosztBKP(event)
    {
            var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["koszt_szkolenia_bkp"] = v; 

            this.setState( {
                rows: rows,
                nn_koszt_szkoleniaBKP: ok
            } );

         //   if(ok == "correct" )     this.updateSzkolenie(null);

    } 

    onChangeStawka(event)
    {
            var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["stawka_vat"] = v; 

            this.setState( {
                rows: rows,
                nn_stawka: ok
            } );

    } 

    onChangeJM(event)
    {
        var v   = event.target.value;
        var ok = "";     

        var rows = this.state.rows;
        rows["koszt_jm"] = v; 

        this.setState( {
            rows: rows,
            nn_koszt_jm: ok
        } );

      //  this.updateSzkolenie(null);
    }

    onChangePodstawa(event)
    {
        var v   = event.target.value;
        var ok = "";     

        var rows = this.state.rows;
        rows["podstawa_kwoty"] = v; 

        this.setState( {
            rows: rows,
            nn_koszt_jm: ok
        } );
    }

    onChangeStatus(event)
    {
        var v   = event.target.value;      

        var rows = this.state.rows;
        rows["status"] = v; 

        this.setState( {
            rows: rows,     
        } );

        //this.updateSzkolenie(null);
    }

    onChangePlatnosci(event)
    {
        var v   = event.target.value;
        var ok = "";     

        var rows = this.state.rows;
        rows["platnosci"] = v; 

        this.setState( {
            rows: rows,
            nn_platnosci: ok
        } );

      //  this.updateSzkolenie(null);
    }

    onSelectedFirm(id, nazwa)
    {
        var tmp = this.state.rows;
        tmp.podmiot_prowadzacy = {};
        tmp.podmiot_prowadzacy.id = id;
        tmp.podmiot_prowadzacy.nazwa = nazwa;

        this.setState({
            rows: tmp                        
        });

        this.updateSzkolenie(null);
    }

    onClickTermin(id)
    {
        const data = this.state.rows.terminy.filter( item => {  if(item.id == id ) return true; return false; } );

        var newRefRow = JSON.parse( JSON.stringify(data[0]) );

        this.setState({
            editor_row: newRefRow,
            editor_action: "edit",     
            editor_selected: newRefRow.id              
        });

        this.updateSzkolenie(null);
    }

    onCloseEditor(modifyRow)
    {
        if(modifyRow != null)
        {
           
                //zwróc wszystko oprócz wybranego rekordu.
                var selectedInx = -1;

                this.state.rows.terminy.forEach( (item, index) => {
                    if(item.id == modifyRow.id)
                    {
                        selectedInx = index;                        
                    }
                });

            if(selectedInx >= 0)
            {
                var tmp =  this.state.rows;
                tmp.terminy[selectedInx] = modifyRow;

                this.setState({
                    rows: tmp
                });

              //  console.log("Edytowany " + modifyRow.id);
            }else
            {
              //  console.log("Nowy " + modifyRow.id);
                
                var tmp =  this.state.rows;
                tmp.terminy.push(modifyRow);

                this.setState({
                    rows: tmp
                });
            }
           
        }


        this.setState({
            editor_row: {},
            editor_action: "none",     
            editor_selected: -2              
        });
    }

    onDeleteTime(id)
    {
            var withOut = this.state.rows.terminy.filter( map => {
                if(map.id == id ) return false;
                return true;
            } )

            var tmp = this.state.rows;
            tmp.terminy = withOut;

            this.setState({
                rows: tmp
            });

    }

    onClickNewTime()
    {
        this.setState({
            editor_row:   {},
            editor_action: "new",    
            editor_selected: -1      
        });
    }

    onConfirmDel(type, param, param_id)
    {
      // console.log(type + param + param_id);

       if(type != "yes")  return;

       
       var row = this.state.rows[param_id];
       
       
       this.setState({
                loading: 1
            });


        fetch( ConfigX.restApi+ "/dash,remove_tm" , {
            method: 'POST', 
            body: JSON.stringify(row), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {                   
            
              if(json.status == 'OK')
              {      
                 console.log("ok");
              }
        });


    }
    
    onClickUczestnik(id)
    {
      //  console.log("Wybrano rezerwacje " + id);
    }

    redirect2(action, id, param)
    {
      

        if(param == "deleted_member") //usunięto szkolenie
        {
            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: "Uczestnik został usunięty!",
                alertMSGTime: dt
            });                  
        }

       

        if(action == "det")
        {
            this.getDataUczestnicy();
        }
     
        
        this.setState(
            {
                view: action,
                selectedId: id,
            }
           );
    }

    onChangeStatusUczestnik(status,id)
    {
      //  console.log(id + status);


        var dataPost = {
            id: id,
            status: status
          }
 
         fetch( ConfigX.restApi + "/dash,confirm_mem" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() )
         .then(json => {
                    

             if(json.status == "OK" )
             {
                var idCh = -1;
                for(var k=0; k<this.state.uczestnicy_rows.length; k++)
                {
                   if(this.state.uczestnicy_rows[k].id == id) {idCh = k; break; }
                }

           
                if(idCh >= 0)
                {
                    var tmpU = this.state.uczestnicy_rows;
                    tmpU[idCh].status = status;

                    this.setState({
                        uczestnicy_rows: tmpU                             
                     });                    
                }
              
             }                  
            
         });

    }

    onChangeUczestnikFraza(event)
    {

        this.setState({
            uczestnicy_fraza: event.target.value
        });
    }

    onChangeUczestnikStatus(event)
    {
        //console.log(event.target.value);
        this.setState({
            uczestnicy_status: event.target.value
        });
    }

    onChangeStatusFrekwencja(val,sk_szkolenia_terminy_id, sk_szkolenia_uczestnicy_id)
    {

        var dataPost = {
            id: sk_szkolenia_terminy_id,
            status: val
          }
 
         fetch( ConfigX.restApi + "/dash,confirm_absent" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() )
         .then(json => {
                    

             if(json.status == "OK" )
             {
                var idCh = -1;
                for(var k=0; k<this.state.uczestnicy_rows.length; k++)
                {
                   if(this.state.uczestnicy_rows[k].id == sk_szkolenia_uczestnicy_id) {idCh = k; break; }
                }

               // console.log("DEEE " + idCh);
                if(idCh >= 0)
                {
                    var tmpU = this.state.uczestnicy_rows;

                    var idTerm = -1;

                    for(var kk=0; k<tmpU[idCh].terminy.length; kk++)
                    {
                        if(tmpU[idCh].terminy[kk].id == sk_szkolenia_terminy_id) {  idTerm=kk; break; }
                    }

                    if(idTerm > 0)
                    {
                        tmpU[idCh].terminy[idTerm].frekwencja = val; 

                        this.setState({
                            uczestnicy_rows: tmpU                             
                         }); 
                    }
                    
                }
              
             }                  
            
         });
    }

    onConfirmDel(type, param, param_id)
    {
      // console.log(type + param + param_id);

       if(type != "yes")  return;


       //sprawdz czy sa potwierdzone uczestnictwa..
    
       var potwierdzony = false;
      
       this.state.uczestnicy_rows.forEach(item => {
           if( item.status == 'potwierdzone')
           {
         //    console.log("Nie mozna usunąc z powodu:  " + item.id + "  " + item.status);
             potwierdzony = true;
           }
        })

       if(potwierdzony)
       {
           var dt = new Date();
           dt = dt.toISOString();
         
           this.setState({
              alertMSG: 'Nie możesz usunać tego zgłoszenia. Wypisz wszystkich potwierdzonych członków.',
              alertMSGTime: dt
           });
           return;
       }


         //
         var dataPost = {
            id: param_id,       
        };

   
        fetch( ConfigX.restApi + "/dash,del_training" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             if(json.status == "OK")
             {
                this.props.redirect("list",-1,"deleted");
             }                 
        });

        
        
    }


    setPostanowienia(rows)
    {
        this.setState({
            postanowienia: rows
        });
    }

    onSendSuccess()
    {
        var dt = new Date();
        dt = dt.toISOString();

        this.setState({
            alertMSG: 'Brawo! Wiadomość została wysłana',
            alertMSGTime: dt
         });
    }

    onSendSuccess()
    {
        var dt = new Date();
        dt = dt.toISOString();

        this.setState({
            alertMSG: 'Brawo! Wiadomość została wysłana',
            alertMSGTime: dt
         });
    }



    delUczestnik(type, px_group_id, sk_szkolenia_id)
    {
      // console.log(type +" "+ param +" "+ param_id);

         var dataPost = {
            sk_szkolenia_id: sk_szkolenia_id,
            id_mem: px_group_id       
        };


        fetch( ConfigX.restApi + "/dash,del_member" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
   
             if(json.status == "OK")
             {                   
                this.setState({
                    alertMSG: 'Uczestnik został usunięty.',
                    alertMSGTime: "200"
                 });

                 this.getDataUczestnicy();

             }else{
                this.setState({
                    alertMSG: 'Wystąpił błąd podczas usuwania.',
                    alertMSGTime: "200"
                 });
             }                 
        });

        
        
    }

    render()
    {      

        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>                                               
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        } 
        
       
        var iloscTerminow = 0; 
        var iloscUczestnikow = 0; 
        var iloscUczestnikowOczekuje = 0; 
        var iloscUczestnikowPotwierdzone = 0; 
        var iloscUczestnikowOdrzucone = 0; 

        this.state.uczestnicy_rows.map( (item,index) => {
              if(item.status == "oczekuje")      iloscUczestnikowOczekuje++; 
              if(item.status == "potwierdzone")  iloscUczestnikowPotwierdzone++; 
              if(item.status == "odrzucone_brak_miejsc" || item.status == "odrzucone_odwolanie_szkolenia" )     iloscUczestnikowOdrzucone++; 
        })


        var msg = ""
        if(iloscUczestnikowOczekuje == 1) msg = "Brawo! Masz  chętną osobą na to szkolenie. Przejdź do poniższej listy uczestników i potwierdź zgłoszenie. ";
        if(iloscUczestnikowOczekuje >= 2) msg = "Pojawiły się osoby chętne na to szkolenie. Przejdź do poniższej listy uczestników i potwierdź zgłoszenie. ";


        if( this.state.rows.hasOwnProperty("terminy")  )     iloscTerminow = this.state.rows.terminy.length;
        if(this.state.uczestnicy_rows != undefined) iloscUczestnikow = this.state.uczestnicy_rows.length;
          


        if(this.state.rows.terminy.length < 1)
        {
            msg = "Nie podano jeszcze terminu szkolenia. Każde dodane szkolenie musi posiać min. jeden termin.  ";
        }


      

 

       var proc = 100 * parseInt( iloscUczestnikowPotwierdzone );
       proc = parseInt( proc / this.state.rows.ilosc_miejsc);
       if(proc == NaN || proc == undefined || proc == null || isNaN(proc) ) proc = 0;
      
        if(proc > 100) proc = 100;

       var styleProgress = {
          width: proc+"%"
       }
       if(proc ==0 ) styleProgress = {
         width: 2
     }


        return (
            <div className='details'>
                 <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy szkoleń
                 </div>
                 
                                    
                    <h3>
                    ✏ {this.state.rows.tytul}                                                      
                    </h3>
                

                    <TipMessage  msg={msg} type="notice" />

                    <div className='dashForm'>

                          <div className='FormField'> 
                               <label>Podmiot prowadzący<span className='required'>*</span></label>
                               <FirmSelector onSelectedFirm={this.onSelectedFirm.bind(this)} apiKey={this.props.apiKey}  id={this.state.rows.podmiot_prowadzacy.id} title={this.state.rows.podmiot_prowadzacy.nazwa} onCloseEditor={this.onCloseEditor.bind(this)}/>
                          </div>

                          <div className='FormField inlineField'>
                               <label>Tytuł<span className='required'>*</span></label>
                               <input type="text" className={"field300 " + this.state.nn_tytul } value={this.state.rows.tytul}  col="tytul" onChange={this.onChangeTytul.bind(this) } />
                          </div>

                          <div className='FormField inlineField'>
                               <label>Status<span className='required'>*</span></label>
                               <select defaultValue={this.state.rows.status} onChange={this.onChangeStatus.bind(this) } col="status" >
                                  <option value="widoczne">Widoczne</option>              
                                  <option value="robocze">Robocze</option>              
                                  <option value="niewidoczne">Niewidoczne</option>
                                  <option value="archiwalne">Archiwalne</option>              
                               </select>
                          </div>
                  
                    
                          <div className='FormField'>
                               <label>Cel szkolenia<span className='required'>*</span> (min. 100 znaków)</label>                  
                               <textarea  className={"field100p field100h " + this.state.nn_cel_szkolenia} value={this.state.rows.cel_szkolenia} col="cel_szkolenia" onChange={this.onChangeCelSzkolenia.bind(this) }   />
                          </div>

                          <div className='FormField'>
                               <label>Dla kogo?<span className='required'></span> </label>                  
                               <textarea  className={"field100p field100h " + this.state.nn_dla_kogo} value={this.state.rows.dla_kogo} col="dla_kogo" onChange={this.onChangeDlaKogo.bind(this) }   />
                          </div>                

                          <div className='FormField inlineField'>
                               <label>Ilość miejsc<span className='required'>*</span> (maks. liczba uczestników)</label>
                               <input type="text" className={"field200 " + this.state.nn_ilosc_miejsc} value={this.state.rows.ilosc_miejsc}  col="ilosc_miejsc" onChange={this.onChangeIloscMiejsce.bind(this) }    />
                          </div>
                   
                   <br/>
                          <div className='FormField inlineField'>
                               <label>Koszt szkolenia<span className='required'>*</span> (PLN)</label>
                               <input type="text" className={"field100 " + this.state.nn_koszt_szkolenia} value={this.state.rows.koszt_szkolenia}  col="koszt_szkolenia" onChange={this.onChangeKoszt.bind(this) }    />
                          </div>
            
                          <div className='FormField inlineField'>
                               <label>Koszt szkolenia dla członków BKP<span className='required'>*</span> (PLN)</label>
                               <input type="text" className={"field100 " + this.state.nn_koszt_szkoleniaBKP} value={this.state.rows.koszt_szkolenia_bkp}  col="koszt_szkolenia_bkp" onChange={this.onChangeKosztBKP.bind(this) }    />
                          </div>

                          <div className='FormField inlineField'>                         
                               <select defaultValue={this.state.rows.podstawa_kwoty}  col="podstawa_kwoty" onChange={this.onChangePodstawa.bind(this)} >
                                  <option value="netto">Netto</option>
                                  <option value="brutto">Brutto</option>
                               </select>
                          </div>

                          <div className='FormField inlineField'>
                               <label>(%) VAT<span className='required'>*</span> </label>
                               <input type="text" className={"field50 " + this.state.nn_stawka} value={this.state.rows.stawka_vat}  col="stawka_vat" onChange={this.onChangeStawka.bind(this) }    />
                          </div>

                          <div className='FormField inlineField'>
                               <label>Podany koszt obejmuje<span className='required'>*</span></label>
                               <select defaultValue={this.state.rows.koszt_jm}  col="koszt_jm" onChange={this.onChangeJM.bind(this)} >
                                  <option value="UL">Uczestnik / Za jedną lekcje</option>
                                  <option value="UW">Uczestnik / Wszystkie lekcje</option>
                               </select>
                          </div>

                          <div className='FormField inlineField'>
                               <label>Płatność<span className='required'>*</span></label>
                               <select defaultValue={this.state.rows.platnosci}  col="platnosci" onChange={this.onChangePlatnosci.bind(this) } >
                                  <option value="LP">Po każdej lekcji</option>                               
                                  <option value="WP">Po wszystkich lekcjach</option>
                                  <option value="PL">Z górzy przed każdą lekcją</option>
                                  <option value="PW">Z góry za wszystkie lekcje</option>
                               </select>
                          </div>                                               



                    

                        <div className='FormField FormButtons'>
                           zapisz
                            <ButtonConfirm   onConfirm={this.onConfirmDel.bind(this) } param="sk_szkolenia" param_id={this.state.rows.id}  title="usuń szkolenie*" query="Na pewno chcesz usunąć to szkolenie?"  />                                                           
                         </div>

                         <div className='simplyTipAlert'>
                            *Przeniesienie szkolenia do archiwum jest możliwe wyłącznie, gdy nie ma żadnego potwierdzonego uczestnictwa na liście uczestników.  Jeśli chcesz usunąć szkolenie wypisz ze szkolenia wszystkich uczestników.                             
                         </div>

                    </div>  

                    <div className='location' onClick={this.onBackClick.bind(this) }>
                     <br/>
                     <img src={BackPNG} /> Powrót do listy szkoleń
                     <br/>
                    </div>                
             

         
                 <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy szkoleń
                 </div>


                 <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Powiadomienie"/>
           
            </div>
        )
    }
}

export default Det;