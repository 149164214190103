

class ConfigX
{
    static id      = '1'; // nr licencji w razie gdyby ktoś chciał to licencjnować dalej..
    static pathDef = '/front/panel/';
    static restApi = 'https://bkplab.eu';  
    //static restApi = 'http://test.bkplab.eu';   
    static title   = 'SzkoleniaBKP';
    static ENV     = 'prod'; //dev - test, prod - final
    //static ENV     = 'dev'; //dev - test, prod - final
    static GusKey  = 'e8f54f6323d24f46a78e';
} 

export default ConfigX;