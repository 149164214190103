import React from 'react';
import ConfigX from '../../../ConfigX'
import Det from './Det';
import AlertMsg from '../../../components/AlertMsg';
import DetWnioskiRow from "./DetWnioskiRow";
import FileUploader from '../../../components/FileUploaderUser';
import Linkify from 'react-linkify';
import loading from '../../../image/loading.gif'

class Main extends React.Component
 {
    constructor(props)
    {        

        super(props);         

        this.state = {

            // search_bar: {                                                           
            //     tytul: "",
            //     nazwa_firmy: "",
            //     status: "",
            //     id: "",                    
            // },
            // pagger: {
            //     page: 1,
            //     limit: 1000,
            //     order_col: "",
            //     order: ""
            // },
            loading: 1,
            view: "list",
            selectedId: -1,
            rows: [],
            result: [],
            
            alertMSG: '',
            alertMSGTime: 0
           
        }

        
        
    }


   

    //Pobranie danych
    getData(  onFinish )
    {
      
        //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
        var wait = setTimeout(
             () => {
                this.setState({ loading: 1 });
             }
            ,500);

        var tmpNonRef = JSON.stringify( this.state );
        tmpNonRef = JSON.parse(tmpNonRef);

        var dataPost = {}

        fetch( ConfigX.restApi+ "/user,get_research" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        
             //console.log(JSON.stringify(json) );
             clearTimeout(wait);

             this.setState({
                rows: json.rows,
                result: json.result,
                loading: 0
             }); 
                         
             if(onFinish != null && onFinish != undefined ) onFinish();
           
        });
    }
        
 

    redirect(action, id, param)
    {
      

        if(param == "deleted") //usunięto szkolenie
        {
            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: "Usługa została usunięta!",
                alertMSGTime: dt
            });                  
        }

 
       

        if(action == "list")
        {
            this.getData(null);
        }
     
        
        this.setState(
            {
                view: action,
                selectedId: id,
            }
           );
    }

    componentDidUpdate()
    {
        //console.log(document.location.href + " eee ");
    }

    componentDidMount()
    {
        //console.log("List mount " + this.state.search_bar);    
        this.getData(null);                     
    }

    onDialogOK(param)
    {
     
            this.setState({
                alertMSG: '',
                alertMSGTime: 0,
            });
    }
 

    przyjmijWycene(event)
    {      
        var dataPost = {
            id_wniosku: event.target.id,
            typ: "kwota_przyjeta"
         };    
  
  
         fetch( ConfigX.restApi + "/user,przyjmijWyceneWniosku" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() )
         .then(json => {
        
            if(json.status == "OK")
            {            
                this.setState({
                  alertMSG: "przyjęto wycenę pomyślnie",
                  alertMSGTime: 12
                })

                this.getData();
            }

         });                      
    }

    odrzucWycene(event)
    {      
        var dataPost = {
            id_wniosku: event.target.id,
            typ: "kwota_odrzucona"
         };    
  
  
         fetch( ConfigX.restApi + "/user,odrzucWyceneWniosku" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() )
         .then(json => {
        
            if(json.status == "OK")
            {            
                this.setState({
                  alertMSG: "odrzucono pomyślnie wycenę",
                  alertMSGTime: 12
                })

                this.getData();
            }                           
         });                      
    }
  

    render()
    {   
 

        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>

             

               {/* <SearchBar search_bar={this.state.search_bar} goSearch={this.goSearch.bind(this)} goClear={this.goClear.bind(this)}  changeFilter={this.changeFilter.bind(this)}/>                                      */}
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        }
         

       

        if(this.state.view == "det" && this.state.selectedId > 0)
        {
             return (
                 <div className='list'>                 
                     <Det redirect={this.redirect.bind(this) } id={this.state.selectedId} apiKey={this.props.apiKey} /> 
                 </div>
             )
        }




        
    //------------------------------------------
       //------------------------------------------
       //------------------------------------------

            var i = 0;

            if(this.state.rows.length == 0)
            {
                 return (
                     <div className='rowTrain'>
                       <section className="container">
                            <h1>Twoje badania</h1>
                            <div>Nie zleciłeś żadnego badania.</div>
                       </section>
                     </div>
                 )
            }

            var przyjmij_wycene = '';
            var odrzuc_wycene = '';
            var extra_section = '';

            return (
           

                <div className='rowTrain'>
                  <section className="container"> 
                       <h2>Twoje wnioski na badania</h2>

                    {
                        
                        this.state.rows.map( item   => {
                                    i++;

                            var status = (
                                <span style={{"color":"green"}}>Status: {item.status_wniosku}</span>
                            )
                            var komunikat = '';

                            if(item.status_wniosku == 'nowy' || item.status_wniosku == 'przygotowanie wyceny')
                            {
                                komunikat = (
                                    <div className='info_war'>Wniosek jeszcze oczekuje na wycenę. Kiedy wniosek zostanie wyceniony dostaniesz odpowiednią wiadomość e-mail.</div>
                                )                                
                            }

                            if(item.status_wniosku == 'wycena gotowa')
                            {
                                 komunikat = (
                                    <div className='info_succ'>Wyceniono ten wniosek, potwierdź lub odrzuć wycenę klikając w przycisk poniżej.</div>
                                ) 
                              
                                 przyjmij_wycene = (
                                    <div className='card-badania-button-success'>
                                        <a id={item.id_wniosku} href='#'  onClick={this.przyjmijWycene.bind(this)} >Przyjmij wycenę</a>                                           
                                    </div> 
                                )
                                
                                 odrzuc_wycene = (
                                    <div className='card-badania-button-danger'>
                                        <a id={item.id_wniosku} href='#'  onClick={this.odrzucWycene.bind(this)}>Odrzuć wycenę</a>                                           
                                    </div> 
                                )
                            }

                            if(item.faktura)
                            {
                               extra_section = (

                                        <div className="even-info">
                                
                                            <p>                                
                                                <span style={{"color":"#000"}}>Pobierz fakturę:</span>  <br/>                                                                  
                                            </p>
                                           
                                            <FileUploader typ="fak_faktury" typ_id={item.faktura.id} apiKey={this.props.apiKey} onlyDisplay="1"/>                                          
                                                                                                                                        
                                        </div>
                           
                               )
                            }


                                    return (

                                  <>   

                                    <div className="row_badania" key={i}>

                                        {komunikat}

                                        <div className='article-badania'>                                           
                                            <div className='card-badania-header'>
                                                <div>{item.tytul}</div>
                                                <div>{item.podmiot_prowadzacy.nazwa}</div> 
                                                <div>{status}</div>
                                            </div>                                          
                                        </div>

                                        <div className='article-badania'>
                                            <div className='card-badania flex-direction-column'>
                                                
                                            <span>Informacje ogólne</span>

                                                <label>E-mail</label>
                                                <span>{item.info_email}</span>

                                                <label>Osoba kontaktowa</label>
                                                <span>{item.info_osoba_kontaktowa}</span>

                                                <label>Telefon</label>
                                                <span>{item.info_telefon}</span>

                                                <label>Strona WWW</label>
                                                <span>{item.info_www}</span>

                                                <label>Opis procedury</label>
                                                <span>
                                                <Linkify
                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                            <a target="blank" href={decoratedHref} key={key}>
                                                                {decoratedText}
                                                            </a>
                                                        )}
                                                    >{item.opis_procedury}
                                                </Linkify>  

                                                </span>

                                                <label>Informacje ogólne</label>
                                                <span>

                                                <Linkify
                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                            <a target="blank" href={decoratedHref} key={key}>
                                                                {decoratedText}
                                                            </a>
                                                        )}
                                                    >{item.informacje_ogolne}
                                                </Linkify>                                                     
                                              
                                                </span>

                                                <FileUploader typ="sk_badania" typ_id={item.id} apiKey={this.props.apiKey} onlyDisplay="1" deleteIcon="off" showDate='off' showSize='off' />

                                            </div>

                                            <div className='card-badania flex-direction-column'>
                                                <span>Informacje od prowadzącego badanie</span> 

                                                <label>Kwota Netto</label>
                                                <span>{item.kwota_netto}</span>

                                                <label>Kwota VAT</label>
                                                <span>{item.kwota_vat}</span>

                                                <label>Informacje ogólne</label>
                                                <span>
                                                    
                                                <Linkify
                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                            <a target="blank" href={decoratedHref} key={key}>
                                                                {decoratedText}
                                                            </a>
                                                        )}
                                                    >{item.info_dla_wnioskodawcy}
                                                </Linkify>                                                      
                                            
                                                </span>

                                                {extra_section}                                       

                                                <span>{przyjmij_wycene} &nbsp; {odrzuc_wycene} </span>
                                            </div>

                                            
                                        </div>
                                       
                                                                            
                                    </div>

                                 


                               

                                    </>
                                    )
                                } )

                    }
                        </section>

                    <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } msgParam="ok"  onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>
                </div>
            )

       }



        
    }



 export default Main