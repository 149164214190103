import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfigX from '../../../ConfigX';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';


registerLocale('pl', pl)


class SearchBar extends React.Component
 {
        constructor(props)
        {
            super(props)  

            this.state = {          
                badania: [],   
                firmy: [],                  
             }     
                                  
        }
        componentDidMount()
        {
            var dataPost = {};
        
            fetch( ConfigX.restApi + "/dash,getBadaniaCombo" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
       
                 this.setState({
                    badania: json,
                 }); 
                                                
            });  


            fetch( ConfigX.restApi + "/dash,getFirmyCombo" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
       
                 this.setState({
                    firmy: json,
                 }); 
                                                
            });   

        }

        onChangeNazwaFirmy(event)
        {                          
            this.props.changeFilter('id_firmy', event.target.value);
        }

        onChangeNIP(event)
        {                          
            this.props.changeFilter('nip', event.target.value);
        }

      
        onChangeBadanie(event)
        {           
            this.props.changeFilter('id_badania',  event.target.value );
        }

        onChangeDateFrom(event)
        {
            this.props.changeFilter('od', event);
        } 

        onChangeDateTo(event)
        {
            this.props.changeFilter('do', event);
        }

        onChangeTyp(event)
        {
            this.props.changeFilter('typ', event.target.value);
        }

        onChangeCzlonekBKP(event)
        {
            this.props.changeFilter('czlonek_bkp', event.target.value);
        }

        goSearch()
        {
            this.props.goSearch();
        }

        goClear()
        {             
            this.props.goClear();
        }


        render()
        { 

            return (
                <div className='search_bar'>

                    <div className='filter'>
                          <div className='filter_label'>Nazwa firmy</div>
                          <div className='filter_content'>
                          <select value={this.props.search_bar.id_firmy}  onChange={this.onChangeNazwaFirmy.bind(this) }  >
                                { 
                                  this.state.firmy.map((row,index)=>{
                                    return(
                                        <option key={index} value={row.id}>{row.nazwa}</option>
                                    )
                                  })                                
                                }                                       
                               </select>                                                
                          </div>                                 
                    </div>


                    <div className='filter'>
                          <div className='filter_label'>Badanie</div>
                          <div className='filter_content'>
                              <select value={this.props.search_bar.id_badania}  onChange={this.onChangeBadanie.bind(this) }  >
                                { 
                                  this.state.badania.map((row,index)=>{
                                    return(
                                        <option key={index} value={row.id}>{row.tytul}</option>
                                    )
                                  })                                
                                }                                       
                               </select>
                          </div>                                 
                    </div>                   
             
                    <div className='filter'>
                          <div className='filter_label'>NIP</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.nip} onChange={this.onChangeNIP.bind(this)}    />
                          </div>                                 
                    </div>

                    <div className='filter'>
                          <div className='filter_label'>Data złożenia wniosku</div>
                          <div className='filter_content'>
                                <DatePicker
                                selected={this.props.search_bar.od}
                                onChange={this.onChangeDateFrom.bind(this)}
                             
                                dateFormat="yyyy-MM-dd"
                                locale="pl"
                                />                                                                                         
                          </div>
                          <div className='filter_label'> - </div>
                          <div className='filter_content'>
                                <DatePicker
                                selected={this.props.search_bar.do}
                                onChange={this.onChangeDateTo.bind(this)}
                              
                                dateFormat="yyyy-MM-dd"
                                locale="pl"
                                />                                                                                         
                          </div>
                    </div>

                    <div className='filter filterButton'>

                         {/*  <div className='buttonSearch' onClick={this.goSearch.bind(this)}>Szukaj</div>   */}                            
                           <div className='buttonSearch' onClick={this.goClear.bind(this) }>Wyczyść</div>                               
                    </div>
           

                </div>
            )
        }
 }

 export default SearchBar;