
import React from 'react';
import ConfigX from '../ConfigX';
import {Navigate} from 'react-router-dom';

import PageTraining from '../views/page/PageTraining'
import PageResearch from '../views/page/PageResearch';
import Kontakt from '../views/page/Kontakt';
import Onas from '../views/page/Onas';
import Regulamin from '../views/page/Regulamin';

class PageContainer extends React.Component
 {

  constructor(props)
  {
    super(props)

    this.state = { 
        page: this.props.page,
        txt: ""
    }
  }

 
  checkLogout()
  {
  //  console.log("logout: ",this.state.page);

    if(this.state.page == 'logout')
    { 
            var localUsr = window.localStorage.getItem('user');

        //  console.log("||| " + localUsr);

            if(localUsr != null && localUsr != "undefinded" && localUsr.length > 3)
            {
                      var jsonUsr = JSON.parse(localUsr);
            
                      fetch( ConfigX.restApi + '/rest,go_logout', {
                        method: 'POST', 
                        body: JSON.stringify(this.state), 
                        headers: {
                            'Content-Type': 'text/html',
                            'X-API-KEY': jsonUsr.apiKey
                        }
                    })
                    .then(res => res.json() )
                    .then(res => {

                      window.localStorage.removeItem("user");  
                      this.props.resetApiKey();

                      this.setState({
                        page: "logout_success"
                      });
                      
                    })

            }          

    }

  }

  componentDidMount()
  {
    //console.log("PageContainer mount " + this.state.page);
    this.checkLogout();
  }

  componentDidUpdate()
  {     
    
    //console.log("PageContainer update " + this.state.page);
    this.checkLogout();


    if(this.state.page != this.props.page)
    {
        this.setState({
            page: this.props.page,         
        });
    }

  }




  render(){
    
//console.log("Pagecontainer: ",this.state.page);

    if(this.state.page == "szkolenia" || this.state.page == "szczegoly" || this.state.page == "usługi") 
    {
     
        document.title = ConfigX.title  + " / Szkolenia";

        return (
            <section className="container">             
                 <PageTraining apiKey={this.props.apiKey} page={this.state.page} />
            </section>
          );
    }

    if(this.state.page == "badania" || this.state.page == "szczegolyB") 
    {
     
        document.title = ConfigX.title  + " / Badania";

        return (
            <section className="container">             
                 <PageResearch apiKey={this.props.apiKey} page={this.state.page} />
            </section>
          );
    }

    /*if(this.state.page == 'kontakt')
    {
      document.title = ConfigX.title + " / Kontakt";

      return (
        <section className='container'>
          <Kontakt apiKey={this.props.apiKey} page={this.state.page} />
        </section>
      )
    }

    if(this.state.page == 'o_nas')
    {
      document.title = ConfigX.title + " / O nas";

      return (
        <section className='container'>
          <Onas apiKey={this.props.apiKey} page={this.state.page} />
        </section>
      )
    }*/

    if(this.state.page == "logout_success" ) 
    {
           
        return (
              <div>
              {<Navigate to={ConfigX.pathDef} />}
          </div>
          );
    }

    if(this.state.page == "regulamin" ) 
    {
           
    //console.log("page: ",this.state.page); 
      document.title = ConfigX.title  + " / Regulamin";

      return (
          <section className="container">             
               <Regulamin />
          </section>
        );
    }


    document.title = ConfigX.title  + " / " + this.state.page;

    return (
        <section className="container">             
          {this.state.page} 
        </section>
      );

  }
 
}

export default PageContainer;
