
import React from 'react';
//import { Link } from 'react-router-dom';
import ConfigX from '../ConfigX';

import '../App.css';
import '../MG.css';
import BarTop from '../components/BarTop';
import PageContainer from '../components/PageContainer';
import BarBottom from '../components/BarBottom';

class Page extends React.Component
 {

  constructor(props)  
  {
    super(props)       
    
    var apiKey = "";

    var localUsr = window.localStorage.getItem('user');

    if(localUsr != null && localUsr != "undefinded" && localUsr.length > 3)
    { 
       var jsonUsr = JSON.parse(localUsr);
       apiKey = jsonUsr.apiKey;

    }

    this.state = {
      page: this.props.page, 
      apiKey: apiKey,
      view: this.props.view ? this.props.view : "choose",
      refresh: 0,  
    }

  }

  resetApiKey()
  {
      this.setState ({
        apiKey: '',
      }    
    )
   
  }

  componentDidMount()
  {
   // console.log("Mount Page"  );  

    
    
  }

  componentDidUpdate()
  {
    //console.log("Update Page ");    
    
    if(this.state.page != this.props.page)
    {
        this.setState({
            page: this.props.page,        
        });
    }
  }

  selectLogin(option)
  {    
     // console.log("Select Login " + option); 

      this.setState({
        view: option,
        refresh: this.state.refresh + 1,
      });

  }


  render(){
//console.log("Page: ",this.state.page);
    return (
        <div id="App" className="App AppPage">
            <BarTop page={this.state.page} selectLogin={this.selectLogin.bind(this)} refresh={this.state.refresh}/>
              <PageContainer page={this.state.page} apiKey={this.state.apiKey} resetApiKey={this.resetApiKey.bind(this)} />                   
        </div>
      );

  }
 
}

export default Page;
