import React from 'react';

import checkPNG from '../../../image/check.png'
import nonePNG from '../../../image/none.png'
import infoPNG from '../../../image/info.png'
import { Link } from 'react-router-dom';
import ConfigX from '../../../ConfigX';


import Messanger from '../../../components/Messanger';
 
class TableRow extends React.Component
 {
        constructor(props)
        {
            super(props)
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onRowClick(id);
        }

        render()
        {
           
            var letter = '';
            if( this.props.row.nazwisko.length > '0' )
            {
                letter = this.props.row.nazwisko.charAt(0);
            }

            let czlonek_bkp = "NIE";
            if(this.props.row.czlonek_bkp == "1")
            {
                czlonek_bkp = "TAK";
            }
    
            var zgoda_handlowa = "NIE";
            if(this.props.row.zg_handlowa == 1)
            {
                zgoda_handlowa = "TAK";
            }

            return (
                 
                        <tr className='rowLink' >
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} > {this.props.num} </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > <div className="letter" id={this.props.row.id}  onClick={this.onRowClick.bind(this)} > {letter} </div>   </td> 
                             <td id={this.props.row.id}> <Messanger apiKey={this.props.apiKey}    user_id={this.props.row.id}    /> </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_left" > {this.props.row.nazwisko}  &nbsp;  </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_left" > {this.props.row.imie}  </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_left" > {this.props.row.ulica}  </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > {this.props.row.nr_domu}  </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > {this.props.row.nr_mieszkania}  </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > {this.props.row.kod_pocztowy}  </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_left" > {this.props.row.miasto}  </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > {this.props.row.telefon}  </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > {this.props.row.email}  </td>               
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > {this.props.row.ostatnia_aktywnosc}  </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > {czlonek_bkp}  </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > {zgoda_handlowa}  </td>
                        </tr>                 
            )
        }
 }

 export default TableRow;