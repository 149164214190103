import React from 'react';
import ConfigX from '../ConfigX';

class FirmSelector extends React.Component
{
    constructor(props)
    {
        super(props)
        
        this.state = {
            view: "none",
            id: this.props.id,
            title: this.props.title,
            rows: [],
            nn_nazwa: "",
            nn_nip: "",
            nn_ulica: "",
            nn_nr_domu: "",
            nn_nr_mieszkania: "",
            nn_kod_pocztowy: "",
            nn_miasto: "",
            nn_telefon: "",
            nn_email: "",

            nn_nazwa_ok: "",
            nn_nip_ok: "",
            nn_ulica_ok: "",
            nn_nr_domu_ok: "",
            nn_nr_mieszkania_ok: "",
            nn_kod_pocztowy_ok: "",
            nn_miasto_ok: "",
            nn_telefon_ok: "",
            nn_email_ok: "",

        }; 
    }

    componentDidMount()
    {
        this.getData();
    }

    onSelectedFirm(event)
    {
        this.setState({
            view: "none",
            id:  event.target.getAttribute("id"),
            title:  event.target.getAttribute("nazwa"),
        });

        this.props.onSelectedFirm( event.target.getAttribute("id"),  event.target.getAttribute("nazwa")  );       
    }

    onSelectedFirmAfterAdded(id, nazwa)
    {
        this.setState({
            view: "none",
            id:  id,
            title:  nazwa,
        });

        this.props.onSelectedFirm( id,  nazwa  );       
    }

    showList(v)
    {
        this.setState({
            view: "list"
        });
    }

    showNew(v)
    {
        this.setState({
            view: "new",
            id: this.props.id,
            title: this.props.title,         
            nn_nazwa: "",
            nn_nip: "",
            nn_ulica: "",
            nn_nr_domu: "",
            nn_nr_mieszkania: "",
            nn_kod_pocztowy: "",
            nn_miasto: "",
            nn_telefon: "",
            nn_email: "",

            nn_nazwa_ok: "",
            nn_nip_ok: "",
            nn_ulica_ok: "",
            nn_nr_domu_ok: "",
            nn_nr_mieszkania_ok: "",
            nn_kod_pocztowy_ok: "",
            nn_miasto_ok: "",
            nn_telefon_ok: "",
            nn_email_ok: "",
            czlonek_bkp: 0,
        });
    }

    getData()
    {
        fetch( ConfigX.restApi + "/dash,get_firms" , {
            method: 'POST',             
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             this.setState({
                rows: json
             });      
           
        });
    }


    onChangeNazwa(event)
    {
        var ok = "";

        if(event.target.value.length > 2)
        {
            ok = "correct";
        }else ok = "incorrect";

        this.setState({
            nn_nazwa: event.target.value,
            nn_nazwa_ok: ok
        });
    }

    isValidNip(nip) {
        if(typeof nip !== 'string')
            return false;
    
        nip = nip.replace(/[\ \-]/gi, '');
    
        let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(nip.substring(9, 10));
        let weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
            sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        }
        
        return sum % 11 === controlNumber;
    }
    onChangeNIP(event)
    {
         var ok = "";

         var v = event.target.value;
   
 
         v = v.replace(/[^0-9]/g, ""); //wywal litery...
         v = v.replace(" ","");

         if(  this.isValidNip(v)  )
         {
            ok = "correct";
         }else ok= "incorrect";

        this.setState({
            nn_nip: v,
            nn_nip_ok: ok
        });
    }

    onChangeUlica(event)
    {
        var ok = "";
        if(event.target.value.length > 2)
        {
            ok = "correct";
        }else ok ="incorrect";

        this.setState({
            nn_ulica: event.target.value,
            nn_ulica_ok: ok
        });
    }

    onChangeNrDomu(event)
    {
        var ok = "";
        if(event.target.value.length > 0)
        {
            ok = "correct";
        }else ok ="incorrect";

        this.setState({
            nn_nr_domu: event.target.value,
            nn_nr_domu_ok: ok
        });
    }


    onChangeNrN(event)
    {
       var ok ="";
        if(event.target.value.length > 0) ok ="correct";

        this.setState({
            nn_nr_mieszkania: event.target.value          ,
            nn_nr_mieszkania_ok: ok
        });
    }

    onChangeStatus(event)
    {
        var v   = event.target.value;      

        this.setState( {
            czlonek_bkp: v,     
        } );

    }

    onChangeKod(event)
    {
        var ok = "";
     
    
        var v = event.target.value;        

      //  v = v.replace(/[^\d.-]/g, '');

    //    const arr = v.split("-");
     //   if(arr.length == 2 && arr[0].length == 2 && arr[1].length == 3 && /^\d+$/.test(arr[0])  && /^\d+$/.test(arr[1])   )
    //    {
            ok ="correct";
    //    }else ok ="incorrect";

        this.setState({
            nn_kod_pocztowy: v,
            nn_kod_pocztowy_ok: ok
        });
    }

    onChangeMiasto(event)
    {
        var v = event.target.value;

        v = v.replace(/[0-9]/g, ""); //wywal cyfrwy...

        var ok = "";
        if(v.length > 1)
        {
            ok = "correct";
        }else ok ="incorrect";

        this.setState({
            nn_miasto: v,
            nn_miasto_ok: ok,
        });
    }

    onChangeTelefon(event)
    {

        var v = event.target.value;

       // v = v.replace("+48","");

      //  v = v.replace(/[^0-9]/g, ""); //wywal litery...
        v = v.replace(" ","");

      //  if(v.substr(0,3) != '+48')
      //  {
      //      v = "+48" + v;
     //   }
       
        
        var ok = "";
        if(v.length == 12  ) 
        {
              ok = "correct";
        }

        this.setState({
            nn_telefon: v,
            nn_telefon_ok: ok
        });
    }


    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

    onChangeEmail(event)
    {
        var v = event.target.value;
        v = v.replace(" ","");

        var ok = "";
        if(v.length > 0 && this.isValidEmail(v) ) 
        {
           ok = "correct";
        }else
        if(v.length > 0)
        {
            ok ="incorrect";
        }


        this.setState({
            nn_email: v,
            nn_email_ok: ok,
        });
    }

    onCloseForm(event)
    {
       this.setState({
        view: "none"
       })
    }

    onSubmitForm()
    {
        var err = false;

        if(this.state.nn_nazwa.length < 1)        {   this.setState({ nn_nazwa_ok : "incorrect" });  err = true; }
        if(this.state.nn_nip.length < 9)          {   this.setState({ nn_nip_ok : "incorrect" });  err = true;}
        if(this.state.nn_ulica.length < 2)        {   this.setState({ nn_ulica_ok : "incorrect" });  err = true;}
        if(this.state.nn_nr_domu.length < 1)      {   this.setState({ nn_nr_domu_ok : "incorrect" });  err = true;}
        if(this.state.nn_kod_pocztowy.length < 6) {   this.setState({ nn_kod_pocztowy_ok : "incorrect" });  err = true;}
        if(this.state.nn_miasto.length < 2)       {   this.setState({ nn_miasto_ok : "incorrect" });  err = true; }

        if(this.state.nn_nazwa_ok         == "incorrect") { err = true; }
        if(this.state.nn_nip_ok           == "incorrect") { err = true; }
        if(this.state.nn_ulica_ok         == "incorrect") { err = true; }
        if(this.state.nn_nr_domu_ok       == "incorrect") { err = true; }
        if(this.state.nn_nr_mieszkania_ok == "incorrect") { err = true; }
        if(this.state.nn_kod_pocztowy_ok  == "incorrect") { err = true; }
        if(this.state.nn_miasto_ok        == "incorrect") { err = true; }
        if(this.state.nn_telefon_ok       == "incorrect") { err = true; }
        if(this.state.nn_email_ok         == "incorrect") { err = true; }

        if(err) return; 


        var data = {
            id: -1,
            nazwa: this.state.nn_nazwa,
            nip: this.state.nn_nip,
            regon: "",
            ulica: this.state.nn_ulica,
            nr_domu: this.state.nn_nr_domu,
            nr_mieszkania: this.state.nn_nr_mieszkania,
            kod_pocztowy: this.state.nn_kod_pocztowy,
            miasto: this.state.nn_miasto,
            telefon: this.state.nn_telefon,
            email: this.state.nn_email,
            kr_nazwa: this.state.nn_nazwa,
            kr_ulica: this.state.nn_ulica,
            kr_nr_domu: this.state.nn_nr_domu,
            kr_nr_mieszkania: this.state.nn_nr_mieszkania,
            kr_kod_pocztowy: this.state.nn_kod_pocztowy,
            kr_miasto: this.state.nn_miasto,
            osoba_reprezentujaca: "",
            informacje_dodatkowe: "",
            czlonek_bkp: this.state.czlonek_bkp,
        };


        fetch( ConfigX.restApi + '/dash,add_edit_firm', {
            method: 'POST', 
            body: JSON.stringify(data), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
         })
         .then(res => res.json() )
         .then(res => {

            console.log(JSON.stringify(res) );  
            
            if(res.status == 'OK' )
            {  
                this.onSelectedFirmAfterAdded(res.id, this.state.nn_nazwa );  
                this.getData();                                         
            }

         } );

    }

    render()
    {
        if(this.state.view == "list")
        {
                return (
                    <div className="firm_selector">                                              
                        <span className="link_firm" onClick={this.showList.bind(this)}> {this.state.title}</span>
                        <span className='window window_list'>
                            <div className='container'>
                                <div className='options'>                                  
                                    <div className='buttonForm' onClick={this.showNew.bind(this)}>(+) nowy podmiot</div>
                                    &nbsp;&nbsp;
                                    <div className='buttonForm buttonAlert' onClick={this.onCloseForm.bind(this)} title="Anuluj">Zamknij</div> 
                                </div>                                
                                <ul>
                                {
                                    this.state.rows.map( item => {
                                        return (
                                            <li key={item.id} id={item.id}  nazwa={item.nazwa} onClick={this.onSelectedFirm.bind(this) } >
                                                {item.nazwa},  {item.ulica}  {item.nr_domu}   {item.miasto}
                                            </li>
                                        )
                                    } )
                                }
                                </ul>
                            </div>
                        </span>
                    </div>
                )
        }


        if(this.state.view == "new")
        {
                return (
                    <div className="firm_selector">                                              
                        <span className="link_firm" onClick={this.showList.bind(this)}> {this.state.title}</span>
                        <span className='window window_form'>
                            <div className='container container_form'>
                                                           
                                 <div className='dashForm'>
                                    
                                    <div className='FormField '>
                                    <label>Członek BKP</label>
                                    <select defaultValue={this.state.czlonek_bkp} onChange={this.onChangeStatus.bind(this) } col="czlonek_bkp" >
                                        <option value="0">NIE</option>              
                                        <option value="1">TAK</option>                          
                                    </select>
                                    </div> 

                                     <div className='FormField'>
                                        <label>Nazwa firmy<span className='required'>*</span></label>
                                        <input type="text" value={this.state.nn_nazwa} className={"field300 " + this.state.nn_nazwa_ok}  onChange={this.onChangeNazwa.bind(this) } />
                                     </div>

                                     <div className='FormField'>
                                        <label>NIP<span className='required'>*</span></label>
                                        <input type="text" value={this.state.nn_nip} className={"field100 " + this.state.nn_nip_ok } onChange={this.onChangeNIP.bind(this) }  />
                                     </div>

                                     <div className='FormField'>
                                        <label>Ulica<span className='required'>*</span></label>
                                        <input type="text" value={this.state.nn_ulica} className={"field300 " +  this.state.nn_ulica_ok}  onChange={this.onChangeUlica.bind(this) }   />
                                     </div>

                                     <div className='FormField inlineField'>
                                        <label>Nr domu<span className='required'>*</span></label>
                                        <input type="text"  value={this.state.nn_nr_domu} className={"field100 " + this.state.nn_nr_domu_ok }  onChange={this.onChangeNrDomu.bind(this) }  />
                                     </div>

                                     <div className='FormField inlineField'>
                                        <label>Nr mieszkania</label>
                                        <input type="text" value={this.state.nn_nr_mieszkania} className={"field100 " + this.state.nn_nr_mieszkania_ok} onChange={this.onChangeNrN.bind(this) }   />
                                     </div>

                                     <br/>

                                     <div className='FormField inlineField'>
                                        <label>Kod pocztowy<span className='required'>*</span></label>
                                        <input type="text" value={this.state.nn_kod_pocztowy} className={"field50 " + this.state.nn_kod_pocztowy_ok} onChange={this.onChangeKod.bind(this) }   />
                                     </div>

                                     <div className='FormField inlineField'>
                                        <label>Miasto<span className='required'>*</span></label>
                                        <input type="text" value={this.state.nn_miasto} className={"field200 " + this.state.nn_miasto_ok }  onChange={this.onChangeMiasto.bind(this) }  />
                                     </div>

                                     <br/>

                                     <div className='FormField inlineField'>
                                        <label>Telefon</label>
                                        <input type="text" value={this.state.nn_telefon} className={"field100 " + this.state.nn_telefon_ok} onChange={this.onChangeTelefon.bind(this) }  />
                                     </div>

                                     <div className='FormField inlineField'>
                                        <label>E-mail</label>
                                        <input type="text" value={this.state.nn_email} className={"field200 " + this.state.nn_email_ok} onChange={this.onChangeEmail.bind(this) }  />
                                     </div>

                                     <div className='FormField FormButtons'>
                                        <div className='buttonForm' onClick={this.onSubmitForm.bind(this) }>Zapisz</div> 
                                        &nbsp;
                                        <div className='buttonForm' onClick={this.showList.bind(this)}>Anuluj</div> 
                                     </div>

                                 </div>

                            </div>
                        </span>
                    </div>
                )
        }


        if(this.state.view == "none")
        {
                return (
                    <div className="firm_selector">
                       <span className="link_firm" onClick={this.showList.bind(this)}> {this.state.title}</span>
                    </div>
                )
        }
    }
}

export default FirmSelector;