import React from "react";

class PresentList extends React.Component
{

 
 

 render()
 {

    

    const mystyle = {
        color: "black", 
        padding: "0px",
        fontFamily: "Arial",
        fontSize: "16px",
        fontWeight:"normal"    
      };

      const styleTab = {
        width: "100%",
        fontSize: "12px",
        border: "solid #000 !important",
        borderWidth: "1px 0 0 1px !important",
      }


      const styleTr = {        
        borderStyle: "solid",
        borderWidth: "1px",
      }

      const styleTd = {
        padding: "5px"
      }

      const styleThead = {
        backgroundColor: "#ddffdd",
        fontWeight: "400",
        color: "#fff",
        padding: "7px", 
        textAlign: "left",       
      }

      var data_szkolenia = this.props.data;


    return (
        <div  className="printView" style={ mystyle } >
             
             <table cellPadding="30">
              <tr>
                <td style={{padding: "20px"}}>

                  Nazwa szkolenia: {this.props.title} <br/><br/>
                  Data: {data_szkolenia}<br/>

                </td>
              </tr>
            </table>

            <br/>

            <table style={styleTab}>
                        <thead >
                          <tr>
                                <th >LP</th> 
                                <th >Nazwa firmy</th>
                                <th >Imię</th>
                                <th >Nazwisko </th>                                  
                                <th >Podpis</th>                                                                                                         
                           </tr>
                        </thead>
                        <tbody> 

                          {                            
                            this.props.rows.map( (item,index)   => {
 
                                  var nazwa_firmy = 'brak';
                                  if(item.dane_firmy.length > 0)
                                  {
                                    nazwa_firmy = item.dane_firmy[0].nazwa;
                                  }
                                    return (
                                        <tr key={index} >
                                            <td style={{width: "2%"}}> {index + 1 }</td>
                                            <td style={{width: "25%"}}>{nazwa_firmy}</td>
                                            <td style={{width: "24%"}}>{item.imie}</td>
                                            <td style={{width: "24%"}}>{item.nazwisko}</td>
                                            <td style={{width: "25%"}}></td>
                                        </tr>
                                    )

                            })
                          }

                        </tbody>    

            </table>

        </div>
    )
 }
}


export default PresentList