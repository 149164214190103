


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/badania/wnioski/step1.PNG';
import step2 from '../../helpdesk/images/badania/wnioski/step2.png';
import step3 from '../../helpdesk/images/badania/wnioski/step3.PNG';
import step4 from '../../helpdesk/images/badania/wnioski/step4.PNG';
import step5 from '../../helpdesk/images/badania/wnioski/step5.PNG';
import step6 from '../../helpdesk/images/badania/wnioski/step6.PNG';
import step7 from '../../helpdesk/images/badania/wnioski/step7.PNG';
import step8 from '../../helpdesk/images/badania/wnioski/step8.PNG';
import step9 from '../../helpdesk/images/badania/wnioski/step9.PNG';
import step10 from '../../helpdesk/images/badania/wnioski/step10.PNG';

 


class Wnioski extends React.Component
 {



  render(){

    return(
        <>
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>Wnioski na badania znajdują się na liście wniosków w szczegółach danego badania na dole strony.</p>
          <p>Jak widać nowy wniosek znajduje się na liście ze statusem "Nowy" i losowym numerem wygenerowanym przez system.</p>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>Administrator chcąć przetwarzać wniosek klika w jego pozycję, po czym pojawia się okno z wyborem statusu. Na zrzucie widzimy 3 statusy, aby wycenić wniosek wybieramy "Wycena usługi".</p>

          <img src={step2} alt=""/> 

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 3</h3>
          <br/>
          <p>Po wyborze wycena usługi pojawiają nam się pola z kwotą, kwotą vat i datą zakończenia, które musimy wypełnić.</p>
          <p>Dodatkowo możemy wpisać informacje dla wnioskodawcy, które wyświetlą mu się na liście wniosków w jego panelu. Po wypełnieniu klikamy "Zapisz".</p>       

          <img src={step3} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 4</h3>
          <br/> 
          <p>Po zapisie widać, że wniosek na liście został zaktualizowany o dane, które zostały zapisane a status zmienił się na "Wyceniono". W tym momencie firma dostała wiadomość e-mail o tym, że jego wniosek został wyceniony.</p>

          <img src={step4} alt=""/>

        </section>



        <section id="method" className="section border-no-sec">

          <h3>Krok 5</h3>
          <br/>
          <p>Użytkownik po zalogowaniu w menu Badania widzi swoje wnioski. Po lewej stronie wniosku są informacje ogólne a po prawej informacje od prowadzącego badanie i wycena.</p>
          <p>U góry wniosku widać komunikaty o aktualnym statusie, czyli wyceniony i wycena gotowa.</p>
          <p>Przyciski pozwalają na przyjęcie wyceny bądź odrzucenie jezeli kwota uznana będzie za wysoką.</p>

          <img src={step5} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 6</h3>
          <br/>
          <p>Jeżeli użytkownik / firma zdecyduje się na przyjęcie wyceny wybiera opcję "Przyjmij wycenę" po czym zmienia się status tego wniosku na "kwota przyjęta".</p>

          <img src={step6} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 7</h3>
          <br/>
          <p>Po przyjęciu ceny administrator na liście wniosków widzi, że status został zmieniony na "kwota zaakceptowana", czyli może zacząć dalej przetwarzać ten wniosek.</p>

          <img src={step7} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 8</h3>
          <br/>
          <p>Klikając ponownie w pozycję wniosku pojawiają się kolejne statusy, które mówią to co opisują. Jeżeli cena została przyjęta wybieramy status "Wnioskodawca przyjął wycenę".</p>

          <img src={step8} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 9</h3>
          <br/>
          <p>Jak widać po wyborze nowego statusu pojawił się komunikat, ze usługa została zrealizowana, więc wystarczy zapisać wniosek klikając na przycisk "Zapisz".</p>

          <img src={step9} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 10</h3>
          <br/>
          <p>Tutaj cofamy się do kroku 2 gdzie wybraliśmy opcję wycena usługi, natomiast tutaj widać moment  kiedy wybieramy opcję "wniosek odrzucony".</p>
          <p>Wpisujemy przycznę i zapisujemy. Na listach wniosków użytkownika i administratora pojawią się statusy, że wniosek został udrzucony.</p>

          <img src={step10} alt=""/>

        </section>        
        </>

    )
  }



}

export default Wnioski;