import React from 'react';
import TableRow from './TableRow';
import EmptyTab from '../../../components/EmptyTab';
import ConfigX from '../../../ConfigX'
 
class Table extends React.Component
 {
        constructor(props)
        {
            super(props)
        }


        onRowClick(id)
        {
            this.props.redirect("det", id);
        }

        duplicateTraining(id)
        {
         //   var dataPost = {
          //      id: id,           
         //   };

            this.props.redirect('list',id,'duplicate');
            
        /*    fetch( ConfigX.restApi + "/dash,duplicateTraining" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
    
                 if(json.status == "OK")
                 {
                    var id = json.param;
                    
                    //this.props.redirect("det",id,"duplicate");            
                    this.props.redirect("list",id,"duplicate");            
                 }                 
            });  */         
        }

        render()
        {
            var i = 0;

            if(this.props.rows.length == 0)
            {
                return (
                        <EmptyTab msg="Ta tabelka jest pusta lub brak wyników wyszukiwania"/>
                )
            }

            return (
                <div className='table_content'>
                 

                    <table className='table'>
                      <thead>
                        <tr>
                             <td></td>
                             <td>LP</td>                             
                             <td>Status</td>
                             <td>Podmiot prowadzący</td>
                             <td>Tytuł</td>
                             <td>Cel szkolenia</td>                           
                             <td>Ilość miejsc</td>
                             <td>Aktualnie zapisanych</td>
                             <td>Oczekujących</td>
                          
                        </tr>
                      </thead>
                      <tbody> 
                        {
                           
                            this.props.rows.map( item   => {
                                i++;
                                return (
                                    <TableRow key={i} num={i} row={item} onRowClick={this.onRowClick.bind(this)} duplicateTraining={this.duplicateTraining.bind(this)} />
                                )
                            } )
                        } 
                    </tbody>    


                    </table>

                </div>
            )
        }
 }

 export default Table;