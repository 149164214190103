import React from 'react';

import ConfigX from '../../ConfigX'
import ButtonConfirm from '../../components/ButtonConfirm';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FileUploader from '../../components/FileUploaderUser';
import AlertMsg from '../../components/AlertMsg';

import loading from '../../image/loading.gif'

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

 
class SignUpT extends React.Component
 {
        constructor(props)
        {
            super(props)
 
            this.state = {
                sk_szkolenia_id: this.props.sk_szkolenia_id,
                //sk_firmy_id: 0,
                action:  this.props.action,  //new edit none 
                row: this.props.row,
                nowSelected: this.props.nowSelected,                                         
                nn_imie: "",                
                nn_nazwisko: "",
                nn_email: "",
                nn_telefon: "",
                //nn_termin: "UW",
                nn_info_dodatkowe:'',
                dane: [],
                info_dodatkowe: '',
                terminy: this.props.terminy,       
                alertMSG: '',
                alertMSGTime: 0,  
                show: 0,
                show_cd: 0,
                msg_nip: 0,
                checkTyp: '',
                postanowienia: [],
                arrow: '🡺',
                typ: '',
                czlonek_bkp: false,
                czlonek_bkp_ok: "", 
                //isChecked: 0,
                rows: {
                    nazwa: '',
                    nip: '',
                    nr_domu:'',
                    nr_mieszkania:'',
                    kod_pocztowy:'',
                },

                nn_nazwa: "",
                nn_nip: "",
                nn_ulica: "",
                nn_nr_domu: "",
                nn_nr_mieszkania: "",
                nn_kod_pocztowy: "",
                nn_zgody: "",             

                loading: 0,

            };

   
        }

      
        getEmptyRow()
        {
   
            var data =  {
                id: "-1",
               // sk_firmy_id: "0",
                imie: '' ,
                nazwisko: '',
                email: '',
                telefon: "",
                //nn_termin: "empty",
                info_dodatkowe:"",
                terminy: [],
                alertMSG: '',
                alertMSGTime: 0,
                show: 0,
                show_cd: 0,
                msg_nip: 0,
                arrow: '🡺',
                //postanowienia: [],
                //isChecked: 0,

                // rows: {
                //     nazwa: '',
                //     nip: '',
                //     nr_domu:'',
                //     nr_mieszkania:'',
                //     kod_pocztowy:'',
                // },             

                nn_nazwa: "",
                nn_nip: "",
                nn_ulica: "",
                nn_nr_domu: "",
                nn_nr_mieszkania: "",
                nn_kod_pocztowy: "", 
                nn_zgody: "",                
              }

              return JSON.stringify(data);
        }

        componentDidMount()
        {             
            var dataPost = {};     
         
            fetch( ConfigX.restApi + "/user,get_data_user" , { 
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            }) 
            .then( res => res.json() )
            .then(json => {
               
                  this.setState({
                    dane: json.rows[0],
                    sk_firmy_id: json.rows[0].sk_firmy_id,
                    loading: 0,
                  })

            })



            var dataPost2 = {
                sk_szkolenia_id: this.state.sk_szkolenia_id
            };     
   
            fetch( ConfigX.restApi + "/user,get_data_uczestnicy" , { 
                method: 'POST', 
                body: JSON.stringify(dataPost2), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
  
                  this.setState({
                    info_dodatkowe: json.info
                  })
             
            })
            .catch((error) =>
            {
                console.log("błąd z parsowania",error);
            });


            if(this.state.postanowienia.length == 0)
            {
                this.get_postanowienia();
            }

    
        }
 
        get_postanowienia()
        {

            var dataPost3 = {
                typ_id: this.state.sk_szkolenia_id,     
                typ: "sk_szkolenia"
            };

            fetch( ConfigX.restApi + "/user,get_postanowienia" , {
                method: 'POST', 
                body: JSON.stringify(dataPost3), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
            
                if(json.status == "OK")
                {
                    this.setState({
                        postanowienia: json.rows
                    })
                                    
                }                 
            });  
                
        }


        componentDidUpdate()
        { 
           
            if(this.state.nowSelected != this.props.nowSelected)
            {
               // var row = this.props.row;
                var dane = this.state.dane;

                //if(this.props.action == "new") row =  JSON.parse(this.getEmptyRow()); //tworz nową instancje pustych danych..
              //  if(this.props.action == "new") dane =  JSON.parse(this.getEmptyRow()); //tworz nową instancje pustych danych..

                this.setState( {
                    sk_szkolenia_id: this.props.sk_szkolenia_id,
                   // sk_firmy_id: 0,
                    action: this.props.action,  //new edit none 
                    //row: row,
                    nowSelected: this.props.nowSelected,
                    nn_imie: "",
                    nn_nazwisko: "",
                    nn_email: "",
                    nn_telefon: "",  
                    //nn_termin: "UW", 
                    nn_info_dodatkowe: "",
                    dane: dane,
                    alertMSG: '',
                    alertMSGTime: 0,     
                    show: 0,
                    show_cd: 0,
                    msg_nip: 0,
                    arrow: '🡺',
                   // postanowienia: [],
                   // isChecked: 0,


                    // rows: {
                    //     nazwa: '',
                    //     nip: '',
                    //     nr_domu:'',
                    //     nr_mieszkania:'',
                    //     kod_pocztowy:'',
                    // },

                    nn_nazwa: "",
                    nn_nip: "",
                    nn_ulica: "",
                    nn_nr_domu: "",
                    nn_nr_mieszkania: "",
                    nn_kod_pocztowy: "",  
                    nn_zgody: "",                     
                });

            }
        }
 
        onChangeImie(event)
        {
            var v = event.target.value;
            var ok = "";

            if(v.length < 3) {ok = "incorrect"; } else ok = "correct";            

            //var tmp = this.state.row;
            var tmp = this.state.dane;
            tmp["imie"] = v; 

            this.setState({
                dane: tmp,
                nn_imie: ok
            });
        }

        onChangeNazwisko(event)
        {
            var v = event.target.value;
            var ok = "";
            
            if(v.length < 3) {ok = "incorrect"; } else ok = "correct";            

            //var tmp = this.state.row;
            var tmp = this.state.dane;
            tmp["nazwisko"] = v; 

            this.setState({
                dane: tmp,
                nn_nazwisko: ok
            });
        }

        onChangeEmail(event)
        {
            var v = event.target.value;
            var ok = "";
            
            var malpa = v.indexOf("@"); //sprawdzenie czy to faktycznie adres e-mail

            if(v.length < 7 || malpa == -1) {ok = "incorrect"; } else ok = "correct";            
            

            //var tmp = this.state.row;
            var tmp = this.state.dane;
            tmp["email"] = v; 

            this.setState({
                dane: tmp,
                nn_email: ok
            });
        }

        onChangeTelefon(event)
        {
            var v = event.target.value;

          //  v = v.replace(/[^0-9]/g, ""); //wywal litery...
            v = v.replace(" ","");

            var ok = "";
            
            if(v.length < 7) {ok = "incorrect"; } else ok = "correct";            

            //var tmp = this.state.row;
            var tmp = this.state.dane;
            tmp["telefon"] = v; 

            this.setState({
                dane: tmp,
                nn_telefon: ok
            });
        }

        onChangeWymagane(event)
        {
            var n_name =  event.target.name;
            var n_val  =  event.target.value;
            var n_id = event.target.id;

            var cc   =  event.target.checked;
            
            if(cc) n_val = "1";
            if(!cc) n_val = "0";

            var rowsx = this.state.postanowienia;   

            rowsx.forEach( (item,index) => {  
                if(rowsx[index]['t0_id'] == n_id)
                {
                    rowsx[index]['isChecked'] = n_val;                  
                }
            });

             this.setState({
                postanowienia: rowsx,              
             })

        } 
        
        // changeSort(event)
        // {
        //     var v   = event.target.value;
        //     var ok = "";     
    
        //     var rows = this.state.dane;
        //     rows["termin"] = v; 
    
        //     this.setState( {
        //         dane: rows, 
        //         nn_termin: ok
        //     } );
        // }

        isValidNip(nip) {
            if(typeof nip !== 'string')
                return false;
        
            nip = nip.replace(/[\ \-]/gi, '');
        
            let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
            let sum = 0;
            let controlNumber = parseInt(nip.substring(9, 10));
            let weightCount = weight.length;
            for (let i = 0; i < weightCount; i++) {
                sum += (parseInt(nip.substr(i, 1)) * weight[i]);
            }
            
            return sum % 11 === controlNumber;
        }

        onChangeNazwa(event)
        {
                var v   = event.target.value;
                var ok = "";
      
                if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }
    
                var rows = this.state.rows;
                rows["nazwa"] = v; 
    
                this.setState( {
                    rows: rows,
                    nn_nazwa: ok
                } );
        }  

        onChangeNIP(event)
        {
                var v   = event.target.value;
                var ok = "";

                v = v.replaceAll('-', '').trim();

                if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

                if(!this.isValidNip(v))
                { 
                    ok = "incorrect"; 
                }else { 
                    ok="correct"; 

                    var data = {
                        nip: v,
                        telefon: this.state.dane.telefon,
                        email: this.state.dane.email, 
                    
                    }

                    fetch( ConfigX.restApi + "/user,checkFirm" , {
                        method: 'POST', 
                        body: JSON.stringify(data), 
                        headers: {
                            'Content-Type': 'text/html',
                            'X-API-KEY': this.props.apiKey
                        }
                    })
                    .then( res => res.json() )
                    .then(json => {
      
                        if(json.status == 'OK')
                        {

                            this.setState({
                                sk_firmy_id: json.id,  
                                show_cd: 1, 
                                msg_nip: 0,
                                                                   
                            });  
                        }
                        if(json.status == 'EXIST')
                        {
                            var dt = new Date();
                            dt = dt.toISOString();                  

                            this.setState({
                                show_cd: 1,
                                msg_nip: 0,

                                alertMSG: json.param,
                                alertMSGTime: dt,                                                  
                                loading: 0                                
                            })
                       
                        }
                           
                    });                    

                }             

                var rows = this.state.rows;
                rows["nip"] = v; 

                this.setState( {
                    rows: rows,
                    nn_nip: ok
                } );
        }  
        
        onChangeUlica(event)
        {
                var v   = event.target.value;
                var ok = "";
      
                if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }
    
                var rows = this.state.rows;
                rows["ulica"] = v; 
    
                this.setState( {
                    rows: rows,
                    nn_ulica: ok
                } );
        }  

        onChangeNrDomu(event)
        {
                var v   = event.target.value;
                var ok = "";
      
                if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }
    
                var rows = this.state.rows;
                rows["nr_domu"] = v; 
    
                this.setState( {
                    rows: rows,
                    nn_nr_domu: ok
                } );
        } 

        onChangeNrMieszkania(event)
        {
                var v   = event.target.value;
                var ok = "";
    
               // if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

                var rows = this.state.rows;
                rows["nr_mieszkania"] = v; 

                this.setState( {
                    rows: rows,
                    nn_nr_mieszkania: 'correct'
                } );
        }  

        onChangeKod(event)
        {
            var ok = "";
        
        
            var v = event.target.value;        

          //  v = v.replace(/[^\d.-]/g, '');

        //    const arr = v.split("-");
        //    if(arr.length == 2 && arr[0].length == 2 && arr[1].length == 3 && /^\d+$/.test(arr[0])  && /^\d+$/.test(arr[1])   )
        //    {
                ok ="correct";
        //    }else ok ="incorrect";

        
            var rows = this.state.rows;
            rows["kod_pocztowy"] = v; 

            this.setState( {
                rows: rows,
                nn_kod_pocztowy: ok
            } );

        }

        onChangeMiasto(event)
        {
                var v   = event.target.value;
                var ok = "";
    
                if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

                var rows = this.state.rows;
                rows["miasto"] = v; 

                this.setState( {
                    rows: rows,
                    nn_miasto: ok
                } );
        } 

        onChangeInfo(event)
        {
            var v = event.target.value;
            var ok = " correct";                     

            //var tmp = this.state.row;
            var tmp = this.state.dane;
            tmp.informacje_dodatkowe = v;

            this.setState({
                dane: tmp,
                nn_info_dodatkowe: ok
            });
        }
        
        onChangeCzlonekBKP(event)
        {
         
            this.setState({
                czlonek_bkp: !this.state.czlonek_bkp,
                czlonek_bkp_ok: ""
             });
        }

        onClickDaneFaktura(event)
        {


            var typ = event.target.value;

            if(typ == 'firma')
            {
                    var dataPost3 = {
                        id: this.state.sk_firmy_id
                    };     

                    fetch( ConfigX.restApi + "/user,get_data_firm" , {
                        method: 'POST', 
                        body: JSON.stringify(dataPost3), 
                        headers: {
                            'Content-Type': 'text/html',
                            'X-API-KEY': this.props.apiKey
                        }
                    })
                    .then( res => res.json() )
                    .then(json => {
                    
                        this.setState({
                            rows: json.rows[0],                              
                        });      
                        
                    }); 

                    this.setState({
                        show: 1,   
                        typ: typ,            
                    })   

            }

            if(typ == 'os_pryw')
            {
                this.setState({
                    typ: typ,
                    show: 0,
                    show_cd: 0
                })
            }


        }

        onDialogOK(param)
        {
            //console.log("click ok dialog");
                this.setState({
                    alertMSG: '',
                    alertMSGTime: 0,
                });
        }

        onSubmitForm(event)
        {
           // event.preventDefault();


           var wait = setTimeout(
            () => {
               this.setState({ loading: 1 });  
            }
           ,500);
 

            var err = false;
            var iter = 0;
            var komunikat = '';

            this.state.postanowienia.map( (item,index) => {  

                if(item.t0_wymagane == 1 && item.isChecked == 0)
                {
                    iter++;
                }
            });
       


            if(this.state.dane.imie.length < 3 || this.state.nn_imie == "incorrect") {

                komunikat = 'Imię musi posiadać przynajmniej 3 znaki.';                        

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_imie: "incorrect",
                    loading: 0
                });                  
                clearTimeout(wait); 
            }

            if(this.state.dane.nazwisko.length < 3 || this.state.nn_nazwisko == "incorrect") {

                komunikat = 'Nazwisko musi posiadać przynajmniej 3 znaki.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_nazwisko: "incorrect",
                    loading: 0
                });                  
                clearTimeout(wait); 
            }

            if(this.state.dane.email.length < 7 || this.state.nn_email== "incorrect") {

                komunikat = 'Adres e-mail musi posiadać przynajmniej 7 znaków.';

                var malpa = this.state.dane.email.indexOf("@");

                if(malpa == -1)komunikat = 'Podaj prawidłowy adres e-mail.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_email: "incorrect",
                    loading: 0
                });     
                clearTimeout(wait);                        
            }

            if(this.state.dane.telefon.length < 1 || this.state.nn_telefon== "incorrect") {

                komunikat = 'Podaj prawidłowy numer telefonu.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_telefon: "incorrect",
                    loading: 0
                });                   
                clearTimeout(wait); 
            }

            // if(this.state.dane.termin == 'empty' || this.state.nn_termin== "incorrect") {

            //     this.setState( {nn_termin: "incorrect" } );
            //     err = true;   
            // }


            var typ = this.state.typ;

            if(typ == 'firma')
            {
                if(this.state.rows.nazwa.length < 3 || this.state.nn_nazwa== "incorrect") {

                    komunikat = 'Nazwa firmy musi mieć przynajmniej 3 znaki.';

                    var dt = new Date();
                    dt = dt.toISOString();
                    err = true;
        
                    this.setState({
                        alertMSG: komunikat,
                        alertMSGTime: dt,                
                        nn_nazwa: "incorrect",
                        loading: 0
                    });                        
                    clearTimeout(wait); 
                }

                if(this.state.rows.ulica.length < 3 || this.state.nn_ulica== "incorrect") {

                    komunikat = 'Ulica musi mieć przynajmniej 3 znaki.';

                    var dt = new Date();
                    dt = dt.toISOString();
                    err = true;
        
                    this.setState({
                        alertMSG: komunikat,
                        alertMSGTime: dt,                
                        nn_ulica: "incorrect",
                        loading: 0
                    });                      
                    clearTimeout(wait); 
                }

                if(this.state.rows.nr_domu.length < 1 || this.state.nn_nr_domu== "incorrect") {

                    komunikat = 'Podaj numer domu.';

                    var dt = new Date();
                    dt = dt.toISOString();
                    err = true;
        
                    this.setState({
                        alertMSG: komunikat,
                        alertMSGTime: dt,                
                        nn_nr_domu: "incorrect",
                        loading: 0
                    }); 
                    clearTimeout(wait); 
                }

                if(this.state.rows.kod_pocztowy.length < 3 || this.state.nn_kod_pocztowy== "incorrect") {

                    komunikat = 'Kod pocztowy musi posiadać przynajmniej 3 znaki.';

                    var dt = new Date();
                    dt = dt.toISOString();
                    err = true;
        
                    this.setState({
                        alertMSG: komunikat,
                        alertMSGTime: dt,                
                        nn_kod_pocztowy: "incorrect",
                        loading: 0
                    }); 
                    clearTimeout(wait); 
                }

                if(this.state.rows.miasto.length < 3 || this.state.nn_miasto== "incorrect") { 

                    komunikat = 'Miasto musi posiadać przynajmniej 3 znaki.';

                    var dt = new Date();
                    dt = dt.toISOString();
                    err = true;
        
                    this.setState({
                        alertMSG: komunikat,
                        alertMSGTime: dt,                
                        nn_miasto: "incorrect",
                        loading: 0
                    }); 
                    clearTimeout(wait); 
                }

            }

            if(iter > 0) //jeżeli są jakieś niezaznaczone zgody
            {
                komunikat = 'Musisz zaakceptować wszystkie wymagane zgody';
                
                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_zgody: "incorrect",
                    loading: 0
                });   
                clearTimeout(wait);                
              
            }               

         /*   if(this.state.dane.informacje_dodatkowe == 'empty' || this.state.nn_info_dodatkowe== "incorrect") {

                komunikat = 'Miasto musi posiadać przynajmniej 3 znaki.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_info_dodatkowe: "incorrect"
                }); 
                err = true;   
            }*/

            if(this.state.show == 1 && this.state.rows.nip.length < 5)
            {
                komunikat = 'NIP musi posiadać przynajmniej 5 znaków.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_nip: "incorrect",
                    loading: 0
                }); 
                clearTimeout(wait); 
            }

            
            if(this.state.czlonek_bkp) var BKP_mem = 1; else var BKP_mem = 0;


            if(err) return;
            


           // console.log("PPP " + JSON.stringify(this.state.dane) );

            //zapisz na serwerze...
 
            var dataPost = {
                sk_szkolenia_id: this.state.sk_szkolenia_id,
                //id: this.state.dane.id,
                imie: this.state.dane.imie,
                nazwisko: this.state.dane.nazwisko ,
                email: this.state.dane.email,
                telefon: this.state.dane.telefon,
                info_dodatkowe: this.state.dane.informacje_dodatkowe,

                nazwa: this.state.rows.nazwa,
                nip: this.state.rows.nip,
                ulica: this.state.rows.ulica,
                nr_domu: this.state.rows.nr_domu,
                nr_mieszkania: this.state.rows.nr_mieszkania,
                kod_pocztowy: this.state.rows.kod_pocztowy,
                miasto : this.state.rows.miasto, 

                postanowienia: this.state.postanowienia,
                termin: "UW",
                czlonek_bkp: BKP_mem,
                typ: this.state.typ,

            };     


            // if(this.state.dane.termin)
            // {
            //     dataPost['termin'] = this.state.dane.termin;              
            // }else{
            //     dataPost['termin'] = this.state.nn_termin;
            // }
 

            fetch( ConfigX.restApi + "/user,sign_up_training" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
                
                clearTimeout(wait);

                var param = json.param;

                 if(json.status == "OK")
                 {
                    this.setState({loading: 0})

                    this.state.dane.id = json.id;
                    this.props.onCloseEditor(json.id,"ok"); 
                 }else
                 {
                    if(param.szkolenie) 
                    {   var param_szkolenie = param.szkolenie; 
                        var msg = param_szkolenie.param;
                    }                 
                    this.setState({
                        alertMSG: msg,
                        alertMSGTime: 12, 
                        loading: 0,                    
                    });
                    return;
                 }
                
            })
            .catch((error) =>
            {
                console.log("błąd z parsowania",error);
            });
     
        }

        onConfirmDel(type, param, id)
        {
           //console.log(type +" "+ param+" "+" "+id);

           if(type == "yes")
           {
            this.onSubmitForm();
           }
        }

        onCheckDaneFirmy(event)
        {
            var n_name =  event.target.name;
            var n_val  =  event.target.value;
            var n_id   =  event.target.id;

            this.setState({
                checkTyp: n_val,
            });          

        }



        onConfirm()
        {
            this.onSubmitForm();
        }
        onDelete(event)
        {           

            var dataPost = {
                id: this.state.dane.id
            };

            fetch( ConfigX.restApi + "/dash,del_training_time" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
             
                 if(json.status == "OK")
                 {                  
                     this.props.onDeleteTime(json.id);
                     this.props.onCloseEditor(json.id,"xxx");
                 }else
                 {

                 }                 
            });


         
        }

        onCloseForm(event)
        {
           this.props.onCloseEditor(this.state.sk_szkolenia_id,"xxx");
        }

 
        render()
        {   

            if(this.state.loading == 1) 
            {

                return (


                <div className="_editor">
                    <div className='info_shadow'>
                        
                    <div className='modal'>
                        <div class='modal-dialog'>
        
                        <div class='modal-content'>
        
                            <div className='modal-body'>
                                <div className='loading'><img src={loading} /><div>Proszę czekać.</div></div>
                            </div>

                        </div>
                        </div>
                      </div>
                    </div>
                </div>
                     


               
                )
            }

            if(this.state.action == "none" )
            {
                return ( <span></span> )
            }

         /*   if(this.state.checkTyp == 'firma')
            {
                var showDaneFirmy = (
                    <>
                    <div className='FormField inlineField'>  
                        <a href="#" style={{color:"blue"}} onClick={this.onClickDaneFaktura.bind(this) }  title="Dodaj dane do faktury">Dane do faktury  <span>{ this.state.arrow }</span></a>                                                                                
                    </div>
                    <br/>
                    </>
                )
            }
            if(this.state.checkTyp == 'os_pryw')
            {
                var showDaneFirmy = "";
            }*/

            if(this.state.show == 1)
            {
                if(this.state.msg_nip == 1 || this.state.show_cd == 0) 
                {
                    var msgNIP = (
                        <span style={{color:"red"}}>Firma istnieje już w bazie danych, dane zostaną automatycznie przypisane</span>
                    )
                }
                if(this.state.msg_nip == 0 && this.state.show_cd == 0)
                {
                    var msgNIP = '';
                }
                
 
                var dane_firmy = '';

                // if( this.state.show == 1  && this.state.show_cd == 0)
                // {
                //     var dane_firmy = (
                //         <div className='FormField inlineField' style={{"marginLeft":20,"verticalAlign":"middle"}}>
                //             <div style={{"fontWeight": "bold","fontSize":12}}>Twoje dane firmowe - w celu zmiany danych skontaktuj się z administratorem</div>
                //             <div className='field300 font-small'>{this.state.rows.nazwa}</div>
                //             <div className='field300 font-small'> ul.{this.state.rows.ulica} {this.state.rows.nr_domu} / {this.state.rows.nr_mieszkania}</div>
                //             <div className='field300 font-small'>{this.state.rows.kod_pocztowy} {this.state.rows.miasto}</div>
                //         </div>
                //     )
                // }

                var showNIP = (
                <>

                    <div className='col-md-6'>
                      <label className='col-form-label'>Wpisz NIP<span className='required'>*</span> {msgNIP}</label>

                      <input type="text" className={"form-control " + this.state.nn_nip } defaultValue=""  col="nip" onChange={this.onChangeNIP.bind(this) } onPaste={this.onChangeNIP.bind(this)}/>

                    </div>
                  
                </>                    
                )
            }
            
            var showCD = '';

           /* if(this.state.show == 0 && this.state.show_cd == 0)
            {
                var showNIP = '';
                var showCD = '';
            } */         

            if(this.state.show_cd == 1 )
            {
                var showCD = (
                    < >

                    
                        <div className='col-md-12 mt-3 mb-3'>      
                            <input type="checkbox" name='czlonek_bkp' defaultChecked={this.state.czlonek_bkp}  onChange={this.onChangeCzlonekBKP.bind(this)} />                             
                            &nbsp; <span  className={"form-check-input " + this.state.czlonek_bkp_ok } >&nbsp;Członek BKP </span>
                        </div>

                        <div className='col-md-12 '>
                            <label className='col-form-label'>Nazwa firmy<span className='required'>*</span></label>
                            <input type="text" className={"form-control " + this.state.nn_nazwa } defaultValue={this.state.rows.nazwa}  col="Nazwa" onChange={this.onChangeNazwa.bind(this) } />
                        </div>
  
                  
                        <div className='col-md-7'>  
                            <label className='col-form-label'>Ulica<span className='required'>*</span></label>
                            <input type="text" className={"form-control " + this.state.nn_ulica } defaultValue={this.state.rows.ulica}  col="Ulica" onChange={this.onChangeUlica.bind(this) } />
                        </div>
                    
                        <div className='col-md-2'> 
                            <label className='col-form-label'>Nr domu<span className='required'>*</span></label>
                            <input type="text" className={"form-control " + this.state.nn_nr_domu } defaultValue={this.state.rows.nr_domu}  col="Nr domu" onChange={this.onChangeNrDomu.bind(this) } />
                        </div>

                        <div className='col-md-2'>
                            <label className='col-form-label'>Nr mieszkania</label>    
                            <input type="text" className={"form-control " + this.state.nn_nr_mieszkania } defaultValue={this.state.rows.nr_mieszkania}  col="Nr mieszkania" onChange={this.onChangeNrMieszkania.bind(this) } />
                        </div>
                 
                        <div className='col-md-6'>
                            <label className='col-form-label'>Miasto<span className='required'>*</span></label>
                            <input type="text" className={"form-control " + this.state.nn_miasto } defaultValue={this.state.rows.miasto}  col="Miasto" onChange={this.onChangeMiasto.bind(this) } />
                        </div>                 

                        <div className='col-md-3'> 
                            <label className='col-form-label'>Kod pocztowy<span className='required'>*</span></label>
                            <input type="text" className={"form-control " + this.state.nn_kod_pocztowy } defaultValue={this.state.rows.kod_pocztowy}  col="Kod pocztowy" onChange={this.onChangeKod.bind(this) } />
                        </div>


                       
                    </>                    
                )
            }
            
          /*  if(this.state.show_cd == 0 )
            {
                var showCD = '';
            }*/

           /* <div>
            <br/>
            <FileUploader typ="sk_szkolenia" typ_id={this.state.sk_szkolenia_id} apiKey={this.props.apiKey} onlyMyFiles='yes' showDate='off' showSize='off' />
            <br/>
             </div>*/


            return (


                <>  
 
                <div className="_editor">
                <div className='info_shadow'>
                    
                <div className='modal'>
                <div class='modal-dialog'>

                  <div class='modal-content'>
                     <div className='modal-header'>
                        <h3>Dane zapisującego</h3>  
                    </div>

                    <div className='modal-body'>
                        
                        <div className='container-fluid'>
                           
                                <div className='row g-3'>

                                       <div className='col-md-6'> 
                                            <label className='col-form-label '>Imię<span className='required'>*</span></label>
                                            <input type="text" defaultValue={ this.state.dane.imie } className={"form-control   " + this.state.nn_imie}   onSelect={this.onChangeImie.bind(this)} /> 
                                       </div>
                                    
                                       <div className='col-md-6'>
                                            <label className='col-form-label'>Nazwisko<span className='required'>*</span></label>
                                            <input type="text" defaultValue={ this.state.dane.nazwisko } className={"form-control  " + this.state.nn_nazwisko} onSelect={this.onChangeNazwisko.bind(this)} />
                                       </div>                        
    
                                        <div className='col-md-6'>
                                            <label className='col-form-label'>E-mail<span className='required'>*</span></label>
                                            <input type="text" defaultValue={ this.state.dane.email } className={"form-control " + this.state.nn_email} onSelect={this.onChangeEmail.bind(this)} />                                    
                                        </div>                                     

                                        <div className='col-md-6'>
                                            <label className='col-form-label'>Telefon<span className='required'>*</span></label>
                                            <input type="text" defaultValue={ this.state.dane.telefon } className={"form-control " + this.state.nn_telefon} onSelect={this.onChangeTelefon.bind(this)} />
                                        </div>

                              
                                    {/* <div className='FormField inlineField'>
                                        <label>Termin<span className='required'>*</span></label>
                                        <select onChange={this.changeSort.bind(this)}   className={ this.state.nn_termin}  defaultValue={this.state.nn_termin} >                                                                                             
                                            <option value="empty">---</option>
                                            <option value="UW">Uczestnik / Wszystkie lekcje</option>
                                        </select>
                                  
                                    </div> */}

                                    <div className='col-md-12'>  
                                       <label className='col-form-label'>Informacje dodatkowe</label>
                                       <input type="text" defaultValue={ this.state.info_dodatkowe } className={"form-control" + this.state.nn_info_dodatkowe} onSelect={this.onChangeInfo.bind(this)} />
                                    </div>

                                    <div className='col-md-6'>   
                                        <label class='col-form-label'>Zapisz się jako:</label>
                                        <select id="check_typ" className="form-select" name="checkTyp"  onChange={this.onClickDaneFaktura.bind(this) }> 
                                            <option value="os_pryw">Zapisz się jako osoba prywatna</option>
                                            <option value="firma">Zapisz się jako firma</option>
                                        </select>                                                                 
                                    </div>

                               
                                                               

                                    {showNIP}

                                    {showCD}

                                 
                                 


                                    {
                                        this.state.postanowienia.map( (item,index) => {     
                                            var req = '';
                                            if(item.t0_wymagane == '1')
                                            {
                                                 req = "*"; 
                                            }
                                           
                                            return(                                                
                                                <div className='col-md-12 mt-3 mb-3' key={index}>                                                                                                                             
                                                    <input type="checkbox"  id={item.t0_id} name={item.t0_uq + "_"+item.t0_id+"_"+item.t0_wymagane} value="1" className={" " + this.state.nn_zgody} defaultChecked={this.state.akc_reg} onChange={this.onChangeWymagane.bind(this)} />
                                                    <span className='required'>{req}</span>                                     
                                                    &nbsp;<span  className={this.state.akc_reg_ok } >&nbsp;{item.t0_tytul} </span>                                               
                                                </div>                                                                                                
                                            )
                                        } )
                                     }


                                </div>
                            </div>

                                <div className='modal-footer'>

                                        {
                                            this.state.action == "edit" ? <span><ButtonConfirm  onConfirm={this.onConfirmDel.bind(this) }  title="Usuń" query="Na pewno chcesz usunąć ten termin?"  />&nbsp;</span>: ''
                                        }      

                                        <div className='btn btn-secondary' onClick={this.onCloseForm.bind(this)} title="Anuluj">Anuluj</div>                                      
                                        <ButtonConfirm  onConfirm={this.onConfirmDel.bind(this) }  title="Zapisz się" button="1" class="btn btn-primary" query="Czy akceptujesz dane?" />
                                                                          

                                </div>

                                <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime }  msgParam="ok" onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>
           

                                                 
                    </div>
                 </div>
                </div>
                </div>
                </div>
            </div>

            </>

            )


        }
 }

 export default SignUpT;