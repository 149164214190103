
import React from 'react';
import ConfigX from '../ConfigX';

import Menu  from '../views/dashboard/Menu';
import PanelMain from '../views/dashboard/panel/Main'
import SzkoleniaMain from '../views/dashboard/szkolenia/Main'
import BadaniaMain from '../views/dashboard/badania/Main'
import BazaFirmMain from '../views/dashboard/baza_firm/Main'
import BazaUczestnikowMain from '../views/dashboard/baza_uczestnikow/Main'
import RozliczeniaMain from '../views/dashboard/rozliczenia/Main'
import RaportySzkolenia from '../views/dashboard/raporty/Main'
import RaportyBadania from '../views/dashboard/raporty_badan/Main'
import MojeKontoMain from '../views/dashboard/konto/Main'

import BarBottom from './BarBottom';


 class DashboardCointener extends React.Component
 {




  constructor(props)
  {
    super(props)
    
 

    this.state = {
        option: this.props.option,
        page: this.props.page,
        name: this.props.name ? this.props.name : "Panel",
        refresh: 0,
    }

  }


  componentDidMount()
  {
   // console.log("DashboardContainer mount ");          
  }

  componentDidUpdate()
  {
    //console.log("DashboardConatainer update " + this.props.option);      
    //console.log("DashboardConatainer update2 " + this.state.option);      

    
    /*if(this.props.option != this.state.option)
    {
      this.setState({
          option: this.props.option,       
      });
    }*/
  }

  selectMenu(option, name)
  {    
   // console.log(option, name);
     // console.log("Select Menu " + name); 
     //var option =  event.target.getAttribute("id");


      this.setState({
        option: option,
        name: name,
        refresh: this.state.refresh + 1,
      });

  }



  render()
  {
   
    //console.log("option2: ",this.state.option); 
    //console.log("name: ",this.state.name); 

   // console.log("DashboardContainer: " + this.state.option + " | " + this.props.option);

  

   //var content = <PanelMain apiKey={this.props.apiKey} refresh={this.state.refresh} />;
   var content = null;

   //console.log(this.state.refresh, " || " , content);


   if(this.state.option == 1) content = <PanelMain apiKey={this.props.apiKey}     refresh={this.state.refresh} />
   if(this.state.option == 2) content = <SzkoleniaMain apiKey={this.props.apiKey} refresh={this.state.refresh} />
   if(this.state.option == 3) content = <BadaniaMain apiKey={this.props.apiKey}   refresh={this.state.refresh} />
   if(this.state.option == 4) content = <BazaFirmMain apiKey={this.props.apiKey}  refresh={this.state.refresh} />
   if(this.state.option == 5) content = <BazaUczestnikowMain apiKey={this.props.apiKey} refresh={this.state.refresh} />
   if(this.state.option == 6) content = <RozliczeniaMain apiKey={this.props.apiKey} refresh={this.state.refresh} />
   if(this.state.option == 7) content = <RaportySzkolenia apiKey={this.props.apiKey} refresh={this.state.refresh} />
   if(this.state.option == 8) content = <MojeKontoMain apiKey={this.props.apiKey} refresh={this.state.refresh} />
   if(this.state.option == 9) content = <RaportyBadania apiKey={this.props.apiKey} refresh={this.state.refresh} />

   document.title = ConfigX.title  + " / Dashboard / "+ this.state.name;

   return (
       <>
       <div className='dashboard'>
           <div className='d_menu'>
                <Menu option={this.state.option} name={this.state.name} selectMenu={this.selectMenu.bind(this)} refresh={this.state.refresh} />                    
           </div>           
           <div className='d_menu_tab'>
                {content}
           </div>
        </div>
            <BarBottom page={this.state.page}/> 
        </>
   )


  }


}

export default DashboardCointener