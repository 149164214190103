

import React from "react";

class Onas extends React.Component
{
 
    constructor(props)
    {
        super(props)
    }
 

  render(){
      
       return (
            <div class="info-wrap bg-primary w-100 p-lg-5 p-4">
                <br></br>
                <h2 class="mb-4 mt-md-4 align-center">
                    <b>Bydgoski Klaster Przemysłowy Dolina Narzędziowa</b>
                    <h3>BKP - Potencjał i Misja</h3>
                </h2>
                <div class="dbox w-100 ">
                    <div class="text pl-3">
                        <p>Bydgoski Klaster Przemysłowy Dolina Narzędziowa istnieje od 2006 roku i skupia firmy z branży narzędziowej i przetwórstwa tworzyw polimerowych oraz szereg instytucji okołobiznesowych, w tym uczelnie, jednostki badawczo-rozwojowe, urzędy, stowarzyszenia przedsiębiorców i instytucje finansowe. Większość firm działających w branży przetwórczej i narzędziowej to małe i średnie przedsiębiorstwa.  Ich działalność charakteryzuje się dużą elastycznością. Działalność Klastra ma na celu integrację środowiska przetwórców i narzędziowców, reprezentację ich interesów na zewnątrz, jak również stworzenie sieci powiązań biznesowych ułatwiających funkcjonowanie firm, dostęp do kadr, rozwój technologiczny i zwiększenie innowacyjności produkcji. Przedsiębiorstwa zrzeszone w Klastrze oferują wysokiej jakości formy wtryskowe i inne narzędzia do przetwórstwa, jak również zajmują się produkcją wyrobów z tworzyw sztucznych dla szeregu branż, m.in. AGD, spożywczej, medycznej, farmaceutycznej, samochodowej, kosmetycznej i opakowaniowej.</p>
                    </div><br/>
                    <div class="text pl-3">
                        <p>Członkowie Bydgoskiego Klastra Przemysłowego Dolina Narzędziowa tworzą łańcuch dostawców – posiadają w ofercie zarówno tworzywa polimerowe jak i dodatki do tworzyw: barwniki, wypełniacze, modyfika­tory. Wytwarzają pełen asortyment narzędzi do przetwórstwa tworzyw: formy wtryskowe, formy rozdmuchowe, głowice wytłaczarskie, formy do przetwórstwa gumy, jak i narzędzia do obróbki metali: wykrojniki i tłoczniki, matryce kuzienne. Narzędzia wytwarzane są ze stali i często na bazie norma­liów, dostarczanych przez lokalnych dostawców. Przetwórcy produkują szeroką gamę wyrobów metodami wtryskiwania, wytłaczania i rozdmuchiwania.</p>
                    </div><br/>        
                    <div class="text pl-3">
                        <p>W październiku 2016 r. BKP uzyskał status Krajowego Klastra Kluczowego (KKK), który jest przyznawany w ramach konkursu organizowanego przez Ministerstwo Przedsiębiorczości i Technologii. Krajowy Klaster Kluczowy to „klaster o istotnym znaczeniu dla gospodarki kraju i wysokiej konkurencyjności międzynarodowej. Krajowe Klastry Kluczowe są identyfikowane na poziomie krajowym, w oparciu o analizę sześciu kluczowych obszarów funkcjonowania klastrów: zasoby ludzkie i organizacyjne, zasoby finansowe i infrastrukturalne, potencjał gospodarczy, tworzenie i transfer wiedzy, działanie na rzecz polityk publicznych oraz orientacja na klienta”. Status KKK nadawany jest na trzy lata i każdy klaster może ubiegać się o jego odnowienie. Bydgoski Klaster Przemysłowy pozytywnie przeszedł recertyfikację i odnowił status KKK do 2022 r.</p>
                    </div><br/> 
                    <div class="text pl-3">
                        <p>W kwietniu 2019 r. BKP uzyskał Silver Label Certificate tj. srebrną odznakę jakości działania Klastra przyznawaną przez Europejski Sekretariat Analiz Klastrowych (European Secretariat for Cluster Analysis). ESCA promuje doskonałość zarządzania klastrami poprzez prowadzenie benchmarkingu klastrów i przyznawania im odznak – brązowej, srebrnej i złotej. Uzyskanie Certyfikatu Silver Label poprzedzone było dwukrotnym otrzymaniem we wcześniejszych latach Certyfikatu Bronze Label.</p>
                    </div><br/>                                                     
                </div>
                <br/>
                <h2 class="mb-4 mt-md-4 align-center">
                    <b>WIZJA</b>
                </h2>
                <div class="dbox w-100 d-flex align-items-start">
                    <div class="text pl-3">
                        <p>Wizją BKP do 2025 r. jest dążenie do funkcjonowania Klastra jako skutecznej i dynamicznej organizacji, współtworzącej korzystne warunki dla rozwoju przedsiębiorstw, szczególnie z branży przetwórstwa tworzyw sztucznych i recyklingu oraz produkcji narzędzi przy udziale i zaangażowaniu współpracujących ze sobą przedsiębiorców, jednostek naukowo-badawczych, instytucji otoczenia biznesu i władz lokalnych.</p>
                    </div><br/> 
                </div>                
                <br/>
                <h2 class="mb-4 mt-md-4 align-center">
                    <b>MISJA</b>
                </h2>
                <div class="dbox w-100 d-flex align-items-start">
                    <div class="text pl-3">
                        <p>Tworzenie optymalnych warunków rozwoju, stymulowanie współpracy i integracja środowiska przetwórców i narzędziowców sektora tworzyw sztucznych w województwie kujawsko-pomorskim</p>
                    </div><br/> 
                </div> 
                <br/>
                <h2 class="mb-4 mt-md-4 align-center">
                    <b>CELE STRATEGICZNE DZIAŁALNOŚCI BYDGOSKIEGO KLASTRA PRZEMYSŁOWEGO DOLINY NARZĘDZIOWEJ</b>
                </h2>
                <div class="dbox w-100 ">
                    <div class="text pl-3">
                        <p>* Rozwój organizacji Bydgoskiego Klastra Przemysłowego Doliny Narzędziowej poprzez doskonalenie kadry Klastra, pozyskiwanie nowych członków, prowadzenie analiz rynku branży, lobbing na rzecz stwarzania lepszych warunków do rozwoju firm, popularyzacja branży tworzyw sztucznych jako istotnej w kontekście rozwoju regionu, poszukiwanie i pozyskiwanie środków zewnętrznych na realizację działań.</p>
                    </div><br/> 
                    <div class="text pl-3">
                        <p>* Propagowanie idei klastrowej, działania marketingowe takie jak: szkolenia, warsztaty i seminaria oraz organizacja spotkań biznesowych jako form propagujących ideę Klastrów i wiedzę na ten temat wśród obecnych i potencjalnych Członków, udział przedstawicieli Klastra w imprezach targowo-wystawienniczych, misjach gospodarczych, organizacja konferencji branżowych, publikacja materiałów promocyjno-reklamowych, prowadzenie strony internetowej Klastra.</p>
                    </div><br/> 
                    <div class="text pl-3">
                        <p>* Pozyskiwanie nowych kadr oraz podnoszenie kompetencji i wiedzy obecnych kadr członków BKP – promocja szkolnictwa zawodowego wśród gimnazjalistów, współpraca z lokalnymi szkołami zawodowymi, technikami i uczelniami, kierowanie zainteresowań zawodowych uczniów w stronę zawodów technicznych, współpraca z uczelniami regionu w zakresie kształcenia w konkretnych kierunkach, inicjowanie i organizacja seminariów, warsztatów, spotkań, dyskusji, szkoleń wewnątrz Klastra dotyczących tematyki branży i zarządzania w przedsiębiorstwie.</p>
                    </div><br/>       
                    <div class="text pl-3">
                        <p>* Wzrost innowacyjności firm BKP oraz stymulowanie nowych inwestycji poprzez działania na rzecz utworzenia specjalistycznego zaplecza badawczego na potrzeby Klastra, pozyskiwanie oraz wdrożenie wyników prac B+R w działalności gospodarczej członków Klastra, utworzenie platformy intranetowej przekazu informacji m.in. w obszarze transferu nowych technologii, organizacja spotkań dyskusyjnych i wizyt w firmach należących do Klastra w celu wypracowywania wspólnych przedsięwzięć rozwojowych członków Klastra.</p>
                    </div><br/>    
                    <div class="text pl-3">
                        <p>* Internacjonalizacja członków BKP na rynki zagraniczne oraz pogłębianie współpracy - opracowanie planu współpracy międzynarodowej oraz działania Klastra w kierunku pozyskiwania nowych grup odbiorców dla wytwarzanych produktów i oferowanych usług poza granicami kraju, organizacja wyjazdów na targi i misje o charakterze międzynarodowym w celu prezentacji oferty handlowej oraz nawiązania współpracy z partnerami handlowymi.</p>
                    </div><br/>                                                       
                </div> 

                <br/>
                <h2 class="mb-4 mt-md-4 align-center">
                    <b>WSPÓŁPRACA MIĘDZYNARODOWA</b>
                </h2>
                <div class="dbox w-100 d-flex align-items-start">
                    <div class="text pl-3">
                        <p>Bydgoski Klaster Przemysłowy Dolina Narzędziowa jest członkiem pięciu międzynarodowych sieci: Inmoldnet, MERGEurope, European Lightweight Cluster Alliance (ELCA), European Composites, Plastics and Polymer Processing Platform (ECP4) i DIH2. Współpracuje z wieloma klastrami, m.in. z Belgii, Bułgarii, Czech, Finlandii, Francji, Hiszpanii, Łotwy, Niemiec, Portugalii, Rumunii, Słowacji, Słowenii, Węgier oraz Włoch.
                            <br/>
                        Działania Klastra mające na celu rozwój współpracy z partnerami zagranicznymi obejmują wyjazdy na targi, misje gospodarcze, wizyty studyjne oraz projekty międzynarodowe. BKP jest partnerem następujących projektów ponadnarodowych: AMULET (H2020), Better Factory (H2020), ELCA (COSME Cluster Go International).</p>
                    </div><br/> 
                </div> 

                <br/>
                <h2 class="mb-4 mt-md-4 align-center">
                    <b>Dlaczego tutaj?</b>
                </h2>
                <div class="dbox w-100 d-flex align-items-start">
                    <div class="text pl-3">
                        <p>  
                            - ponad stuletnia tradycja<br/>
                            - koncentracja firm przetwórczych i narzędziowych w regionie<br/>
                            - wysoko wykwalifikowana kadra techniczna<br/>
                            - konkurencyjne koszty produkcji<br/>
                            - zaplecze naukowo-oświatowe<br/>
                            - środowisko przyjazne inwestorom<br/>
                            - ulgi podatkowe<br/>
                            - lotnisko<br/>
                            - sieć drogowa<br/>
                        </p>
                    </div><br/> 
                </div> 

                <br/>
                <h2 class="mb-4 mt-md-4 align-center">
                    <b>Dlaczego tutaj?</b>
                </h2>
                <div class="dbox w-100 d-flex align-items-start">
                    <div class="text pl-3">
                        <p>  
                            - ponad stuletnia tradycja<br/>
                            - koncentracja firm przetwórczych i narzędziowych w regionie<br/>
                            - wysoko wykwalifikowana kadra techniczna<br/>
                            - konkurencyjne koszty produkcji<br/>
                            - zaplecze naukowo-oświatowe<br/>
                            - środowisko przyjazne inwestorom<br/>
                            - ulgi podatkowe<br/>
                            - lotnisko<br/>
                            - sieć drogowa<br/>
                        </p>
                    </div><br/> 
                </div> 

                <br/>
                <h2 class="mb-4 mt-md-4 align-center">
                    <b>Jak przystąpić do BKP?</b>
                </h2>
                <div class="dbox w-100 d-flex align-items-start">
                    <div class="text pl-3">
                        <p>  
                        Członkiem Bydgoskiego Klastra Przemysłowego może zostać przedsiębiorstwo, instytucja lub inny podmiot posiadający osobowość prawną (członek wspierający), a także osoba fizyczna (członek zwyczajny).
                        <br/>
                        Składki członkowskie:<br/><br/>

                        a) członkowie wspierający oraz członkowie zwyczajni prowadzący działalność gospodarczą – wysokość składek zależy od wielkości zatrudnienia w przedsiębiorstwie/instytucji (wg stanu na dzień 1 stycznia każdego roku):
                        <br/><br/>
                            do 25 osób – 125 zł/miesięcznie,<br/>
                            od 26-100 osób – 250 zł/miesięcznie,<br/>
                            od 101-250 osób – 500 zł/miesięcznie,<br/>
                            powyżej 251 osób – 750 zł/miesięcznie.<br/><br/>

                        b) członkowie zwyczajni nieprowadzący działalności gospodarczej – 150 zł rocznie.<br/><br/>

                        Każdy nowo przyjęty członek uiszcza wpisowe w wysokości rocznej składki członkowskiej.<br/><br/>

                        Wraz z deklaracją należy przedłożyć przynajmniej dwie rekomendacje od obecnych członków BKP (wspierających lub zwyczajnych).<br/>
                        </p>
                    </div><br/> 
                </div>

            </div>
       );

  }
}

export default Onas