import React from 'react';
import Avatar from '../../../image/company_icon.png';
import Det from './Det';
import ConfigX from '../../../ConfigX';
import loading from '../../../image/loading.gif'

import AlertMsg from '../../../components/AlertMsg';
import ButtonConfirm from '../../../components/ButtonConfirm';
import { elementType } from 'prop-types';


class Main extends React.Component
 {
    constructor(props)
    {
        super(props)

        this.state = {
            loading: 1,
            view: "list",

            refresh: props.refresh,

            //new admin...
            new_adm: {
                imie: "",
                nazwisko: "",
                e_mail_kontakt: "",
                telefon_kontaktowy: ""
            },

            me:
            {
                new_password: "",
                new_password2: "",
                now_password: "",
            },

            notice:
            {
                powiadomienia_email: "",
                powiadomienie_nowe_ucz_szk: "",
                powiadomienie_nowe_ucz_bad: "",
                powiadomienie_nowe_szkolenie: "",
                powiadomienie_nowe_badanie: "",
            },

            //admins..
            adm_msg: "",
            adm_msg_t: 0,
            rows: [], 
            nn_powiadomienia: "",
        }
    
    }

    getData(  onFinish )
    {

        var wait = setTimeout(
             () => {
                this.setState({ loading: 1 });
             }
            ,500);

           
        var tmpNonRef = JSON.stringify( this.state );

        tmpNonRef = JSON.parse(tmpNonRef);

        var dataPost = {
           
          }

          fetch( ConfigX.restApi+ "/user,get_data_user" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        
             clearTimeout(wait);
 
             this.setState({
                rows: json.rows[0],
                loading: 0
             }); 
                         
             if(onFinish != null && onFinish != undefined ) onFinish();
           
        });
    }

    getAdm()
    {           
      
       this.setState({
            loading: 1
       });

       var dataPost = {
          
         }

         fetch( ConfigX.restApi+ "/dash,get_admin" , {
           method: 'POST', 
           body: JSON.stringify(dataPost), 
           headers: {
               'Content-Type': 'text/html',
               'X-API-KEY': this.props.apiKey
           }
       })
       .then( res => res.json() )
       .then(json => {                   
            this.setState({
               rows:  json,
               loading: 0
            });            
       });
    }


    getNotice()
    {           
      
    

       var dataPost = {
          
         }

         fetch( ConfigX.restApi+ "/dash,get_notice" , {
           method: 'POST', 
           body: JSON.stringify(dataPost), 
           headers: {
               'Content-Type': 'text/html',
               'X-API-KEY': this.props.apiKey
           }
       })
       .then( res => res.json() )
       .then(json => {                   
            this.setState({
               notice:  json               
            });            
       });
    }

    componentDidMount()
    {
        this.getAdm();
        this.getNotice();
    }

    componentDidUpdate()
    {
        if(this.state.refresh != this.props.refresh)
        {   
            this.setState( {refresh: this.props.refresh, view: "list" }  );
        }        
    }

    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    handleChangeNew(event) 
    {
        var n_name =  event.target.name;
        var n_val  =  event.target.value;


      
        if(n_name == "telefon_kontaktowy")
        {  
            var v = n_val;

            //v = v.replace("+48","");

          //  v = v.replace(/[^0-9]/g, ""); //wywal litery...
            v = v.replace(" ","");

         //   if(v.substr(0,3) != '+48')
         //   {
        //        v = "+48" + v;
       //     }         

            n_val = v; 
        }

            
        if(n_name == "imie" || n_name == "nazwisko")
        {  
            var v = n_val;        
            v = v.replace(/[0-9]/g, ""); //wywal cyfry...
            v = v.replace(" ",""); 
            n_val = v; 
        }


        var newx = this.state.new_adm;        
        newx[n_name] = n_val;       
        this.setState(
            {
                new_adm: newx,
                adm_msg: "",
            }
        );
    };  


    handleChangePass(event) 
    {
        var n_name =  event.target.name;
        var n_val  =  event.target.value;
 

        var rowsx = this.state.me;        
        rowsx[n_name] = n_val;       
        this.setState(
            {
                me: rowsx,
                adm_msg: "",
            }
        );
        
      //  console.log(JSON.stringify(rowsx) );
    }

  

 
    handleChangeEdit(event) 
    {
        var n_name =  event.target.name;
        var n_val  =  event.target.value;
        var n_ix   =  event.target.getAttribute("atr-ix");


        if(n_name == "telefon_kontaktowy")
        {  
            var v = n_val;

          //  v = v.replace("+48","");

         //   v = v.replace(/[^0-9]/g, ""); //wywal litery...
            v = v.replace(" ","");

        //    if(v.substr(0,3) != '+48')
        //    {
       //         v = "+48" + v;
      //      }         

            n_val = v; 
        }

            
        if(n_name == "imie" || n_name == "nazwisko")
        {  
            var v = n_val;        
            v = v.replace(/[0-9]/g, ""); //wywal cyfry...
            v = v.replace(" ",""); 
            n_val = v; 
        }

        if(n_name == "is_active"){
            var cc   =  event.target.checked;
            if(cc) n_val = "1";
            if(!cc) n_val = "0";
        }


        var rowsx = this.state.rows;        
        rowsx[n_ix][n_name] = n_val;       
        this.setState(
            {
                rows: rowsx,
                adm_msg: "",
            }
        );

       
        if(n_name == "is_active" )
        {   
            this.goSaveRecord( rowsx[n_ix] );          
        }

    }


    trySave(event) 
    {
        var n_name =  event.target.name;
        var n_val  =  event.target.value;
        var n_ix   =  event.target.getAttribute("atr-ix");

        var rowToUpdate = this.state.rows[n_ix];
        this.goSaveRecord(rowToUpdate);
          
    }

    
    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

    goSaveRecord(row)
    {        
       // console.log("Try Save: "  +  JSON.stringify(row) ) ;  

        var dt = new Date();
        dt = dt.toISOString();


        if(row.e_mail_kontakt.length < 3)
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj adres e-mail.",
                adm_msg_t: dt
            });
            return;
        }

        if(row.imie.length < 2)
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj imię",
                adm_msg_t: dt
            });
            return;
        }   
        
        if(row.nazwisko.length < 2)
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj nazwisko",
                adm_msg_t: dt
            });
            return;
        }              

      //  console.log("E " + this.isValidEmail( row.e_mail_kontakt )  );
        if( !this.isValidEmail( row.e_mail_kontakt ) )
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj prawidłowy adres e-mail.",
                adm_msg_t: dt
            });
            return;
        }        




        this.setState({
            loading: 1
        });

 

            fetch( ConfigX.restApi+ "/dash,update_admin" , {
                method: 'POST', 
                body: JSON.stringify(row), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {                   
                
                  if(json.status == 'OK')
                  {      
                    this.setState({
                        loading: 0,
                        adm_msg: "Informacje zostały zapisane",
                        adm_msg_t: dt
                    });
                  }

            });


    }


    

    onConfirmDel(type, param, param_id)
    {
       console.log(type + param + param_id);

       if(type != "yes")  return;

       
       var row = this.state.rows[param_id];
       
       
       this.setState({
                loading: 1
            });


        fetch( ConfigX.restApi+ "/dash,remove_admin" , {
            method: 'POST', 
            body: JSON.stringify(row), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {                   
            
              if(json.status == 'OK')
              {      
                 this.getAdm();
              }
        });


    }



    sendPass(event) 
    {        
        var n_ix   =  event.target.getAttribute("atr-ix");
        var row = this.state.rows[n_ix];    
             
        var dt = new Date();
        dt = dt.toISOString();

            this.setState({
                loading: 1
            });


        fetch( ConfigX.restApi+ "/dash,send_pass" , {
            method: 'POST', 
            body: JSON.stringify(row), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {                   
            
            if(json.status == 'OK')
            {      
                this.setState({
                    loading: 0,
                    adm_msg: "Hasło zostało wysłane na adres e-mail",
                    adm_msg_t: dt
                });
            }
        });
    }


    goSaveNew()
    {        
        console.log("Try Save: "  +  JSON.stringify(this.state.new_adm) ) ;  

        var dt = new Date();
        dt = dt.toISOString();


        if(this.state.new_adm.e_mail_kontakt.length < 3)
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj adres e-mail.",
                adm_msg_t: dt
            });
            return;
        }

        if(this.state.new_adm.imie.length < 2)
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj imię",
                adm_msg_t: dt
            });
            return;
        }   
        
        if(this.state.new_adm.nazwisko.length < 2)
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj nazwisko",
                adm_msg_t: dt
            });
            return;
        }              

      
        if( !this.isValidEmail( this.state.new_adm.e_mail_kontakt ) )
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj prawidłowy adres e-mail.",
                adm_msg_t: dt
            });
            return;
        }        

        if(   this.state.new_adm.telefon_kontaktowy.length < 9 )
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj prawidłowy numer telefonu.",
                adm_msg_t: dt
            });
            return;
        }  



        this.setState({
            loading: 1
        });

 

            fetch( ConfigX.restApi+ "/dash,new_admin" , {
                method: 'POST', 
                body: JSON.stringify(this.state.new_adm), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {                   
                
                  if(json.status == 'OK')
                  {     
                       this.setState({
                        new_adm: {
                            imie: "",
                            nazwisko: "",
                            e_mail_kontakt: "",
                            telefon_kontaktowy: ""
                        }
                       });    

                       this.getAdm();
                  }

            });


    }




    savePassword(ev)
    {        
    //    console.log("Try Save: "  +  JSON.stringify(this.state.me) ) ;  

        var dt = new Date();
        dt = dt.toISOString();


       
        
        if(this.state.me.new_password.length < 8)
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj hasło, min. 8 znaków",
                adm_msg_t: dt
            });
            return;
        }  
        
        if(this.state.me.new_password2.length < 8)
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj hasło, min. 8 znaków",
                adm_msg_t: dt
            });
            return;
        }  

        if(this.state.me.new_password  != this.state.me.new_password2  )
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podane hasła się różnią.",
                adm_msg_t: dt
            });
            return;
        }  
 

        if(this.state.me.now_password.length < 5)
        {
            this.setState({
                loading: 0,
                adm_msg: "Błąd, podaj aktualne hasło",
                adm_msg_t: dt
            });
            return;
        }  



        this.setState({
            loading: 1
        });

 

            fetch( ConfigX.restApi+ "/dash,save_pass" , {
                method: 'POST', 
                body: JSON.stringify(this.state.me), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {                   
                
                  if(json.status == 'OK')
                  {      
                    this.setState({
                        loading: 0,
                        adm_msg: "Informacje zostały zapisane",
                        adm_msg_t: dt
                    });
                  }else
                  {
                    this.setState({
                        loading: 0,
                        adm_msg: "Błąd, nie można zpisać danych. Upewnij się, że aktualne hasło jest prawidłowe.",
                        adm_msg_t: dt
                    });
                  }

            });


    }




    handleChangeNotice(event) 
    {
        var n_name =  event.target.name;
        var n_val  =  event.target.value;
   
        if(n_name == 'powiadomienia_email')
        {
            if(n_val.length > 5)
            {
                this.setState({nn_powiadomienia: "correct"})
            }else{
                this.setState({nn_powiadomienia: "incorrect"})
            }
        }

        if(n_name == "powiadomienie_nowe_ucz_szk" || n_name == "powiadomienie_nowe_ucz_bad" || n_name == "powiadomienie_nowe_szkolenie"  || n_name == "powiadomienie_nowe_badanie"  ){
            var cc   =  event.target.checked;
            if(cc) n_val = "1";
            if(!cc) n_val = "0";
        }

        var rowsx = this.state.notice;        
        rowsx[n_name] = n_val;       
        this.setState(
            {
                notice: rowsx,
                adm_msg: "",
            }
        );     
    }



    saveNotice()
    {

        var dt = new Date();
        dt = dt.toISOString();

        var n1 = this.state.notice.powiadomienie_nowe_badanie;
        var n2 = this.state.notice.powiadomienie_nowe_szkolenie;
        var n3 = this.state.notice.powiadomienie_nowe_ucz_bad;
        var n4 = this.state.notice.powiadomienie_nowe_ucz_szk;

        if(this.state.notice.powiadomienia_email.length == 0 && (n1 == 1 || n2 == 1 || n3 == 1 || n4 == 1))
        {
            this.setState({
                adm_msg: "Wpisz adres e-mail do powiadomień.",
                adm_msg_t: 200,
                nn_powiadomienia: "incorrect"
            })
            return; 
        }

        fetch( ConfigX.restApi+ "/dash,save_notice" , {
            method: 'POST',  
            body: JSON.stringify(this.state.notice), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {                   
            
              if(json.status == 'OK')
              {     
                this.setState({
                    loading: 0,
                    adm_msg: "Informacje zostały zapisane",
                    adm_msg_t: dt
                });
              }

        });
    }




    render()
    {
        var cc1 = this.state.notice.powiadomienie_nowe_ucz_szk   === "1" ? true : false
        var cc2 = this.state.notice.powiadomienie_nowe_ucz_bad   === "1" ? true : false
        var cc3 = this.state.notice.powiadomienie_nowe_szkolenie === "1" ? true : false
        var cc4 = this.state.notice.powiadomienie_nowe_badanie   === "1" ? true : false

       // console.log("cc3" , cc3);
       // console.log("cc4" , cc4); 
   
        return (
            <div className='main-content'>                
                  <h3>Moje konto / Autoryzacja </h3>     
                  <div className='content'>                 
                    
                     <div>
                     🔑 <input type="password" className="field200" name="new_password" placeholder="Nowe hasło:"  value={this.state.me.new_password} onChange={this.handleChangePass.bind(this) } />
                      &nbsp;
                       <input type="password"  className="field200" name="new_password2" placeholder="Powtórz nowe hasło:"  value={this.state.me.new_password2} onChange={this.handleChangePass.bind(this) } />
                      &nbsp;
                       <input type="password"  className="field200" name="now_password"  placeholder="Aktualne hasło:"  value={this.state.me.now_password} onChange={this.handleChangePass.bind(this) } />                       
                      &nbsp;&nbsp;
                      <span className='buttonForm' onClick={this.savePassword.bind(this)}>zapisz nowe hasło</span> 
                     </div>


                  </div>          

                  <br/>

                  <h3>✉ Ustawienia powiadomień  </h3>     
                  <div className='content'>                 
                     
                     <div>
                         <div className='formSimplyField'>
                            <input type="text"  className={"field500 " + this.state.nn_powiadomienia} name="powiadomienia_email" placeholder="E-mail dla powiadomień:"  defaultValue={this.state.notice.powiadomienia_email} onChange={this.handleChangeNotice.bind(this) } onKeyUp={this.handleChangeNotice.bind(this)} />  
                         </div>                   
                                                          
                         <div className='formSimplyField'>
                             <input type="checkbox" name="powiadomienie_nowe_ucz_szk" value="1"  checked={cc1} onChange={this.handleChangeNotice.bind(this) } /> Wysyłaj powiadomienia o nowym uczestniku szkolenia
                         </div>

                         <div className='formSimplyField'>
                             <input type="checkbox" name="powiadomienie_nowe_ucz_bad" value="1"   checked={cc2} onChange={this.handleChangeNotice.bind(this) } /> Wysyłaj powiadomienia o nowym uczestniku badania
                         </div>

                         <div className='formSimplyField'>
                             <input type="checkbox" name="powiadomienie_nowe_szkolenie" value="1"  checked={cc3} onChange={this.handleChangeNotice.bind(this) } /> Wysyłaj powiadomienia o nowym szkoleniu
                         </div>                         

                         <div className='formSimplyField'>                          
                             <input type="checkbox" name="powiadomienie_nowe_badanie" value="1"   checked={cc4} onChange={this.handleChangeNotice.bind(this) } /> Wysyłaj powiadomienia o nowym badaniu
                         </div>  
                         
                    
                        <br/>
                        <span className='buttonForm' onClick={this.saveNotice.bind(this)}>zapisz powiadomienia</span> 
                     </div>


                  </div> 

                  <br/>


                  <h3>🧑‍💻 Administratorzy systemu ({this.state.rows.length})  </h3>               

                  <AlertMsg msg={this.state.adm_msg }  msgTime={this.state.adm_msg_t } title="Powiadomienie"/>
                  

                  <div className='table_content'>                 
                            <table className='table'>
                            <thead>
                                <tr>
                                    <td>LP</td>
                                    <td>Imię</td>                                                                                                                        
                                    <td>Nazwisko</td>                                                                                                                        
                                    <td>E-mail</td>                                                                                                                        
                                    <td>Telefon</td>                                                                                                                        
                                    <td>Aktywny?</td>                                                                                                                                                                                                                                                                                   
                                    <td></td>                                                                                                                        
                                    <td> </td>                                                                                                                        
                                </tr>
                            </thead>
                            <tbody> 

                                 <tr>
                                    <td> ⏺ </td>
                                    <td> <input type="text" className='field200' name="imie" placeholder="Imię:"  value={this.state.new_adm.imie} onChange={this.handleChangeNew.bind(this) } /> </td>                                                                                                                        
                                    <td> <input type="text" className='field200' name="nazwisko" placeholder="Nazwisko:" value={this.state.new_adm.nazwisko}   onChange={this.handleChangeNew.bind(this) } /></td>                                                                                                                        
                                    <td> <input type="text" className='field200' name="e_mail_kontakt" placeholder="E-mail:"  value={this.state.new_adm.e_mail_kontakt}  onChange={this.handleChangeNew.bind(this) } /> </td>                                                                                                                        
                                    <td> <input type="text" className='field200' name="telefon_kontaktowy" placeholder="Telefon:" value={this.state.new_adm.telefon_kontaktowy}   onChange={this.handleChangeNew.bind(this) }/> </td>                                                                                                                        
                                    <td>  </td>                                                                                                                        
                                    <td>  </td>                                                                                                                        
                                    <td> <span className='buttonForm' onClick={this.goSaveNew.bind(this)}>dodaj</span> </td>                                                                                                                        
                                </tr>

                               

                                {
                                 this.state.rows.map( (row, ix) => {

                                        var isActive = false;
                                        if(row.is_active == "1") { isActive=true;} 
                                 

                                        return (
                                            <tr key={ix}>
                                                <td> ⏺ </td>
                                                <td> <input type="text" name="imie" className='field200' placeholder="Imię:" value={row.imie}  atr-ix={ix} onBlur={this.trySave.bind(this)}   onChange={this.handleChangeEdit.bind(this) }  /> </td>                                                                                                                        
                                                <td> <input type="text" name="nazwisko" className='field200' placeholder="Nazwisko:" value={row.nazwisko}  atr-ix={ix}  onBlur={this.trySave.bind(this)}    onChange={this.handleChangeEdit.bind(this) }  /></td>                                                                                                                        
                                                <td> <input type="text" name="e_mail_kontakt" className='field200' placeholder="E-mail:" value={row.e_mail_kontakt}  atr-ix={ix}  onBlur={this.trySave.bind(this)}   onChange={this.handleChangeEdit.bind(this) }  /> </td>                                                                                                                        
                                                <td> <input type="text" name="telefon_kontaktowy" className='field200' placeholder="Telefon:" atr-ix={ix}   value={row.telefon_kontaktowy}   onBlur={this.trySave.bind(this)}    onChange={this.handleChangeEdit.bind(this) }  /> </td>                                                                                                                        
                                                <td className='tdcenter'> <input type="checkbox" name="is_active"  value="1" atr-ix={ix}  defaultChecked={isActive}    onChange={this.handleChangeEdit.bind(this) }  /> </td>                                                                                                                        
                                                                                                                                                                      
                                                <td> <span  atr-ix={ix}  className='buttonForm' onClick={this.sendPass.bind(this) }>wyślij hasło</span> </td>                                                                                                                        
                                                <td>                                                   
                                                     <ButtonConfirm   onConfirm={this.onConfirmDel.bind(this) } param="index" param_id={ix}  title="usuń*" query={"Na pewno chcesz usunąć konto " + row.imie + " " + row.nazwisko + " ?"}  />
                                                </td>                                                                                                                        
                                            </tr>
                                        )
                                 } )
                                }

                            </tbody>
                          </table>
                  </div>
            </div>
        )



        return (

                <div className='d_menu_tab'>

                    <div className="container rounded bg-white">
                            <h2>Konta administratorów</h2>
                            df sdfs df
                    </div>

                    <br/>
                    <br/>

                      <div>
                        dfs df
                      </div>

                    <div className="container rounded bg-white">
                        <div className="row">

                            <div className="col-md-3 border-right">
                                <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                                    <img className="rounded-circle mt-5" width="150px" alt='awatar' src={Avatar} />
                                    <span className="font-weight-bold">{this.state.rows.imie} {this.state.rows.nazwisko}</span>
                                    <span className="text-black-50">{this.state.rows.email}</span>
                                </div>
                            </div>

                            <div className="col-md-5 border-right">
                                <div className="p-3 py-5">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h4 className="text-right">Ustawienia użytkownika</h4>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6"><label className="labels">Imię</label><input type="text" className="form-control" defaultValue={this.state.rows.imie} disabled='disabled' /></div>
                                        <div className="col-md-6"><label className="labels">Nazwisko</label><input type="text" className="form-control" defaultValue={this.state.rows.nazwisko} disabled='disabled' /></div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12"><label className="labels">Nazwa firmy</label><input type="text" className="form-control" defaultValue={this.state.rows.nazwa_firmy} disabled='disabled' /></div>
                                    </div>
                                    <Det px_user_id={this.state.rows.id}  redirect={this.redirect.bind(this) } apiKey={this.props.apiKey}/>    
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>

        )
    }
 }


 export default Main