


import React from 'react';
import { Link  } from 'react-router-dom';
import './styles.css';
import './bootstrap.css';

import reg1 from '../helpdesk/images/register/reg1.PNG';
import reg2 from '../helpdesk/images/register/reg2.PNG';
import reg3 from '../helpdesk/images/register/reg3.PNG';
import reg4 from '../helpdesk/images/register/reg4.PNG';
import reg5 from '../helpdesk/images/register/reg5.PNG';
import reg6 from '../helpdesk/images/register/reg6.PNG';


class Register extends React.Component
 {



  render(){

    return(
        <>
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>Aby zarejestrować się do systemu klikamy w nagłówku link "Zaloguj się".</p>

          <img src={reg1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>W kolejnym kroku wybieramy opcję "Jestem nowy".</p>

          <img src={reg2} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 3</h3>
          <br/>
          <p>Wypełniamy i zaznaczamy wszystkie pola z czerwoną gwiazdką. </p>
          <div className="imp-note"><p>Należy pamiętać, że hasło ze względów bezpieczeństwa musi posiadać małe, duże litery i cyfry.</p></div>
          <p>W zgodach do zaznaczenia możemy także podejrzeć regulamin i dokument RODO. Jeżeli wszystko wypełnimy i zaznaczymy poprawnie, przechodzimy do kolejnego kroku klikając przycisk "Dalej".</p>


          <img src={reg3} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 4</h3>
          <br/>
          <p>W tym kroku na podany adres e-mail wysyłany jest kod autoryzacyjny w celu aktywacji konta.</p>

          <img src={reg4} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 5</h3>
          <br/>
          <p>Z wiadomości e-mail przepisujemy kod autoryzacyjny i podajemy go w polu z kroku poprzedniego. </p>

          <img src={reg5} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 6</h3>
          <br/>
          <p>Po pomyślnym przejściu wszystkich kroków, system automatycznie loguje i przekierowuje nas do systemu. </p>

          <img src={reg6} alt=""/>

        </section>

        </>

    )
  }



}

export default Register;