import React from 'react';

import checkPNG from '../../../image/check.png'
import nonePNG from '../../../image/none.png'
import infoPNG from '../../../image/info.png'
import { Link } from 'react-router-dom';
import ConfigX from '../../../ConfigX';
 
class TableRow extends React.Component
 {
        constructor(props)
        {
            super(props)
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onRowClick(id);
        }

        render()
        {
  
            return (
                 
                        <tr className='rowLink' >
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} >  {this.props.num} </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className='text_center'> {this.props.row.do_px_group_id}  </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)}> {this.props.row.tytul}  </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)}>  {this.props.row.tresc} </td>                           
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className='text_center'> {this.props.row.dataczas}  </td>   
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className='text_center'> Odpowiedz </td>                    
                        </tr>                 
            )
        }
 }

 export default TableRow;