import React from 'react';
import Avatar from '../../../image/user.png';
//import Det from './Det';
import ConfigX from '../../../ConfigX';
import loading from '../../../image/loading.gif'
import Calendar from '../../../components/Calendar';

class Main extends React.Component
 {
    constructor(props)
    {
        super(props)

        this.state = {

            refresh: props.refresh,

            loading: 1,
            view: "list",
            rows: [], 
            result: [],            
        }
    
    }

    getData(  onFinish )
    {

        var wait = setTimeout(
             () => {
                this.setState({ loading: 1 });
             }
            ,500);

           
        var tmpNonRef = JSON.stringify( this.state );

        tmpNonRef = JSON.parse(tmpNonRef);

        var dataPost = {
           
          }
 
          fetch( ConfigX.restApi+ "/dash,get_statistics" , { 
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        
             clearTimeout(wait);
 
 //console.log(JSON.stringify(json));  
             this.setState({
                rows: json,
                loading: 0
             }); 
                         
             if(onFinish != null && onFinish != undefined ) onFinish();
           
        })
        .catch((error=>{
            console.log("Błąd Json "+error);
        }
            
            ));
    }

    componentDidMount()
    {
        this.getData(null);                     
    }

    componentDidUpdate()
    {
        if(this.state.refresh != this.props.refresh)
        {   
            this.setState( {refresh: this.props.refresh, view: "list" }  );
        }
    }

    redirect(action, id, param)
    {

        if(action == "list")
        {
            this.getData(null);
        }
     
        
        this.setState(
            {
                view: action,
                selectedId: id,
            }
           );
    }



    render()
    {
   
        return (
        <div className='main-content'>
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="sin_el">
                        <div className="q_act">
                           
                                    <div className='q_act_wrap'>

                                         <div className='sin_q_act'>
                                            <h4>Liczba aktywnych szkoleń</h4>
                                            <h3>#<span className='counter'>{this.state.rows.ilosc_szkolen}</span></h3>                                        
                                         </div>

                                         <div className='sin_q_act'>
                                            <h4>Liczba aktywnych badań</h4>
                                            <h3>#<span className='counter'>{this.state.rows.ilosc_badan}</span></h3>                                          
                                         </div>
                                         
                                         <div className='sin_q_act'>
                                            <h4>Liczba uczestników szkoleń</h4>
                                            <h3>#<span className='counter'>{this.state.rows.ilosc_uczestnikow}</span></h3>                                           
                                         </div>

                                         <div className='sin_q_act'>
                                            <h4>Liczba wniosków badań</h4>
                                            <h3>#<span className='counter'>{this.state.rows.ilosc_wnioskow}</span></h3>                                        
                                         </div>

                                         <div className='sin_q_act'>
                                            <h4>Liczba firm</h4>
                                            <h3>#<span className='counter'>{this.state.rows.ilosc_firm}</span></h3>                                   
                                         </div>
                                    </div>
              
                               
                        </div>
                    </div>
                </div>
                
                <div className="col-lg-12 overflow-x">
                    <Calendar apiKey={this.props.apiKey}></Calendar>
                </div>

            </div>
        </div>
        )
    }
 }


 export default Main