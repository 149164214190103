
import React from "react";
import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom";


import ConfigX from '../../ConfigX';
 
class ItemB extends React.Component
{  

  onSelect()
  {
    this.props.onClickItemB( this.props.id );
  }
  

  render(){  

    var info = this.props.row.informacje_ogolne;
    if(info.length > 150)
    {
        info = info.substring(0,150) + "...";
    }

    return(
 
        <div className="item">

             <div className="p1_3">
                 <div className="info">
                    <div className="title padding-2"><Link to={ConfigX.pathDef + "szczegolyB?id_b=" + this.props.row.id }  >{this.props.row.tytul}</Link></div>
                    <div className="firm  padding-2">Organizator: {this.props.row.podmiot_prowadzacy.nazwa}</div>
                    &nbsp;
                    <br/>
                 </div>
             </div>
 
             <div className="p1_3 border-left-right">
                 <div className="info"  >
                  <p>
                      {info}
                  </p>
                  <p>                   
                  </p>
                </div>
              </div>

             <div className="p1_3">
                <div className="info info_center"  >
                       <Link to={ConfigX.pathDef + "szczegolyB?id_b=" + this.props.row.id }  className="buttonStandard">Więcej informacji</Link>                                   
                </div>
             </div>

        </div>
    )

  }
}


export default ItemB