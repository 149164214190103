
import React from 'react';
import { Link } from 'react-router-dom';

import ConfigX from '../../ConfigX';

class Menu_user extends React.Component
{

    constructor(props)
    {
         super(props)           
         this.state = {
            m_panel: "",
            m_szkolenia: "selected",
            m_badania: "",
            m_wiadomosci: "",
            m_moje_konto: "",
          //  m_zapisz_innych: "",
            selected: 1           
         }
    }

    selectMenu(event){

         var option =  event.target.getAttribute("id");
         this.props.selectMenu(option);
    }

  

    componentDidMount()
    {
       // console.log("Menu Mount " + this.props.option);
    }

    componentDidUpdate()
    {
       // console.log("Menu Update " + this.props.option);  
        
        if(this.props.option != this.state.selected)
        {
             var tmp = this.state;
             tmp.m_panel = "";
             tmp.m_szkolenia = "";
             tmp.m_badania = "";
             tmp.m_wiadomosci = "";
             tmp.m_moje_konto = "";
            // tmp.m_zapisz_innych = "";

             tmp.selected = this.props.option;

             //if(this.props.option == 1) tmp.m_panel = "selected";
             if(this.props.option == 2) tmp.m_szkolenia = "selected";
             if(this.props.option == 3) tmp.m_badania = "selected";
             if(this.props.option == 4) tmp.m_wiadomosci = "selected";
             if(this.props.option == 5) tmp.m_moje_konto = "selected";
             //if(this.props.option == 6) tmp.m_zapisz_innych = "selected";

             this.setState(tmp);
            
        }
         
    }

   /* 
    <li className={this.state.m_szkolenia}  id="2"><Link to={ConfigX.pathDef + "user,training" }  >Szkolenia</Link></li>
    <li className={this.state.m_badania}    id="3"><Link to={ConfigX.pathDef + "user,research" }  >Badania</Link></li>
    <li className={this.state.m_wiadomosci} id="4"><Link to={ConfigX.pathDef + "user,message" }   >Wiadomości</Link></li>
    <li className={this.state.m_moje_konto} id="5"><Link to={ConfigX.pathDef + "user,settings" }  >Moje konto</Link></li> 
   */

    render()
    { 
        return (
            <div>
                <ul> 
                    {/* <li className={this.state.m_panel}      id="1"><Link to={ConfigX.pathDef + "user" }  >Panel</Link></li> */}
                    <Link to={ConfigX.pathDef + "user,training" } ><li className={this.state.m_szkolenia}  id="2" onClick={this.selectMenu.bind(this)}>Szkolenia</li></Link>
                    {/*<Link to={ConfigX.pathDef + "user,sign_other" } ><li className={this.state.m_zapisz_innych}  id="6" onClick={this.selectMenu.bind(this)}>Zapisz innych na szkolenie</li></Link>*/}
                    <Link to={ConfigX.pathDef + "user,research" } ><li className={this.state.m_badania}    id="3" onClick={this.selectMenu.bind(this)}>Badania</li></Link>
                    <Link to={ConfigX.pathDef + "user,message"  } ><li className={this.state.m_wiadomosci} id="4" onClick={this.selectMenu.bind(this)}>Wiadomości</li></Link>
                    <Link to={ConfigX.pathDef + "user,settings" } ><li className={this.state.m_moje_konto} id="5" onClick={this.selectMenu.bind(this)}>Moje konto</li> </Link>
                </ul>
            </div>
        )
    }

}

export default Menu_user