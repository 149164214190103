import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

 

 
class SearchBar extends React.Component
 {
        constructor(props)
        {
            super(props)            
        }


        onChangeNumerDokumentu(event)
        {                          
              this.props.changeFilter('numer_dokumentu', event.target.value);
        }
 
        onChangeDataWystawieniaOd(dat)
        {                          
              this.props.changeFilter('data_wystawienia_od', dat);
        }

        onChangeDataWystawieniaDo(dat)
        {                          
              this.props.changeFilter('data_wystawienia_do', dat);
        }

        onChangeNazwsiko(event)
        {                          
              this.props.changeFilter('nazwisko', event.target.value);
        }        
 
        onChangeNazwaFirmy(event)
        {                          
              this.props.changeFilter('nazwa_firmy', event.target.value);
        }  

        onChangeStatus(event)
        {                          
              this.props.changeFilter('status', event.target.value);
        } 

        onChangeTyp(event)
        {                          
              this.props.changeFilter('typ', event.target.value);
        } 

        goSearch()
        {
            this.props.goSearch();
        }

        goClear()
        {
            this.props.goClear();
        }

      

        render()
        {
            return (
                <div className='search_bar'>


                        <div className='filter'>
                            <div className='filter_label'>Status</div>
                            <div className='filter_content'>
                                <select value={this.props.search_bar.status} onChange={this.onChangeStatus.bind(this)}>
                                     <option value="">Wszystkie</option>
                                     <option value="r">Rozliczone</option>
                                     <option value="n">Nierozliczone</option>                                
                                </select>
                            </div>                                 
                        </div>

                        <div className='filter'>
                            <div className='filter_label'>Typ</div>
                            <div className='filter_content'>
                                <select value={this.props.search_bar.typ} onChange={this.onChangeTyp.bind(this)}>                                 
                                     <option value="s">Szkolenia</option>
                                     <option value="b">Badania</option>                                
                                </select>
                            </div>                                 
                        </div>

                    <div className='filter'>
                          <div className='filter_label'>Numer dokumentu</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.numer_dokumentu} onChange={this.onChangeNumerDokumentu.bind(this)}    />
                          </div>                                 
                    </div>
 
                    <div className='filter'>
                          <div className='filter_label'>Data wystawienia</div>
                          <div className='filter_content'>
                                <DatePicker
                                selected={this.props.search_bar.data_wystawienia_od}
                                onChange={this.onChangeDataWystawieniaOd.bind(this)}                               
                                dateFormat="yyyy-MM-dd"
                                locale="pl"
                                />                                                                                         
                          </div>                      
                          -
                          <div className='filter_content'>
                                <DatePicker
                                selected={this.props.search_bar.data_wystawienia_do}
                                onChange={this.onChangeDataWystawieniaDo.bind(this)}                                
                                dateFormat="yyyy-MM-dd"
                                locale="pl"
                                />                                                                                         
                          </div>  
                    </div>

                    

                    <div className='filter'>
                          <div className='filter_label'>Nazwisko</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.nazwisko} onChange={this.onChangeNazwsiko.bind(this)}    />
                          </div>                                 
                    </div>

                    
                    <div className='filter'>
                          <div className='filter_label'>Nazwa firmy</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.nazwa_firmy} onChange={this.onChangeNazwaFirmy.bind(this)}    />
                          </div>                                 
                    </div>
             

                    <div className='filter filterButton'>
                           {/*<div className='buttonSearch' onClick={this.goSearch.bind(this)}>Szukaj</div>                               */}
                           <div className='buttonSearch' onClick={this.goClear.bind(this) }>Wyczyść</div>                               
                    </div>
           

                </div>
            )
        }
 }

 export default SearchBar;