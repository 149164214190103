


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/rozliczenia/preview/step1.PNG';
import step2 from '../../helpdesk/images/rozliczenia/preview/step2.PNG';

 


class preview extends React.Component
 {



  render(){

    return(
        <>
        
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>Aby sprawdzić listę rozliczeń za szkolenia czy badania przechodzimy do menu "Rozliczenia".</p>
          <div className="imp-note"><p style={{color: "red"}}>Uwaga! Pozycje pojawiają się w momencie kiedy uczestnik zapisze się na szkolenie a administrator potwierdzi jego status na liście uczestników szkolenia. Jeżeli chodzi o badania to musi zostać wysłany wniosek, później musi zostać wyceniony i zaakcetowany przez użytkownika.</p></div>
          <p>Na liście widzimy czego dotyczy rozliczenie, czyli na zrzucie ekranu widać tylko rozliczenia za szkolenia. W danych odbiorcy podane jest imię, nazwisko i nazwę firmy.</p>
          <p>Numer dokumentu w którym jest znak zapytania oznacza, że nie został jeszcze wprowadzony przez administratora.</p>
          <p>Dalej widzimy datę wystawienia, kiedy jest termin płatności, jaka jest kwota do zapłaty, kwota już rozliczona i na końcu kolumna z kwotą ile jeszcze pozostało do zapłacenia, czyli wszystkie ważne i niezbędne informacje.</p>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>Przechodząc do podglądu danego rozliczenia widzimy w nagłówku tytuł rozliczenia, czyli tytuł szkolenia i kwotę do rozliczenia.</p>
          <p>Poniżej tytułu widzimy dane do faktury, które użytkownik podał zapisując się na to szkolenie.</p>
          <p>Niżej widzimy ważny blok danych dokumentu wystawionego w zewnętrznym systemie. Przypominamy, że w tym systemie nie ma możliwości wystawiania faktur, więc trzeba to zrobić w innym systemie a w tym miejscu podajemy te informacje. </p>
          <p>Kwota do zapłaty wskazuje jaka jest kwota netto do zapłaty przez uczestnika szkolenia a kwota rozliczenia wskazuje ile ten uczestnik rozliczył (przelał) pieniędzy.</p>
          <div className="imp-note"><p style={{color: "red"}}>Jeżeli kwota rozliczenia będzie równała się kwocie do zapłaty to po zapisie takiego rozliczenia automatycznie zmieni się jego status na "rozliczone".</p>
          <p style={{color: "red"}}>Musimy pamiętać, żeby wypełniać wszystkie pola z czerwoną gwiazdką. </p></div>

          <img src={step2} alt=""/>

        </section>


        </>

    )
  }



}

export default preview;