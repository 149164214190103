
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';


import ConfigX from '../ConfigX';



import '../App.css';
import BarTop from '../components/BarTop';
import DashboardContainerUser from '../components/DashboardContainerUser';
import BarBottom from '../components/BarBottom';

class Page extends React.Component
 {

  constructor(props)  
  {
    super(props)     
          

    var apiKey = "";
    var localUsr = window.localStorage.getItem('user');
    if(localUsr != null && localUsr != "undefinded" && localUsr.length > 3)
    {
       var jsonUsr = JSON.parse(localUsr);
       apiKey = jsonUsr.apiKey;
    }



    this.state = {
      option: this.props.option,
      apiKey: apiKey
  }
 
  
  }


  componentDidMount()
  {
    console.log("Dashboard user mount ");  
        
  }

  componentDidUpdate()
  {
    console.log("Dashboard user update "  );   
    
    if(this.props.option != this.state.option)
    {
      this.setState({
          option: this.props.option
      });
    }
    
  }

  render(){
       
    return (
        <div className="App AppDashboard">
            <BarTop/>
               <DashboardContainerUser apiKey={this.state.apiKey} option={this.state.option}/>
            <BarBottom/>
        </div>
      );

  }
 
}

export default Page;
