import React from "react";


class MembersPrint extends React.Component
{


 

 render()
 {

    

    const mystyle = {
        color: "black", 
        padding: "30px",
        fontFamily: "Arial",
        fontSize: "10px",
        fontWeight:"normal"    
      };

      const styleTab = {
        width: "100%",
        fontSize: "12px",
      }


      const styleTr = {
        backgroundColor: "#ddffdd"
      }

    return (
        <div  className="printView" style={ mystyle } >
             
            <h3>Lista uczestników szkolenia: {this.props.title} </h3>

            <table style={styleTab}>
                        <thead>
                            <tr>
                                <td>LP</td>
                                <td>Nazwisko</td>
                                <td>Imię</td>
                                <td>E-mail</td>
                                <td>Telefon</td>
                                <td>Status</td>                                                                                                           
                            </tr>
                        </thead>
                        <tbody> 

                          {
                            this.props.rows.map( (item,index)   => {

                                    return (
                                        <tr key={index} style={styleTr}>
                                            <td>{index + 1 }</td>
                                            <td>{item.nazwisko}</td>
                                            <td>{item.imie}</td>
                                            <td>{item.email}</td>
                                            <td>{item.telefon}</td>
                                            <td>{item.status}</td>
                                        </tr>
                                    )

                            })
                          }

                        </tbody>    


                        </table>

        </div>
    )
 }
}


export default MembersPrint