import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

 

 
class SearchBar extends React.Component
 {
        constructor(props)
        {
            super(props)            
        }


        onChangeNazwaFirmy(event)
        {                          
              this.props.changeFilter('nazwa_firmy', event.target.value);
        }

        onChangeNazwa(event)
        {                          
              this.props.changeFilter('tytul', event.target.value);
        }

      

        onChangeStatus(event)
        {
            this.props.changeFilter('status',  event.target.value );
        }


        goSearch()
        {
            this.props.goSearch();
        }

        goClear()
        {
            this.props.goClear();
        }

      

        render()
        {
            return (
                <div className='search_bar'>

                    <div className='filter'>
                          <div className='filter_label'>Podmiot prowadzący</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.nazwa_firmy} onChange={this.onChangeNazwaFirmy.bind(this)}    />
                          </div>                                 
                    </div>

                    <div className='filter'>
                          <div className='filter_label'>Tytuł</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.tytul} onChange={this.onChangeNazwa.bind(this)}    />
                          </div>                                 
                    </div>
                    
             


                    <div className='filter'>
                          <div className='filter_label'>Status</div>
                          <div className='filter_content'>
                              <select value={this.props.search_bar.status} onChange={this.onChangeStatus.bind(this) }  >
                                  <option value="">Wszystkie</option>  
                                  <option value="widoczne">Aktywne</option>              
                                  <option value="robocze">Robocze</option>              
                                  <option value="niewidoczne">Niewidoczne</option>  
                                  <option value="archiwalne">Archiwalne</option>                     
                               </select>
                          </div>                                 
                    </div>

                    <div className='filter filterButton'>
                           {/*<div className='buttonSearch' onClick={this.goSearch.bind(this)}>Szukaj</div>  */}                             
                           <div className='buttonSearch' onClick={this.goClear.bind(this) }>Wyczyść</div>                               
                    </div>
           

                </div>
            )
        }
 }

 export default SearchBar;