import React from 'react';
import { withRouter } from 'react-router-dom';

import ConfigX from '../../../ConfigX'
import SearchBar from './SearchBar';
import Pagger from '../../../components/Pagger';
import SortBy from '../../../components/SortBy';
import Table from './Table';
import Det from './Det';
import AlertMsg from '../../../components/AlertMsg';


import loading from '../../../image/loading.gif'
 

class Main extends React.Component
 {
    constructor(props)
    {        

        super(props);         

        this.state = {

            search_bar: {                                           
                od: "",
                do: "",
                nazwa: "",
                nazwa_firmy: "",
                status: "",
                id: "",                    
            },
            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },
            loading: 1,
            view: "list",
            selectedId: -1,
            rows: [],
            result: [],
           
            newButton : "",
            alertMSG: '',
            alertMSGTime: 0
           
        }

        
        
    }


    //Filtry..
    changeFilter(filterName, filterValue)
    {                                 
       
            var searchTmp = this.state.search_bar;
            searchTmp[filterName] = filterValue;                         

                    this.setState({
                        search_bar:  searchTmp,                            
                        view: "list",
                        selectedId: -1
                    });                 

            this.getData();
    }


    //Stronnicowanie...
    changePage(page)
    {
        

       //console.log("changePage: " + page );    
       this.state.pagger.page = parseInt(page);          
       this.getData(null);      
    }

    changeLimit(limit)
    {
      
       // console.log("changeLimit: " + limit );                        
        this.state.pagger.page = 1;
        this.state.pagger.limit = parseInt(limit);
        this.getData(null);
    }

    //Sortowanie..

    changeSort(column, sort)
    {
       //  console.log("changeSort: " + column + " | "  + sort );    

         this.state.pagger.order_col = column;
         this.state.pagger.order = sort;
         this.getData(null);
    }

    //Wyszukaj..
    goSearch(){
       
        this.getData(null);
    }
        
    //Wyczszczenie parametrów wyszukiwania
    goClear()
    {          
        this.setState(
            {
                search_bar:
                {                                           
                    od: "",
                    do: "",
                    nazwa: "",
                    nazwa_firmy: "",
                    status: "",
                    id: "",                    
                },
                view: "list",
                selectedId: -1
            }
        );
        
        setTimeout(() => {
             this.getData(null);                
        }, 100);
     
    }

    //Pobranie danych
    getData(  onFinish )
    {

        //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
        var wait = setTimeout(
             () => {
                this.setState({ loading: 1 });
             }
            ,500);

           

        var tmpNonRef = JSON.stringify( this.state );
        tmpNonRef = JSON.parse(tmpNonRef);

        var dataPost = tmpNonRef.search_bar;
        dataPost.pagger = tmpNonRef.pagger;
        dataPost.user   = this.props.user; 
      
        
        //console.log("Requets " +  JSON.stringify(dataPost) );


        var action = "/dash,get_training";
     

        fetch( ConfigX.restApi + action , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        
   
             clearTimeout(wait);

             this.setState({
                rows: json.rows,
                result: json.result,
                loading: 0
             }); 
                         
             if(onFinish != null && onFinish != undefined ) onFinish();
           
        });
    }
        


    redirect(action, id, param)
    {
      

        if(param == "deleted") //usunięto szkolenie
        {
            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: "Szkolenie zostało usunięte!",
                alertMSGTime: dt
            });                  
        }

        if(param == "duplicate") //zduplikowane szkolenie
        {
            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: "Szkolenie zostało zduplikowane!",
                alertMSGTime: dt
            });                  
        }       

       

        if(action == "list")
        {
            this.getData(null);
        }
     
        
        this.setState(
            {
                view: action,
                selectedId: id,
            }
           );
    }

    componentDidUpdate()
    {
        //console.log( "update eee ");
    }

    componentDidMount()
    {
        //console.log("List mount " + this.state.search_bar);    
        this.getData(null);                     
    }



    onClickAddNew()
    {                   
        //this.redirect("new",-1,"");

        var dataPost = {
            id: -1,       
            tytul: "Zapisz na szkolenie",       
            cel_szkolenia: "Celem szkolenia jest dostarczenie obszernej i przydatnej wiedzy na temat...",       
            //wymagane_od_uczestnika: " ",       
            ilosc_miejsc: "100",
            dla_kogo: "",       
            zapisanych: "100",       
            sk_firmy_id: "-1",       
            koszt_szkolenia: "10",  
            koszt_szkolenia_bkp: "10",      
            koszt_jm: "UW",       
            platnosci: "PW",       
            termin_odwolania: "2050-01-01 12:00",       
            terminy: [],       
        };

        
        fetch( ConfigX.restApi + "/dash,add_edit_training" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             if(json.status == "OK")
             {
                var id = json.id;
                
                this.setState({
                     loading: 1
                });
                this.getData( () => {

                        setTimeout(()=>{  //poczekaj az zakończy rendering..
                            this.redirect("det",id,"added");
                        } ,50);                   
                });              
             }                 
        });

    }

    onDialogOK(param)
    {
        //console.log("click ok dialog");
            this.setState({
                alertMSG: '',
                alertMSGTime: 0,
            });
    }


    render()
    {         
        var newButton = "";
        var user = this.props.user;

       if(user === undefined)
       {
           newButton = (

            <div className='buttonAbove'>
              <div className='buttonNewItem' onClick={this.onClickAddNew.bind(this)} >Zapisz kogoś na szkolenie (+)</div> 
            </div>  

           ) ;
          
       }

       if(this.state.loading == 1)  
       {
           return (
            <div className='list'>

                {newButton}

              <SearchBar search_bar={this.state.search_bar} goSearch={this.goSearch.bind(this)} goClear={this.goClear.bind(this)}  changeFilter={this.changeFilter.bind(this)}/>                                     
               <div className='table_content'>
                 <div className='loading'><img src={loading} /></div>
               </div>
             </div>
           )
       }
             
       
       if(this.state.view == "det" && this.state.selectedId > 0)
       {
            return (
                <div className='list'>           
                    <Det redirect={this.redirect.bind(this) } id={this.state.selectedId} apiKey={this.props.apiKey} /> 
                </div>
            )
       }


      
       //------------------------------------------
       //------------------------------------------
       //------------------------------------------

       if(this.state.rows.length >= 10)
       {
    
            return (                
                <div className='list'>
                    
                    {newButton}

                    <SearchBar search_bar={this.state.search_bar} goSearch={this.goSearch.bind(this)} goClear={this.goClear.bind(this)}  changeFilter={this.changeFilter.bind(this)}/>                                     

                    <SortBy  changeSort={this.changeSort.bind(this) }  result={this.state.result} />
                    <Pagger page={this.state.result.page} pages={this.state.result.pages} limit={this.state.result.limit} changePage={this.changePage.bind(this)} changeLimit={this.changeLimit.bind(this)} />

                

                    <Table rows={this.state.rows} search_bar={this.state.search_bar} redirect={this.redirect.bind(this)} apiKey={this.props.apiKey}/>

                    <SortBy changeSort={this.changeSort.bind(this) }    result={this.state.result} />
                    <Pagger page={this.state.result.page} pages={this.state.result.pages} limit={this.state.result.limit} changePage={this.changePage.bind(this)} changeLimit={this.changeLimit.bind(this)} />   

                    <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime }  msgParam="ok" onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>
          
                </div>
            )

       }else
       {

            return (
                <div className='list'>

                    {newButton}

                    <SearchBar search_bar={this.state.search_bar} goSearch={this.goSearch.bind(this)} goClear={this.goClear.bind(this)}  changeFilter={this.changeFilter.bind(this)}/>                                     

                    <SortBy  changeSort={this.changeSort.bind(this) }  result={this.state.result} />
                    <Pagger page={this.state.result.page} pages={this.state.result.pages} limit={this.state.result.limit} changePage={this.changePage.bind(this)} changeLimit={this.changeLimit.bind(this)} />

           

                    <Table rows={this.state.rows} search_bar={this.state.search_bar} redirect={this.redirect.bind(this)}/>  

                    <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } msgParam="ok"  onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>
                </div>
            )

       }

         
    }
 }


 export default Main