import React from 'react';
import TableRow from './TableRow';
import EmptyTab from '../../../components/EmptyTab';

 
class Table extends React.Component
 {
        constructor(props)
        {
            super(props)
        }



        onRowClick(id)
        {
            this.props.redirect("det", id);
        }

        render()
        {
            var i = 0;

            if(this.props.rows.length == 0)
            {
                return (
                        <EmptyTab msg="Ta tabelka jest pusta lub brak wyników wyszukiwania"/>
                )
            }

            var razemPozostalo = 0;

            return (
                <div className='table_content'>
                 

                    <table className='table'>
                      <thead>
                        <tr>
                             <td>LP</td>
                             <td>Napisz</td>
                             <td>Dotyczy</td>                                           
                             <td>Odbiorca</td>                                                                        
                             <td>Numer dokumentu</td>                                           
                             <td>Data wystawienia</td>                                           
                             <td>Termin płatności</td>                                           
                             <td>Kwota do zapłaty</td>                                           
                             <td>Kwota rozliczona</td>                                           
                             <td>Pozostało</td>                                           
                        </tr>
                      </thead>
                      <tbody> 
                        {
                           
                            this.props.rows.map( item   => {
                                i++;

                                var doZap    = parseFloat(item.do_zaplaty);
                                var kwotaRoz = parseFloat(item.kwota_rozliczenia);
                                var pozostalo = doZap - kwotaRoz;
                                razemPozostalo = razemPozostalo + pozostalo;

                                return (
                                    <TableRow key={i} num={i} row={item} apiKey={this.props.apiKey} onRowClick={this.onRowClick.bind(this)} />
                                )
                            } )                          
                        } 

                        

                        <tr>
                             <td> </td>
                             <td> </td>
                             <td> </td>                                           
                             <td> </td>                                           
                             <td> </td>                                           
                             <td> </td>                                           
                             <td> </td>                                           
                             <td> </td>                                           
                             <td></td>                                           
                             <td className='number text_bold'>{ parseFloat(razemPozostalo).toFixed(2) }</td>                                           
                        </tr>
                    </tbody>    


                    </table>

                </div>
            )
        }
 }

 export default Table;