
import React from 'react';

import NumberFormat from 'react-number-format';
import { Link, Navigate } from 'react-router-dom';
import { format } from 'react-string-format';
import ConfigX from '../../ConfigX';

import LogIn from '../login/LogIn';
import Regulamin from '../page/Regulamin';
import RODO from '../page/RODO';

class Register extends React.Component
 {
 
 
      constructor(props)
      {
        super(props)


        this.state = {
            name: "",
            name_err: "",            
            name_ok: "",            
            last_name: "",
            last_name_err: "",
            last_name_ok: "",
            email: "",
            email_err: "",
            email_ok: "",
            phone: "",
            phone_err: "",
            phone_ok: "",
            password: "",
            password_err: "",
            password_ok: "",
            password2: "",
            password2_ok: "",
            msg_error: "",
            crypt: "",
            auth: "", //sms || email
            code: "",
            code_err: "",
            status: "",
            //step: 1,  //wyswietlenie formularza rejestracji... /2 podanie kodu // 3 finalizacja...           
            step: this.props.step,
            akc_reg: false, //zaakceptował regulamin?
            akc_reg_ok: '', //zaakceptował regulamin?
            akc_reg2: false,
            akc_reg2_ok: '',
           // akc_reg3: false,
           // akc_reg3_ok: '',
            akc_reg4: false,
            akc_reg4_ok: '',
            akc_reg5: false,
            akc_reg5_ok: '',
            akc_newletter: false, //newslttter..

            login: 0,
            redirect: "",

            glmsg: '',
            glmsg2: '',
            glmsg3: '',
            glmsg4: '',
            glmsg5: '',
            glmsg6: '',

            show_remind_link: 0, //pokaż link do zapomniałem hasła w komunikacie błędu
            remind: 0,

            regId: 0, 
            regTxt: "?", 
            view: "",
        }

        
      }


      componentDidMount()
      {
     
            fetch( ConfigX.restApi + '/common,get_regulamin', {
                method: 'POST',                
                headers: {
                    'Content-Type': 'text/html'
                }
            })
            .then(res => res.json() )
            .then(res => {

              //  console.log(JSON.stringify(res) );  
                
                this.setState({
                    regId: res.id,
                    regTxt: res.tresc_html,
                });

              

            } );

      }

      isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

      isValidPassword(password)
      {                        
         if(password.length < 6) return false;

         var lowercase = /[a-z]/.test(password);
         var uppercase = /[A-Z]/.test(password);
         var numbers = /[0-9]/.test(password);

        

         if(!lowercase) return false;
         if(!uppercase) return false;
         if(!numbers) return false;

         return true;
      }

      onChangeName(event)
      {
           var v = event.target.value;
           v = v.replace(/[0-9]/g, ""); //wywal litery...
           v = v.replace(" ","");
           
           var ok = "";
           if(v.length > 2) ok = "correct";

            this.setState(
                {
                  name: v,
                  name_ok: ok
                }
            );
      }
      
      onChangeLastName(event)
      {
            var v = event.target.value;
            v = v.replace(/[0-9]/g, ""); //wywal litery...
            v = v.replace(" ","");

            var ok = "";
            if(v.length > 2) ok = "correct";

            this.setState(
                {
                  last_name: v,
                  last_name_ok: ok
                }
            );
      }

      onChangeEmail(event)
      {       
        var v = event.target.value;
        v = v.replace(" ","");

        var ok = "";
        if(v.length > 2 && this.isValidEmail(v) ) 
        {
           ok = "correct";
        }



            this.setState(
                {
                  email: v,
                  email_ok: ok
                }
            );
      }

      onChangePassword(event)
      {

        var v = event.target.value;

            var ok = "";
        
            if( this.isValidPassword(v))
            {
                ok = "correct";
            }


            this.setState(
                {
                  password: v,
                  password_ok: ok
                }
            );
      }

      
      onChangePassword2(event)
      {
            var v = event.target.value;
            var ok = "";
            
            if( this.isValidPassword(v) &&  this.state.password == v )
            {
                ok = "correct";
            }

            this.setState(
                {
                  password2: v,
                  password2_ok: ok
                }
            );
      }

      onChangePhone(event)
      {
            var v = event.target.value;

            //v = v.replace("+48","");

          //  v = v.replace(/[^0-9]/g, ""); //wywal litery...
            v = v.replace(" ","");

           // if(v.substr(0,3) != '+48')
          //  {
          //      v = "+48" + v;
          //  }
           
            
            var ok = "";
            if(v.length > 8  ) 
            {
                  ok = "correct";
            }


            this.setState(
                {
                  phone: v,
                  phone_ok: ok
                }
            );
      }

      onSubmitForm(event)
      {
            event.preventDefault();

         
            var err = false;

            //-----
            if(!this.state.akc_reg)
            {
                this.setState(
                    {
                        akc_reg_ok: "incorrect"
                    }
                );                
                err = true;
            }                                  
            if(!this.state.akc_reg2)
            {
                this.setState(
                    {
                        akc_reg2_ok: "incorrect"
                    }
                );                
                err = true;
            }
          /*  if(!this.state.akc_reg3)
            {
                this.setState(
                    {
                        akc_reg3_ok: "incorrect"
                    }
                );                
                err = true;
            } */
            var zgoda_handlowa = 0;
            if(this.state.akc_reg4)
            {
                 var zgoda_handlowa = 1;

            //    this.setState(
             //       {
             //           akc_reg4_ok: "incorrect"
             //       }
              //  );                
              //  err = true;
            }  
            if(!this.state.akc_reg5)
            {
                this.setState(
                    {
                        akc_reg5_ok: "incorrect"
                    }
                );                
                err = true;
            }                                
            if(this.state.name.length < 2)      
            {
                 this.setState({ name_ok: "incorrect", glmsg:"Podaj imię." }); 
                 err = true; 
            }else{
                this.setState({ name_ok: "correct", glmsg:"" }); 
                err = false; 
            }

            if(this.state.last_name.length < 2) 
            { 
                this.setState({ last_name_ok: "incorrect", glmsg2:"Podaj nazwisko." });  
                err = true;        
            }else{
                this.setState({ last_name_ok: "correct", glmsg2:"" });  
                err = false;    
            }

            if(this.state.email.length < 5)     
            { 
                this.setState({ email_ok: "incorrect", glmsg3:"Podaj adres e-mail." });
                 err = true; 
            }else{
                this.setState({ email_ok: "correct", glmsg3:"" });
                 err = false;
            }

            if(this.state.phone.length < 9)     
            { 
                this.setState({ phone_ok: "incorrect", glmsg4:"Podaj numer telefonu." }); 
                err = true; 
            }else{
                this.setState({ phone_ok: "correct", glmsg4:"" }); 
                err = false; 
            }


            if(this.state.password.length < 6)  { this.setState({ password_ok: "incorrect" ,glmsg5:"Hasło musi mieć przynajmniej 6 znaków."}); err = true; return;}
            if(this.state.password2.length < 6) { this.setState({ password2_ok: "incorrect",glmsg6:"Hasło musi mieć przynajmniej 6 znaków." }); err = true; return;}

            var lowercase = /[a-z]/.test(this.state.password);
            var uppercase = /[A-Z]/.test(this.state.password);
            var numbers   = /[0-9]/.test(this.state.password);    

            var lowercase2 = /[a-z]/.test(this.state.password2);
            var uppercase2 = /[A-Z]/.test(this.state.password2);
            var numbers2   = /[0-9]/.test(this.state.password2);

            if(!lowercase) { this.setState({ password_ok: "incorrect",  glmsg5:"Błąd Podaj min. jedną mała literę." }); err = true; }
            if(!uppercase) { this.setState({ password_ok: "incorrect" , glmsg5:"Błąd Podaj min. jedną dużą literę." }); err = true; }
            if(!numbers)   { this.setState({ password_ok: "incorrect" , glmsg5:"Błąd Podaj min. jedną cyfrę."}); err = true; }

            if(!lowercase2) { this.setState({ password2_ok: "incorrect", glmsg6:"Błąd Podaj min. jedną mała literę." }); err = true; }
            if(!uppercase2) { this.setState({ password2_ok: "incorrect", glmsg6:"Błąd Podaj min. jedną dużą literę." }); err = true; }
            if(!numbers2)   { this.setState({ password2_ok: "incorrect", glmsg6:"Błąd Podaj min. jedną cyfrę."}); err = true; }



            if(this.state.password != this.state.password2)
             {
                 this.setState({ password_ok: "incorrect",password2_ok: "incorrect", glmsg5:"Błąd hasła. Podane hasła się różnią.",glmsg6:"Błąd hasła. Podane hasła się różnią." });
                  err = true; 
             }
             if(this.state.password == this.state.password2)
             {
                this.setState({ password_ok: "correct",password2_ok: "correct", glmsg5:"",glmsg6:"" });
                err = false;
             }

            if(!(this.state.email.length > 2 && this.isValidEmail(this.state.email) ) ) 
            {
                 this.setState({ email_ok: "incorrect",glmsg3:"Błędny adres e-mail." }); 
                 err = true; 
            }else{
                this.setState({ email_ok: "correct",glmsg3:"" }); 
                err = false; 
            }


           // if(err) return;
            ///------  

            var akc_newsletter    = 0;
            var akc_regulamin     = 0;
            var adm_regulaminy_id = 0;

            if(this.state.akc_newletter)       { akc_newsletter = 1 };
            if(this.state.akc_reg)             { akc_regulamin = 1 };
            if(!this.state.akc_reg2)           { akc_regulamin = 0 };
            //if(!this.state.akc_reg3)           { akc_regulamin = 0 };
            if(!this.state.akc_reg4)           { akc_regulamin = 0 };
            if(!this.state.akc_reg5)           { akc_regulamin = 0 };
  

            var data = {
                licenceId: ConfigX.id,
                type: "u",
                name: this.state.name,
                last_name: this.state.last_name,
                password: this.state.password,
                email: this.state.email,
                phone: this.state.phone,
                nip: "",
                company: "",
                akc_newsletter: akc_newsletter,
                akc_regulamin: akc_regulamin,
                adm_regulaminy_id: this.state.regId,
                zgoda_handlowa: zgoda_handlowa,
            };


            fetch( ConfigX.restApi + '/rest,go_register', {
                method: 'POST', 
                body: JSON.stringify(data), 
                headers: {
                    'Content-Type': 'text/html'
                }
             })
             .then(res => res.json() )
             .then(res => {
    
               // console.log(JSON.stringify(res) );  
   
                if(res.email )
                {
                    let res_code = res.email.code;

                    if(res_code == 999) 
                    {
                        this.setState({
                            msg_error: res.email.param,
                            show_remind_link: 1
                        })
                    }
                }

                if(res.auth == 'email' || res.auth == 'sms')
                {
               
                    this.setState(
                        {
                            auth: res.auth,
                            crypt:res.crypt,
                            step: 2
                        }                        
                    );                

                    this.props.handler(2);
                }
    
             } );

      }

      onChangeCode(event)
      {
            var v = event.target.value;

            this.setState(
                {
                    code: v
                }
            );
      }

      onChangeReg(event)
      {
       
        if(event.target.name == 'regulamin')
        {
           this.setState({
              akc_reg: !this.state.akc_reg,
              akc_reg_ok: ""
           });
        }

        if(event.target.name == 'regulamin2')
        {
            this.setState({
                akc_reg2: !this.state.akc_reg2,
                akc_reg2_ok: ""
             });            
        }
        if(event.target.name == 'regulamin3')
        {
            this.setState({
                akc_reg3: !this.state.akc_reg3,
                akc_reg3_ok: ""
             });            
        }        
        if(event.target.name == 'regulamin4')
        {
            this.setState({
                akc_reg4: !this.state.akc_reg4,
                akc_reg4_ok: ""
             });            
        }
        if(event.target.name == 'regulamin5')
        {
            this.setState({
                akc_reg5: !this.state.akc_reg5,
                akc_reg5_ok: ""
             });            
        }

      }

      onChangeNews(event)
      {
        this.setState({
            akc_newletter: !this.state.akc_reg
         });
      }

      onSubmitForm2(event)
      {
          event.preventDefault();

          if(this.state.code.length < 4) return;

          var data = {
                code: this.state.code,
                crypt: this.state.crypt
          };


            fetch( ConfigX.restApi + '/rest,go_register_code', {
                method: 'POST', 
                body: JSON.stringify(data), 
                headers: {
                    'Content-Type': 'text/html'
                }
            })
            .then(res => res.json() )
            .then(res => {

              //  console.log(JSON.stringify(res) );  
                
                 if(res.status == 'OK')
                 {
                    this.setState({
                        step: 3
                    });
                 this.props.handler(3);
                  
                 }
                 if(res.status == 'ERROR')
                 {
                    this.setState({
                        msg_error: "Wystąpił błąd. Spróbuj ponownie."
                    })
                 }

            } );


      }


      finish()
      {
        this.setState({
            step: 4
       });
        this.props.handler(4);
      }



      goLogin()
      {
        //event.preventDefault();

        var data = {
            driver_name: "PC",
            driver_os: "??",
            username: this.state.email,
            password: this.state.password
        }

        //spróbuj się zalogować...
       
        fetch( ConfigX.restApi + '/rest,go_login', {
            method: 'POST', 
            body: JSON.stringify(data), 
            headers: {
                'Content-Type': 'text/html'
            }
         })
         .then(res => res.json() )
         .then(res => {

               var apiKey = res.apiKey;

               if(apiKey.length > 5 ) //jest apiKey - jest zalogowany..
               {
                     this.saveProfileToLocalStoge(apiKey) ;                    
                     return;
               }else
               {
                    this.setState({
                        username_err: "Podaj prawidłowe dane"
                    })
               }

         } );
                       

      }

      saveProfileToLocalStoge(apiKey)
      {
                fetch( ConfigX.restApi + '/rest,get_profile', {
                    method: 'POST', 
                    body: JSON.stringify(this.state), 
                    headers: {
                        'Content-Type': 'text/html',
                        'X-API-KEY': apiKey
                    }
                })
                .then(res => res.json() )
                .then(res => {

                    var usr = res.usr;

                    res.apiKey = apiKey;
                    
                    if(usr != null && usr != 'undefinded' && usr.length > 1)
                    {
                       window.localStorage.setItem('user', JSON.stringify(res));

                       //redirect.. somewere :)

                       if(usr.usr == "ADMIN") 
                       {                          
                                this.setState({ 
                                    redirect: ConfigX.pathDef + "dashboard"
                                    });

                       }else
                       {
                                this.setState({ 
                                    redirect: ConfigX.pathDef 
                                    });
                       }
                    }

                } );
      }


      counting(limit,callback)
      {
        var counter = 5;             

        var timer = setTimeout(function () {
             document.getElementById("count").innerHTML = counter;  
             counter = (counter - 1);
     
             if(counter < 0) 
             {                             
                 document.getElementById("count").style.display = 'none';  
                 callback('done');                  
             }
           
         }, limit);
              
      }

      goRegulamin(event)
      {
        this.setState({
            view: "regulamin"
        })
      }

      goRODO(event)
      {
        this.setState({
            view: "rodo"
        })
      }

      countAndLogin() 
      {       
        let id = setTimeout(()=> this.goLogin() , 3000);             
       // clearTimeout(id);            
      }

      showRemind(event)
      {
        this.setState({
            remind: 1
        })
      }

      render()
      {
 
        if(this.state.view == 'regulamin'){return(<Regulamin />)}
        if(this.state.view == 'rodo'){return(<RODO />)}
            

        let msg_error = '';
        let remind_link = '';

        if(this.state.msg_error.length > 0)
        {
             if(this.state.show_remind_link == 1)
             {
                remind_link = ( <span className="link alert alert alert-danger" onClick={this.showRemind.bind(this)} >"Nie pamiętam hasła"</span>)
             }
             msg_error = (
                <div>
                    <span className='alert alert-danger'>{this.state.msg_error}</span>{remind_link}<br/>
                </div>
             );
        }

   

        if(this.state.redirect.length > 3)
        {
            return (
                <div>
                   {<Navigate to={this.state.redirect} />}
                </div>
            )
        }

        if(this.state.remind == 1)
        {
            return(<LogIn view="remind" />)
        }
  

         if(this.state.step == 1)
         { 
                    return (
                        <div className="register">
                             
                                      
                            <form className='simplyForm' method='post' action="#" onSubmit={this.onSubmitForm.bind(this) }>

                            <div className='formTitle'>Zarejestruj się</div>  

                            <div className='text-center'>{msg_error}</div>  

                            <div className='formField formField50'>
                                <label>Imię<span className='required'>*</span></label> 
                                <span className={'errorForm'}>{this.state.glmsg}</span>
                                <input type="text" name='name' className={this.state.name_ok} value={this.state.name}  placeholder='Jan '  onChange={this.onChangeName.bind(this) } />
                            </div>

                            <div className='formField formField50'>
                                <label>Nazwisko<span className='required'>*</span></label> 
                                <span className={'errorForm'}>{this.state.glmsg2}</span>
                                <input type="text" name='nazwisko' className={this.state.last_name_ok}  value={this.state.last_name}  placeholder='Kowalski ' onChange={this.onChangeLastName.bind(this) } />
                            </div>

                            <div className='formField formField50'>
                                <label>E-mail<span className='required'>*</span></label> 
                                <span className={'errorForm'}>{this.state.glmsg3}</span>
                                <input type="text" name='email'  value={this.state.email} className={this.state.email_ok} placeholder='example@com.pl ' onChange={this.onChangeEmail.bind(this) }/>               
                            </div>

                            <div className='formField formField50'>
                                <label>Telefon<span className='required'>*</span></label> 
                                <span className={'errorForm'}>{this.state.glmsg4}</span>
                                <input type="text" name='phone'  value={this.state.phone} className={this.state.phone_ok}  placeholder='telefon' onChange={this.onChangePhone.bind(this) }/>
                            </div>                           

                            <div className='formField formField50'>
                                <label>Hasło (duże i małe litery, cyfry)<span className='required'>*</span></label> 
                                <span className={'errorForm'}>{this.state.glmsg5}</span>
                                <input type="password" name='password'  value={this.state.password} className={this.state.password_ok}  placeholder='Hasło: ' onChange={this.onChangePassword.bind(this) }/>                          
                            </div>

                            <div className='formField formField50'>
                                <label>Powtórz hasło<span className='required'>*</span></label> 
                                <span className={'errorForm'}>{this.state.glmsg6}</span>
                                <input type="password" name='password2'   value={this.state.password2}  className={this.state.password2_ok} placeholder='Powtórz: ' onChange={this.onChangePassword2.bind(this) } />
                            </div>
                          



                            <div className='formField formCheckbox'>                           
                            <input type="checkbox" name='regulamin2' defaultChecked={this.state.akc_reg2} onChange={this.onChangeReg.bind(this) } /><span className='required'>*</span>
                                 &nbsp;<span  className={this.state.akc_reg2_ok } >&nbsp; Oświadczam, ze zostałem/am poinformowany/a o zasadach przetwarzania danych oraz przysługujących mi uprawnieniach znajdujących się <span onClick={this.goRODO.bind(this)} className='link'><b>tutaj</b></span>.</span> 
                            </div>

                            <div className={'formField formCheckbox '}>    
                            <input type="checkbox" name='regulamin' defaultChecked={this.state.akc_reg}  onChange={this.onChangeReg.bind(this)} /><span className='required'>*</span>                               
                                 &nbsp; <span  className={this.state.akc_reg_ok } >&nbsp;Akceptuję postanowienia regulaminu zajdującego się <span onClick={this.goRegulamin.bind(this)} className='link'><b>tutaj</b></span>. </span>
                            </div>

                            <div className='formField formCheckbox'>                           
                            <input type="checkbox" name='regulamin5' defaultChecked={this.state.akc_reg5} onChange={this.onChangeReg.bind(this) } /><span className='required'>*</span>
                                 &nbsp;<span  className={this.state.akc_reg5_ok } > &nbsp; wyrażam zgodę na przetwarzanie moich danych osobowych dla celów związanych z przesyłaniem informacji na temat planowanych wydarzeń organizowanych przez BKP</span>
                            </div>

                            <div className='formField formCheckbox'>                           
                            <input type="checkbox" name='regulamin4' defaultChecked={this.state.akc_reg4} onChange={this.onChangeReg.bind(this) } />
                                 &nbsp; <span  >&nbsp; wyrażam zgodę na otrzymywanie od BKP informacji handlowych w rozumieniu ustawy z dnia 18 lipca 2002 o świadczeniu usług drogą elektroniczną, w tym informacji o planowanych szkoleniach</span>
                            </div>


                            <div className='formField'>                       
                              <input type="submit" className='buttonForm' value="Dalej"/>
                            </div>

                         

                            </form>

                        </div>
                    )
         }//koniec 1..


         if(this.state.step == 2)
         {
            return (
                <div className="register">
                    

                    <form className='simplyForm' method='post' action="#" onSubmit={this.onSubmitForm2.bind(this) }>

                        <div className='formTitle'>Autoryzacja kodem e-mail</div>

                        <div className='formField'>
                            <p>
                                Sprawdź swoja skrzynkę pocztową a następnie wprowadź otrzymany kod.   
                            </p>
                        </div>

                        <div className='formField'>
                            <label>Kod autoryzacyjny<span className='required'>*</span></label> 
                            <input type="text" name='code'  value={this.state.code}  placeholder='Kod: '  onChange={this.onChangeCode.bind(this) } />
                        </div>
                

                        <div className='formField'>                       
                            <input type="submit" className='buttonForm' value="Zatwierdź"/>
                        </div>

                    </form>

                </div>
            )
         }

         if(this.state.step == 3)
         {

            this.countAndLogin();

            return (
                <div className="register">
                    

                    <form className='simplyForm ' method='post' action="#" >
                    <div className='formTitle'>Gratulacje, zostałeś zarejestrowany.</div>

                    <div className='formField'>
                        <p>
                            Twoje konto jest już aktywne. Za chwilę nastąpi przekierowanie... <span id='count' className='text-red'></span>                                                      
                        </p>
                        <p>
                            Jeśli potrzebujesz dodatkowej pomocy skontaktuj się z nami. Chętnie pomożemy. 
                        </p>
                    </div> 
                    
                   
                    
                    </form>

                </div>
            )
         }

         if(this.state.step == 4)
         {
            return (
                <div className="register"></div>
            )

         }


      }
 }

 export default Register;