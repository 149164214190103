import React from 'react'; 

class DetailsPrint extends React.Component
{
  

   render()
   {

    const mystyle = {
        color: "black", 
        padding: "30px",
        fontFamily: "Arial"
      };

     const title = {
          fontSize:"18px"
     } ;

     const pp = {
        fontSize:"14px",
        marginTop: "10px",
        lineHeight: "1.6"
     } ;

     const subTitle = {
        fontSize:"14px",
        marginTop: "10px",
        lineHeight: "1.6",
        fontWeight: "bold"
     } ;

     return (
        <div  className="printView" style={ mystyle } >
            <div>             
                 <h1 style={title}>{this.props.row.tytul}</h1>

                 <div style={subTitle}>Cel szkolenia:</div> 
                 <p style={pp}>{this.props.row.cel_szkolenia}</p>

                 <div style={subTitle}>Wymagane od uczestnika:</div> 
                 <p style={pp}>{this.props.row.wymagane_od_uczestnika}</p>


                 <div style={subTitle}>Organizator / Podmiot prowadzący szkolenie:</div> 
                    <p>{this.props.row.podmiot_prowadzacy.nazwa}  </p>
                    <p>{this.props.row.podmiot_prowadzacy.ulica}  {this.props.row.podmiot_prowadzacy.nr_domu}  {this.props.row.podmiot_prowadzacy.nr_mieszkania} {this.props.row.podmiot_prowadzacy.kod_pocztowy} {this.props.row.podmiot_prowadzacy.miasto}  NIP: {this.props.row.podmiot_prowadzacy.nip}  </p>            
                    <p>{this.props.row.podmiot_prowadzacy.telefon} {this.props.row.podmiot_prowadzacy.email} </p>
                 

               <div style={subTitle}>Termin(y) zajęć w ramach szkolenia:</div> 
                    <ul>
                            {
                                    this.props.row.terminy.map( (row,index) => {
                                        return (
                                            <li key={index}   > {row.data_od}, Miejsce: {row.miasto} {row.ulica} {row.nr_domu} / {row.nr_mieszkania} {row.nr_nazwa_pomieszczenia}, Prowadzący: {row.osoba_prowadzaca_inne}      </li>
                                        )
                                    } )
                            }
                    </ul>


            </div>
        </div>
     );
   }


}

export default DetailsPrint;