import React from "react";

import deletePNG from '../image/delete.png'
import downloadPNG from '../image/download.png'
import checkPNG from '../image/check.png'
import ConfigX from '../ConfigX'

class FileUploader extends React.Component
{

    url_upload   = "";
    url_download = "";  
    url_get = "";  
    apiKey       = "";
    dragdrop = "";

    onlyDisplay = ""; // uwaga opcja dodana, flaga ustawia czy ma tylko wyświetlać pliki bez uploadera

    typ          = "news";
    typ_id       = 99999;

    myRefname = React.createRef();

    constructor(props)
    {
        super(props)

        var defView = "list"

        if(this.props.view)
        {
            defView = this.props.view;
        }

        this.state = {          
           view: defView,
           title: "wybierz plik",
           titlex: "📎 Materiały / Pliki",
           files: [],                   
           uploadProcentAll: 0, 
           onlyDisplay: 0,     
           deleteIcon: "on"             
        }

        if(this.props.deleteIcon)
        {
            this.state.deleteIcon = this.props.deleteIcon;
        }

        if(this.props.titlex)
        {
            this.state.titlex = this.props.titlex;
        }
        if(this.props.onlyDisplay)
        {
            this.state.onlyDisplay  = this.props.onlyDisplay;
        }

        this.url_upload   = ConfigX.restApi + "/dash,file_upload";
        this.url_download = ConfigX.restApi + "/dash,file_list";  
        this.url_get = ConfigX.restApi + "/dash,file_download";  
        this.url_delete   = ConfigX.restApi + "/dash,file_delete";  
        this.apiKey       = this.props.apiKey;
        this.typ          = this.props.typ;
        this.typ_id       = this.props.typ_id;



        window.ondragover = function(e) { e.preventDefault(); return false };
        window.ondrop = function(e) { e.preventDefault(); return false };
    }

    /**
     * Get actual list of file uploaded to server
     */
    componentDidMount()
    {
            var dataPost = {
                typ: this.typ,
                typ_id: this.typ_id,
            };

            fetch( this.url_download , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
                         
                this.setState({                 
                    files: json
                });      
            
            });
    }

   
    onChangeInput(event)
    {
        var handle = event.target;
        
        console.log("FileUploader, selected: " + handle.files.length );

        if( handle.files.length == 0) return false;     
        
        
        for(var i = 0; i < handle.files.length; i++)  
        {  
            var file = handle.files[i];
            var isAdded = false;

            this.state.files.forEach( exFile => {
                 if(exFile.name == file['name']) isAdded = true;
            });

            if(isAdded) continue;

                var randName = crypto.getRandomValues( new Uint32Array(1)) + file['name'];

                var item = {
                    name: file['name'],                
                    progress: 0,
                    status: 'start',
                    uq: randName,
                    size: '0',
                    time: '',
                }
                var list = this.state.files;
                list.push(item);
                this.setState({ files: list});

                this.startUpload(file, list.length-1, randName );
        }        

    }

    onDelete(event)
    {
        var uq = event.currentTarget.getAttribute("uq");
        console.log("FileUploader, delete: " + uq);


        var dataPost = {
            uq: uq,
            typ: this.typ,
            typ_id: this.typ_id,
        };

        fetch( this.url_delete , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
          
            if(json.status == "OK") //deleted on server..
            {
                var files = this.state.files.filter( item => {
                    if(item.uq == uq) return false;
                    return true;
                } );

                this.setState({
                    files: files
                })
            }
          
        });

    }



    onDownload(event)
    {
        var uq = event.currentTarget.getAttribute("uq");
        var ix = event.currentTarget.getAttribute("ix");
        console.log("FileUploader, download: " + uq +  " ix " + ix);

      

        var ff = this.state.files;

        if(ff[ix]['down_start']) return;

        ff[ix]['down_start'] = true;
        this.setState( { files: ff } );

        var dataPost = {
            uq: uq,
            typ: this.typ,
            typ_id: this.typ_id,
        };

        fetch( this.url_get , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
          
            if(json.status == "OK") //deleted on server..
            {                            
                var ff = this.state.files;
                ff[ix]['down_end'] = new Array();
                ff[ix]['down_end']['b64'] =  json.b64 ;
                ff[ix]['down_end']['mime'] = json.mime;
                this.setState( { files: ff } );
            }
          
        });

    }



    refreshProgress()
    {
        var sumP   = 0;
        var countP = 0;

        this.state.files.map( item => {
            
                if(item.status == 'start' || item.status == 'upload')
                {
                    countP++;
                    sumP += item.progress;
                }

        } );

        var avg = sumP / countP;

        this.setState({
            uploadProcentAll: avg
        });
    }

    startUpload(file, tabIndex, randName)
    {        
        var refState = this.state;
        var refRefreshProgress = this.refreshProgress.bind(this);

            var xhr = new XMLHttpRequest();           
            xhr.upload.addEventListener('progress',function(ev)
            {        
                var procent = (ev.loaded/ev.total );   
                procent *= 100;
                procent = Math.round(procent) ; 
                
                refState.files[tabIndex]['progress'] = procent;
                refState.files[tabIndex]['status'] = "upload";
                refRefreshProgress();
                
            }, false)


            xhr.addEventListener("load",  function(ev){               
            } , false);

            xhr.addEventListener("error", function(ev){}, false);
            xhr.addEventListener("abort", function(ev){}, false);

            xhr.onreadystatechange = function(ev)
            {                  
                  if (xhr.readyState == 4) 
                   {                                                                       
                      refState.files[tabIndex]['status'] = "finish";              
                      refState.files[tabIndex]['progress'] = 100;                    
                                          
                      var json = JSON.parse(this.responseText);
                      if(json.status && json.status == 'OK')
                      {
                        refState.files[tabIndex]['size'] = json.size;  
                        refState.files[tabIndex]['time'] = json.time;  
                        refState.files[tabIndex]['id'] = json.id; 
                      }            

                      refRefreshProgress();
                   }
            };

            xhr.open('POST', this.url_upload, true);  
            xhr.setRequestHeader('X-API-KEY', this.apiKey); 

            var data = new FormData();      
            data.append('file'+tabIndex, file ); 
            data.append('typ', this.typ);
            data.append('typ_id', this.typ_id);
            data.append('fileUploader','yes');
            data.append('uq', randName);
            xhr.send(data);

    }

    onDrop(ev)
    {
        console.log("onDrop");

        console.log( this.state.files);
        

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            [...ev.dataTransfer.items].forEach((item, i) => {
              // If dropped items aren't files, reject them
              if (item.kind === 'file') {
                const file = item.getAsFile();
                console.log(`… file[${i}].name = ${file.name}`);

                ////-----------------------               
                var isAdded = false;
    
                this.state.files.forEach( exFile => {
                     if(exFile.name == file['name']) isAdded = true;
                });
    
                if(!isAdded)
                 {
                    var randName = crypto.getRandomValues( new Uint32Array(1)) + file['name'];
    
                    var item = {
                        name: file['name'],                
                        progress: 0,
                        status: 'start',
                        uq: randName,
                        size: '0',
                        time: '',
                    }
                    var list = this.state.files;
                    list.push(item);
                    this.setState({ files: list});
    
                    this.startUpload(file, list.length-1, randName );
                }
                 ////-----------------------


              }
            });
          } else { 
            // Use DataTransfer interface to access the file(s)
            [...ev.dataTransfer.files].forEach((file, i) => {
              console.log(`… file[${i}].name = ${file.name}`);

                ////-----------------------               
                var isAdded = false;
    
                this.state.files.forEach( exFile => {
                     if(exFile.name == file['name']) isAdded = true;
                });
    
                if(!isAdded)
                 {
                    var randName = crypto.getRandomValues( new Uint32Array(1)) + file['name'];
    
                    var item = {
                        name: file['name'],                
                        progress: 0,
                        status: 'start',
                        uq: randName,
                        size: '0',
                        time: '',
                    }
                    var list = this.state.files;
                    list.push(item);
                    this.setState({ files: list});
    
                    this.startUpload(file, list.length-1, randName );
                }
                 ////-----------------------


            });
          }

    }

    handleClick = () => {
        this.myRefname.current.click();
     }


      formatSizeUnits(bytes){
        if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
        else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
        else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
        else if (bytes > 1)           { bytes = bytes + " b "; }
        else if (bytes == 1)          { bytes = bytes + " b "; }
        else                          { bytes = "0 b "; }
        return bytes;
      }

    render()
    {        
        
        if(this.state.view == "intab")
        {
            if(this.state.files.length > 0)
            {
                return (
                    <div>
                           {
                                  this.state.files.map( (item,index) => {

                                    var size = item['size'];
                                    var pr   = item['progress'];
                                    var downloading = <img src={downloadPNG} /> 


                                    size = this.formatSizeUnits(size);                            
                                    if(pr == '100')  pr = <img src={checkPNG}/>      
                                    
                                    if(item["down_start"])   var downloading = "czekaj...";
                                    if(item["down_end"])  
                                    {
                                         var downloading = "pobierz";
                                         downloading = <a target="_blank" download={item["uq"]} href={"data:"+ item["down_end"]["mime"] + ";base64, " + encodeURI(item["down_end"]["b64"]) }>pobierz</a>
                                    }

                                    return (
                                        <span key={index} id={"tt_ " + index}>
                                            <span className="text_center">{pr}</span>
                                            <span>{item.name}</span>
                                            <span className="text_center">{item.time}</span>
                                            <span className="number">{size}</span>
                                            <span className="text_center downloadIco"  uq={item.uq} ix={index}   onClick={this.onDownload.bind(this) }  > {downloading} </span>
                                            <span className="text_center deleteIco" uq={item.uq}  ix={index}  onClick={this.onDelete.bind(this) } >   <img src={deletePNG} /> </span>
                                        </span>
                                    )
                                })
                           } 
                    </div>
                )                
            }else
            {
                return (null)
            }
        }


        var progress = "";
        var progressStyle = {
            width: this.state.uploadProcentAll+"%"
        }

        if(this.state.uploadProcentAll > 0 && this.state.uploadProcentAll < 100 )
        {
            progress = (
                <div className="progressBar">
                   <div className="progressLine" style={progressStyle}></div>
                </div>
            )
        }

      
        var dragdrop = "";

        if(this.state.onlyDisplay == "0")
        {
            var dragdrop = (
                <div className="dragAndDrop" onDrop={this.onDrop.bind(this) }  onClick={this.handleClick.bind(this) } >Przeciągnij i wrzuć tutaj</div>
            )
        }


        if(this.state.files.length > 0)
        {

                return (
                    <div className="fileUploader">
                        <h3>{this.state.titlex} ({this.state.files.length})</h3>                 
        
                        <div className="buttonUploader"   >                    
                        <input type="file" ref={this.myRefname}   multiple id="fileselect" name="fileselect[]"  accept="*/*" onChange={this.onChangeInput.bind(this)}  /> 
                            
                            {dragdrop}
                                            
                        </div>
                        {progress}
                        <div className="fileListSc">
                            <table className="fileList">
                                <tbody>
                                { 
                                   
                                    this.state.files.map( (item,index) => {


                                        var size = item['size'];
                                        var pr   = item['progress'];
                                        var downloading = <img src={downloadPNG} /> 
                                        
                                        var deleteFileButton = (<td className="text_center deleteIco" uq={item.uq}  ix={index}  onClick={this.onDelete.bind(this) } >   <img src={deletePNG} /> </td>);

                                        if(this.state.onlyDisplay == '1' || this.state.deleteIcon == 'off')
                                        {
                                            var deleteFileButton = (<td className="text_center"></td>);                                                                                            
                                        }

                                        size = this.formatSizeUnits(size);                            
                                        if(pr == '100')  pr = <img src={checkPNG}/>      
                                        
                                        if(item["down_start"])   var downloading = "czekaj...";
                                        if(item["down_end"])  
                                        {
                                             var downloading = "pobierz";
                                             downloading = <a target="_blank" download={item["uq"]} href={"data:"+ item["down_end"]["mime"] + ";base64, " + encodeURI(item["down_end"]["b64"]) }>pobierz</a>
                                        }

                                        return (
                                            <tr key={index} id={"tt_ " + index}>
                                                <td className="text_center">{pr}</td>
                                                <td>{item.name}</td>
                                                <td className="text_center">{item.time}</td>
                                                <td className="number">{size}</td>
                                                <td className="text_center downloadIco" uq={item.uq} ix={index}   onClick={this.onDownload.bind(this) }  > {downloading} </td>
                                                {deleteFileButton}
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
        }else 
        {
                return (
                    <div className="fileUploader">
                        <h3>{this.state.titlex} ({this.state.files.length})</h3>                 
        
                        <div className="buttonUploader"   >                    
                        <input type="file" ref={this.myRefname}   multiple id="fileselect" name="fileselect[]"  accept="*/*" onChange={this.onChangeInput.bind(this)}  />                             
                            {dragdrop}                                   
                        </div>
                        {progress}                      
                    </div>
                )
        }



     
    }

}


export default FileUploader;