import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ConfigX from '../../../ConfigX';
import FileLister from "../../../components/FileLister";
import ButtonConfirm from "../../../components/ButtonConfirm";
import FileUploader from "../../../components/FileUploader";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

class EditWniosek extends React.Component
{
    constructor(props)
    {
        super(props)

       
        this.state = {
            view: 'empty',
            row: this.props.row,
            nn_kwota_netto: "",
            nn_kwota_vat: "",
            nn_przewidywana_data_zakonczenia_uslugi: "",
            nn_informacje_dla_wnioskodawcy: "",
        }
    }


    componentDidUpdate()
    {
        var goUpdate = false;

        if(this.props.row != null && this.state.row != null && this.props.row.id != this.state.row.id )
        {
            goUpdate = true;
        }
       
        if(this.props.row == null && this.state.row != null  || this.props.row != null && this.state.row == null )
        {
            goUpdate = true;
        }

        //console.log('UP' + goUpdate);

        if(goUpdate)
        {
            var defView = "empty";

            if(this.props.row != null )
            {
                defView = this.props.row.status;
            }

            this.setState({
                view: defView,
                row: this.props.row,

                nn_kwota_netto: ""
            });
        }               
    }


    onChangeKwota(event)
    {
        var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var row = this.state.row;
            row["kwota_netto"] = v; 

            this.setState( {
                row: row,
                nn_kwota_netto: ok
            } );
    }


    onChangeVat(event)
    {
        var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var row = this.state.row;
            row["kwota_vat"] = v; 

            this.setState( {
                row: row,
                nn_kwota_vat: ok
            } );
    }


    onChangeDateFrom(from)
    {

        //https://reactdatepicker.com/#example-min-date
        var v = from;
        var yyyy = v.getFullYear();
        var mm = (v.getMonth()+1) ;  if(mm < 10) { mm = "0" + mm; }
        var dd = (v.getDate()) ;  if(dd < 10) { dd = "0" + dd; }
        var hh = (v.getHours()) ;  if(hh < 10) { hh = "0" + hh; }
        var ii = (v.getMinutes()) ;  if(ii < 10) { ii = "0" + ii; }

        v  =   yyyy+ "-" + mm + "-" + dd + " " + hh + ":" + ii;

     //   console.log(v);

        var tmp = this.state.row;
        tmp.przewidywana_data_zakonczenia_uslugi = v;

        this.setState({
            row: tmp
        });
    }



    onChangeStatus(event)
    {
        var v   = event.target.value;      

        var row = this.state.row;
        row["status"] = v; 

        this.setState( {
            row: row,     
        } );
    }


    
    onChangeInformacjeDlaWnioskodawcy(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 2)  { ok = "incorrect"; } else { ok="correct" }

            var row = this.state.row;
            row["informacje_dla_wnioskodawcy"] = v; 

            this.setState( {
                row: row,
                nn_informacje_dla_wnioskodawcy: ok
            } );

    }

    
    updateWniosek(event)
    {
          //zapisz na serwerze...
        console.log(JSON.stringify(this.state.row) );

        var st = this.state.row.status;
        if(st == 'przygotowanie_wyceny') 
        {
            st = 'wycena_gotowa';

            if(this.state.row.kwota_netto.length < 1 || this.state.row.kwota_netto < 1 )
            {
                this.setState( {                
                    nn_kwota_netto: 'incorrect'
                } );
                return;
            }

            if(this.state.row.kwota_vat.length < 1 || this.state.row.kwota_vat < 1 )
            {
                this.setState( {                     
                    nn_kwota_vat: 'incorrect'
                } );
                return;
            }
        }

        if(st == 'odrzucony') 
        {
            if(this.state.row.informacje_dla_wnioskodawcy.length < 5)
            {
                this.setState( {                     
                    nn_informacje_dla_wnioskodawcy: 'incorrect'
                } );
                return;
            }
        }

          var dataPost = { 
            id: this.state.row.id,             
            status: st,  
            kwota_netto: this.state.row.kwota_netto,  
            kwota_vat: this.state.row.kwota_vat,  
            przewidywana_data_zakonczenia_uslugi: this.state.row.przewidywana_data_zakonczenia_uslugi,  
            data_zakonczeniu_uslugi: this.state.row.data_zakonczeniu_uslugi,  
            informacje_wlasne: this.state.row.informacje_wlasne,  
            informacje_dla_wnioskodawcy: this.state.row.informacje_dla_wnioskodawcy,  
        };     

        fetch( ConfigX.restApi + "/dash,edit_application" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             if(json.status == "OK")
             {
                  this.props.onClose();
             }else
             {

             }
            
        });

    }

    zamknij(event)
    {
        this.props.onClose();
    }


    onConfirmDel(type, param, param_id)
    {
       console.log(type + param + param_id);

       if(type != "yes")  return;
 
         var dataPost = {
            id: param_id,       
        };

   
        fetch( ConfigX.restApi + "/dash,del_application" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             if(json.status == "OK")
             {
                this.props.onClose();
             }                 
        });

        
        
    }



    render()
    {
      

        if(this.state.view == "empty" || this.state.row ==null )
        {
             return (null)
        }

 
        var content = (
            <div className='FormField FormButtons'>      
                <div className='buttonForm' onClick={this.zamknij.bind(this)}>anuluj</div>  
            </div>
        )    
        var combo = (null)

        var pliki = (null)

        //Combo....
        if(this.state.row && this.state.row.status == 'nowy')
        {
            combo = (
                <div className='FormField'>
                <label>Status<span className='required'>*</span></label>
                <select defaultValue={this.state.row.status} onChange={this.onChangeStatus.bind(this) } col="status" >
                   <option value="nowy">Nowe</option>                                                   
                   <option value="przygotowanie_wyceny">Wycena usługi</option>              
                   <option value="odrzucony">Wniosek odrzucony</option>              
                </select>
             </div> 
            )
        }
  
        if(this.state.row && this.state.row.status == 'kwota_przyjeta')
        {
            combo = (
                <div className='FormField'>
                <label>Status<span className='required'>*</span></label>
                <select defaultValue={this.state.row.status} onChange={this.onChangeStatus.bind(this) } col="status" >                                                               
                   <option value="kwota_przyjeta">Wnioskodawca przyjął wycenę</option>              
                   <option value="usluga_w_trakcie">Usługa w trakcie realizacji</option>              
                   <option value="usluga_wykonana">Usługa wykonana (gotowa do rozliczenia)</option>              
                   <option value="usluga_przerwana">Usługa przerwana (brak konieczności rozliczenia)</option>              
                </select>
             </div> 
            )
        }

        if(this.state.row && this.state.row.status == 'usluga_w_trakcie')
        {
            combo = (
                <div className='FormField'>
                <label>Status<span className='required'>*</span></label>
                <select defaultValue={this.state.row.status} onChange={this.onChangeStatus.bind(this) } col="status" >    
                   <option value="usluga_w_trakcie">Usługa w trakcie realizacji</option>                                
                   <option value="usluga_wykonana">Usługa wykonana (gotowa do rozliczenia)</option>              
                   <option value="usluga_przerwana">Usługa przerwana (brak konieczności rozliczenia)</option>              
                </select>
             </div> 
            )
        }


        //Content..
        if( this.state.row && this.state.row.status == "przygotowanie_wyceny") //formularz do wyceny :) 
        {
            if( this.state.row.przewidywana_data_zakonczenia_uslugi.length < 5 || this.state.row.przewidywana_data_zakonczenia_uslugi == '0000-00-00')
            {
                this.state.row.przewidywana_data_zakonczenia_uslugi = (new Date()).toISOString();
            }

          

            content =   (
                <div>                                

                                <div className='FormField inlineField'>
                                        <label>Kwota netto<span className='required'>*</span></label>
                                        <input type="text" value={ this.state.row.kwota_netto } className={"field100 " + this.state.nn_kwota_netto} onChange={this.onChangeKwota.bind(this)} />
                                </div>

                                <div className='FormField inlineField'>
                                        <label>Kwota vat<span className='required'>*</span></label>
                                        <input type="text" value={ this.state.row.kwota_vat } className={"field100 " + this.state.nn_kwota_vat} onChange={this.onChangeVat.bind(this)} />
                                </div>

                                <div className='FormField inlineField'>
                                        <label>Przewidywana data zakończenia usługi (wykonania)<span className='required'>*</span></label>
                                        <DatePicker
                                            selected={  new Date(this.state.row.przewidywana_data_zakonczenia_uslugi) }
                                            onChange={this.onChangeDateFrom.bind(this)}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={5}
                                            timeCaption="time"
                                            dateFormat="yyyy-MM-dd HH:mm"
                                            locale="pl"
                                            className={this.state.nn_przewidywana_data_zakonczenia_uslugi}
                                            />
                                </div>

                                <div className='FormField'>
                                        <label>Informacje dla wnioskodawcy</label>                            
                                        <textarea  className={"field100p field100h " + this.state.nn_informacje_dla_wnioskodawcy} value={this.state.row.informacje_dla_wnioskodawcy} col="informacje_dla_wnioskodawcy" onChange={this.onChangeInformacjeDlaWnioskodawcy.bind(this) }   />
                                </div>

                         


                               <div className='FormField FormButtons'>      
                                  <div className='buttonForm' onClick={this.updateWniosek.bind(this)}>zapisz</div>  
                                  &nbsp;
                                  <div className='buttonForm' onClick={this.zamknij.bind(this)}>anuluj</div>  
                               </div>
                            
                </div>
            )
        }


        if( this.state.row && this.state.row.status == "odrzucony") 
        {
            content =   (
                <div>
                                
 

                                <div className='FormField'>
                                        <label>Przyczyna odrzucenia</label>                            
                                        <textarea  className={"field100p field100h " + this.state.nn_informacje_dla_wnioskodawcy} value={this.state.row.informacje_dla_wnioskodawcy} col="informacje_dla_wnioskodawcy" onChange={this.onChangeInformacjeDlaWnioskodawcy.bind(this) }   />
                                </div>

                               <div className='FormField FormButtons'>      
                                  <div className='buttonForm' onClick={this.updateWniosek.bind(this)}>zapisz</div>  
                                  &nbsp;
                                  <div className='buttonForm' onClick={this.zamknij.bind(this)}>anuluj</div>  
                               </div>
                            
                </div>
            )
        }
      

        if( this.state.row && this.state.row.status == "wycena_gotowa") //oczekiwanie na reakcje klienta..
        {
            var content = (
                <div>
                    <div className="wniosekMsg">
                        Wycena jest gotowa ({this.state.row.kwota_netto}).zł. Oczekiwanie na zatwierdzenie lub odrzucenie kwoty wyceny przez wnioskodawce. 
                    </div>
                    <div className='FormField FormButtons'>      
                        <ButtonConfirm   onConfirm={this.onConfirmDel.bind(this) } param="sk_badania_wnioski" param_id={this.state.row.id}  title="usuń wniosek" query="Na pewno chcesz usunąć wniosek?"  />
                         &nbsp; &nbsp; 
                        <div className='buttonForm' onClick={this.zamknij.bind(this)}>zamknij</div>  
                    </div>
                </div>
            )
        }


        
        if( this.state.row && this.state.row.status == "usluga_w_trakcie" || this.state.row && this.state.row.status == "usluga_wykonana" ||  this.state.row && this.state.row.status == "usluga_przerwana") 
        {
            var tytul = "";
            if( this.state.row && this.state.row.status == "usluga_w_trakcie" ) {tytul = "Usługa w trakcie realizacji";}
            if( this.state.row && this.state.row.status == "usluga_wykonana" ) {tytul = "Usługa zrealizowana";}
            if( this.state.row && this.state.row.status == "usluga_przerwana" ) {tytul = "Usługa przerwana / niewykonana";}

            content =   (
                <div>                
                       <div className="wniosekMsg">
                          {tytul}
                       </div>

                                <div className='FormField'>
                                        <label>Informacje dla klienta</label>                            
                                        <textarea  className={"field100p field100h " + this.state.nn_informacje_dla_wnioskodawcy} value={this.state.row.informacje_dla_wnioskodawcy} col="informacje_dla_wnioskodawcy" onChange={this.onChangeInformacjeDlaWnioskodawcy.bind(this) }   />
                                </div>

                               <div className='FormField FormButtons'>      
                                  <div className='buttonForm' onClick={this.updateWniosek.bind(this)}>zapisz</div>  
                                  &nbsp;
                                  <div className='buttonForm' onClick={this.zamknij.bind(this)}>anuluj</div>  
                               </div>
                            
                </div>
            )
        }

        //Pliki
        if(this.state.row.pliki.length > 0)
        {
            pliki = (
                          
                <table className='fileLister'>
                <tbody>
                {
                    this.state.row.pliki.map( (item,index) => {
                           return (                                                        
                               <FileLister key={index} fileName={item.nazwa_wlasna  } uq={item.uq} apiKey={this.props.apiKey} typ="sk_badania_wnioski" typ_id={this.state.row.id} />
                           )
                   })
                }
                </tbody>
               </table>
               
            ) 
        }
        
        if(this.state.row.numer_wniosku.length == 0)
        {
            var nrWniosku = "Brak numeru wniosku";
        }else{
            var nrWniosku = this.state.row.numer_wniosku;
        }
        
            return (
                <div className="_editor">         
                <div className="info_shadow">                                                     
                <span className='window window_form'>
                    <div className='container container_form'>                       

                        <div className='windowTextContent'>       
                           <h3>{nrWniosku},  {this.state.row.data_zlozenia}</h3>
                           <h5>{this.state.row.imie} {this.state.row.nazwisko} </h5>                        
                           <div>Tel.: {this.state.row.telefon} E-mail: {this.state.row.email} </div>
                           <br/>
                           {pliki}
                        </div> 

                        <div className='dashForm'>            
                           {combo}                                
                           {content}
                        </div>

                    </div>
                </span>
                </div>
            </div>
            )
        

     
    }
}

export default EditWniosek 