import React from 'react';

import checkPNG from '../../../image/check.png'
import nonePNG from '../../../image/none.png'
import infoPNG from '../../../image/info.png'
import archivePNG from '../../../image/archive.png'
import duplicatePNG from '../../../image/duplicate.png'
import { Link } from 'react-router-dom';
import ConfigX from '../../../ConfigX';
 
class TableRow extends React.Component
 {
        constructor(props)
        {
            super(props)
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onRowClick(id);
        }
        duplicateTraining(event)
        {
            var id = event.target.getAttribute("id");
            this.props.duplicateTraining(id);
        }

        render()
        {

            var duplicate = (
                <img src={duplicatePNG} title="Duplikuj szkolenie"   id={this.props.row.id} alt="duplikuj szkolenie" style={{height:"20px"}} onClick={this.duplicateTraining.bind(this)} />                
            )
           
            var status = this.props.row.status;
            if(status == 'robocze') {
             //    duplicate = (
             //       <img src={duplicatePNG} title="Duplikuj szkolenie"   id={this.props.row.id} alt="duplikuj szkolenie" style={{height:"20px"}} onClick={this.duplicateTraining.bind(this)} />                
             //   )                
                status = (
                    <img src={infoPNG} title="Szkolenie robocze - niewidoczne"   id={this.props.row.id} onClick={this.onRowClick.bind(this)} />
                )
            }
            else
            if(status == 'widoczne') {

            //     duplicate = (
             //       <img src={duplicatePNG} title="Duplikuj szkolenie"   id={this.props.row.id} alt="duplikuj szkolenie" style={{height:"20px"}} onClick={this.duplicateTraining.bind(this)} />                
            //    )                                
                status = (
                    <img src={checkPNG} title="Szkolenie aktywne - widoczne"   id={this.props.row.id} onClick={this.onRowClick.bind(this)}  />
                ) 
            }
            else
            if(status == 'niewidoczne') {
              //   duplicate = (
             //       <img src={duplicatePNG} title="Duplikuj szkolenie"   id={this.props.row.id} alt="duplikuj szkolenie" style={{height:"20px"}} onClick={this.duplicateTraining.bind(this)} />                
             //   )                
                status = (
                    <img src={nonePNG} title="Szkolenie ukryte - niewidoczne"   id={this.props.row.id} onClick={this.onRowClick.bind(this)} />
                ) 
            }
            else
            if(status == 'archiwalne') {
              //  duplicate = (
             //       <img src={duplicatePNG} title="Duplikuj szkolenie"   id={this.props.row.id} alt="duplikuj szkolenie" style={{height:"20px"}} onClick={this.duplicateTraining.bind(this)} />                
             //   )                    
                status = (
                    <img src={archivePNG} title="Szkolenie archiwalne"   id={this.props.row.id} onClick={this.onRowClick.bind(this)} />
                ) 
            }

            return (
                 
                        <tr className='rowLink' >                             
                             <td id={this.props.row.id}   className="text_center">  {duplicate} </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} >  {this.props.num} </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > {status}  </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)}> {this.props.row.podmiot_prowadzacy.nazwa}  </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)}> {this.props.row.tytul}  </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)}>  {this.props.row.cel_szkolenia} </td>                           
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className='text_center'> {this.props.row.ilosc_miejsc}  </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className='text_center'> {this.props.row.zapisanych}  </td>                          
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className='text_center'> {this.props.row.oczekujacych}  </td>                          
                        </tr>                 
            )
        }
 }

 export default TableRow;