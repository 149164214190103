


import React from 'react';
import { Link  } from 'react-router-dom';
import './styles.css';
import './bootstrap.css';

import account1 from '../helpdesk/images/account/konto1.PNG';
import account2 from '../helpdesk/images/account/konto2.PNG';
import account3 from '../helpdesk/images/account/konto3.PNG';


class Account extends React.Component
 {



  render(){

    return(
        <>
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>Aby zalogować się do panelu klikamy w nagłówku link "Zaloguj się".</p>

          <img src={account1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>W kolejnym kroku wybieramy opcję "Mam już konto".</p>

          <img src={account2} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 3</h3>
          <br/>
          <p>Wpisujemy adres e-mail, ktróy podawaliśmy podczas rejestracji i hasło po czym klikamy na przycisk "Wejdź" logując się do panelu.</p>


          <img src={account3} alt=""/>

        </section>


        </>

    )
  }



}

export default Account;