import React from 'react';

class AlertMsg extends React.Component
 {
    constructor(props)
    {
        super(props)

      

        this.state = {
            title: this.props.title,            
            msg: this.props.msg, 
            msgTime: this.props.msgTime,
            view: "dialog",
            param: this.props.msgParam
        }              
    }
 
    showDialog()
    {
        this.setState({
            view: "dialog"
        });
        
    }

    onDialogOK()
    {
        this.setState({
            view: "close",
            msg: ''
        }); 

        if(this.props.onDialogOK)
        {
          this.props.onDialogOK(this.state.param);
        }
    }

    componentDidUpdate()
    {       

        if( this.props.msgTime &&  this.props.msgTime != this.state.msgTime)
        {
            this.setState({
                msg: this.props.msg,
                msgTime: this.props.msgTime,
                view: "dialog",
                param: this.props.msgParam
            });
        }
    }

 

    render()
    {        
      //  console.log("AlertMSG " + this.state.view + " | " + this.state.msg);

        if(this.state.view == "dialog" && this.state.msg.length > 2)
        {
            return (
                <div className="dialog_alert">                           
                  <div className="DialogContent">
                    <div className='dialog_title'>{this.state.title}</div>
                    <div className='dialog_container'>
                        <div className='dialog_query'>{this.state.msg}</div>
                        <div className="buttonForm buttonAlert" onClick={this.onDialogOK.bind(this) } >Ok</div>                     
                    </div>
                  </div>
                </div>
            );
        }
    }
 }

 export default AlertMsg;