import React from 'react';
import ConfigX from '../../../ConfigX'



import UserSelector from '../../../components/UserSelector';

import ButtonConfirm from '../../../components/ButtonConfirm';
import BackPNG from './../../../image/back.png'
import PrintPNG from './../../../image/print.png'

import loading from '../../../image/loading.gif'
import AlertMsg from '../../../components/AlertMsg';

//import MembersPrint from './MembersPrint';
import ReactToPrint from 'react-to-print';

import FileUploader from '../../../components/FileUploaderUser';


class Det extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            loading: 1,
            search_bar: {                                           
                dataczas: "",
                tytul: "",
                imie: "",     
                nazwisko: "",   
                id: this.props.id,                    
            },
            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },
            rows: [],
           

            alertMSG: '',
            alertMSGTime: 0,            
            title: "Podgląd wiadomości",

            nn_tytul: "",
            nn_dataczas: "",
            nn_tresc: "",
            nn_do_px_group_id: "",
            err_adresat: 0,

         //   editor_action: "none", //nie wyświetlaj edytora terminów
         //   editor_row: { }, //pusta baza dla editora..               
         //   editor_selected: 0
        };

        if(this.props.new_rec)
        {
            this.state.new_rec = this.props.new_rec
        }else{
            this.state.new_rec = 0;
        }


    }

    onBackClick()
    {
        this.props.redirect("list", this.props.id);
    }

    componentDidMount()
    {
        console.log("Det did mount");
        this.getData();
  
    }


      //Pobranie danych
    getData()
      {

          //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
          var wait = setTimeout(
               () => {
                  this.setState({ loading: 1 });
               }
              ,500);
  
           var tmpNonRef = JSON.stringify( this.state );
           tmpNonRef     = JSON.parse(tmpNonRef);

           var dataPost = {
             id: this.props.id
           }
  

          //console.log("Requets " +  JSON.stringify(dataPost) );
  
          fetch( ConfigX.restApi + "/user,get_empty_message" , {
              method: 'POST', 
              body: JSON.stringify(dataPost), 
              headers: {
                  'Content-Type': 'text/html',
                  'X-API-KEY': this.props.apiKey
              }
          })
          .then( res => res.json() )
          .then(json => {
          
              // console.log(JSON.stringify(json) );
               clearTimeout(wait);

               this.setState({
                  rows: json.rows[0],
                  loading: 0,       
               });      
             
          });
    }

    onSelectedUser(id, nazwa)
    {
        var tmp = this.state.rows;
        tmp.adresat = {};
        tmp.adresat.id = id;
        tmp.adresat.nazwa = nazwa;

        this.setState({
            rows: tmp,
            err_adresat: 0                        
        });

        //this.updateSzkolenie(null);
    }

    onDeleteTime(id)
    {
            var withOut = this.state.rows.terminy.filter( map => {
                if(map.id == id ) return false;
                return true;
            } )

            var tmp = this.state.rows;
            tmp.terminy = withOut;

            this.setState({
                rows: tmp
            });

    }

    onClickNewTime()
    {
        this.setState({
            editor_row:   {},
            editor_action: "new",    
            editor_selected: -1      
        });
    }


    updateWiadomosc(event)
    {            

        var err = false;

        if(this.state.rows.tytul.length < 2 || this.state.nn_tytul == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_tytul: "incorrect"
            });
        }

        if(this.state.rows.dataczas.length < 10 || this.state.nn_dataczas == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_dataczas: "incorrect"
            });
        }

        if(this.state.rows.tresc.length < 3 || this.state.nn_tresc == 'incorrect' ) 
        {
            err = true;
            this.setState({
                nn_tresc: "incorrect"
            });
        }


            if(this.state.new_rec == 1)
            {
                
                    if( this.state.nn_do_px_group_id == 'incorrect' || !this.state.rows.adresat.id || this.state.rows.nadawca.length == 0 )
                    {
                        err = true;
                        this.setState({
                            err_adresat: 1,
                            nn_do_px_group_id: 'incorrect'
                        });            
                    }
            }

        if(err) return;

        var bnText = event.target.innerHTML;
        event.target.innerHTML = "...";



            if(this.state.new_rec == 1)
            {
                var dataPost = {
                    id: this.state.rows.id,
                    tytul: this.state.rows.tytul,
                    dataczas: this.state.rows.dataczas,
                    tresc: this.state.rows.tresc,
                 //    od_px_group_id: this.state.rows.od_px_group_id,
                    do_px_group_id: this.state.rows.adresat.id, 
                    nowa_wiadomosc: this.state.new_rec
                };                
            }else{
                var dataPost = {
                    id: this.state.rows.id,
                    tytul: this.state.rows.tytul,
                    dataczas: this.state.rows.dataczas,
                    tresc: this.state.rows.tresc,
                 //    od_px_group_id: this.state.rows.od_px_group_id,
                    nowa_wiadomosc: this.state.new_rec
                    
                };                
            }



         //  console.log(JSON.stringify(dataPost) );
          
   
           fetch( ConfigX.restApi + "/user,send_message" , {
               method: 'POST', 
               body: JSON.stringify(dataPost), 
               headers: {
                   'Content-Type': 'text/html',
                   'X-API-KEY': this.props.apiKey
               }
           })
           .then( res => res.json() )
           .then(json => {

                 var dt = new Date();
                 dt = dt.toISOString();

                if(json.status == "OK")
                {
                    event.target.innerHTML = bnText;
                                     
                    this.setState({
                       alertMSG: 'Wysłano poprawnie wiadomość.',
                       alertMSGTime: dt
                    });

                    var wait = setTimeout(
                        () => {
                            this.props.redirect("list", this.props.id,"added");
                        }
                       ,1000);                    
                    


                }else
                {
                    this.setState({
                        alertMSG: 'UPS! Wystąpił problem z zapisem danych.',
                        alertMSGTime: dt
                     });  
                }
         
              
           });
    }

    onChangeTytul(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["tytul"] = v; 

            this.setState( {
                rows: rows,
                nn_tytul: ok
            } );
    }  

    onChangeDataczas(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["dataczas"] = v; 

            this.setState( {
                rows: rows,
                nn_dataczas: ok
            } );
    }

    onChangeTresc(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["tresc"] = v; 

            this.setState( { 
                rows: rows,
                nn_tresc: ok
            } );
    }


    onConfirmDel(type, param, param_id)
    {
       console.log(type + param + param_id);

       if(type != "yes")  return;


         //
         var dataPost = {
            id: param_id,       
        };

   
        fetch( ConfigX.restApi + "/user,del_message" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             if(json.status == "OK")
             {
                this.props.redirect("list",-1,"deleted");
             }                 
        });                
    }


    render()
    {      
        var err_adresat = '';
        var odp = '';

  
        if(this.state.err_adresat == 1)
        {
             err_adresat = (
                <span className={'field300 '+ this.state.nn_do_px_group_id }>Wybierz adresata</span>
            )
        }

        var wybierzAdresata = '';

        if( this.state.new_rec == 1 ) 
        {
            var title = "Napisz wiadomość";      

            wybierzAdresata = ( 
                <div className='FormField'>
                    <label>Adresat<span className='required'>*</span>{err_adresat}</label>
                    <UserSelector apiKey={this.props.apiKey}  title='Wybierz adresata' onSelectedUser={this.onSelectedUser.bind(this)}/>                                     
                </div>                
            )

            

        }else{
            var title = "Odpowiedz na wiadomość";

            wybierzAdresata = (
                <div className='FormField'>
                <label>Adresat</label>
                <input type="text" className={"field300 " + this.state.nn_do_px_group_id  } defaultValue={this.state.rows.adresat}  col="adresat"  />
                </div>                
            )
            odp = 'RE:';
        }

        if(this.state.loading == 1)  
        {
            return (
             <div className='list'>                                               
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        } 


        const pageStyle = `
        @page {
          size: 210mm 297mm;
        }
      
        @media all {
          .pagebreak {
            display: none;
          }
        }
      
        @media print {
          .pagebreak {
            page-break-before: always;
          }
        }
      `;
      


        return (
            <div className='details'>
                 <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} alt=""/> Powrót do listy wiadomości
                 </div>
                 
                                    
                    <h3>
                        {title}                                                
                    </h3>
                     

                    <div className='dashForm'>

                            {wybierzAdresata}


                          <div className='FormField inlineField'>
                               <label>Tytuł*</label>
                               <input type="text" className={"field300 " + this.state.nn_tytul } defaultValue={odp + this.state.rows.tytul}  col="tytul" onChange={this.onChangeTytul.bind(this) } />
                          </div>

                          <div className='FormField inlineField'>
                               <label>Data wysłania</label>
                               <input type="text" className={"field300 "+ this.state.nn_dataczas } defaultValue={this.state.rows.dataczas}  col="data wyslania" onChange={this.onChangeDataczas.bind(this) }/>
                          </div>

                          <div className='FormField'>
                               <label>Treść*</label>                  
                               <textarea  className={"field100p field100h " + this.state.nn_tresc } defaultValue={this.state.rows.tresc} col="tresc"   onChange={this.onChangeTresc.bind(this) } />
                          </div>

                        <div>
                          <br/>
                           <FileUploader typ="sk_wiadomosci" typ_id={this.state.rows.id} apiKey={this.props.apiKey} new_rec={this.state.new_rec} />
                          <br/>
                        </div>

                        <div className='FormField FormButtons'>
                            {/* <ButtonConfirm   onConfirm={this.onConfirmDel.bind(this) } param="sk_wiadomosci" param_id={this.state.rows.id}  title="usuń wiadomość*" query="Na pewno chcesz usunąć tę wiadomość?"  /> */}
                              
                            <div className='buttonForm' onClick={this.updateWiadomosc.bind(this)}>wyślij</div>  
                         </div>

                    </div>  



                    <div className='location' onClick={this.onBackClick.bind(this) }>
                        <br/>
                        <img src={BackPNG} /> Powrót do listy wiadomości
                        <br/>
                    </div>                
                                    


                 <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Powiadomienie"/>
           
            </div>
        );
    }
}

export default Det;