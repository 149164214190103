import React from 'react';

import FileLister from '../../../components/FileLister';

 
class DetWnioskiRow extends React.Component
 {
        constructor(props)
        {
            super(props)

            this.state = {
              chat: '1' //nowo dodane, flaga oznacza czy ma wyświetlać ikonkę czatu przy nazwisku
            }

            if(this.props.chat)
            {
                this.state.chat = this.props.chat;
            }
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
       
            if(this.state.chat == '1')this.props.onClickWniosek(id); else return null;
        }
 

      

        render()
        {
 
            var roz = "Nie";         
            var rowStyle = "";

           
            
            if(this.props.row.rozliczono == 1) 
            {
                 roz = "Tak"; 
                 rowStyle = "row_green";
            }

            var status = "Nowy";

            if(this.props.row.status == 'nowy')
            {
                status = "Nowy";
            }

            if(this.props.row.status == 'przygotowanie_wyceny')
            {
                status = "W trakcie wyceny";
            }

            if(this.props.row.status == 'wycena_gotowa')
            {
                status = "Wyceniono";
            }
  
            if(this.props.row.status == 'kwota_przyjeta')
            {
                status = "Kwota zaakceptowana";
            }

            if(this.props.row.status == 'kwota_odrzucona')
            {
                status = "Kwota odrzucona";
            }

            if(this.props.row.status == 'usluga_w_trakcie')
            {
                status = "Usługa w trakcie realizacji";
            }

            if(this.props.row.status == 'usluga_wykonana')
            {
                status = "Usługa wykonana";
            }
            
            if(this.props.row.status == 'usluga_przerwana')
            {
                status = "Usługa niewykonana";
            }

            if(this.props.row.status == 'odrzucony')
            {
                status = "Wniosek odrzucony";
            }




            return (               

                                  <tr className={ 'rowLink ' + rowStyle} >
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.num}</td>
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="text_center">{this.props.row.numer_wniosku} </td>  
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="text_center">{this.props.row.data_zlozenia}</td>  
                                      <td>   <span id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.nazwisko}</span> </td>  
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.imie}</td>                                                                                                                                                                                                                                                                   
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="text_center">{status}</td>  
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="number">{this.props.row.kwota_netto}</td> 
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="number">{this.props.row.kwota_vat}</td> 
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="text_center">{roz}</td> 
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="text_center">
                                        <table className='fileLister'>
                                         <tbody>
                                         {
                                             this.props.row.pliki.map( (item,index) => {
                                                    return (                                                        
                                                        <FileLister key={index} fileName={item.nazwa_wlasna  } uq={item.uq} apiKey={this.props.apiKey} typ="sk_badania_wnioski" typ_id={this.props.row.id} />
                                                    )
                                            })
                                         }
                                         </tbody>
                                        </table>
                                      </td> 
                                  </tr>                 
                      )
  



        }
 }

 export default DetWnioskiRow;