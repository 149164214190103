import React from 'react';
import ConfigX from '../../../ConfigX'

import FirmSelector from '../../../components/FirmSelector';
import DetTerminyRow from './DetTerminyRow';
import DetUczestnicyRow from './DetUczestnicyRow';
import EditTermin from './EditTermin';

import TipMessage from '../../../components/TipMessage';

import BackPNG from './../../../image/back.png'
import PrintPNG from './../../../image/print.png'

import loading from '../../../image/loading.gif'
import ButtonConfirm from '../../../components/ButtonConfirm';
import AlertMsg from '../../../components/AlertMsg';

import MembersPrint from './MembersPrint';
import ReactToPrint from 'react-to-print';

import FileUploader from '../../../components/FileUploader';


class Det extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            loading: 1,
            search_bar: {                                           
                od: "",
                do: "",
                nazwa: "",
                nazwa_firmy: "",
                id: this.props.id,                    
            },
            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },
            rows: [],
            result: [],

            alertMSG: '',
            alertMSGTime: 0,

            uczestnicy_fraza: '',      
            uczestnicy_status: '',      
            uczestnicy_rows: [],      
               

            nn_tytul: "",
            nn_cel_szkolenia: "",
            nn_wymagane_od_uczestnika: "",
            nn_ilosc_miejsc: "",
            nn_koszt_szkolenia: "",
            nn_koszt_jm: "",
            nn_platnosci: "",

            editor_action: "none", //nie wyświetlaj edytora terminów
            editor_row: { }, //pusta baza dla editora..               
            editor_selected: 0
        };
    }

    onBackClick()
    {
        this.props.redirect("list", this.props.id);
    }

    componentDidMount()
    {
        console.log("Det did mount");
        this.getData();
        this.getDataUczestnicy();
    }


      //Pobranie danych
    getData()
      {
  
          //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
          var wait = setTimeout(
               () => {
                  this.setState({ loading: 1 });
               }
              ,500);
  
          var tmpNonRef = JSON.stringify( this.state );
          tmpNonRef = JSON.parse(tmpNonRef);
  
          var dataPost = tmpNonRef.search_bar;
          dataPost.pagger = tmpNonRef.pagger;
  
        
          
          //console.log("Requets " +  JSON.stringify(dataPost) );
  
          fetch( ConfigX.restApi + "/user,get_training" , {
              method: 'POST', 
              body: JSON.stringify(dataPost), 
              headers: {
                  'Content-Type': 'text/html',
                  'X-API-KEY': this.props.apiKey
              }
          })
          .then( res => res.json() )
          .then(json => {
          
               //console.log(JSON.stringify(json) );
               clearTimeout(wait);
  
               this.setState({
                  rows: json.rows[0],
                  result: json.result, //zawsze pierwszy indeks
                  loading: 0
               });      
             
          });
    }


     //Pobranie danych
     getDataUczestnicy()
     {
  
 
         var dataPost = {
            pagger: {
              page: "1",
              limit: "1000",
              order_col: "",
              order: ""
            },
            id: this.state.search_bar.id,
            imie_nazwisko: ""
          }
 
         fetch( ConfigX.restApi + "/user,get_members" , {
             method: 'POST', 
             body: JSON.stringify(dataPost), 
             headers: {
                 'Content-Type': 'text/html',
                 'X-API-KEY': this.props.apiKey
             }
         })
         .then( res => res.json() )
         .then(json => {
         
 
              this.setState({
                 uczestnicy_rows: json,                  
                      
              });      
            
         });
   }


    onSelectedFirm(id, nazwa)
    {
        var tmp = this.state.rows;
        tmp.podmiot_prowadzacy = {};
        tmp.podmiot_prowadzacy.id = id;
        tmp.podmiot_prowadzacy.nazwa = nazwa;

        this.setState({
            rows: tmp                        
        });
    }

    onClickTermin(id)
    {
        const data = this.state.rows.terminy.filter( item => {  if(item.id == id ) return true; return false; } );

        var newRefRow = JSON.parse( JSON.stringify(data[0]) );

        this.setState({
            editor_row: newRefRow,
            editor_action: "edit",     
            editor_selected: newRefRow.id              
        });
    }

    onCloseEditor(modifyRow)
    {
        if(modifyRow != null)
        {
           
                //zwróc wszystko oprócz wybranego rekordu.
                var selectedInx = -1;

                this.state.rows.terminy.forEach( (item, index) => {
                    if(item.id == modifyRow.id)
                    {
                        selectedInx = index;                        
                    }
                });

            if(selectedInx >= 0)
            {
                var tmp =  this.state.rows;
                tmp.terminy[selectedInx] = modifyRow;

                this.setState({
                    rows: tmp
                });

                console.log("Edytowany " + modifyRow.id);
            }else
            {
                console.log("Nowy " + modifyRow.id);
                
                var tmp =  this.state.rows;
                tmp.terminy.push(modifyRow);

                this.setState({
                    rows: tmp
                });
            }
           
        }


        this.setState({
            editor_row: {},
            editor_action: "none",     
            editor_selected: -2              
        });
    }

    onDeleteTime(id)
    {
            var withOut = this.state.rows.terminy.filter( map => {
                if(map.id == id ) return false;
                return true;
            } )

            var tmp = this.state.rows;
            tmp.terminy = withOut;

            this.setState({
                rows: tmp
            });

    }

    onClickNewTime()
    {
        this.setState({
            editor_row:   {},
            editor_action: "new",    
            editor_selected: -1      
        });
    }


    render()
    {      
      
        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>                                               
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        } 
        
       
        var iloscTerminow = 0; 
        var iloscUczestnikow = 0; 
        var iloscUczestnikowOczekuje = 0; 
        var iloscUczestnikowPotwierdzone = 0; 
        var iloscUczestnikowOdrzucone = 0; 

        this.state.uczestnicy_rows.map( (item,index) => {
              if(item.status == "oczekuje")      iloscUczestnikowOczekuje++; 
              if(item.status == "potwierdzone")  iloscUczestnikowPotwierdzone++; 
              if(item.status == "odrzucone_brak_miejsc" || item.status == "odrzucone_odwolanie_szkolenia" )     iloscUczestnikowOdrzucone++; 
        })


        var msg = ""
        if(iloscUczestnikowOczekuje == 1) msg = "Brawo! Masz  chętną osobą na to szkolenie. Przejdź do poniższej listy uczestników i potwierdź zgłoszenie. ";
        if(iloscUczestnikowOczekuje >= 2) msg = "Pojawiły się osoby chętne na to szkolenie. Przejdź do poniższej listy uczestników i potwierdź zgłoszenie. ";


        if( this.state.rows.hasOwnProperty("terminy")  )     iloscTerminow = this.state.rows.terminy.length;
        if(this.state.uczestnicy_rows != undefined) iloscUczestnikow = this.state.uczestnicy_rows.length;
          


        if(this.state.rows.terminy.length < 1)
        {
            msg = "Nie podano jeszcze terminu szkolenia. Każde dodane szkolenie musi posiać min. jeden termin.  ";
        }


        const pageStyle = `
        @page {
          size: 210mm 297mm;
        }
      
        @media all {
          .pagebreak {
            display: none;
          }
        }
      
        @media print {
          .pagebreak {
            page-break-before: always;
          }
        }
      `;
      
       var proc = 100 * parseInt( iloscUczestnikowPotwierdzone );
       proc = parseInt( proc / this.state.rows.ilosc_miejsc);
       if(proc == NaN || proc == undefined || proc == null || isNaN(proc) ) proc = 0;
      
        if(proc > 100) proc = 100;

       var styleProgress = {
          width: proc+"%"
       }
       if(proc ==0 ) styleProgress = {
         width: 2
     }
 
        return (

            <div className='details'>
                 <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy szkoleń
                 </div>
                 
                                    
                    <h3>
                        {this.state.rows.tytul}                                                      
                    </h3>
                     

                    zajętych miejsc: {proc}% 
                    <div className='progressBarX'>
                        <div className='progressLineX' style={styleProgress}></div>
                    </div>

                    <TipMessage  msg={msg} type="notice" />

                    <div className='dashForm'>


                          <div className='FormField inlineField'>
                               <label>Podmiot prowadzący</label>
                               <input type="text" className={"field200 " + this.state.nn_nazwa} defaultValue={this.state.rows.podmiot_prowadzacy.nazwa}  col="nazwa"    />
                          </div>

                          <div className='FormField inlineField'>
                               <label>E-mail kontaktowy</label>
                               <input type="text" className={"field200 " + this.state.nn_email} defaultValue={this.state.rows.podmiot_prowadzacy.email}  col="email"    />
                          </div>

                          <div className='FormField inlineField'>
                               <label>Tytuł</label>
                               <input type="text" className={"field300 " + this.state.nn_tytul} defaultValue={this.state.rows.tytul}  col="tytul"    />
                          </div>

                          <div className='FormField inlineField'>
                               <label>Status</label>
                               <input type="text" className={"field100 " + this.state.nn_status} defaultValue={this.state.rows.status}  col="status"    />
                          </div>
                                      
                          <div className='FormField'>
                               <label>Cel szkolenia<span className='required'>*</span> (min. 100 znaków)</label>                  
                               <textarea  className={"field100p field100h " + this.state.nn_cel_szkolenia} defaultValue={this.state.rows.cel_szkolenia} col="cel_szkolenia"   />
                          </div>
                  

                     
                          <div className='FormField'>
                               <label>Wymagane od uczestnika</label>                  
                               <textarea  className={"field100p field100h " + this.state.nn_wymagane_od_uczestnika} defaultValue={this.state.rows.wymagane_od_uczestnika} col="wymagane_od_uczestnika"  />
                          </div>
                     

                   
                          <div className='FormField inlineField'>
                               <label>Ilość miejsc(maks. liczba uczestników)</label>
                               <input type="text" className={"field200 " + this.state.nn_ilosc_miejsc} defaultValue={this.state.rows.ilosc_miejsc}  col="ilosc_miejsc"    />
                          </div>
                   

                    
                          <div className='FormField inlineField'>
                               <label>Koszt szkolenia brutto (PLN)</label>
                               <input type="text" className={"field200 " + this.state.nn_koszt_szkolenia} defaultValue={this.state.rows.koszt_szkolenia}  col="koszt_szkolenia"  />
                          </div>

                          <div className='FormField inlineField'>
                               <label>Podany koszt obejmuje</label>
                               <input type="text" className={"field200 " + this.state.nn_koszt_jm} defaultValue={this.state.rows.koszt_jm}  col="koszt_jm"  />
                          </div>

                          <div className='FormField inlineField'>
                               <label>Płatność</label>
                               <input type="text" className={"field200 " + this.state.nn_platnosci} defaultValue={this.state.rows.platnosci}  col="platnosci"  />
                          </div>                        

                         <div className='simplyTipAlert'>
                            *Przeniesienie szkolenia do archiwum jest możliwe wyłącznie, gdy nie ma żadnego potwierdzonego uczestnictwa na liście uczestników.  Jeśli chcesz usunąć szkolenie wypisz ze szkolenia wszystkich uczestników.                             
                         </div>

                    </div>  

                    <div className='location' onClick={this.onBackClick.bind(this) }>
                     <br/>
                     <img src={BackPNG} /> Powrót do listy szkoleń
                     <br/>
                    </div>                
                                    

                   

                    <h3>Terminy zajęć (lekcji) w ramach szkolenia ({iloscTerminow})</h3>                     
                    <div className="terminy">

                            <div className='table_content'>                            
                                <table className='table table100'>
                                <thead>
                                    <tr>
                                        <td>LP</td>
                                        <td>Rozpoczęcie</td>
                                        <td>Zakończenie</td>
                                        <td>Temat</td>
                                        <td>Adres</td>                                                               
                                        <td>Informacje dodatkowe</td>                                                                                                                                                                                                                                           
                                    </tr>
                                </thead>
                                <tbody> 
                                     {
                                        this.state.rows.terminy.map( (item,index) => {
                                            return(
                                                <DetTerminyRow num={index+1} key={index} row={item} />
                                            )
                                        } )
                                     }
                                </tbody>    
                                </table>
                            </div>

                    </div>



                      
                    <div>
                        <br/>
                          <FileUploader typ="sk_szkolenia" typ_id={this.state.rows.id} apiKey={this.props.apiKey} onlyDisplay="1"/>
                          <br/>
                    </div>


                 <br/><br/>
                 <br/><br/>
                 <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy szkoleń
                 </div>


                 <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Powiadomienie"/>
           
            </div>
        )
    }
}

export default Det;