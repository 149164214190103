
import React from 'react';
import { Link  } from 'react-router-dom';

import BarTop from '../components/BarTop';
import BarBottom from '../components/BarBottom';

import LogIn from '../views/login/LogIn';
import Register from '../views/login/Register';
import ConfigX from '../ConfigX';
import newIcon from '../image/new_icon.png';
import existIcon from '../image/exist_icon.png';



class Login extends React.Component
 {
  constructor(props)  
  {
    super(props)       
    

    this.state = {
        page: this.props.page,   
        step: 1,
        view: this.props.view ? this.props.view : "choose",
        refresh: 0,  
    }
  
  }


  componentDidMount()
  {
    //console.log("Mount Page Login"  );          
  }

  componentDidUpdate()
  {
    //console.log("Update Page ");   
      
    if(this.state.page != this.props.page)
    {
        this.setState({
            page: this.props.page,                                 
        });
    }


  }

  handler(step)
  {
     this.setState({
        step: step,
     })
  }

  changeViewNew(event)
  {
     this.setState({
      view: "new"
     })
  }
  changeViewExist(event)
  {
     this.setState({
      view: "exist"
     })
  }

  selectLogin(option)
  {    
     // console.log("Select Login " + option); 

      this.setState({
        view: option,
        refresh: this.state.refresh + 1,
      });

  }



  render(){


    document.title = ConfigX.title + " / Moje konto";


    if(this.state.view == 'choose')
    {
      return(
        <div className="App AppLogin">
            <BarTop page={this.state.page} selectLogin={this.selectLogin.bind(this)} />

            <div className='loginWrapper mt-7'>               
               <div className='new_btn'   onClick={this.changeViewNew.bind(this)}><img src={newIcon} />Jestem nowy</div>
               <div className='exist_btn' onClick={this.changeViewExist.bind(this)}><img src={existIcon} />Mam już konto</div>
            </div>       


        </div>
      )
    }


   if(this.state.view == 'new')
   {
      return (
        <div className="App AppLogin">
            <BarTop page={this.state.page} selectLogin={this.selectLogin.bind(this)} />

            <div className='loginWrapper'>               
              <Register step={this.state.step} handler={this.handler.bind(this)} /> 
            </div>       

         
        </div>
      );
   }

   if(this.state.view == 'exist')
   {
      return (
        <div className="App AppLogin">
            <BarTop page={this.state.page} selectLogin={this.selectLogin.bind(this)} />

            <div className='loginWrapper'>               
              <LogIn  step={this.state.step} />
            </div>       

          
        </div>
      );
   }

  /*  if(this.state.step == 1)
    {
      return (
        <div className="App AppLogin">
            <BarTop page={this.state.page}/>

            <div className='loginWrapper'>               
               <Register step={this.state.step} handler={this.handler.bind(this)} /> 
               <LogIn  step={this.state.step} />
            </div>       

            <BarBottom page={this.state.page}/>
        </div>
      );
    }

    if(this.state.step > 1)
    {
      return (
        <div className="App AppLogin">
            <BarTop page={this.state.page}/>

            <div className='loginWrapper'>               
               <Register step={this.state.step} handler={this.handler.bind(this)} /> 
            </div>       

            <BarBottom page={this.state.page}/>
        </div>
      );
    }*/


  }
}

export default Login;
