
import React from 'react';
import ConfigX from '../../ConfigX';

import ItemT from './ItemT';
//import ItemB from './ItemB';
import Search from './Search';
import Details from './Details';
//import DetailsB from './DetailsB';
import { Link } from 'react-router-dom';

class PageTraining extends React.Component
 {

  constructor(props)  
  {
      super(props)    
    
      this.state = {
        training: [],
        badania: [],
        filter: "wszystkie",
        searchFraze: ' ',
       // searchFrazeB: ' ',
       // sk_szkolenia_id: -1,
       // sk_badania_id: -1,
        selected: []     ,
      //  selectedB: []     
      }
  }


  localTraining = []; //przechowaj lokalnie kursy aby je szybciej filtrować
 // localBadania = [];

  componentDidMount()
  {
     // console.log("PageTraining mount");
     
         fetch( ConfigX.restApi + "/common,get_training" )
         .then( res => res.json() )
         .then(json => {
        
              this.localTraining = json;

              this.setState({
                training: this.localTraining
              });

      
         });

        //  fetch( ConfigX.restApi + "/common,get_badania")
        //  .then( res => res.json() )
        //  .then(json => {
        
        //       this.localBadania = json;

        //       this.setState({
        //         badania: this.localBadania
        //       });

        //  });

  
  }

  componentDidUpdate()
  {         
//console.log("komponent zaktualizowany");

    const queryParams = new URLSearchParams(window.location.search);
    var id = queryParams.get('id');
    //var id_badania = queryParams.get('id_b');

   // console.log("Update PageTraining " + this.props.page + " | " + id);  
          
    if(id == null) id = -1;
   // if(id_badania == null) id_badania = -1;

    if(this.state.sk_szkolenia_id != id) 
    {
         // this.setState({sk_szkolenia_id: id});
        this.onClickItem(id);         
    }

  //  if(this.state.sk_badania_id != id_badania) 
 //   {        
  //      this.onClickItemB(id_badania);          
 //   }    

  }

  onSearchVal(val)
  {        
        //console.log("search " + val + "  " + val.length  + " " + this.state.sk_szkolenia_id);
        val = val.trim();

        if(val.length > 1)
        {
         
              var results = this.localTraining.filter(item => {
                     
                  if( item.tytul.toLowerCase().includes( val )) return true;
                  if( item.podmiot_prowadzacy.nazwa.toLowerCase().includes( val )) return true;
              } );
 
            //  var resultsB = this.localBadania.filter(item => {
                     
           //     if( item.tytul.toLowerCase().includes( val )) return true;
           //     if( item.podmiot_prowadzacy.nazwa.toLowerCase().includes( val )) return true;
          //    } );

              this.setState({
                searchFraze:  val,
              //  searchFrazeB:  val,
                training: results,
              //  badania: resultsB,
              //  sk_badania_id: -1,
                sk_szkolenia_id: -1,
                selected: [],
               // selectedB: []
              });
        }else
        {
              this.setState({
                searchFraze: ' ',
              //  searchFrazeB:  ' ',
                training: this.localTraining,
              //  badania: this.localBadania,
              //  sk_badania_id: -1,
                sk_szkolenia_id: -1 ,
                selected: []  ,
              //  selectedB: []          
              });
        }
    

  }


  onClickItem(id)
  {
    if(id > 0)
    {       
      
          var sel = this.localTraining.filter( (item)=> {

              if(item.id == id ) return true;
              return false;
          } )

          sel = sel[0];       
          //console.log(JSON.stringify(sel) );

          this.setState({
          //  sk_badania_id: -1,
            sk_szkolenia_id: id,
            selected: sel,
            //selectedB: []
          });


    }else //zeruj..
    {
      this.setState({
       // sk_badania_id: -1,
        sk_szkolenia_id: -1,
        selected: [],
        //selectedB: []
      });
    }

  }

  // onClickItemB(id_badania)
  // {
  //   if(id_badania > 0)
  //   {       

  //     var sel = this.localBadania.filter( (item)=> {
  
  //       if(item.id == id_badania ) return true; 
  //          return false;
  //       } )
     
  //       sel = sel[0]; 
  
  //       this.setState({
  //           sk_szkolenia_id: -1,
  //           sk_badania_id: id_badania,
  //           selected: [],
  //           selectedB: sel
  //         });


  //   }else //zeruj..
  //   {
  //     this.setState({
  //        sk_szkolenia_id: -1,
  //        sk_badania_id: -1,
  //        selected: [],
  //        selectedB: []
  //     });
  //   }

  // } 

  onClickFilter(event)
  {
    var id = event.target.id;

    this.setState({
      filter: id
    }); 
   
  }



  render()
  {

    //console.log("rows: ",this.state.training);
   //console.log("Szkolenia_id: "+this.state.sk_szkolenia_id);
      var classW = "";
      var classB = "";
      var classS = "";

      var fraz = '';
      if(this.state.searchFraze.length > 1)
      {
          fraz = "(" +  this.state.training.length + ") Znalezione dla frazy: \""+this.state.searchFraze+"\"";
      }
    //  if(this.state.searchFrazeB.length > 1)
   //   {
    //      fraz = "(" +  this.state.badania.length + ") Znalezione dla frazy: \""+this.state.searchFrazeB+"\"";
    //  }


      if(this.state.training.length == 0)
      {
        return(
          <div className='training'>
                <Search onSearchVal={this.onSearchVal.bind(this) } searchFraze={this.state.searchFraze} />
                <h2>Brak aktywnych szkoleń.</h2>
          </div>  
        )      
      }


      //brak wybranych szczegółów szkolenia i badania...
        //if(this.state.sk_szkolenia_id <= 0 && this.state.sk_badania_id <= 0 ) 
        if(this.state.sk_szkolenia_id <= 0 ) 
        {

          if(this.state.filter === 'szkolenia' || this.state.filter === 'usługi')
          {
            var classS = "filter_active";
            var trainings = (
                this.state.training.map( (row,index)=>{
                  return(                            
                      <ItemT key={index} row={row} id={row.id} onClickItem={this.onClickItem.bind(this)}  page="szczegoly_szkolenia"/>                                                                                                             
                  )                 
                })   
            )          
          // }
          // else if(this.state.filter === 'badania')
          // {
          //   var classB = "filter_active";
          //   var researches = (
          //       this.state.badania.map( (row,index)=>{                             
          //         return(                            
          //             <ItemB key={index} row={row} id={row.id} onClickItemB={this.onClickItemB.bind(this)} page="szczegoly_badania" />                                                                            
          //         )                 
          //       }) 
          //   )             
          }else{ //wszystko---------          

            var classW = "filter_active";
            var trainings = (
              this.state.training.map( (row,index)=>{
                return(                            
                    <ItemT key={index} row={row} id={row.id} onClickItem={this.onClickItem.bind(this)}  page="szczegoly_szkolenia"/>                                                                                                             
                )                 
              })   
            )  

            // var researches = (
            //     this.state.badania.map( (row,index)=>{                             
            //       return(                            
            //           <ItemB key={index} row={row} id={row.id} onClickItemB={this.onClickItemB.bind(this)} page="szczegoly_badania" />                                                                            
            //       )                 
            //     }) 
            // )             
            
          }
 
            return(
            <div className='training'>
                  <Search onSearchVal={this.onSearchVal.bind(this) } searchFraze={this.state.searchFraze} />

                  <span>{fraz}</span>
                  <div className='items'>
 
                    {/* <div className='location'>
                      <a href="#" id="wszystkie" onClick={this.onClickFilter.bind(this)} className={classW}>Wszystkie</a>&nbsp;&nbsp;|&nbsp;
                      <a href="#" id="szkolenia" onClick={this.onClickFilter.bind(this)} className={classS}>Szkolenia</a>&nbsp;&nbsp;|&nbsp; 
                      <a href="#" id="badania"   onClick={this.onClickFilter.bind(this)} className={classB}>Badania</a> 
                    </div>    */}

                      {trainings}                                      
                     

                  </div>

            </div>
            )
        }
 //----------------------------------------------------------------
//console.log(this.state.training);

 //wybrano szkolenie---------------------------------------
        if(this.state.sk_szkolenia_id > 0 )
        {
            return(
                     
            <div className='training'>  
   
                  <div className='details'>
                         <Details row={this.state.selected}  onClickItem={this.onClickItem.bind(this)} apiKey={this.props.apiKey} page="szczegoly_szkolenia" />       
                  </div>

            </div>
            )
        }  

//wybrano badanie----------------------
        // if(this.state.sk_badania_id > 0 )
        // {  
          

        //     return(
                 
        //     <div className='training'>  

        //           <div className='details'>
        //                  <DetailsB row={this.state.selectedB}  apiKey={this.props.apiKey} onClickItemB={this.onClickItemB.bind(this)}  page="szczegoly_badania" />       
        //           </div>

        //     </div>
        //     )
        // }         
        
  }
}


export default PageTraining