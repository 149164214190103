import React from 'react';

import checkPNG from '../../../image/check.png'
import nonePNG from '../../../image/none.png'
import infoPNG from '../../../image/info.png'
import { Link } from 'react-router-dom';
import ConfigX from '../../../ConfigX';
 
class TableRow extends React.Component
 {
        constructor(props)
        {
            super(props)
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onRowClick(id);
        }

        render()
        {
         /*   let nip = '';
            var letter = '';
            if( this.props.row.nazwa.length > '0' )
            {
                letter = this.props.row.nazwa.charAt(0);
            }
            if(this.props.row.nip == 0)
            {
                nip = "-";
            }else{
                nip = this.props.row.nip;
            }*/


           // var badania = this.props.row;    
  
            var status = this.props.row.status;

            status = status.replace("_", ' ');

            return (
                  
                        <tr className='' >
                            <td>{this.props.num}</td> 
                            <td>{this.props.row.nazwa} </td>  
                            <td>{this.props.row.tytul}</td>                         
                            <td>{this.props.row.data}</td>                         
                            <td>{status}</td>                         
                        </tr>                 
            )
        }
 }

 export default TableRow;