import React from 'react';
import PropTypes from 'prop-types';
 
class Pagger extends React.Component
 {
        constructor(props)
        {
            super(props) 
        }


        changePage(event)
        {
            var v = event.target.getAttribute("value");
            this.props.changePage(v);
        }

        changeLimit(event)
        {
            var v = event.target.getAttribute("value");
            this.props.changeLimit(v);
        }


        render()
        {           
            var page = parseInt( this.props.page) ;
            var pages = parseInt(this.props.pages );
            var limit = parseInt(this.props.limit);

            if(page > pages)
            {
                page = pages;
            }

            console.log("Pagger: " + page + "/ " + pages + ",  limit " + limit);
 
            var clickablePageLeft = [];
            var clickablePageRight = [];

            var clickableLimit = [];
            clickableLimit.push({
                "num" : 10,
                "class" : "",
            })

            clickableLimit.push({
                "num" : 50,
                "class" : "",
            })

            clickableLimit.push({
                "num" : 100,
                "class" : "",
            })

            clickableLimit.push({
                "num" : 1000,
                "class" : "",
            })

            clickableLimit.push({
                "num" : 10000,
                "class" : "",
            })

            clickableLimit.forEach(element => {
                if(element.num == limit)
                {
                    element.class = "page_info_selected"
                }
            });
            

            
          
                for(var k= parseInt(page-5); k<page; k++)
                {
                    if(k < 1) continue;
                    clickablePageLeft.push(k);
                }
                       
                var max = parseInt(page+1);              
                for(var k=max; k<=pages; k++) 
                {   
                    if(k > parseInt(page + 5) ) continue;
                    clickablePageRight.push(k);
                }
              
                  
            if(pages == 1)    
            {
                return (
                    <div className='pagger'>               
                          <div className='pleft'>                                                                              
                          </div>
                          
                          <div className='pright'>                            
                              Ilość pozycji na stronie:
                                 {
                                    clickableLimit.map(item => {
                                        return (
                                            <div key={item.num}  className={ "page_info " + item.class  } value={item.num} onClick={this.changeLimit.bind(this)}> {item.num} </div>
                                        )
                                    })
                                 }                                                                                      
                          </div>
    
                    </div>
                ) 
            }
          

            return (
                <div className='pagger'>               
                      <div className='pleft'>                                                  
                          {
                            clickablePageLeft.map(item => {
                                return <div key={item} value={ item } className="page_info" title={ "Strona nr " + item } onClick={this.changePage.bind(this)} > {item} </div>
                            } )
                          }
                          <div className="page_info page_info_selected"> {page} </div>
                          {
                            clickablePageRight.map(item => {
                                return <div key={item}  value={ item } className="page_info"  title={ "Strona nr " + item } onClick={this.changePage.bind(this)} > {item} </div>
                            } )
                          }
                      </div>
                      
                      <div className='pright'>                            
                      Ilość pozycji na stronie:
                             {
                                clickableLimit.map(item => {
                                    return (
                                        <div key={item.num}  className={ "page_info " + item.class  } value={item.num} onClick={this.changeLimit.bind(this)}> {item.num} </div>
                                    )
                                })
                             }                                                                                      
                      </div>

                </div>
            )
        }
 }

 Pagger.propTypes = {
    page: PropTypes.number,
    pages: PropTypes.number,
    limit: PropTypes.number,
 }

 export default Pagger;