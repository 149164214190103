import React from 'react'; 

class DetailsPrint extends React.Component
{
  

   render()
   {

    const mystyle = {
        color: "black", 
        padding: "30px",
        fontFamily: "Arial"
      };

     const title = {
          fontSize:"18px"
     } ;

     const pp = {
        fontSize:"14px",
        marginTop: "10px",
        lineHeight: "1.6"
     } ;

     const subTitle = {
        fontSize:"14px",
        marginTop: "10px",
        lineHeight: "1.6",
        fontWeight: "bold"
     } ;

     var info_og   = this.props.row.informacje_ogolne;

     if(info_og.length < 1) info_og = 'Brak informacji od organizatora badania.'

     return (
        <div  className="printView" style={ mystyle } >
            <div>             
                 <h1 style={title}>{this.props.row.tytul}</h1>

                 <div style={subTitle}>Opis procedury:</div> 
                 <p style={pp}>{this.props.row.opis_procedury}</p>

                 <div style={subTitle}>Informacje ogólne:</div> 
                 <p style={pp}>{info_og}</p>


                 <div style={subTitle}>Organizator / Podmiot prowadzący szkolenie:</div> 
                    <p>{this.props.row.podmiot_prowadzacy.nazwa}  </p>
                    <p>{this.props.row.podmiot_prowadzacy.ulica}  {this.props.row.podmiot_prowadzacy.nr_domu}  {this.props.row.podmiot_prowadzacy.nr_mieszkania} {this.props.row.podmiot_prowadzacy.kod_pocztowy} {this.props.row.podmiot_prowadzacy.miasto}  NIP: {this.props.row.podmiot_prowadzacy.nip}  </p>            
                    <p>{this.props.row.podmiot_prowadzacy.telefon} {this.props.row.podmiot_prowadzacy.email} </p>
                 

            </div>
        </div>
     );
   }


}

export default DetailsPrint;