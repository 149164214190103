import React from 'react';
import ConfigX from '../../../ConfigX'

import FirmSelector from '../../../components/FirmSelector';

import TipMessage from '../../../components/TipMessage';

import BackPNG from './../../../image/back.png'
import PrintPNG from './../../../image/print.png'

import loading from '../../../image/loading.gif'
import ButtonConfirm from '../../../components/ButtonConfirm';
import AlertMsg from '../../../components/AlertMsg';
 

import FileUploader from '../../../components/FileUploader';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Messanger from '../../../components/Messanger';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl);




class Det extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            loading: 1,
            search_bar: {                                           
                numer_dokumentu: this.props.search_bar.numer_dokumentu,                                  
                data_wystawienia_od: this.props.search_bar.data_wystawienia_od,                                  
                data_wystawienia_do: this.props.search_bar.data_wystawienia_do,                                  
                nazwisko: this.props.search_bar.nazwisko,                                  
                nazwa_firmy: this.props.search_bar.nazwa_firmy,                                  
                status: this.props.search_bar.status,  
                typ: this.props.search_bar.typ,                                       
                id: this.props.id,                    
            },
            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },
            rows: [],
            result: [],

            data_calc: [],

            nn_numer_dokumentu: '',
            nn_data_wystawienia: '',
            nn_razem_netto: '',
            nn_razem_vat: '',
            nn_razem_brutto: '',
            nn_do_zaplaty: '',
            nn_kwota_rozliczenia: '',

            alertMSG: '',
            alertMSGTime: 0,

            uczestnicy_fraza: '',      
            uczestnicy_status: '',      
            uczestnicy_rows: [],      
               
 
            editor_action: "none", //nie wyświetlaj edytora terminów
            editor_row: { }, //pusta baza dla editora..               
            editor_selected: 0
        };
    }

    onBackClick()
    {
        this.props.redirect("list", this.props.id);
    }

    componentDidMount()
    {
        console.log("Det did mount");
        this.getData();     
    }


      //Pobranie danych
    getData()
      {
  
          //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
          var wait = setTimeout(
               () => {
                  this.setState({ loading: 1 });
               }
              ,500);
  
          var tmpNonRef = JSON.stringify( this.state );
          tmpNonRef = JSON.parse(tmpNonRef);
  
          var dataPost = tmpNonRef.search_bar;
          dataPost.pagger = tmpNonRef.pagger;
  
        
          
         // console.log("Requets " +  JSON.stringify(dataPost) );
  
          fetch( ConfigX.restApi + "/dash,calc" , {
              method: 'POST', 
              body: JSON.stringify(dataPost), 
              headers: {
                  'Content-Type': 'text/html',
                  'X-API-KEY': this.props.apiKey
              }
          })
          .then( res => res.json() )
          .then(json => {
          
           
               clearTimeout(wait);
  
               if(json.rows[0].numer_dokumentu == '?' || json.rows[0].numer_dokumentu == '') json.rows[0].numer_dokumentu = '';

               this.setState({
                  rows: json.rows[0],
                  result: json.result, //zawsze pierwszy indeks
                  loading: 0
               });                                
             
          });
    }

 
  
 

    updateRozliczenie(event)
    {            

        var err = false;

        if(this.state.rows.numer_dokumentu.length < 3 || this.state.nn_numer_dokumentu == 'incorrect') 
        {
            var komunikat = 'Numer dokumentu musi posiadać przynajmniej 3 znaki.';

            var dt = new Date();
            dt = dt.toISOString();
            err = true;

            this.setState({
                alertMSG: komunikat,
                alertMSGTime: dt,                
                nn_numer_dokumentu: "incorrect",
            });

        }

        /*if(this.state.rows.razem_netto.length < 1 || this.state.nn_razem_netto == 'incorrect') 
        {
            var komunikat = 'Kwota do zapłaty jest niepoprawna.';

            var dt = new Date();
            dt = dt.toISOString();
            err = true;

            this.setState({
                alertMSG: komunikat,
                alertMSGTime: dt,                
                nn_razem_netto: "incorrect",
            });            

        }*/
    
       /* if(this.state.rows.razem_vat.length < 1 || this.state.nn_razem_vat == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_razem_vat: "incorrect"
            });
        }*/

       /* if(this.state.rows.razem_brutto.length < 1 || this.state.nn_razem_brutto == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_razem_brutto: "incorrect"
            });
        }*/

        if(this.state.rows.do_zaplaty.length < 1 || this.state.nn_do_zaplaty == 'incorrect') 
        {
            var komunikat = 'Kwota do zapłaty musi zostać wypełniona.';

            var dt = new Date();
            dt = dt.toISOString();
            err = true;

            this.setState({
                alertMSG: komunikat,
                alertMSGTime: dt,                
                nn_do_zaplaty: "incorrect",
            });
        }

        if(this.state.rows.kwota_rozliczenia.length < 1 || this.state.nn_kwota_rozliczenia == 'incorrect') 
        {
            var komunikat = 'Kwota rozliczenia musi zostać wypełniona.';

            var dt = new Date();
            dt = dt.toISOString();
            err = true;

            this.setState({
                alertMSG: komunikat,
                alertMSGTime: dt,                
                nn_kwota_rozliczenia: "incorrect",
            });
        }

        if(this.state.rows.data_wystawienia.length < 7 || this.state.nn_data_wystawienia == 'incorrect') 
        {
            var komunikat = 'Data wystawienia musi zostać wypełniona.';

            var dt = new Date();
            dt = dt.toISOString();
            err = true;

            this.setState({
                alertMSG: komunikat,
                alertMSGTime: dt,                
                nn_data_wystawienia: "incorrect",
            });
        }

        if(err) return;

        var bnText = event.target.innerHTML;
        //event.target.innerHTML = "...";

           var dataPost = {
               id: this.state.rows.id,        
               numer_dokumentu: this.state.rows.numer_dokumentu,
               data_wystawienia: this.state.rows.data_wystawienia,
               razem_netto: this.state.rows.razem_netto,
               razem_vat: this.state.rows.razem_vat,
               razem_brutto: this.state.rows.razem_brutto,
               do_zaplaty: this.state.rows.do_zaplaty,
               kwota_rozliczenia: this.state.rows.kwota_rozliczenia
           };

         //  console.log(JSON.stringify(dataPost) );return;
          

   
           fetch( ConfigX.restApi + "/dash,edit_calc" , {
               method: 'POST', 
               body: JSON.stringify(dataPost), 
               headers: {
                   'Content-Type': 'text/html',
                   'X-API-KEY': this.props.apiKey
               }
           })
           .then( res => res.json() )
           .then(json => {

                 var dt = new Date();
                 dt = dt.toISOString();

                if(json.status == "OK")
                {
                    event.target.innerHTML = bnText;
                                     
                    this.setState({
                       alertMSG: 'Informacje zostały zapisane.',
                       alertMSGTime: dt
                    });
                }else
                {
                    this.setState({
                        alertMSG: 'Wystąpił problem z zapisem danych.',
                        alertMSGTime: dt
                     });  
                }
         
              
           });
    }

    onChangeNumerDokumentu(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["numer_dokumentu"] = v; 

            this.setState( {
                rows: rows,
                nn_numer_dokumentu: ok
            } );

    }  
       
 
    onChangeDataWystawienia(event)
    {
          
      //  console.log('Data wystaweienia ' + event);
        var ok = '';
                
        var v = event;

        if(v != null)
        {
            var yyyy = v.getFullYear();
            var mm = (v.getMonth()+1) ;  if(mm < 10) { mm = "0" + mm; }
            var dd = (v.getDate()) ;  if(dd < 10) { dd = "0" + dd; }
            var hh = (v.getHours()) ;  if(hh < 10) { hh = "0" + hh; }
            var ii = (v.getMinutes()) ;  if(ii < 10) { ii = "0" + ii; }
            v  =   yyyy+ "-" + mm + "-" + dd;
        }else v = '';

            var rows = this.state.rows;
            rows["data_wystawienia"] = v; 

            this.setState( {
                rows: rows,
                nn_data_wystawienia: ok
            } );

    }  
       

    onChangeRazemNetto(event)
    {
            var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["razem_netto"] = v; 

            this.setState( {
                rows: rows,
                nn_razem_netto: ok
            } );

    } 


    onChangeRazemVat(event)
    {
            var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["razem_vat"] = v; 

            this.setState( {
                rows: rows,
                nn_razem_vat: ok
            } );

    } 


    onChangeRazemBrutto(event)
    {
            var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["razem_brutto"] = v; 
            rows["do_zaplaty"] = v; 

            this.setState( {
                rows: rows,
                nn_razem_brutto: ok
            } );

    } 


    onChangeDoZaplaty(event)
    {
            var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["do_zaplaty"] = v; 

            this.setState( {
                rows: rows,
                nn_do_zaplaty: ok
            } );

    } 

    onChangeKwotaRozliczenia(event)
    {
            var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

            var rows = this.state.rows;
            rows["kwota_rozliczenia"] = v; 

            this.setState( {
                rows: rows,
                nn_kwota_rozliczenia: ok
            } );

    } 


    onConfirmSend(type, param, param_id)
    {
       // console.log(type + param + param_id);

       if(type != "yes")  return;       


       var dt = new Date();
       dt = dt.toISOString();

       var err = false;

       if(this.state.rows.numer_dokumentu.length < 3 || this.state.nn_numer_dokumentu == 'incorrect') 
       {
           var komunikat = 'Numer dokumentu musi posiadać przynajmniej 3 znaki.';

           var dt = new Date();
           dt = dt.toISOString();
           err = true;

           this.setState({
               alertMSG: komunikat,
               alertMSGTime: dt,                
               nn_numer_dokumentu: "incorrect",
           });

       }

       if(this.state.rows.do_zaplaty.length < 1 || this.state.nn_do_zaplaty == 'incorrect') 
       {
           var komunikat = 'Kwota do zapłaty musi zostać wypełniona.';

           var dt = new Date();
           dt = dt.toISOString();
           err = true;

           this.setState({
               alertMSG: komunikat,
               alertMSGTime: dt,                
               nn_do_zaplaty: "incorrect",
           });
       }

       if(this.state.rows.kwota_rozliczenia.length < 1 || this.state.nn_kwota_rozliczenia == 'incorrect') 
       {
           var komunikat = 'Kwota rozliczenia musi zostać wypełniona.';

           var dt = new Date();
           dt = dt.toISOString();
           err = true;

           this.setState({
               alertMSG: komunikat,
               alertMSGTime: dt,                
               nn_kwota_rozliczenia: "incorrect",
           });
       }

       if(this.state.rows.data_wystawienia.length < 7 || this.state.nn_data_wystawienia == 'incorrect') 
       {
           var komunikat = 'Data wystawienia musi zostać wypełniona.';

           var dt = new Date();
           dt = dt.toISOString();
           err = true;

           this.setState({
               alertMSG: komunikat,
               alertMSGTime: dt,                
               nn_data_wystawienia: "incorrect",
           });
       }

       if(err) 
       {
            this.setState({
                alertMSG: 'Błąd. Przed wysyłką rozliczenia wypełnij prawidłowo dane w formularzu.',
                alertMSGTime: dt
            });

            return;
       } 

       var dataPost = {
        id: this.state.rows.id,    
       };


       
       fetch( ConfigX.restApi + "/dash,send_calc" , {
        method: 'POST', 
        body: JSON.stringify(dataPost), 
        headers: {
            'Content-Type': 'text/html',
            'X-API-KEY': this.props.apiKey
        }
        })
        .then( res => res.json() )
        .then(json => {

            var dt = new Date();
            dt = dt.toISOString();

            if(json.status == "OK")
            {                                                
                this.setState({
                    alertMSG: 'Brawo! Rozliczenie zostało wysłane',
                    alertMSGTime: dt
                });
            }else
            {
                this.setState({
                    alertMSG: json.param,
                    alertMSGTime: dt
                });  
            }
    
        
        });


    }

 
    render()
    {      
     
        var msg = '';
//console.log(this.state.rows);
        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>                                               
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        }
        
        var dataWystawienia =              
                <div className='FormField inlineField'>
                <label>Data wystawienia<span className='required'>*</span></label>
                <DatePicker              
                 onChange={this.onChangeDataWystawienia.bind(this)}                                      
                 dateFormat="yyyy-MM-dd"
                 locale="pl"
                 className={this.state.nn_data_wystawienia}
                 />
                </div>

        if( this.state.rows.data_wystawienia.length > 0 &&  this.state.rows.data_wystawienia!= '0000-00-00' )
        {
            var dataWystawienia =              
            <div className='FormField inlineField'>
            <label>Data wystawienia<span className='required'>*</span></label>
            <DatePicker
             selected={  new Date(this.state.rows.data_wystawienia) }
             onChange={this.onChangeDataWystawienia.bind(this)}                                      
             dateFormat="yyyy-MM-dd"
             locale="pl"
             className={this.state.nn_data_wystawienia}
             />
            </div>
        }
           

        var dataWyst = this.state.rows.data_wystawienia;
        if(dataWyst == '' || dataWyst == '0000-00-00')
        {
            dataWyst = '---';
        } 

        var pozostalo = parseFloat( this.state.rows.do_zaplaty) - parseFloat(  this.state.rows.kwota_rozliczenia );        
        var formatPozostalo        = parseFloat(pozostalo).toFixed(2); 
        if(formatPozostalo == NaN || formatPozostalo == 'NaN') formatPozostalo = ' -- ';
       
 
        return (
            <div className='details'>
                 <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy rozliczeń
                 </div>                 
                                    
                    <h4>                  
                       {this.state.rows.info}      <br/>                                                
                       Pozostało do rozliczenia: <span className='text_red'>{formatPozostalo} zł.</span>
                    </h4>
                 
        
                    <TipMessage  msg={msg} type="notice" />

                                    

                    <div className='dashContent'>

                        <div className='dashForm'>
                          Firma / Dane do faktury
                        </div> 
                        <div className='info_lab'>                     
                            <div className='cont'>
                                  <div><b>Nazwa podmiotu: </b> {this.state.rows.firma.nazwa}</div>
                                  <div><b>Adres podmiotu: </b> {this.state.rows.firma.ulica} {this.state.rows.firma.nr_domu}/{this.state.rows.firma.nr_mieszkania} </div>
                                  <div><b>Adres cd.: </b>  {this.state.rows.firma.kod_pocztowy} {this.state.rows.firma.miasto} </div>                                 
                            </div>
                            <div className='cont'>
                                  <b>NIP:</b> {this.state.rows.firma.nip} 
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <b>Tel.:</b> {this.state.rows.firma.telefon}
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <b>E-mail:</b> {this.state.rows.firma.email} 
                            </div>
                        </div>                        
                        <br/>


                        <div className='dashForm'>
                           Osoba / Uczestnik
                        </div>  
                        <div className='info_lab'>                            
                            <div className='cont'> 
                               <b>Imię i nazwisko: </b> {this.state.rows.odbiorca.imie}  {this.state.rows.odbiorca.nazwisko} 
                                 &nbsp;&nbsp;&nbsp;&nbsp; 
                                 <b>E-mail:</b> {this.state.rows.odbiorca.e_mail}  
                                 &nbsp;&nbsp;&nbsp;&nbsp;
                                 <b>Tel.:</b> {this.state.rows.odbiorca.telefon}
                            </div>
                        </div>

                    


                    </div>

                  
                    <div className='dashForm'>

                         <div className='FormField'>
                           <h5>Dane dokumentu wystawionego w zewnętrznym systemie.</h5>
                         </div>


                         <div className='FormField inlineField'>
                               <label>Kwota do zapłaty ({this.state.rows.podstawa})<span className='required'>*</span></label>
                               <input type="text" className={"field100 " + this.state.nn_do_zaplaty } value={this.state.rows.do_zaplaty}  col="" onChange={this.onChangeDoZaplaty.bind(this) } />
                        </div>


                        <div className='FormField inlineField'>
                               <label>Kwota rozliczenia<span className='required'>*</span></label>
                               <input type="text" className={"field100 " + this.state.nn_kwota_rozliczenia } value={this.state.rows.kwota_rozliczenia}  col="" onChange={this.onChangeKwotaRozliczenia.bind(this) } />
                        </div>

                       
                        <br/>
                     
                          <div className='FormField inlineField'>
                               <label>Numer dokumentu<span className='required'>*</span></label>
                               <input type="text" className={"field200 " + this.state.nn_numer_dokumentu } value={this.state.rows.numer_dokumentu}  col="tytul" onChange={this.onChangeNumerDokumentu.bind(this) } />
                          </div>
                  
                            {dataWystawienia}

                        <br/>
 

                      
                  

                        <div className='FormField FormButtons'>                           
                            <div className='buttonForm' onClick={this.updateRozliczenie.bind(this)}>zapisz</div>  
 
 
                         </div>

                      
                    </div>                      
               
                    <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy rozliczeń
                 </div>

                 


                    <div>
                        <div/>
                          <FileUploader typ="fak_faktury" typ_id={this.state.rows.id} apiKey={this.props.apiKey} titlex="Załączone faktury / rachunki" />
                        <div/>         
                        <ButtonConfirm   onConfirm={this.onConfirmSend.bind(this) } param="fak_faktura" param_id={this.state.rows.id}  title="Wyślij rozliczenie do uczestnika*" query={"Na pewno chcesz wysłać to rozliczenie do " + this.state.rows.odbiorca.imie + " " + this.state.rows.odbiorca.nazwisko +  " "+ this.state.rows.odbiorca.e_mail  + "?" }  />             
                    </div>

 


                 <br/><br/>
                 <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy rozliczeń
                 </div>


                 <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Powiadomienie"/>
           
            </div>
        )
    }
}

export default Det;