


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/trainings/step1.PNG';
import step2 from '../../helpdesk/images/trainings/step2.PNG';
import step3 from '../../helpdesk/images/trainings/step3.PNG';
import step4 from '../../helpdesk/images/trainings/step4.PNG';
import step4N from '../../helpdesk/images/trainings/step4N.PNG';
import step5 from '../../helpdesk/images/trainings/step5.PNG';
import step6 from '../../helpdesk/images/trainings/step6.PNG';
import step7 from '../../helpdesk/images/trainings/step7.PNG';
import step8 from '../../helpdesk/images/trainings/step8.PNG';
import step9 from '../../helpdesk/images/trainings/step9.PNG';
import step10 from '../../helpdesk/images/trainings/step10.PNG';
 


class SignUpTrainings extends React.Component
 {



  render(){

    return(
        <>
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <div className="imp-note"><p>Uwaga! Na szkolenie nie może zapisać się administrator systemu, więc ta opcja będzie zablokowana. Aby użytkownik mógł zapisać się na szkolenie wchodzimy w listę szkoleń klikając w Menu BKP EDU w miejscu pokazanym na zrzucie ekranu.</p></div>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>Wyświetla nam się lista szkoleń z informacjami podstawowymi. Chcąc zapisać się na szkolenie lub przeczytać więcej informacji o szkoleniu klikamy w przycisk "Więcej informacji", nad każdym przyciskiem widnieje ilość dostępnych miejsc na dane szkolenie.</p>
          
          <img src={step2} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 3</h3>
          <br/>
          <p>Przy tytule szkolenia wyświetla się przycisk z zapisem a poniżej wszystkie niezbędne i szczegółowe informacje o szkoleniu.</p>
          <div className="imp-note"><p>Użytkownik niezarejestrowany / nie zalogowany do systemu może tylko zapoznać się z informacjami o szkoleniu. Użytkownik chcący zapisać się na szkolenie musi najpierw zarejestrować / zalogować się do systemu, na co wskazuje czerwony komunikat.</p></div>

          <img src={step3} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 4</h3>
          <br/>
          <p>Po wyborze opcji "Zapisz się na szkolenie", użytkownik nie zalogowany / niezarejestrowany zostaje przekierowany do okna logowania lub rejestracji. Aby sprawdzić jak to zrobić należy przejść do sekcji Konto. </p>

          <img src={step4N} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 4</h3>
          <br/>
          <p>Logujemy się podając swój adres e-mail i hasło podane podczas rejestracji.</p>

          <img src={step4} alt=""/>

        </section>



        <section id="method" className="section border-no-sec">

          <h3>Krok 5</h3>
          <br/>
          <p>Użytkownika po zalogowaniu przechodzi jeszcze raz przez wcześniejsze kroki do momentu kliknięcia na "Zapisz się na szkolenie" po czym wyskakuje nam okno gdzie wymagane jest wypełnienie wszystkich pól z czerwoną gwiazdką.</p>
          <p>Wyróżnia się dwa typy zapisu na szkolenie:</p>
          <ul className="pro-ul">
            <li><p>Jako osoba prywatna - podajemy tylko dane oosbowe, akceptujemy ewentualne zgody i klikamy "Zapisz się"</p></li>
            <li><p>Jako firma - najpierw podajemy NIP, system wyszukuje czy istnieje już taki w systemie, jeżeli tak to pobierają się dane tej firmy i wypełnia nimi formularz, jeżeli nie to wpisujemy ręcznie własne dane.<br/>
              Należy pamiętać, że NIP musi być prawdziwy i prawidłowy.</p></li>
          </ul>
          <img src={step5} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 6</h3>
          <br/>
          <p>Opcja do zaznaczenia "Członek BKP" określa czy użytkownik jest członkiem Bydgoskiego Klastra czy nie, dzięki temu system wykryje i naliczy zniżki na szkolenia.</p>
          <p>Po wypełnieniu wszystkich danych poprawnie i zaznaczeniu ewentualnych zgód, klikamy "Zapisz się".</p>

          <img src={step6} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 7</h3>
          <br/>
          <p>Administrator wchodząc w menu "Szkolenia" widzi listę wszystkich szkoleń. Klikając w dane szkolenie przechodzi do szczegółów szkolenia, gdzie może edytować wszystkie interesujące go informacje. </p>

          <img src={step7} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 8</h3>
          <br/>
          <p>Po wyświetleniu szczegółów szkolenia widzimy ilość zajętych już miejsc, tytuł, status, cel szkolenia, koszt szkolenia itd. Jeżeli chcemy edytować jakieś informacje, musimy wypełnić wszystkie pola z czerwoną gwiazdką.</p>

          <img src={step8} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 9</h3>
          <br/>
          <p>U dołu strony pojawiąją się kolejne pola. Możemy usunąć szkolenie, zapisać informacje, dodać termin, materiały w postaci plików i inne. Jeżeli chcemy zobaczyć listę uczestników zapisanych na szkolenie, musimy zejść na sam dół strony.</p>

          <img src={step9} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 10</h3>
          <br/>
          <p>W tym miejscu widzimy możliwość dodawania / usuwania postanowień, czyli zgód które będą wyświetlały się podczas zapisywania się uczestnika na szkolenie. </p>
          <p>W bloku "Uczestnicy" widoczna jest lista uczestników zapisana na to szkolenie. Możemy zmieniać im statusy, potwierdzając czy anulując, później zmieniać im frekwencję, czyli czy pojawili się na szkoleniu czy nie.</p>
          <p>Ikonki nad tabelą pozwalają na wygenerowanie plików z informacjami o tych uczestnikach. Typ pliku widoczny jest na ikonie. Ikona drukarki informuje, że wygeneruje nam się plik do wydruku.</p>

          <img src={step10} alt=""/>

        </section>

        </>

    )
  }



}

export default SignUpTrainings;