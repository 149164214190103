
import React from 'react';
import ConfigX from '../../ConfigX';

import ItemT from './ItemT';
import ItemB from './ItemB';
import Search from './Search';
import Details from './Details';
import DetailsB from './DetailsB';
import { Link } from 'react-router-dom';

class PageResearch extends React.Component
 {

  constructor(props)  
  {
      super(props)    
    
      this.state = {
        //training: [],
        badania: [],
        filter: "wszystkie",
       // searchFraze: ' ',
        searchFrazeB: ' ',
        //sk_szkolenia_id: -1,
        sk_badania_id: -1,
        //selected: []     ,
        selectedB: []     
      }
  }


  localBadania = [];//przechowaj lokalnie badania aby je szybciej filtrować

  componentDidMount()
  {
     // console.log("PageResearch mount");     

         fetch( ConfigX.restApi + "/common,get_badania")
         .then( res => res.json() )
         .then(json => {
        
              this.localBadania = json;

              this.setState({
                badania: this.localBadania
              });

         });

  
  }

  componentDidUpdate()
  {         

    const queryParams = new URLSearchParams(window.location.search);
    //var id = queryParams.get('id');
    var id_badania = queryParams.get('id_b');

   // console.log("Update PageTraining " + this.props.page + " | " + id);  
          
   // if(id == null) id = -1;
    if(id_badania == null) id_badania = -1;

    if(this.state.sk_badania_id != id_badania) 
    {
        this.onClickItemB(id_badania);          
    }    

  }

  onSearchVal(val)
  {        
        //console.log("search " + val + "  " + val.length  + " " + this.state.sk_szkolenia_id);
        val = val.trim();

        if(val.length > 1)
        {
         
              var resultsB = this.localBadania.filter(item => {
                     
                if( item.tytul.toLowerCase().includes( val )) return true;
                if( item.podmiot_prowadzacy.nazwa.toLowerCase().includes( val )) return true;
              } );

              this.setState({
                //searchFraze:  val,
                searchFrazeB:  val,
              //  training: results,
                badania: resultsB,
                sk_badania_id: -1,
              //  sk_szkolenia_id: -1,
              //  selected: [],
                selectedB: []
              });
        }else
        {
              this.setState({
                //searchFraze: ' ',
                searchFrazeB:  ' ',
              //  training: this.localTraining,
                badania: this.localBadania,
                sk_badania_id: -1,
               // sk_szkolenia_id: -1 ,
              //  selected: []  ,
                selectedB: []          
              });
        }
    

  }


  onClickItemB(id_badania)
  {
    if(id_badania > 0)
    {       

      var sel = this.localBadania.filter( (item)=> {
  
        if(item.id == id_badania ) return true; 
           return false;
        } )
     
        sel = sel[0]; 
  
        this.setState({
           // sk_szkolenia_id: -1,
            sk_badania_id: id_badania,
           // selected: [],
            selectedB: sel
          });


    }else //zeruj..
    {
      this.setState({
       //  sk_szkolenia_id: -1,
         sk_badania_id: -1,
      //   selected: [],
         selectedB: []
      });
    }

  } 

  onClickFilter(event)
  {
    var id = event.target.id;

    this.setState({
      filter: id
    }); 
   
  }



  render()
  {

  // console.log("Badania: "+this.state.badania);  

      var fraz = '';

      if(this.state.searchFrazeB.length > 1)
      {
          fraz = "(" +  this.state.badania.length + ") Znalezione dla frazy: \""+this.state.searchFrazeB+"\"";
      }


      //brak wybranych szczegółów szkolenia i badania...
        //if(this.state.sk_szkolenia_id <= 0 && this.state.sk_badania_id <= 0 ) 

        if(this.state.badania.length == 0)
        {
          return(
            <div className='training'>
                  <Search onSearchVal={this.onSearchVal.bind(this) } searchFrazeB={this.state.searchFrazeB} />
                  <h2>Brak aktywnych badań.</h2>
            </div>
          )
        }

        if(this.state.sk_badania_id <= 0 ) 
        {

            var researches = (
                this.state.badania.map( (row,index)=>{                             
                  return(                            
                      <ItemB key={index} row={row} id={row.id} onClickItemB={this.onClickItemB.bind(this)} page="szczegoly_badania" />                                                                            
                  )                 
                }) 
            )             
            

 
            return(
            <div className='training'>
                  <Search onSearchVal={this.onSearchVal.bind(this) } searchFrazeB={this.state.searchFrazeB} />

                  <span>{fraz}</span>
                  <div className='items'>
                                                  
                      {researches}   

                  </div>

            </div>
            )
        }
 //----------------------------------------------------------------

//wybrano badanie----------------------

        if(this.state.sk_badania_id > 0 )
        {  
          

            return(
                 
            <div className='training'>  

                  <div className='details'>
                         <DetailsB row={this.state.selectedB}  apiKey={this.props.apiKey} onClickItemB={this.onClickItemB.bind(this)}  page="szczegoly_badania" />       
                  </div>

            </div>
            )
        }         
        
  }
}


export default PageResearch