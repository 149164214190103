import React from "react";


import messagePng from '../image/message.png'
import attachPng from '../image/attach.png'

import ConfigX from "../ConfigX";

class Messanger extends React.Component
{
    myRefname = React.createRef();

    myRefMessage = React.createRef();

    constructor(props)
    {
        super(props)
      

        this.state = {
            user: "0", //czy osoba to użytkownik czy admin
            typ: "",  //nowo dodane, rozróżnić czy badania czy szkolenia
            record_id: "0", //id_rekordu

            view: "empty",
            userId: this.props.user_id,           
            search: "",         
            msgText: "" ,
            msgTitle: "" ,
            nn_msgText: "",
            nn_msgTitle: "",
            loading: 1,         
            rows: [],
            files: [],
            upfile: [],
            allP: 0
        }    
        
        if(this.props.user)
        {
            this.state.user = this.props.user;
        }
        if(this.props.typ)
        {
            this.state.typ = this.props.typ;
        }
        if(this.props.record_id)
        {
            this.state.record_id = this.props.record_id;
        }
    }

    componentDidMount()
    {       
    }

    componentDidUpdate()
    {
         
 
    }


    getData()
    {

        //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
        var wait = setTimeout(
             () => {
                this.setState({ loading: 1 });
             }
            ,500);

      

        var dataPost = {

        };

       var action = "/dash,msg";

       if(this.state.user == '1')
       {
          var action = "/user,msg";

          var dataPost = {
            prowadzacy_id: this.state.userId,
            typ: this.state.typ,
            record_id: this.state.record_id,
        };          
       }

        fetch( ConfigX.restApi + action , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
                  
             clearTimeout(wait);

             var ff = {};


             json.forEach(element => {
                element.wiadomosci.forEach(element2 => {
                    element2.pliki.forEach(plik => {                       
                        var u = plik.uq
                        ff[u] = {};
                        ff[u]['status'] = "";
                        ff[u]['mime'] = ".";
                        ff[u]['b64'] = ".";
                    });
                });
             });

          //   console.log(JSON.stringify(ff) );

             this.setState({
                rows: json,
                files: ff,
                loading: 0
             });  
             
             setTimeout( ()=>{                
                //this.myRefMessage.current.scrollIntoView({ behavior: 'smooth', block: 'end' });

//uwaga!!!!!! zostało dodane bo był błąd-------------------------

               // if(!this.myRefMessage.current) return
              //  if (this.myRefMessage.current.getBoundingClientRect().top <= -580 || null) {

                    const y = this.myRefMessage.current.getBoundingClientRect().top + window.pageYOffset + 20000;
                    this.myRefMessage.current.scrollTo({top: y, behavior: 'smooth'});

              //  }
               // console.log("3333333");
             },200 )
                     
           
        });
  }

    onSearch(event)
    {
        var v = event.target.value;
        this.setState({
            search: v
        });
    }

    onChangeMsg(event)
    {
        var v = event.target.value;
        var ok = "correct";

        if(v.length < 2) { ok="incorrect"; }

        this.setState({
            msgText: v,
            nn_msgText: ok
        });
    }

    onChangeTitle(event)
    {
        var v = event.target.value;
        var ok = "correct";

        if(v.length < 2) { ok="incorrect"; }

        this.setState({
            msgTitle: v,
            nn_msgTitle: ok
        }); 
    }

    open()
    {
        this.setState({
            view: "show",
            userId: this.props.user_id            
        });

        this.getData();
    }

    close()
    {
        this.setState({
            view: "empty",
            userId: -1            
        });
    }

    changeUser(event)
    {
        var id = event.target.getAttribute("user_id");
       // console.log("Wybrano " + id);

        this.setState({
            userId: id
        });
    }


    wyslijWiadomosc(event)
    {
        var err = false;

            if(this.state.msgText.length < 2)
            {
                this.setState({
                    nn_msgText: "incorrect"
                });
                err = true;
            }

            if(this.state.msgTitle.length < 2)
            {
                this.setState({
                    nn_msgTitle: "incorrect"
                });
                err = true;
            }

            if(err)return;



            event.target.innerHTML = "...";

            var pl = new Array();

            this.state.upfile.forEach(item => {
            
                pl.push(
                    {
                        uq: item.uq,
                        nazwa_wlasna: "",
                        typ: "",
                        created_at: "",
                        rozmiar: ""  
                    }
                )
            })

            var dataPost = {
                odbiorca_id: this.state.userId,
                tytul: this.state.msgTitle,
                tresc: this.state.msgText,
                pliki: pl,
            };
    
            fetch( ConfigX.restApi + "/dash,new_msg" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
              
                if(json.status == "OK") //deleted on server..
                {
                    event.target.innerHTML = "Wyślij E-mail";

                    this.setState({
                        msgTitle: "",
                        nn_msgTitle: "",
                        msgText: "",
                        nn_msgText: "",
                        upfile: []
                    });                  
                    this.getData();
                }
                else
                {
                    event.target.innerHTML = "Błąd!";
                }
              
            });

    }

    ////////////////////////////////////////////////
    formatSizeUnits(bytes){
        if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
        else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
        else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
        else if (bytes > 1)           { bytes = bytes + " b "; }
        else if (bytes == 1)          { bytes = bytes + " b "; }
        else                          { bytes = "0 b "; }
        return bytes;
      }


    onDownload(event)
    {
        var uq = event.currentTarget.getAttribute("uq");
        var name = event.currentTarget.getAttribute("nx");
        var typ = event.currentTarget.getAttribute("typ");
        var typ_id = event.currentTarget.getAttribute("typ_id");
        console.log("Download: " + uq +  " n " + name);

        if(this.state.files[uq] && this.state.files[uq].b64.length > 3)
        {
            return;
        }


        var ff = JSON.stringify(this.state.files);
        ff = JSON.parse(ff);
        ff[uq]['status'] = 'wait';            
        this.setState(
            {
                files: ff
            }
        );



        var dataPost = {
            uq: uq,
            typ: typ,
            typ_id: typ_id,
        };

        fetch( ConfigX.restApi + "/dash,file_download" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
          
            if(json.status == "OK") //deleted on server..
            {                                           
                //ff[ix]['down_end'] = new Array();
                //ff[ix]['down_end']['b64'] =  json.b64 ;
                //ff[ix]['down_end']['mime'] = json.mime;
                //this.setState( { files: ff } );

                var ff = JSON.stringify(this.state.files);
                ff = JSON.parse(ff);
                ff[uq]['status'] = 'ready';
                ff[uq]['b64'] = json.b64;
                ff[uq]['mine'] =json.mime;

                this.setState(
                    {
                        files: ff
                    }
                );
            }
          
        });

    }


    handleClick = () => {
        this.myRefname.current.click();
     }


     onChangeInput(event)
     {
         var handle = event.target;
         
         console.log("FileUploader, selected: " + handle.files.length );
 
         if( handle.files.length == 0) return false;     
         
         
         for(var i = 0; i < handle.files.length; i++)  
         {  
             var file = handle.files[i];
             var isAdded = false;
 
             this.state.upfile.forEach( exFile => {
                  if(exFile.name == file['name']) isAdded = true;
             });
 
             if(isAdded) continue;
 
                 var randName = crypto.getRandomValues( new Uint32Array(1)) + file['name'];
 
                 var item = {
                     name: file['name'],                
                     progress: 0,
                     status: 'start',
                     uq: randName,
                     size: '0',
                     time: '',
                 }
                 var list = this.state.upfile;
                 list.push(item);
                 this.setState({ upfile: list});
 
                 this.startUpload(file, list.length-1, randName );
         }        
 
     }


     odswiezProcent()
     {
        var proc = 0;


            this.state.upfile.forEach(item => { 
                    proc = proc + item["'progress"]
            })

            proc = proc / this.state.upfile.length;

            this.setState({
                allP: proc
            });
     }

     startUpload(file, tabIndex, randName)
     {        
         var refState = this.state;
         var refOd = this.odswiezProcent.bind(this);
 
 
             var xhr = new XMLHttpRequest();           
             xhr.upload.addEventListener('progress',function(ev)
             {        
                 var procent = (ev.loaded/ev.total );   
                 procent *= 100;
                 procent = Math.round(procent) ; 
                 
                 refState.upfile[tabIndex]['progress'] = procent;
                 refState.upfile[tabIndex]['status'] = "upload";
          
                 refOd();
                 
             }, false)
 
 
             xhr.addEventListener("load",  function(ev){               
             } , false);
 
             xhr.addEventListener("error", function(ev){}, false);
             xhr.addEventListener("abort", function(ev){}, false);
 
             xhr.onreadystatechange = function(ev)
             {                  
                   if (xhr.readyState == 4) 
                    {                                                                       
                       refState.upfile[tabIndex]['status'] = "finish";              
                       refState.upfile[tabIndex]['progress'] = 100;                    
                                           
                       var json = JSON.parse(this.responseText);
                       if(json.status && json.status == 'OK')
                       {
                         refState.upfile[tabIndex]['size'] = json.size;  
                         refState.upfile[tabIndex]['time'] = json.time;  
                         refState.upfile[tabIndex]['id'] = json.id;      
               
                         refOd();
                       }            
 
                       //refRefreshProgress();
                    }
             };
 
             xhr.open('POST', ConfigX.restApi + "/dash,file_upload", true);  
             xhr.setRequestHeader('X-API-KEY', this.props.apiKey); 
 
             var data = new FormData();      
             data.append('file'+tabIndex, file ); 
             data.append('typ', "none");
             data.append('typ_id', "-1");
             data.append('fileUploader','yes');
             data.append('uq', randName);
             xhr.send(data);
 
     }
     

     deleteFile(event)
     {      
        var uq = event.currentTarget.getAttribute("uq");
        console.log("FileUploader, delete: " + uq);


        var dataPost = {
            uq: uq,
            typ: "none",
            typ_id: "-1",
        };

        fetch( ConfigX.restApi + "/dash,file_delete" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
          
            if(json.status == "OK") //deleted on server..
            {
                var files = this.state.upfile.filter( item => {
                    if(item.uq == uq) return false;
                    return true;
                } );

                this.setState({
                    upfile: files
                })
            }
          
        });
     }
    ////////////////////////////////////////////////    

    render()
    {
        if(this.state.view == "empty")
        {
            return (
                <sup className="messanger d-flex" title="Komunikator" onClick={this.open.bind(this)}>
                     <img className="startIco" src={messagePng} />
                </sup>
            )
        }
   

        var msgSelected = [];
        var titleX = 'Wybierz adresata wiadomości!';        
    

//console.log(JSON.stringify(this.state.upfile) );

        if(this.state.userId > 0)
        {           

                for(var m=0; m<this.state.rows.length; m++)
                {
                    if(this.state.rows[m].korespondent.id == this.state.userId )
                    {
                        msgSelected = this.state.rows[m].wiadomosci;
                        titleX = "Nowa wiadomość  " + this.state.rows[m].korespondent.nazwisko + " " + this.state.rows[m].korespondent.imie + " / " + this.state.rows[m].korespondent.e_mail;                       
                        break;
                    }
                }
        }

        
   
    //console.log(JSON.stringify( this.state.upfile ) );


        return (
            <sup className="messanger " >
                 <img className="startIco" src={messagePng} />

                 <div className='info_shadow'> 
                    <div className="dialogMessanger"> 
                        <div className="persons">
                            <input type="text" placeholder="Wyszukaj osobę" value={this.state.search}  onChange={this.onSearch.bind(this) }/>
                            <ul>                                

                                {
                                    this.state.rows.map( item => {

                                        var render = true;

                                        if(this.state.search.length > 0)
                                        {
                                            render = false;

                                            if(item.korespondent.nazwisko.toLowerCase().includes(this.state.search.toLowerCase()) ) render =  true;
                                            if(item.korespondent.imie.toLowerCase().includes(this.state.search.toLowerCase()) ) render =  true;
                                        }

                                        if(render == false)
                                        {
                                            return (null)
                                        }

                                        var clsSelected = ""

                                        if( item.korespondent.id == this.state.userId)
                                        {
                                            clsSelected = "selected";
                                        }

                                        return (
                                            <li  className={clsSelected}  key={item.korespondent.id} user_id={item.korespondent.id} onClick={this.changeUser.bind(this) }   >
                                                 {item.korespondent.nazwisko} {item.korespondent.imie} 
                                                 <br />
                                                 {item.korespondent.e_mail}
                                            </li>
                                        )                                        
                                    } )
                                }

                            </ul>
                        </div>

                        <div className="tabs">
                              <div className="chat">
                                   <div className="title">&nbsp;&nbsp; {titleX}</div>                                    
                                    <div className="messages" id="messages"  ref={this.myRefMessage} >
                                           {
                                              msgSelected.map(item => {

                                                var kierunek = "Wysłana:";
                                                if(item.kierunek == 1) kierunek = "Odebrana:"

                                                return (
                                                    <div className="msgItem" key={item.id}>
                                                      <div className="msgtitle" key={item.id}><b>{kierunek}</b> {item.data} {item.tytul}</div>
                                                        <div className="msgTxt" >
                                                          <p>
                                                            {item.tresc}
                                                          </p> 
                                                          <br />
                                                          <div >                                                      
                                                          {

                                                            item.pliki.map(plik => {

                                                                var size = this.formatSizeUnits(plik.rozmiar);                            

                                                                var goDow = (null)
                                                                var b64 = this.state.files[plik.uq]['b64'];
                                                                var mine = this.state.files[plik.uq]['mine'];
                                                                var status = this.state.files[plik.uq]['status'];

                                                                if(status == 'wait')
                                                                {
                                                                    var goDow = (<span key={item.id} className="text_bold text_link_red">czekaj...</span>)
                                                                }else
                                                                if(status == 'ready')
                                                                {
                                                                    goDow = <a  key={item.id} className="text_bold text_link_red" target="_blank"   href={"data:"+ mine + ";base64, " + encodeURI(b64) }>  otwórz plik </a>
                                                                }else
                                                                {

                                                                }

                                                                return (
                                                                    <div key={item.id}>
                                                                    &nbsp;
                                                                        <span  className="text_link"  uq={plik.uq}  nx={plik.nazwa_wlasna} typ="sk_wiadomosci" typ_id={item.id}  onClick={this.onDownload.bind(this) }  > {plik.nazwa_wlasna} [{size}] </span>
                                                                        {goDow}
                                                                    &nbsp;
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                          </div>
                                                       </div>
                                                    </div>
                                                )

                                              })
                                           } 
                                    </div>
                              </div>

                              <div className={"send "  }>                                       
                                        <div>
                                            <input type="text" value={this.state.msgTitle} className={this.state.nn_msgTitle}  placeholder="Temat:" onChange={this.onChangeTitle.bind(this) }  />
                                        </div>
                                        <textarea value={this.state.msgText} className={this.state.nn_msgText} placeholder={ "Napisz wiadomość " } onChange={this.onChangeMsg.bind(this)}></textarea>

                                        <div className="attach" >                                                                      
                                             <span>
                                                {
                                                    this.state.upfile.map(item=>{

                                                        return (
                                                            <span className="attach_item" >                                                                                                                                                                                                   
                                                                  {item.name} {item.progress} %
                                                                  <sup className="text_link_red"  uq={item.uq} onClick={this.deleteFile.bind(this)}>[usuń]</sup>                                                                 
                                                            </span>
                                                        )
                                                    })
                                                }
                                             </span>
                                             <br/><br/>
                                             <span className="text_link text_link_red"   onClick={this.handleClick.bind(this) }  >Dodaj załącznik</span>
                                             <input type="file" ref={this.myRefname}   multiple id="fileselect" name="fileselect[]"  accept="*/*" onChange={this.onChangeInput.bind(this)}  />  
                                        </div>

                                        <div className="buttonM">                                                                                                          
                                            <div className="buttonForm" title="Wyślij wiadomość" onClick={this.wyslijWiadomosc.bind(this)}>Wyślij E-mail</div>
                                            &nbsp;
                                            <div className="buttonForm" title="Opuść komunikator" onClick={this.close.bind(this)}>Zamknij</div>
                                            &nbsp;
                                        </div>
                              </div>
                        </div>

                    </div>
                </div>
            </sup>
        )
    }
}


export default Messanger