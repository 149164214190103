import React from 'react';

 
class DetTerminyRow extends React.Component
 {
        constructor(props)
        {
            super(props)
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onClickTermin(id);
        }

        render()
        {
            
            return (                 
                        <tr className='rowLink' >
                             <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.num}</td>
                             <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="text_center">{this.props.row.data_od}</td>                                                      
                             <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="text_center">{this.props.row.data_do}</td>                                                      
                             <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.row.temat}</td>                                                      
                             <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.row.ulica} {this.props.row.nr_domu} / {this.props.row.nr_mieszkania} {this.props.row.kod_pocztowy} {this.props.row.miasto}, {this.props.row.nr_nazwa_pomieszczenia}   </td>                                                                     
                             <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.row.osoba_prowadzaca_inne}</td>  
                             <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.row.informacje_dodatkowe}</td>                                                                        
                        </tr>                 
            )
        }
 }

 export default DetTerminyRow;