import React   from 'react';
import ConfigX from '../../../ConfigX';

import TipMessage    from '../../../components/TipMessage';
import loading       from '../../../image/loading.gif';
import ButtonConfirm from '../../../components/ButtonConfirm';
import AlertMsg      from '../../../components/AlertMsg';
import BackPNG from './../../../image/back.png'
import FileUploader from '../../../components/FileUploader';

class Det extends React.Component
{
    constructor(props) 
    {
        super(props)

        this.state = {
            loading: 1,

            rows: [],
            alertMSG: '',
            alertMSGTime: 0,

            nn_login: "",
            nn_haslo: "",
            nn_haslo2: "",

            editor_action: "none", //nie wyświetlaj edytora terminów
            editor_row: { }, //pusta baza dla editora..               
            editor_selected: 0
        };
    }

    componentDidMount()
    {
        console.log("Det did mount");
        this.getData();
    }


      //Pobranie danych
    getData()
      {
  
          //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
          var wait = setTimeout(
               () => {
                  this.setState({ loading: 1 });
               }
              ,500);

          var tmpNonRef = JSON.stringify( this.state );
          tmpNonRef = JSON.parse(tmpNonRef);

          var dataPost = {
            "px_user_id": this.state.px_user_id
          }

      //    var dataPost = tmpNonRef.search_bar;
      //    dataPost.pagger = tmpNonRef.pagger;

          //console.log("Requets " +  JSON.stringify(dataPost) );
  
          fetch( ConfigX.restApi + "/user,get_data_user" , {
              method: 'POST',  
              body: JSON.stringify(dataPost), 
              headers: {
                  'Content-Type': 'text/html',
                  'X-API-KEY': this.props.apiKey
              }
          })
          .then( res => res.json() )
          .then(json => {
          
               clearTimeout(wait);
 
               this.setState({
                  rows: json.rows[0],                
                  loading: 0
               });      
             
          });
    }


    onChangeLogin(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["login"] = v; 

            this.setState( {
                rows: rows,
                nn_login: ok
            } );

    }  
       

    onChangeHaslo(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 6)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["haslo"] = v; 

            this.setState( {
                rows: rows,
                nn_haslo: ok
            } );

    }  

    onChangeHaslo2(event)
    {
            var v   = event.target.value;
            var ok = "";
              

            var rows = this.state.rows;
            rows["haslo2"] = v; 

            this.setState( {
                rows: rows,
                nn_haslo2: ok
            } );
    }  
 

    updateDane(event)
    {            

        var err = false;
         
        if(this.state.rows.login == undefined)
        {
           var rows = this.state.rows;
           rows['login'] = this.state.rows.username;

           this.setState({
                rows: rows,
            }); 
        }

        if(this.state.rows.login.length < 2 || this.state.nn_login == 'incorrect') 
        {         
            err = true;
            this.setState({
                nn_login: "incorrect"
            });
        }

        if(this.state.rows.haslo.length < 8 || this.state.nn_haslo == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_haslo: "incorrect"
            });
        }

        if(this.state.rows.haslo2.length < 8 || this.state.nn_haslo2 == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_haslo2: "incorrect"
            });
        }

        if(err) return;

        var bnText = event.target.innerHTML;
        event.target.innerHTML = "...";

           var dataPost = {
               id:     this.state.rows.id, //px_user_id
               login:  this.state.rows.login,
               haslo:  this.state.rows.haslo,
               haslo2: this.state.rows.haslo2,
               sk_firmy_id: this.state.rows.sk_firmy_id,
               px_group_id:  this.state.rows.px_group_id
           };
   
           fetch( ConfigX.restApi + "/user,edit_data_user" , {
               method: 'POST', 
               body: JSON.stringify(dataPost), 
               headers: {
                   'Content-Type': 'text/html',
                   'X-API-KEY': this.props.apiKey
               }
           })
           .then( res => res.json() )
           .then(json => {

                 var dt = new Date();
                 dt = dt.toISOString();

                if(json.status == "OK")
                {
                    event.target.innerHTML = bnText;
                                     
                    this.setState({
                       alertMSG: 'Brawo! Informacje zostały zapisane.',
                       alertMSGTime: dt
                    });
                }else
                {
                    this.setState({
                        alertMSG: 'UPS! Wystąpił problem z zapisem danych.',
                        alertMSGTime: dt
                     });  
                }
         
              
           });
    }



    render()
    {      
      
        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>                                               
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        } 

 
        const pageStyle = `
        @page {
          size: 210mm 297mm;
        }
      
        @media all {
          .pagebreak {
            display: none;
          }
        }
      
        @media print {
          .pagebreak {
            page-break-before: always;
          }
        }
      `;
      
 
        return (

            <div className=''>
                    <div className="row mt-3">   
                        <div className="col-md-12"><label className="labels">Login (e-mail)<span className='required'>*</span></label>
                            <input type="text" className={"form-control " + this.state.nn_login } defaultValue={this.state.rows.username}  col="tytul" onChange={this.onChangeLogin.bind(this) } />
                        </div>
                        <div className="col-md-12"><label className="labels">Hasło (min. 8 znaków, min. 1 cyfra, min. 1 litera)<span className='required'>*</span></label>
                            <input type="password" className={"form-control " + this.state.nn_haslo} defaultValue={this.state.haslo}  col="haslo" onChange={this.onChangeHaslo.bind(this) }    />
                        </div>
                        <div className="col-md-12"><label className="labels">Powtórz hasło <span className='required'>*</span></label>
                            <input type="password" className={"form-control " + this.state.nn_haslo2} defaultValue={this.state.haslo2}  col="haslo2" onChange={this.onChangeHaslo2.bind(this) }    />
                        </div>
                    </div>
                    
                    {/* <div  className="col-md-12">
                        <FileUploader typ="logo" typ_id={this.state.rows.sk_firmy_id} apiKey={this.props.apiKey} deleteIcon="off" titlex='Wybierz logo' />
                    </div> */}             

                    <div className="mt-5 text-center">
                        <button className="btn btn-primary" type="button" onClick={this.updateDane.bind(this)}>Zapisz dane</button>
                    </div>

                <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Powiadomienie"/>
            </div>
        
        )
    }
}

export default Det;