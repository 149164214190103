

import React from "react";

class Kontakt extends React.Component
{
 
    constructor(props)
    {
        super(props)
    }
 

  render(){
      
       return (
            <div class="info-wrap bg-primary w-100 p-lg-5 p-4">
                <br></br>
                <h2 class="mb-4 mt-md-4 align-center">
                    <b>Stowarzyszenie Bydgoski Klaster Przemysłowy Dolina Narzędziowa</b>
                    <h3>Informacje kontaktowe</h3>
                </h2>
                <div class="dbox w-100 d-flex align-items-start">
                <div class="icon d-flex align-items-center justify-content-center">
                    <span>Adres:</span> 
                </div>
                <div class="text pl-3">
                    <p>Bydgoski Klaster Przemysłowy Dolina Narzędziowa
                    ul. Bydgoskich Przemysłowców 6
                    85-862 Bydgoszcz
                    <br/>
                    Piotr Wojciechowski - Dyrektor Zarządzający</p>
                </div>
                </div>
                <div class="dbox w-100 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <span>Telefon:</span>
                    </div>
                    <div class="text pl-3">
                        <p> +48 52 515 30 73</p>
                    </div>
                </div>
                <div class="dbox w-100 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <span>Email:</span>
                    </div>
                    <div class="text pl-3">
                        <p> bkp@klaster.bydgoszcz.pl</p>
                    </div>
                </div>
                <div class="dbox w-100 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <span>Strona WWW: </span>
                    </div>
                    <div class="text pl-3">
                        <p> <a href="https://klaster.bydgoszcz.pl/">https://klaster.bydgoszcz.pl</a></p>
                    </div>
                </div>
                <div class="dbox w-100 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <span>Godziny pracy biura: </span>
                    </div>
                    <div class="text pl-3">
                        <p> poniedziałek - piątek 8.00-16.00</p>
                    </div>
                </div>  
                <div class="dbox w-100 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <span>Zarząd: </span>
                    </div>
                    <div class="text pl-3">
                        <p> <br/>
                            Katarzyna Meger - Prezes<br/>
                            Dariusz Sykutera - Wiceprezes<br/>
                            Marek Bieliński - Członek<br/>
                            Tadeusz Konek - Członek<br/>
                            Marcin Kropidłowski - Członek<br/>
                            Grzegorz Kapka - Członek<br/>
                            Maciej Mazurkiewicz - Członek</p>
                    </div>
                </div>                

            </div>
       );

  }
}

export default Kontakt