import React from "react";

import loading from '../image/no_data.png'

class EmptyTab extends React.Component
{
    render()
    {
        return (
            <div className="emptyResult">
                <img src={loading} />
                <br/>
                <br/>
                {this.props.msg}
            </div>
        )
    }
}


export default EmptyTab