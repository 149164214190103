import React from "react";


class InvoiceData extends React.Component
{


 

 render()
 {



    const mystyle = {
        color: "black", 
        padding: "30px",
        fontFamily: "Arial",
        fontSize: "10px",
        fontWeight:"normal"    
      };

      const styleTab = {
        width: "100%",
        fontSize: "12px",
      }


      const styleTr = {
        backgroundColor: "#ddffdd"
      }

    return (
        <div  className="printView" style={ mystyle } >
             
            <h3>Dane do faktur {this.props.title} </h3>


            <table style={styleTab}>
                        <thead>
                            <tr>
                                <td>LP</td>
                                <td>Imię nazwisko</td>
                                <td>Nazwa firmy</td>
                                <td>NIP</td>
                                <td>Adres</td>
                                <td>Kod pocztowy</td>                                                                                                           
                                <td>Miasto</td>                                                                                                           
                                <td>Telefon</td>                                                                                                           
                                <td>Email</td>                                                                                                           
                            </tr>
                        </thead>
                        <tbody> 

                          {
                           /*
                              this.props.rows.dane_firmy.map( (item,index)   => {

                                if(this.props.rows.dane_firmy.length > 0)
                                {
                                      return (
                                          <tr key={index} style={styleTr}>
                                              <td>{index + 1 }</td>
                                              <td>{item.imie} {item.nazwisko}</td>
                                              <td>{item.nazwa}</td>
                                              <td>{item.nip}</td>
                                              <td>{item.ulica} {item.nr_domu} {item.nr_mieszkania}</td>
                                              <td>{item.kod_pocztowy}</td>
                                              <td>{item.miasto}</td>
                                              <td>{item.telefon}</td>
                                              <td>{item.email}</td>
                                          </tr>
                                      )
                                }
                              })*/
                           
                        }

                        </tbody>    


                        </table>

        </div>
    )
 }
}


export default InvoiceData