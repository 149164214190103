import React from 'react';
import ConfigX from '../../ConfigX';
import {Navigate} from 'react-router-dom';
 
import { isValidElement } from 'react';


class LogIn extends React.Component
 {
      constructor(props)
      {
        super(props)


        this.state = {
            username: "",
            username_ok: "",
            username_err: "",
            password: "",
            password2: "",
            password_ok: "",
            password_err: "",
            password2_ok: "",
            password2_err: "",
            driver_name: "PC",
            driver_os: "??",
            redirect: "",

            code: "",
            code_ok: "",
            code_err: "",

            view: this.props.view ? this.props.view : "login",
            step: this.props.step
        };


      

      }

      goLogin()
      {
        //event.preventDefault();

        var data = {
            driver_name: "PC",
            driver_os: "??",
            username: this.state.email,
            password: this.state.password
        }

        //spróbuj się zalogować...
       
        fetch( ConfigX.restApi + '/rest,go_login', {
            method: 'POST', 
            body: JSON.stringify(this.state), 
            headers: {
                'Content-Type': 'text/html'
            }
         })
         .then(res => res.json() )
         .then(res => {

               var apiKey = res.apiKey;

               if(apiKey.length > 5 ) //jest apiKey - jest zalogowany..
               {
                     this.saveProfileToLocalStoge(apiKey) ;
                     return;
               }else
               {
                    this.setState({
                        username_err: "Podaj prawidłowe dane"
                    })
               }

         } );
           
       

        /* fetch( ConfigX.restApi + '/rest,go_login', {
            method: 'POST', 
            body: JSON.stringify(this.state), 
            headers: {
                'Content-Type': 'text/html'
            }
         })
         .then(res => res.json() )
         .then(res => {

               var apiKey = res.apiKey;

               if(apiKey.length > 5 ) //jest apiKey - jest zalogowany..
               {
                     this.saveProfileToLocalStoge(apiKey) ;
                     return;
               }else
               {
                    this.setState({
                        username_err: "Podaj prawidłowe dane"
                    })
               }

         } );*/


      }



      isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

      isValidPassword(password)
      {
         if(password.length < 6) return false;
         return true;
      }

      onChangeUsername(event)
      {                     
            //---------------
            this.setState({
                username: event.target.value,
                username_ok: "",
                username_err: "",
            });
      }

      onChangePassword(event)
      {
         this.setState({
            password: event.target.value,
            password_ok: "",
            password_err: "",
         });
      }


      onChangeCode(event)
      {
         this.setState({
            code: event.target.value,
            code_ok: "",
            code_err: "",
         });
      }

      onChangePassword2(event)
      {
         this.setState({
            password2: event.target.value,
            password2_ok: "",
            password2_err: "",
         });
      }


      onSubmitForm(event)
      {
        event.preventDefault();

        //console.log( JSON.stringify(this.state) );

        if(this.state.username == null || this.state.username == 'undefinded')
        {
            return;
        }

        if(!this.isValidEmail(this.state.username) )
        {
            this.setState({
                username_err: "Podaj prawidłowy adres e-mail"
            })
            return;
        }

        if(!this.isValidPassword(this.state.password) )
        {
            this.setState({
                password_err: "Podaj hasło"
            })
            return;
        }

        //spróbuj się zalogować...
       
        this.goLogin();
                       

      }


      onSubmitFormRemindStep1(event)
      {
        event.preventDefault();
 

        if(!this.isValidEmail(this.state.username) )
        {
            this.setState({
                username_err: "Podaj prawidłowy adres e-mail"
            })
            return;
        }            
       
        fetch( ConfigX.restApi + '/rest,pass_back', {
            method: 'POST', 
            body: JSON.stringify(this.state), 
            headers: {
                'Content-Type': 'text/html'
            }
         })
         .then(res => res.json() )
         .then(res => {
           
             if(res.status == 'OK')
             {
                this.setState({
                    view: "remind_code" 
                })
                return;
             }else
             {
                this.setState({
                    //username_err: "Podaj prawidłowy adres e-mail"
                    username_err: res.param
                })
                return;
             }

         } );
                       

      }


      onSubmitFormRemindStep2(event)
      {
        event.preventDefault();

        var err = false;
 

        if(!this.isValidEmail(this.state.username) )
        {
            this.setState({
                username_err: "Podaj prawidłowy adres e-mail"
            })
            err = true;
        }    
        
        if(this.state.code.length < 4)
        {
            this.setState({
                code_err: "Podaj prawidłowy kod"
            })
            err = true;
        }
      

        var lowercase = /[a-z]/.test(this.state.password);
        var uppercase = /[A-Z]/.test(this.state.password);
        var numbers = /[0-9]/.test(this.state.password);    
        if(!lowercase) { this.setState({ password_err: "Min. jedna mała litera" }); err = true; }
        if(!uppercase) { this.setState({ password_err: "Min. jedna duża litera" }); err = true; }
        if(!numbers)  { this.setState({ password_err: "Min. jedna cyfra" }); err = true; }

        if(this.state.password.length < 2) { this.setState({ password_err: "Podaj hasło" }); err = true; }
        if(this.state.password2.length < 2) { this.setState({ password2_err: "Podaj hasło" }); err = true; }

        if(this.state.password != this.state.password2) { this.setState({ password2_err: "Hasła się różnią" }); err = true; };

        if(err) return;

       
        fetch( ConfigX.restApi + '/rest,pass_code', {
            method: 'POST', 
            body: JSON.stringify(this.state), 
            headers: {
                'Content-Type': 'text/html'
            }
         })
         .then(res => res.json() )
         .then(res => {
           
             if(res.status == 'OK')
             {
                this.setState({
                    view: "success_pass"
                })
                return;
             }else
             {
                this.setState({
                    code_err: "Podaj prawidłowy kod"
                })
                return;
             }

         } );
                       

      }


      saveProfileToLocalStoge(apiKey)
      {
                fetch( ConfigX.restApi + '/rest,get_profile', {
                    method: 'POST', 
                    body: JSON.stringify(this.state), 
                    headers: {
                        'Content-Type': 'text/html',
                        'X-API-KEY': apiKey
                    }
                })
                .then(res => res.json() )
                .then(res => {

                    var usr = res.usr;

                    res.apiKey = apiKey;
                    
                    if(usr != null && usr != 'undefinded' && usr.length > 1)
                    {
                       window.localStorage.setItem('user', JSON.stringify(res));

                       //redirect..  :)

                       if(usr.usr == "ADMIN") 
                       {                          
                                this.setState({ 
                                    redirect: ConfigX.pathDef + "dashboard"
                                    });

                       }else
                       {
                                this.setState({ 
                                    redirect: ConfigX.pathDef 
                                    });
                       }
                    }

                } );
      }

      countAndLogin() 
      {       
        let id = setTimeout(()=> this.goLogin() , 3000);             
       // clearTimeout(id);            
      }

      showLogin()
      {        
         this.setState({
            view: "login"
         });
      }

      showRemind()
      {
         this.setState({
            view: "remind"
         });
      }


      render()
      {

      //  console.log("login step: ",this.state.step);

        if(this.state.redirect.length > 3)
        {
            return (
                <div>
                   {<Navigate to={this.state.redirect} />}
                </div>
            )
        }


        if(this.state.view == "remind")
        {
            /*          <div className='formField' onClick={this.showLogin.bind(this)}>                       
                          <span className='link'>Anuluj</span>
                        </div> */
            return (
                <div className="login">
                     
    
                     <form className='simplyForm' method='post' action="#" onSubmit={this.onSubmitFormRemindStep1.bind(this) } >
                     <div className='formTitle'>Przypomnij hasło</div>
                        <div className='formField'>
                           <label>E-mail<span className='required'>*</span></label> 
                           <input type="text" name='username'  placeholder='example@com.pl' value={this.state.username}  onChange={this.onChangeUsername.bind(this)} />
                           <div className='errorForm'>{this.state.username_err}</div>
                           <div className='okForm'>{this.state.username_ok}</div>
                        </div>                  
    
                        <div className='formField'>                       
                           <input type="submit" className='buttonForm' value="Dalej"/>
                        </div>
    

    
                     </form>
                </div>
            )         
        }

        if(this.state.view == "remind_code")
        {
            return (
                <div className="login">
                     
    
                     <form className='simplyForm' method='post' action="#" onSubmit={this.onSubmitFormRemindStep2.bind(this) } >

                     <div className='formTitle'>Ustaw nowe hasło</div>

                        <div className='formField'>
                           <label>E-mail<span className='required'>*</span></label> 
                           <input type="text" name='username'  placeholder='example@com.pl: ' value={this.state.username}  onChange={this.onChangeUsername.bind(this)} />
                           <div className='errorForm'>{this.state.username_err}</div>
                           <div className='okForm'>{this.state.username_ok}</div>
                        </div>   

                        <div className='formField'>
                       <label>Nowe hasło<span className='required'>*</span></label> 
                       <input type="password" name='password'  placeholder='Hasło: ' value={this.state.password}  onChange={this.onChangePassword.bind(this)}  />
                       <div className='errorForm'>{this.state.password_err}</div>
                       <div className='okForm'>{this.state.password_ok}</div>
                       </div>      

                       <div className='formField'>
                       <label>Powtórz hasło<span className='required'>*</span></label> 
                       <input type="password" name='password2'  placeholder='Hasło: ' value={this.state.password2}  onChange={this.onChangePassword2.bind(this)}  />
                       <div className='errorForm'>{this.state.password2_err}</div>
                       <div className='okForm'>{this.state.password2_ok}</div>
                       </div>    


                       <div className='formField'>
                           <label>Kod autoryzacyjny (otrzymany na maila)<span className='required'>*</span></label> 
                           <input type="text" name='code'  placeholder='Podaj kod: ' value={this.state.code}  onChange={this.onChangeCode.bind(this)} />
                           <div className='errorForm'>{this.state.code_err}</div>
                           <div className='okForm'>{this.state.code_ok}</div>
                        </div>  

    
                        <div className='formField'>                       
                           <input type="submit" className='buttonForm' value="Dalej"/>
                        </div>
    

    
                     </form>
                </div>
            )         
        }

        if(this.state.view == "success_pass")
        {
            this.countAndLogin();

            return (
                <div className="register">
                    

                    <form className='simplyForm ' method='post' action="#" >
                    <div className='formTitle'>Gratulacje, zmieniłeś hasło.</div>

                    <div className='formField'>
                        <p>
                            Twoje konto jest już aktywne. Za chwilę nastąpi przekierowanie... <span id='count' className='text-red'></span>                                                      
                        </p>
                        <p>
                            Jeśli potrzebujesz dodatkowej pomocy skontaktuj się z nami. Chętnie pomożemy. 
                        </p>
                    </div> 
                    
                   
                    
                    </form>

                </div>
            )        
        }

        if(this.state.step == 1 )
        {

            return (
                <div className="login">
                    

                    <form className='simplyForm' method='post' action="#" >

                    <div className='formTitle'>Zaloguj się</div>

                        <div className='formField'>
                        <label>E-mail<span className='required'>*</span></label> 
                        <input type="text" name='username'  placeholder='E-mail: ' value={this.state.username}  onChange={this.onChangeUsername.bind(this)} />
                        <div className='errorForm'>{this.state.username_err}</div>
                        <div className='okForm'>{this.state.username_ok}</div>
                        </div>
                
                        <div className='formField'>
                        <label>Hasło<span className='required'>*</span></label> 
                        <input type="password" name='password'  placeholder='Hasło: ' value={this.state.password}  onChange={this.onChangePassword.bind(this)}  />
                        <div className='errorForm'>{this.state.password_err}</div>
                        <div className='okForm'>{this.state.password_ok}</div>
                        </div>

                        <div className='formField'>                       
                        <input type="button" className='buttonForm' value="Wejdź"  onClick={this.onSubmitForm.bind(this) } />
                        </div>

                        <div className='formField' onClick={this.showRemind.bind(this)}>                       
                        <span className='link'>Nie pamiętam hasła</span>
                        </div>

                    </form>
                </div>
            )

        }else{

            return(<></>)

        }
      }
 }

 export default LogIn;