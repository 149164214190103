import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ConfigX from '../../../ConfigX';
import FileLister from "../../../components/FileLister";
import ButtonConfirm from "../../../components/ButtonConfirm";
import FileUploader from "../../../components/FileUploader";
import AlertMsg from '../../../components/AlertMsg';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

class ChooseResearch extends React.Component
{
    constructor(props)
    {
        super(props)

       
        this.state = {
            id_firmy: this.props.id,
            view: this.props.view,
            row: this.props.rows,
            osoby: this.props.osoby,

            sk_badania_id: '_x_',
            kwota_netto: "",
            kwota_vat: "",
            px_group_id: '_x_',
            przewidywana_data_zakonczenia_uslugi: new Date(),  

            nn_kwota_netto: "",
            nn_kwota_vat: "",
            nn_badanie: "",
            nn_osoba: "",

            nn_przewidywana_data_zakonczenia_uslugi: "",

            alertMSG: '',
            alertMSGTime: 0,
        }
    }


    componentDidUpdate()
    {
                
    }

    onChangeBadanie(event)
    {
        var v   = event.target.value;      

        var row = this.state.row;
        row["id"] = v; 

        this.setState( {
            row: row,     
        } );
    }

    onChangeOsoba(event)
    {
        var id = event.target.value;
        var ok = '';

        if(id == '_x_'){ ok = "incorrect" } else ok ="correct";

        this.setState({px_group_id: id,nn_osoba: ok})
    }


    zlecBadanie(event)
    {
        var err = false;
        var i = 0;


        if(this.state.kwota_netto.length < 1 || this.state.nn_kwota_netto == 'incorrect') 
        { 
          //  var dt = new Date();
          //  dt = dt.toISOString();
            err = true;

            this.setState({
               // alertMSG: 'Podaj kwotę netto.',
              //  alertMSGTime: dt,
                nn_kwota_netto: "incorrect"
            });
            i++;
           
        }  

        if(this.state.kwota_vat.length < 1 || this.state.nn_kwota_vat == 'incorrect') 
        { 
           // var dt = new Date();
           // dt = dt.toISOString();
            err = true;

            this.setState({
             //   alertMSG: 'Podaj kwotę VAT.',
             //   alertMSGTime: dt,
                nn_kwota_vat: "incorrect"
            });  
            i++;         
        } 

        if(this.state.sk_badania_id == '_x_' ) 
        { 
          //  var dt = new Date();
          //  dt = dt.toISOString();
            err = true;

            this.setState({
            //    alertMSG: 'Wybierz badanie.',
            //    alertMSGTime: dt,
                nn_badanie: "incorrect"
            }); 
            i++;          
        } 

        if(this.state.px_group_id == '_x_' ) 
        { 
          //  var dt = new Date();
          //  dt = dt.toISOString();
            err = true;

            this.setState({
               // alertMSG: 'Wybierz osobę.',
               // alertMSGTime: dt,
                nn_osoba: "incorrect"
            });
            i++;           
        } 

     
        if(err && i > 0)
        {
            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: 'Wypełnij wymagane pola.',
                alertMSGTime: dt,
                //nn_osoba: "incorrect"
            });

            return;
        } 

        var dataPost = { 
            id_badania: this.state.sk_badania_id,
            px_group_id: this.state.px_group_id,
            id_firmy: this.state.id_firmy,
            kwota_netto: this.state.kwota_netto,
            kwota_vat: this.state.kwota_vat,  
            data_zakonczenia_uslugi: this.state.przewidywana_data_zakonczenia_uslugi,           
        };     

        fetch( ConfigX.restApi + "/dash,zlecBadanieDlaFirmy" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {

             if(json.status == "OK")
             {
                 this.props.onClose();
             }
             if(json.status == 'ERR')
             {
                var dt = new Date();
                dt = dt.toISOString();

                this.setState({
                    alertMSG: json.param,
                    alertMSGTime: dt
                });
             }
            
        });

    }


    onChangeKwota(event)
    {
        var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

           // var row = this.state.row;
           // row["kwota_netto"] = v; 

            this.setState( {
                kwota_netto: v,
                nn_kwota_netto: ok
            } );
    }

    onChangeBadanie(event)
    {
        var id = event.target.value;
        var ok = '';

        if(id == '_x_'){ ok = "incorrect" } else ok ="correct";

        this.setState({sk_badania_id: id,nn_badanie: ok})
    }

    zamknij(event)
    {
        this.props.onClose();
    }

    onChangeDateFrom(from)
    {

        //https://reactdatepicker.com/#example-min-date
        var v = from;
        var yyyy = v.getFullYear();
        var mm = (v.getMonth()+1) ;  if(mm < 10) { mm = "0" + mm; }
        var dd = (v.getDate()) ;  if(dd < 10) { dd = "0" + dd; }
       // var hh = (v.getHours()) ;  if(hh < 10) { hh = "0" + hh; }
       // var ii = (v.getMinutes()) ;  if(ii < 10) { ii = "0" + ii; }

        //v  =   yyyy+ "-" + mm + "-" + dd + " " + hh + ":" + ii;
        v  =   yyyy+ "-" + mm + "-" + dd;

     //   console.log(v);

        //var tmp = this.state.row;
      //  this.state.przewidywana_data_zakonczenia_uslugi = v;

        this.setState({
            przewidywana_data_zakonczenia_uslugi: v
        });
    }

    onChangeVat(event)
    {
        var v   = event.target.value;
            var ok = "";
              
       
            v = v.replace(/[^0-9^.^,]/g, ""); //wywal litery...
            v = v.replace(",", "."); //wywal litery...

            //sprawdz czy drugi przecinek..
            var dots = 0;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") dots++;
            }
           
            if(dots > 1) return;

            var index = v.length;
            for(var k =0; k<v.length; k++)
            {
                if(v[k] == ".") { index = k; break; }
            }

            if(v.length > index+2)
            {
                v = v.substring(0,index+3);
            }
         
            if(v.length<1  ){ ok = "incorrect" } else ok ="correct";

           // var row = this.state.row;
           // row["kwota_vat"] = v; 

            this.setState( {
                kwota_vat: v,
                nn_kwota_vat: ok
            } );
    }

    render()
    {

        if(this.state.view == "empty" || this.state.row ==null )
        {
             return (null)
        }

        var content = '';        
        var combo = (null);
        var pliki = (null) ; 

        //Pliki----------------------------
       // var pliki = (<FileUploader typ="sk_badania" typ_id='-1' apiKey={this.props.apiKey} onlyDisplay="1" onlyMyFiles='no' deleteIcon='off' showSize='off' showDate='off'/> );    
        

          ///  if( this.state.row && this.state.row.status == "usluga_w_trakcie" ) {tytul = "Usługa w trakcie realizacji";}
           // if( this.state.row && this.state.row.status == "usluga_wykonana" ) {tytul = "Usługa zrealizowana";}
          //  if( this.state.row && this.state.row.status == "usluga_przerwana" ) {tytul = "Usługa przerwana / niewykonana";}

        //    if( this.state.przewidywana_data_zakonczenia_uslugi.length < 5 || this.state.przewidywana_data_zakonczenia_uslugi == '0000-00-00')
        //    {
       //         this.state.przewidywana_data_zakonczenia_uslugi = new Date().toISOString();
       //     }
            
            content =   (
                <div>                
                      
                          <div className='FormField '>
                               <label>Badanie <span className='required'>*</span></label>
                               <select className={this.state.nn_badanie} defaultValue={this.state.sk_badania_id} onChange={this.onChangeBadanie.bind(this) } col="badanie" >
                                  {
                                        this.state.row.map((item,index)=>{
                                            return(<option key={item.id} value={item.id}>{item.tytul}</option>)
                                        }) 
                                  }                      
                               </select>
                          </div> 
                          
                          <div className='FormField inlineField'>
                               <label>Osoba <span className='required'>*</span></label>
                               <select className={this.state.nn_osoba} defaultValue={this.state.px_group_id} onChange={this.onChangeOsoba.bind(this) } col="osoby" >
                                  {
                                        this.state.osoby.map((item,index)=>{
                                            return(<option key={item.id} value={item.id}>{item.imie} {item.nazwisko}</option>)
                                        }) 
                                  }                      
                               </select>
                          </div> 

                          <div className='FormField inlineField'>
                                 <label>Kwota netto<span className='required'>*</span></label>
                                 <input type="text" value={ this.state.row.kwota_netto } className={"field100 " + this.state.nn_kwota_netto} onChange={this.onChangeKwota.bind(this)} />
                          </div>  

                          <div className='FormField inlineField'>
                                 <label>Kwota vat<span className='required'>*</span></label>
                                 <input type="text" value={ this.state.row.kwota_vat } className={"field100 " + this.state.nn_kwota_vat} onChange={this.onChangeVat.bind(this)} />
                          </div>

                          <div className='FormField inlineField'>
                                        <label>Przewidywana data zakończenia usługi (wykonania)</label>
                                        <DatePicker
                                            selected={new Date(this.state.przewidywana_data_zakonczenia_uslugi)}
                                            onChange={this.onChangeDateFrom.bind(this)}
                                           // showTimeSelect
                                           // timeFormat="HH:mm"
                                          //  timeIntervals={5} 
                                          //  timeCaption="time"
                                            dateFormat="yyyy-MM-dd"
                                            locale="pl"
                                            className={this.state.nn_przewidywana_data_zakonczenia_uslugi}
                                        />
                                </div>

                                <br/>

                               <div className='FormField FormButtons'>      
                                  <div className='buttonNewItem' onClick={this.zlecBadanie.bind(this)}>Zleć badanie</div>  
                                  &nbsp;
                                  <div className='buttonForm' onClick={this.zamknij.bind(this)}>Anuluj</div>  
                               </div>
                            
                </div>
            )



            return (
                
                <div className="_editor">   
                      
                <div className="info_shadow">                                                     
                <span className='window window_form'>
                    <div className='container container_form'>                       

                        <div className='windowTextContent'>       
                           <h3>Wybierz i zleć badanie w imieniu tej firmy</h3>                   
                        </div> 

                        <div className='dashForm'>            
                           {combo}                                
                           {content}
                        </div>

                    </div>
                </span>
                </div>

                <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Powiadomienie"/>

            </div>
            )
        
     
     
    }
}

export default ChooseResearch 