import React from 'react';
import Linkify from 'react-linkify';
 
class DetTerminyRow extends React.Component
 {
        constructor(props)
        {
            super(props)
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
           // this.props.onClickTermin(id);
           return null;
        }

        render()
        {
   
            return (         
                   
                        <div className='even-info' key={this.props.num}>    
                        <br/>                                            
                             <span style={{"color":"#000"}}>Data &nbsp;</span><br/> <span id={this.props.row.id}  className="text_center">{this.props.row.data_od}</span> /                                                     
                             <span id={this.props.row.id}   className="text_center">{this.props.row.data_do}</span>   <br/>                                                   
                             <span style={{"color":"#000"}}>Temat &nbsp;</span> <br/><span id={this.props.row.id} className="text_center">{this.props.row.temat}</span>          <br/>                                            
                             <span style={{"color":"#000"}}>Adres &nbsp;</span> <br/><span id={this.props.row.id} className="text_center">{this.props.row.ulica} {this.props.row.nr_domu} / {this.props.row.nr_mieszkania} {this.props.row.kod_pocztowy} {this.props.row.miasto}, {this.props.row.nr_nazwa_pomieszczenia}   </span><br/>                                                                                                 
                             <span style={{"color":"#000"}}>Osoba prowadząca &nbsp;</span><br/> <span id={this.props.row.id}  className="text_center">{this.props.row.osoba_prowadzaca_inne}</span> <br/>
                             <span style={{"color":"#000"}}>Informacje dodatkowe &nbsp;</span><br/> <span id={this.props.row.id} className="text_center">

                             <Linkify
                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a target="blank" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>
                                )}
                            >{this.props.row.informacje_dodatkowe}
                            </Linkify>                               

                            </span>                                                                         
                        </div>    
                                 
                       
            )
        }
 }

 export default DetTerminyRow;