import React from 'react';
import ReactDOM from 'react-dom/client';


import Config from './ConfigX' 

import Page from './layout/Page';
import Login from './layout/Login';
import Dashboard from './layout/Dashboard';
import DashboardUser from './layout/DashboardUser'
import Helpdesk from '../src/views/dashboard/helpdesk/Main';



import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route  } from "react-router-dom";



/*
      <Route path={Config.pathDef + "szkolenia"} component={() => {
        return <Redirect to="szkolenia" push={true} />
         }} 
      />
      <Route path={Config.pathDef + "badania"} component={() => { 
        return <Redirect to="badania" push={true} />
        }} 
      />

        <Route path="/" element={<Page page="usługi" />}>

          <Route path={Config.pathDef + ""} index element={<Page page="usługi" />}  /> 
                                      
          <Route path={Config.pathDef + "szkolenia"} element={<Page page="szkolenia" />}  />   
          <Route path={Config.pathDef + "badania"} element={<Page page="badania" />}  /> 

*/
var page = "szkolenia";


  const params = new URLSearchParams(window.location.pathname);

  page =  params.get("page");

  if(page === "undefined" || page === null)
  {
    page = "szkolenia";
  } 
  
//console.log("params: ",params);
//console.log("page: ",page);

var apiKey = "";
var user = "";
var localUsr = window.localStorage.getItem('user');

if(localUsr != null && localUsr != "undefinded" && localUsr.length > 3)
{
   var jsonUsr = JSON.parse(localUsr);
   apiKey = jsonUsr.apiKey;
   user = jsonUsr.usr;
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render( 

  <BrowserRouter>
  <Routes>

     <Route path={Config.pathDef + "/"} element={<Page page={page} />}  />
     
     <Route path={Config.pathDef + "szkolenia"} element={<Page page="szkolenia" />}  />   

     <Route path={Config.pathDef + "badania"} element={<Page page="badania" />}  /> 


     <Route path={Config.pathDef + "szczegolyB"} element={<Page page="szczegolyB" />}  />  
     <Route path={Config.pathDef + "szczegoly"} element={<Page page="szczegoly" />}  />   
     <Route path={Config.pathDef + "o_nas"} element={<Page page="o_nas" />}  /> 
     <Route path={Config.pathDef + "partnerzy"} element={<Page page="partnerzy" />}  /> 
     <Route path={Config.pathDef + "kontakt"} element={<Page page="kontakt" />}  /> 
     <Route path={Config.pathDef + "logout"} element={<Page page="logout" />}  /> 
     <Route path={Config.pathDef + "login"} element={<Login/>}   /> 




     <Route path={Config.pathDef + "dashboard"}           element={<Dashboard option="1"/>}   />   
     <Route path={Config.pathDef + "dashboard,panel"}     element={<Dashboard option="1"/>}   />   
     <Route path={Config.pathDef + "dashboard,training"}  element={<Dashboard option="2"/>}   /> 
     <Route path={Config.pathDef + "dashboard,research"}  element={<Dashboard option="3"/>}   /> 
     <Route path={Config.pathDef + "dashboard,firms"}     element={<Dashboard option="4"/>}   /> 
     <Route path={Config.pathDef + "dashboard,members"}   element={<Dashboard option="5"/>}   /> 
     <Route path={Config.pathDef + "dashboard,calculate"} element={<Dashboard option="6"/>}   /> 
     <Route path={Config.pathDef + "dashboard,raports"}   element={<Dashboard option="7"/>}   /> 
     <Route path={Config.pathDef + "dashboard,settings"}  element={<Dashboard option="8"/>}   /> 
     <Route path={Config.pathDef + "dashboard,raportsb"}  element={<Dashboard option="9"/>}   /> 
     <Route path={Config.pathDef + "dashboard,helpdesk"}  element={<Helpdesk />}   /> 




     <Route path={Config.pathDef + "dashboardUser"} element={<DashboardUser />} /> 

     <Route path={Config.pathDef + "user,panel"} element={<DashboardUser option="1"/>}   />   
     <Route path={Config.pathDef + "user,training"} element={<DashboardUser option="2"/>}   />   
     <Route path={Config.pathDef + "user,research"} element={<DashboardUser option="3"/>}   /> 
     <Route path={Config.pathDef + "user,message"} element={<DashboardUser option="4"/>}   /> 
     <Route path={Config.pathDef + "user,settings"} element={<DashboardUser option="5"/>}   />  
     <Route path={Config.pathDef + "user"} element={<DashboardUser />}   /> 


  </Routes>
</BrowserRouter>


);



/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
