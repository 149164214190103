import React from 'react';
import Avatar from '../../../image/company_icon.png';
import Det from './Det';
import ConfigX from '../../../ConfigX';

import loading from '../../../image/loading.gif'
class Main extends React.Component
 {
    constructor(props)
    {
        super(props)

        this.state = {
            loading: 1,
            view: "list",
            rows: [], 
            result: [],            
        }
    
    }

    getData(  onFinish )
    {

        var wait = setTimeout(
             () => {
                this.setState({ loading: 1 });
             }
            ,500);

           
        var tmpNonRef = JSON.stringify( this.state );

        tmpNonRef = JSON.parse(tmpNonRef);

        var dataPost = {
           
          }

          fetch( ConfigX.restApi+ "/user,get_data_user" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        
             clearTimeout(wait);
 
             this.setState({
                rows: json.rows[0],
                loading: 0
             }); 
                         
             if(onFinish != null && onFinish != undefined ) onFinish();
           
        });
    }

    componentDidMount()
    {
        this.getData(null);   
        
        // const head = document.querySelector("body");

        // const link = document.createElement("link");
        // link.rel  = 'stylesheet';
        // link.type = 'text/css';        
        // link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-alpha1/dist/css/bootstrap.min.css';
        // link.media = 'all';
        // head.appendChild(link);    
        
        // const script2 = document.createElement("script");
        // script2.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-alpha1/dist/js/bootstrap.bundle.min.js";
        // script2.async = true;    
        // head.appendChild(script2); 
        
        // const script3 = document.createElement("script");
        // script3.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js";
        // script3.async = true;    
        // head.appendChild(script3);         
    }

    redirect(action, id, param)
    {  

        if(action == "list")
        {
          //  this.getData(null);
        }
     
        
        this.setState(
            {
                view: action,
                selectedId: id,
            }
           );
    }


// <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous"/>



    render()
    {
   
        return (

            <>

                    <div className='rowTrain'>
                        <section className='container'>
                            <h2>Ustawienia użytkownika</h2>
                        </section>
                    </div>

                    <div className="container-fluid">
                    
                         <Det redirect={this.redirect.bind(this) } apiKey={this.props.apiKey}/>    
    
                    </div>

                   
                 

            </>

              

        )
    }
 }


 export default Main