import React from 'react';
import ConfigX from '../ConfigX';

class UserSelector extends React.Component
{
    constructor(props)
    {
        super(props)
        
        this.state = {
            view: "none",
            id: this.props.id,
            title: this.props.title,
            rows: [],
        }; 
    }

    componentDidMount()
    {
        this.getData();
    }

    onSelectedUser(event)
    {
        this.setState({
            view: "none",
            id:  event.target.getAttribute("id"),
            title:  event.target.getAttribute("nazwa"),
        });

        this.props.onSelectedUser( event.target.getAttribute("id"),  event.target.getAttribute("nazwa")  );       
    }



    showList(v)
    {
        this.setState({
            view: "list"
        });
    }

    getData()
    {
        fetch( ConfigX.restApi + "/user,getAdresatWiadomosci" , {
            method: 'POST',             
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        
             this.setState({
                rows: json
             });      
           
        });
    }
    onCloseForm(event)
    {
       this.setState({
        view: "none"
       })
    }

    render()
    {
      
        if(this.state.view == "list")
        {
           // console.log(this.state.rows);
                return (
                    <div className="firm_selector">    

                        <span className="link_firm" onClick={this.showList.bind(this)}> ◼ {this.state.title}</span>

                        <div className='info_shadow'>
                        <span className='window window_list'>
                            <div className='container'>
                                <div className='options'>                                  
                                    <div>Wybierz adresata wiadomości</div>
                                    &nbsp;&nbsp;
                                    <div className='buttonForm buttonAlert' onClick={this.onCloseForm.bind(this)} title="Anuluj">Zamknij</div> 
                                </div>                                
                                <ul>
                                {
                                    this.state.rows.map( item => { 
                                        return (
                                            <li key={item.id} id={item.id}  nazwa={item.imie_nazwisko} onClick={this.onSelectedUser.bind(this) } >
                                                {item.imie_nazwisko},  {item.e_mail}  
                                            </li>
                                        )
                                    } )
                                }
                                </ul>
                            </div>
                        </span>
                        </div>
                    </div>
                )
        }


        if(this.state.view == "none")
        {
                return (
                    <div className="firm_selector">
                       <span className="link_firm" onClick={this.showList.bind(this)}> ◼ {this.state.title}</span>                       
                    </div>
                )
        }
    }
}

export default UserSelector;