

import React from "react";

class Search extends React.Component
{
 
    constructor(props)
    {
        super(props)

        this.state = {
            search: this.props.searchFraze
        };
    }
 
  onChangeSearch(event)  
  {
     let val = event.target.value;

     this.setState({
        search: val
     });

     this.props.onSearchVal(val);
  }


  render(){
      
       return (
          <div className="searchEngine">
                <form>
                  <input type="text" name="searchEngine" id="searchEngine" placeholder="Znajdź szkolenie lub badanie"  value={this.state.search}  onChange={this.onChangeSearch.bind(this) } /> 
                    <div className="buttonForm">Szukaj</div>
                </form>
          </div>
       );

  }
}

export default Search