import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

 

 
class SearchBar extends React.Component
 {
        constructor(props)
        {
            super(props)            
        }


        onChangeFraza(event)
        {                          
              this.props.changeFilter('fraza', event.target.value);
        }
        onChangeEmail(event)
        {                          
              this.props.changeFilter('email', event.target.value);
        }
        onChangeTelefon(event)
        {                          
              this.props.changeFilter('telefon', event.target.value);
        }
 

        goSearch()
        {
            this.props.goSearch();
        }

        goClear()
        {
            this.props.goClear();
        }

      

        render()
        {
            return (
                <div className='search_bar'>

                    <div className='filter'>
                          <div className='filter_label'>Nazwisko / Imię</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.fraza} onChange={this.onChangeFraza.bind(this)}    />
                          </div>                                 
                    </div>
 
                    <div className='filter'>
                          <div className='filter_label'>Email</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.email} onChange={this.onChangeEmail.bind(this)}    />
                          </div>                                 
                    </div>

                    <div className='filter'>
                          <div className='filter_label'>Telefon</div>
                          <div className='filter_content'>
                               <input type="text" value={this.props.search_bar.telefon} onChange={this.onChangeTelefon.bind(this)}    />
                          </div>                                 
                    </div>                                 

                    <div className='filter filterButton'>
                          {/* <div className='buttonSearch' onClick={this.goSearch.bind(this)}>Szukaj</div>                               */}
                           <div className='buttonSearch' onClick={this.goClear.bind(this) }>Wyczyść</div>                               
                    </div>
           

                </div>
            )
        }
 }

 export default SearchBar;