


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/badania/addbadania/step1.PNG';
import step2 from '../../helpdesk/images/badania/addbadania/step2.PNG';
import step3 from '../../helpdesk/images/badania/addbadania/step3.PNG';
import step4 from '../../helpdesk/images/badania/addbadania/step4.PNG';

 


class AddBadania extends React.Component
 {



  render(){

    return(
        <>
        
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>Jeśli chcemy dodać badanie poprzez panel administracyjny przechodzimy do menu "Badania". Nad listą badań po prawej stronie znajduje się zielony przycisk "Nowa usługa", musimy kliknąć aby przejść do kolejnego kroku.</p>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>W tym kroku wypełniamy wszystkie pola z czerwonymi gwiazdkami. Na samym początku możemy wybrać firmę, która prowadzić będzie te badania. Domyślnie wybraną firmą zawsze jest BKP.</p>
          <p>Klikając w pole z nazwą podmiotu, otworzy nam się okno w którym pojawi się lista firm do wyboru. </p>
          <p>Dalej wybieramy status, który ustawiony jako Roboczy ustawia badanie niewidoczne jeszcze na stronie głównej i na liście badań aktualnych. Dopiero ustawienie statusu jako widoczny, ustawia to badanie jako widoczne na stronie głównej.</p>
          <p>Po wypełnieniu wszystkich pól prawidłowo, klikamy na "Zapisz".</p>

          <img src={step2} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 3</h3>
          <br/>
          <p>Idąc niżej, dodajemy postanowienia, czyli zgody na które użytkownik musi się zgodzić lub nie, zależy to od wybranej opcji podczas dodawania. Domyślnie dodawane jest jedna zgoda wymagana do zaznaczenia przez użytkownika. </p>
          <p>Postanowienia dodajemy wpisując w puste pole treść tej zgody, zaznaczając czy ma być ono wymagane do zaznaczenia czy nie i klikamy w przycisk "Dodaj", znajdujący się obok pól.</p>
          <p>Na samym dole widzimy ważną tabelę w której znajdują się wnioski złożone przez firmy na to badanie.</p>

          <img src={step3} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 4</h3>
          <br/>
          <p>Po zapisie widać, że nowa pozycja pojawiła się na liście badań w panelu administracyjnym.</p>

          <img src={step4} alt=""/>

        </section>



        </>

    )
  }



}

export default AddBadania;