
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';


import ConfigX from '../ConfigX';



import '../App.css';
import BarTop from '../components/BarTop';
import DashboardContainer from '../components/DashboardContainer';
import DashboardContainerUser from '../components/DashboardContainerUser';
import BarBottom from '../components/BarBottom';

class Page extends React.Component
 {

  constructor(props)  
  {
    super(props)     
          

    var apiKey = "";
    var user = "";
    var localUsr = window.localStorage.getItem('user');

    if(localUsr != null && localUsr != "undefinded" && localUsr.length > 3)
    {
       var jsonUsr = JSON.parse(localUsr);
       apiKey = jsonUsr.apiKey;
       user = jsonUsr.usr;
    }

      this.state = {
        option: this.props.option,
        apiKey: apiKey,
        user: user
      }
 
  
  }


  componentDidMount()
  {
    console.log("Dashboard mount ");  
        
  }

  componentDidUpdate()
  {
    //console.log("Dashboard update "  );   
    
    if(this.props.option != this.state.option)
    {
      this.setState({
          option: this.props.option
      });
    }
    
  }

  render(){
    
   
    if(this.state.user == "ADMIN")
    {
      return (
      
        <div className="App AppDashboard"> 
            <BarTop/>
             <DashboardContainer apiKey={this.state.apiKey} option={this.state.option}/>                         
        </div>


      );
    }




  }
 
}

export default Page;
