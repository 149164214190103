


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/badania/delbadania/step1.PNG';
import step2 from '../../helpdesk/images/badania/delbadania/step2.PNG';
 


class DelBadania extends React.Component
 {



  render(){

    return(
        <>
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>Przechodzimy do listy badań. Klikamy na pozycję aby zajrzeć do szczegółów.</p>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>Aby usunąć badanie musimy poszukać niżej na stronie czerwonego przycisku "Usuń usługę". Należy pamiętać, że jeżeli chcemy to zrobić nie może być żadnego potwierdzonego uczestnika tego szkolenia, na co wskazuje komunikat.</p>

          <img src={step2} alt=""/>

        </section>


        </>

    )
  }



}

export default DelBadania;