
import React from 'react';
import { Link, NavLink  } from 'react-router-dom';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { MenuItem,  Menu, Header, Label } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import ConfigX from '../../ConfigX'; 

class MenuExample extends React.Component
{

    constructor(props) 
    {
         super(props)           
         this.state = {
            m_panel: "selected",
            m_szkolenia: "",
            m_badania: "",
            m_baza_firm: "",
            m_baza_uczestnikow: "",
            m_rozliczenia: "",
            m_moje_konto: "", 
            m_raporty: "",
            m_raportyb: "",
            selected: 1 ,      
            activeItem: this.props.name ? this.props.name : 'Panel',     
            refresh: this.props.refresh          
         }
    }

    selectMenu(event){

         var option =  event.target.id;
         var name   =  event.target.name;

       //  var option =  event.target.getAttribute("id");
         this.props.selectMenu(option,name);
    }

  

    componentDidMount()
    {
       // console.log("Menu Mount " + this.props.option);
    }

    componentDidUpdate()
    {
       // console.log("Menu Update " + this.props.option);  
       //console.log("props: ",this.props);

     /*   if(this.props.option != this.state.selected )
        {
             var tmp = this.state;
             tmp.m_panel = "";
             tmp.m_szkolenia = "";
             tmp.m_badania = "";
             tmp.m_baza_firm = "";
             tmp.m_baza_uczestnikow = "";
             tmp.m_rozliczenia = "";
             tmp.m_raporty = "";
             tmp.m_raportyb = "";
             tmp.m_moje_konto = "";
             tmp.selected = this.props.option;

             if(this.props.option == 1) tmp.m_panel = "active";
             if(this.props.option == 2) tmp.m_szkolenia = "active";
             if(this.props.option == 3) tmp.m_badania = "active";
             if(this.props.option == 4) tmp.m_baza_firm = "active";
             if(this.props.option == 5) tmp.m_baza_uczestnikow = "active";
             if(this.props.option == 6) tmp.m_rozliczenia = "active";
             if(this.props.option == 7) tmp.m_raporty = "active";
             if(this.props.option == 8) tmp.m_moje_konto = "active";
             if(this.props.option == 9) tmp.m_raportyb = "active";

             this.setState(tmp);
            
        }*/

        if(this.props.name != this.state.activeItem)
        {
            var tmp = this.state;
            tmp.activeItem = this.props.name;

            this.setState(tmp);
        }
    }

 

    handleItemClick = (e, { id, name }) => {   
                
        this.setState({ activeItem: name });
        this.props.selectMenu(id, name);
    } 

 
    render()
    {
       // const { activeItem } = this.state



        return (  
            <div >


            <Menu vertical> 
                <MenuItem
                name='Panel'          
                //href={ConfigX.pathDef + "dashboard" } 
                active={this.state.activeItem === 'Panel'}
                id="1" 
                onClick={this.handleItemClick}
                >
                Panel  
                </MenuItem>

                <MenuItem
                name='Szkolenia'
                //href={ConfigX.pathDef + "dashboard,training" }
                active={this.state.activeItem === 'Szkolenia'}
                id="2" 
                onClick={this.handleItemClick}
                >      
                Szkolenia
                </MenuItem>

                <MenuItem
                name='Badania'
                //href={ConfigX.pathDef + "dashboard,research" }
                active={this.state.activeItem === 'Badania'}
                id="3" 
                onClick={this.handleItemClick}
                >
                Badania
                </MenuItem>

                <MenuItem
                name='Baza firm'
                //href={ConfigX.pathDef + "dashboard,firms" } 
                active={this.state.activeItem === 'Baza firm'}
                id="4" 
                onClick={this.handleItemClick}
                >
                Baza firm
                </MenuItem>

                <MenuItem
                name='Baza osób'
                //href={ConfigX.pathDef + "dashboard,members" }
                active={this.state.activeItem === 'Baza osób'}
                id="5" 
                onClick={this.handleItemClick}
                >
                Baza osób
                </MenuItem>

                <MenuItem
                name='Rozliczenia'
                // href={ConfigX.pathDef + "dashboard,calculate" }
                active={this.state.activeItem === 'Rozliczenia'}
                id="6" 
                onClick={this.handleItemClick}
                >
                Rozliczenia
                </MenuItem>

                <MenuItem
                name='Raport szkoleń'
                //href={ConfigX.pathDef + "dashboard,raports" }
                active={this.state.activeItem === 'Raport szkoleń'}
                id="7" 
                onClick={this.handleItemClick}
                >
                Raport szkoleń
                </MenuItem>

                <MenuItem
                name='Raport badań'
                //href={ConfigX.pathDef + "dashboard,raportsb" }
                active={this.state.activeItem === 'Raport badań'}
                id="9" 
                onClick={this.handleItemClick}
                >
                Raport badań
                </MenuItem>

                <MenuItem
                name='Ustawienia systemu' 
                //href={ConfigX.pathDef + "dashboard,settings" }
                active={this.state.activeItem === 'Ustawienia systemu'}
                id="8" 
                onClick={this.handleItemClick}
                >
                Ustawienia systemu
                </MenuItem>

            </Menu>


            </div>
        )
    }

}

export default MenuExample