import React from 'react';

import ConfigX from '../../ConfigX'
import ButtonConfirm from '../../components/ButtonConfirm';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AlertMsg from '../../components/AlertMsg';

import loading from '../../image/loading.gif'

import FileUploader from '../../components/FileUploaderUser';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)

 
class SignUpB extends React.Component
 {
        constructor(props)
        {
            super(props)

            this.state = {
                sk_badania_id: this.props.sk_badania_id,
                action:  this.props.action,  //new edit none 
                rows: [],
                nowSelected: this.props.nowSelected,    
                alertMSG: '',
                alertMSGTime: 0,                  
                dane: [],
                show: 1,
                show_cd: 0,
                checkTyp: '',
                msg_nip: 0,
                postanowienia: [],
                arrow:'0x1405',

               // isChecked: 0,
              /*  rows: {
                    nazwa: '',
                    nip: '',
                    nr_domu:'',
                    nr_mieszkania:'',
                    kod_pocztowy:'',
                },  */              

                nn_imie: "",
                nn_nazwisko: "",
                nn_email: "",
                nn_telefon: "",  
                
                nn_nazwa: "",
                nn_ulica: "",
                nn_nr_domu: "",
                nn_nr_mieszkania: "",
                nn_kod_pocztowy: "",
                nn_miasto: "",

                loading: 0,
                //dane: [],
               // terminy: this.props.terminy,
            };
        }

      
     /*   getEmptyRow()
        {
            var data =  {
                id: "-1",
                imie: '' ,
                nazwisko: '',
                email: '',
                telefon: "",
                terminy: [],

                alertMSG: '',
                alertMSGTime: 0,
                show: 0,
                show_cd: 0,
                msg_nip: 0,
                arrow:'0x1405',

                nn_imie: "",
                nn_nazwisko: "",
                nn_email: "",
                nn_telefon: "",  
                
                nn_nazwa: "",
                nn_ulica: "",
                nn_nr_domu: "",
                nn_nr_mieszkania: "",
                nn_kod_pocztowy: "",
                nn_miasto: "",

               // postanowienia: [],
              //  isChecked: 0,
                // rows: {
                //     nazwa: '',
                //     nip: '',
                //     nr_domu:'',
                //     nr_mieszkania:'',
                //     kod_pocztowy:'',
                // },                
              }

              return JSON.stringify(data);
        }
        */


        componentDidMount()
        {        
 
            this.getDataUser(this.props.rows.nip);
            this.getDataFirm(null); 

            this.setState({loading: 0});

            if(this.state.postanowienia.length == 0)
            {
                this.get_postanowienia();
            }

        }
 

        getDataUser()
        {
           var dataPost = {};     
            
            fetch( ConfigX.restApi + "/user,get_data_user" , { 
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
            
                  this.setState({
                    //dane: json.rows[0] 
                    dane: json.rows[0],
                    sk_firmy_id: json.rows[0].sk_firmy_id, 
                  })
              
            })
            .catch((error) =>
            {
                console.log("błąd z parsowania",error);
            });           
        }

        get_postanowienia()
        {

            var dataPost3 = {
                typ_id: this.state.sk_badania_id,     
                typ: "sk_badania"
            };

            fetch( ConfigX.restApi + "/user,get_postanowienia" , {
                method: 'POST', 
                body: JSON.stringify(dataPost3), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
            
                if(json.status == "OK")
                {
                    this.setState({
                        postanowienia: json.rows,
                        loading: 0,
                    })
                                    
                }                 
            });  
                
        }

        isValidNip(nip) {
            if(typeof nip !== 'string')
                return false;
        
            nip = nip.replace(/[\ \-]/gi, '');
        
            let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
            let sum = 0;
            let controlNumber = parseInt(nip.substring(9, 10));
            let weightCount = weight.length;
            for (let i = 0; i < weightCount; i++) {
                sum += (parseInt(nip.substr(i, 1)) * weight[i]);
            }
            
            return sum % 11 === controlNumber;
        }

        onChangeNazwa(event)
        {
                var v   = event.target.value;
                var ok = "";
      
                if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }
    
                var rows = this.state.rows;
                rows["nazwa"] = v; 
    
                this.setState( {
                    rows: rows,
                    nn_nazwa: ok
                } );
        }  

        onChangeNIP(event)
        {
                var v   = event.target.value;
                var ok = "";
                
                this.setState({msg_nip: 0})
 
                v = v.replaceAll('-', '').trim();

                if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

                if(!this.isValidNip(v))
                { 
                    ok = "incorrect"; 
                }else { 
                    ok="correct"; 

                   /* var data = {
                        nip: v,
                        telefon: this.state.rows.telefon,
                        email: this.state.rows.email,
                    }

                    fetch( ConfigX.restApi + "/user,checkFirm" , {
                        method: 'POST', 
                        body: JSON.stringify(data), 
                        headers: {
                            'Content-Type': 'text/html',
                            'X-API-KEY': this.props.apiKey
                        }
                    })
                    .then( res => res.json() )
                    .then(json => {
           
                        if(json.status == 'OK')
                        {
                            this.setState({
                                sk_firmy_id: json.id,  
                                show_cd: 1, 
                                msg_nip: 0,                               
                            });  
                        }
                        if(json.status == 'EXIST')
                        {
                            this.setState({
                                show_cd: 1,
                                msg_nip: 1,                               
                            })
                        }
                           
                    }); */                   

                    this.getDataFirm(v);

                    this.setState({
                        show: 1,                              
                    }) 

                }             

                var rows = this.state.rows;
                rows["nip"] = v; 

                this.setState( {
                    rows: rows,
                    nn_nip: ok
                } );
        }  
        
        getDataFirm(param)
        {

            if(param == null)//pobierz mój podmiot
            {
                var dataPost = {    
                    
                };
            }else{
                var dataPost = {
                    id: -1,
                    nip: param
                };
            }

            fetch( ConfigX.restApi + "/user,get_data_firm" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            }) 
            .then( res => res.json() )
            .then(json => {
        
                this.setState({
                    rows: json.rows[0],                              
                });      
                
            });            
        }

        onChangeUlica(event)
        {
                var v   = event.target.value;
                var ok = "";
      
                if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }
    
                var rows = this.state.rows;
                rows["ulica"] = v; 
    
                this.setState( {
                    rows: rows,
                    nn_ulica: ok
                } );
        }  

        onChangeNrDomu(event)
        {
                var v   = event.target.value;
                var ok = "";
      
                if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }
    
                var rows = this.state.rows;
                rows["nr_domu"] = v; 
    
                this.setState( {
                    rows: rows,
                    nn_nr_domu: ok
                } );
        } 

        onChangeNrMieszkania(event)
        {
                var v   = event.target.value;
                var ok = "";
    
                //if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

                var rows = this.state.rows;
                rows["nr_mieszkania"] = v; 

                this.setState( {
                    rows: rows,
                    nn_nr_domu: ok
                } );
        }  

        onChangeKod(event)
        {
            var ok = "";
        
        
            var v = event.target.value;        

           // v = v.replace(/[^\d.-]/g, '');

            const arr = v.split("-");
            if(arr.length == 2 && arr[0].length == 2 && arr[1].length == 3 && /^\d+$/.test(arr[0])  && /^\d+$/.test(arr[1])   )
            {
                ok ="correct";
            }else ok ="incorrect";

        
            var rows = this.state.rows;
            rows["kod_pocztowy"] = v; 

            this.setState( {
                rows: rows,
                nn_kod_pocztowy: ok
            } );

        }

        onChangeMiasto(event)
        {
                var v   = event.target.value;
                var ok = "";
    
                if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

                var rows = this.state.rows;
                rows["miasto"] = v; 

                this.setState( {
                    rows: rows,
                    nn_miasto: ok
                } );
        } 

        onClickDaneFaktura(event)
        {

            var dataPost3 = {
                id: this.state.sk_firmy_id
             };    
  

             fetch( ConfigX.restApi + "/user,get_data_firm" , {
                 method: 'POST', 
                 body: JSON.stringify(dataPost3), 
                 headers: {
                     'Content-Type': 'text/html',
                     'X-API-KEY': this.props.apiKey
                 }
             })
             .then( res => res.json() )
             .then(json => {
            
             
                  this.setState({
                     rows: json.rows[0],    
                     loading: 0,     
                  });      
                 
             });                   


         /*  var show = this.state.show;

            if(show == 0)
            {
                this.setState({
                    show: 1,
                    arrow:'0x1401',
                })
              
            }
    
            if(show == 1)
            {
                this.setState({
                    show: 0,
                    arrow:'0x1405',
                })
            }*/
        }

        componentDidUpdate()
        {            
                            
            

            if(this.state.nowSelected != this.props.nowSelected)
            {            
                var rows = this.state.rows;          
                //if(this.props.action == "new") row =  JSON.parse(this.getEmptyRow()); //tworz nową instancje pustych danych..
       
                this.setState( {
                    sk_badania_id: this.state.sk_badania_id,
                    action: this.props.action,  //new edit none 
                    rows: rows,
                    nowSelected: this.props.nowSelected,

                    alertMSG: '',
                    alertMSGTime: 0,     
                    show: 0,
                    show_cd: 0,
                    msg_nip: 0,
                    loading: 0,
                   // postanowienia: [],
                   // isChecked: 0,
                    // rows: {
                    //     nazwa: '',
                    //     nip: '',
                    //     nr_domu:'',
                    //     nr_mieszkania:'',
                    //     kod_pocztowy:'',
                    // }, 

                    nn_imie: "",
                    nn_nazwisko: "",
                    nn_email: "",
                    nn_telefon: "",  
                   // nn_termin: "UW", 
                    //dane: this.state.dane        
                });
            }
           

        }

        onChangeImie(event)
        {
            var v = event.target.value;
            var ok = "";
        
            if(v.length < 3) {ok = "incorrect"; } else ok = "correct";            

            var tmp = this.state.dane;
            tmp.imie = v;

            this.setState({
                dane: tmp,
                nn_imie: ok
            });
        }

        onChangeNazwisko(event)
        {
            var v = event.target.value;
            var ok = "";
            
            if(v.length < 3) {ok = "incorrect"; } else ok = "correct";            

            var tmp = this.state.dane;
            tmp.nazwisko = v;

            this.setState({
                dane: tmp,
                nn_nazwisko: ok
            });
        }


        onChangeEmail(event)
        {
            var v = event.target.value;
            var ok = "";
            
            if(v.length < 7) {ok = "incorrect"; } else ok = "correct";            

            var tmp = this.state.dane;
            tmp.email = v;

            this.setState({
                dane: tmp,
                nn_email: ok
            });
        }


        onChangeTelefon(event)
        {
            var v = event.target.value;

           // v = v.replace(/[^0-9]/g, ""); //wywal litery...
            v = v.replace(" ","");
                        
            var ok = "";
            
            if(v.length < 7) {ok = "incorrect"; } else ok = "correct";            

            var tmp = this.state.dane;
            tmp.telefon = v;

            this.setState({
                dane: tmp,
                nn_telefon: ok
            });
        }

        changeSort(event)
        {
            var v   = event.target.value;
            var ok = "";     
    
            var rows = this.state.dane;
            rows["termin"] = v; 
    
            this.setState( {
                dane: rows, 
                nn_termin: ok
            } );
        }

        onChangeWymagane(event)
        {
            var n_name =  event.target.name;
            var n_val  =  event.target.value;
            var n_id = event.target.id;

            var cc   =  event.target.checked;
            
            if(cc) n_val = "1";
            if(!cc) n_val = "0";

            var rowsx = this.state.postanowienia;   

            rowsx.forEach( (item,index) => {  
                if(rowsx[index]['t0_id'] == n_id)
                {
                    rowsx[index]['isChecked'] = n_val;
                }
            });
         
             this.setState({
                postanowienia: rowsx
             })
  
        } 

        onSubmitForm(event)
        {


            var wait = setTimeout(
                () => {
                   this.setState({ loading: 0 });
                }
               ,500);

            var err = false;
            var iter = 0;
            var komunikat = '';

            this.state.postanowienia.map( (item,index) => {  

                if(item.t0_wymagane != item.isChecked)
                {
                    iter++;
                } 
            });

            if(iter > 0) //jeżeli są jakieś niezaznaczone zgody
            {
                komunikat = 'Musisz zaakceptować wszystkie wymagane zgody';
                
                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_zgody: "incorrect",
                    loading: 0 
                });   
                clearTimeout(wait);                
                
            }   

            if(this.state.dane.imie.length < 3 || this.state.nn_imie == "incorrect") {

                komunikat = 'Imię musi posiadać przynajmniej 3 znaki.';                        

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_imie: "incorrect",
                    loading: 0
                });   
              
            }

            if(this.state.dane.nazwisko.length < 3 || this.state.nn_nazwisko == "incorrect") {

                komunikat = 'Nazwisko musi posiadać przynajmniej 3 znaki.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_nazwisko: "incorrect",
                    loading: 0
                }); 
            }

            if(this.state.dane.email.length < 7 || this.state.nn_email== "incorrect") {

                komunikat = 'Adres e-mail musi posiadać przynajmniej 7 znaków.';

                var malpa = this.state.rows.email.indexOf("@");

                if(malpa == -1)komunikat = 'Podaj prawidłowy adres e-mail.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_email: "incorrect",
                    loading: 0
                });   
            }

            if(this.state.dane.telefon.length < 1 || this.state.nn_telefon== "incorrect") {

                komunikat = 'Podaj prawidłowy numer telefonu.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_telefon: "incorrect",
                    loading: 0
                }); 
            }

            if(this.state.rows.nip.length < 5)
            {
                komunikat = 'Podaj prawidłowy numer NIP.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_nip: "incorrect",
                    loading: 0
                });
               
            }




            if(this.state.rows.nazwa.length < 3 || this.state.nn_nazwa== "incorrect") {

                    komunikat = 'Nazwa firmy musi mieć przynajmniej 3 znaki.';

                    var dt = new Date();
                    dt = dt.toISOString();
                    err = true;
        
                    this.setState({
                        alertMSG: komunikat,
                        alertMSGTime: dt,                
                        nn_nazwa: "incorrect",
                        loading: 0
                    });   
            }

            if(this.state.rows.ulica.length < 3 || this.state.nn_ulica== "incorrect") {

                komunikat = 'Ulica musi mieć przynajmniej 3 znaki.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_ulica: "incorrect",
                    loading: 0
                }); 
            }

            if(this.state.rows.nr_domu.length < 1 || this.state.nn_nr_domu== "incorrect") {

                komunikat = 'Podaj numer domu.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_nr_domu: "incorrect",
                    loading: 0
                }); 
            }

            if(this.state.rows.kod_pocztowy.length < 3 || this.state.nn_kod_pocztowy== "incorrect") {

                komunikat = 'Kod pocztowy musi posiadać przynajmniej 3 znaki.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_kod_pocztowy: "incorrect",
                    loading: 0
                }); 
            }

            if(this.state.rows.miasto.length < 3 || this.state.nn_miasto== "incorrect") 
            {

                komunikat = 'Miasto musi posiadać przynajmniej 3 znaki.';

                var dt = new Date();
                dt = dt.toISOString();
                err = true;
    
                this.setState({
                    alertMSG: komunikat,
                    alertMSGTime: dt,                
                    nn_miasto: "incorrect",
                    loading: 0
                });
               
            } 

 
            if(err) return; 


          //  console.log("PPP " + JSON.stringify(this.state.row) );

            //zapisz na serwerze...

            var dataPost = {
                sk_badania_id: this.state.sk_badania_id,
                //id: this.state.dane.id,
                imie: this.state.dane.imie,
                nazwisko: this.state.dane.nazwisko ,
                email: this.state.dane.email,
                telefon: this.state.dane.telefon,

                nazwa: this.state.rows.nazwa,
                nip: this.state.rows.nip,
                ulica: this.state.rows.ulica,
                nr_domu: this.state.rows.nr_domu,
                nr_mieszkania: this.state.rows.nr_mieszkania,
                kod_pocztowy: this.state.rows.kod_pocztowy,
                miasto : this.state.rows.miasto,
                postanowienia: this.state.postanowienia,                
            };     
 

            fetch( ConfigX.restApi + "/user,sign_up_badania" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
             
                clearTimeout(wait);

                var param = json.param;

                 if(json.status == "OK")
                 {
                    this.setState({loading: 0})

                    this.state.rows.id = json.id;
                    this.props.onCloseEditor(json.id,"ok"); 
                 }else
                 {
                    var dt = new Date();

                    if(param.badanie) 
                    {   var param_badanie = param.badanie; 
                        var msg = param_badanie.param;
                    }   
                       
                    if(json.status == 'ERR')
                    {
                        var msg = json.param;
                    }

                    this.setState({
                        alertMSG: msg,
                        alertMSGTime: dt,
                    });
                    return;
                 }
                
            });

     
        }

        onCheckDaneFirmy(event)
        {
            var n_name =  event.target.name;
            var n_val  =  event.target.value;
            var n_id   =  event.target.id;

            this.setState({
                checkTyp: n_val,
            });          

        }

        onConfirmDel(type, param, id)
        {
           //console.log(type + param + id);

           if(type == "yes")
           {
            this.onSubmitForm();
           }
        }

        onConfirm(event)
        {
            this.onSubmitForm();
        } 

        onDelete(event)
        {           

            var dataPost = {
                id: this.state.row.id
            };

            fetch( ConfigX.restApi + "/dash,del_training_time" , {
                method: 'POST', 
                body: JSON.stringify(dataPost), 
                headers: {
                    'Content-Type': 'text/html',
                    'X-API-KEY': this.props.apiKey
                }
            })
            .then( res => res.json() )
            .then(json => {
             
                 if(json.status == "OK")
                 {                  
                     this.props.onDeleteTime(json.id);
                     this.props.onCloseEditor(json.id,'del');
                 }else
                 {

                 }                 
            });


         
        }

        onCloseForm(event)
        {
           this.props.onCloseEditor(this.state.sk_badania_id,'xxx');
        }

        onDialogOK(param)
        {
       
                this.setState({
                    alertMSG: '',
                    alertMSGTime: 0,
                    loading: 0, 
                });
        }
 
        render()
        {

            if(this.state.loading == 1) 
            {

                return (
                    <div className="_editor">
                        <div className='info_shadow'>                             
                            <div className='modal'>
                                <div class='modal-dialog'>
                
                                <div class='modal-content'>
                
                                    <div className='modal-body'>
                                        <div className='loading'><img src={loading} /><div>Proszę czekać.</div></div>
                                    </div>

                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                 
                )
            }

            // this.state.postanowienia.map( (item,index) => {   
            //     item['isChecked'] = this.state.isChecked;
            // });

            if(this.state.action == "none" )
            {
                return ( <span></span> )
            }

         /*   if(this.state.checkTyp == 'firma')
            {
                var showDaneFirmy = (
                    <div className='FormField inlineField'>  
                        <a href="#" style={{color:"blue"}} onClick={this.onClickDaneFaktura.bind(this) }  title="Dodaj dane do faktury">Dane do faktury <span>{ String.fromCharCode(this.state.arrow) }</span></a>                                         
                    </div>
                )
            }
            if(this.state.checkTyp == 'os_pryw')
            {
                var showDaneFirmy = "";
            }*/

          //  if(this.state.show == 1)
          //  {
                var msgNIP = '';

                if(this.state.msg_nip == 1 ) 
                {
              
                    var msgNIP = (
                        <span style={{color:"red"}}>Firma istnieje już w bazie danych, dane zostaną automatycznie przypisane</span>
                    )
                }

 
                var dane_firmy = '';

                // if( this.state.show == 1  && this.state.show_cd == 0)
                // {
                //     var dane_firmy = (
                //         <div className='FormField inlineField' style={{"marginLeft":20,"verticalAlign":"middle"}}>
                //             <div style={{"fontWeight": "bold","fontSize":12}}>Twoje dane firmowe - w celu zmiany danych skontaktuj się z administratorem</div>
                //             <div className='field300 font-small'>{this.state.rows.nazwa}</div>
                //             <div className='field300 font-small'> ul.{this.state.rows.ulica} {this.state.rows.nr_domu} / {this.state.rows.nr_mieszkania}</div>
                //             <div className='field300 font-small'>{this.state.rows.kod_pocztowy} {this.state.rows.miasto}</div>
                //         </div>
                //     )
                // }

                var showNIP = (
                 <>
                    <div className='col-md-12'>
                      <label className='col-form-label '>Wpisz NIP<span className='required'>*</span> {msgNIP}</label>
                      <input type="text" className={"form-control " + this.state.nn_nip } defaultValue={this.state.rows.nip}  col="nip" onChange={this.onChangeNIP.bind(this) } onPaste={this.onChangeNIP.bind(this)} />
                    </div>
                    {dane_firmy}
                 </>                
                )
           // }
            
          //  if(this.state.show == 0)
          //  {
          //      var showNIP = '';
          //  }

       
                var showCD = (
                    < >

                        <div className='col-md-12 '>
                            <label className='col-form-label '>Nazwa firmy<span className='required'>*</span></label>
                            <input type="text" className={"form-control " + this.state.nn_nazwa } defaultValue={this.state.rows.nazwa}  col="Nazwa" onSelect={this.onChangeNazwa.bind(this) } />
                        </div>
                      
                        <div className='col-md-7'>
                            <label className='col-form-label '>Ulica<span className='required'>*</span></label>
                            <input type="text" className={"form-control " + this.state.nn_ulica } defaultValue={this.state.rows.ulica}  col="Ulica" onSelect={this.onChangeUlica.bind(this) } />
                        </div>

                        <div className='col-md-2'>
                            <label className='col-form-label '>Nr domu<span className='required'>*</span></label>
                            <input type="text" className={"form-control " + this.state.nn_nr_domu } defaultValue={this.state.rows.nr_domu}  col="Nr domu" onSelect={this.onChangeNrDomu.bind(this) } />
                        </div>

                        <div className='col-md-2'>
                            <label className='col-form-label '>Nr mieszkania</label>
                            <input type="text" className={"form-control " + this.state.nn_nr_mieszkania } defaultValue={this.state.rows.nr_mieszkania}  col="Nr mieszkania" onSelect={this.onChangeNrMieszkania.bind(this) } />
                        </div>
                                   
                        <div className='col-md-3'>
                            <label className='col-form-label '>Kod pocztowy<span className='required'>*</span></label>
                            <input type="text" className={"form-control " + this.state.nn_kod_pocztowy } defaultValue={this.state.rows.kod_pocztowy}  col="Kod pocztowy" onSelect={this.onChangeKod.bind(this) } />
                        </div>

                        <div className='col-md-6'>
                            <label className='col-form-label '>Miasto<span className='required'>*</span></label>
                            <input type="text" className={"form-control " + this.state.nn_miasto } defaultValue={this.state.rows.miasto}  col="Miasto" onSelect={this.onChangeMiasto.bind(this) } />
                        </div>

                  
                    </>                      
                )
       

           // if(this.state.show_cd == 0)
          //  {
          //      var showCD = '';
         //   }       


/*
                                    <div className='FormField '>
                                        <label>Zapisz się jako:</label>
                                        <select id="check_typ" className="" name="checkTyp" onChange={this.onCheckDaneFirmy.bind(this)}> 
                                            <option value="os_pryw">Zapisz się jako osoba prywatna</option>
                                            <option value="firma">Zapisz się jako firma</option>
                                        </select>                                                                 
                                    </div>
*/

            return (

            <div className="_editor">
              <div className='info_shadow'>
                    
                <div className='modal'>
                <div class='modal-dialog'>

                  <div class='modal-content'>
                     <div className='modal-header'>
                        <div><b>Dane zapisującego</b></div>  
                    </div>

                    <div className='modal-body'>
                        
                        <div className='container-fluid'>
                           
                            <div className='row g-3'>

                                    <div className='col-md-6'>
                                       <label className='col-form-label '>Imię<span className='required'>*</span></label>
                                       <input type="text" defaultValue={ this.state.dane.imie } className={"form-control " + this.state.nn_imie} onChange={this.onChangeImie.bind(this)} />
                                    </div>

                                    <div className='col-md-6'>
                                       <label className='col-form-label '>Nazwisko<span className='required'>*</span></label>
                                       <input type="text" defaultValue={ this.state.dane.nazwisko } className={"form-control " + this.state.nn_nazwisko} onChange={this.onChangeNazwisko.bind(this)} />
                                    </div>

                                    <div className='col-md-6'>
                                       <label className='col-form-label '>E-mail<span className='required'>*</span></label>
                                       <input type="text" defaultValue={ this.state.dane.email } className={"form-control " + this.state.nn_email} onChange={this.onChangeEmail.bind(this)} />
                                    </div>

                                    <div className='col-md-6'>
                                       <label className='col-form-label '>Telefon<span className='required'>*</span></label>
                                       <input type="text" defaultValue={ this.state.dane.telefon } className={"form-control " + this.state.nn_telefon} onChange={this.onChangeTelefon.bind(this)} />
                                    </div>
                                  
                            </div> 
                         </div>
                        </div>
                                    
                            <div className='modal-header mt-3'>
                                <div><b>Dane firmowe</b></div> 
                            </div>

                            <div className='modal-body'>
                                <div className='container-fluid'>
                           
                                    <div className='row g-3'>

                                    {showNIP}

                                    {showCD}
                                

                                    <div className='col-md-12'>                                        
                                        <FileUploader typ="sk_badania_wnioski" typ_id={this.state.sk_badania_id} apiKey={this.props.apiKey} onlyMyFiles='yes' />                                       
                                    </div>

                                    {
                                        this.state.postanowienia.map( (item,index) => {     
                                            var req = '';
                                            if(item.t0_wymagane == '1')
                                            {
                                                 req = "*"; 
                                            }
                                           
                                            return(                                                
                                                <div className='col-md-12 mt-3 mb-3' key={index}>                                                                                                                             
                                                    <input type="checkbox"  id={item.t0_id} name={item.t0_uq + "_"+item.t0_id+"_"+item.t0_wymagane} value="1" className={this.state.nn_zgody} defaultChecked={this.state.akc_reg} onChange={this.onChangeWymagane.bind(this)} />
                                                    <span className='required'>{req}</span>                                     
                                                    &nbsp;<span  className={this.state.akc_reg_ok } >&nbsp;{item.t0_tytul} </span>                                               
                                                </div>                                                                                                
                                            )
                                        } )
                                     }
                                </div>
                            </div>
                                 

                                  <div className='modal-footer'>
                                        {
                                            this.state.action == "edit" ? <span><ButtonConfirm  onConfirm={this.onConfirmDel.bind(this) }  title="Usuń" query="Na pewno chcesz usunąć ten termin?"  />&nbsp;</span>: ''
                                        }      


                                        <div className='btn btn-secondary' onClick={this.onCloseForm.bind(this)} title="Anuluj">Anuluj</div>                                     
                                        <ButtonConfirm  onConfirm={this.onConfirm.bind(this) }  title="Zleć" button="1" class="btn btn-primary" query="Czy akceptujesz dane?" />   
                                        
                                  </div>

                                     <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime }  msgParam="ok" onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>
           
                        </div>                           
                    </div>
                  </div>

                </div>
              </div>
            </div>
            )
        }
 }

 export default SignUpB;