


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/badania/step1.PNG';
import step2 from '../../helpdesk/images/badania/step2.PNG';
import step3 from '../../helpdesk/images/badania/step3.PNG';
import step4 from '../../helpdesk/images/badania/step4.PNG';
import step5 from '../../helpdesk/images/badania/step5.PNG';
import step6 from '../../helpdesk/images/badania/step6.PNG';
import step7 from '../../helpdesk/images/badania/step7.PNG';
import step8 from '../../helpdesk/images/badania/step8.PNG';

 


class SignUpBadania extends React.Component
 {



  render(){

    return(
        <>
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <div className="imp-note"><p>Uwaga! Badanie nie może zlecić administrator systemu, więc ta opcja będzie zablokowana. Aby użytkownik mógł zlecić badanie wchodzimy w listę badań klikając w Menu BKP LAB w miejscu pokazanym na zrzucie ekranu.</p></div>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>Wyświetla nam się lista badań z informacjami podstawowymi. Chcąc zlecić badanie lub przeczytać więcej informacji o klikamy w przycisk "Więcej informacji".</p>

          <img src={step2} alt=""/> 

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 3</h3>
          <br/>
          <p>Przy tytule badania wyświetla się przycisk "Zleć badanie" a poniżej wszystkie niezbędne i szczegółowe informacje .</p>
          <p>Użytkownik niezarejestrowany / nie zalogowany do systemu może tylko zapoznać się z informacjami szczegółowymi. Użytkownik chcący zlecić badanie musi najpierw zarejestrować / zalogować się do systemu, na co wskazuje czerwony komunikat.</p>

          <img src={step3} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 4</h3>
          <br/> 
          <p>Po zalogowaniu się do systemu, przechodzimy jeszcze raz kroki logowania następnie wypełniamy wszystkie pola z czerwoną gwiazdką. Należy pamiętać, że tylko firmy mogą zlecić badanie.</p>

          <img src={step4} alt=""/>

        </section>



        <section id="method" className="section border-no-sec">

          <h3>Krok 5</h3>
          <br/>
          <p>Niezbędne do zlecenia jest załączenie wniosku w formie pliku w sekcji Materiały/Pliki . Po wypełnieniu wszystkich niezbędnych pól i zaznaczeniu ewentualnych zgód możemy zlecić badanie klikając "Zleć".</p>

          <img src={step5} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 6</h3>
          <br/>
          <p>Po wysłaniu wniosku, użytkownik chcący sprawdzić jaki jest jego status, przechodzi do menu "Badania" gdzie pojawia się jego lista wniosków.</p>

          <img src={step6} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 7</h3>
          <br/>
          <p>Przed wyceną wniosku przez administratora status wniosku ustawiony jest jako nowy, co widać z prawej strony wniosku. W pustym polu zaznaczonym na zrzucie ekranu po zatwierdzeniu wniosku i wystawienia wyceny przez administratorów, wyświetlą się przyciski aby użytkownik mógł zaakceptować tę wycenę po swojej stronie.</p>

          <img src={step7} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 8</h3>
          <br/>
          <p>W tym widoku widzimy wniosek zatwierdzony i wyceniony. Pojawiła się kwota wyceny wniosku i przyciski do jej zaakceptowania bądź anulowania przez użytkownika.</p>

          <img src={step8} alt=""/>

        </section>


        </>

    )
  }



}

export default SignUpBadania;