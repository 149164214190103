

import React from "react";

class RODO extends React.Component
{
 
    constructor(props)
    {
        super(props)
    }
 

  render(){
      
       return (
            <div className="info-wrap bg-primary w-80 m-3 p-lg-5 p-4" style={{lineHeight: "1.5rem"}}>
                <br></br>
                <span className="mb-4 mt-md-4 align-center">
                    <h3>Polityka prywatności RODO </h3>
                </span>
                <br/><br/>
                <div>
                    <b>Bydgoski Klaster Przemysłowy Dolina Przemysłowa</b> w świetle przepisów prawnych jest administratorem danych osobowych Uczestników szkoleń organizowanych przez Klaster. Tym samym, odpowiadamy za wykorzystanie Pani/-Pana danych w sposób bezpieczny, zgodny z przepisami prawa.                    
                </div>
                <br/><br/>
                <span className="mb-4 mt-md-4 align-left">
                    <b>1.	Dane teleadresowe administratora danych osobowych</b>
                </span>
                <br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                        <p>
                        Dane teleadresowe administratora danych osobowych: <b>Bydgoski Klaster Przemysłowy Dolina Przemysłowa, ul. Bydgoskich Przemysłowców 6, 85 – 862 Bydgoszcz.</b>
                        Klaster nie powołał Inspektora Ochrony Danych Osobowych.
                        </p>
                    </div><br/> 
                </div>                
                <br/>
              
                <span className="mb-4 mt-md-4 align-left">
                    <b>2.	Podstawa prawna i cel wykorzystywania danych osobowych uczestników szkoleń</b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">

                    Celem oraz podstawą przetwarzania Pani/-Pana danych osobowych jest: <br/><br/>
                    &nbsp;2.1	<b>realizacja umowy</b> na uczestnictwo w szkoleniach (art. 6 ust. 1 lit. B RODO);<br/>
                    &nbsp;2.2	<b>realizacja obowiązków podatkowych i rachunkowych</b> związanych z zawartymi umowy (art. 6 ust. 1 lit. C RODO);<br/>
                    &nbsp;2.3	<b>zapewnienie komunikacji</b> niezbędnej dla prawidłowej realizacji szkoleń (art. 6 ust. 1 lit. B RODO);<br/>
                    &nbsp;2.4	<b>ustalenie i dochodzenie roszczeń</b> w związku z umowami na uczestnictwo w ww. wydarzeniach (art. 6 ust. 1 lit. B i F RODO);<br/>
                    &nbsp;2.5	<b>informowanie</b> o kolejnych wydarzeniach, w tym poprzez wysyłanie informacji handlowych (art. 6 ust. 1 lit. A i F RODO);<br/>
                    <br/>

                    Klaster nie podejmuje w oparciu o dane zautomatyzowanych decyzji, jak również ich nie profiluje.

                                        
                    </div> 
                </div>                
                <br/>

                <span className="mb-4 mt-md-4 align-left">
                    <b>3.	Dane, które należy nam podać oraz ich źródło</b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                        Dane przetwarzane przez Klaster należą do podstawowych danych identyfikacyjnych i teleadresowych (kontaktowych),
                        a ponadto obejmują wizerunek. Dane zostały nam udostępnione bezpośrednio bądź przez podmiot zgłaszający Pani/-Pana udział w szkoleniu. 
                        Podanie danych osobowych jest dobrowolne, natomiast odmowa podania danych uniemożliwi zawarcie umowy i realizację celów przetwarzania danych,
                        tj. przede wszystkim uniemożliwi udział w szkoleniu.                                        
                    </div><br/> 
                </div>                
                <br/>


                <span className="mb-4 mt-md-4 align-left">
                    <b>4.	Przekazywanie Państwa danych innym podmiotom</b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                    Pani/-Pana w razie potrzeby mogą być przekazane:<br/><br/>
                        4.1	<b>podmiotom przetwarzającym</b> dane w naszym imieniu, uczestniczącym w wykonywaniu naszych czynności:<br/>
                        &nbsp;&nbsp;&nbsp;4.1.1	obsługującym nasze systemy teleinformatyczne lub udostępniającym nam narzędzia teleinformatyczne,<br/>
                        &nbsp;&nbsp;&nbsp;4.1.2	podmiotom świadczącym nam usługi doradcze, pomoc prawną, podatkową, ochrony osób i mienia.<br/><br/>
                        4.2	<b>innym administratorom</b> danych przetwarzającym Pani/-Pana dane we własnym imieniu:<br/>
                        &nbsp;&nbsp;&nbsp;4.2.1	podmiotom prowadzącym działalność pocztową lub kurierską,<br/>
                        &nbsp;&nbsp;&nbsp;4.2.2	bankom prowadzącym działalność płatniczą, w celu dokonania ewentualnych zwrotów na Pani/-Pana rzecz.<br/><br/>
                    </div><br/> 
                </div>                
                <br/>


                <span className="mb-4 mt-md-4 align-left">
                    <b>5.	Przekazywanie danych osobowych poza Europejski Obszar Gospodarczy (EOG) lub organizacjom międzynarodowym</b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                        Pani/-Pana dane osobowe nie będą przekazywane poza EOG (obejmujący Unię Europejską, Norwegię, Liechtenstein i Islandię).                                         
                    </div><br/> 
                </div>                
                <br/>

                <span className="mb-4 mt-md-4 align-left">
                    <b>6.	Okres przetwarzania danych osobowych</b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                        Pani/Pana dane będą przetwarzane do upływu okresu przedawnienia roszczeń związanych z zawartą umową – nie dłużej jednak niż przez okres 6 lat od dnia 
                        zawarcia umowy.              
                    </div><br/> 
                </div>                
                <br/>

                <span className="mb-4 mt-md-4 align-left">
                    <b>7.	Przysługujące Państwu uprawnienia </b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">

                    Może Pan/-Pani złożyć do nas wniosek (dotyczący danych osobowych) o:<br/>
                    •	sprostowanie (poprawienie) danych,<br/>
                    •	usunięcie danych przetwarzanych bezpodstawnie,<br/>
                    •	ograniczenie przetwarzania (wstrzymanie operacji na danych lub nieusuwanie danych – stosownie do złożonego wniosku),<br/>
                    •	dostęp do danych (o informację o przetwarzanych przez nas danych oraz o kopię danych),<br/>
                    •	przeniesienie danych do innego administratora danych lub do Państwa (w zakresie określonym w art. 20 RODO),<br/>
                    •	prawo do wniesienia skargi do organu nadzorczego w razie stwierdzenia, że przetwarzanie Pani/-Pana danych narusza RODO,<br/>
                    •	cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.<br/><br/>

                    Z tych praw może Pani/-Pan skorzystać, składając wniosek pod adresem bkp@klaster.bydgoszcz.pl  lub dzwoniąc pod numer Klastra (numery są dostępne na <a href="https://klaster.bydgoszcz.pl/dane-kontaktowe/">https://klaster.bydgoszcz.pl/dane-kontaktowe/</a>).<br/>
                    Aby mieć pewność, że jest Pan/-Pani uprawniona do złożenia wniosku, możemy prosić o podanie dodatkowych informacji pozwalających nam Panią/-Pana uwierzytelnić.<br/>
                    Zakres każdego z tych praw oraz sytuacje, kiedy można z nich skorzystać, wynikają z przepisów prawa. To, z którego uprawnienia mogą Państwo skorzystać, zależeć będzie np. od podstawy prawnej przetwarzania Państwa danych oraz od celu ich przetwarzania.<br/>
                            
                    </div><br/> 
                </div>                
                <br/>

                <span className="mb-4 mt-md-4 align-left">
                    <b>8.	Prawo sprzeciwu </b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                    W szczególnych sytuacjach mogą Państwo w dowolnym momencie wnieść sprzeciw wobec przetwarzania przez nas Pani/-Pana danych osobowych, jeśli podstawą wykorzystania danych jest nasz prawnie uzasadniony interes. W takiej sytuacji, po rozpatrzeniu wniosku, nie będziemy już mogli przetwarzać danych osobowych objętych sprzeciwem na tej podstawie, chyba że istnieją:<br/>
                    •	ważne prawnie uzasadnione podstawy do przetwarzania danych, które według prawa uznaje się za nadrzędne wobec Państwa interesów, praw i wolności, lub<br/>
                    •	podstawy do ustalenia, dochodzenia lub obrony roszczeń.<br/>

                    </div><br/> 
                </div>                
                <br/>





            </div>

         
       );

  }
}

export default RODO