import React from 'react';

import checkPNG from '../../../image/check.png'
import nonePNG from '../../../image/none.png'
import infoPNG from '../../../image/info.png'
import { Link } from 'react-router-dom';
import ConfigX from '../../../ConfigX';
 
class TableRow extends React.Component
 {
        constructor(props)
        {
            super(props)
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onRowClick(id);
        }

        render()
        {
           
            var status = this.props.row.status;
            if(status == 'robocze') {
                status = (
                    <img src={infoPNG} title="Usługa robocza - niewidoczne"   id={this.props.row.id} onClick={this.onRowClick.bind(this)} />
                )
            }
            else
            if(status == 'widoczne') {
                status = (
                    <img src={checkPNG} title="Usługa aktywna - widoczne"   id={this.props.row.id} onClick={this.onRowClick.bind(this)}  />
                ) 
            }
            else
            if(status == 'niewidoczne') {
                status = (
                    <img src={nonePNG} title="Usługa nieaktywna - niewidoczne"   id={this.props.row.id} onClick={this.onRowClick.bind(this)} />
                ) 
            }
            
            var info = this.props.row.informacje_ogolne;
            if( info.length > 50 ) info = info.substring(0,50);

            return (
                 
                        <tr className='rowLink' >
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} > {this.props.num} </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" > {status}  </td>
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)}>  {this.props.row.podmiot_prowadzacy.nazwa}    </td>
                             <td id={this.props.row.id}   onClick={this.onRowClick.bind(this)}> {this.props.row.info_osoba}  </td>                   
                             <td id={this.props.row.id}   onClick={this.onRowClick.bind(this)}> {this.props.row.tytul}  </td>                   
                             <td id={this.props.row.id}   onClick={this.onRowClick.bind(this)}> {info}  </td>   
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" >{this.props.row.liczba_wnioskow}  </td>                
                             <td id={this.props.row.id}  onClick={this.onRowClick.bind(this)} className="text_center" >{this.props.row.liczba_aktualnych_badan}  </td>                
                        </tr>                 
            )
        }
 }

 export default TableRow;