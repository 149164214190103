import React from 'react';

import ConfigX from '../../../ConfigX'
import Messanger from '../../../components/Messanger';
import AlertYesNo from '../../../components/AlertYesNo';
import ButtonConfirm from '../../../components/ButtonConfirm';


class SzkoleniaRow extends React.Component
 {
        constructor(props)
        {
            super(props)

            this.state = {
                alertMSG: '',
                alertMSGTime: 0,
                alertParam1: '',
                alertParam2: '',
            }
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onClickUczestnik(id);
        }

        onChangeStatus(event)
        {      
            var dt = new Date();
            dt = dt.toISOString();

            var stTmp = event.target.value ;
            if(stTmp == 'potwierdzone') { stTmp = 'POTWIERDZONE'; }
            if(stTmp == 'odrzucone_brak_miejsc') { stTmp = 'ODRZUCONE - BRAK MIEJSC'; }
            if(stTmp == 'odrzucone_odwolanie_szkolenia') { stTmp = 'ODRZUCONE - SZKOLENIE ODWOŁANE'; }
            if(stTmp == 'wypisany') { stTmp = 'UCZESTNIK WYPISANY'; }

            this.setState({
                alertMSG: 'Zmienić status na: ' + stTmp + "? ",
                alertMSGTime: dt,
                alertParam1:event.target.value,
                alertParam2:  this.props.row.id
             }); 
            
           // this.props.onChangeStatusUczestnik(event.target.value, this.props.row.id);
        }

        onDialogYes(msgParam, param1, param2)
        {      

            this.props.onChangeStatusUczestnik(param1, param2);
        }


        delUczestnik(type, param)
        {
            if(type != "yes")  return;

            const text = param.split(" ");
            let px_group_id = text[0];
            let sk_szkolenia_id = text[1];

            this.props.delUczestnik(type, px_group_id, sk_szkolenia_id); 
        }


        onChangeStatusFrekwencja(event)
        {
            var val = event.target.value;
            var id = event.target.getAttribute("id");

            this.props.onChangeStatusFrekwencja(val, id, this.props.row.id);
        }

        render()
        {
  
 
                      
                        return (                 
                                    <tr className={ 'rowLink ' } >                                        

                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="text_center">{this.props.num}</td>
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.nazwa}   </td>                                                      
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.termin}</td>                                                      
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.status}</td>                                                                          
                                                                  
                                    </tr>                 
                        )
        

                
                   
      



        }
 }

 export default SzkoleniaRow;