import React from "react";
import { useEffect, useState } from 'react';

class GUS extends React.Component
{

    constructor(props) 
    {
        super(props)

        this.state = {
            idGus: null,
            nn_nip: '',
            rows: [],

            nip:'',
            dane         : '', 
            imie: '',
            nazwisko: '',            
            email        : '',
            krs          : '',
            telefon      : '',  
            nazwa_firmy        : '',
            ulica        : '',
            nr_lokalu        : '',
            nr_nieruchomosci : '',
            nr_domu      : '',
            miasto       : '',
            regon        : '', 
            gmina: '',
            kod_pocztowy: '',
            poczta: '',
            powiat: '',
            silosID: '',
            typ: '',
            wojewodztwo: '',               
        }
    }



    isValidNip(nip) 
    {
        if(typeof nip !== 'string')
            return false;
    
        nip = nip.replace(/[\ \-]/gi, '');
    
        let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(nip.substring(9, 10));
        let weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
            sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        }
        
        return sum % 11 === controlNumber;
    }

    onChangeNIP(event)
    {
            var v   = event.target.value;
            var ok = "";

            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            if(!this.isValidNip(v))
            { 
                ok = "incorrect"; 

                this.setState( {                
                    nn_nip: ok
                } );
            }else { 
                ok="correct"; 

                //var firma = this.state.firma;
                //firma["nip"] = v; 

                this.setState( {
                    //firma: firma,
                    nn_nip: ok
                } );


                this.setState({
                    nip:v
                })
                
                this.checkNIP(v); 

                // this.getDataGUS(this.state.nip);                  
                

            }  


            
    }

    convertHTMLEntity(text){
        const span = document.createElement('span');
    
        return text
        .replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }

    goLogin()
    {
        var dataPost = "" ;
        dataPost +=  '<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:ns="http://CIS/BIR/PUBL/2014/07">';
        dataPost +=        '<soap:Header xmlns:wsa="http://www.w3.org/2005/08/addressing">';
        dataPost +=            '<wsa:To>https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc</wsa:To>';
        dataPost +=            '<wsa:Action>http://CIS/BIR/PUBL/2014/07/IUslugaBIRzewnPubl/Zaloguj</wsa:Action>';
        dataPost +=        '</soap:Header>';
        dataPost +=        '<soap:Body>';
        dataPost +=        '<ns:Zaloguj>';
        dataPost +=            '<ns:pKluczUzytkownika>e8f54f6323d24f46a78e</ns:pKluczUzytkownika>';
        dataPost +=        '</ns:Zaloguj>';
        dataPost +=        '</soap:Body>';
        dataPost +=    '</soap:Envelope>';


        fetch( "wsBIR/UslugaBIRzewnPubl.svc", {
            method: 'POST', 
            body: dataPost,  
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc',
                'Access-Control-Allow-Methods': 'POST, OPTIONS',
                'Content-Type': 'application/soap+xml;charset=UTF-8;action="http://CIS/BIR/PUBL/2014/07/IUslugaBIRzewnPubl/Zaloguj"',                                                
            }
        })       
         .then((data) => data.text())
         .then(str => {

            let start = str.search("<ZalogujResult>");
            let stop  = str.search("</ZalogujResult>");
            let id    = str.slice(start + 15, stop);

            this.setState({
                idGus: id
            })

         });
         
    }

    checkNIP(nip)
    {

        var dataPost = `<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:ns="http://CIS/BIR/PUBL/2014/07" xmlns:dat="http://CIS/BIR/PUBL/2014/07/DataContract">    
            <soap:Header xmlns:wsa="http://www.w3.org/2005/08/addressing"> 
            <wsa:To>https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc</wsa:To>
            <wsa:Action>http://CIS/BIR/PUBL/2014/07/IUslugaBIRzewnPubl/DaneSzukajPodmioty</wsa:Action> 
            </soap:Header>    
            <soap:Body>       
                <ns:DaneSzukajPodmioty>          
                <ns:pParametryWyszukiwania>                     
                    <dat:Nip>`+nip.trim()+`</dat:Nip>                                
                    </ns:pParametryWyszukiwania>       
                </ns:DaneSzukajPodmioty>   
            </soap:Body> 
            </soap:Envelope>` ;
        


        fetch( "wsBIR/UslugaBIRzewnPubl.svc", {
            method: 'POST', 
            body: dataPost,  
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc',
                'Access-Control-Allow-Methods': 'POST, OPTIONS',
                'Content-Type': 'application/soap+xml;charset=UTF-8;action="http://CIS/BIR/PUBL/2014/07/IUslugaBIRzewnPubl/DaneSzukajPodmioty"',   
                'sid': this.state.idGus                                             
            }
        })       
         .then((data) => data.text())
         .then(str => {

            let server_output = this.convertHTMLEntity(str);

            let start = server_output.search("<dane>");
            let stop  = server_output.search("</dane>");
            let dane  = server_output.slice(start + 5, stop);

            if(Number.isInteger(start) && Number.isInteger(stop))
            {                
                let rowsW = dane.split("\n");                                        
                let assoc = [];

                rowsW.forEach((element) => {

                    let exT = element.split("</");
                    if(exT.length == 2)
                    {
                        let tr = exT[0].split(">");
                        if(tr.length == 2)
                        {
                            let expw = tr[0].split("<");
                            assoc[expw[1]] = tr[1];
                        }
                    }                                      
                });

                this.setState({
                    rows: assoc
                })
               
             /*   if(assoc.length > 0)
                {
                    this.setState({
                        gmina: assoc['Gmina'],
                        kod_pocztowy: assoc['KodPocztowy'],
                        miasto: assoc['Miejscowosc'],
                        poczta: assoc['MiejscowoscPoczty'],
                        nazwa_firmy: assoc['Nazwa'],
                        nip: assoc['Nip'],
                        nr_lokalu: assoc['NrLokalu'],
                        nr_nieruchomosci: assoc['NrNieruchomosci'],
                        powiat: assoc['Powiat'],
                        regon: assoc['Regon'],
                        silosID: assoc['SilosID'],
                        typ: assoc['Typ'],
                        ulica: assoc['Ulica'],
                        wojewodztwo: assoc['Wojewodztwo'],
                    })

                // this.getDataGUS();
                }  */

            }else{
                return null;
            }

         });
        
        
    }

    checkREGON(regon,silosID)
    {
       
        let nazwaRaportu = '';
        if(silosID == '1') nazwaRaportu = "BIR11OsFizycznaDzialalnoscCeidg";
        if(silosID == '2') nazwaRaportu = "BIR11OsFizycznaDzialalnoscRolnicza";
        if(silosID == '3') nazwaRaportu = "BIR11OsFizycznaDzialalnoscPozostala";
        if(silosID == '4') nazwaRaportu = "";
        if(silosID == '6') nazwaRaportu = "BIR11OsPrawna";
   
        let dataPost = `<soap:Envelope xmlns:soap='http://www.w3.org/2003/05/soap-envelope' xmlns:ns='http://CIS/BIR/PUBL/2014/07'>
                <soap:Header xmlns:wsa='http://www.w3.org/2005/08/addressing'>
                <wsa:To>https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc</wsa:To>
                <wsa:Action>http://CIS/BIR/PUBL/2014/07/IUslugaBIRzewnPubl/DanePobierzPelnyRaport</wsa:Action>
                </soap:Header>
                    <soap:Body>
                    <ns:DanePobierzPelnyRaport>
                        <ns:pRegon>`+ regon.trim() +`</ns:pRegon>
                        <ns:pNazwaRaportu>`+nazwaRaportu+`</ns:pNazwaRaportu>  
                    </ns:DanePobierzPelnyRaport>
                </soap:Body>
                </soap:Envelope>`;
  
        fetch( "wsBIR/UslugaBIRzewnPubl.svc", {
            method: 'POST', 
            body: dataPost,  
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc',
                'Access-Control-Allow-Methods': 'POST, OPTIONS',
                'Content-Type': 'application/soap+xml;charset=UTF-8;action="http://CIS/BIR/PUBL/2014/07/IUslugaBIRzewnPubl/DanePobierzPelnyRaport"',   
                'sid': this.state.idGus                                             
            }
        })       
         .then((data) => data.text())
         .then(str => {

            let server_output = this.convertHTMLEntity(str);

            let start = server_output.search("<dane>");
            let stop  = server_output.search("</dane>");
            let dane  = server_output.slice(start + 5, stop);

            if(Number.isInteger(start) && Number.isInteger(stop))
            {
                let rowsW = dane.split("\n");                                        
                let assoc = [];

                rowsW.forEach((element) => {

                    let exT = element.split("</");
                    if(exT.length == 2)
                    {
                        let tr = exT[0].split(">");
                        if(tr.length == 2)
                        {
                            let expw = tr[0].split("<");
                            assoc[expw[1]] = tr[1];
                        }
                    }                                      
                });
                    
            

                if(this.state.silosID == 1 || this.state.silosID == 2 || this.state.silosID == 3) //CEIDG , Rolnicza, Inna np.komornik
                {   



                    this.setState({
                        gmina: assoc['fiz_adSiedzGmina_Nazwa'],
                        kod_pocztowy: assoc['fiz_adSiedzKodPocztowy'],
                        miasto: assoc['fiz_adSiedzMiejscowosc_Nazwa'],
                        nazwa_firmy: assoc['fiz_nazwa'],
                        nr_lokalu: assoc['fiz_adSiedzNumerLokalu'],
                        nr_nieruchomosci: assoc['fiz_adSiedzNumerNieruchomosci'],
                        powiat: assoc['fiz_adSiedzPowiat_Nazwa'],
                        regon: assoc['fiz_regon9'],
                        ulica: assoc['fiz_adSiedzUlica_Nazwa'],
                        wojewodztwo: assoc['fiz_adSiedzWojewodztwo_Nazwa'],
                        email: assoc['fiz_adresEmail'],
                        telefon: assoc['fiz_numerTelefonu']
                    })

                }                          
                
                if(this.state.silosID == 6) //Os.Prawna
                {

                    this.setState({
                        gmina: assoc['praw_adSiedzGmina_Nazwa'],
                        kod_pocztowy: assoc['praw_adSiedzKodPocztowy'],
                        miasto: assoc['praw_adSiedzMiejscowosc_Nazwa'],
                        nazwa_firmy: assoc['praw_nazwa'],
                        nr_lokalu: assoc['praw_adSiedzNumerLokalu'],
                        nr_nieruchomosci: assoc['praw_adSiedzNumerNieruchomosci'],
                        powiat: assoc['praw_adSiedzPowiat_Nazwa'],
                        regon: assoc['praw_regon9'],
                        ulica: assoc['praw_adSiedzUlica_Nazwa'],
                        wojewodztwo: assoc['praw_adSiedzWojewodztwo_Nazwa'],
                        email: assoc['praw_adresEmail'],
                        telefon: assoc['praw_numerTelefonu']
                    })

                }



            }else{
                return null;
            }

         });
        

    }

    checkDanePodmiotu(regon)
    {

        var dataPost = `<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:ns="http://CIS/BIR/PUBL/2014/07">
            <soap:Header xmlns:wsa="http://www.w3.org/2005/08/addressing">
            <wsa:To>https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc</wsa:To>
            <wsa:Action>http://CIS/BIR/PUBL/2014/07/IUslugaBIRzewnPubl/DanePobierzPelnyRaport</wsa:Action>
            </soap:Header>
                <soap:Body>
                <ns:DanePobierzPelnyRaport>
                    <ns:pRegon>`+regon.trim()+`</ns:pRegon>
                    <ns:pNazwaRaportu>BIR11OsFizycznaDaneOgolne</ns:pNazwaRaportu>
                </ns:DanePobierzPelnyRaport>
            </soap:Body>
            </soap:Envelope>` ;


        fetch( "wsBIR/UslugaBIRzewnPubl.svc", {
            method: 'POST', 
            body: dataPost,  
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://wyszukiwarkaregon.stat.gov.pl/wsBIR/UslugaBIRzewnPubl.svc',
                'Access-Control-Allow-Methods': 'POST, OPTIONS',
                'Content-Type': 'application/soap+xml;charset=UTF-8;action="http://CIS/BIR/PUBL/2014/07/IUslugaBIRzewnPubl/DanePobierzPelnyRaport"',   
                'sid': this.state.idGus                                             
            }
        })       
         .then((data) => data.text())
         .then(str => {

            let server_output = this.convertHTMLEntity(str);

            let start = server_output.search("<dane>");
            let stop  = server_output.search("</dane>");
            let dane  = server_output.slice(start + 5, stop);

            if(Number.isInteger(start) && Number.isInteger(stop))
            {                
                let rowsW = dane.split("\n");                                        
                let assoc = [];

                rowsW.forEach((element) => {

                    let exT = element.split("</");
                    if(exT.length == 2)
                    {
                        let tr = exT[0].split(">");
                        if(tr.length == 2)
                        {
                            let expw = tr[0].split("<");
                            assoc[expw[1]] = tr[1];
                        }
                    }                                      
                });
            
                this.setState({
                    imie: assoc['fiz_imie1'],
                    nazwisko: assoc['fiz_nazwisko']
                })


            }else{
                return null;
            }

         });
        
        
    }    
            
    componentDidMount() 
    {
       //console.log("Det did mount");
       if(this.state.idGus == null)
       {
          this.goLogin();          
       }
    
    }

    componentDidUpdate(){

    }

    getDataGUS()
    {      

    console.log("idGUS: ",this.state.idGus);

        if(this.state.idGus)
        {

        console.log("data: ",this.state);
         
                    this.checkREGON( this.state.regon, this.state.silosID); // pobranie danych

               /*     if(this.state.regon.length == 0)
                    {
                        this.checkNIP(this.state.nip); //jeżeli pusto pobranie podstawowych danych 
                                               
                    }else{
                        
                        let krs_ex   = this.state.krs.split("/");
                       
                    }

                 
                
                     this.state.nazwa_firmy.replace("&amp;","&");
                     this.state.nazwa_firmy.replace("&amp","&"); 
                     this.state.nazwa_firmy.replace("&AMP;","&");
                     this.state.nazwa_firmy.replace("&AMP","&");
                
                    if(this.state.nr_nieruchomosci != '')
                    {
                        let lok =  this.state.nr_lokalu.trim();

                        this.setState({
                            nr_lokalu: lok
                        })
                        

                        if(this.state.nr_lokalu != ''){
                            let nie = this.state.nr_nieruchomosci.trim();
                            this.setState({
                                nr_nieruchomosci: nie
                            })
                         }else{
                            let nie = this.state.nr_nieruchomosci.trim()+'/';
                            this.setState({
                                nr_nieruchomosci: nie
                            })
                            
                         }
                    }

                    this.state.nr_domu      = this.state.nr_nieruchomosci+this.state.nr_lokalu;
                

                    let error = '';

                    let kod1 = '';
                    let kod2 = '';


                  if(Number.isInteger(this.state.kod_pocztowy))
                    {                        

                        if(this.state.kod_pocztowy.indexOf('-') == 0)
                        {
                             kod1         = this.state.kod_pocztowy.substr(0,2);
                             kod2         = this.state.kod_pocztowy.substr(2,3);

                             kod_pocztowy = kod1 +'-'+ kod2;                           
                        }else{
                             kod1         = this.state.kod_pocztowy.substr(0,2);
                             kod2         = this.state.kod_pocztowy.substr(2,3);
                             kod_pocztowy = kod1+kod2;                           
                        }
                    }else{
                             kod1         = this.state.kod_pocztowy.substr(0,2);
                             kod2         = this.state.kod_pocztowy.substr(2,3);
                             kod_pocztowy = kod1+kod2;                         
                    }*/
                    

                   // this.checkDanePodmiotu(this.state.regon); //pobranie imienia i nazwiska

       
         
        
            
        }
    } 
    
   /* 
    getDataGUS2(nip)
    {      

    console.log("idGUS: ",this.state.idGus);

        if(this.state.idGus)
        {           
            const data = this.checkNIP(nip);
       // console.log("data: ",data);

            if(data.length > 0)
            {
                if(!data['ErrorMessagePl'])
                {        
                    let dane         = '';             
                    let email        = '';
                    let krs          = '';
                    let telefon      = '';  
                    let nazwa        = '';
                    let ulica        = '';
                    let nr_lokalu        = '';
                    let nr_nieruchomosci = '';
                    let nr_domu      = '';
                    let miasto       = '';
                    let regon        = '';

                    dane  = this.checkREGON( data['Regon'], data['SilosID']); // pobranie danych

                    if(dane['Regon'].length == 0)
                    {
                        dane  = this.checkNIP(nip); //jeżeli pusto pobranie podstawowych danych 
                                               
                    }else{
                        email        = dane['Email'];
                        let krs_ex   = dane['KRS'].split("/");
                        krs          = krs_ex[0];
                        telefon      = dane['Telefon'];   
                    }

                    nazwa = dane['Nazwa'];
                    ulica = dane['Ulica'];
                
                    nazwa = nazwa.replace("&amp;","&");
                    nazwa = nazwa.replace("&amp","&");
                    nazwa = nazwa.replace("&AMP;","&");
                    nazwa = nazwa.replace("&AMP","&");
                
                    if(dane['NrNieruchomosci'] != '')
                    {
                        nr_lokalu        = dane['NrLokalu'].trim();

                        if(nr_lokalu != ''){
                            nr_nieruchomosci = dane['NrNieruchomosci'].trim();
                         }else{
                            nr_nieruchomosci = dane['NrNieruchomosci'].trim()+'/';
                         }
                    }

                    nr_domu      = nr_nieruchomosci+nr_lokalu;
                
                    let imie = '';
                    let nazwisko = '';
                    let error = '';

                    let kod1 = '';
                    let kod2 = '';
                    let kod_pocztowy = '';

                    if(Number.isInteger(dane['KodPocztowy']))
                    {                        

                        if(dane['KodPocztowy'].indexOf('-') == 0)
                        {
                             kod1         = dane['KodPocztowy'].substr(0,2);
                             kod2         = dane['KodPocztowy'].substr(2,3);
                             kod_pocztowy = kod1 +'-'+ kod2;                           
                        }else{
                             kod1         = dane['KodPocztowy'].substr(0,2);
                             kod2         = dane['KodPocztowy'].substr(2,3);
                             kod_pocztowy = kod1+kod2;                           
                        }
                    }else{
                             kod1         = dane['KodPocztowy'].substr(0,2);
                             kod2         = dane['KodPocztowy'].substr(2,3);
                             kod_pocztowy = kod1+kod2;                         
                    }
                    
                    miasto       = dane['Miejscowosc'];
                    regon        = dane['Regon'];                      
                    
                    dane = this.checkDanePodmiotu(regon); //pobranie imienia i nazwiska

                    if(!dane)
                    {
                         imie     = dane['Imie'];
                         nazwisko = dane['Nazwisko'];
                    }else{
                         error = data['ErrorMessagePl'];                       
                    }
                }
            }
        }
    }                
      */      


 render()
 {
//console.log("state: ",this.state);


    return (
       <>
    

      
            <div className="col-5">
                <label className="labels">NIP (Po podaniu, wyszukuje dane w GUS)</label>                                            
                <input type="text" className={"form-control mb-3  " } defaultValue={ this.props.row.nip }  col="nip" onChange={this.onChangeNIP.bind(this) } onPaste={this.onChangeNIP.bind(this)} />
            </div>
        </>
    )
 }
}


export default GUS