


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/raport/preview/step1.PNG';
import step2 from '../../helpdesk/images/raport/preview/step2.png';
import step3 from '../../helpdesk/images/raport/preview/step3.png';

 


class preview extends React.Component
 {



  render(){

    return(
        <>
        
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>W miejscu "Raporty" znajdziemy jeden na tę chwilę raport, nazwa wskazuje liczbę mnogą dlatego, że można w przyszłości rozbudowywać tę zakładkę.</p>
          <p>Widoczny raport tyczy się firm i przypisanych im użytkownikom, któzy zapisali się na szkolenia.</p>
          <div className="imp-note"><p>Nad tabelką widać ikonkę CSV "Generuj raport w CSV", która pozwala na wygenerowanie raportu w pliku CSV z wyświetlonych pozycji w tabeli.</p></div>

          <img src={step1} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">
          <h3>Krok 2</h3>
          <br/>
          <p>Pierwszy filtr pozwala na wybór firmy normalnej z NIPem ale także jak widać na zrzucie wkranu firmy bez NIPu, czyli wskazuje na to, że należą do zwykłych użytkowników. </p>
          <div className="imp-note"><p>Kiedy wybierzemy jakąś firmę, system wskaże nam ilość osób z tej firmy jakie brały udział w jakich szkoleniach i o jakich datach. Widoczny jest także status użytkownika po zapisaniu się na szkolenie, ponieważ może się okazać, że ta osoba zapisała się dopiero na szkolenie a jest jeszcze nie potwierdzona przez administratorów. </p></div>

          <img src={step2} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">
          <h3>Krok 3</h3>
          <br/>
          <p>Drugi filtr pozwala na filtrowanie po nazwach szkoleń, czyli wybieramy konkretne szkolenie, które wskaże nam jakie firmy i ilu pracowników tych firm brało udział w tym szkoleniu.</p>
          <div className="imp-note">
            <p>Filtry można używać w wielu kombinacjach, np. wybieramy jedną firmę i jedno szkolenie w filtrach po czym wyświetlają się nam użytkownicy biorący udział w tym szkoleniu.</p>
            <p>Możemy wybrać wszystkie firmy a jedno szkolenie, czy wszystkie szkolenia a jedną firmę.</p>
            <p>Dodatkowo są jeszcze filtry do wyszukiwania po NIPie i dacie szkolenia. Wszystkie filtry mogą być wypełnione i aktywne.</p>
          </div>
                   

          <img src={step3} alt=""/>

        </section>        

        </>

    )
  }



}

export default preview;