import React from 'react';

import ConfigX from '../../../ConfigX'
//import SearchBar from './SearchBar';

import SearchBar from './SearchBar';
import Pagger from '../../../components/Pagger';
import SortBy from '../../../components/SortBy';
import Table from './Table';

import AlertMsg from '../../../components/AlertMsg';
import EmptyTab from '../../../components/EmptyTab';
import loading from '../../../image/loading.gif'

import { CSVLink } from "react-csv";
import CsvIcon from '../../../image/csv_icon.png';

class Main extends React.Component
 {

            //-----------filter memory-----------

            uqFilter = "2uu332sdf";


            setToFilter(xName, xValue)
            {
                const filterJSON = localStorage.getItem("filter-"+this.uqFilter);

                var tab = {};

                if(filterJSON != null && filterJSON != undefined)
                {
                    var tab =  JSON.parse(filterJSON);

                    tab[xName] = xValue;
                }

                console.log('setToFilter', tab );
                localStorage.setItem("filter-"+this.uqFilter, JSON.stringify(tab) );
            }

            getFromFilter(xName)
            {
                const filterJSON = localStorage.getItem("filter-"+this.uqFilter);

                var tab = {};

                if(filterJSON != null && filterJSON != undefined)
                {
                    var tab =  JSON.parse(filterJSON);

                    if(tab[xName]) return tab[xName];
                }

                return '';
            }

            goClearFilter()
            {
                const filterJSON = localStorage.getItem("filter-"+this.uqFilter);
                var tab = {};
                localStorage.setItem("filter-"+this.uqFilter, JSON.stringify(tab) );
            }

            
            //-----------------------------------




    constructor(props)
    {
        super(props);         


        var idF = this.getFromFilter('id_firmy');
        if(idF.length < 1) idF = '_x_';

        var idFS= this.getFromFilter('id_szkolenia');
        if(idFS.length < 1) idFS = '_x_';

        var tOd = this.getFromFilter('od');
        var tDo = this.getFromFilter('do');

      //  if(tOd.length < 1) tOd = 1606878000000;
      //  if(tDo.length < 1) tDo = 1606878000000;


        this.state = {

            refresh: props.refresh,

            search_bar: {                                                                             
                    fraza: this.getFromFilter('fraza')+"dfsdf", 
                    id_firmy: idF,                  
                    id_szkolenia: idFS,                                                     
                    id: this.getFromFilter('id'),                    
                    nip: this.getFromFilter('nip'),       
                   // od: tOd,
                   // do: tDo,
            },

            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },

            loading: 1,
            view: "list",
            selectedId: -1,
            rows: [],
            result: [],
            raport: [],


            alertMSG: '',
            alertMSGTime: 0,                     
        }
  
    }



  
    //Filtry..
    changeFilter(filterName, filterValue)
    {                                 
       
        this.setToFilter(filterName, filterValue);

            var searchTmp = this.state.search_bar;
            searchTmp[filterName] = filterValue;                         

                    this.setState({
                        search_bar:  searchTmp,                            
                        view: "list",
                        selectedId: -1
                    });                 

            this.getData();
    }


    //Stronnicowanie...
    changePage(page)
    {
        

       //console.log("changePage: " + page );    
       this.state.pagger.page = parseInt(page);          
       this.getData(null);      
    }

    changeLimit(limit)
    {
      
       // console.log("changeLimit: " + limit );                        
        this.state.pagger.page = 1;
        this.state.pagger.limit = parseInt(limit);
        this.getData(null);
    }

    //Sortowanie..

    changeSort(column, sort)
    {
         console.log("changeSort: " + column + " | "  + sort );    

         this.state.pagger.order_col = column;
         this.state.pagger.order = sort;
         this.getData(null);
    }

    //Wyszukaj..
    goSearch(){
       
        this.getData(null);
    }
        
    //Wyczszczenie parametrów wyszukiwania
    goClear()
    {          
        this.goClearFilter();

        this.setState(
            {
                search_bar:
                {                                                                
                    id_szkolenia: "_x_",
                    id_firmy: "_x_",          
                    id: "",                    
                },
                view: "list",
                selectedId: -1
            }
        );
        
        setTimeout(() => {
             this.getData(null);                
        }, 100);
     
    }

    //Pobranie danych
    getData(  onFinish )
    {

        //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
        var wait = setTimeout(
             () => {
                this.setState({ loading: 1 });
             }
            ,500);

           

        var tmpNonRef = JSON.stringify( this.state );
        tmpNonRef = JSON.parse(tmpNonRef);

        var dataPost = tmpNonRef.search_bar;
        dataPost.pagger = tmpNonRef.pagger;

        fetch( ConfigX.restApi + "/dash,getDataRaport" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        
             //console.log(JSON.stringify(json) );
             clearTimeout(wait);

             this.setState({
                rows: json.rows,
                raport: json.raport,
                result: json.result,
                loading: 0
             }); 
                         
             if(onFinish != null && onFinish != undefined ) onFinish();
           
        });
    }
        
 
    redirect(action, id, param)
    {
      

        if(param == "deleted") //usunięto szkolenie
        {
            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: "Osoba została usunięta!",
                alertMSGTime: dt
            });                  
        }

 
       

        if(action == "list")
        {
            this.getData(null);
        }
     
        
        this.setState(
            {
                view: action,
                selectedId: id,
            }
           );
    }

    componentDidUpdate()
    {
        //console.log(document.location.href + " eee ");
        if(this.state.refresh != this.props.refresh)
        {   
            this.setState( {refresh: this.props.refresh, view: "list" }  );
        }
    }

    componentDidMount()
    {
        //console.log("List mount " + this.state.search_bar);    
        this.getData(null);                     
    }

    
    onDialogOK(param)
    {
        console.log("click ok dialog");
            this.setState({
                alertMSG: '',
                alertMSGTime: 0,
            });
    }


  
    onClickAddNew(event)
    {
        //this.redirect("new",-1,"");

        if(this.state.new_nip.length < 4 || this.state.nn_new_nip == 'incorrect')
        {
            this.setState({
                alertMSG: 'Podaj prawidłowy nip',
                alertMSGTime: 12,
            });

            return;
        }

        var dataPost = {
            id: -1,       
            nazwa: "Podaj nazwę firmy",       
            nip: this.state.new_nip,       
            regon: "",       
            ulica: "Brak..",       
            nr_domu: "1",       
            nr_mieszkania: "",       
            kod_pocztowy: "00-000",       
            miasto: "...",       
            telefon: "",       
            email: "",       
            kr_nazwa: "",       
            kr_ulica: "",        
            kr_nr_domu: "", 
            kr_nr_mieszkania: "", 
            kr_kod_pocztowy:"00-000", 
            kr_miasto:"", 
            osoba_reprezentujaca: "", 
            informacje_dodatkowe: "",   
        };


        fetch( ConfigX.restApi + "/dash,add_edit_firm" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
        
            if(json.status == "OK")
            {
                var id = json.id;
                
                this.setState({
                    loading: 1
                });


                this.getData( () => {

                        setTimeout(()=>{  //poczekaj az zakończy rendering..
                            this.redirect("det",id,"added");
                        } ,300);                   
                });              
            }                 
        });
    } 



    render()
    {
     
     
        console.log("SearchBar",this.state.search_bar);

        if(this.state.loading == 1) 
        {
 
            return (
              <div className='list'>

               <SearchBar apiKey={this.props.apiKey} search_bar={this.state.search_bar} refresh={this.state.refresh} goSearch={this.goSearch.bind(this)} goClear={this.goClear.bind(this)}  changeFilter={this.changeFilter.bind(this)}/>                                     

                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>

              </div>
            )
        }
        
        var headers = [
            { label: "LP", key: "lp" },
            { label: "Nazwa firmy", key: "nazwa" },
            { label: "Imię", key: "imie" },
            { label: "Nazwisko", key: "nazwisko" },
            { label: "Nazwa szkolenia", key: "nazwa_szkolenia"},
            { label: "Data szkolenia", key: "data_szkolenia"},                        
            { label: "Status", key: "status" },
          ];
              
   
          var arr = [];
          var dane = this.state.raport; 

    /*      for(var i = 0; i < dane.length; i++)
          {
             var newRow = [];
             var raport = dane[i].raport;
          
             for(var i2 = 0; i2 < raport.length; i2++)
            {                           
                var elem = raport[i2];            

                newRow.push(i2+1);
                newRow.push(elem.nazwa);
                newRow.push(elem.imie);
                newRow.push(elem.nazwisko);
                newRow.push(elem.nazwa_szkolenia);
                newRow.push(elem.data_szkolenia); 
                newRow.push(elem.status);    

               
 
            };
            arr.push(newRow);

           // arr[i] = newRow; 
          }

    console.log("arr: ",arr);   */

          const CSV = (
            <>         
                <CSVLink data={dane} headers={headers} separator={";"} filename={"Raport szkoleń.csv"}>

                    <div className='d-flex ' >
                        <div className='link d-flex align-items-center'><img src={CsvIcon} alt="Lista firm CSV" style={{height:"32px"}}/>Generuj raport w CSV</div>                    
                    </div>

                </CSVLink>
            </>                  
          )



        if(this.state.rows.length >= 10)
        {
 
             return (                
                 <div className='list'>
                     
                    <SearchBar apiKey={this.props.apiKey} search_bar={this.state.search_bar} refresh={this.state.refresh} goSearch={this.goSearch.bind(this)} goClear={this.goClear.bind(this)}  changeFilter={this.changeFilter.bind(this)}/>                                     
 
                    <SortBy  changeSort={this.changeSort.bind(this) }  result={this.state.result} />
                    <Pagger page={this.state.result.page} pages={this.state.result.pages} limit={this.state.result.limit} changePage={this.changePage.bind(this)} changeLimit={this.changeLimit.bind(this)} />
          
                    {CSV}

                    <Table rows={this.state.rows} search_bar={this.state.search_bar} redirect={this.redirect.bind(this)}/>

                    <SortBy changeSort={this.changeSort.bind(this) }    result={this.state.result} />
                    <Pagger page={this.state.result.page} pages={this.state.result.pages} limit={this.state.result.limit} changePage={this.changePage.bind(this)} changeLimit={this.changeLimit.bind(this)} />   

                
                     <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime }  msgParam="ok" onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>
           
                 </div>
             )
 
        }else
        {
 
             return (
                 <div className='list'>
  
                    <SearchBar apiKey={this.props.apiKey} search_bar={this.state.search_bar} refresh={this.state.refresh} goSearch={this.goSearch.bind(this)} goClear={this.goClear.bind(this)}  changeFilter={this.changeFilter.bind(this)}/>                                     
                    
                    <SortBy  changeSort={this.changeSort.bind(this) }  result={this.state.result} />
                    <Pagger page={this.state.result.page} pages={this.state.result.pages} limit={this.state.result.limit} changePage={this.changePage.bind(this)} changeLimit={this.changeLimit.bind(this)} />

                    {CSV}

                    <Table rows={this.state.rows} search_bar={this.state.search_bar} redirect={this.redirect.bind(this)}/>

                    <SortBy changeSort={this.changeSort.bind(this) }    result={this.state.result} />
                    <Pagger page={this.state.result.page} pages={this.state.result.pages} limit={this.state.result.limit} changePage={this.changePage.bind(this)} changeLimit={this.changeLimit.bind(this)} />   


                    <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime }  msgParam="ok" onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>

                 </div>
             )
 
        }
 

        
    }
 }


 export default Main