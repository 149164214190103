import React from "react";


class Questionnaire extends React.Component
{


 

 render()
 {

    

    const mystyle = {
        color: "black", 
        padding: "0",
        margin: "0",
        fontFamily: "Arial",
        fontSize: "16px",
        fontWeight:"normal"    
      };

      const styleTab = {
        width: "100%",
        fontSize: "12px",
      }


      const styleTr = {
        backgroundColor: "#ddffdd"
      }

      const title = {
        fontWeight: "bold",
        fontSize: "22px",
        textAlign: "center"
      }

      const oceny = {
        textAlign: "center",
        padding: "5px"
      }

      const pyt1 = {}
      const pyt2 = {}
      const pyt3 = {}
      const pyt4 = {}
      const pyt5 = {}
      const pyt6 = {}
      const pyt7 = {}
      const pyt8 = {}
      const pyt9 = {}
      const pyt10 = {}

      var data_szkolenia = this.props.data;

    return (
        <div  className="printView" style={ mystyle } >

            <table cellPadding="30">
              <tr>
                <td style={{padding: "20px"}}>

                  Nazwa szkolenia: {this.props.title} <br/><br/>
                  Data: {data_szkolenia}<br/>

                </td>
              </tr>
            </table>

            <br/>

            <div style={title}>ANKIETA OCENY SZKOLENIA</div> <br/>

            <div style={pyt1}>
              1.	Proszę odpowiedzieć na pięciostopniowej skali, w jakim stopniu odbyte szkolenie spełniło Pani/Pana oczekiwania? (1 - niewystarczająco, 5 – bardzo dobrze)<br/>
              <table>
                <tr>
                  <td style={oceny}>1</td>
                  <td style={oceny}>2</td>
                  <td style={oceny}>3</td>
                  <td style={oceny}>4</td>
                  <td style={oceny}>5</td>
                </tr>
              </table>              
            </div>

            <br/>

            <div style={pyt2}>
              2.	Proszę ocenić na pięciostopniowej skali, w jakim stopniu przekazane podczas szkolenia informacje pogłębiły Pani/Pana wiedzę teoretyczną z omawianego na szkoleniu obszaru? (1 - niewystarczająco, 5 – bardzo dobrze)<br/>
              <table>
                <tr>
                  <td style={oceny}>1</td>
                  <td style={oceny}>2</td>
                  <td style={oceny}>3</td>
                  <td style={oceny}>4</td>
                  <td style={oceny}>5</td>
                </tr>
              </table>              
            </div>

            <br/>

            <div style={pyt3}>
              3.	Proszę odpowiedzieć na pięciostopniowej skali, jak ocenia Pani/Pan przydatność zdobytych umiejętności pod względem podnoszenia własnych kompetencji ? (1 - niewystarczająco, 5 – bardzo dobrze))<br/>
              <table>
                <tr>
                  <td style={oceny}>1</td>
                  <td style={oceny}>2</td>
                  <td style={oceny}>3</td>
                  <td style={oceny}>4</td>
                  <td style={oceny}>5</td>
                </tr>
              </table>              
            </div>

            <br/>

            <div style={pyt4}>
              4.	Proszę ocenić posługując się pięciostopniową skalą, jakość i przydatność materiałów szkoleniowych. (1 - źle, 5 – bardzo dobrze)<br/>
              <table>
                <tr>
                  <td style={oceny}>1</td>
                  <td style={oceny}>2</td>
                  <td style={oceny}>3</td>
                  <td style={oceny}>4</td>
                  <td style={oceny}>5</td>
                </tr>
              </table>              
            </div>

            <br/>

            <div style={pyt5}>
              5.	Proszę ocenić posługując się pięciostopniową skalą, wiedzę prowadzącego i jego przygotowanie merytoryczne. (1 - złe, 5 – bardzo dobre)<br/>
              <table>
                <tr>
                  <td style={oceny}>1</td>
                  <td style={oceny}>2</td>
                  <td style={oceny}>3</td>
                  <td style={oceny}>4</td>
                  <td style={oceny}>5</td>
                </tr>
              </table>              
            </div>

            <br/>

            <div style={pyt6}>
              6.	Proszę odpowiedzieć posługując się trzystopniową skalą, jak ocenia Pan/Pani zakres prezentowanego materiału? (1 – za wąski, 2 – odpowiedni, 3 – za szeroki)<br/>
              <table>
                <tr>
                  <td style={oceny}>1</td>
                  <td style={oceny}>2</td>
                  <td style={oceny}>3</td>
                </tr>
              </table>              
            </div>

            <br/>

            <div style={pyt7}>
              7.	Proszę ocenić na pięciostopniowej skali, sposób przekazywania informacji przez prowadzącego szkolenia. (1 - nieprzystępny, 5 – bardzo przystępny)<br/>
              <table>
                <tr>
                  <td style={oceny}>1</td>
                  <td style={oceny}>2</td>
                  <td style={oceny}>3</td>
                  <td style={oceny}>4</td>
                  <td style={oceny}>5</td>
                </tr>
              </table>              
            </div>

            <br/>

            <div style={pyt8}>
              8.	Proszę odpowiedzieć na pięciostopniowej skali, jak ocenia Pani/Pan szkolenie od strony organizacyjnej? (1 - źle, 5 - bardzo dobrze)<br/>
              <table>
                <tr>
                  <td style={oceny}>1</td>
                  <td style={oceny}>2</td>
                  <td style={oceny}>3</td>
                  <td style={oceny}>4</td>
                  <td style={oceny}>5</td>
                </tr>
              </table>              
            </div>

            <br/><br/><br/><br/><br/>

            <div style={pyt9}>
              9.	Jakie treści merytoryczne Pani/Pana zdaniem powinny być uwzględnione w kolejnych szkoleniach tego typu?:<br/>
                <span>.......................................................................................................................................................</span><br/>                        
                <span>.......................................................................................................................................................</span><br/>                        
                <span>.......................................................................................................................................................</span><br/>                        
                <span>.......................................................................................................................................................</span><br/>                        
                <span>.......................................................................................................................................................</span><br/>                        
            </div>

            <br/>

            <div style={pyt10}>
              10.	Dodatkowe uwagi: <br/>
              <span>.......................................................................................................................................................</span><br/>           
              <span>.......................................................................................................................................................</span><br/>           
              <span>.......................................................................................................................................................</span><br/>           
              <span>.......................................................................................................................................................</span><br/>           
              <span>.......................................................................................................................................................</span><br/>           
            </div>

            <br/>

              <div style={{textAlign: "center",fontStyle: "italic"}}>Dziękujemy za wypełnienie ankiety!</div> 


        </div>
    )
 }
}


export default Questionnaire