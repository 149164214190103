import React from 'react';
import { Link } from 'react-router-dom';
import ConfigX from '../../ConfigX';
import {Navigate} from 'react-router-dom';

import DetailsPrintB from './DetailsPrintB';
import ReactToPrint from 'react-to-print';
import TipMessage from '../../components/TipMessage';
import Linkify from 'react-linkify';

import BackPNG from '../../image/back.png';
import PrintPNG from '../../image/print.png';
import FileUploader from '../../components/FileUploaderUser';
import downloadPNG from '../../image/download.png';
import SignUPB from '../page/SignUpB';
import AlertMsg from '../../components/AlertMsg';
class DetailsB extends React.Component
{

    url_get = "";  

    typ          = "news";
    typ_id       = 99999;
 
  constructor(props)
  {
    super(props)
 
    this.state = {
        page: this.props.page,
        editor_action: "none", //nie wyświetlaj edytora terminów
        editor_row: { }, //pusta baza dla editora..               
        editor_selected: 0,
        files: [],
        url_get: ConfigX.restApi + "/common,file_download",
        sk_badania_id: this.props.row.id,
        response: 0,
        wycena_gotowa: 0,
        w_trakcie: 0,
        nowy: 0,
        przygotowanie_wyceny: 0,
        user: [],
        alertMSG: '',
        alertMSGTime: 0,           
        //login: 0,
    };

   // this.url_get      = ConfigX.restApi + "/common,file_download";       
 
   // this.typ          = this.props.typ;
   // this.typ_id       = this.props.typ_id;    


  }

  componentDidMount()
  {
    if(this.props.apiKey != "")
    {
       this.getDataUser();
       this.checkSignUp();
       //this.getListaPlikow();
     }

  }

  componentDidUpdate()
  {
    //this.checkLogout();
  }

  checkLogout()
  {
    if(this.state.page == 'logout')
    { 
            var localUsr = window.localStorage.getItem('user');

          //  console.log("||| " + localUsr);

            if(localUsr != null && localUsr != "undefinded" && localUsr.length > 3)
            {
                      var jsonUsr = JSON.parse(localUsr);
            
                      fetch( ConfigX.restApi + '/rest,go_logout', {
                        method: 'POST', 
                        body: JSON.stringify(this.state), 
                        headers: {
                            'Content-Type': 'text/html',
                            'X-API-KEY': jsonUsr.apiKey
                        }
                    })
                    .then(res => res.json() )
                    .then(res => {

                      window.localStorage.removeItem("user");  

                      this.setState({
                        page: "logout_success",
                        login: 1,
                      });
                      
                    })

            }          

    }

  }

  getDataUser()
  {

                    fetch( ConfigX.restApi + '/user,get_data_user', {
                        method: 'POST', 
                        body: JSON.stringify(this.state), 
                        headers: {
                            'Content-Type': 'text/html',
                            'X-API-KEY': this.props.apiKey
                        }
                    })
                    .then(res => res.json() )
                    .then(res => {

                
                            this.setState({
                                user: res.rows[0] 
                            });
                       
                      
                    })
  }


  checkSignUp()
  {
      var dataPost = {
          sk_badania_id: this.state.sk_badania_id
       };    


       fetch( ConfigX.restApi + "/user,checkSignUpB" , {
           method: 'POST', 
           body: JSON.stringify(dataPost), 
           headers: {
               'Content-Type': 'text/html',
               'X-API-KEY': this.props.apiKey
           }
       })
       .then( res => res.json() )
       .then(json => {

          if(json.status == "wycena_gotowa")
          {            
              this.setState({
                wycena_gotowa: 1,
                id_uczestnika: json.id_uczestnik
              })
          }
          if(json.status == "usluga_w_trakcie")
          {            
              this.setState({
                w_trakcie: 1,
                id_uczestnika: json.id_uczestnik
              })
          }      
          if(json.status == "nowy")
          {            
              this.setState({
                nowy: 1,
                id_uczestnika: json.id_uczestnik
              })
          }         
          if(json.status == "przygotowanie_wyceny")
          {            
              this.setState({
                przygotowanie_wyceny: 1,
                id_uczestnika: json.id_uczestnik
              })
          }                       
         
          
       });                      
  }


  getListaPlikow()
  {
        var dataPost = {
            typ: "sk_badania_wnioski",
            typ_id: this.state.sk_badania_id,    
        };

        fetch( ConfigX.restApi + "/user,file_list" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html'       
            }
        })
        .then( res => res.json() )
        .then(json => {
    //console.log(+JSON.stringify(json));  
            this.setState({                 
                files: json
            });      
        
        })
        .catch(error => {
            console.log("Błąd parsowania: ",error);
        })      
  }

  onRowClick(event)
  {                        
      var id = event.target.getAttribute("id");
      this.props.onRowClick(id);
  }


  onClickNewSign()
  {
      this.setState({
          page: "sign",
          editor_row:   {},
          editor_action: "new",    
          editor_selected: -1      
      });
  }

  onDeleteTime(id)
  {
          this.setState({
              rows: this.state.rows
          });
  }

  onCloseEditor(modifyRow,res_type) 
  {

    if(modifyRow != null)
    {
        if(res_type === 'ok') 
        {
            this.setState({response:1}) 
            this.getDataUser();
        }
        this.setState({ 
            sk_badania_id: modifyRow, 
        })
       
    }

      this.setState({
          editor_row: {},
          editor_action: "none",     
          editor_selected: -2              
      });
  }

  goLogin(event)
  {
    this.setState({page:"sign"})
  }

  onDialogOK(param)
  {
   
          this.setState({
              alertMSG: '',
              alertMSGTime: 0,

              wycena_gotowa: 0,
              w_trakcie : 0,
              brak_miejsc: 0,
              nowy: 0,
              przygotowanie_wyceny: 0,
              id_uczestnika: 0,
              alertMSG: '',
              alertMSGTime: 0,   
          });


  }

  render()
  {
    var i = 0;
    var opis_proc = this.props.row.opis_procedury;
    var info_og   = this.props.row.informacje_ogolne;

    if(info_og.length < 1) info_og = 'Brak informacji od organizatora badania.'
    
    var msg = "";

    var content = "";

    if(this.state.page == "sign" && this.props.apiKey != "") 
    {
   
        document.title = ConfigX.title  + " / Badania";  

        content = (
            <section className="container">             
                 <SignUPB apiKey={this.props.apiKey} action={this.state.editor_action}  rows={this.state.user} sk_badania_id={this.state.sk_badania_id} nowSelected={this.state.editor_selected} onCloseEditor={this.onCloseEditor.bind(this)}/>                 
            </section>
          );
    }

    var msg_type = "";//notice
    var zapiszButton = "";
    var msg = '';
    var wypiszSie ='';
    var opusc = '';
    
    var v = false;


    if(this.state.page == "sign" && this.props.apiKey == "") 
    {
           
        return (
              <div>
                {<Navigate to={ConfigX.pathDef   + "login"  } />}
              </div>
          );
    }
    
    if(this.props.apiKey == '')//niezalogowany
    {
        zapiszButton = (
            <div className='detailsButton'>
                <div className='buttonStandard' onClick={this.goLogin.bind(this)}>Zleć badanie</div>                                            
            </div>            
        )  
    
        msg = (
             <span>Aby zlecić badanie musisz najpierw zarejestrować się w systemie.</span>
        )
        msg_type = "error";            
    }

    if(this.props.apiKey != '')//zalogowany
    {

        if(this.state.user.typ == 'ADMIN' ) 
        {
             zapiszButton = '';
             msg = '';
        }

        if(this.state.user.typ == 'UCZESTNIK' ) 
        {
            if(this.state.response == '1')
            {
                msg = "Badanie zlecone pomyślnie ";
                msg_type = 'notice';
                v = true;
            }

            if(this.state.wycena_gotowa == 1) //status oczekuje
            {
                msg = "Wycena jest już gotowa, wejdź do panelu w listę badań aby zaakceptować wycenę. ";
                msg_type = 'notice';
                v =true;
    
              //  zapiszButton = '';       
            }

            if(this.state.w_trakcie == 1) //status w trakcie wyceny
            {
                msg = "Aktualnie wycena badania jest w trakcie, otrzymasz odpowiednią wiadomość, gdy zmienic się status. ";
                msg_type = 'error';
                v =true;
   
              //  zapiszButton = '';       
            }

            if(this.state.nowy == 1 || this.state.przygotowanie_wyceny == 1) //status w trakcie wyceny
            {
                msg = "Aktualnie wniosek jest w trakcie sprawdzania. Otrzymasz odpowiednią wiadomość gdy zmieni się status.";
                msg_type = 'error';
                v =true;
   
              //  zapiszButton = '';       
            }            

            if(v == false)
            {
                var zapiszButton = (
                    <div className='detailsButton'>
                        <div className='buttonStandard' onClick={this.onClickNewSign.bind(this)}>Zleć badanie</div>                                            
                    </div>            
                )
            }
        }

    }



    return (
        <div className='details table-content-boot'>
                {content}
                
                <TipMessage  msg={msg} type={msg_type} />

                <div className='location'><Link to={ConfigX.pathDef + 'badania'}><img src={BackPNG} alt=''/> Badania</Link> | {this.props.row.tytul} </div>

                {zapiszButton}

                <h1 style={{fontFamily: "inherit"}}>&nbsp;{this.props.row.tytul}</h1>

                <div className='slitInfo'>
                    <div className='subTitle'>Opis procedury:</div> 
                    <div className='p-1'>
                    <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}
                        >{opis_proc}
                    </Linkify>                         
                    </div>
                </div>

                <br/>

                <div className='slitInfo'>
                    <div className='subTitle'>Informacje ogólne:</div> 
                    <div className='p-1'>
                    <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}
                        >{info_og}
                    </Linkify>                         
                    </div>
                </div>
                <div className='p-1'>{this.props.row.info_telefon}</div>
                <div className='p-1'>{this.props.row.info_email}</div>
                <div className='p-1'>{this.props.row.info_www}</div>
                <div className='p-1'>{this.props.row.info_osoba_kontaktowa}</div> 

                <br/>

                <div className='slitInfo'>
                    <div className='subTitle'>Organizator / Podmiot prowadzący badanie:</div> <br/>
                    <div className='p-1'>{this.props.row.podmiot_prowadzacy.nazwa}  </div>
                    <div className='p-1'>{this.props.row.podmiot_prowadzacy.ulica}  {this.props.row.podmiot_prowadzacy.nr_domu}  {this.props.row.podmiot_prowadzacy.nr_mieszkania} </div>
                    <div className='p-1'>{this.props.row.podmiot_prowadzacy.miasto} {this.props.row.podmiot_prowadzacy.kod_pocztowy}</div>
                    <div className='p-1'>NIP: {this.props.row.podmiot_prowadzacy.nip}  </div>  
                    <div className='p-1'>{this.props.row.podmiot_prowadzacy.email} </div>          
                    <div className='p-1'>{this.props.row.podmiot_prowadzacy.telefon} </div>
                </div>
                
                <br /><br />
                <div className='slitInfo'>
                    <div className='subTitle'>Pliki załączone do badania:</div>

                    <FileUploader typ="sk_badania" typ_id={this.props.row.id} apiKey={this.props.apiKey} onlyDisplay="1" onlyMyFiles='no' deleteIcon='off' showSize='off' showDate='off'/> 
                </div>
           
                <br/><br/>

                <div className='location'>
                    <Link to={ConfigX.pathDef}>
                          <img src={BackPNG} alt='' /> Pokaż inne 
                    </Link> 
                    
                    &nbsp;|&nbsp;
                

                    <ReactToPrint
                    trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return  <span className='link'><img src={PrintPNG} alt='' /> Drukuj </span>
                    }}
                    content={() => this.componentRef}
                    />
                    <DetailsPrintB ref={el => (this.componentRef = el)} row={this.props.row} />          

                    <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime }  msgParam="ok" onDialogOK={this.onDialogOK.bind(this) }  title="Powiadomienie"/>

                </div>

         </div>
    )
  }

}

export default DetailsB