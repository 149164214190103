

import React from "react";

class Regulamin extends React.Component
{
 
    constructor(props)
    {
        super(props)
    }
 

  render(){
      
       return (
            <div className="info-wrap bg-primary w-80 m-3 p-lg-5 p-4" style={{lineHeight: "1.5rem"}}>
                <br></br>
                <span className="mb-4 mt-md-4 align-center">
                    <h3>Regulamin szkoleń stacjonarnych otwartych oraz szkoleń on-line </h3>
                    <h3>organizowanych przez</h3>
                    <h3>Bydgoski Klaster Przemysłowy Dolina Narzędziowa</h3>
                </span>
                <br/><br/><br/>

                <span className="mb-4 mt-md-4 align-left">
                    <b>§ 1. Postanowienia ogólne.</b>
                </span>
                <br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                        <p>Niniejszy Regulamin („Regulamin") określa zasady udziału w szkoleniach stacjonarnych otwartych oraz szkoleń on-line organizowanych przez BKP DN.<br/><br/>
                        Organizatorem szkoleń jest Stowarzyszenie Bydgoski Klaster Przemysłowy Dolina Narzędziowa z siedzibą w Bydgoszczy przy ul. Bydgoskich Przemysłowców 6 (85-862 Bydgoszcz), wpisanym do rejestru stowarzyszeń, innych organizacji społecznych i zawodowych, fundacji oraz samodzielnych publicznych zakładów opieki zdrowotnej prowadzonego przez Sąd Rejonowy w Bydgoszczy XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem: 0000949865; NIP: 7282859405; REGON: 34036030, adres email uslugi@klaster.bydgoszcz.pl, numer telefonu +48 52 515 30 73<br/><br/>
                        Udział w szkoleniach wymaga zaakceptowania Regulaminu.
                        </p>
                    </div><br/> 
                </div>                
                <br/><br/><br/>
              
                <span className="mb-4 mt-md-4 align-left">
                    <b>§ 2. Definicje:</b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                    1.	Klent – osoba fizyczna lub prawna, zobowiązana do uiszczenia należności za szkolenie. Klient nie musi być jednocześnie Uczestnikiem szkolenia, choć może nim być;<br/>
                    2.	Organizator – Bydgoski Klaster Przemysłowy Dolina Narzędziowa  (dalej w treści regulaminu zwany także BKP);<br/>
                    3.	Rezygnacja ze szkolenia – przesłanie przez Klienta pisemnej bądź e-mailowej (pod adresem uslugi@klaster.bydgoszcz.pl) informacji o wycofaniu uczestnika/ków z udziału w szkoleniu;<br/>
                    4.	Uczestnik – osoba, która poprzez przysłanie zgłoszenia wyraziła wolę uczestnictwa w szkoleniu, za które została wniesiona opłata;<br/>
                    5.	Umowa – umowa zawierana pomiędzy Klientem a Organizatorem na zasadach określonych w niniejszym Regulaminie. W odniesieniu do Klientów będących Konsumentami Umowa jest umową zawieraną na odległość w rozumieniu ustawy z dnia 30 maja 2014 r. o prawach konsumenta;<br/>
                    6.	Szkolenie otwarte – szkolenie, na które może się zapisać każda osoba zainteresowana tematem szkolenia;<br/>
                    7.	Szkolenie stacjonarne otwarte – szkolenie, w którym Uczestnicy zgromadzeni są w sali wykładowej BKP;<br/>
                    8.	Szkolenie on-line – szkolenie, w którym Uczestnik bierze udział korzystając z komputera (tabletu), poprzez zalogowanie się na platformę internetową wskazaną przez Organizatora. Szkolenie prowadzone jest przez Wykładowcę w czasie rzeczywistym, z udziałem innych Uczestników szkolenia;<br/>
                    9.	Zgłoszenie – podanie swoich danych w interaktywnym formularzu rejestracyjnym, dostępnym na stronie https://bkplab.eu/front/panel/szkolenia <br/>
                    10.	Strona Organizatora – strona https://klaster.bydgoszcz.pl/oferta-szkoleniowa/, na której dokonuje się zgłoszenia na szkolenie.<br/>
                                        
                    </div><br/> 
                </div>                
                <br/><br/><br/>

                <span className="mb-4 mt-md-4 align-left">
                    <b>§ 3. Warunki uczestnictwa w szkoleniu stacjonarnym otwartym oraz szkoleniu on-line.</b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                    1.	Informacja o organizowanych szkoleniach przez BKP przekazywana jest potencjalnym zainteresowanym w formie:<br/><br/>
                        &nbsp;&nbsp;1)	oferty publikowanej na stronie internetowej: https://bkplab.eu/front/panel/szkolenia zawierającej opis szkolenia wraz z datą i miejscem jego realizacji oraz ceną,<br/>
                        &nbsp;&nbsp;2)	działań promocyjno-sprzedażowych (mailing, ulotka, baner).<br/><br/>
                    2.	Podstawą uczestnictwa w szkoleniu jest zawarcie Umowy co następuje poprzez wypełnienie i wysłanie interaktywnego formularza zgłoszeniowego dostępnego przy każdym szkoleniu na stronie Organizatora wraz z zaakceptowaniem postanowień niniejszego Regulaminu, dokonaniem opłaty za szkolenie oraz po zaakceptowaniu zgłoszenia na Szkolenie przez Organizatora.<br/><br/>
                    3.	Liczba miejsc jest ograniczona, decyduje kolejność zgłoszeń. BKP zastrzega sobie możliwość odwołania szkolenia lub zmiany jego terminu w przypadkach określonych w niniejszym Regulaminie.<br/><br/>
                    4.	Szkolenie on-line odbywa się poprzez platformę internetową. Uczestnik zobowiązany jest zapewnić we własnym zakresie komputer (tablet) z szybkim internetem oraz zestaw słuchawkowy z mikrofonem. Po dokonaniu płatności uczestnik otrzyma od BKP drogą mailową link (zaproszenie) na szkolenie on-line oraz instrukcję korzystania z platformy internetowej.<br/><br/>

                                        
                    </div><br/> 
                </div>                
                <br/><br/><br/>


                <span className="mb-4 mt-md-4 align-left">
                    <b>§ 4. Warunki płatności.</b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                    1.	Wysokość opłaty za szkolenie zgodna jest z aktualną ofertą zamieszczoną na stronie https://bkplab.eu/front/panel/szkolenia w momencie dokonywania rejestracji na szkolenie.
                    <br/>2.	W cenie szkolenia stacjonarnego Uczestnicy otrzymują od Organizatora: materiały szkoleniowe w formie drukowanej bądź elektronicznej, Zaświadczenie/Certyfikat o ukończeniu szkolenia.
                    <br/>3.	Cena szkolenia on-line obejmuje udział w szkoleniu (z wyłączeniem dostępu do urządzenia i sieci Internet, do których zorganizowania zobowiązany jest każdy z Uczestników we własnym zakresie), materiały szkoleniowe w formie elektronicznej oraz Zaświadczenie/Certyfikat  uczestnictwa w szkoleniu.
                    <br/>4.	Cena szkolenia nie obejmuje kosztów dojazdu uczestników, kosztów parkingu, noclegów i wyżywienia, chyba że jest to wyraźnie zaznaczone w ofercie.
                    <br/>5.	Opłatę za szkolenie należy dokonać przelewem na konto 88 1050 1139 1000 0090 8107 2267 ING BANK ŚLĄSKI S.A. po otrzymaniu potwierdzenia od Organizatora, że szkolenie się odbędzie, a przed rozpoczęciem szkolenia.
                    <br/>6.	Tytuł przelewu powinien zawierać temat szkolenia, nazwę firmy/klienta/uczestnika oraz datę szkolenia.
                    <br/>7.	Dokumenty księgowe – faktury przekazywane są uczestnikom na zakończenie szkolenia lub wysyłane drogą mailową.
                    <br/>8.	Udostępnienie przez Organizatora linku do spotkania on-line, możliwe jest wyłącznie w przypadku Uczestników, za których opłata została uiszczona i zaksięgowana na rachunku bankowym Organizatora.

                                        
                    </div><br/> 
                </div>                
                <br/><br/><br/>


                <span className="mb-4 mt-md-4 align-left">
                    <b>§ 5. Odwołanie lub zmiana terminu szkolenia przez Organizatora.</b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                    1.	Organizator zastrzega sobie prawo do zmiany terminu szkolenia lub do odwołania szkolenia w terminie do 14 dni przed planowaną datą jego rozpoczęcia:
                    <br/>&nbsp;&nbsp;1)	gdy prowadzący szkolenie z przyczyn losowych nie będzie mógł przeprowadzić szkolenia;
                    <br/>&nbsp;&nbsp;2)	brak wystarczającej liczby uczestników, która określana jest w formularzu zgłoszenia;
                    <br/>&nbsp;&nbsp;3)	z innych przyczyn niezależnych od organizatora i nie możliwych do przewidzenia
                    <br/><br/>2.	Organizator, w wypadku odwołania szkolenia informuje o tym fakcie Klienta/Uczestnika. Informacja dotycząca odwołania szkolenia przesyłana jest na adres e-mail wskazany w zgłoszeniu.
                    <br/><br/>3.	W przypadku odwołania szkolenia przez Organizatora, Klient otrzymuje zwrot wniesionych opłat na rachunek bankowy, z którego dokonana była płatność.

                                        
                    </div><br/> 
                </div>                
                <br/><br/><br/>

                <span className="mb-4 mt-md-4 align-left">
                    <b>§ 6. Konsumenckie prawo odstąpienia od umowy. </b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                    1.	Klienci będący konsumentami w rozumieniu obowiązujących przepisów prawnych mają prawo odstąpić od Umowy w terminie 14 dni bez podania jakiejkolwiek przyczyny, a w przypadku umowy zawartej podczas nieumówionej wizyty w miejscu zamieszkania lub zwykłego pobytu konsumenta albo wycieczki - w terminie 30 dni.
                    <br/><br/>2.	Termin do odstąpienia od umowy wygasa po upływie 14 dni od dnia zawarcia umowy, a w przypadku umowy zawartej podczas nieumówionej wizyty w miejscu zamieszkania lub zwykłego pobytu konsumenta albo wycieczki - po upływie 30 dni od dnia zawarcia umowy.
                    <br/><br/>3.	Aby skorzystać z prawa odstąpienia od umowy, Klient będący konsumentem zobowiązany jest poinformować Organizatora o swojej decyzji o odstąpieniu od Umowy w drodze jednoznacznego oświadczenia (na przykład pismo wysłane pocztą lub pocztą elektroniczną na adres uslugi@klaster.bydgoszcz.pl). Klient będący konsumentem może skorzystać z załączonego do niniejszego Regulaminu wzoru formularza odstąpienia od umowy, jednak nie jest to obowiązkowe. 
                    <br/><br/>4.	Aby zachować termin do odstąpienia od umowy, wystarczy, aby Klient będący konsumentem wysłał Organizatorowi informację dotyczącą wykonania przysługującego mu prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy.
                    <br/><br/>5.	W przypadku odstąpienia od niniejszej umowy zwracamy Klientowi będącemu konsumentem wszystkie otrzymane od niego płatności, niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym zostaliśmy poinformowani o decyzji o wykonaniu prawa odstąpienia od niniejszej umowy. Zwrotu płatności dokonamy przy użyciu takich samych sposobów płatności, jakie zostały użyte w pierwotnej transakcji, chyba że wyraźnie zgodziliście się Państwo na inne rozwiązanie; w każdym przypadku nie poniosą Państwo żadnych opłat w związku z tym zwrotem. 
                    <br/><br/>6.	Prawo odstąpienia od Umowy zawartej poza lokalem przedsiębiorstwa lub na odległość nie przysługuje Klientowi będącego Konsumentem jeżeli Organizator przeprowadził Szkolenie w całości za wyraźną zgodą Konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez Organizatora utraci prawo odstąpienia od Umowy.
                    <br/><br/>7.	Jeżeli Klient będący Konsumentem wykonuje prawo odstąpienia od Umowy zawartej poza lokalem przedsiębiorstwa lub na odległość, po wyraźnym zażądaniu przez niego wykonywania usługi przed upływem terminu do odstąpienia od tego rodzaju umowy (art. 15 ust. 3 i art. 21 ust. 2 ustawy o prawach konsumenta), a przed przeprowadzeniem całości Szkolenia, na zasadzie art. 35 cyt. ustawy ma on wówczas obowiązek zapłaty za świadczenia spełnione do chwili odstąpienia od takiej umowy. Kwotę zapłaty oblicza się proporcjonalnie do zakresu spełnionego świadczenia, z uwzględnieniem uzgodnionej w Umowie kwoty wynagrodzenia.

                                        
                    </div><br/> 
                </div>                
                <br/><br/><br/>

                <span className="mb-4 mt-md-4 align-left">
                    <b>§ 7. Rezygnacja ze szkolenia.  </b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">

                    1.	Rezygnacja ze szkolenia wymaga przesłania jej e-mailem: uslugi@klaster.bydgoszcz.pl 
                    <br/>2.	W przypadku rezygnacji z uczestnictwa w szkoleniu w terminie dłuższym niż 14 dni przed szkoleniem Klient/Uczestnik nie ponosi żadnych kosztów.
                    <br/>3.	Odwołanie zgłoszenia przez Klienta/Uczestnika w terminie krótszym niż 14 dni przed szkoleniem lub brak udziału w szkoleniu skutkuje obciążeniem go całością kosztów szkolenia.
                    <br/>4.	W razie nieprzewidzialnych okoliczności dopuszczalna jest ewentualna zmiana uczestnika szkolenia.
                                        
                    </div><br/> 
                </div>                
                <br/><br/><br/>

                <span className="mb-4 mt-md-4 align-left">
                    <b>§ 8. Reklamacje. </b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">

                    1.	Uczestnikom przysługuje prawo reklamacji usługi szkoleniowej. Reklamacja powinna być złożona w terminie nieprzekraczającym 5 dni od daty zakończenia szkolenia.
                    <br/><br/>2.	Zgłoszenia reklamacji wraz ze szczegółowym opisem, z podaniem imienia i nazwiska oraz adresu e-mailowego można dokonać w następujących formach:
                    <br/>&nbsp;&nbsp;1)	pisemnie na adres: BKP, ul. Bydgoskich Przemysłowców 6, 85-862 Bydgoszcz.
                    <br/>&nbsp;&nbsp;2)	na adres e-mail: uslugi@klaster.bydgoszcz.pl 
                    <br/><br/>3.	 Reklamacje zgłoszone po terminie określonym w ust. 1 nie będą rozpatrywane.
                    <br/><br/>4.	Organizator zobowiązuje się rozpatrzyć reklamacje do 14 dni od daty wpływu do siedziby firmy a o ich rezultacie powiadomi niezwłocznie Klienta/Uczestnika na podany przez niego adres e-mail.

                    </div><br/> 
                </div>                
                <br/><br/><br/>

                <span className="mb-4 mt-md-4 align-left">
                    <b>§ 9. Przetwarzanie danych osobowych. </b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">
                    1.	Bydgoski Klaster Przemysłowy Dolina Przemysłowa w świetle przepisów prawnych jest administratorem danych osobowych Uczestników szkoleń organizowanych przez Klaster. Tym samym, odpowiadamy za wykorzystanie Pani/-Pana danych w sposób bezpieczny, zgodny z przepisami prawa.
                    <br/><br/>2.	Dane teleadresowe administratora danych osobowych
                    Bydgoski Klaster Przemysłowy Dolina Przemysłowa, ul. Bydgoskich Przemysłowców 6, 85 – 862 Bydgoszcz.
                    Klaster nie powołał Inspektora Ochrony Danych Osobowych.
                    <br/><br/>3.	Podstawa prawna i cel wykorzystywania danych osobowych Uczestników 
                    Celem oraz podstawą przetwarzania Pani/-Pana danych osobowych jest: 
                    <br/>&nbsp;&nbsp;1)	realizacja umowy na uczestnictwo w szkoleniach (art. 6 ust. 1 lit. B RODO);
                    <br/>&nbsp;&nbsp;2)	realizacja obowiązków podatkowych i rachunkowych związanych z zawartymi umowy (art. 6 ust. 1 lit. C RODO);
                    <br/>&nbsp;&nbsp;3)	zapewnienie komunikacji niezbędnej dla prawidłowej realizacji szkoleń (art. 6 ust. 1 lit. B RODO);
                    <br/>&nbsp;&nbsp;4)	ustalenie i dochodzenie roszczeń w związku z umowami na uczestnictwo w szkoleniach (art. 6 ust. 1 lit. B i F RODO);
                    <br/>&nbsp;&nbsp;5)	informowanie o kolejnych wydarzeniach, w tym poprzez wysyłanie informacji handlowych (art. 6 ust. 1 lit. A i F RODO);
                    <br/>&nbsp;&nbsp;6)	rejestracja przebiegu szkoleń, w tym wizerunków Uczestników oraz promocja szkoleń (art. 6 ust. 1 lit. A i F RODO);
                    Klaster nie podejmuje w oparciu o dane zautomatyzowanych decyzji, jak również ich nie profiluje.
                    <br/><br/>4.	Dane, które należy nam podać oraz ich źródło
                    Dane przetwarzane przez Klaster należą do podstawowych danych identyfikacyjnych i teleadresowych (kontaktowych), a ponadto obejmują wizerunek. Dane zostały nam udostępnione bezpośrednio bądź przez podmiot zgłaszający Pani/-Pana udział w szkoleniu. Podanie danych osobowych jest dobrowolne, natomiast odmowa podania danych uniemożliwi zawarcie umowy i realizację celów przetwarzania danych, tj. przede wszystkim uniemożliwi udział w szkoleniu.
                    <br/><br/>5.	Przekazywanie Państwa danych innym podmiotom
                    Dane Pani/-Pana w razie potrzeby mogą być przekazane:
                    <br/>&nbsp;&nbsp;1)	podmiotom przetwarzającym dane w naszym imieniu, uczestniczącym w wykonywaniu naszych czynności:
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;a)	obsługującym nasze systemy teleinformatyczne lub udostępniającym nam narzędzia teleinformatyczne,
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;b)	podmiotom świadczącym nam usługi doradcze, pomoc prawną, podatkową, ochrony osób i mienia.
                    <br/>&nbsp;&nbsp;2)	innym administratorom danych przetwarzającym Pani/-Pana dane we własnym imieniu:
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;a)	podmiotom prowadzącym działalność pocztową lub kurierską,
                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;b)	bankom prowadzącym działalność płatniczą, w celu dokonania ewentualnych zwrotów na Pani/-Pana rzecz.
                    <br/><br/>6.	Przekazywanie danych osobowych poza Europejski Obszar Gospodarczy (EOG) lub organizacjom międzynarodowym
                    Pani/-Pana dane osobowe nie będą przekazywane poza EOG (obejmujący Unię Europejską, Norwegię, Liechtenstein i Islandię). 
                    <br/><br/>7.	Okres przetwarzania danych osobowych
                    Pani/Pana dane będą przetwarzane do upływu okresu przedawnienia roszczeń związanych z zawartą umową – nie dłużej jednak niż przez okres 6 lat od dnia zawarcia umowy.
                    <br/><br/>8.	Przysługujące Państwu uprawnienia
                    Może Pan/-Pani złożyć do nas wniosek (dotyczący danych osobowych) o:
                    <br/><br/>&nbsp;&nbsp;1)	sprostowanie (poprawienie) danych,
                    <br/>&nbsp;&nbsp;2)	usunięcie danych przetwarzanych bezpodstawnie,
                    <br/>&nbsp;&nbsp;3)	ograniczenie przetwarzania (wstrzymanie operacji na danych lub nieusuwanie danych – stosownie do złożonego wniosku),
                    <br/>&nbsp;&nbsp;4)	dostęp do danych (o informację o przetwarzanych przez nas danych oraz o kopię danych),
                    <br/>&nbsp;&nbsp;5)	przeniesienie danych do innego administratora danych lub do Państwa (w zakresie określonym w art. 20 RODO),
                    <br/>&nbsp;&nbsp;6)	prawo do wniesienia skargi do organu nadzorczego w razie stwierdzenia, że przetwarzanie Pani/-Pana danych narusza RODO,
                    <br/>&nbsp;&nbsp;7)	cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.<br/><br/>
                    Z tych praw może Pani/-Pan skorzystać, składając wniosek pod adresem bkp@klaster.bydgoszcz.pl  lub dzwoniąc pod numer Klastra (numery są dostępne na https://klaster.bydgoszcz.pl/).
                    Aby mieć pewność, że jest Pan/-Pani uprawniona do złożenia wniosku, możemy prosić o podanie dodatkowych informacji pozwalających nam Panią/-Pana uwierzytelnić.
                    Zakres każdego z tych praw oraz sytuacje, kiedy można z nich skorzystać, wynikają z przepisów prawa. To, z którego uprawnienia mogą Państwo skorzystać, zależeć będzie np. od podstawy prawnej przetwarzania Państwa danych oraz od celu ich przetwarzania.
                    <br/><br/>9.	Prawo sprzeciwu
                    W szczególnych sytuacjach mogą Państwo w dowolnym momencie wnieść sprzeciw wobec przetwarzania przez nas Pani/-Pana danych osobowych, jeśli podstawą wykorzystania danych jest nasz prawnie uzasadniony interes. W takiej sytuacji, po rozpatrzeniu wniosku, nie będziemy już mogli przetwarzać danych osobowych objętych sprzeciwem na tej podstawie, chyba że istnieją:
                    <br/>&nbsp;&nbsp;1)	ważne prawnie uzasadnione podstawy do przetwarzania danych, które według prawa uznaje się za nadrzędne wobec Państwa interesów, praw i wolności, lub
                    <br/>&nbsp;&nbsp;2)	podstawy do ustalenia, dochodzenia lub obrony roszczeń.



                    </div><br/> 
                </div>                
                <br/><br/><br/>

                <span className="mb-4 mt-md-4 align-left">
                    <b>§ 11. Postanowienia końcowe. </b>
                </span>
                <br/><br/>
                <div className="dbox w-100 d-flex align-items-start">
                    <div className="text pl-3">

                    1.	Regulamin wchodzi w życie z dniem 01.08.2023r.
                    <br/><br/>2.	Organizator zastrzega możliwość zmiany niniejszego Regulaminu w trakcie trwania Umowy. W szczególności ww. zmiana jest uprawniona, gdy dojdzie do zmiany przepisów prawnych, zostaną wydane orzeczenia istotne z punktu widzenia dotychczasowej treści Regulaminu.
                    <br/><br/>3.	Wszelkie spory związane z Umową będą rozstrzygane polubownie, a jeżeli okaże się to niemożliwe:
                    <br/>&nbsp;&nbsp;1)	w przypadku Klientów będących Konsumentami przez właściwe polskie sądy powszechne;
                    <br/>&nbsp;&nbsp;2)	w przypadku Klientów niebędących Konsumentami przez polskie sądy powszechne właściwe dla miejsca siedziby Organizatora.
                    <br/>4.	W sprawach nieuregulowanych Regulaminem zastosowanie mają przepisy Kodeksu cywilnego, ustawy z dnia 30 maja 2014 r. o prawach konsumenta oraz inne bezwzględnie obowiązujące przepisy prawa polskiego.
                                    
                    </div><br/> 
                </div>                
                <br/><br/><br/>











            </div>

         
       );

  }
}

export default Regulamin