
import React from 'react';
import { Link  } from 'react-router-dom';
import ConfigX from '../ConfigX';

class BarBottom extends React.Component
 {
  render(){
    
     

    const d = new Date();
    let data = d.getFullYear() ;

    return (
      <footer className="footer">
       
          <Link to={ConfigX.pathDef + "dashboard,helpdesk" } ><span style={{color: "#fff"}}>Pomoc</span></Link>
           
        </footer>
      );

  }
 
} 

export default BarBottom;
