
import React from 'react';
import { Link  } from 'react-router-dom';
import './styles.css';


import Intro from './Intro';
import Account from './Account';
import Register from './Register';
import Remind from './Remind';

import Members from './trainings/Members';
import SignUpTrainings from './trainings/SignUpTrainings';
import AddTraining from './trainings/AddTraining';
import DelTraining from './trainings/DelTraining';

import SignUpBadania from './badania/SignUpBadania';
import Wnioski from './badania/Wnioski';
import AddBadania from './badania/AddBadania';
import DelBadania from './badania/DelBadania';

import AddBaza from './baza/AddBaza';
import DelBaza from './baza/DelBaza';

import EditOsoba from './osoby/EditOsoba';
import DelOsoba from './osoby/DelOsoba';

import Rozliczenia from './rozliczenia/preview';

import Raport from './raport/preview';
import Ustawienia from './ustawienia/preview';
import ConfigX from '../../../ConfigX';


class Main extends React.Component
 {

    constructor(props)
    {
      super(props)
      
      this.state = {

          view: "intro",
          page: "Wstęp",
          naglowek: "Helpdesk to pomoc w zrozumieniu działania systemu usług BKP Klaster Dolina Przemysłowa. Spis podzielony jest na sekcje i podsekcje. Tematy opisywane są czasami bardziej szczegółowo w miejsach gdzie zachodzi taka potrzeba. Ważne miejsca zaznaczone są na zrzutach ekranu czerwonymi blokami czy strzałkami.",
          param: "",
      }
    }


toggle(){
  
            
        var element = document.querySelector(".page-wrapper"); 
        var xxx = element.classList;
        xxx.toggle("toggled"); 

}

getIntro(event)
{
    this.setState({
        view: "intro",
        page: "Wstęp",
        naglowek: "Helpdesk to pomoc w zrozumieniu działania systemu usług BKP Klaster Dolina Przemysłowa. Spis podzielony jest na sekcje i podsekcje. Tematy opisywane są czasami bardziej szczegółowo w miejsach gdzie zachodzi taka potrzeba. Ważne miejsca zaznaczone są na zrzutach ekranu czerwonymi blokami czy strzałkami."
    })
}

getAccount(event){this.setState({view: "account", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces logowania się do systemu, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}
getRegister(event){this.setState({view: "register", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces rejestracji do systemu, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}
getRemind(event){this.setState({view: "remind", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest mechanizm zmiany hasła do systemu, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}

getMembers(event){this.setState({view: "members", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces obsługi uczestników zapisanych na szkolenie, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}
getSignUpTrainings(event){this.setState({view: "trainings1", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces zapisywania się przez użytkownika na szkolenie, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}
addTraining(event){this.setState({view: "addtraining", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces dodawania szkolenia poprzez panel administracyjny aby mogło być wyświetlane na stronie głównej na liście szkoleń, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}
delTraining(event){this.setState({view: "deltraining", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces usuwania szkolenia z systemu poprzez panel administracyjny, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}

getSignUpBadania(event){this.setState({view: "badania1", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces składania wniosku do systemu na badanie przez firmę, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}
getWnioski(event){this.setState({view: "wnioski", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces obsługi zleconych wniosków badań, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}
addBadanie(event){this.setState({view: "addbadania", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces dodawania badania poprzez panel administracyjny aby mogło być wyświetlane na stronie głównej, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}
delBadanie(event){this.setState({view: "delbadania", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces usuwania badania z systemu poprzez panel administracyjny, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}

addBaza(event){this.setState({view: "addbaza", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces edycji firmy istniejącej w systemie, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów.",param: event.target.id})}
delBaza(event){this.setState({view: "delbaza", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces usuwania firmy w systemie, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}

editOsoba(event){this.setState({view: "editosoba", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces edycji użytkownika/uczestnika systemu, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}
delOsoba(event){this.setState({view: "delosoba", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces usuwania użytkownika/uczestnika systemu, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}

previewRozliczenia(event){this.setState({view: "rozliczenia", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces generowania i edytowania rozliczeń w systemie, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}

previewRaport(event){this.setState({view: "raport", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisany jest proces generowania raportu w systemie, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}

previewUstawienia(event){this.setState({view: "ustawienia", page: event.target.innerHTML, naglowek: "Sekcja gdzie opisane są ustawienia systemowe, zmiana hasła, dodanie nowych administratorów, należy zwracać uwagę na czerwone podpowiedzi na zrzutach ekranów."})}




  render(){
    
     

    const d = new Date();
    let data = d.getFullYear() ;

    let view = this.state.view;
    let intro = "";
    let account = "";
    let register = "";
    let remind = "";
    let trainings1 = "";
    let addtraining = "";
    let deltraining = "";
    let badania1 = "";
    let addbadania = "";
    let delbadania = "";
    let addbaza = "";
    let delbaza = "";
    let editosoba = "";
    let delosoba = "";
    let rozliczenia = "";
    let raport = "";
    let ustawienia = "";
    let members = "";
    let wnioski = "";

    if(view == 'intro'){ intro = (<Intro/>)  }
    if(view == 'account'){ account = (<Account/>)  }
    if(view == 'register'){ register = (<Register/>)  }
    if(view == 'remind'){ remind = (<Remind/>)  }

    if(view == 'members'){ members = (<Members/>)  }
    if(view == 'trainings1'){ trainings1 = (<SignUpTrainings/>)  }
    if(view == 'addtraining'){ addtraining = (<AddTraining/>)  }
    if(view == 'deltraining'){ deltraining = (<DelTraining/>)  }

    if(view == 'badania1'){ badania1 = (<SignUpBadania/>)  }
    if(view == 'wnioski'){ wnioski = (<Wnioski/>)  }
    if(view == 'addbadania'){ addbadania = (<AddBadania/>)  }
    if(view == 'delbadania'){ delbadania = (<DelBadania/>)  }

    if(view == 'addbaza'){ addbaza = (<AddBaza param={this.state.param}/>)  }
    if(view == 'delbaza'){ delbaza = (<DelBaza/>)  }

    if(view == 'editosoba'){ editosoba = (<EditOsoba/>)  }
    if(view == 'delosoba'){ delosoba = (<DelOsoba/>)  }

    if(view == 'rozliczenia'){ rozliczenia = (<Rozliczenia/>)  }

    if(view == 'raport'){ raport = (<Raport/>)  }
    if(view == 'ustawienia'){ ustawienia = (<Ustawienia/>)  }

    document.title = ConfigX.title + " / Pomoc";

    return (

    <>

        <div className='page-wrapper org-theme toggled'>

        <a id="show-sidebar" class="btn btn-sm" href="#"  onClick={this.toggle.bind(this)}>MENU</a>

<div id="sidebar" className="sidebar-wrapper nav2">
            <div className="sidebar-content">
                <div className="sidebar-brand">
                    <a href="#" style={{pointerEvents: "none"}}>HELPDESK</a>
                    <div id="close-sidebar" onClick={this.toggle.bind(this)}>
                        ✖
                    </div>
               
                </div>

                <div className="sidebar-menu">
                    <ul>
                        <li className="side-list" onClick={this.getIntro.bind(this)}><a href="#">Wstęp</a></li>
                        <li className="sidebar-dropdown">
                            <a><span>Konto</span></a>
                            <div className="sidebar-submenu">
                                <ul>
                                    <li onClick={this.getAccount.bind(this)}><a href="#">Jak zalogować się do systemu?</a></li>
                                    <li onClick={this.getRegister.bind(this)}><a href="#">Jak zarejestrować się do systemu?</a></li>
                                    <li onClick={this.getRemind.bind(this)}><a href="#">Jak przypomnieć hasło?</a></li>
                                </ul>
                            </div>
                          
                        </li>
                      
                        <li className="sidebar-dropdown">
                            <a><span>Szkolenia</span></a>
                            <div className="sidebar-submenu">
                                <ul>                                     
                                    <li onClick={this.getSignUpTrainings.bind(this)}><a href="#">Zapis na szkolenie</a></li>
                                    <li onClick={this.getMembers.bind(this)}><a href="#">Uczestnicy szkolenia</a></li>
                                    <li onClick={this.addTraining.bind(this)}><a href="#">Dodawanie szkolenia</a></li>
                                    <li onClick={this.delTraining.bind(this)}><a href="#">Usuwanie szkolenia</a></li>
                                </ul>
                            </div>
                       
                        </li>

                        <li className="sidebar-dropdown">
                            <a><span>Badania</span></a>
                            <div className="sidebar-submenu">
                                <ul> 
                                    <li onClick={this.getSignUpBadania.bind(this)}><a href="#">Zlecenie badania</a></li>
                                    <li onClick={this.getWnioski.bind(this)}><a href="#">Obsługa wniosków</a></li>
                                    <li onClick={this.addBadanie.bind(this)}><a href="#">Dodawanie badania</a></li>
                                    <li onClick={this.delBadanie.bind(this)}><a href="#">Usuwanie badania</a></li>
                                </ul>
                            </div>
                       
                        </li>
                     
                        <li className="sidebar-dropdown">
                            <a><span>Baza firm</span></a>
                            <div className="sidebar-submenu">
                                <ul> 
                                    <li onClick={this.addBaza.bind(this)}><a href="#">Dodawanie firmy</a></li>
                                    <li onClick={this.addBaza.bind(this)} ><a href="#zlec">Zleć badanie przez firmę</a></li>
                                    <li onClick={this.delBaza.bind(this)}><a href="#">Usuwanie firmy</a></li>
                                </ul>
                            </div>
                       
                        </li>
                    
                        <li className="sidebar-dropdown">
                            <a><span>Baza osób</span></a>
                            <div className="sidebar-submenu">
                                <ul> 
                                    <li onClick={this.editOsoba.bind(this)}><a href="#">Edycja użytkownika</a></li>
                                    <li onClick={this.delOsoba.bind(this)}><a href="#">Usuwanie użytkownika</a></li>
                                </ul>
                            </div>
                       
                        </li>

                        <li className="sidebar-dropdown">
                            <a><span>Rozliczenia</span></a>
                            <div className="sidebar-submenu">
                                <ul> 
                                    <li onClick={this.previewRozliczenia.bind(this)}><a href="#">Podgląd rozliczeń</a></li>
                                </ul>
                            </div>                       
                        </li>

                        <li className="sidebar-dropdown">
                            <a><span>Raport</span></a>
                            <div className="sidebar-submenu">
                                <ul> 
                                    <li onClick={this.previewRaport.bind(this)}><a href="#">Podgląd raportu</a></li>
                                </ul>
                            </div>                       
                        </li>

                        <li className="sidebar-dropdown">
                            <a><span>Ustawienia</span></a>
                            <div className="sidebar-submenu">
                                <ul> 
                                    <li onClick={this.previewUstawienia.bind(this)}><a href="#">Podgląd ustawień</a></li>
                                </ul>
                            </div>                       
                        </li>

                    </ul>
                </div>
            
            </div>
           
        </div>




        <main className="page-content">
     
            <div className="doc-head-section">
                <div className="header">
                    <nav className="navbar navbar-expand-lg navbar-light clearfix with-sidebar"> <a className="navbar-brand brand-name" href="#">VedhaDocs</a>
                  
                        
                        <div className="collapse" id="navbarSupportedContent">
                            <div className="navbar-nav mr-auto d-flex flex-wrap justify-content-center py-1">
                                
                                    <Link to={ConfigX.pathDef + "dashboard" } className='nav-link' >Wróć do panelu administracyjnego</Link>
                                
                            </div>
                        </div>
               
                    </nav>
              
                </div>
            
                <div className="upper-head">
                    <h3>{this.state.page}</h3>
                    <p>{this.state.naglowek}</p>
                </div>
           
            </div>
  
            <div className="clearfix main-content2">
                <div className="content-section">
         
                    <section id="method" className="section border-no-sec">

                        {intro}
                        {account}
                        {register}
                        {remind}

                        {members}
                        {trainings1}
                        {addtraining}
                        {deltraining}

                        {badania1}
                        {wnioski}
                        {addbadania}
                        {delbadania}

                        {addbaza}
                        {delbaza}

                        {editosoba}
                        {delosoba}

                        {rozliczenia}

                        {raport}

                        {ustawienia}




                    </section>
             
                </div>
            
              
                
             
                
            </div>
         
     

        </main>




        </div>


    </>

    )
 
    } 
 }

export default Main;
