import React from "react";


import ConfigX from '../../../ConfigX'
import FirmSelector from '../../../components/FirmSelector';
import TipMessage from '../../../components/TipMessage';

import BackPNG from './../../../image/back.png'
import PrintPNG from './../../../image/print.png'

import loading from '../../../image/loading.gif'
import ButtonConfirm from '../../../components/ButtonConfirm';
import AlertMsg from '../../../components/AlertMsg';

import DetWnioskiRow from "./DetWnioskiRow";

import FileUploader from '../../../components/FileUploader';
import Regulation from "../../../components/Regulation";

import EditWniosek from "./EditWniosek";


class Det extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            loading: 1,
            search_bar: {                                           
                od: "",
                do: "",
                nazwa: "",
                nazwa_firmy: "",
                id: this.props.id,                    
            },
            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },
            rows: [],
            result: [],

            postanowienia: [],

            wnioski: [],
            wnisek_fraza: "",
    
            wniosek_status: "",

            wniosek_selected_row: null,
            wniosek_selected_id: -1,


            nn_tytul: "",
            nn_informacje_ogolne: "",
            nn_opis_procedury: "",
            nn_info_telefon: "",
            nn_info_email: "",
            nn_info_www: "",
            nn_info_osoba_kontaktowa: "",

            alertMSG: '',
            alertMSGTime: 0,
        }


      
    }



    componentDidMount()
    {
        console.log("Det did mount");
        this.getData(); 
        this.getWnioski();
    }


      //Pobranie danych
    getData()
      {
  
          //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
          var wait = setTimeout(
               () => {
                  this.setState({ loading: 1 });
               }
              ,500);
  
          var tmpNonRef = JSON.stringify( this.state );
          tmpNonRef = JSON.parse(tmpNonRef);
  
          var dataPost = tmpNonRef.search_bar;
          dataPost.pagger = tmpNonRef.pagger;
  
        
          
          //console.log("Requets " +  JSON.stringify(dataPost) );
  
          fetch( ConfigX.restApi + "/dash,get_research" , {
              method: 'POST', 
              body: JSON.stringify(dataPost), 
              headers: {
                  'Content-Type': 'text/html',
                  'X-API-KEY': this.props.apiKey
              }
          })
          .then( res => res.json() )
          .then(json => {
          
               //console.log(JSON.stringify(json) );
               clearTimeout(wait);
  
               this.setState({
                  rows: json.rows[0],
                  result: json.result, //zawsze pierwszy indeks
                  loading: 0
               });      
             
          });
    }


    onBackClick()
    {
        this.props.redirect("list", this.props.id);
    }

    onSelectedFirm(id, nazwa)
    {
        var tmp = this.state.rows;
        tmp.podmiot_prowadzacy = {};
        tmp.podmiot_prowadzacy.id = id;
        tmp.podmiot_prowadzacy.nazwa = nazwa;

        this.setState({
            rows: tmp                        
        });

        this.updateBadanie(null);
    }

    onChangeTytul(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["tytul"] = v; 

            this.setState( {
                rows: rows,
                nn_tytul: ok
            } );

         //  if(ok == "correct"  )  this.updateBadanie(null);

    }  

    onChangeStatus(event)
    {
        var v   = event.target.value;      

        var rows = this.state.rows;
        rows["status"] = v; 

        this.setState( {
            rows: rows,     
        } ); 

       // this.updateBadanie(null);
    }


    onChangeInformacjeOgolne(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 20)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["informacje_ogolne"] = v; 

            this.setState( {
                rows: rows,
                nn_informacje_ogolne: ok
            } );

          //  if(ok == "correct"  )  this.updateBadanie(null);

    }

    onChangeOpisProcedury(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 20)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["opis_procedury"] = v; 

            this.setState( {
                rows: rows,
                nn_opis_procedury: ok
            } );

           // if(ok == "correct"  )   this.updateBadanie(null);

    }

    onChangeTelefon(event)
    {
              var v = event.target.value;

                //v = v.replace("+48","");

               // v = v.replace(/[^0-9]/g, ""); //wywal litery...
                v = v.replace(" ","");

              //  if(v.substr(0,3) != '+48')
              //  {
              //      v = "+48" + v;
             //   }
            
                
                var ok = ""; 
                if(v.length > 8  ) 
                {
                    ok = "correct";
                }

                var rows = this.state.rows;
                rows["info_telefon"] = v; 
    
                this.setState( {
                    rows: rows,
                    nn_info_telefon: ok
                } );

              //  if(ok == "correct"  )  this.updateBadanie(null);
    }

    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

    onChangeEmail(event)
    {
          var v = event.target.value;
        v = v.replace(" ","");

        var ok = "";
        if(v.length > 0 && this.isValidEmail(v) ) 
        {
           ok = "correct";
        }else
        if(v.length > 0)
        {
            ok ="incorrect";
        }

        var rows = this.state.rows;
        rows["info_email"] = v; 

        this.setState( {
            rows: rows,
            nn_info_email: ok
        } );


       // if(ok == "correct"  )  this.updateBadanie(null);
    }


    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }

    onChangeWWW(event)
    {
        var v   = event.target.value;
        var ok = "";

        if(v.length > 0)
        {
            if(!this.validURL(v) )
            { ok = "incorrect"; } else { ok="correct" }
        }else
        {
            ok="correct" 
        }

        var rows = this.state.rows;
        rows["info_www"] = v; 

        this.setState( {
            rows: rows,
            nn_info_www: ok
        } );

        //if(ok == "correct"  )    this.updateBadanie(null);
    }


    onChangeOsoba(event)
    {
        var v   = event.target.value;
        var ok = "";
      
        var rows = this.state.rows;
        rows["info_osoba_kontaktowa"] = v; 

        this.setState( {
            rows: rows,
            nn_info_osoba_kontaktowa: ok
        } );

        //this.updateBadanie(null);
    }



    onConfirmDel(type, param, param_id)
    {
      // console.log(type + param + param_id);

       if(type != "yes")  return;


       //sprawdz czy sa potwierdzone uczestnictwa..
    
       var potwierdzony = false;
      
       /*
       this.state.uczestnicy_rows.forEach(item => {
           if( item.status == 'potwierdzone')
           {
             console.log("Nie mozna usunąc z powodu:  " + item.id + "  " + item.status);
             potwierdzony = true;
           }
        })


       if(potwierdzony)
       {
           var dt = new Date();
           dt = dt.toISOString();
         
           this.setState({
              alertMSG: 'Nie możesz usunać tej usługi',
              alertMSGTime: dt
           });
           return;
       }

       */

       
         //
         var dataPost = {
            id: param_id,       
        };

   
        fetch( ConfigX.restApi + "/dash,del_research" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             if(json.status == "OK")
             {
                this.props.redirect("list",-1,"deleted");
             }
             if(json.status == 'ERROR')
             {
                this.setState({
                    alertMSG: json.param,
                    alertMSGTime: 200,
                })
             }                 
        });

        
        
    }

    updateBadanie(event)
    {
        if(event != null)
        {
        var err = false;

        if(this.state.rows.tytul.length < 2 || this.state.nn_tytul == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_tytul: "incorrect"
            });
        }

        if(this.state.rows.informacje_ogolne.length < 20 || this.state.nn_informacje_ogolne == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_informacje_ogolne: "incorrect"
            });
        }

        if(this.state.rows.opis_procedury.length < 20 || this.state.nn_opis_procedury == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_opis_procedury: "incorrect"
            });
        }

        if(this.state.rows.info_email.length < 10 || this.state.nn_info_email == 'incorrect') 
        {
            err = true;
            this.setState({
                nn_info_email: "incorrect"
            });
        }


        if(err) return;

        if(this.state.postanowienia.length < 1)
        {
            err = true;

            var dt = new Date();
            dt = dt.toISOString();

            this.setState({
                alertMSG: 'Dodaj min. jedno postanowienie. ',
                alertMSGTime: dt
             });
        }


        if(err) return;
       }


        if(event != null)
        {
          var bnText = event.target.innerHTML;
          event.target.innerHTML = "...";
        }



        var dataPost = {
            id: this.state.rows.id,
            sk_firmy_id: this.state.rows.podmiot_prowadzacy.id,
            tytul: this.state.rows.tytul,
            informacje_ogolne: this.state.rows.informacje_ogolne,
            opis_procedury: this.state.rows.opis_procedury,
            info_telefon: this.state.rows.info_telefon,
            info_email: this.state.rows.info_email,
            info_www: this.state.rows.info_www,
            info_osoba_kontaktowa: this.state.rows.info_osoba_kontaktowa,
            status: this.state.rows.status,

            email_tytul: this.state.rows.email_tytul,
            email_tresc: this.state.rows.email_tresc,

        }


        fetch( ConfigX.restApi + "/dash,add_edit_research" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {

              var dt = new Date();
              dt = dt.toISOString();

             if(json.status == "OK")
             {
                if(event != null)
                {
                   event.target.innerHTML = bnText;
                                    
                    this.setState({
                        alertMSG: 'Informacje zostały zapisane.',
                        alertMSGTime: dt
                    });
                }
             }else
             {
                 this.setState({
                     alertMSG: ' Wystąpił problem z zapisem danych.', 
                     alertMSGTime: dt
                  });  
             }
      
            //this.getPostanowienia();
           
        });

    }

    getPostanowienia()
    {       
           var dataPost = {
            typ: "sk_badania",
            typ_id: this.state.rows.id
            }
   
           fetch( ConfigX.restApi + "/dash,get_regulation" , {
               method: 'POST', 
               body: JSON.stringify(dataPost), 
               headers: {
                   'Content-Type': 'text/html',
                   'X-API-KEY': this.props.apiKey
               }
           })
           .then( res => res.json() )
           .then(json => {              
                this.setState({
                   postanowienia: json,                                          
                });      
              
           });
    }

    onChangeWniosekFraza(event)
    {
        this.setState({
            wnisek_fraza: event.target.value
        });

        this.updateBadanie(null);
    }
    
   
    onChangeWniosekkStatus(event)
    {
        //console.log(event.target.value);
        this.setState({
            wniosek_status: event.target.value
        });

        this.updateBadanie(null);
    }


       //Pobranie danych
    getWnioski()
    {       
           var dataPost = {
              id: this.props.id
            }
   
           fetch( ConfigX.restApi + "/dash,get_application" , {
               method: 'POST', 
               body: JSON.stringify(dataPost), 
               headers: {
                   'Content-Type': 'text/html',
                   'X-API-KEY': this.props.apiKey
               }
           })
           .then( res => res.json() )
           .then(json => {              
                this.setState({
                   wnioski: json,                                          
                });      
              
           });
    }


    onClickWniosek(id)
    {
          //  console.log("Wniosek " + id);

            if(id <= 0 ) 
            {
                this.setState({
                    wniosek_selected_row: null,
                    wniosek_selected_id: -1
                });
                return;
            }

            
            this.state.wnioski.forEach( item => {
                if(item.id == id)
                {                     
                   var newRef = JSON.stringify(item);
                   newRef = JSON.parse(newRef);

                   this.setState({
                       wniosek_selected_row: newRef,
                       wniosek_selected_id: id
                   });
                   return;
                }
            } )


    }

    onWniosekClose()
    {
        this.setState({
            wniosek_selected_row: null,
            wniosek_selected_id: -1
        }); 

        this.getWnioski();
    }



    onChangeEmailTemat(event)
    {
            var v   = event.target.value;
            var ok = "";
              

            var rows = this.state.rows;
            rows["email_tytul"] = v; 

            this.setState( {
                rows: rows,               
            } );

            //this.updateBadanie(null);
    } 

    onChangeEmailTresc(event)
    {
            var v   = event.target.value;
            var ok = "";
              

            var rows = this.state.rows;
            rows["email_tresc"] = v; 

            this.setState( {
                rows: rows,               
            } );

           // this.updateBadanie(null);
    } 


    setPostanowienia(rows)
    {
        this.setState({
            postanowienia: rows
        });

        this.updateBadanie(null);
    }


    render()
    {

        var msg = "";
        var nowych = 0;



        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>                                               
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        } 
        

       
            this.state.wnioski.forEach( item => {
                if(item.status == "nowy") nowych ++ ;
            } )
        
        

   

        return (
            <div className='details'>
                  
                  <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy badań
                 </div>
                 
                                    
                    <h3>
                    ✏  {this.state.rows.tytul}                                                     
                    </h3>
                    <h5>nowych wniosków: {nowych}</h5>     

                    <TipMessage  msg={msg} type="notice" />

                    <div className='dashForm'>
                            <div className='FormField'>
                                <label>Podmiot prowadzący<span className='required'>*</span></label>
                                <FirmSelector onSelectedFirm={this.onSelectedFirm.bind(this)} apiKey={this.props.apiKey}  id={this.state.rows.podmiot_prowadzacy.id} title={this.state.rows.podmiot_prowadzacy.nazwa} />
                            </div>

                            <div className='FormField inlineField'>
                               <label>Tytuł<span className='required'>*</span></label>
                               <input type="text" className={"field300 " + this.state.nn_tytul } value={this.state.rows.tytul}  col="tytul" onChange={this.onChangeTytul.bind(this) } />
                            </div>

                            <div className='FormField inlineField'>
                               <label>Status<span className='required'>*</span></label>
                               <select defaultValue={this.state.rows.status} onChange={this.onChangeStatus.bind(this) } col="status" >
                                  <option value="widoczne">Widoczne</option>              
                                  <option value="robocze">Robocze</option>              
                                  <option value="niewidoczne">Niewidoczne</option>              
                               </select>
                            </div>

                            <div className='FormField'>
                               <label>Informacje ogólne<span className='required'>*</span> (min. 100 znaków)</label>                  
                               <textarea  className={"field100p field100h " + this.state.nn_informacje_ogolne} value={this.state.rows.informacje_ogolne} col="informacje_ogolne" onChange={this.onChangeInformacjeOgolne.bind(this) }   />
                          </div>

                          <div className='FormField'>
                               <label>Opis procedury<span className='required'>*</span> (min. 50 znaków)</label>                  
                               <textarea  className={"field100p field100h " + this.state.nn_opis_procedury} value={this.state.rows.opis_procedury} col="opis_procedury" onChange={this.onChangeOpisProcedury.bind(this) }   />
                          </div>


                          <div className='FormField inlineField'>
                               <label>Osoba kontaktowa</label>
                               <input type="text" className={"field200 " + this.state.nn_info_osoba_kontaktowa} value={this.state.rows.info_osoba_kontaktowa}  col="info_osoba_kontaktowa" onChange={this.onChangeOsoba.bind(this) }    />
                          </div>

                          <div className='FormField inlineField'>
                               <label>Telefon kontaktowy<span className='required'>*</span> (+48________)</label>
                               <input type="text" className={"field200 " + this.state.nn_info_telefon} value={this.state.rows.info_telefon}  col="info_telefon" onChange={this.onChangeTelefon.bind(this) }    />
                          </div>

                          <div className='FormField inlineField'>
                               <label>E-mail kontaktowy<span className='required'>*</span></label>
                               <input type="text" className={"field200 " + this.state.nn_info_email} value={this.state.rows.info_email}  col="info_email" onChange={this.onChangeEmail.bind(this) }    />
                          </div>

                          <div className='FormField inlineField'>
                               <label>Strona internetowa projektu (https://...)</label>
                               <input type="text" className={"field200 " + this.state.nn_info_www} value={this.state.rows.info_www}  col="info_www" onChange={this.onChangeWWW.bind(this) }    />
                          </div>



                          <br/>
                          <br/>
                          <div className='FormField inlineField'>
                               <label>Tytuł e-mail <span className='required'>*</span></label>
                               <input type="text" className={"field300 " } value={this.state.rows.email_tytul}  col="tytul" onChange={this.onChangeEmailTemat.bind(this) } />
                          </div>
                          <div className='FormField'>
                               <label>Treść e-mail (wysyłana podczas zapisu na badanie)</label>                  
                               <textarea  className={"field100p field100h " } value={this.state.rows.email_tresc} col="email_tresc" onChange={this.onChangeEmailTresc.bind(this) }    />
                          </div>

                          <br/>
                          <br/>



                          <div className='FormField FormButtons'>
                            <div className='buttonForm' onClick={this.updateBadanie.bind(this)}>zapisz</div>
                            &nbsp; &nbsp;   
                            <ButtonConfirm   onConfirm={this.onConfirmDel.bind(this) } param="sk_badania" param_id={this.state.rows.id}  title="usuń usługę*" query="Na pewno chcesz usunąć tę usługę?"  />                                                          
                         </div>

                         <div className='simplyTipAlert'>
                            *Przeniesienie badania do archiwum jest możliwe wyłącznie, gdy nie ma żadnego wniosku z zaakceptowaną wyceną.                       
                         </div>

                    </div>

                    <div className='location' onClick={this.onBackClick.bind(this) }>
                      <img src={BackPNG} /> Powrót do listy badań
                    </div>

                    <div>
                        <br/>
                          <FileUploader typ="sk_badania" typ_id={this.state.rows.id} apiKey={this.props.apiKey} />
                          <br/>
                    </div>

                    <div>
                        <br/>
                          <Regulation typ="sk_badania" typ_id={this.state.rows.id} onChange={this.setPostanowienia.bind(this)} apiKey={this.props.apiKey} />
                          <br/>
                    </div>


                    <h3>📜 Wnioski <sup>nowych  {nowych}</sup> </h3>     


                    <div className='search_bar'>
                        <div className='filter'>
                            <div className='filter_label'>Fraza, imię, nazwisko</div>
                            <div className='filter_content'>
                                <input type="text" value={this.state.wnisek_fraza} onChange={this.onChangeWniosekFraza.bind(this)}    />
                            </div>                                 
                        </div>

                  

                        <div className='filter'>
                            <div className='filter_label'>Status</div>
                            <div className='filter_content'>
                                <select defaultValue={this.state.uczestnicy_status} onChange={this.onChangeWniosekkStatus.bind(this)}>
                                     <option value="">Wszystkie</option>
                                     <option value="nowy">Nowy wniosek</option>
                                     <option value="przygotowanie_wyceny">Przygotowanie wyceny</option>
                                     <option value="wycena_gotowa">Wycena gotowa</option>
                                     <option value="kwota_przyjeta">Kwota przyjęta</option>
                                     <option value="kwota_odrzucona">Kwota odrzucona</option>
                                     <option value="usluga_w_trakcie">Usługa w trakcie realizacji</option>
                                     <option value="usluga_wykonana">Usługa wykonana</option>
                                     <option value="usluga_przerwana">Usługa przerwana</option>
                                </select>
                            </div>                                 
                        </div>
                    </div>




                    <div className='table_content tableScroll250'>                 
                        <table className='table table100'>
                        <thead>
                            <tr>
                                <td>LP</td>
                                <td>Numer</td>
                                <td>Data</td>
                                <td>Firma</td>
                                <td>Nazwisko</td>
                                <td>Imię</td>                                                                                    
                                <td>Status</td>  
                                <td>K. netto</td>  
                                <td>K. vat</td>  
                                <td>Rozliczone</td>  
                                <td>Dokumenty</td>
                            </tr>
                        </thead>
                        <tbody> 
                            {                                                        
                                this.state.wnioski.map( (item,index) => 
                                {                                                                                   
                                        var render = true;
                          

                                        if(this.state.wnisek_fraza.length>0 )
                                        {                                         
                                                render = false;
                                                if(item.nazwisko.toLowerCase().includes(this.state.wnisek_fraza.toLowerCase()) ) render =  true;
                                                if(item.imie.toLowerCase().includes(this.state.wnisek_fraza.toLowerCase()) ) render =  true;
                                                if(item.telefon.toLowerCase().includes(this.state.wnisek_fraza.toLowerCase()) ) render = true;                                                                                     
                                        }

                                        if(this.state.wniosek_status.length > 1)
                                        {
                                            render = false;
                                            if(item.status == this.state.wniosek_status) render = true;
                                        }
                         
                                        if(!render) return (null);                                    

                                    return(
                                     <DetWnioskiRow row={item} key={index}    num={index+1} onClickWniosek={this.onClickWniosek.bind(this)}  apiKey={this.props.apiKey}  />
                                    )
                                    
                                } )
                            } 
                        </tbody>    


                        </table>

                   </div>



                    <br/>
                    <div className='location' onClick={this.onBackClick.bind(this) }>
                      <img src={BackPNG} /> Powrót do listy badań
                    </div>

                    <EditWniosek apiKey={this.props.apiKey}  row={this.state.wniosek_selected_row}  id={this.state.wniosek_selected_id} onClose={this.onWniosekClose.bind(this)} />        

                    <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Powiadomienie"/>

            </div>
        )
    }
}

export default Det;