import React from "react";


import ConfigX from '../../../ConfigX'
import FirmSelector from '../../../components/FirmSelector';
import TipMessage from '../../../components/TipMessage';

import BackPNG from './../../../image/back.png'
import PrintPNG from './../../../image/print.png'

import loading from '../../../image/loading.gif'
import ButtonConfirm from '../../../components/ButtonConfirm';
import AlertMsg from '../../../components/AlertMsg';

import DetWnioskiRow from "./DetWnioskiRow";
import Messanger from '../../../components/Messanger';
import FileUploader from '../../../components/FileUploader';

import EditWniosek from "./EditWniosek";


class Det extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            loading: 1,
            search_bar: {                                           
                od: "",
                do: "",
                nazwa: "",
                nazwa_firmy: "",
                id: this.props.id,                    
            },
            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },
            rows: [],
            result: [],

            wnioski: [],
            wnisek_fraza: "",
    
            wniosek_status: "",

            wniosek_selected_row: null,
            wniosek_selected_id: -1,


            nn_tytul: "",
            nn_informacje_ogolne: "",
            nn_opis_procedury: "",
            nn_info_telefon: "",
            nn_info_email: "",
            nn_info_www: "",
            nn_info_osoba_kontaktowa: "",

            alertMSG: '',
            alertMSGTime: 0,
        }
    }

    componentDidMount()
    {
        console.log("Det did mount");
        this.getData(); 
        this.getWnioski();
    }


      //Pobranie danych
    getData()
      {
  
          //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
          var wait = setTimeout(
               () => {
                  this.setState({ loading: 1 });
               }
              ,500);
  
          var tmpNonRef = JSON.stringify( this.state );
          tmpNonRef = JSON.parse(tmpNonRef);
  
          var dataPost = tmpNonRef.search_bar;
          dataPost.pagger = tmpNonRef.pagger;
  
        
          
          //console.log("Requets " +  JSON.stringify(dataPost) );
  
          fetch( ConfigX.restApi + "/user,get_research" , {
              method: 'POST', 
              body: JSON.stringify(dataPost), 
              headers: {
                  'Content-Type': 'text/html',
                  'X-API-KEY': this.props.apiKey
              }
          })
          .then( res => res.json() )
          .then( json => {
          
               //console.log(JSON.stringify(json) );
               clearTimeout(wait);
  
               this.setState({
                  rows: json.rows[0],
                  result: json.result, //zawsze pierwszy indeks
                  loading: 0
               });      
             
          });
    }


    onBackClick()
    {
        this.props.redirect("list", this.props.id);
    }

    onSelectedFirm(id, nazwa)
    {
        var tmp = this.state.rows;
        tmp.podmiot_prowadzacy = {};
        tmp.podmiot_prowadzacy.id = id;
        tmp.podmiot_prowadzacy.nazwa = nazwa;

        this.setState({
            rows: tmp                        
        });
    }
 

       //Pobranie danych
    getWnioski()
    {       
           var dataPost = {
              id: this.props.id
            }
   
           fetch( ConfigX.restApi + "/user,get_application" , {
               method: 'POST', 
               body: JSON.stringify(dataPost), 
               headers: {
                   'Content-Type': 'text/html',
                   'X-API-KEY': this.props.apiKey
               }
           })
           .then( res => res.json() )
           .then(json => {              
                this.setState({
                   wnioski: json,                                          
                });      
              
           });
    }


  

    render()
    {

        var msg = "";
        var nowych = 0;


        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>                                               
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        } 
        

       
            this.state.wnioski.forEach( item => {
                if(item.status == "nowy") nowych ++ ;
            } )
   
        return (
            <div className='details'>
                  
                  <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy badań
                 </div>
                 
                                    
                    <h3>
                        {this.state.rows.tytul}                                                     
                    </h3>
                    <h5>nowych wniosków: {nowych}</h5>     

                    <TipMessage  msg={msg} type="notice" />

                    <div className='dashForm'>
                            <div className='FormField inlineField'>
                                <label>Podmiot prowadzący</label>
                                <input type="text" defaultValue={this.state.rows.podmiot_prowadzacy.nazwa}  className="field300" />
                            </div>

                            <div className='FormField '>
                               <label>E-mail kontaktowy</label>
                               <input type="text" className={"field200 " + this.state.nn_info_email} defaultValue={this.state.rows.info_email}  col="info_email"   />
                               <div className="chatIcon"><Messanger apiKey={this.props.apiKey}    user_id={this.state.rows.podmiot_prowadzacy.id}  user="1" typ="badania" record_id={this.props.id} /> </div>
                            </div>

                            <div className='FormField inlineField'>
                               <label>Tytuł</label>
                               <input type="text" className={"field300 " + this.state.nn_tytul } defaultValue={this.state.rows.tytul}  col="tytul"  />
                            </div>

                            <div className='FormField inlineField'>
                               <label>Status</label>
                                <input type="text" className="field300" defaultValue={this.state.rows.status} />
                            </div>

                            <div className='FormField'>
                               <label>Informacje ogólne (min. 100 znaków)</label>                  
                               <textarea  className={"field100p field100h " + this.state.nn_informacje_ogolne} defaultValue={this.state.rows.informacje_ogolne} col="informacje_ogolne"  />
                          </div>

                          <div className='FormField'>
                               <label>Opis procedury (min. 50 znaków)</label>                  
                               <textarea  className={"field100p field100h " + this.state.nn_opis_procedury} defaultValue={this.state.rows.opis_procedury} col="opis_procedury"  />
                          </div>


                          <div className='FormField inlineField'>
                               <label>Osoba kontaktowa</label>
                               <input type="text" className={"field200 " + this.state.nn_info_osoba_kontaktowa} defaultValue={this.state.rows.info_osoba_kontaktowa}  col="info_osoba_kontaktowa"   />
                          </div>

                          <div className='FormField inlineField'>
                               <label>Telefon kontaktowy (+48________)</label>
                               <input type="text" className={"field200 " + this.state.nn_info_telefon} defaultValue={this.state.rows.info_telefon}  col="info_telefon"   />
                          </div>


                          <div className='FormField inlineField'>
                               <label>Strona internetowa projektu (https://...)</label>
                               <input type="text" className={"field200 " + this.state.nn_info_www} defaultValue={this.state.rows.info_www}  col="info_www"   />
                          </div>

    

                    </div>

                    <div className='location' onClick={this.onBackClick.bind(this) }>
                      <img src={BackPNG} /> Powrót do listy badań
                    </div>

                    <div>
                        <br/>
                          <FileUploader typ="sk_badania" typ_id={this.state.rows.id} apiKey={this.props.apiKey} onlyDisplay="1" />
                          <br/>
                    </div>

                    <h3>Wnioski <sup>nowych  {nowych}</sup> </h3>    

                    <div className='table_content tableScroll250'>                 
                        <table className='table table100'>
                        <thead>
                            <tr>
                                <td>LP</td>
                                <td>Numer</td>
                                <td>Data</td>
                                <td>Nazwisko</td>
                                <td>Imię</td>                                                                                    
                                <td>Status</td>  
                                <td>K. netto</td>  
                                <td>K. vat</td>  
                                <td>Rozliczone</td>  
                                <td>Dokumenty</td>
                            </tr>
                        </thead>
                        <tbody> 
                            {                                                        
                                this.state.wnioski.map( (item,index) => 
                                {                                                                                   
                                        var render = true;
                          

                                        if(this.state.wnisek_fraza.length>0 )
                                        {                                         
                                                render = false;
                                                if(item.nazwisko.toLowerCase().includes(this.state.wnisek_fraza.toLowerCase()) ) render =  true;
                                                if(item.imie.toLowerCase().includes(this.state.wnisek_fraza.toLowerCase()) ) render =  true;
                                                if(item.telefon.toLowerCase().includes(this.state.wnisek_fraza.toLowerCase()) ) render = true;                                                                                     
                                        }

                                        if(this.state.wniosek_status.length > 1)
                                        {
                                            render = false;
                                            if(item.status == this.state.wniosek_status) render = true;
                                        }
                         
                                        if(!render) return (null);                                    

                                    return(
                                     <DetWnioskiRow row={item} key={index}    num={index+1} apiKey={this.props.apiKey}  chat='0' />
                                    )
                                    
                                } )
                            } 
                        </tbody>    


                        </table>

                   </div>



                    <br/>
                    <div className='location' onClick={this.onBackClick.bind(this) }>
                      <img src={BackPNG} /> Powrót do listy badań
                    </div>

                    <EditWniosek apiKey={this.props.apiKey}  row={this.state.wniosek_selected_row}  id={this.state.wniosek_selected_id}  />         

                    <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Powiadomienie"/>

            </div>
        )
    }
}

export default Det;