


import React from 'react';
import { Link  } from 'react-router-dom';


import step1 from '../../helpdesk/images/trainings/deltraining/step1.PNG';
import step2 from '../../helpdesk/images/trainings/deltraining/step2.PNG';
 


class DelTraining extends React.Component
 {



  render(){

    return(
        <>
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>Przechodzimy do listy szkoleń. Klikamy na szkolenie aby zajrzeć do szczegółów.</p>

          <img src={step1} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>Aby usunąć szkolenie musimy poszukać niżej na stronie czerwonego przycisku "Usuń szkolenie". Należy pamiętać, że jeżeli chcemy to zrobić nie może być żadnego potwierdzonego uczestnika tego szkolenia, na co wskazuje komunikat.</p>

          <img src={step2} alt=""/>

        </section>


        </>

    )
  }



}

export default DelTraining;