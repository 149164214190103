import React from 'react';

import ConfigX from '../ConfigX';
import AlertMsg from './AlertMsg';

class Calendar extends React.Component
 {

    lang = 'pl'; 


    constructor(props)
    {
        super(props)

        var dd = new Date();            

        if(this.props.y && this.props.m )
        {          
                dd = new Date( this.props.y + "-" + this.props.m + "-01 00:00:00" );
        }

      /*  var y = dd.getFullYear();
        var m = dd.getMonth()+1;
        var d = dd.getDate();
        var t = dd.getDay();
        var last  =   new Date(y, m, 0).getDate();        */

        var y = dd.getFullYear(); 
        var m = dd.getMonth()+1;
        var d = dd.getDate();
        var t = dd.getDay();
        var last  =   new Date(y, m, 0).getDate(); 
       // var first =   new Date(y, m-1, 1).getDay();

     //   console.log( y+ "-"  + m + '-' + d + ' d ' + t + "all " + last);

       // var calDays = new Array();

      /*  for(var k=0; k<t; k++)
        {
            calDays.push("");
        }

        for(var k=1; k<=last; k++)
        {
            calDays.push(k);
        }*/

        var years = new Array();
        for(var k=y-10; k<=y+10; k++)
        {
            years.push(k);
        }

        var months = new Array()
        months[1] = 'Styczeń'; 
        months[2] = 'Luty';
        months[3] = 'Marzec';
        months[4] = 'Kwiecień';
        months[5] = 'Maj';
        months[6] = 'Czerwiec';
        months[7] = 'Lipiec';
        months[8] = 'Sierpień';
        months[9] = 'Wrzesień';
        months[10] = 'Październik';
        months[11] = 'Listopad';
        months[12] = 'Grudzień';    

        if(this.lang == "en")
        {
            months[1] = 'January'; 
            months[2] = 'February';
            months[3] = 'March';
            months[4] = 'April';
            months[5] = 'May';
            months[6] = 'June';
            months[7] = 'July';
            months[8] = 'August';
            months[9] = 'September';
            months[10] = 'October';
            months[11] = 'November';
            months[12] = 'December';   
        }

        var daysOfWeek = new Array();
        daysOfWeek[0] = 'Poniedziałek';
        daysOfWeek[1] = 'Wtorek';
        daysOfWeek[2] = 'Środa';
        daysOfWeek[3] = 'Czwartek';
        daysOfWeek[4] = 'Piątek';
        daysOfWeek[5] = 'Sobota';
        daysOfWeek[6] = 'Niedziela';

        if(this.lang == 'en')
        {
            daysOfWeek[0] = 'Monday';
            daysOfWeek[1] = 'Tuesday';
            daysOfWeek[2] = 'Wednesday';
            daysOfWeek[3] = 'Thursday';
            daysOfWeek[4] = 'Friday';
            daysOfWeek[5] = 'Saturday';
            daysOfWeek[6] = 'Sunday';  
        }
        var calDays = new Array();

        this.state  = {
            year: y,
            month: m,
            days: calDays,
            rows: [],
            view: "month", //week / days
            years: years,
            months: months,
            daysOfWeek: daysOfWeek,  

            alertMSG: '',
            alertMSGTime: 0,
        }



    }


    getData()
    {      

        /**
         * Example response from server
               [
                {
                    "day": "2",
                    "month": "12",
                    "year": "2022",
                    "h": [
                    {
                        "timeStart": "18:48",
                        "timeStop": "18:48",
                        "title": "Training....",
                        "subtitle": "Lesson 1",
                        "typ": "sk_szkolenia_terminy",
                        "typ_id": "109"
                    }
                    ]
                 }
                ]
         */

        var dataPost = {};       

        fetch( ConfigX.restApi + "/dash,cal" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
      

             this.setState({
                rows: json,              
             });      
           
        });
  }


    componentDidMount()
    {
        this.getData();
    }

    onChangeMonth(event)
    {
        var v = event.target.value;
        this.setState({
            month: v
        }); 
    }

    onChangeYear(event)
    {
        var v = event.target.value;
        this.setState({
            year: v
        }); 
    }

    onClickTask(event)
    {
        var day = event.target.getAttribute("day");
        var h   = event.target.getAttribute("h");

        var det = null;

      //  console.log("Click on " + this.state.year+"-"+this.state.month+"-"+day + " " + h);


        for(var k=0; k<this.state.rows.length; k++)
        {
             if(  parseInt( this.state.rows[k].year)  == parseInt(this.state.year) && parseInt( this.state.rows[k].month)  == parseInt(this.state.month) )  
             {
                  for(var w =0; w< this.state.rows[k].h.length; w++)
                  {
                      if(this.state.rows[k].h[w].timeStart == h)
                      {
                          det = this.state.rows[k].h[w]; 
                          break;
                      }
                  }
             }
        }

        if(det == null) return;       
        

        var dt = new Date();
        dt = dt.toISOString();
        
        this.setState({
            alertMSG:  det.timeStart + " - " + det.timeStop + "\n\n" + det.title,
            alertMSGTime: dt
        });

    }

    onDialogOK(param)
    {
        var dt = new Date();
        dt = dt.toISOString();
        
        this.setState({
            alertMSG: '',
            alertMSGTime: dt
        });  
    }

    onViewMonth()
    {
        this.setState({
            view:"month"
        });
    }

    onViewWeek()
    {
        this.setState({
            view:"week"
        });
    }

    render()
    {    
        
        var days = new Array();     

        var first =   new Date(this.state.year, this.state.month-1, 1).getDay();
        var last  =   new Date(this.state.year, this.state.month, 0).getDate(); 
 
         for(var k=1; k<first; k++)
          {
             days.push("");
          }
  
          for(var k=1; k<=last; k++)
          {
              days.push(k);   
          }



        if(this.state.view == 'month')
        {
                return (
                    <div className='compCalendar'>

                            <div className='compControll'>
                            

                                

                                    <select defaultValue={this.state.year} onChange={this.onChangeYear.bind(this) }  >
                                        {
                                            this.state.years.map( item => {
                                                return (
                                                    <option value={item} key={item}>
                                                        {item}
                                                    </option>
                                                )
                                            })
                                        }
                                                                            
                                    </select>

                                    &nbsp;

                                    <select defaultValue={this.state.month} onChange={this.onChangeMonth.bind(this) }  >
                                        {
                                            this.state.months.map( (index,item) => {
                                                return (
                                                    <option value={item} key={item}>
                                                        {index}
                                                    </option>
                                                )
                                            })
                                        }
                                                                            
                                    </select>
                                

                                <div className='compControllLeft'>
                                    <div className='btnD' onClick={this.onViewMonth.bind(this)}>Miesiąc</div>
                                    <div className='btnD' onClick={this.onViewWeek.bind(this)}>Tydzień</div>
                                </div>

                            </div>
                    
                        <div className='cellWidth cellBold'>{this.state.daysOfWeek[0]}</div>
                        <div className='cellWidth cellBold'>{this.state.daysOfWeek[1]}</div>
                        <div className='cellWidth cellBold'>{this.state.daysOfWeek[2]}</div>
                        <div className='cellWidth cellBold'>{this.state.daysOfWeek[3]}</div>
                        <div className='cellWidth cellBold'>{this.state.daysOfWeek[4]}</div>
                        <div className='cellWidth cellBold'>{this.state.daysOfWeek[5]}</div>
                        <div className='cellWidth cellBold'>{this.state.daysOfWeek[6]}</div>                
                        {
                            days.map(item => {

                                var task = [];
                                for(var kk=0; kk<this.state.rows.length; kk++)
                                { 
                                    if(  parseInt(this.state.rows[kk].year) == parseInt(this.state.year) &&  parseInt(this.state.rows[kk].month) == parseInt(this.state.month) && parseInt(this.state.rows[kk].day) == parseInt(item) ) 
                                    {
                                        task[kk] = this.state.rows[kk].h;
                                    }
                                }                      

                                return (
                                    <div className='cellWidth celHeightN'>
                                        <div className='dayNum'>{item}</div>                               
                                        <div className='dayContent'>
                                                {
                                                    task.map( h=>{

                                                        var title = h.title;
                                                        if(title.length > 30) {
                                                            title = title.substring(0,30) + "...";
                                                        }

                                                        return (
                                                                    <div className='task'  day={item} h={h.timeStart}  onClick={this.onClickTask.bind(this) }>
                                                                        <span className='hour'>{h.timeStart}</span>
                                                                        &nbsp;-&nbsp;
                                                                        <span>{title}</span>
                                                                    </div>
                                                        )
                                                    })
                                                }
                                        </div>  
                                    </div>
                                )
                            })
                        }

                        <AlertMsg msg={this.state.alertMSG } onDialogOK={this.onDialogOK.bind(this)}  msgTime={this.state.alertMSGTime } title="Szczegóły"/>   
                    </div>
                )
        }
    
        if(this.state.view == 'week')
        {
                return (
                    <div className='compCalendar'>

                            <div className='compControll'>
                            

                                

                                    <select defaultValue={this.state.year} onChange={this.onChangeYear.bind(this) }  >
                                        {
                                            this.state.years.map( item => {
                                                return (
                                                    <option value={item}>
                                                        {item}
                                                    </option>
                                                )
                                            })
                                        }
                                                                            
                                    </select>

                                    &nbsp;

                                    <select defaultValue={this.state.month} onChange={this.onChangeMonth.bind(this) }  >
                                        {
                                            this.state.months.map( (index,item) => {
                                                return (
                                                    <option value={item}>
                                                        {index}
                                                    </option>
                                                )
                                            })
                                        }
                                                                            
                                    </select>
                                

                                <div className='compControllLeft'>
                                    <div className='btnD' onClick={this.onViewMonth.bind(this)}>Miesiąc</div>
                                    <div className='btnD' onClick={this.onViewWeek.bind(this)}>Tydzień</div>
                                </div>

                            </div>
                    
                        
                           <div>
                                {                                   
                                    this.state.days.map(item => {

                                        var dayOfW = 'Pn';                                        
                                        if(item == '.') { return (null) }

                                        var date = new Date(this.state.year + "-" + this.state.month + "-" + item + " 00:00:00" );
                                        var tDay = date.getDay();

                                        if(tDay > 0)
                                        {
                                          tDay = this.state.daysOfWeek[tDay-1]  ;
                                        }else tDay = this.state.daysOfWeek[6];

                                        var task = [];
                                        for(var kk=0; kk<this.state.rows.length; kk++)
                                        { 
                                            if(  parseInt(this.state.rows[kk].year) == parseInt(this.state.year) &&  parseInt(this.state.rows[kk].month) == parseInt(this.state.month) && parseInt(this.state.rows[kk].day) == parseInt(item) ) 
                                            {
                                                task = this.state.rows[kk].h;
                                            }
                                        }                      

                                        return (
                                            <div className='cellHor'>
                                                <div className='dayNum'>{item}</div>                               
                                                <div className='dayT'>{tDay}</div>                               
                                                <div className='dayContent1'>&nbsp;
                                                { 
                                                            task.map( h=>{

                                                                var title = h.title;
                                                                if(title.length > 30) {
                                                                  //  title = title.substring(0,30) + "...";
                                                                }

                                                                return (
                                                                            <span className='task2'  day={item} h={h.timeStart}  onClick={this.onClickTask.bind(this) }>
                                                                                <span className='hour2'>{h.timeStart}</span>
                                                                                &nbsp;
                                                                                {title}
                                                                            </span>
                                                                )
                                                            })
                                                }
                                                </div>  
                                            </div>
                                        )
                                    })
                                }
                           </div>


                           <AlertMsg msg={this.state.alertMSG } onDialogOK={this.onDialogOK.bind(this)}  msgTime={this.state.alertMSGTime } title="Szczegóły"/>                       
                    </div>
                )
        }
    
    }
 }


 export default Calendar