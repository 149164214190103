import React from "react";


import ConfigX from '../../../ConfigX'
import TipMessage from '../../../components/TipMessage';
import Messanger from '../../../components/Messanger';

import BackPNG from './../../../image/back.png'
import PrintPNG from './../../../image/print.png'

import ChooseResearch from "./ChooseResearch";

import loading from '../../../image/loading.gif'
import ButtonConfirm from '../../../components/ButtonConfirm';
import AlertMsg from '../../../components/AlertMsg';

import FileUploader from '../../../components/FileUploader';

import AddPerson from "./AddPerson";


class Det extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            loading: 1,
            search_bar: {                                           
                od: "",
                do: "",
                nazwa: "",
                nazwa_firmy: "",
                id: this.props.id,                    
            },
            pagger: {
                page: 1,
                limit: 1000,
                order_col: "",
                order: ""
            },
            rows: [],
            result: [],
            badania: [],
            badaniaCombo: [],
            osobyCombo: [],

            view: '',
            user_id: -1,
            redirect_param: '', //parametr przekazywany w przekierowaniu

            alertMSG: '',
            alertMSGTime: 0,

            err_nip: '',
            err_nazwa: '',
            err_ulica: '',
            err_nr_domu: '',
            err_kod: '',
            err_miasto: '',
            err_telefon: '',
            err_email: '',

            nn_nazwa: "",
            nn_kr_nazwa: "",
            nn_nip: "",
            nn_ulica: "",
            nn_kr_ulica: "",
            nn_nr_domu: "",
            nn_kr_nr_domu: "",
            nn_nr_mieszkania: "",
            nn_kr_nr_mieszkania: "",
            nn_kod_pocztowy: "",
            nn_kr_kod_pocztowy: "",
            nn_miasto: "",
            nn_kr_miasto: "",
            nn_telefon: "",
            nn_email: "",

            nn_osoba_reprezentujaca: "",
            nn_informacje_dodatkowe: "",
        }

      
    }



    componentDidMount()
    {
        //console.log("Det did mount");
        this.getData();        
    }


      //Pobranie danych
    getData()
      {
  
          //Jeżeli dłużej niż 0.5 sek będzie pobrać dane to wyświetli loader..
          var wait = setTimeout(
               () => {
                  this.setState({ loading: 1 });
               }
              ,500);
  
          var tmpNonRef = JSON.stringify( this.state );
          tmpNonRef = JSON.parse(tmpNonRef);
  
          var dataPost = tmpNonRef.search_bar;
          dataPost.pagger = tmpNonRef.pagger;
  
        
          
          //console.log("Requets " +  JSON.stringify(dataPost) );
  
          fetch( ConfigX.restApi + "/dash,get_firms_v2" , {
              method: 'POST', 
              body: JSON.stringify(dataPost), 
              headers: {
                  'Content-Type': 'text/html',
                  'X-API-KEY': this.props.apiKey
              }
          })
          .then( res => res.json() )
          .then(json => {
          
               //console.log(JSON.stringify(json) );
               clearTimeout(wait);
  
               this.setState({
                  rows: json.rows[0],
                  result: json.result, //zawsze pierwszy indeks
                  loading: 0
               });      
             
          });

          fetch( ConfigX.restApi + "/dash,getBadaniaFirm" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {


             this.setState({
                badania: json,
             });      
           
        });

        fetch( ConfigX.restApi + "/dash,getBadaniaCombo" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {


             this.setState({
                badaniaCombo: json,
             });      
           
        });

        

        fetch( ConfigX.restApi + "/dash,getOsobyCombo" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {


             this.setState({
                osobyCombo: json,
             });      
           
        });

    }


    onBackClick()
    {
        //this.props.redirect("list", this.props.id);
        this.props.redirect("list", -1);
    }

  

    onChangeNazwa(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["nazwa"] = v; 

            this.setState( {
                rows: rows,
                nn_nazwa: ok
            } );

            //if(ok == 'correct') this.updateFirma(null);
    }  
 

    onChangeKrNazwa(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 3)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["kr_nazwa"] = v; 

            this.setState( {
                rows: rows,
                nn_kr_nazwa: ok
            } );

          //  if(ok == 'correct') this.updateFirma(null);
    }  

     isValidNip(nip) {
        if(typeof nip !== 'string')
            return false;
    
        nip = nip.replace(/[\ \-]/gi, '');
    
        let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(nip.substring(9, 10));
        let weightCount = weight.length;
        for (let i = 0; i < weightCount; i++) {
            sum += (parseInt(nip.substr(i, 1)) * weight[i]);
        }
        
        return sum % 11 === controlNumber;
    }

    onChangeNIP(event)
    {
            var v   = event.target.value;

            var ok = "";
   

              //  if(v.length < 3)  { ok = "incorrect";  } else { ok="correct" }
                                                
              //  if(!this.isValidNip(v)) {ok = "incorrect"; } else { ok="correct" } 
  

            var rows = this.state.rows;
            rows["nip"] = v; 

            this.setState( {
                rows: rows,
                nn_nip: ok
            } );

           // if(ok == 'correct') this.updateFirma(null);
    }  


    onChangeUlica(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["ulica"] = v; 

            this.setState( {
                rows: rows,
                nn_ulica: ok
            } );

           // if(ok == 'correct') this.updateFirma(null);
    } 


    onChangeKrUlica(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["kr_ulica"] = v; 

            this.setState( {
                rows: rows,
                nn_kr_ulica: ok
            } );

          //  if(ok == 'correct') this.updateFirma(null);
    } 

    onChangeNrDomu(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["nr_domu"] = v; 

            this.setState( {
                rows: rows,
                nn_nr_domu: ok
            } );

           // if(ok == 'correct') this.updateFirma(null);
    } 

    onChangeKrNrDomu(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["kr_nr_domu"] = v; 

            this.setState( {
                rows: rows,
                nn_kr_nr_domu: ok
            } );

          //  if(ok == 'correct') this.updateFirma(null);
    } 

    onChangeNrMieszkania(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            //if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["nr_mieszkania"] = v; 

            this.setState( {
                rows: rows,
                nn_nr_domu: ok
            } );

        //  this.updateFirma(null);
    }     
   

    onChangeKrNrMieszkania(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            //if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["kr_nr_mieszkania"] = v; 

            this.setState( {
                rows: rows,
                nn_kr_nr_domu: ok
            } );

           //   this.updateFirma(null);
    }     
  

    onChangeKod(event)
    {
      //  var ok = "";
     
    
        var v = event.target.value;        

     //   v = v.replace(/[^\d.-]/g, '');

      //  const arr = v.split("-");
     //   if(arr.length == 2 && arr[0].length == 2 && arr[1].length == 3 && /^\d+$/.test(arr[0])  && /^\d+$/.test(arr[1])   )
     //   {
           var ok ="correct";
    //    }else ok ="incorrect";

       
        var rows = this.state.rows;
        rows["kod_pocztowy"] = v; 

        this.setState( {
            rows: rows,
            nn_kod_pocztowy: ok
        } );

      //  if(ok == 'correct') this.updateFirma(null);

    }


    onChangeKrKod(event)
    {
        var ok = "";
     
    
        var v = event.target.value;        

      //  v = v.replace(/[^\d.-]/g, '');

      //  const arr = v.split("-");
      //  if(arr.length == 2 && arr[0].length == 2 && arr[1].length == 3 && /^\d+$/.test(arr[0])  && /^\d+$/.test(arr[1])   )
      //  {
            ok ="correct";
      //  }else ok ="incorrect";

       
        var rows = this.state.rows;
        rows["kr_kod_pocztowy"] = v; 

        this.setState( {
            rows: rows,
            nn_kr_kod_pocztowy: ok
        } );

       // if(ok == 'correct') this.updateFirma(null);

    }


    onChangeMiasto(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["miasto"] = v; 

            this.setState( {
                rows: rows,
                nn_miasto: ok
            } );

           // if(ok == 'correct') this.updateFirma(null);
    } 


    onChangeKrMiasto(event)
    {
            var v   = event.target.value;
            var ok = "";
  
            if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["kr_miasto"] = v; 

            this.setState( {
                rows: rows,
                nn_kr_miasto: ok
            } );

           // if(ok == 'correct') this.updateFirma(null);
    } 



    onChangeTelefon(event)
    {
              var v = event.target.value;

                //v = v.replace("+48","");

              //  v = v.replace(/[^0-9]/g, ""); //wywal litery...
                v = v.replace(" ","");

              //  if(v.substr(0,3) != '+48')
             //   {
            //        v = "+48" + v;
            //    }
            
                
                var ok = "";
                if(v.length > 8  ) 
                {
                    ok = "correct";
                }

                var rows = this.state.rows;
                rows["telefon"] = v; 
    
                this.setState( {
                    rows: rows,
                    nn_telefon: ok
                } );

             //   if(ok == 'correct') this.updateFirma(null);
    }

    isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

    onChangeEmail(event)
    {
          var v = event.target.value;
        v = v.replace(" ","");

        var ok = "";
        if(v.length > 0 && this.isValidEmail(v) ) 
        {
           ok = "correct";
        }else
        if(v.length > 0)
        {
            ok ="incorrect";
        }

        var rows = this.state.rows;
        rows["email"] = v; 

        this.setState( {
            rows: rows,
            nn_email: ok
        } );

       // if(ok == 'correct') this.updateFirma(null);
    }

 
    onChangeOsoba(event)
    {
            var v   = event.target.value;
            var ok = "";
  
           // if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["osoba_reprezentujaca"] = v; 

            this.setState( {
                rows: rows,
                nn_osoba_reprezentujaca: ok
            } );

          //  this.updateFirma(null);
    } 
  

    onChangeInfoDod(event)
    {
            var v   = event.target.value;
            var ok = "";
  
           // if(v.length < 1)  { ok = "incorrect"; } else { ok="correct" }

            var rows = this.state.rows;
            rows["informacje_dodatkowe"] = v; 

            this.setState( {
                rows: rows,
                nn_informacje_dodatkowe: ok
            } );

         //   this.updateFirma(null);
    } 



    onConfirmDel(type, param, param_id)
    {
       //console.log(type + param + param_id);

       if(type != "yes")  return;


       var dt = new Date();
       dt = dt.toISOString();

       //sprawdz czy sa potwierdzone uczestnictwa..
       if(this.state.rows.pracownicy.length > 0)
       {
            this.setState({
                alertMSG: 'Nie można usunąć podmiotu. Usuń wszystkich pracowników',
                alertMSGTime: dt
            });
           return;
       }
      
       
       
         //
         var dataPost = {
            id: param_id,       
        };

   
        fetch( ConfigX.restApi + "/dash,del_firm" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             if(json.status == "OK")
             {
                this.props.redirect("list",-1,"deleted");
             }                 

             if(json.status == "ERROR")
             {
             
                                
                  this.setState({
                      alertMSG: 'Nie można usunąć podmiotu [' + json.param + '] ',
                      alertMSGTime: dt
                   });
             }    

        });

        
        
    }


    onConfirmDelUser(type, param, param_id)
    {
      // console.log(type + param + param_id);

       if(type != "yes")  return;


       var dt = new Date();
       dt = dt.toISOString();


         var dataPost = {
            id: param_id,       
        };

    //console.log(dataPost);
    //return;
   
        fetch( ConfigX.restApi + "/dash,del_person" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {
         
             if(json.status == "OK")
             {
                this.setState({
                    alertMSG:  "Usunięto pomyślnie",
                    alertMSGTime: dt
                 });

                this.getData();
             }                 

             if(json.status == "ERROR")
             {                                             
                  this.setState({
                      alertMSG:  json.param,
                      alertMSGTime: dt
                   });
             }    

        });

        
        
    }


    updateFirma(event)
    {


        if(event != null)
        {
                var err = false;

                
             /*   if(this.state.rows.nip.length < 2 || this.state.nn_nip == 'incorrect') 
                {
                    err = true;
                    this.setState({
                        nn_nazwa: "incorrect"
                    });
                }*/

                if(this.state.nn_nip == 'incorrect')
                {
                    err = true;
                    this.setState({
                        err_nip: "Podaj poprawny numer NIP",
                        nn_nip: "incorrect"
                     })
                }

                if(this.state.rows.nazwa.length < 2 || this.state.nn_nazwa == 'incorrect') 
                {
                   // console.log("nazwa: ",this.state.rows.nazwa);
                    err = true;
                    this.setState({
                        err_nazwa: "Podaj poprawną nazwę firmy",
                        nn_nazwa: "incorrect"
                    });
                }

                if(this.state.rows.ulica.length < 2 || this.state.nn_ulica == 'incorrect') 
                {
                    err = true;
                    this.setState({
                        err_ulica: "Podaj ulicę",
                        nn_ulica: "incorrect"
                    });
                }        
            
                if(this.state.rows.nr_domu.length < 1 || this.state.nn_nr_domu == 'incorrect') 
                {
                    err = true;
                    this.setState({
                        err_nr_domu: "Podaj nr domu",
                        nn_nr_domu: "incorrect"
                    });
                }        
                    

                if(this.state.rows.kod_pocztowy.length < 2 || this.state.nn_kod_pocztowy == 'incorrect') 
                {
                    err = true;
                    this.setState({
                        err_kod: "Podaj kod pocztowy",
                        nn_kod_pocztowy: "incorrect"
                    });
                }         


                if(this.state.rows.miasto.length < 2 || this.state.nn_miasto == 'incorrect') 
                {
                    err = true;
                    this.setState({
                        err_miasto: "Podaj miasto",
                        nn_miasto: "incorrect"
                    });
                }         

                if(this.state.rows.telefon.length < 2 || this.state.nn_telefon == 'incorrect') 
                {
                    err = true;
                    this.setState({
                        err_telefon: "Podaj telefon",
                        nn_telefon: "incorrect"
                    });
                }    

                if(this.state.rows.email.length < 2 || this.state.nn_email == 'incorrect') 
                {
                    err = true;
                    this.setState({
                        err_email: "Podaj email",
                        nn_email: "incorrect"
                    });
                }

                if(this.state.rows.kr_nazwa.length < 2)  { this.state.rows.kr_nazwa = this.state.rows.nazwa; }
                if(this.state.rows.kr_ulica.length < 2)  { this.state.rows.kr_ulica = this.state.rows.ulica; }
                if(this.state.rows.kr_nr_domu.length < 1)  { this.state.rows.kr_nr_domu = this.state.rows.nr_domu; }
                if(this.state.rows.kr_nr_mieszkania.length < 1)  { this.state.rows.kr_nr_mieszkania = this.state.rows.nr_mieszkania; }
                if(this.state.rows.kr_kod_pocztowy.length < 1)  { this.state.rows.kr_kod_pocztowy = this.state.rows.kod_pocztowy; }
                if(this.state.rows.kr_miasto.length < 1)  { this.state.rows.kr_miasto = this.state.rows.miasto; }

            
                if(err) return;

                var bnText = event.target.innerHTML;
               // event.target.innerHTML = "...";
        }



   


        var dataPost = {
            id: this.state.rows.id,       
            nazwa: this.state.rows.nazwa,       
            nip: this.state.rows.nip,       
            regon: this.state.rows.regon,       
            ulica: this.state.rows.ulica,       
            nr_domu: this.state.rows.nr_domu,       
            nr_mieszkania: this.state.rows.nr_mieszkania,       
            kod_pocztowy: this.state.rows.kod_pocztowy,       
            miasto: this.state.rows.miasto,       
            telefon: this.state.rows.telefon,       
            email: this.state.rows.email,       
            kr_nazwa: this.state.rows.kr_nazwa,       
            kr_ulica: this.state.rows.kr_ulica,       
            kr_nr_domu: this.state.rows.kr_nr_domu,       
            kr_nr_mieszkania: this.state.rows.kr_nr_mieszkania,       
            kr_kod_pocztowy: this.state.rows.kr_kod_pocztowy,       
            kr_miasto: this.state.rows.kr_miasto,       
            osoba_reprezentujaca: this.state.rows.osoba_reprezentujaca,       
            informacje_dodatkowe: this.state.rows.informacje_dodatkowe,  
            czlonek_bkp: this.state.rows.czlonek_bkp,       
        }

      //  console.log(JSON.stringify(this.state.rows)  );
       // console.log(JSON.stringify(dataPost) );


        fetch( ConfigX.restApi + "/dash,edit_firm" , {
            method: 'POST', 
            body: JSON.stringify(dataPost), 
            headers: {
                'Content-Type': 'text/html',
                'X-API-KEY': this.props.apiKey
            }
        })
        .then( res => res.json() )
        .then(json => {

              var dt = new Date();
              dt = dt.toISOString();

             if(json.status == "OK")
             {
                if(event != null)
                {
                    event.target.innerHTML = bnText;
                                  
                    this.setState({
                       alertMSG: 'Informacje zostały zapisane.',
                       alertMSGTime: dt
                    });
                    
                }                
             }else
             {
               // console.log(json);
                 this.setState({
                     alertMSG: 'Wystąpił problem z zapisem danych.',
                     alertMSGTime: dt
                  });  
             }
      
           
        });

    }

    copyAdress(event)
    {
        var rows = this.state.rows;
        
        rows['kr_ulica'] = rows.ulica;
        rows['kr_nr_domu'] = rows.nr_domu;
        rows['kr_nr_mieszkania'] = rows.nr_mieszkania;
        rows['kr_kod_pocztowy'] = rows.kod_pocztowy;
        rows['kr_miasto'] = rows.miasto;

        this.setState({
            rows: rows
        })

    }

    addBadanie()
    {
        this.setState({view: "sign"})
    }

    onChangeStatus(event)
    {
        var v   = event.target.value;      

        var rows = this.state.rows;
        rows["czlonek_bkp"] = v; 

        this.setState( {
            rows: rows,     
        } );

        //this.updateSzkolenie(null);
    }
 

    closeWindow()
    {
            this.setState({
                view:"",
                user_id: -1,
            }); 
    
            this.getData();   
    }

    onRowClick(event){

        var id = event.target.id;

        this.setState({view: "new",user_id: id})

        //this.redirect("det", event.target.id ,"edit"  );

        //this.setState({
       //     view: "det",
        //    user_id: event.target.id    
      //  })
    }

    onClickAddNew(event)
    {
       // this.redirect("det", "-1" , "new" );
       this.setState({view: "new"})
    }

    redirect(action, id, param)
    {
        this.setState(
            {
                view: action,
                user_id: id,
                redirect_param: param,
            }
           );
    }
    
 
    render()
    {

        var msg = "";
       
//<td id={item.id} className="text_center">  <ButtonConfirm   onConfirm={this.onConfirmDelUser.bind(this) } param="px_group" param_id={item.id}  title="usuń*" query={"Uwaga, usunięcie spowoduje odpięcie użytkownika od firmy, nie usunięcie z systemu. Czy chcesz odpiąć " + item.imie + " " + item.nazwisko + "?"}  /> </td>  

        //if(this.props.redirect_param)this.getData();

        if(this.state.loading == 1) 
        {
            return (
             <div className='list'>                                               
                <div className='table_content'>
                  <div className='loading'><img src={loading} /></div>
                </div>
              </div>
            )
        } 
        
        var content = '';


        if(this.state.view == "new" )
        {
    
            content = (  
                 <>                                                                      
                     <AddPerson onClose={this.closeWindow.bind(this)}  redirect={this.redirect.bind(this) } param="new" id={this.state.user_id} apiKey={this.props.apiKey} sk_firmy_id={this.state.rows.id} title={this.state.rows.nazwa}/> 
                 </>
             )
        }  
        
        

        if(this.state.view == 'sign') //na później --- demoooo
        {           
            content = (               
               <>
                    <ChooseResearch apiKey={this.props.apiKey} rows={this.state.badaniaCombo} osoby={this.state.osobyCombo} view={this.state.view} onClose={this.closeWindow.bind(this)} id={this.state.rows.id}/>
               </>         
            );
        }

        var zlec_badanie = '';
        if(this.state.rows.pracownicy.length > 0)
        {
             zlec_badanie = (
                <div className='buttonNewItem' onClick={this.addBadanie.bind(this)}>Zleć badanie</div>  
            )
        }else{
             zlec_badanie = (<div className="tipAlert">Aby ta firma mogła zlecić badanie, musisz dodać przynajmniej jednego pracownika do firmy.</div>);
        }

        return (
            <div className='details'>
                            

                  <div className='location' onClick={this.onBackClick.bind(this) }>
                    <img src={BackPNG} /> Powrót do listy podmiotów
                 </div>
                 
                                    
                    <h3>
                        {this.state.rows.nazwa}                                                     
                    </h3>
                 

                    <TipMessage  msg={msg} type="notice" />

                    <div className='dashForm'>
                           
                            <div className='FormField '>
                               <label>Członek BKP</label>
                               <select defaultValue={this.state.rows.czlonek_bkp} onChange={this.onChangeStatus.bind(this) } col="czlonek_bkp" >
                                  <option value="0">NIE</option>              
                                  <option value="1">TAK</option>                          
                               </select>
                            </div> 

                             <div className='FormField'>
                               <label>NIP<span className='required'>*</span><span className={'errorForm'}>{this.state.err_nip}</span></label>
                               <input type="text" className={"field200 " + this.state.nn_nip } value={this.state.rows.nip}  col="nip" onChange={this.onChangeNIP.bind(this) } />                               
                            </div>

                            <div className='FormField'>
                               <label>Nazwa firmy<span className='required'>*</span><span className={'errorForm'}>{this.state.err_nazwa}</span></label>
                               <input type="text" className={"field500 " + this.state.nn_nazwa } value={this.state.rows.nazwa}  col="Nazwa" onChange={this.onChangeNazwa.bind(this) } />                               
                            </div>

                            <div className='FormField inlineField'>
                               <label>Ulica<span className='required'>*</span><span className={'errorForm'}>{this.state.err_ulica}</span></label>                               
                               <input type="text" className={"field300 " + this.state.nn_ulica } value={this.state.rows.ulica}  col="ulica" onChange={this.onChangeUlica.bind(this) } />                               
                            </div>

                            <div className='FormField inlineField'>
                               <label>Nr domu<span className='required'>*</span><span className={'errorForm'}>{this.state.err_nr_domu}</span></label>
                               <input type="text" className={"field50 " + this.state.nn_nr_domu } value={this.state.rows.nr_domu}  col="" onChange={this.onChangeNrDomu.bind(this) } />                               
                            </div>

                            <div className='FormField inlineField'>
                               <label>Nr mieszkania</label>
                               <input type="text" className={"field50 " + this.state.nn_nr_mieszkania } value={this.state.rows.nr_mieszkania}  col="" onChange={this.onChangeNrMieszkania.bind(this) } />
                            </div>
                        
                            <br/>

                            <div className='FormField inlineField'>
                               <label>Kod pocztowy<span className='required'>*</span><span className={'errorForm'}>{this.state.err_kod}</span></label>
                               <input type="text" className={"field100 " + this.state.nn_kod_pocztowy } value={this.state.rows.kod_pocztowy}  col="" onChange={this.onChangeKod.bind(this) } />                               
                            </div>

                            <div className='FormField inlineField'>
                               <label>Miasto<span className='required'>*</span><span className={'errorForm'}>{this.state.err_miasto}</span></label>
                               <input type="text" className={"field200 " + this.state.nn_miasto } value={this.state.rows.miasto}  col="" onChange={this.onChangeMiasto.bind(this) } />                               
                            </div>


                            <br/>

                            <div className='FormField inlineField'>
                            <label>Telefon<span className='required'>*</span><span className={'errorForm'}>{this.state.err_telefon}</span></label>
                            <input type="text" className={"field100 " + this.state.nn_telefon } value={this.state.rows.telefon}  col="" onChange={this.onChangeTelefon.bind(this) } />                            
                            </div>


                            <div className='FormField inlineField'>
                            <label>E-mail<span className='required'>*</span><span className={'errorForm'}>{this.state.err_email}</span></label>
                            <input type="text" className={"field300 " + this.state.nn_email } value={this.state.rows.email}  col="" onChange={this.onChangeEmail.bind(this) } />                            
                            </div>

                            <br/>

                            <div className='FormField'>
                               <label>Osoba reprezentująca / kontaktowa</label>
                               <textarea value={this.state.rows.osoba_reprezentujaca}   className="field100p" onChange={this.onChangeOsoba.bind(this)}></textarea>
                            </div>

                            <div className='FormField'>
                               <label>Informacje dodatkowe / Własne</label>
                               <textarea value={this.state.rows.informacje_dodatkowe}  className="field100p"  onChange={this.onChangeInfoDod.bind(this)}></textarea>
                            </div>


                           
                            <h5>Adres korespondencyjny</h5>

                            <div className='buttonForm' onClick={this.copyAdress.bind(this)}>taki sam jak domowy</div>  
                            

                            <div className='FormField'>
                               <label>Nazwa<span className='required'>*</span></label>
                               <input type="text" className={"field500 " + this.state.nn_kr_nazwa } value={this.state.rows.kr_nazwa}  col="Nazwa" onChange={this.onChangeKrNazwa.bind(this) } />
                            </div>

                            <div className='FormField inlineField'>
                               <label>Ulica<span className='required'>*</span></label>
                               <input type="text" className={"field300 " + this.state.nn_kr_ulica } value={this.state.rows.kr_ulica}  col="ulica" onChange={this.onChangeKrUlica.bind(this) } />
                            </div>

                            <div className='FormField inlineField'>
                               <label>Nr domu<span className='required'>*</span></label>
                               <input type="text" className={"field50 " + this.state.nn_kr_nr_domu } value={this.state.rows.kr_nr_domu}  col="" onChange={this.onChangeKrNrDomu.bind(this) } />
                            </div>

                            <div className='FormField inlineField'>
                               <label>Nr mieszkania</label>
                               <input type="text" className={"field50 " + this.state.nn_kr_nr_mieszkania } value={this.state.rows.kr_nr_mieszkania}  col="" onChange={this.onChangeKrNrMieszkania.bind(this) } />
                            </div>
                        
                            <br/>

                            <div className='FormField inlineField'>
                               <label>Kod pocztowy<span className='required'>*</span></label>
                               <input type="text" className={"field100 " + this.state.nn_kr_kod_pocztowy } value={this.state.rows.kr_kod_pocztowy}  col="" onChange={this.onChangeKrKod.bind(this) } />
                            </div>

                            <div className='FormField inlineField'>
                               <label>Miasto<span className='required'>*</span></label>
                               <input type="text" className={"field200 " + this.state.nn_kr_miasto } value={this.state.rows.kr_miasto}  col="" onChange={this.onChangeKrMiasto.bind(this) } />
                            </div>

                    <br/>
                    <br/>
                    <br/>

                         <div className='FormField FormButtons'>
                            <div className='buttonForm' onClick={this.updateFirma.bind(this)}>zapisz</div>  
                            &nbsp; &nbsp; 
                            <ButtonConfirm   onConfirm={this.onConfirmDel.bind(this) } param="sk_firmy" param_id={this.state.rows.id}  title="przenieś do archiwum*" query="Na pewno chcesz przenieść podmiot do archiwum?"  />
                                                         
                         </div>

                         <div className='simplyTipAlert'>
                             Nie można przenieść firmy do archiwum wtedy gdy: <br/><br/>
                            *firma prowadzi szkolenia<br/>
                            *firma prowadzi badania<br/>      
                            *do firmy powiązani są pracownicy                 
                         </div>
 

                    </div>

                    <div className='location' onClick={this.onBackClick.bind(this) }>
                      <img src={BackPNG} /> Powrót do listy podmiotów
                    </div>

                    <div>
                        <br/>
                          <FileUploader typ="sk_firmy_adm" typ_id={this.state.rows.id} apiKey={this.props.apiKey} titlex="Powiązane dokumenty"/>
                          <br/>
                    </div>
 

                    <br/>


                    <h3>Pracownicy / Osoby powiązane </h3>    
 
                                
                    <div className='buttonNewItem' onClick={this.onClickAddNew.bind(this)} >Dodaj osobę (+)</div> 
                 
                    <div className='table_content'>                 
                        <table className='table table100'>
                        <thead>
                            <tr>
                                <td>LP</td>
                                <td></td>
                                <td>Imię</td>                       
                                <td>Nazwisko</td>                       
                                <td>Telefon</td>                       
                                <td>E-mail</td>                                                                       
                            </tr>
                        </thead>
                        <tbody> 
                            {                                                        
                                this.state.rows.pracownicy.map( (item,index) => 
                                {                                                                                   
                                   
                                    return(
                                       <tr className="rowLink" >
                                             <td id={item.id} onClick={this.onRowClick.bind(this)} className="text_center">{index+1}</td>
                                             <td id={item.id}> <Messanger apiKey={this.props.apiKey}    user_id={item.id}    /> </td>
                                             <td id={item.id} onClick={this.onRowClick.bind(this)}>{item.imie}</td>                                                 
                                             <td id={item.id} onClick={this.onRowClick.bind(this)}>{item.nazwisko} &nbsp;</td>    
                                             <td id={item.id} onClick={this.onRowClick.bind(this)} className="text_center">{item.telefon}</td>   
                                             <td id={item.id} onClick={this.onRowClick.bind(this)} className="text_center">{item.email}</td>                                                                                                 
                                       </tr>
                                    )
                                    
                                } )
                            } 
                        </tbody>    


                        </table>

                   </div>

                   <br/><br/>

                  {content}
                       

                    <h3>Zlecone wnioski na badania </h3>    

                    {zlec_badanie}

                    <div className='table_content'>                 
                        <table className='table table100'>
                        <thead>
                            <tr>
                                <td>LP</td>                           
                                <td>Numer wniosku</td>                       
                                <td>Nazwa badania</td>                       
                                <td>Data złożenia wniosku</td> 
                                <td>Kwota</td>                            
                                <td>Status</td>                                                                                                                                                                        
                            </tr>
                        </thead>
                        <tbody> 
                            {                                                        
                                this.state.badania.map( (item,index) => 
                                {                                                                                   
                                
                                    var status = item.status.replace("_"," ");

                                    return(
                                    <tr className="" >
                                            <td id={item.id}  className="text_center">{index+1}</td>
                                            <td id={item.id}  className="text_center">{item.numer_wniosku}</td>                                                 
                                            <td id={item.id}  className="text_center">{item.tytul} &nbsp;</td>    
                                            <td id={item.id}  className="text_center">{item.data}</td>   
                                            <td id={item.id}  className="text_center">{item.kwota}</td>                                                                                                 
                                            <td id={item.id}  className="text_center">{status}</td>                                                                                                 
                                    </tr>
                                    )
                                    
                                } )
                            } 
                        </tbody>    


                        </table>

                    </div>

                 


                   <div className='location' onClick={this.onBackClick.bind(this) }>
                      <img src={BackPNG} /> Powrót do listy podmiotów
                    </div>

                    

                    
                    <AlertMsg msg={this.state.alertMSG }  msgTime={this.state.alertMSGTime } title="Powiadomienie"/>

            </div>
        )
    }
}

export default Det;