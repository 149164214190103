import React from 'react';

import Messanger from '../../../components/Messanger';

 
class DetUczestnicyRow extends React.Component
 {
        constructor(props)
        {
            super(props)
        }

        onRowClick(event)
        {                        
            var id = event.target.getAttribute("id");
            this.props.onClickUczestnik(id);
        }

        onChangeStatus(event)
        {            
            this.props.onChangeStatusUczestnik(event.target.value, this.props.row.id);
        }

        onChangeStatusFrekwencja(event)
        {
            var val = event.target.value;
            var id = event.target.getAttribute("id");

            this.props.onChangeStatusFrekwencja(val, id, this.props.row.id);
        }

        render()
        {

           
 

            var rozliczone = "nie";
            if(this.props.row.rozliczone == 1) rozliczone = "tak";

            var rowStyle = "";


            if(this.props.row.status == "potwierdzone") 
            {                
                        rowStyle = "row_green";
                        return (                 
                                    <tr className={ 'rowLink ' + rowStyle} >
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.num}</td>
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.nazwisko}  &nbsp;<Messanger apiKey={this.props.apiKey}    user_id={this.props.row.px_group_id}    />  </td>                                                      
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.imie}</td>                                                      
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="">{this.props.row.email}</td>                                                      
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.row.telefon}     </td>                                                                     
                                        <td id={this.props.row.id} className="text_center">
                                            <select defaultValue={this.props.row.status}  onChange={this.onChangeStatus.bind(this) }>           
                                               <option value="wypisany">wypisany</option>                                  
                                               <option value="potwierdzone">potwierdzone</option>                                                                                                                                     
                                            </select> 
                                                                  
                                        </td>                                                                        
                                        <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{rozliczone}</td>                                                                        
                                        <td id={this.props.row.id}   className="">
                                            <ul className='listInnerTab'>
                                            {
                                                this.props.row.terminy.map( (item,index) => {
                                                    return (
                                                        <li key={index}>                                                            
                                                            <select defaultValue={item.frekwencja} id={item.id} onChange={this.onChangeStatusFrekwencja.bind(this) }  >
                                                                <option value="-">-</option>
                                                                <option value="obecny">obecny</option>
                                                                <option value="nieobecny" >nieobecny</option>
                                                            </select>
                                                            &nbsp;&nbsp;
                                                            {item.termin.data_od}                                    
                                                        </li>
                                                    )
                                                } )                                    
                                            }
                                            </ul>
                                        </td>                                                                        
                                    </tr>                 
                        )
           }

           if(this.props.row.status != "potwierdzone" && this.props.row.status != "wypisany") 
           {                       
                       return (                 
                                   <tr className={ 'rowLink ' + rowStyle} >
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.num}</td>
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.nazwisko} &nbsp;<Messanger apiKey={this.props.apiKey}    user_id={this.props.row.px_group_id}    /> </td>                                                      
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.imie}</td>                                                      
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="">{this.props.row.email}</td>                                                      
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.row.telefon}     </td>                                                                     
                                       <td id={this.props.row.id} className="text_center">
                                           <select defaultValue={this.props.row.status} onChange={this.onChangeStatus.bind(this) }>
                                               <option value="oczekuje">oczekuje</option>
                                               <option value="potwierdzone">potwierdzone</option>
                                               <option value="odrzucone_brak_miejsc">brak miejsc </option>
                                               <option value="odrzucone_odwolanie_szkolenia">odwołano szk.</option>
                                           </select>                                
                                            
                                       </td>                                                                        
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">&nbsp;</td>                                                                        
                                       <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="">&nbsp;</td>                                                                        
                                   </tr>                 
                       )
          }


          if( this.props.row.status == "wypisany") 
          {                       
                      return (                 
                                  <tr className={ 'rowLink ' + rowStyle} >
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.num}</td>
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.nazwisko} &nbsp;<Messanger apiKey={this.props.apiKey}    user_id={this.props.row.px_group_id}    /> </td>                                                      
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)}  className="">{this.props.row.imie}</td>                                                      
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="">{this.props.row.email}</td>                                                      
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{this.props.row.telefon}     </td>                                                                     
                                      <td id={this.props.row.id} className="text_center">
                                          <select defaultValue={this.props.row.status} onChange={this.onChangeStatus.bind(this) }>
                                                <option value="wypisany">wypisany</option>                       
                                          </select>                                
                                           
                                      </td>                                                                        
                                      <td id={this.props.row.id} onClick={this.onRowClick.bind(this)} className="text_center">{rozliczone}</td>                                                                        
                                        <td id={this.props.row.id}   className="">
                                            <ul className='listInnerTab'>
                                            {
                                                this.props.row.terminy.map( (item,index) => {
                                                    return (
                                                        <li key={index}>                                                            
                                                            <select defaultValue={item.frekwencja} id={item.id} onChange={this.onChangeStatusFrekwencja.bind(this) }  >
                                                                <option value="-">-</option>
                                                                <option value="obecny">obecny</option>
                                                                <option value="nieobecny" >nieobecny</option>
                                                            </select>
                                                            &nbsp;&nbsp;
                                                            {item.termin.data_od}                                    
                                                        </li>
                                                    )
                                                } )                                    
                                            }
                                            </ul>
                                        </td>                                                                          
                                  </tr>                 
                      )
         }



        }
 }

 export default DetUczestnicyRow;