


import React from 'react';
import { Link  } from 'react-router-dom';
import './styles.css';
import './bootstrap.css';

import rem0 from '../helpdesk/images/remind/remind0.PNG';
import rem1 from '../helpdesk/images/remind/remind1.PNG';
import rem2 from '../helpdesk/images/remind/remind2.PNG';
import rem3 from '../helpdesk/images/remind/remind3.PNG';
import rem4 from '../helpdesk/images/remind/remind4.PNG';
import rem5 from '../helpdesk/images/remind/remind5.PNG';
import rem6 from '../helpdesk/images/remind/remind6.PNG';
 


class Remind extends React.Component
 {



  render(){

    return(
        <>
        <section id="method" className="section border-no-sec">
          <h3>Krok 1</h3>
          <br/>
          <p>Aby przypomnieć hasło klikamy w nagłówku link "Zaloguj się".</p>

          <img src={rem0} alt=""/>

        </section>
        <section id="method" className="section border-no-sec">

          <h3>Krok 2</h3>
          <br/>
          <p>W kolejnym kroku wybieramy opcję "Mam już konto".</p>

          <img src={rem1} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 3</h3>
          <br/>
          <p>Klikamy "Nie pamiętam hasła" </p>

          <img src={rem2} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 4</h3>
          <br/>
          <p>Wpisujemy swój login / adres e-mail. Zostanie wysłana wiadomość e-mail z kodem autoryzacyjnym, następnie klikamy "Dalej". </p>

          <img src={rem3} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 5</h3>
          <br/>
          <p>Z wiadomości e-mail przepisujemy kod autoryzacyjny i wpisujemy go w kroku kolejnym.</p>

          <img src={rem5} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 6</h3>
          <br/>
          <p>W tym kroku Podajemy NOWE HASŁO i POWTARZAMY JE oraz kod autoryzacyjny który został wysłany na adres e-mail, później klikamy "Dalej".</p>

          <img src={rem4} alt=""/>

        </section>

        <section id="method" className="section border-no-sec">

          <h3>Krok 7</h3>
          <br/>
          <p>Po pomyślnym przejściu wszystkich kroków, system automatycznie loguje i przekierowuje nas do systemu. </p>

          <img src={rem6} alt=""/>

        </section>

        </>

    )
  }



}

export default Remind;